import React, { useState } from "react";
import Utils from "../Common/Utils";
import "./Routes.scss";
import { useDispatch, useSelector } from "react-redux";
import allActions from "../Store/Actions/AllActions";
import SiteBreadCrumbs from "../Subcomponent/Shared/SiteBreadCrumbs";
import Constants from "../Common/Constants";
import { Box, Checkbox, FormControlLabel, FormGroup, Grid, ModalUnstyledOwnerState, Paper, TextField, Card, CardContent } from "@mui/material";

import { Button, ButtonGroup, Typography, Divider } from "@material-ui/core";
import IdentityManager from "../Managers/Identity.manager";
import { IReportPayload } from "../Models/IReports";
import ReportManager from "../Managers/Reports.Manager";
import ExpediteReport from "../Subcomponent/Reports/ExpediteReport";
import ShippedPickupInStoreReport from "../Subcomponent/Reports/ShippedPickupInStoreReport";
import ContactCustomerFulfillment from "../Subcomponent/Reports/ContactCustomerFulfillment";
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import FulfillmentRateDemoReport from "../Subcomponent/Reports/FulfillmentRateDemoReport";
import BarGraph from "../Subcomponent/Graph/BarGraph";
import MixedBarGraph from "../Subcomponent/Graph/MixedBarGraph";
import PieChartGraph from "../Subcomponent/Graph/PieChartGraph"
import DatePicker from '@mui/lab/DatePicker';
import { Tiles } from "../Subcomponent/Reports/Tiles";
import CustomerResponseTrend from "../Subcomponent/Reports/CustomerResponseTrend";
import { ICustomerContactReport, IDistrictCCData, IStoreCCData } from "../Models/ICustomerContactReport";
import GaugeChart from 'react-gauge-chart';
import ChartShimmer from "../Subcomponent/Shared/ChartShimmer";
import ServiceTrend from "../Subcomponent/Reports/ServiceTrend";
import { ZoomOutMapIcon } from "../Subcomponent/Shared/SharedIcons";
import { ResourceOrderDataGrid } from "../Subcomponent/Reports/ResourceOrderDataGrid";
import DataNotFound from "../Subcomponent/Shared/DataNotFound";
import { OrderTimeGrid } from "../Subcomponent/Reports/OrderTimeGrid";
import OrderTimeChart from "../Subcomponent/Reports/OrderTimeChart";
import DummyDataManager from "../Managers/DummyDataManager";
import OrderProcessingTime from "../Subcomponent/Reports/OrderProcessingTime";
import { log } from "console";
import { ResponsiveContainer } from "recharts";
import { style } from "@mui/system";


const color = '#00FF00'; // Replace with your desired color

const Reports = (props: any) => {

  var moment = require('moment');
  const dispatch = useDispatch();
  const [rangeType, setRangeType] = useState("7");
  const [dataType, setDataType] = useState("1");
  const [dateFilter, setDateFilter] = useState(Utils.getDates(7));
  const [isExpeditedLoaded, setExpeditedLoaded] = useState(false);
  const [expeditedData, setExpediteData] = useState({ expeditedData: [], dataType: "1" } as any);
  const [isShippedPickupLoaded, setShippedPickupLoaded] = useState(false);
  const [shippedPickupData, setShippedPickupData] = useState({ shippedHits: undefined, pickUpInStoreHits: undefined, toShipOrders: undefined, toPickUpOrders: undefined, shipped: [], pickUpInStore: [], dataType: "1", total: [] } as any);
  // for order resource Grid
  const [gridPopUp, setGridPopUp] = useState(false);

  // for Customer contacted
  const [isccDataLoaded, setCCDataLoaded] = useState(false);

  // top three lined resource reasons 
  const [topThreelineResourceReason, setTopThreelineResourceReason] = useState({});
  const [ccData, setCCData] = useState([] as any);
  // for Resource Order
  const [isroDataLoaded, setRODataLoaded] = useState(false);
  const [ROData, setROData] = useState([] as any);

  // BOPI variables
  const [bopiCount, setBopiCount] = useState(0);
  const [bopiCountValue, setBopiCountValue] = useState(0);
  const [bopiUsedCount, setBopiUsedCount] = useState(0);
  const [bopiUsedCountValue, setBopiUsedCountValue] = useState(0);

  // BOPI STS variables
  const [stsCount, setStsCount] = useState(0);
  const [stsCountValue, setStsCountValue] = useState(0);
  const [stsUsedCount, setStsUsedCount] = useState(0);
  const [stsUsedCountValue, setStsUsedCountValue] = useState(0);

  // SFS variables
  const [sfsCount, setSfsCount] = useState(0);
  const [sfsCountValue, setSfsCountValue] = useState(0);
  const [sfsUsedCount, setSfsUsedCount] = useState(0);
  const [sfsUsedCountValue, setSfsUsedCountValue] = useState(0);


  // District list of Resourced Order
  const [DistrictROData, setDistrictROData] = useState([] as any);

  const [isAllEnterPriseChecked, setEnterPriseChecked] = useState(false);
  //   Custom Dates
  const [customFromDate, setCustomFromDate] = React.useState<Date | null>(null);
  const [customToDate, setCustomToDate] = React.useState<Date | null>(null);

  // for shipping stats
  const [disableApplyBtn, setDisableApplyBtn] = useState(false);

  // for shipping stats
  const [isShippingDataLoaded, setIsShippingDataLoaded] = useState(false);
  const [shippingData, setshippingData] = useState([] as any);

  // for fulfilledOrders
  const [isFulfilledOrdersLoaded, setFulfilledOrdersLoaded] = useState(false);
  const [fulfilledData, setFulfilledData] = useState([] as any);

  const [isOrderAccuracyLoaded, setOrderAccuracyLoaded] = useState(false);
  const [orderAccuracy, setOrderAccuracy] = useState(0);

  // for Order Processing Time
  const [isOrderProcessingTimeLoaded, setOrderProcessingTimeLoaded] = useState(true);
  const [orderProcessingData, setOrderProcessingData] = useState([] as any);
  const [totalOrders, setTotalOrders] = useState(0);
  const [totalResourcedOrders, setResourcedOrders] = useState(0);
  const [usedReleased, setUsedReleased] = useState(0)

  // For Released and Picked up Total
  const [totalReleased, setReleased] = useState(0);
  const [totalPickedUp, setPickedUp] = useState(0);

  const districts = useSelector((state: any) => state.ActionLoaderReducersState.districts);
  const stores = useSelector((state: any) => state.ActionLoaderReducersState.stores);

  var momenttz = require('moment-timezone');
  const todayDate = momenttz.tz("America/Los_Angeles");

  const closeGridPopup = () => {
    setGridPopUp(false);
  }

  const showActionLoader = () => {
    dispatch(allActions.actionLoaderActions.showLoader());
  }
  const hideActionLoader = () => {
    dispatch(allActions.actionLoaderActions.hideLoader());
  }

  const onChangeRangeType = (type: any) => {
    showActionLoader()
    setRangeType(type);
    var dates = null;
    switch (type) {
      case "Y":
        dates = Utils.getDates(-1);
        setDataType("0");

        break;

      case "M":
        dates = Utils.getDates(0);
        setDataType("1");

        break;

      case "T":
        dates = Utils.getDates(1);
        setDataType("0");

        break;

      case "7":
        dates = Utils.getDates(7);
        setDataType("1");

        break;

      case "15":
        dates = Utils.getDates(15);
        setDataType("1");

        break;

      case "30":
        dates = Utils.getDates(30);
        setDataType("2");

        break;

      // case "60":
      //   dates = Utils.getDates(60);
      //   setDataType("3");
      //   break;

      // case "90":
      //   dates = Utils.getDates(90);
      //   setDataType("3");
      //   break;
      case "C":
        setCustomFromDate(moment().subtract(7, 'days').toDate());
        setCustomToDate(moment().toDate());
        setDataType("1");

        break;
      default:
        dates = Utils.getDates(7);
        setDataType("2");

        break;
    }
    if (dates) {
      setDateFilter(dates);

    }
  };

  const onChangeDataType = (type: any) => {
    setDataType(type);
  };

  const checkActiveDataRange = (type: any) => {
    return type == rangeType;
  };

  const checkActiveDataType = (type: any) => {
    return dataType == type;
  };

  const customDateRange = () => {
    let startDateTime = customFromDate;
    let endDateTime = customToDate;
    if (startDateTime && endDateTime) {
      startDateTime.setHours(0);
      startDateTime.setMinutes(0);
      startDateTime.setSeconds(0);
      startDateTime.setMilliseconds(0);

      endDateTime.setHours(23);
      endDateTime.setMinutes(59);
      endDateTime.setSeconds(59);
      endDateTime.setMilliseconds(999);

      var start = moment(startDateTime).format();
      var end = moment(endDateTime).format();
      setDateFilter({ start, end });
    }
  }

  const onChangeFromDate = (event: any) => {

    const startDate = moment(event);
    const endDate = moment(customToDate);
    setCustomFromDate(event);
    if (endDate.diff(startDate, 'days') < 0) {

      setDisableApplyBtn(true)
      return
    }

    if (endDate.diff(startDate, 'days') > 30) {
      setDisableApplyBtn(true)
      return
    } else {
      setDisableApplyBtn(false)
    }


    // let toDate = moment(event).add(1, 'months').toDate();
    // if (toDate > moment().toDate()) {

    //   setCustomToDate(moment().toDate());
    // }
    // else {
    //   setCustomToDate(toDate);
    // }
  }

  const onChangeToDate = (event: any) => {
    let maxDate = moment(customFromDate).add(1, 'months').toDate();
    if (event < maxDate) {
      setDisableApplyBtn(false)
      setCustomToDate(event);
    } else {
      setDisableApplyBtn(true)
    }
  }

  const handleApplyCustomDate = () => {

    customDateRange()
  }


  // Get Total Line Order Resourced
  const getTotalResourcedOrder = (roDATA: any) => {

    if (roDATA?.districtROData.length > 1) {
      const sumWithInitial = roDATA?.districtROData.reduce(
        (accumulator: any, currentValue: any) => accumulator + currentValue.totalResourceOrderCount, 0);
      setResourcedOrders(sumWithInitial);
      return;
      //return sumWithInitial
    }
    if (roDATA?.districtROData.length === 1) {
      const resourcedOrders = roDATA?.districtROData[0].totalResourceOrderCount;
      console.log("Resourced", resourcedOrders);
      setResourcedOrders(resourcedOrders);
      return;
    }
    setResourcedOrders(0);
    return null
  }

  //Calculate Inventory Accuracy
  const getInventoryAccuracy = () => {
    if (totalOrders > 0) {
      let inventoryAccuracy = parseFloat((((totalOrders - totalResourcedOrders) / totalOrders) * 100).toFixed(2));
      inventoryAccuracy = inventoryAccuracy > 100 ? 100 : inventoryAccuracy < 0 ? 0 : inventoryAccuracy;
      return inventoryAccuracy;
    }
    return 0;
  }

  const getCustomReasonName = (value: string) => {

    switch (value) {
      case "floorModelReasonCount":
        return "FLOOR MODEL"
      case 'blemishedReasonCount':
        return "BLEMISHED"
      case 'openBoxReasonCount':
        return "OPEN BOX"
      case 'damagedReasonCount':
        return "DAMAGED "
      case "missingPartsReasonCount":
        return "MISSING PARTS"
      case "demoReasonCount":
        return "DEMO"
      case "customerCommittedReasonCount":
        return "CUSTOMER COMITTED"
      case "cannotLocateReasonCount":
        return "CANNOT LOCATE"

      case "storeFloorModelReasonCount":
        return "FLOOR MODEL"
      case 'storeBlemishedReasonCount':
        return "BLEMISHED"
      case 'storeOpenBoxReasonCount':
        return "OPEN BOX"
      case 'storeDamagedReasonCount':
        return "DAMAGED"
      case "storeMissingPartsReasonCount":
        return "MISSING PARTS"
      case "storeDemoReasonCount":
        return "DEMO"
      case "storeCustomerCommittedReasonCount":
        return "CUSTOMER COMMITED"
      case "storeCannotLocateReasonCount":
        return "CANNOT LOCATE"

    }


  }

  const webView = () => {
    return (
      <div>
        {!Utils.isMobile ? (
          <SiteBreadCrumbs
            siteBreadCrumbs={Constants.SiteBreadCrumbs.Reports}
          ></SiteBreadCrumbs>
        ) : (
          <></>
        )}


        <Paper
          style={{
            paddingLeft: 3,
            paddingRight: 3,
            marginBottom: 10,
            paddingBottom: 10,
            marginTop: Utils.isMobile ? 8 : 0,
          }}
        >

          <Grid container>
            {/* <Grid item xs={12} sm={12}>
              <Typography
                style={{ marginTop: "8px", marginBottom: "8px" }}
                variant="h5"
                className="topheading"
              >
                Data Range
              </Typography>
            </Grid> */}
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={12}>
              <ButtonGroup variant="text" aria-label="text button group" style={{ display: Utils.isMobile ? "block" : "", marginTop: "10px" }}>
                <Button
                  onClick={() => {
                    onChangeRangeType("Y");
                  }}
                  className={checkActiveDataRange("Y") ? "btn" : ""}
                  variant={checkActiveDataRange("Y") ? "contained" : "outlined"}
                >
                  Yesterday
                </Button>
                <Button
                  onClick={() => {
                    onChangeRangeType("M");
                  }}
                  className={checkActiveDataRange("M") ? "btn" : ""}
                  variant={
                    checkActiveDataRange("M") ? "contained" : "outlined"
                  }
                >
                  MTD
                </Button>
                <Button
                  onClick={() => {
                    onChangeRangeType("T");
                  }}
                  className={checkActiveDataRange("T") ? "btn" : ""}
                  variant={checkActiveDataRange("T") ? "contained" : "outlined"}
                >
                  Today
                </Button>
                <Button
                  onClick={() => {
                    onChangeRangeType("7");
                  }}
                  className={checkActiveDataRange("7") ? "btn" : ""}
                  variant={checkActiveDataRange("7") ? "contained" : "outlined"}
                >
                  Last 7 days
                </Button>
                <Button
                  onClick={() => {
                    onChangeRangeType("15");
                  }}
                  className={checkActiveDataRange("15") ? "btn" : ""}
                  variant={
                    checkActiveDataRange("15") ? "contained" : "outlined"
                  }
                >
                  15 days
                </Button>
                <Button
                  onClick={() => {
                    onChangeRangeType("30");
                  }}
                  className={checkActiveDataRange("30") ? "btn" : ""}
                  variant={
                    checkActiveDataRange("30") ? "contained" : "outlined"
                  }
                >
                  30 days
                </Button>
                {/* <Button
                  onClick={() => {
                    onChangeRangeType("60");
                  }}
                  className={checkActiveDataRange("60") ? "btn" : ""}
                  variant={
                    checkActiveDataRange("60") ? "contained" : "outlined"
                  }
                >
                  60 days
                </Button> */}
                {/* <Button
                  onClick={() => {
                    onChangeRangeType("90");
                  }}
                  className={checkActiveDataRange("90") ? "btn" : ""}
                  variant={
                    checkActiveDataRange("90") ? "contained" : "outlined"
                  }
                >
                  90 days
                </Button> */}
                <Button
                  onClick={() => {
                    onChangeRangeType("C");
                  }}
                  className={checkActiveDataRange("C") ? "btn" : ""}
                  variant={
                    checkActiveDataRange("C") ? "contained" : "outlined"
                  }
                >
                  Custom Date
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
          {rangeType == 'C' &&
            <Grid container style={{ marginTop: "20px" }}>
              <Grid item xs={12} sm={12} style={{ display: 'flex', justifyContent: "center" }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="From"
                    inputFormat="MM-dd-yyyy"
                    mask="__-__-____"
                    value={customFromDate}
                    maxDate={moment().toDate()}
                    onChange={(newValue: any) => {
                      onChangeFromDate(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} sx={{ width: "200px", marginLeft: "2px" }} />}
                  />

                </LocalizationProvider>
                <Typography
                  style={{ margin: "8px" }}>
                  To
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="To"
                    inputFormat="MM-dd-yyyy"
                    mask="__-__-____"
                    value={customToDate}
                    minDate={customFromDate}

                    onChange={(newValue: any) => {
                      onChangeToDate(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} sx={{ width: "200px" }} />}
                  />

                </LocalizationProvider>
                <Button
                  style={{ marginLeft: "5px" }}
                  disabled={disableApplyBtn}
                  onClick={() => {
                    handleApplyCustomDate();
                  }}
                  className={disableApplyBtn ? "disableClass btn" : "btn"}
                  variant={
                    checkActiveDataRange("C") ? "contained" : "outlined"
                  }
                >
                  Apply
                </Button>
              </Grid>
              <Grid item xs={12} sm={12} style={{ display: 'flex', justifyContent: "center" }}>
                {disableApplyBtn && <p style={{ color: "#D93A2F" }}>*Please select a 30-day date range</p>}
              </Grid>
            </Grid>}
        </Paper>
        <Grid container>
          <Grid item xs={12} sm={12} style={{ paddingTop: "2px" }}>
            <Tiles
              bopiCount={bopiCount}
              bopiCountValue={bopiCountValue}
              bopiUsedCount={bopiUsedCount}
              bopiUsedCountValue={bopiUsedCountValue}
              stsCount={stsCount}
              stsCountValue={stsCountValue}
              stsUsedCount={stsUsedCount}
              stsUsedCountValue={stsUsedCountValue}
              sfsCount={sfsCount}
              sfsCountValue={sfsCountValue}
              sfsUsedCount={sfsUsedCount}
              sfsUsedCountValue={sfsUsedCountValue}
              dateFilter={dateFilter} isccDataLoaded={isccDataLoaded} isShippingDataLoaded={isShippingDataLoaded} isFulfilledOrdersLoaded={isFulfilledOrdersLoaded} ccData={ccData} fulfilledData={fulfilledData} shippingData={shippingData} released={totalReleased} pickedUp={totalPickedUp} usedReleased={usedReleased}></Tiles>
          </Grid>
        </Grid>
        <Grid container>
          {/* {Inventory Accuracy} */}
          <Grid item xs={12} sm={4} style={{ padding: "3px" }}>
            <Card className='dCard' style={{ height: "400px" }}>
              <CardContent className='dCardContent' style={{ padding: 0 }}>
                <div className='dCardLeftHeader' style={{ display: "inherit" }}>
                  <div onClick={() => { setGridPopUp(true) }} style={{ cursor: "pointer", marginLeft: "auto" }}>
                    <ZoomOutMapIcon style={{ float: "right", padding: "5px", width: "20px", color: Constants.Colors.grey }} />
                  </div>
                </div>
                <div className='dCardHeader'>
                  <div className='header'>
                    <Typography variant="h5" gutterBottom className='number' style={{ marginTop: 35 }}>
                      {"Fulfillment Rate "}
                      {/* {isOrderAccuracyLoaded && isroDataLoaded ? `${getInventoryAccuracy()}%` : "0%"} */}
                    </Typography>
                  </div>
                </div>
                <Divider />


                {(!isOrderAccuracyLoaded || !isroDataLoaded) && <ChartShimmer></ChartShimmer>}
                <ResponsiveContainer height={172}>
                  {(isOrderAccuracyLoaded && isroDataLoaded && totalOrders > 0) ? <div id="gauge-chart" style={{ marginTop: "10px" }}> <GaugeChart id="gauge-chart"
                    nrOfLevels={420}
                    arcsLength={[0.3, 0.4, 0.3]}
                    colors={['#EA4228', '#F5CD19', '#5BE12C',]}
                    arcPadding={0.02}
                    textColor="#000000"
                    percent={(getInventoryAccuracy() / 100)}
                  /></div> : isOrderAccuracyLoaded && isroDataLoaded && (ROData?.districtROData?.length) == 0 ?
                    <DataNotFound></DataNotFound>
                    :
                    <></>}
                </ResponsiveContainer>
                {(isOrderAccuracyLoaded && isroDataLoaded && totalResourcedOrders > 0) ?

                  <Grid container id="" style={{ marginTop: "", textAlign: "center", height: "140px" }}>
                    <Grid item sm={12} xs={12} style={{ margin: "auto", backgroundColor: "#5A5A5A" }}>
                      <div style={{ color: "white", fontWeight: 500, padding: "10px" }}> <b>TOTAL LINES RESOURCED</b>  - <b>{totalResourcedOrders}</b> </div>
                    </Grid>
                    <Grid item sm={12} xs={12}>

                      <div style={{ backgroundColor: "#EEEEEE", justifyContent: "center" }}>
                        <span style={{ fontWeight: "100" }}> <u style={{ margin: "7px", display: "inline-block" }}>TOP RESOURCED REASONS</u>  </span>
                      </div>
                      <div style={{ backgroundColor: "#EEEEEE" }}>
                        {Object.keys(topThreelineResourceReason).map((item, i) => (
                          <div style={{ color: "#676767" }}> <span style={{ textAlign: "left" }}>{getCustomReasonName(Object.keys(topThreelineResourceReason)[i])}</span>  {'-'}  <span > {Object.values(topThreelineResourceReason)[i]} </span>  </div>
                        ))}
                      </div>
                    </Grid>


                    {/* <Grid container sm={12} xs={12} >
                      <Grid item sm={8} xs={8} style={{ textAlign: "right" }} >
                        TOTAL LINES RESOURCED
                      </Grid>

                      <Grid item sm={4} xs={6} md={4}>
                        <b>{getTotalResourcedOrder()}</b>
                      </Grid>

                    </Grid>

                    <Grid container sm={12} xs={12}  >
                      {Object.keys(topThreelineResourceReason).map((item, i) => (
                        <>
                          <Grid item sm={8} xs={8} style={{ textAlign: "center" }} > {getCustomReasonName(Object.keys(topThreelineResourceReason)[i])}  </Grid>

                          <Grid item sm={4} xs={4} style={{ fontWeight: "bold" }}  >   {Object.values(topThreelineResourceReason)[i]} </Grid>
                        </>
                      ))}
                    </Grid> */}

                    {/* <Grid item sm={12} xs={12}>
                      <div>
                        {Object.keys(topThreelineResourceReason).map((item, i) => (
                          <div style={{ color: "#676767", fontWeight: 500, textAlign: "left" }}> {getCustomReasonName(Object.keys(topThreelineResourceReason)[i])} {'-'}  {Object.values(topThreelineResourceReason)[i]}  </div>
                        ))}

                      </div>
                    </Grid> */}
                  </Grid> : ""}






              </CardContent>
            </Card>
          </Grid>

          {/* {Carrier Services} */}
          <Grid item xs={12} sm={4} style={{ padding: "3px" }}>
            <Card className='dCard' style={{ height: "400px" }}>
              <CardContent className='dCardContent' >
                <div className='dCardHeader'>
                  <div className='header'>
                    <Typography variant="h5" gutterBottom className='number'>
                      {"Carrier Services"}
                    </Typography>
                  </div>
                </div>
                <Divider />
                {!isShippingDataLoaded && <ChartShimmer></ChartShimmer>}

                {isShippingDataLoaded && <ServiceTrend isLoaded={isShippingDataLoaded} data={shippingData}></ServiceTrend>}
              </CardContent>
            </Card>
          </Grid>

          {/* {Customer Response Trend} */}
          <Grid item xs={12} sm={4} style={{ padding: "3px" }}>
            <Card className='dCard' style={{ height: "400px" }}>
              <CardContent className='dCardContent' >
                <div className='dCardHeader'>
                  <div className='header'>
                    <Typography variant="h5" gutterBottom className='number'>
                      {"Customer Response Trend"}
                    </Typography>
                  </div>
                </div>
                <Divider />
                {!isccDataLoaded && <ChartShimmer></ChartShimmer>}

                {isccDataLoaded && <CustomerResponseTrend isLoaded={isccDataLoaded} data={ccData}></CustomerResponseTrend>}
              </CardContent>
            </Card>
          </Grid>

        </Grid>

        {/* Order Processing Time */}
        {
          IdentityManager.showOrderProcessTime ?
            <OrderProcessingTime isLoaded={isOrderProcessingTimeLoaded} data={orderProcessingData}></OrderProcessingTime>
            :
            <></>
        }



        {/* Shipped and pickup store */}
        <Grid container style={{ paddingTop: "5px" }}>
          <Grid item xs={12} sm={12}>
            <Card className='dCard'>
              <CardContent className='dCardContent'>
                <Grid item xs={12} sm={12} style={{ height: 30, float: Utils.isMobile ? "none" : "right" }}>

                  <ButtonGroup variant="text" aria-label="text button group" style={{ display: Utils.isMobile ? "block" : "" }}>
                    <Button
                      onClick={() => {
                        onChangeDataType("0");
                      }}
                      className={checkActiveDataType("0") ? "" : ""}
                      variant={checkActiveDataType("0") ? "contained" : "outlined"}
                      style={{ marginTop: "0px !important" }}
                    >
                      Hourly
                    </Button>
                    <Button
                      onClick={() => {
                        onChangeDataType("1");
                      }}
                      className={checkActiveDataType("1") ? "" : ""}
                      variant={checkActiveDataType("1") ? "contained" : "outlined"}
                      style={{ marginTop: "0px !important" }}
                    >
                      Daily
                    </Button>
                    <Button
                      onClick={() => {
                        onChangeDataType("2");
                      }}
                      className={checkActiveDataType("2") ? "" : ""}
                      variant={checkActiveDataType("2") ? "contained" : "outlined"}
                      style={{ marginTop: "0px !important" }}
                    >
                      Weekly
                    </Button>
                    {/* <Button
                      onClick={() => {
                        onChangeDataType("3");
                      }}
                      className={checkActiveDataType("3") ? "" : ""}
                      variant={checkActiveDataType("3") ? "contained" : "outlined"}
                      style={{ marginTop: "0px !important" }}
                    >
                      {" "}
                      Monthly
                    </Button> */}
                  </ButtonGroup>
                </Grid>
                <Grid item xs={12} sm={12} >

                  <ShippedPickupInStoreReport isLoaded={isShippedPickupLoaded} data={shippedPickupData} isLoadedExpedite={isExpeditedLoaded} dataExpedite={expeditedData}></ShippedPickupInStoreReport>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>


      </div >
    );
  };

  const getExpeditedData = async () => {

    let storeId = IdentityManager.getMultipleStoresId(props.store);
    if (storeId || isAllEnterPriseChecked) {
      let payload: IReportPayload = {
        store: storeId,
        timeZone: moment().format('Z'),
        startDate: dateFilter.start,
        endDate: dateFilter.end,
        dataType: dataType,
        isAllEnterPriseChecked: isAllEnterPriseChecked
      };
      setExpeditedLoaded(false);
      await ReportManager.getExpeditesReport(payload)
        .then((result) => {

          if (result.success && result?.data?.aggregations?.orders_over_time?.buckets) {
            let buckets: any[] = result?.data?.aggregations?.orders_over_time?.buckets;
            setExpeditedLoaded(true);
            let data: any = {};
            if (dataType == '0') {
              data = {
                expeditedData: transformHourlyData(buckets),
                dataType: dataType
              }
            }
            else {
              data = {
                expeditedData: buckets,
                dataType: dataType
              }
            }

            setExpediteData(data)
          }
          hideActionLoader()
        })
        .catch((error) => {
          console.log(error);
          hideActionLoader()
        });
    }
    else {
      setExpeditedLoaded(true);
      hideActionLoader()
    }
  };

  const getShippedAndPickupInStoreReport = async () => {

    let storeId = IdentityManager.getMultipleStoresId(props.store);
    if (storeId || isAllEnterPriseChecked) {
      let payload: IReportPayload = {
        store: storeId,
        timeZone: moment().format('Z'),
        startDate: dateFilter.start,
        endDate: dateFilter.end,
        dataType: dataType,
        isAllEnterPriseChecked: isAllEnterPriseChecked
      };
      setShippedPickupLoaded(false);
      await ReportManager.getShippedAndPickupInStoreReport(payload)
        .then((result) => {
          if (result?.success
            && result?.data?.shippedOrders?.hits?.total
            && result?.data?.pickUpInStoreOrders?.hits?.total
            && result?.data?.shippedOrders?.aggregations?.orders_over_time?.buckets
            && result?.data?.pickUpInStoreOrders?.aggregations?.orders_over_time?.buckets
            && result?.data?.totalOrders?.hits?.total
            && result?.data?.totalOrders?.aggregations?.orders_over_time?.buckets
            && result?.data?.totalOrders?.aggregations?.BOPIS?.doc_count
            && result?.data?.totalOrders?.aggregations?.SFS?.doc_count


          ) {
            // BOPI Variables
            setBopiCount(result?.data?.totalOrders?.aggregations?.BOPIS?.doc_count)
            setBopiCountValue(result?.data?.totalOrders?.aggregations?.BOPIS?.BOPISValueSum?.value)
            setBopiUsedCount(result?.data?.totalOrders?.aggregations?.BOPIS?.usedOrdersCount?.value)
            setBopiUsedCountValue(result?.data?.totalOrders?.aggregations?.BOPIS?.usedOrdersValueSum?.value)

            //  STS BOPIS sub section
            setStsCount(result?.data?.totalOrders?.aggregations?.STS?.doc_count)
            setStsCountValue(result?.data?.totalOrders?.aggregations?.STS?.BOPISValueSum?.value)
            setStsUsedCount(result?.data?.totalOrders?.aggregations?.STS?.usedOrdersCount?.value)
            setStsUsedCountValue(result?.data?.totalOrders?.aggregations?.STS?.usedOrdersValueSum?.value)

            // SFS Variables
            setSfsCount(result?.data?.totalOrders?.aggregations?.SFS?.doc_count)
            setSfsCountValue(result?.data?.totalOrders?.aggregations?.SFS?.SFSValueSum?.value)
            setSfsUsedCount(result?.data?.totalOrders?.aggregations?.SFS?.usedOrdersCount?.value)
            setSfsUsedCountValue(result?.data?.totalOrders?.aggregations?.SFS?.usedOrdersValueSum?.value)

            //Statistics Data
            let shippedHits: any = result?.data?.shippedOrders?.hits;
            let pickUpInStoreHits: any = result?.data?.pickUpInStoreOrders?.hits;
            // Total Amount
            //let shippedTotal: any = result?.data?.shippedOrders?.aggregations?.TotalAmount?.value;
            let pickupTotal: any = result?.data?.pickUpInStoreOrders?.aggregations?.TotalAmount?.value;
            // Hits Total
            // let shippedHitsTotal: any = result?.data?.shippedOrders?.aggregations?.TotalRecords?.buckets.reduce((accumulator: any, obj: any) => {
            //   return accumulator + Number(obj.doc_count ? obj.doc_count : "0");
            // }, 0);

            let pickupHitsTotal: any = result?.data?.pickUpInStoreOrders?.aggregations?.TotalRecords?.buckets.reduce((accumulator: any, obj: any) => {
              return accumulator + Number(obj.doc_count ? obj.doc_count : "0");
            }, 0);

            let totalCustomerPickedUp: any = result.data.pickUpInStoreOrders.aggregations.orders_over_time.buckets.reduce((accumulator: any, curr: any) => {
              return accumulator + curr.doc_count;
            }, 0);

            let totalReleasedOrders: any = result.data.totalOrders.aggregations.orders_over_time.buckets.reduce((accumulator: any, curr: any) => {
              return accumulator + curr.doc_count;
            }, 0);

            setReleased(totalReleasedOrders);
            setPickedUp(totalCustomerPickedUp);
            // setUsedReleased(result?.data?.totalOrders?.aggregations?.usedOrdersSum?.value);
            let shippedbuckets: any[] = result?.data?.shippedOrders?.aggregations?.orders_over_time?.buckets;
            let pickUpInStorebuckets: any[] = result?.data?.pickUpInStoreOrders?.aggregations?.orders_over_time?.buckets;
            let totalBuckets: any[] = result?.data?.totalOrders?.aggregations?.orders_over_time?.buckets;
            setShippedPickupLoaded(true);
            let data: any = {};
            if (dataType == '0') {
              data = {
                shippedHits: shippedHits,
                pickUpInStoreHits: pickUpInStoreHits,
                //shippedTotalAmountValue: shippedTotal,
                pickupTotalAmountDataValue: pickupTotal,
                //shippedHitsTotal: shippedHitsTotal,
                pickupHitsTotal: pickupHitsTotal,
                shipped: transformHourlyData(shippedbuckets),
                pickUpInStore: transformHourlyData(pickUpInStorebuckets),
                total: transformHourlyData(totalBuckets),
                dataType: dataType
              }

            }
            else {
              data = {
                shippedHits: shippedHits,
                pickUpInStoreHits: pickUpInStoreHits,
                //shippedTotalAmountValue: shippedTotal,
                pickupTotalAmountValue: pickupTotal,
                //shippedHitsTotal: shippedHitsTotal,
                pickupHitsTotal: pickupHitsTotal,
                shipped: shippedbuckets,
                pickUpInStore: pickUpInStorebuckets,
                total: totalBuckets,
                dataType: dataType
              }
            }
            hideActionLoader()
            setShippedPickupData(data);
          }
        })
        .catch((error) => {
          hideActionLoader()
          console.log(error);
        });
    }
    else {
      hideActionLoader()
      setShippedPickupLoaded(true);
    }
  };

  const transformHourlyData = (data: any) => {
    let result: any = distinctSum(data);
    result.forEach((element: any) => {
      element["convertedTime"] = Utils.timeConversion(element.key_as_string)
    })
    return result;
  }

  const distinctSum = (data: any) => {
    const result = Object.values(
      data.reduce((acc: any, item: any) => {
        acc[item.key_as_string] = acc[item.key_as_string]
          ? { ...item, doc_count: item.doc_count + acc[item.key_as_string].doc_count }
          : item;
        return acc;
      }, {})
    );
    return result.filter((a: any) => a.doc_count > 0);
  }


  const getContactCustomerReportForFulfillment = async () => {
    let storeId = IdentityManager.getMultipleStoresId(props.store);
    if (storeId || isAllEnterPriseChecked) {
      let payload: IReportPayload = {
        store: storeId,
        timeZone: moment().format('Z'),
        startDate: dateFilter.start,
        endDate: dateFilter.end,
        dataType: dataType,
        isAllEnterPriseChecked: isAllEnterPriseChecked
      };
      setCCDataLoaded(false);
      await ReportManager.getCustomerContactedReport(payload)
        .then((result) => {
          if (result.success && result.data?.aggregations?.StoresAggs?.buckets) {
            const _filteredStores = stores.filter((store: any) => storeId.includes(store.id));
            let mappedCCData = Utils.mapCCData(result.data.aggregations.StoresAggs.buckets, _filteredStores, districts);
            setCCDataLoaded(true);
            setCCData(mappedCCData);
            hideActionLoader()

          }
        })
        .catch((error) => {
          setCCDataLoaded(true);
          hideActionLoader()
          console.log(error);
        });

    }
    else {
      setCCDataLoaded(true);
      hideActionLoader()
    }
  }

  const getResourceOrderReportForFulfillment = async () => {
    let storeId = IdentityManager.getMultipleStoresId(props.store);
    if (storeId || isAllEnterPriseChecked) {
      let payload: IReportPayload = {
        store: storeId,
        timeZone: moment().format('Z'),
        startDate: dateFilter.start,
        endDate: dateFilter.end,
        dataType: dataType,
        isAllEnterPriseChecked: isAllEnterPriseChecked
      };
      setRODataLoaded(false);
      await ReportManager.getResourcedOrdersReport(payload)
        .then((result) => {
          if (result.success && result.data?.aggregations?.resourceCountByStore?.buckets) {
            const _filteredStores = stores.filter((store: any) => storeId.includes(store.id));
            let mappedROData = Utils.mapROData(result.data.aggregations.resourceCountByStore.buckets, _filteredStores, districts);
            if (mappedROData.districtROData.length > 1) {

              let blemishedReasonCount = 0;
              let cannotLocateReasonCount = 0;
              let customerCommittedReasonCount = 0;
              let damagedReasonCount = 0;
              let demoReasonCount = 0;
              let floorModelReasonCount = 0;
              let missingPartsReasonCount = 0;
              let openBoxReasonCount = 0
              for (let i = 0; i < mappedROData.districtROData.length; i++) {
                blemishedReasonCount = blemishedReasonCount + mappedROData.districtROData[i].blemishedReasonCount;
                cannotLocateReasonCount = cannotLocateReasonCount + mappedROData.districtROData[i].cannotLocateReasonCount
                customerCommittedReasonCount = customerCommittedReasonCount + mappedROData.districtROData[i].customerCommittedReasonCount
                damagedReasonCount = damagedReasonCount + mappedROData.districtROData[i].damagedReasonCount
                demoReasonCount = demoReasonCount + mappedROData.districtROData[i].demoReasonCount
                floorModelReasonCount = floorModelReasonCount + mappedROData.districtROData[i].floorModelReasonCount
                missingPartsReasonCount = missingPartsReasonCount + mappedROData.districtROData[i].missingPartsReasonCount
                openBoxReasonCount = openBoxReasonCount + mappedROData.districtROData[i].openBoxReasonCount
              }
              setTopThreelineResourceReason(Utils.pickHighest({ blemishedReasonCount, cannotLocateReasonCount, customerCommittedReasonCount, damagedReasonCount, demoReasonCount, floorModelReasonCount, missingPartsReasonCount, openBoxReasonCount }, 4))

            }
            console.log(mappedROData, "appedROData")
            if (mappedROData.districtROData.length === 1) {
              let storeBlemishedReasonCount = 0;
              let storeCannotLocateReasonCount = 0;
              let storeCustomerCommittedReasonCount = 0;
              let storeDamagedReasonCount = 0;
              let storeDemoReasonCount = 0;
              let storeFloorModelReasonCount = 0;
              let storeMissingPartsReasonCount = 0;
              let storeOpenBoxReasonCount = 0
              for (let i = 0; i < mappedROData.districtROData[0].roStoresData.length; i++) {
                storeBlemishedReasonCount = storeBlemishedReasonCount + mappedROData.districtROData[0].roStoresData[i].storeBlemishedReasonCount;
                storeCannotLocateReasonCount = storeCannotLocateReasonCount + mappedROData.districtROData[0].roStoresData[i].storeCannotLocateReasonCount
                storeCustomerCommittedReasonCount = storeCustomerCommittedReasonCount + mappedROData.districtROData[0].roStoresData[i].storeCustomerCommittedReasonCount
                storeDamagedReasonCount = storeDamagedReasonCount + mappedROData.districtROData[0].roStoresData[i].storeDamagedReasonCount
                storeDemoReasonCount = storeDemoReasonCount + mappedROData.districtROData[0].roStoresData[i].storeDemoReasonCount
                storeFloorModelReasonCount = storeFloorModelReasonCount + mappedROData.districtROData[0].roStoresData[i].storeFloorModelReasonCount
                storeMissingPartsReasonCount = storeMissingPartsReasonCount + mappedROData.districtROData[0].roStoresData[i].storeMissingPartsReasonCount
                storeOpenBoxReasonCount = storeOpenBoxReasonCount + mappedROData.districtROData[0].roStoresData[i].storeOpenBoxReasonCount
              }
              setTopThreelineResourceReason(Utils.pickHighest({ storeBlemishedReasonCount, storeCannotLocateReasonCount, storeCustomerCommittedReasonCount, storeDamagedReasonCount, storeDemoReasonCount, storeFloorModelReasonCount, storeMissingPartsReasonCount, storeOpenBoxReasonCount }, 4))

            }
            setRODataLoaded(true);
            getTotalResourcedOrder(mappedROData);
            setROData(mappedROData);
            hideActionLoader()
          }
        })
        .catch((error) => {
          console.log(error);
        });

    }
    else {
      hideActionLoader()
      setRODataLoaded(true);
    }
  }

  const getFulfilledStat = async () => {
    let storeId = IdentityManager.getMultipleStoresId(props.store);
    if (storeId || isAllEnterPriseChecked) {
      let payload: IReportPayload = {
        store: storeId,
        timeZone: moment().format('Z'),
        startDate: dateFilter.start,
        endDate: dateFilter.end,
        dataType: dataType,
        isAllEnterPriseChecked: isAllEnterPriseChecked
      };
      setFulfilledOrdersLoaded(false);
      await ReportManager.getFulfilledOrdersHistoryData(payload)
        .then((result) => {
          if (result.success && result.data?.aggregations && result.data?.aggregations) {
            const _filteredStores = stores.filter((store: any) => storeId.includes(store.id));
            let mappedCCData = Utils.mapFulfillDataHistory(result.data, _filteredStores, districts);
            const totalOrders = mappedCCData.districtfulfillData.reduce((acc, current) => {
              return acc + (current.customerPickedupNumbers + current.shippedNumbers);
            }, 0)
            console.log("Total Orders", totalOrders);
            console.log("mappedCCData", mappedCCData);

            setTotalOrders(totalOrders);
            hideActionLoader()
            setFulfilledOrdersLoaded(true);
            setFulfilledData(mappedCCData);
          }
        })
        .catch((error) => {
          console.log(error);
        });

    }
    else {
      hideActionLoader()
      setFulfilledOrdersLoaded(true);
    }
  }
  const getShippingStat = async () => {
    let storeId = IdentityManager.getMultipleStoresId(props.store);
    if (storeId || isAllEnterPriseChecked) {
      let payload: IReportPayload = {
        store: storeId,
        timeZone: moment().format('Z'),
        startDate: dateFilter.start,
        endDate: dateFilter.end,
        dataType: dataType,
        isAllEnterPriseChecked: isAllEnterPriseChecked
      };
      setIsShippingDataLoaded(false);
      await ReportManager.getShippingReport(payload)
        .then((result) => {
          if (result.success && result.data?.aggregations && result.data?.aggregations) {
            const _filteredStores = stores.filter((store: any) => storeId.includes(store.id));
            let mappedCCData = Utils.mapShippingData(result.data, _filteredStores, districts);
            console.log("mappedCCData", mappedCCData);

            setIsShippingDataLoaded(true);
            hideActionLoader()
            setshippingData(mappedCCData);
          }
        })
        .catch((error) => {
          setIsShippingDataLoaded(true);
          hideActionLoader()
          console.log(error);
        });

    }
    else {
      setIsShippingDataLoaded(true);
    }
  }

  const getOrderAccuracy = async () => {
    let storeId = IdentityManager.getMultipleStoresId(props.store);
    if (storeId || isAllEnterPriseChecked) {
      let payload: IReportPayload = {
        store: storeId,
        timeZone: moment().format('Z'),
        startDate: dateFilter.start,
        endDate: dateFilter.end,
        dataType: dataType,
        isAllEnterPriseChecked: isAllEnterPriseChecked
      };
      setOrderAccuracyLoaded(false);
      await ReportManager.getOrderAccuracy(payload)
        .then((result) => {
          //

          if (result?.data?.aggregations) {
            let totalResourceLines = result?.data?.aggregations.TotalResourceLines.value;
            let totalShipmentLines = result?.data?.aggregations.TotalShipLines.value;
            let accurateLines: any = parseFloat(((totalShipmentLines - totalResourceLines) / totalShipmentLines).toFixed(2));
            // console.log(accurateLines, "accurateLines");
            setOrderAccuracy(accurateLines);
            setOrderAccuracyLoaded(true);
            hideActionLoader()

          }


        })
        .catch((error) => {
          setOrderAccuracyLoaded(true);
          hideActionLoader()
          console.log(error);
        });
    }
    else {
      setOrderAccuracyLoaded(true);
    }

  }
  const getOrderProcessingTime = async () => {

    if (IdentityManager.showOrderProcessTime) {
      let storeId = IdentityManager.getMultipleStoresId(props.store);
      if (storeId || isAllEnterPriseChecked) {
        let payload = {
          // store: storeId,
          startDate: dateFilter.start,
          endDate: dateFilter.end
        };
        setOrderProcessingTimeLoaded(false);

        await ReportManager.getOrderProcessingTime(payload)
          .then((result) => {

            if (result.success && result.data.length > 0) {
              const _filteredStores = stores.filter((store: any) => storeId.includes(store.id));
              const mappedData = Utils.mapOrdersTimeData(result.data, _filteredStores, districts)
              setOrderProcessingData(mappedData);
            }
            if (result.success && result.data.length == 0) {
              setOrderProcessingData([]);
            }
            setOrderProcessingTimeLoaded(true);
            hideActionLoader()

          })
          .catch((error) => {
            setOrderProcessingTimeLoaded(true);
            hideActionLoader()
            console.log(error);
          });
      }
      else {
        setOrderProcessingTimeLoaded(true);
        hideActionLoader()
      }
    }
  }

  const getData = async () => {
    getExpeditedData();
    getShippedAndPickupInStoreReport();
    getContactCustomerReportForFulfillment();
    getShippingStat();
    getFulfilledStat();
    getResourceOrderReportForFulfillment();
    getOrderAccuracy();
    getOrderProcessingTime();
  };
  const dataTypeDependent = async () => {
    getExpeditedData();
    getShippedAndPickupInStoreReport();
  };

  React.useEffect(() => {
    dataTypeDependent()
  }, [dataType])

  React.useEffect(() => {
    getData();
  }, [props.store, dateFilter, isAllEnterPriseChecked]);

  // React.useEffect(() => {
  //   customDateRange();
  // }, [customFromDate, customToDate])
  return (
    <>
      <div className={Utils.isMobile ? "innerContianerMobile" : "innerContianer"}>
        {webView()}
      </div>
      {gridPopUp && <ResourceOrderDataGrid open={gridPopUp} close={closeGridPopup} isLoaded={isroDataLoaded} data={ROData} />}
    </>
    // <div className={Utils.isMobile ? "innerContianerMobile" : "innerContianer"}>
    //   {Utils.isMobile ? mobileView() : webView()}
    // </div>

  );
};

export default Reports;

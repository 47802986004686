import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { Box, Button, Card, CardContent, Checkbox, Divider, Drawer, FormHelperText, Grid, Paper, Stack, TextField, Toolbar, Tooltip, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import { useDispatch, useSelector, } from "react-redux";
import { IconButton } from "@mui/material";
import Utils from "../Common/Utils";
import Chip from "@mui/material/Chip";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import SiteBreadCrumbs from "../Subcomponent/Shared/SiteBreadCrumbs";
import Constants from "../Common/Constants";
import { VerticalLinearStepper } from "../Subcomponent/Stepper/VerticalLinearStepper";
import { IVerticalStepper, IStepOption } from "../Models/IVerticalStepper";
import { CancelIcon, DoneIcon, PlumbingIcon } from "../Subcomponent/Shared/SharedIcons";
import { Note } from "../Subcomponent/Shared/Note";
import { NotificationPopup } from "../Subcomponent/Shared/NotificationPopup";
import { INotificationPopupSettings, IButton } from "../Models/INotificationPopupSettings";
import LabelText from "../Common/LabelText";
import { BoxCharacteristics } from '../Subcomponent/PackOrder/AddBox';
import { FileCopyIcon, SaveIcon, PrintIcon, ShareIcon, AddCommentIcon, CloseIcon, ArrowForwardIosSharpIcon, StarPurple500Icon, CheckIcon, LocalShippingIcon, AddCircleIcon, DoDisturbOnIcon, TimelineIcon, PersonIcon, AddLocationAltIcon, LocationOnIcon, EmailIcon, LocalPhoneIcon, CommentIcon, ArrowBackIcon } from "../Subcomponent/Shared/SharedIcons"
import allActions from '../Store/Actions/AllActions';
import { Container, ContainerDetail, IKcdcDetail, ShipmentLine2 } from '../Models/IKcdcDetail';
import OMSManager from '../Managers/Oms.manager';
import OrderLineShimmer from '../Subcomponent/PickOrder/OrderLineShimmer';
import { ICustomerDetail } from '../Models/IPickOrder';
import OrderLineShimmerMobile from '../Subcomponent/PickOrder/OrderLineShimmerMobile';
import IBoxCharacteristic from '../Models/IBoxCharacteristic';
import StoreManager from '../Managers/Store.manager';
import OrderManager from '../Managers/Orders.Manager';
import PFUtils from '../Common/Process.Fulfillment.Utils';
import ApiService from '../Common/ApiService';
import IdentityManager from '../Managers/Identity.manager';
import { PDFHexString } from 'pdf-lib';
import PrintManager from '../Managers/Print.Manager';
import { executionAsyncResource } from 'async_hooks';
import { AddAdHoqTrackingNumbers } from '../Subcomponent/PackOrder/AddAdHoqTrackingNumbers';
import { KcdcPackPrintSlip } from '../Subcomponent/KcdsReturns/KcdcPackPrintSlip';
import { IImageViewerDetail, IImageViewerPopupSettings } from '../Models/IImageViewerPopupSettings';
import { ImageViewerPopup } from '../Subcomponent/Shared/ImageViewerPopup';

const actions = [
    { icon: <FileCopyIcon />, name: "Copy" },
    { icon: <SaveIcon />, name: "Save" },
    { icon: <PrintIcon />, name: "Print" },
    { icon: <ShareIcon />, name: "Share" },
];
let step: IStepOption[] = [];
step.push({
    label: "Order Release",
    description: ``,
    time: "Jan 20 2022 13:11:17 ",
});
step.push({
    label: "Customer Contacted",
    description: "dummy",
    time: "Jan 20 2022 13:11:17 ",
});
step.push({ label: "Picking", description: ``, time: "Jan 20 2022 13:11:17" });
step.push({ label: "Packing", description: ``, time: "" });
step.push({ label: "Shipped", description: ``, time: "" });
let verticalStepperOptions: IVerticalStepper = {
    activeStep: "Packing",
    stepsOptions: step,
};
const Accordion = styled((props: any) => (
    <MuiAccordion style={{ minHeight: "35px !important" }} disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem', color: Constants.Colors.white }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor: Constants.Colors.red,
    color: Constants.Colors.white,
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
        marginTop: 6,
        marginBottom: 6,
    },
}));
const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const KCDCReturnDetail = (props: any) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // state objects
    let orderDetail: IKcdcDetail = useSelector((state: any) => state.KcdcReducerState.orderDetail);
    let isShipDisabled = true;
    if (orderDetail && orderDetail?.Containers?.Container && orderDetail?.Containers?.Container.length > 0) {
        isShipDisabled = false
    }
    let selectedItems: ShipmentLine2[] = useSelector((state: any) => state.PackOrderReducersState.selectedItems);
    let isResidential: boolean = useSelector((state: any) => state.PackOrderReducersState.isResidential);
    const [kcdcIsResidential,setKcdcIsResidential] = useState(false);
    let shipDate: Date = useSelector((state: any) => state.PackOrderReducersState.shipDate);
    let boxCharacteristicList: IBoxCharacteristic[] = useSelector((state: any) => state.PackOrderReducersState.boxCharacteristicList);
    const [disableMultiBox, setdisableMultiBox] = useState(false)
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const shipmentkey = urlParams.get('shipmentkey');
    const queryParameterStore = urlParams.get('store');
    const [errorMsg, setErrorMsg] = useState("");
    const [isLoaded, setLoaded] = useState(false);
    const [isPrintPackOrder, setPrintPackOrder] = useState(0);
    const [openError, setOpenError] = useState(false);
    window.scrollTo(0, 0);
    const [value, setValue] = React.useState<Date | null>(new Date());
    const [openTrackingDrawer, setTrackingDrawer] = React.useState(false);
    const [openDrawer, setDrawer] = useState(false);
    const [openHistoryDrawer, setHistoryDrawer] = useState(false);
    const [notes, setNotes] = useState("");
    const [invcPopup, setInvcPopup] = useState(false);
    const [expandedPacked, setExpandedPacked] = React.useState(true);
    const [steps, setSteps] = useState<IVerticalStepper>(verticalStepperOptions);
    const [expandedUnPacked, setExpandedUnPacked] = React.useState(true);
    const [expanded, setExpanded] = React.useState('panel1');
    const [markInvItem, setMarkInvItem] = useState({} as ShipmentLine2);
    const [inputListADHoc, setInputListADHoc] = useState([{ trackingNo: "", error: false }]);
    const [isADHocPopup, setADHocPopup] = useState(false);
    const [imagePopUp, setImagePopUp] = useState(false);
    const [imageData, setImageData] = useState({} as IImageViewerDetail);
    const [navigationKCDC, setNavigationKCDC] = useState(false);

    const [shipDateError, setShipDateError] = React.useState(false);
    const [customerDetails, setCustomerDetails] = React.useState<ICustomerDetail>(
        {
            name: "",
            city: "",
            email: "",
            phone: "",
            zipCode: "",
            address: "",
            state: ""
        }
    );
    const handleChange = (panel: any) => (event: any, newExpanded: any) => {
        setExpanded(newExpanded ? panel : false);
    };

    const popUpBack = () => {
        navigate(-1)
    }
    const closeErrorPopup = () => {
        setOpenError(false)
    }
    const openErrorPopup = () => {
        setOpenError(true)
    }
    const printPackSlip = () => {
        setPrintPackOrder(isPrintPackOrder + 1)
    }

    const showActionLoader = () => {
        dispatch(allActions.actionLoaderActions.showLoader());
    }
    const hideActionLoader = () => {
        dispatch(allActions.actionLoaderActions.hideLoader());
    }

    const handleChangePacked = () => {
        setExpandedPacked(!expandedPacked);
    };
    const handleChangeUnPacked = () => {
        setExpandedUnPacked(!expandedUnPacked);
    };
    const printAll = (e: any) => {
        e.stopPropagation()
    };
    const voidAll = (e: any) => {
        e.stopPropagation()
    };

    const closeInvc = () => {
        setMarkInvItem({} as ShipmentLine2);
        setInvcPopup(false);
    };
    const openInvc = (item: ShipmentLine2) => {
        setMarkInvItem(item);
        setInvcPopup(true);
    };


    const openNavigationKCDC = () =>{
        setNavigationKCDC(true);
    }

    const navigateToKCDC = () => {
        navigate("/KCDCReturns");
    }

    let navigateToKCDCButtons: IButton[] = [
        {
            text: "To KCDC Returns List",
            icon: <DoneIcon />,
            action: navigateToKCDC,
            color: Constants.Colors.red,
        }
    ];

    let navigationToKCDC: INotificationPopupSettings = {
        open: navigationKCDC,
        type: Constants.NotificationPopupType.Info,
        title: "",
        msg: "This Order doesn't belong to selected Store.",
        draggable: false,
        handleClose: navigateToKCDC,
        actions: navigateToKCDCButtons
    }

    const markInvTrigger = async () => {
        if (markInvItem && markInvItem.ShipmentKey) {
            try {
                showActionLoader();
                let omsContainerResponse = await OMSManager.GCShipToDCMarkInv(markInvItem.ShipmentKey, markInvItem.ShipmentLineKey, (Number(markInvItem.QtyAvailableToPack)).toString());
                hideActionLoader();
                closeInvc();
                if (omsContainerResponse.success) {
                    if (omsContainerResponse.data.errors != undefined) {
                        setTrackingDrawer(false)
                        setErrorMsg(omsContainerResponse.data.errors[0]?.ErrorDescription);
                        hideActionLoader();
                        openErrorPopup();
                    }
                    else {
                        setMarkInvItem({} as ShipmentLine2);
                        setTrackingDrawer(false)
                        resetBoxSide();
                        getKcdcTotalQuantity();
                        loadData(true);
                        hideActionLoader();
                    }
                }
                else {
                    setTrackingDrawer(false)
                    setErrorMsg("Error While Creating Label");
                    hideActionLoader();
                    openErrorPopup();
                }
            }
            catch {
                setTrackingDrawer(false)
                setErrorMsg("Error While Creating Label");
                hideActionLoader();
                openErrorPopup();
            }
        }
    }

    let invcPopupButtons: IButton[] = [
        {
            text: "Mark Inv",
            icon: <DoneIcon />,
            action: markInvTrigger,
            color: Constants.Colors.yellow
        },
        {
            text: "Close",
            icon: <CloseIcon />,
            action: closeInvc,
            color: Constants.Colors.yellow
        }

    ];
    let notesCustomerPopup: INotificationPopupSettings = {
        open: invcPopup,
        type: Constants.NotificationPopupType.Warning,
        title: LabelText.NotificationPopupHeaders.Warning,
        msg: LabelText.MarkINVC.detail,
        handleClose: closeInvc,
        isCustomJSX: false,
        actions: invcPopupButtons,
    };

    var startProductBarPos = -1;
    window.onscroll = function () {
        console.log("scrolled");
        var bar = document.getElementById("bar") as any;
        var mblBar = document.getElementById("mblBar") as any;
        if (bar) {
            if (startProductBarPos < 0) startProductBarPos = findPosY(mblBar);

            if (window.pageYOffset > startProductBarPos) {
                bar.classList.add("scrolledTopFix");
            } else {
                bar.classList.remove("scrolledTopFix");
            }
        }
    };
    function findPosY(obj: any) {
        var curtop = 0;
        if (obj && typeof obj.offsetParent != "undefined" && obj.offsetParent) {
            while (obj.offsetParent) {
                curtop += obj.offsetTop;
                obj = obj.offsetParent;
            }
            curtop += obj.offsetTop;
        } else if (obj && obj.y) curtop += obj.y;
        return curtop;
    }

    const openADHocPopup = () => {
        setInputListADHoc([{ trackingNo: "", error: false }]);
        setADHocPopup(true);
    }

    const closeADHocPopup = () => {
        setADHocPopup(false);
    }

    const createContainerFromADHocTrackingNumbers = () => {
        const list = [...inputListADHoc];
        let anyError = false
        list.forEach((item) => {
            if (item.trackingNo == "") {
                item.error = true
                anyError = true
            }
        })
        setInputListADHoc(list);
        if (!anyError) {
            let trackingNumberList = [] as any;
            trackingNumberList = list.map((item) => {
                return item.trackingNo
            })
            createShipmentADHoc(trackingNumberList)
        }
    }

    const createShipmentADHoc = async (trackingNumbers: any) => {
        setADHocPopup(false);
        showActionLoader();
        let shipmentKey = orderDetail.ShipmentKey;

        //one/multiple items data
        let shipmentLineKey = [] as string[];
        let orderLineKey = [] as string[];
        let qtys = [] as string[];

        //loop over selected items to get ShipmentLineKey and OrderLineKey 
        selectedItems.forEach((selectedItem: ShipmentLine2) => {

            shipmentLineKey.push(selectedItem.ShipmentLineKey)
            orderLineKey.push(selectedItem.OrderLineKey);
            qtys.push((Number(selectedItem.toPackInput)).toString());

        });
        try {
            let omsContainerResponse = await OMSManager.GCShipToDCUpdateTrackingNo(shipmentKey, shipmentLineKey, trackingNumbers, orderLineKey, qtys);
            if (omsContainerResponse.success == true) {
                if (omsContainerResponse.data.errors != undefined) {
                    setErrorMsg(omsContainerResponse.data.errors[0]?.ErrorDescription);
                    hideActionLoader();
                    openErrorPopup();
                }
                else {
                    resetBoxSide()
                    loadData();
                    hideActionLoader();
                }
            }
            else {
                setErrorMsg("Error While Creating Label");
                hideActionLoader();
                openErrorPopup();
            }
        }
        catch {
            setErrorMsg("Error While Creating Label");
            hideActionLoader();
            openErrorPopup();
        }
    }

    const createShipment = async () => {

        //

        let error = checkBoxValidation();
        if (!error) {

            let storeId: any = IdentityManager.getStoreId(props.store);
            if (!storeId) {
                setErrorMsg("Please select a store to process the transaction");
                openErrorPopup();
                return;
            }
            // Procees with shipment
            showActionLoader();
            let trackingNumbers = [] as any;
            // same data accross one shipment
            let levelOfService = orderDetail.LevelOfService;
            let selectedStore = StoreManager.currentStore;
            let toAddress = orderDetail.ToAddress;
            let billToAddress = orderDetail.BillToAddress;
            let vopNumber = selectedItems.map(u => u.ExtnVPOReferenceNo).join(' ')
            let orderNo = vopNumber && vopNumber.trim().length > 0 ? vopNumber : selectedItems[0].OrderNo;
            let ShipmentKey = orderDetail.ShipmentKey;

            //one/multiple items data
            let ShipmentLineKey = [] as string[];
            let OrderLineKey = [] as string[];
            let qtys = [] as string[];

            //loop over selected items to get ShipmentLineKey and OrderLineKey 
            selectedItems.forEach((selectedItem: ShipmentLine2) => {

                ShipmentLineKey.push(selectedItem.ShipmentLineKey)
                OrderLineKey.push(selectedItem.OrderLineKey);
                qtys.push((Number(selectedItem.toPackInput)).toString());

            });

            toAddress.FirstName = "GUITARCENTER.COM (RETURNS)";
            toAddress.Company = "DOCK 107108";

            let createLabelpayloadList = [] as any;
            boxCharacteristicList.forEach(box => {
                let requestData = PFUtils.parseData_CreateShipment_exstore(ShipmentKey, orderNo, ShipmentLineKey, OrderLineKey, box, selectedStore, levelOfService, toAddress, billToAddress, shipDate, kcdcIsResidential, true)
                //
                if (requestData.success == true) {
                    let payload = requestData.data
                    createLabelpayloadList.push(payload);
                }
                else {
                    setTrackingDrawer(false)
                    setErrorMsg(requestData.msg)
                    hideActionLoader();
                    openErrorPopup();
                }
            });

            if (boxCharacteristicList.length == createLabelpayloadList.length) {
                // get response of all boxes created
                let response = await ApiService.post("/pf/sendRequest", createLabelpayloadList)
                if (response.success == true) {
                    for (const element of response.data) {
                        let result = element.response
                        if (result.success) {
                            if (result.printData) {
                                result.printData.forEach((r: any) => {
                                    PrintManager.sendPrinterRequest(r);
                                });
                            }
                            if (result.data.ERROR_CODE == "0") {
                                trackingNumbers.push(result.data.PACKAGES.PACKAGE.CARRIER_PACKAGE_REFERENCE) // make list of tracking numbers
                            }
                            else {
                                setTrackingDrawer(false)
                                setErrorMsg("Error While Creating Label")
                                hideActionLoader();
                                openErrorPopup();
                                break;
                            }
                        }
                        else {
                            setTrackingDrawer(false)
                            setErrorMsg(result.msg ? "Error While Creating Label: " + result.msg : "Error While Creating Label");
                            hideActionLoader();
                            openErrorPopup();
                            break;
                        }
                    }
                    // Logging for Create Label
                    //
                    Utils.writeSendRequestLogs(response, Constants.LOG_TYPES.CREATE_LABEL);


                    // number of boxes and tracking numbers are same then continue
                    if (trackingNumbers.length == boxCharacteristicList.length) {
                        try {
                            let omsContainerResponse = await OMSManager.GCShipToDCUpdateTrackingNo(ShipmentKey, ShipmentLineKey, trackingNumbers, OrderLineKey, qtys);
                            if (omsContainerResponse.success) {
                                if (omsContainerResponse.data.errors != undefined) {
                                    setTrackingDrawer(false)
                                    setErrorMsg(omsContainerResponse.data.errors[0]?.ErrorDescription);
                                    hideActionLoader();
                                    openErrorPopup();
                                }
                                else {
                                    setTrackingDrawer(false)
                                    resetBoxSide()
                                    loadData();
                                    hideActionLoader();
                                }
                            }
                            else {
                                setTrackingDrawer(false)
                                setErrorMsg("Error While Creating Label");
                                hideActionLoader();
                                openErrorPopup();
                            }
                        }
                        catch {
                            setTrackingDrawer(false)
                            setErrorMsg("Error While Creating Label");
                            hideActionLoader();
                            openErrorPopup();
                        }
                    }
                }
                else {
                    setTrackingDrawer(false)
                    setErrorMsg("Error While Creating Label " + response.msg)
                    hideActionLoader();
                    openErrorPopup();
                }
            }




        }

    }

    const checkBoxValidation = () => {
        let isError = false;
        boxCharacteristicList.forEach((box: any) => {
            let Error = {
                quantity: false,
                dimensions: false,
                weight: false,
                length: false,
                width: false,
                height: false
            }
            if (box.weight == "" || box.weight <= 0) {
                Error.weight = true
                isError = true
            }
            if (box.dimensions == "") {
                Error.dimensions = true
                isError = true
            }
            if (!box.length || box.length == "") {
                Error.length = true
                isError = true
            }
            if (!box.width || box.width == "") {
                Error.width = true
                isError = true
            }
            if (!box.height || box.height == "") {
                Error.height = true
                isError = true
            }
            let payload = {
                index: box.index,
                value: Error,
                key: "Error"
            }
            dispatch(allActions.packOrderActions.changeBoxCharacteristicByIndex(payload));
            if (shipDate == null || shipDate instanceof Date == false || isNaN(shipDate.valueOf())) {
                setShipDateError(true)
                isError = true
            }
            else {
                setShipDateError(false)
            }
        })
        return isError
    }

    const changeResidential = () => {
        let changeIsResidential = !kcdcIsResidential
        setKcdcIsResidential(changeIsResidential)
        //dispatch(allActions.packOrderActions.changeResidential(changeIsResidential));
    }
    const changeShipDate = (shipDate: any) => {
        dispatch(allActions.packOrderActions.changeShipDate(shipDate));
    }

    const resetBoxSide = () => {
        dispatch(allActions.packOrderActions.changeSelectedItems([]));
        dispatch(allActions.packOrderActions.initilizeBoxCharacteristicList(Constants.boxCharacteristic));
        if (kcdcIsResidential == true) {
            changeResidential();
        }
        changeShipDate(new Date());
    }

    const onChangeItemQty = (event: any, item: ShipmentLine2) => {
        let qtyValue = event.target.value;
        if (Number(qtyValue) < 1) {

            setShipLineToPackQty(item, "1");


        }
        else {
            if (Number(qtyValue) >= 1) {
                setShipLineToPackQty(item, qtyValue);
                resetBoxSide();
                setdisableMultiBox(true);

            }
            else {
                setdisableMultiBox(false);
            }
        }

    }


    const setShipLineToPackQty = (shipLine: ShipmentLine2, qtyValue: any) => {
        orderDetail.ShipmentLines.ShipmentLine.forEach((item: ShipmentLine2) => {
            //item.checked = false;
            if (item.ShipmentLineKey == shipLine.ShipmentLineKey) {
                item.toPackInput = qtyValue;
            }
        })

        dispatch(allActions.kcdcOrdersActions.loadKcdcOrderDetail(orderDetail));
    }

    const checkShipLine = (shipLineKey: any, checked: boolean) => {
        orderDetail.ShipmentLines.ShipmentLine.forEach((item: ShipmentLine2) => {
            if (item.ShipmentLineKey == shipLineKey) {
                item.checked = checked;
                dispatch(allActions.kcdcOrdersActions.loadKcdcOrderDetail(orderDetail));
            }
        })

        let multipleCheckCount: any = orderDetail.ShipmentLines.ShipmentLine.filter(a => a.checked).length > 1 ? true : false;;
        let greaterThanOneQtyCount: any = orderDetail.ShipmentLines.ShipmentLine.filter(a => Number(a.toPackInput) > 1).length > 1 ? true : false;

        if (multipleCheckCount || greaterThanOneQtyCount) {
            setdisableMultiBox(true);
            resetBoxSide();
        }
        else {
            setdisableMultiBox(false);
        }


        dispatch(allActions.packOrderActions.changeSelectedItems(orderDetail.ShipmentLines.ShipmentLine.filter(a => a.checked)));
    }

    const generateTrackingNotes = () => {
        return (
            <Paper
                style={{
                    padding: "5px",
                    color: "#D93A2F",
                    fontSize: "1rem",
                    marginTop: 4,
                }}
            >
                <Typography>
                    <StarPurple500Icon style={{ fontSize: 12, marginRight: 2 }} /><b>For multi-box item, select item, add <AddCircleIcon></AddCircleIcon> package detail(s) and click generate label</b>
                </Typography>
                <Typography>
                    <StarPurple500Icon style={{ fontSize: 12, marginRight: 2 }} />
                    <b>For single box multiple items, select all the items and click generate labels</b>
                </Typography>
            </Paper>
        );
    };
    const generateTrackingNotesWeb = () => {
        return (
            <Paper
                style={{
                    padding: "5px",
                    color: "#D93A2F",
                    fontSize: "1rem",
                    textAlign: "left",
                    paddingTop: 4,
                    backgroundColor: Constants.Colors.white,
                    paddingLeft: 2,
                    marginBottom: 4,
                    marginTop: 4,
                }}
            >
                <Typography>
                    <StarPurple500Icon style={{ fontSize: 12, marginRight: 2 }} /><b>For multi-box item, select item, add <AddCircleIcon></AddCircleIcon> package detail(s) and click generate label</b>
                </Typography>
                <Typography>
                    <StarPurple500Icon style={{ fontSize: 12, marginRight: 2 }} />
                    <b>For single box multiple items, select all the items and click generate labels</b>
                </Typography>
            </Paper>
        );
    };
    const generateTrackingView = () => {
        return (
            <div>
                <div className="backdrop">
                    <div className="title">
                        <Grid container>
                            <Grid
                                item
                                style={{ margin: "auto", textAlign: "left" }}
                                xs={10}
                                sm={10}
                            >
                                <Typography>Generate Label/Tracking</Typography>
                            </Grid>
                            <Grid item xs={2} sm={2} style={{ textAlign: "right" }}>
                                {Utils.isMobile ? (
                                    <IconButton
                                        onClick={() => {
                                            if (Utils.isMobile) {
                                                setTrackingDrawer(false);
                                            }
                                        }}
                                        style={{ color: "white" }}
                                    >
                                        <CancelIcon></CancelIcon>
                                    </IconButton>
                                ) : (
                                    ""
                                )}
                            </Grid>
                        </Grid>
                    </div>
                    <div
                        className="mainContainerPackOrderGenerateLabel"
                        style={{ padding: "5px" }}
                    >
                        {Utils.isMobile ? generateTrackingNotes() : ""}
                        {Utils.isMobile ? "" : generateTrackingNotesWeb()}
                        <div className={selectedItems.length == 0 ? "overlay" : ""} style={{ borderRadius: 4 }}>
                            <BoxCharacteristics isBundle={false} disableMultiBox={disableMultiBox}></BoxCharacteristics>
                            <Grid item xs={12} sm={12} className="inputField">
                                <Typography className={selectedItems.length > 0 ? "" : "disabledColor"}>Ship Date</Typography>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DesktopDatePicker
                                        disabled={selectedItems.length < 1 ? true : false}
                                        label=""
                                        value={shipDate}
                                        minDate={new Date()}
                                        onChange={(newValue: any) => {
                                            changeShipDate(newValue);
                                        }}
                                        renderInput={(params: any) => (
                                            <TextField
                                                InputLabelProps={{ shrink: false }}
                                                fullWidth
                                                label=""
                                                error={true}
                                                helperText={shipDateError ? <FormHelperText style={{ color: "#d32f2f", marginLeft: 0 }}>Invalid</FormHelperText> : ""}
                                                style={{ width: "100%" }}
                                                {...params}
                                            />
                                        )}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                className="inputField"
                                style={{ display: "flex" }}
                            >
                                <Grid container>
                                    <Grid item xs={6} sm={6} style={{ padding: "7px" }}>
                                        <Typography className={selectedItems.length > 0 ? "" : "disabledColor"} style={{ marginTop: "12px !important" }}>
                                            Residential
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} sm={6}>
                                        <Checkbox
                                            disabled={selectedItems.length > 0 ? false : true}
                                            checked={kcdcIsResidential}
                                            onChange={() => changeResidential()}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Button
                                disabled={selectedItems.length > 0 ? false : true}
                                onClick={() => createShipment()}
                                style={{ marginTop: "20px", width: "100%" }}
                                variant="contained"
                                className={selectedItems.length > 0 ? "btn" : "btn disabledColor"}
                                startIcon={<CheckIcon />}
                            >
                                Generate Label
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
        
    const closeImageViewer = () =>{
        setImagePopUp(false);
        setImageData({} as IImageViewerDetail);
    }

    let imageViewerSettings : IImageViewerPopupSettings = {
        open: imagePopUp,
        imageDetail: imageData,
        close: closeImageViewer
    }


    const imageViewer = (item:any) =>{
        let imageData : IImageViewerDetail = {
            imageURL: item.ImageUrl,
            itemDesc: item.ItemDesc,
            itemID: item.OrderLine?.ItemDetails?.Extn?.ExtnPOSItemID ?? "-",
            skuID: item.ItemID,
            price: item.UnitPrice,
            qty: item.Quantity
        }

        setImagePopUp(true);
        setImageData(imageData);
    }

    const renderWebShipmentLines = () => {

        if (isLoaded && orderDetail) {
            return <React.Fragment>
                {
                    orderDetail.ShipmentLines.ShipmentLine.map((item: ShipmentLine2) => {
                        let isalreadyCompletePacled = Number(item.QtyAvailableToPack) == 0 ? true : false;
                        if (isalreadyCompletePacled) {
                            return "";
                        }
                        else {
                            return <Grid container spacing={2} display={"flex"} style={{ background: Number(item.QtyAvailableToPack) == 0 ? "#eee" : "" }}>
                                <Grid item xs={12} sm={12} className='textLeft'>
                                    <Grid container spacing={2} style={{ margin: "0px" }} sx={{ flexGrow: 1 }}>
                                        <Grid item xs={2} sm={2} style={{ padding: "8px", borderRight: "1px solid #2f2d2d", display: "flex", justifyContent: "center", alignItems:"center" }}>
                                        {Utils.disableUserAction(props.store) && <Checkbox style={{ color: "#D93A2F", maxHeight: "40px" }}
                                                disabled={isalreadyCompletePacled}
                                                checked={item.checked}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => { checkShipLine(item.ShipmentLineKey, checked) }}
                                            />}
                                            <div className="image-zoom" onClick={() => imageViewer(item)} >
                                                <img style={{height: "auto", width: "100%"}} alt={""} src={Utils.convertToHttps(item.ImageUrl)}/>
                                            </div>
                                        </Grid>
                                        <Grid item xs={10} sm={10} style={{ padding: "8px", textAlign: "left" }}>
                                            <Typography style={{ fontSize: "15px", fontWeight: 500, color: "#D93A2F" }}>{item.ItemDesc}</Typography>
                                            <Grid container>
                                                <Grid item xs={6} sm={6}>
                                                    <Typography variant="body2" sx={{ fontSize: 15, marginTop: 1 }} gutterBottom>
                                                        Return Order # <b>{item.ExtnReturnOrderNo}</b>
                                                    </Typography>
                                                    <Typography variant="body2" sx={{ fontSize: 15, marginTop: 1 }} gutterBottom>
                                                        VPO Reference # <b>{item.ExtnVPOReferenceNo}</b>
                                                    </Typography>
                                                    <Typography variant="body2" sx={{ fontSize: 15 }} gutterBottom>
                                                        Brand #: <b>{item.ExtnBrandName}</b>
                                                    </Typography>
                                                    <Typography variant="body2" sx={{ fontSize: 15 }} gutterBottom>
                                                        Price: <b>$ {item.UnitPrice}</b>
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={4} sm={4}>
                                                    <Typography variant="body2" sx={{ fontSize: "18px", marginTop: 1, color: "green" }} gutterBottom>
                                                        {isalreadyCompletePacled ? <DoneIcon style={{ fontSize: "38px" }}></DoneIcon> : ""}
                                                    </Typography>

                                                    <Typography variant="body2" sx={{ fontSize: 15, marginTop: 1 }} gutterBottom>
                                                        QTY to Ship: <b>{Number(item.QtyAvailableToPack)}</b>
                                                    </Typography>
                                                    <div style={{ display: "flex" }}>
                                                        <Typography variant="body2" sx={{ fontSize: 15, marginTop: 1.5 }} gutterBottom>
                                                            QTY to Pack:
                                                        </Typography>
                                                        <TextField className="remove-buttons" id="order" onChange={(event: any) => { onChangeItemQty(event, item) }}
                                                            disabled={Number(item.QtyAvailableToPack) == 0 ? true : false} type={"number"}
                                                            defaultValue={Number(item.toPackInput)} value={Number(item.toPackInput)}
                                                            inputProps={{ style: { padding: 0 } }}
                                                            InputProps={{ inputProps: { min: 1, max: item.QtyAvailableToPack }, readOnly: false, style: { padding: "0px !important" } }}
                                                            style={{ marginLeft: "5px", width: "20%" }} />
                                                    </div>

                                                </Grid>
                                                {Utils.disableUserAction(props.store) && <Grid item xs={2} sm={2}>
                                                    <Typography variant="body2" sx={{ fontSize: 15 }} gutterBottom>
                                                        &#160;
                                                    </Typography>
                                                    <Button
                                                        disabled={Number(item.QtyAvailableToPack) == 0 ? true : false}
                                                        onClick={() => openInvc(item)}
                                                        variant="contained"
                                                        className={Number(item.QtyAvailableToPack) == 0 ? "btn disabledButton" : "btn"}
                                                        size="small"
                                                    >
                                                        Mark INVC
                                                    </Button>
                                                </Grid>}
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                </Grid>

                            </Grid>
                        }

                    })
                }
            </React.Fragment >

        }
        else {
            return <React.Fragment>
                <OrderLineShimmer></OrderLineShimmer>
            </React.Fragment>
        }

    }

    const getImageUrl = (lineKey: any) => {

        let url = "";
        let filter = orderDetail.ShipmentLines.ShipmentLine.filter(a => a.ShipmentLineKey == lineKey);
        if (filter && filter.length > 0) {
            url = filter[0].ImageUrl;
        }

        return Utils.convertToHttps(url);

    }
    const getVpoBrand = (lineKey: any) => {

        let vpo = "";
        let brand = "";
        let filter = orderDetail.ShipmentLines.ShipmentLine.filter(a => a.ShipmentLineKey == lineKey);
        if (filter && filter.length > 0) {
            vpo = filter[0].ExtnVPOReferenceNo;
            brand = filter[0].ExtnBrandName;
        }

        return { brand, vpo };

    }
    const getCustomerDetails = (data: IKcdcDetail) => {
        let initialCustomerDetail: ICustomerDetail = {
            name: "",
            city: "",
            email: "",
            phone: "",
            zipCode: "",
            address: "",
            state: ""
        }
        if (data.ToAddress != undefined) {
            try {

                if (initialCustomerDetail.name.trim() == "") {
                    initialCustomerDetail.name = data.BillToAddress.FirstName + " " + data.BillToAddress.LastName
                }

                if (initialCustomerDetail.phone.trim() == "") {
                    initialCustomerDetail.phone = data.BillToAddress.DayPhone
                }
                if (initialCustomerDetail.phone.trim() == "") {
                    initialCustomerDetail.phone = data.BillToAddress.MobilePhone
                }
                if (data.ToAddress.hasOwnProperty("City")) {
                    initialCustomerDetail.city = data.ToAddress.City
                }
                if (data.ToAddress.hasOwnProperty("ZipCode")) {
                    initialCustomerDetail.zipCode = data.ToAddress.ZipCode
                }
                if (data.ToAddress.hasOwnProperty("AddressLine1")) {
                    initialCustomerDetail.address = data.ToAddress.AddressLine1
                }
                if (data.ToAddress.hasOwnProperty("AddressLine2") && data.ToAddress.AddressLine2.trim() != "") {
                    initialCustomerDetail.address = initialCustomerDetail.address + ", " + data.ToAddress.AddressLine2
                }
                if (data.ToAddress.hasOwnProperty("State")) {
                    initialCustomerDetail.state = data.ToAddress.State
                }
            }
            catch {
                console.log("Error Getting Customer Detail")
            }

        }
        return initialCustomerDetail
    }
    const omsDeleteTrackingNumber = async (item: Container) => {
        let omsContainerResponse = await OMSManager.GCShipToDCDeleteTrackingNo(orderDetail.ShipNode.ShipNode, item.ShipmentKey, item.ShipmentContainerKey, item?.Extn?.ExtnMultiBoxGroupID);
        if (omsContainerResponse.success) {
            if (omsContainerResponse.data.errors != undefined) {
                setTrackingDrawer(false)
                let erroMessage = omsContainerResponse.data.errors[0]?.ErrorDescription;
                setErrorMsg(erroMessage && erroMessage.length > 0 ? erroMessage : "Error While Voiding Label");
                hideActionLoader();
                openErrorPopup();
            }
            else {

                loadData();
                hideActionLoader();
            }
        }
        else {
            setTrackingDrawer(false)
            setErrorMsg("Error While Voiding Label");
            hideActionLoader();
            openErrorPopup();
        }

    }
    const voidLabel = async (item: Container) => {
        // Void Label
        // ExtnMultiBoxGroupID find all tracking number related and void them
        showActionLoader();
        let trackingNumbers: any[] = item.TrackingNo.split(",");
        let adhoqTrackingNumbers: any[] = [];
        let zplData: any[] = [];
        if (trackingNumbers.length) {
            trackingNumbers.forEach((element: any) => {
                if (element && element.length > 0) {
                    adhoqTrackingNumbers.push(element);
                }
            });
            if (item?.Extn?.ExtnMultiBoxGroupID && item?.Extn?.ExtnMultiBoxGroupID != "") {
                orderDetail.Containers.Container.forEach((container: Container) => {
                    if (container?.Extn?.ExtnMultiBoxGroupID == item.Extn.ExtnMultiBoxGroupID) {
                        if (trackingNumbers.indexOf(container.TrackingNo) == -1) {
                            adhoqTrackingNumbers.push(container.TrackingNo)
                        }
                    }
                })
            }
            let response = await ApiService.post("/pf/voidAdhocTrackingData", adhoqTrackingNumbers)
            //if (response.success) {            
            if (response.success) {
                await omsDeleteTrackingNumber(item);
            }
            else {
                setErrorMsg("Error While Voiding Label");
                hideActionLoader();
                openErrorPopup();
            }
            // if (response.data && response.data.length > 0) {
            //     for (const element of response.data) {
            //         let result = element.response;
            //         if (result.success) {

            //             // delete the tracking numbers is OMS as well.
            //             await omsDeleteTrackingNumber(item);

            //         }
            //         else {
            //             setTrackingDrawer(false)
            //             setErrorMsg("Error While Voiding Label");
            //             hideActionLoader();
            //             openErrorPopup();
            //         }
            //     }
            // }
            // else {
            //     debugger
            //     if (response.success) {
            //         await omsDeleteTrackingNumber(item);
            //     }
            //     else {
            //         setErrorMsg("Error While Voiding Label");
            //         hideActionLoader();
            //         openErrorPopup();
            //     }


            // }

            //}

            Utils.writeSendRequestLogs(response, Constants.LOG_TYPES.REPRINT);


        }

    }

    const ShipIt = async () => {

        try {


            showActionLoader();
            let omsContainerResponse = await OMSManager.GCShipToDCShipConfirm(orderDetail.ShipmentKey, OMSManager.GetUserIdFromLocalStorage(), orderDetail.FulfillmentType);
            hideActionLoader();
            if (omsContainerResponse.success) {
                if (omsContainerResponse?.data?.errors != undefined) {
                    setTrackingDrawer(false)
                    let erroMessage = omsContainerResponse.data.errors[0]?.ErrorDescription;
                    setErrorMsg(erroMessage && erroMessage.length > 0 ? erroMessage : "Error Occured While Processing Ship Transaction");
                    hideActionLoader();
                    openErrorPopup();
                }
                else {

                    printPackSlip()
                    hideActionLoader();
                    navigate("/KCDCReturns?ship=1");
                }
            }
            else {
                hideActionLoader();
                navigate("/KCDCReturns?ship=1");
            }


        }
        catch (e) {

            setTrackingDrawer(false)
            setErrorMsg("Error Occured While Processing Ship Transaction");
            hideActionLoader();
            openErrorPopup();
        }

    }

    const printLabel = async (item: Container) => {

        // Print Label

        showActionLoader();
        let trackingNumbers: any[] = item.TrackingNo.split(",");
        let adhoqTrackingNumbers: any[] = [];
        let zplData: any[] = [];
        if (trackingNumbers.length) {
            trackingNumbers.forEach((element: any) => {
                if (element && element.length > 0) {
                    adhoqTrackingNumbers.push(element);
                }

            });
            let response = await ApiService.post("/pf/printAdhocTrackingData", adhoqTrackingNumbers)
            if (response.success) {
                if (response.data && response.data.length > 0) {
                    for (const element of response.data) {
                        let result = element.response;
                        if (result.success) {
                            if (result.data?.hits?.hits[0]?._source?.ServiceResponse?.BASE_64_PDF != undefined || result.data?.hits?.hits[0]?._source?.ServiceResponse?.BASE_64_PDF != "") {
                                result.printData.forEach((r: any) => {
                                    PrintManager.sendPrinterRequest(r)
                                    zplData.push(r)
                                });
                            }
                        }
                        else {
                            setTrackingDrawer(false)
                            setErrorMsg("Error While printing Label");
                            hideActionLoader();
                            openErrorPopup();
                        }
                    }
                }
                else {
                    setTrackingDrawer(false)
                    setErrorMsg("Error While printing Label");
                    hideActionLoader();
                    openErrorPopup();
                }

            }

            Utils.writeSendRequestLogs(response, Constants.LOG_TYPES.REPRINT);


        }
        await PrintManager.handlePrintDataDisplay(zplData);

        hideActionLoader();


    }
    const renderWebTrackingNumberAssociation = () => {

        if (isLoaded && orderDetail && orderDetail.Containers.Container && orderDetail.Containers.Container.length > 0) {
            return <React.Fragment>
                {
                    orderDetail.Containers.Container.map((item: Container, i: any) => {

                        return <div className="orderBoxDiv">
                            <Paper className="packOrderPackageHeaderPackChild">
                                <Grid container>
                                    <Grid item xs={6} sm={6}>
                                        <Typography variant="h6" className="primaryColor">
                                            Package {i + 1} of {orderDetail.Containers.Container.length}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} sm={6} style={{}}>
                                        <Box display="flex" justifyContent="flex-end" style={{ float: "right" }}>
                                            <Tooltip title="Print Label">
                                                <IconButton
                                                    style={{
                                                        color: "white",
                                                        paddingTop: 0,
                                                        paddingBottom: 0,
                                                    }}
                                                    onClick={() => { printLabel(item) }}
                                                >
                                                    <PrintIcon></PrintIcon>
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Void Label">
                                                <IconButton
                                                    style={{
                                                        color: "white",
                                                        paddingTop: 0,
                                                        paddingBottom: 0,
                                                    }}
                                                    onClick={() => { voidLabel(item) }}
                                                >
                                                    <DoDisturbOnIcon></DoDisturbOnIcon>
                                                </IconButton>
                                            </Tooltip>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Paper>
                            <div>
                                <Grid
                                    container
                                    className="headerRow"
                                    style={{ paddingLeft: 5, marginBottom: "10px" }}
                                >
                                    <Grid
                                        item
                                        xs={3}
                                        sm={3}
                                        style={{ paddingTop: "10px" }}
                                    >
                                        <Typography
                                            sx={{ fontSize: 15 }}
                                            color="text.secondary"
                                            gutterBottom
                                        >
                                            Item Description
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={6}
                                        sm={6}
                                        style={{ paddingTop: "10px" }}
                                    >
                                        <Grid container>
                                            <Grid item xs={4} sm={4}>
                                            </Grid>
                                            <Grid item xs={6} sm={6}>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={3}
                                        sm={3}
                                        style={{ paddingTop: "6px", paddingBottom: "6px" }}
                                    >
                                        <Grid item xs={10} sm={10} style={{}}>
                                            <Typography
                                                style={{ textAlign: "left", fontSize: "14px" }}
                                            >
                                                <Chip
                                                    className="chipTracking"
                                                    icon={<TimelineIcon />}
                                                    label={item.TrackingNo}
                                                    style={{
                                                        background: "#dc2b2b",
                                                        color: "white",
                                                    }}
                                                />
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Divider></Divider>
                                {item.ContainerDetails.ContainerDetail.map((ccdetail: ContainerDetail) => {

                                    return <React.Fragment>
                                        <Grid container spacing={2} display={"flex"}>
                                            <Grid item xs={11} sm={11} className='textLeft'>
                                                <Grid spacing={2} container style={{ margin: "0px" }} sx={{ flexGrow: 1 }}>
                                                    <Grid item xs={2} sm={2} style={{ padding: "8px", borderRight: "1px solid #2f2d2d", display: "flex", justifyContent: "center" }}>
                                                        <img style={{ height: "95px"}} alt="" src={getImageUrl(ccdetail.ShipmentLine.ShipmentLineKey)}/>
                                                    </Grid>
                                                    <Grid item xs={10} sm={10} style={{ padding: "8px", textAlign: "left" }}>
                                                        <Typography style={{ fontSize: "15px", fontWeight: 500, color: "#D93A2F" }}>{ccdetail.ShipmentLine.ItemDesc}</Typography>
                                                        <Grid container>
                                                            <Grid item xs={6} sm={6}>
                                                                <Typography variant="body2" sx={{ fontSize: 15, marginTop: 1 }} gutterBottom>
                                                                    Return Order #: <b>{ccdetail.ShipmentLine.OrderNo}</b>
                                                                </Typography>
                                                                <Typography variant="body2" sx={{ fontSize: 15, marginTop: 1 }} gutterBottom>
                                                                    VPO Reference # <b>{getVpoBrand(ccdetail.ShipmentLine.ShipmentLineKey).vpo}</b>
                                                                </Typography>
                                                                <Typography variant="body2" sx={{ fontSize: 15 }} gutterBottom>
                                                                    Brand #: <b>{getVpoBrand(ccdetail.ShipmentLine.ShipmentLineKey).brand}</b>
                                                                </Typography>

                                                            </Grid>
                                                            <Grid item xs={6} sm={6}>
                                                                <Typography variant="body2" sx={{ fontSize: 15, marginTop: 1 }} gutterBottom>
                                                                    QTY to Ship:<b>{ccdetail.ShipmentLine.Quantity}</b>
                                                                </Typography>
                                                                <Typography variant="body2" sx={{ fontSize: 15, marginTop: 1 }} gutterBottom>
                                                                    QTY Packed:<b>{ccdetail.Quantity}</b>
                                                                </Typography>

                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </React.Fragment>

                                })}

                            </div>
                        </div>

                    })
                }
            </React.Fragment>
        }
        else {
            if (isLoaded) {
                return <React.Fragment>
                    <Typography>
                        No data to display
                    </Typography>
                </React.Fragment>
            }
            else {
                return <React.Fragment>
                    <OrderLineShimmer></OrderLineShimmer>
                </React.Fragment>
            }

        }

    }
    const renderWebShipAddress = () => {

        if (isLoaded && orderDetail) {
            return <React.Fragment>
                <Grid
                    container
                    spacing={2}
                    display={"flex"}
                    style={{ padding: "10px" }}
                >
                    <Grid
                        item
                        xs={6}
                        sm={6}
                        className="textLeft"
                        style={{ display: "flex" }}
                    >
                        <LocationOnIcon />{" "}
                        <Typography
                            style={{
                                fontSize: "18px",
                                fontWeight: 500,
                                marginLeft: "10px",
                            }}
                        >
                            Shipping Address
                        </Typography>
                    </Grid>
                </Grid>
                <Grid
                    container
                    spacing={2}
                    display={"flex"}
                    style={{ padding: "10px" }}
                >
                    <Grid
                        item
                        xs={6}
                        sm={6}
                        className="textLeft"
                        style={{ display: "flex" }}
                    >
                        <Typography
                            style={{
                                fontSize: "16px",
                                fontWeight: 400,
                                marginLeft: "35px",
                            }}
                        >
                            {" "}
                            {customerDetails.address}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid
                    container
                    spacing={2}
                    display={"flex"}
                    style={{ padding: "10px" }}
                >
                    <Grid
                        item
                        xs={6}
                        sm={6}
                        className="textLeft"
                        style={{ display: "flex" }}
                    >
                        {" "}
                        <Typography
                            style={{
                                fontSize: "18px",
                                fontWeight: 400,
                                marginLeft: "35px",
                            }}
                        >
                            {customerDetails.city}, {customerDetails.state}, {customerDetails.zipCode}
                        </Typography>
                    </Grid>
                </Grid>
            </React.Fragment>


        }
        else {
            return <React.Fragment>
                <OrderLineShimmer></OrderLineShimmer>
            </React.Fragment>
        }

    }
    const webView = () => {

        return (
            <div>
                <SiteBreadCrumbs
                    siteBreadCrumbs={Constants.SiteBreadCrumbs.KCDCReturnDetails}
                ></SiteBreadCrumbs>
                <div style={{ margin: "5px" }}>
                    <Paper
                        className="paperToBottomPadding"
                        style={{ padding: "10px", marginBottom: "8px" }}
                    >
                        <Grid container spacing={2} display={"flex"}>
                            <Grid item xs={4} sm={4} className="textLeft">
                                <Typography
                                    variant="h6"
                                    style={{ padding: "0px" }}
                                    className="scanColor"
                                >
                                    Shipment # {orderDetail.ShipmentNo}
                                </Typography>
                            </Grid>
                            {Utils.disableUserAction(props.store) && <Grid item xs={8} sm={8} className="textRight">
                                <Button
                                    disabled={selectedItems.length ? false : true}
                                    className={selectedItems.length ? "btn " : "btn disabledButton"}
                                    variant="contained"
                                    startIcon={<PlumbingIcon />}
                                    onClick={() => openADHocPopup()}
                                    style={{ marginTop:1 }}
                                >
                                    Add Tracking # (Ad-hoc)
                                </Button>
                                <Button
                                    style={{ marginLeft: "5px",marginTop:1  }}
                                    startIcon={<PrintIcon />}
                                    disabled={isShipDisabled}
                                    variant="contained"
                                    className={isShipDisabled ? "btn disabledButton" : "btn"}
                                    onClick={() => { printPackSlip() }}
                                >
                                    Print Pack Slip
                                </Button>
                                <Button
                                    style={{ marginLeft: "5px",marginTop:1  }}
                                    startIcon={<LocalShippingIcon />}
                                    disabled={isShipDisabled}
                                    variant="contained"
                                    className={isShipDisabled ? "btn disabledButton" : "btn"}
                                    onClick={() => { ShipIt() }}
                                >
                                    Ship
                                </Button>
                            </Grid>}
                        </Grid>
                    </Paper>
                    <Paper style={{ marginTop: "26px" }}>
                        <Grid container spacing={2} display={"flex"}>
                            <Grid item xs={9} sm={9} style={{ paddingTop: "0px", paddingRight: "4px" }} className="textLeft">
                                <div className="backdrop">
                                    <div className="mainContainer" style={{ marginBottom: 5 }}>
                                        <div style={{ boxShadow: "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)" }}>
                                            <Accordion expanded={expandedPacked} onChange={() => handleChangePacked()}>
                                                <AccordionSummary aria-controls="panel1d-content">
                                                    <Typography>Item to Ship Back to DC</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails style={{ display: "block" }}>
                                                    <Grid
                                                        container
                                                        className="headerRow"
                                                        style={{ paddingLeft: 5, marginBottom: "10px" }}
                                                    >
                                                        <Grid item xs={3} sm={3} style={{}}>
                                                            <Typography
                                                                sx={{ fontSize: 15 }}
                                                                color="text.secondary"
                                                                gutterBottom
                                                            >
                                                                Item Description
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={7} sm={7} style={{}}>
                                                            <Grid container>
                                                                <Grid item xs={4} sm={4}>
                                                                    <Typography
                                                                        sx={{ fontSize: 14 }}
                                                                        color="text.secondary"
                                                                        gutterBottom
                                                                    >
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={4} sm={4}>
                                                                    <Typography
                                                                        sx={{ fontSize: 14 }}
                                                                        color="text.secondary"
                                                                        gutterBottom
                                                                    >
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={2}
                                                            sm={2}
                                                            style={{ textAlign: "right" }}
                                                        >
                                                        </Grid>
                                                    </Grid>
                                                    <Divider></Divider>
                                                    {
                                                        renderWebShipmentLines()
                                                    }

                                                </AccordionDetails>
                                            </Accordion>
                                        </div>
                                    </div>
                                </div>
                                <div className="backdrop">
                                    <div className="mainContainer" style={{ marginBottom: 5 }}>
                                        <Accordion expanded={expandedUnPacked} onChange={() => handleChangeUnPacked()}>
                                            <AccordionSummary aria-controls="panel1d-content">
                                                <Grid container>
                                                    <Grid item xs={4} sm={4}>
                                                        <Typography>
                                                            Tracking Number Associations
                                                        </Typography>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={4}
                                                        sm={4}
                                                        style={{ display: "flex", justifyContent: "center" }}
                                                    >
                                                        {/* <Stack direction={"row"}>
                                                            <Tooltip title="Print All Label">
                                                                <IconButton
                                                                    onClick={(e) => printAll(e)}
                                                                    style={{
                                                                        color: "white",
                                                                        paddingTop: 0,
                                                                        paddingBottom: 0,
                                                                    }}
                                                                >
                                                                    <PrintIcon></PrintIcon>
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip title="Void All Label">
                                                                <IconButton
                                                                    onClick={(e) => voidAll(e)}
                                                                    style={{
                                                                        color: "white",
                                                                        paddingTop: 0,
                                                                        paddingBottom: 0,
                                                                    }}
                                                                >
                                                                    <DoDisturbOnIcon></DoDisturbOnIcon>
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Stack> */}
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={4}
                                                        sm={4}
                                                        style={{ display: "flex", justifyContent: "left" }}
                                                    ></Grid>
                                                </Grid>
                                            </AccordionSummary>
                                            <AccordionDetails style={{ display: "block" }}>
                                                {renderWebTrackingNumberAssociation()}
                                            </AccordionDetails>
                                        </Accordion>
                                    </div>
                                </div>
                                <div className="backdrop">
                                    <div className="title">
                                        <Typography>Address Details</Typography>
                                    </div>
                                    <div className="mainContainer" style={{ padding: "20px" }}>
                                        {renderWebShipAddress()}
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={3} sm={3} className="textRight" style={{ paddingTop: "0px", paddingRight: "0px", paddingLeft: "6px" }}>
                                {generateTrackingView()}
                            </Grid>
                        </Grid>
                    </Paper>
                </div>
            </div>
        );
    };


    const renderMobileTrackingNumberAssociation = () => {

        if (isLoaded && orderDetail && orderDetail.Containers.Container && orderDetail.Containers.Container.length > 0) {
            return <React.Fragment>
                {
                    orderDetail.Containers.Container.map((item: Container, i: any) => {

                        return <React.Fragment>
                            <Card style={{ margin: "6px" }}>
                                <div
                                    style={{
                                        color: Constants.Colors.white,
                                        backgroundColor: "#828181",
                                        display: "flex",
                                        justifyContent: "space-around",
                                    }}
                                >
                                    <Typography style={{ paddingTop: 3, paddingBottom: 3 }}>
                                        Package {i + 1} of {orderDetail.Containers.Container.length}
                                    </Typography>
                                    <Typography
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            flexWrap: "wrap",
                                            paddingTop: 3,
                                            paddingBottom: 3,
                                        }}
                                    >
                                        <TimelineIcon style={{ color: "white" }} />
                                        <span style={{ fontSize: "10px" }}>{item.TrackingNo}</span>
                                    </Typography>
                                </div>
                                <CardContent style={{ padding: "10px" }}>

                                    {item.ContainerDetails.ContainerDetail.map((ccdetail: ContainerDetail) => {

                                        return <React.Fragment>

                                            <Grid
                                                spacing={2}
                                                container
                                                style={{ margin: "0px" }}
                                                sx={{ flexGrow: 1 }}
                                            >
                                                <Grid
                                                    item
                                                    xs={2}
                                                    sm={2}
                                                    style={{
                                                        padding: "8px",
                                                        borderRight: "1px solid #2f2d2d",
                                                    }}
                                                >
                                                    <img
                                                        alt=""
                                                        style={{ height: "50px" }}
                                                        src={getImageUrl(ccdetail.ShipmentLine.ShipmentLineKey)}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={10}
                                                    sm={10}
                                                    style={{ padding: "8px", textAlign: "left" }}
                                                >
                                                    <Typography
                                                        style={{
                                                            fontSize: "13px",
                                                            fontWeight: 500,
                                                            color: "#D93A2F",
                                                        }}
                                                    >
                                                        <em>
                                                            {ccdetail.ShipmentLine.ItemDesc}
                                                        </em>
                                                    </Typography>

                                                    <Typography style={{ fontSize: "11px" }}>
                                                        Qty to Ship: <b>{ccdetail.ShipmentLine.Quantity}</b>
                                                    </Typography>
                                                    <Typography style={{ fontSize: "11px" }}>
                                                        Qty to Pack: <b>{ccdetail.Quantity}</b>
                                                    </Typography>
                                                    <Typography style={{ fontSize: "11px" }}>
                                                        Return Order #: <b>{ccdetail.ShipmentLine.OrderNo}</b>
                                                    </Typography>
                                                    <Typography style={{ fontSize: "11px" }}>
                                                        Brand: <b>{getVpoBrand(ccdetail.ShipmentLine.ShipmentLineKey).brand}</b>
                                                    </Typography>
                                                    <Typography style={{ fontSize: "11px" }}>
                                                        VPO Reference #: <b>{getVpoBrand(ccdetail.ShipmentLine.ShipmentLineKey).vpo}</b>
                                                    </Typography>

                                                </Grid>
                                            </Grid>

                                        </React.Fragment>

                                    })}



                                    <Grid container className="rightAlign">
                                        <Button
                                            startIcon={<DoDisturbOnIcon fontSize="small" />}
                                            className="btn btnMblCancel"
                                            style={{
                                                color: "#ffffff",
                                                border: "1px solid #D93A2F",
                                                width: "100%",
                                            }}
                                            variant="contained"
                                            onClick={() => { voidLabel(item) }}
                                        >
                                            Void
                                        </Button>
                                        <Button
                                            startIcon={<PrintIcon fontSize="small" />}
                                            className="btn btnMblResource"
                                            style={{
                                                color: "#ffffff",
                                                border: "1px solid #D93A2F",
                                                width: "100%",
                                            }}
                                            variant="contained"
                                            onClick={() => { printLabel(item) }}
                                        >
                                            Print
                                        </Button>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </React.Fragment>

                    })
                }
            </React.Fragment>
        }
        else {
            if (isLoaded) {
                return <React.Fragment>
                    <Typography>
                        No data to display
                    </Typography>
                </React.Fragment>
            }
            else {
                return <React.Fragment>
                    <OrderLineShimmer></OrderLineShimmer>
                </React.Fragment>
            }

        }




    }

    const renderMobileShipLines = () => {

        if (isLoaded && orderDetail && orderDetail.ShipmentLines.ShipmentLine && orderDetail.ShipmentLines.ShipmentLine.length > 0) {
            {
                return <React.Fragment>
                    {
                        orderDetail.ShipmentLines.ShipmentLine.map((item: ShipmentLine2) => {

                            let isalreadyCompletePacled = Number(item.QtyAvailableToPack) == 0 ? true : false;
                            if (isalreadyCompletePacled) {
                                return "";
                            }
                            else {
                                return <Card style={{ background: Number(item.QtyAvailableToPack) == 0 ? "#eee" : "", margin: "6px" }}>
                                    <CardContent style={{ padding: "10px" }}>
                                        <Grid
                                            spacing={2}
                                            container
                                            style={{ margin: "0px" }}
                                            sx={{ flexGrow: 1 }}
                                        >
                                            <Grid
                                                item
                                                xs={2}
                                                sm={2}
                                                style={{
                                                    padding: "8px",
                                                    borderRight: "1px solid #2f2d2d",
                                                    display: "flex", justifyContent:"center", alignItems:"center"
                                                    
                                                }}
                                            >
                                                {Utils.disableUserAction(props.store) && <Typography variant="body2" sx={{ fontSize: "18px", marginTop: 1, color: "green" }} gutterBottom>
                                                    {isalreadyCompletePacled ? <DoneIcon style={{ fontSize: "38px" }}></DoneIcon> : <Checkbox
                                                        style={{
                                                            color: "#D93A2F",
                                                        }}
                                                        disabled={isalreadyCompletePacled}
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => { checkShipLine(item.ShipmentLineKey, checked) }}

                                                    />}
                                                </Typography>}
                                                {" "}
                                                <img alt=""
                                                    style={{ height: "42px",cursor:"zoom-in" }}
                                                    src={Utils.convertToHttps(item.ImageUrl)}
                                                    onClick={() => imageViewer(item)}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                xs={10}
                                                sm={10}
                                                style={{ padding: "8px", textAlign: "left" }}
                                            >
                                                <Typography
                                                    style={{
                                                        fontSize: "13px",
                                                        fontWeight: 500,
                                                        color: "#D93A2F",
                                                    }}
                                                >
                                                    <em>
                                                        {item.ItemDesc}
                                                    </em>
                                                </Typography>
                                                <Typography style={{ fontSize: "11px" }}>
                                                    Qty to Ship: <b>{item.QtyAvailableToPack}</b>
                                                </Typography>
                                                <Typography style={{ fontSize: "11px" }}>
                                                    <div style={{ display: "flex" }}>
                                                        <Typography style={{ fontSize: "11px", marginTop: "8px" }}>
                                                            Qty to Pack:
                                                        </Typography>
                                                        <TextField onChange={(event: any) => { onChangeItemQty(event, item) }} id="order" disabled={Number(item.QtyAvailableToPack) == 0 ? true : false} type={"number"} variant={"standard"} defaultValue={Number(item.toPackInput)} value={item.toPackInput} inputProps={{ style: { padding: 0 } }} InputProps={{ inputProps: { min: 1, max: Number(item.QtyAvailableToPack) }, readOnly: false, style: { padding: "0px !important" } }} style={{ marginLeft: "5px", width: "20%" }} />
                                                    </div>

                                                </Typography>
                                                <Typography style={{ fontSize: "11px" }}>
                                                    Return Order #: <b>{item.OrderNo}</b>
                                                </Typography>
                                                <Typography style={{ fontSize: "11px" }}>
                                                    Brand: <b>{item.ExtnBrandName}</b>
                                                </Typography>
                                                <Typography style={{ fontSize: "11px" }}>
                                                    VPO Reference #: <b>{item.ExtnVPOReferenceNo}</b>
                                                </Typography>
                                                <Typography style={{ fontSize: "11px" }}>
                                                    Price: <b>$ {item.UnitPrice}</b>
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        {Utils.disableUserAction(props.store) && <Grid container className="rightAlign">
                                            <Button
                                                disabled={Number(item.QtyAvailableToPack) == 0 ? true : false}
                                                onClick={() => openInvc(item)}

                                                className={Number(item.QtyAvailableToPack) == 0 ? "btn btnMblCancel disabledButton" : "btn btnMblCancel"}
                                                style={{
                                                    color: "#ffffff",
                                                    border: "1px solid #D93A2F",
                                                    width: "100%",
                                                }}
                                                variant="contained"
                                            >
                                                Mark INVC
                                            </Button>
                                        </Grid>}
                                    </CardContent>
                                </Card>
                            }


                        })
                    }

                </React.Fragment>

            }

        }
        else {
            if (isLoaded) {
                return <React.Fragment>
                    <Typography>
                        No data to display
                    </Typography>
                </React.Fragment>
            }
            else {
                return <OrderLineShimmerMobile></OrderLineShimmerMobile>
            }
        }



    }

    const mobileView = () => {
        return (
            <React.Fragment>
                <div style={{ paddingBottom: "150px" }}>
                    <Paper
                        id="bar"
                        style={{
                            color: "white",
                            top: 0,
                            left: 0,
                            right: 0,
                            zIndex: 999,
                            borderRadius: 0,
                        }}
                        className="appBarPrimary"
                    >
                        <Toolbar style={{ height: "46px", minHeight: "46px" }}>
                            <IconButton
                                size="large"
                                edge="start"
                                color="inherit"
                                aria-label="menu"
                                sx={{ mr: 2 }}
                                onClick={() => {
                                    navigate(-1);
                                }}
                            >
                                <ArrowBackIcon />
                            </IconButton>
                            <Typography
                                variant="h6"
                                component="div"
                                sx={{ flexGrow: 1 }}
                                style={{ marginLeft: "-48px" }}
                            >
                                KCDC Return Detail
                            </Typography>
                            <IconButton
                                style={{ background: "#D93A2F", color: "white" }}
                                sx={{ p: "10px" }}
                                aria-label="actions"
                            >
                            </IconButton>
                        </Toolbar>
                    </Paper>
                    <Paper style={{ marginTop: 2, paddingTop: 1, paddingBottom: 1 }}>
                        <Card style={{ margin: "6px" }} id="cardOrder">
                            <CardContent style={{ padding: "10px" }}>
                                <Grid
                                    spacing={2}
                                    container
                                    style={{ margin: "0px" }}
                                    sx={{ flexGrow: 1 }}
                                >
                                    <Grid item xs={12} sm={12} style={{ padding: "8px" }}>
                                        <Typography
                                            variant="h6"
                                            style={{ padding: "0px" }}
                                            className="scanColor"
                                        >
                                            Shipment # {orderDetail.ShipmentNo}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                        {Utils.disableUserAction(props.store) && <React.Fragment>
                            <Button
                                onClick={() => {
                                    setTrackingDrawer(true);
                                }}
                                style={{ marginTop: "4px", width: "98%", marginBottom: "6px" }}
                                variant="contained"
                                className="btn"
                                startIcon={<CheckIcon />}
                            >
                                Generate Label/Tracking
                            </Button>
                            <Button
                                disabled={selectedItems.length ? false : true}
                                className={selectedItems.length ? "btn " : "btn disabledButton"}
                                style={{ marginTop: "4px", width: "98%", marginBottom: "6px" }}
                                variant="contained"
                                startIcon={<PlumbingIcon />}
                                onClick={() => openADHocPopup()}
                            >
                                Add Tracking # (Adhoc)
                            </Button>
                            {/* <Button
                                style={{ marginTop: "4px", width: "98%", marginBottom: "6px" }}
                                disabled={isShipDisabled}
                                className={isShipDisabled ? "btn disabledButton" : "btn"}
                                variant="contained"
                                startIcon={<PrintIcon />}
                                onClick={() => {

                                    printPackSlip()

                                }}
                            >
                                Print Pack Slip
                            </Button> */}
                            <Button
                                style={{ marginTop: "4px", width: "98%", marginBottom: "6px" }}
                                //disabled={isShipDisabled}
                                //className={isShipDisabled ? "btn disabledButton" : "btn"}
                                disabled={true}
                                className={"btn disabledButton"}
                                variant="contained"
                                startIcon={<LocalShippingIcon />}
                                onClick={() => {

                                    ShipIt();

                                }}
                            >
                                Ship
                            </Button>
                        </React.Fragment>}
                       
                    </Paper>
                    <Card style={{ marginTop: "6px" }} id="cardOrder">
                        <CardContent style={{ padding: "1px" }}>
                            <div className="backdrop">
                                <div className="title">
                                    <Typography>Item to Ship Back to DC</Typography>
                                </div>
                                <div className="mainContainer" style={{ padding: "5px" }}>
                                    {renderMobileShipLines()}
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                    <Card style={{ marginTop: "6px" }} id="cardOrder">
                        <CardContent style={{ padding: "1px" }}>
                            <div className="backdrop">
                                <div className="title">
                                    <Grid container>
                                        <Grid
                                            item
                                            xs={6}
                                            sm={6}
                                            style={{
                                                display: "flex",
                                                justifyContent: "left",
                                                verticalAlign: "center",
                                            }}
                                        >
                                            <Typography style={{ paddingTop: 5 }}>
                                                Tracking Number Associations
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={6}
                                            sm={6}
                                            style={{ display: "flex", justifyContent: "right" }}
                                        >

                                        </Grid>
                                    </Grid>
                                </div>
                                <div className="mainContainer" style={{ padding: "5px" }}>
                                    {renderMobileTrackingNumberAssociation()}
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                </div>
                <Card className="customerDetailsBoxPickOrder" style={{}}>
                    <CardContent style={{ padding: "1px", background: "#F3F3F4" }}>
                        <Button
                            onClick={() => {
                                setDrawer(true);
                            }}
                            className="MobileDrawerButton"
                            variant="outlined"
                        >
                            Address Details
                        </Button>
                    </CardContent>
                </Card>
                <Drawer
                    anchor={"top"}
                    open={openTrackingDrawer}
                    onClose={() => {
                        setTrackingDrawer(false);
                    }}
                >
                    <Card style={{ margin: "6px", overflowY: "scroll" }}>
                        <CardContent style={{ padding: "10px" }}>
                            {generateTrackingView()}
                        </CardContent>
                    </Card>
                </Drawer>
                <Drawer
                    anchor={"bottom"}
                    open={openDrawer}
                    onClose={() => { }}
                    onClick={() => {
                        setDrawer(false);
                    }}
                >
                    <Card style={{ margin: "6px" }}>
                        <CardContent style={{ padding: "10px" }}>
                            <Typography variant="h5" component="div">
                                Shipping Address
                            </Typography>
                            <hr></hr>
                            <Typography variant="body2">
                                {customerDetails.address}
                            </Typography>
                            <Typography variant="body2">
                                {customerDetails.city}, {customerDetails.state}, {customerDetails.zipCode}
                            </Typography>
                        </CardContent>
                    </Card>
                </Drawer>
            </React.Fragment>
        );
    };

    const resetKcdcQuantity = () => {
        dispatch(allActions.actionLoaderActions.setKcdcTotalQuantity(0));
    }

    const getKcdcTotalQuantity = () => {
        if (StoreManager.currentStore?.StoreId) {
            let storeId = StoreManager.currentStore.StoreId.toString().split(" ");
            OrderManager.getkcdcOrders(storeId).then((res: any) => {
                if (res.success && res.data?.hits?.totalShipmentLines > 0) {
                    let totalShipmentLines = res.data?.hits.totalShipmentLines;
                    dispatch(allActions.actionLoaderActions.setKcdcTotalQuantity(totalShipmentLines));
                }
                else {
                    resetKcdcQuantity();
                }
            }).catch(err => {
                console.log("KCDC Total Quanty Error", err);
            });
        }
        else {
            resetKcdcQuantity();
        }
    }

    const loadData = async (afterCancelCall = false) => {

        try {

            showActionLoader();
            let selectedStore = IdentityManager.getStoreId(props.store); //StoreManager.currentStore.StoreId;
            if ((!selectedStore || selectedStore < 0) && queryParameterStore != null) {
                selectedStore = queryParameterStore
            }
            if (!selectedStore) {
                setErrorMsg("Please select a store to continue");
                openErrorPopup();
                hideActionLoader()
                return;
            }
            let result: any = await OMSManager.GCShipToDCDetailView(shipmentkey, selectedStore);
            hideActionLoader();
            if (result.success && result.data.ShipmentLines && result.data.ShipmentLines.ShipmentLine && result.data.ShipmentLines.ShipmentLine.length > 0) {

                let kcdcDetail: IKcdcDetail = result.data;

                if (kcdcDetail && kcdcDetail.ShipmentLines && kcdcDetail.ShipmentLines.ShipmentLine && kcdcDetail.ShipmentLines.ShipmentLine.length > 0) {
                    kcdcDetail.ShipmentLines.ShipmentLine.forEach((item: ShipmentLine2) => {
                        item.toPackInput = item.QtyAvailableToPack;
                        item.checked = false;
                    })
                }
                console.log(kcdcDetail, "kcdcDetail");
                dispatch(allActions.kcdcOrdersActions.loadKcdcOrderDetail(kcdcDetail));
                setCustomerDetails(getCustomerDetails(kcdcDetail))
                setLoaded(true);
                console.log(kcdcDetail, "kcdcDetail");

            }
            else {
                //EA-57 if (afterCancelCall && result.data.ShipmentLines && result.data.ShipmentLines.ShipmentLine && result.data.ShipmentLines.ShipmentLine.length == 0) {
                if (afterCancelCall && result.data.IsShipmentListEmpty == "Y") {
                    navigate("/KCDCReturns?ship=1");
                }
                else {
                    let message = ""
                    if (result.success && result.data.Status == "9000") {
                        message = "This Order has been canceled"
                    }
                    else if (!result.success && result.msg) {
                        message = result.msg;
                    }
                    else if ((result.success && result.data?.IsShipmentListEmpty == 'Y')
                        || (result.success && !result.data.ShipmentLines.ShipmentLine)) {
                        message = LabelText.noShipmentLines;
                    }
                    else {
                        message = "Some thing went wrong please contact the IT Help Desk for assistance";
                    }
                    setErrorMsg(message)
                    openErrorPopup();
                }

            }

        }
        catch (e) {
            setErrorMsg("Some thing went wrong please contact the IT Help Desk for assistance");
            openErrorPopup();
        }

    }
    React.useEffect(() => {
        async function getdetailDara() {
            await loadData()
        }
        
        if(queryParameterStore && props.store?.length==1 && props.store[0].id!=queryParameterStore)
        {
            openNavigationKCDC();
            return;
        }

        getdetailDara();
        return () => {
            // cleaer this data as these are also being used by kcdc view
            //dispatch(allActions.packOrderActions.changeResidential(false));
            dispatch(allActions.packOrderActions.changeShipDate(new Date()));
            resetBoxSide();
            dispatch(allActions.packOrderActions.changeSelectedItems([]));


        };

    }, [props.store]);

    // Popups
    let errorPopupButtons: IButton[] = [
        {
            text: "Close",
            icon: <CloseIcon />,
            action: closeErrorPopup,
            color: Constants.Colors.red,
        },
        {
            text: "Back",
            icon: <ArrowBackIcon />,
            action: popUpBack,
            color: Constants.Colors.red,
        },
    ];
    let Error: INotificationPopupSettings = {
        open: openError,
        type: Constants.NotificationPopupType.Error,
        title: "Error",
        msg: errorMsg,
        draggable: false,
        handleClose: closeErrorPopup,
        actions: errorPopupButtons
    }

    let adHocPopupButtons: IButton[] = [
        {
            text: "Close",
            icon: <CloseIcon />,
            action: closeADHocPopup,
            color: Constants.Colors.grey,
        },
        {
            text: "Update Tracking No",
            icon: <CheckIcon />,
            action: createContainerFromADHocTrackingNumbers,
            color: Constants.Colors.red,
        }
    ]

    let adHocPopup: INotificationPopupSettings = {
        open: isADHocPopup,
        type: Constants.NotificationPopupType.Info,
        title: "Add Tracking No",
        msg: "",
        handleClose: closeADHocPopup,
        isCustomJSX: true,
        customJSX: <AddAdHoqTrackingNumbers inputListAdhoq={inputListADHoc} setInputListAdhoq={setInputListADHoc} isBundle={false} disableMultiBox={disableMultiBox}></AddAdHoqTrackingNumbers>,
        actions: adHocPopupButtons,
        fullWidth: true,
    }

    return (
        <div>
            <NotificationPopup {...Error}></NotificationPopup>
            <NotificationPopup {...notesCustomerPopup}>
            </NotificationPopup>
            <NotificationPopup {...adHocPopup}>
            </NotificationPopup>
            <NotificationPopup {...navigationToKCDC}>
            </NotificationPopup>
            {Utils.isMobile ? mobileView() : webView()}
            <KcdcPackPrintSlip isLoaded={isLoaded} isPrintPackOrder={isPrintPackOrder} customerDetails={customerDetails}></KcdcPackPrintSlip>
            <ImageViewerPopup {...imageViewerSettings}>
            </ImageViewerPopup>
        </div>
    );
};

export default KCDCReturnDetail;

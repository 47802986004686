import { Button, Card, CardContent, Divider, Drawer, Grid, Paper, TextField, Toolbar, Typography, InputAdornment } from '@mui/material';

import { useNavigate } from 'react-router-dom';
import Loader from "react-loader-spinner";
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton } from '@mui/material';
import Utils from '../../Common/Utils';
import { ICancelLine, IReasons, IReasonState } from '../../Models/IReasons';
import { IButton, INotificationPopupSettings } from '../../Models/INotificationPopupSettings';
import { CloseIcon, DoneIcon } from './SharedIcons';
import { Reasons } from './Reasons';
import Constants from '../../Common/Constants';
import LabelText from '../../Common/LabelText';
import { NotificationPopup } from './NotificationPopup';
import { VerifyCredentials } from "../../Subcomponent/Shared/VerifyCredentials";
import allActions from '../../Store/Actions/AllActions';
import IdentityManager from '../../Managers/Identity.manager';

const CancelLine = (props: any) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [verifyCredentials, setVerifyCredentials] = useState(false);
    let cancelReason: IReasonState = useSelector((state: any) => state.ActionLoaderReducersState.cancelReason);
    const isOpen = cancelReason.showModal;



    const setCode = (code: any) => {

        dispatch(allActions.actionLoaderActions.setCancelReason(
            {
                ...cancelReason,
                code
            }
        ))

    }

    const setReasonText = (reasonText: any) => {

        dispatch(allActions.actionLoaderActions.setCancelReason(
            {
                ...cancelReason,
                reasonText
            }
        ))

    }

    const setError = (errorMessage: any) => {

        dispatch(allActions.actionLoaderActions.setCancelReason(
            {
                ...cancelReason,
                errorMessage
            }
        ))

    }

    const handleValueChange = (type: any, value: any) => {
        switch (type) {
            case "code":
                setCode(value);
                break;
            case "text":
                setReasonText(value);
                break;
        }
    }
    const closeVerificationPopUp = () => {
        setVerifyCredentials(false);
    }
    
    const openVerifyCredentials = () => {
        if (IdentityManager.verifyCredentialsException)
        {
            confirmCredentials();
        }
        else{
            setVerifyCredentials(true);
        }
    }

    const confirmCredentials = () => {
        getCancelReasonValues()
    }
    const verifyLogin = () => {
        if (props.isExternal == false) {
            if (!cancelReason.code || cancelReason.code.length == 0 || !cancelReason.reasonText || cancelReason.reasonText.length == 0) {
                setError("Please fill all required fields");
            }
            else {
                openVerifyCredentials()
            }
        }
        else {
            getCancelReasonValues()
        }
    }
    const getCancelReasonValues = () => {

        if (!cancelReason.code || cancelReason.code.length == 0 || !cancelReason.reasonText || cancelReason.reasonText.length == 0) {
            setError("Please fill all required fields");
        }
        else {

            if (props.getCancelReasonValues) {
                props.getCancelReasonValues(cancelReason.code, cancelReason.reasonText);
            }

        }

    }

    const closeCancel = () => {

        dispatch(allActions.actionLoaderActions.setCancelReason(
            Utils.initCanceReasonState()
        ))

    }


    let cancelReasonCodes = ["Customer Changed Mind", "Duplicate Order", "Inventory Error", "NLA Item"]
    let cancelReasons: IReasons = {
        reasonsCodes: cancelReasonCodes
    }

    let cancelPopupButtons: IButton[] = [{
        text: "Yes",
        icon: <DoneIcon />,
        action: verifyLogin,
        color: Constants.Colors.yellow,
    },
    {
        text: "No",
        icon: <CloseIcon />,
        action: closeCancel,
        color: Constants.Colors.grey,
    }
    ]
    let cancelPopup: INotificationPopupSettings = {
        open: isOpen,
        type: Constants.NotificationPopupType.Warning,
        title: LabelText.NotificationPopupHeaders.Warning,
        msg: props.isExternal? LabelText.ExternalOrderView.ExternalPickCancelOrderWarning:LabelText.PickOrder.PickCancelOrderWarning,
        handleClose: closeCancel,
        isCustomJSX: true,
        customJSX: <Reasons error={cancelReason.errorMessage} code={cancelReason.code} text={cancelReason.reasonText} reasonsCodes={cancelReasonCodes} handleValueChange={handleValueChange}></Reasons>,
        actions: cancelPopupButtons
    }
    let verifyCredentialsButtons: IButton[] = [];

    let verifyCredentialsPopUp: INotificationPopupSettings = {
        open: verifyCredentials,
        type: Constants.NotificationPopupType.Conformation,
        title: "Status Approved Credentials",
        msg: "",
        handleClose: closeVerificationPopUp,
        isCustomJSX: true,
        customJSX: <VerifyCredentials confirmCredentials={confirmCredentials} closePopUp={closeVerificationPopUp}></VerifyCredentials>,
        actions: verifyCredentialsButtons
    }


    return (
        <div>
            <NotificationPopup {...verifyCredentialsPopUp}>
            </NotificationPopup>
            <NotificationPopup {...cancelPopup}></NotificationPopup>
        </div>
    )

}

export default CancelLine;
import React, { useState } from 'react';
import { IconButton, InputBase, Paper, List, ListItem, ListItemText, Typography, Divider } from '@mui/material';
import './Shared.scss'
import Utils from '../../Common/Utils';
import {
    useLocation,
    useNavigate
} from "react-router-dom";
import { SearchIcon } from "../../Subcomponent/Shared/SharedIcons";
import allActions from '../../Store/Actions/AllActions';
import StoreManager from "../../Managers/Store.manager";
import OrderManager from "../../Managers/Orders.Manager"
import Constants from '../../Common/Constants';
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from 'react-redux';
import { TailSpin } from "react-loader-spinner";
const SearchBar = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [show, setShow] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [showSuggestion, setShowSuggestion] = useState(false);
    const [loading, setLoading] = useState(true);
    const [searchTimeout, setSearchTimeout] = useState<any | null>(null);
    let searchResult = useSelector((state: any) => state.SearchReducerState.searchResult);

    React.useEffect(() => {
        return () => {
        }
    }, [location]);
    const search = () => {
        if (searchText.length > 2) {
            let storeId = 0
            if (StoreManager.currentStore != undefined && StoreManager.currentStore.StoreId != undefined) {
                storeId = StoreManager.currentStore.StoreId
            }
            let query = {
                "search": searchText,
                "store": storeId
            }
            setLoading(true)
            OrderManager.searchOrder(query).then((result) => {
                if (result.success == true) {
                    if (result?.data?.hits?.hits) {
                        dispatch(allActions.searchActions.changeSearchResult(result?.data?.hits?.hits));
                    }
                }
                setLoading(false)
            }).catch((error) => {
                console.log(error)
                setLoading(false)
            })
        }
    }
    const navigateToOrderDetail = (orderno: any, shipmentkey: any) => {
        navigate("/OrderDetail?orderno=" + orderno + "&shipmentkey=" + shipmentkey + "")
    }
    const getCount = (row: any) => {
        let count = 0
        row._source.Shipment?.ShipmentLines?.ShipmentLine?.forEach((element: any) => {
            if (element.ExtnIsWarrantyItem == "N" && element.MinLineStatusDesc != "Cancelled" && element.BundleParentOrderLineKey == undefined) {
                count = count + Number(element.OrderedQty)
            }
        });
        return count
    }
    const searchBoxWidth: any = (Utils.getWebbarWidth / 3).toString() + "px";
    const searchSuggestionResult = () => {
        return (
            <Paper className='searchResult' style={{ width: searchBoxWidth, marginLeft: "1.4%" }}>
                {
                    loading == false ?
                        <List style={{ padding: 0 }}>
                            {
                                searchResult.length == 0 ?
                                    <ListItem key={0} className="SearchHover" alignItems="flex-start" style={{ paddingTop: 2, paddingBottom: 2 }}>
                                        <ListItemText
                                            primary={"No Record Found"}
                                            secondary={""}
                                        />
                                    </ListItem>
                                    :
                                    <React.Fragment>
                                        {
                                            searchResult.map((row: any, index: any) => {
                                                return (
                                                    <div>
                                                        <ListItem key={index} onClick={() => navigateToOrderDetail(row._source.Shipment.OrderNo, row._source.Shipment.ShipmentKey)} className="SearchHover" alignItems="flex-start" style={{ paddingTop: 2, paddingBottom: 2 }}>
                                                            <ListItemText
                                                                primary={row._source.Shipment.OrderNo}
                                                                secondary={
                                                                    <React.Fragment>
                                                                        {"Customer Name:"}
                                                                        <Typography
                                                                            sx={{ display: "inline" }}
                                                                            component="span"
                                                                            color="text.primary"
                                                                            style={{ marginRight: 5 }}
                                                                        >
                                                                            {row._source.Shipment.CustomerInfo.FirstName + " " + row._source.Shipment.CustomerInfo.LastName}
                                                                        </Typography>
                                                                        {"QTY:"}
                                                                        <Typography
                                                                            sx={{ display: "inline" }}
                                                                            component="span"
                                                                            color="text.primary"
                                                                            style={{ marginRight: 5 }}
                                                                        >
                                                                            {getCount(row)}
                                                                        </Typography>
                                                                        {"Fulfillment Location:"}
                                                                        <Typography
                                                                            sx={{ display: "inline" }}
                                                                            component="span"
                                                                            color="text.primary"
                                                                            style={{ marginRight: 5 }}
                                                                        >
                                                                            {row._source.Shipment.ShipNode}
                                                                        </Typography>
                                                                        <div>
                                                                            {
                                                                                row._source.Shipment?.Containers?.Container &&
                                                                                <React.Fragment>
                                                                                    {"Tracking ID:"}
                                                                                    {row._source.Shipment?.Containers?.Container.map((container: any) => {
                                                                                        return (
                                                                                            <Typography
                                                                                                sx={{ display: "inline" }}
                                                                                                component="span"
                                                                                                color="text.primary"
                                                                                                style={{ marginRight: 5 }}
                                                                                            >
                                                                                                {row._source.Shipment?.Containers?.Container.length > 1 ? `${container?.TrackingNo},` : container?.TrackingNo}
                                                                
                                                                                            </Typography>
                                                                                        )
                                                                                    })}
                                                                                </React.Fragment>
                                                                            }
                                                                        </div>

                                                                    </React.Fragment>
                                                                }
                                                            />
                                                        </ListItem>
                                                        <Divider />
                                                    </div>
                                                );
                                            })
                                        }
                                    </React.Fragment >
                            }
                        </List >
                        :
                        <div style={{ textAlign: 'center' }}>
                            <TailSpin color="#D93A2F" height={40} width={40} wrapperStyle={{ justifyContent: "center" }} />
                        </div>
                }
            </Paper >
        );
    }
    const getSearchResult = () => {
        if (searchText.length > 2) {
            let storeId = 0
            if (StoreManager.currentStore != undefined && StoreManager.currentStore.StoreId != undefined) {
                storeId = StoreManager.currentStore.StoreId
            }
            let query = {
                "search": searchText,
                "store": storeId
            }
            setLoading(true)
            OrderManager.searchOrder(query).then((result) => {
                if (result.success == true) {
                    if (result?.data?.hits?.hits) {
                        dispatch(allActions.searchActions.changeSearchResult(result?.data?.hits?.hits));
                    }
                }
                setLoading(false)
            }).catch((error) => {
                console.log(error)
                setLoading(false)
            })
        }
        else if (searchText.length > 0) {
            setLoading(true);
        }
    }
    React.useEffect(() => {
        if (searchTimeout) {
            clearTimeout(searchTimeout);
        }
        setSearchTimeout(
            setTimeout(() => {
                getSearchResult()
            }, 1000)
        );
        return () => clearTimeout(searchTimeout);
    }, [searchText]);
    // React.useEffect(() => {
    //     if (searchTimeout) {
    //         clearTimeout(searchTimeout);
    //     }
    //     setSearchTimeout(
    //         setTimeout(() => {
    //             getSearchResult()
    //         }, 1000)
    //       );
    //       return () => clearTimeout(searchTimeout);
    // }, [searchText])
    const searchTextChange = (event: any) => {
        let inputStr = ''
        if (event.target.value.charAt(0) === ' ') {
            inputStr = event.target.value.substring(1);
        } else {
            inputStr = event.target.value;
        }
        setSearchText(inputStr)

        if (event.target.value.length > 0) {
            setShowSuggestion(true);
        }
        else {
            setShowSuggestion(false);
        }
    }
    return (<div>
        <Paper className={!Utils.isMobile ? "searchWeb" : ""}
            sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}
            style={{ width: searchBoxWidth }}
        >
            <InputBase
                value={searchText}
                onChange={(event) => searchTextChange(event)}
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search Orders"
                inputProps={{ 'aria-label': 'search google maps' }}
            />
            <IconButton onClick={() => search()} style={{ background: "#d6d3d3" }} sx={{ p: '10px' }} aria-label="search">
                <SearchIcon />
            </IconButton>
        </Paper>
        {showSuggestion ?
            searchSuggestionResult()
            :
            <></>
        }
    </div>
    );

}

export default SearchBar;
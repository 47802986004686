import { AnyARecord } from "dns";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import PrintConfigurationManager from "../../Managers/PrintConfiguration.Manager";
import {
    CHANGE_FO_TAB, CHNAGE_FO_PAGINATION, CHANGE_FO_TOTAL, CHANGE_FO_ORDERROWS, CHANGE_SELECTED_ORDER_TYPE,
    CHANGE_ORDER_TYPE_FILTER, CHANGE_SELECTED_ORDER_AGE, CHANGE_ORDER_AGE_FILTER,
    CHANGE_SELECTED_ORDER_VALUE, CHANGE_ORDER_VALUE_FILTER, CHANGE_TAB_Counts, CHANGE_PRINT_PICKSLIP,
    CHANGE_PICK_SINGLE_MULTIPLE_MARKED_VALUES,
    CHANGE_PICK_SINGLE_MULTIPLE_VAL
} from "../ActionTypes";
const changeFOTabCounts = (obj: any) => {
    return {
        type: CHANGE_TAB_Counts,
        payLoad: obj
    };
}
const changeFOTab = (obj: any) => {
    return {
        type: CHANGE_FO_TAB,
        payLoad: obj
    };
}
const changeSelectedOrderType = (obj: any) => {
    return {
        type: CHANGE_SELECTED_ORDER_TYPE,
        payLoad: obj
    };
}
const changeOrderTypeFilter = (obj: any) => {
    return {
        type: CHANGE_ORDER_TYPE_FILTER,
        payLoad: obj
    };
}

const changeSelectedOrderAge = (obj: any) => {
    return {
        type: CHANGE_SELECTED_ORDER_AGE,
        payLoad: obj
    };
}

const changeOrderAgeFilter = (obj: any) => {
    return {
        type: CHANGE_ORDER_AGE_FILTER,
        payLoad: obj
    };
}

const changeSelectedOrderValue = (obj: any) => {
    return {
        type: CHANGE_SELECTED_ORDER_VALUE,
        payLoad: obj
    };
}

const changeOrderValueFilter = (obj: any) => {
    return {
        type: CHANGE_ORDER_VALUE_FILTER,
        payLoad: obj
    };
}

const changeTotal = (obj: any) => {
    return {
        type: CHANGE_FO_TOTAL,
        payLoad: obj
    };
}

const changeOrderRows = (obj: any) => {
    return {
        type: CHANGE_FO_ORDERROWS,
        payLoad: obj
    };
}

const changePrintPickSlips = (obj:any)=>{
    return {
        type: CHANGE_PRINT_PICKSLIP,
        payLoad: obj
    };

}
const changeSingleMultipleVal = (obj:any)=>{
    return {
        type: CHANGE_PICK_SINGLE_MULTIPLE_VAL,
        payLoad: obj
    };

}
const changeSingleMultipleMarkedValues = (obj:any)=>{
    return {
        type: CHANGE_PICK_SINGLE_MULTIPLE_MARKED_VALUES,
        payLoad: obj
    };

}


const fulfillOrdersActions = {
    changeFOTabCounts,
    changeFOTab,
    changeSelectedOrderType,
    changeOrderTypeFilter,
    changeSelectedOrderAge,
    changeOrderAgeFilter,
    changeSelectedOrderValue,
    changeOrderValueFilter,
    changeTotal,
    changeOrderRows,
    changePrintPickSlips,
    changeSingleMultipleVal,
    changeSingleMultipleMarkedValues
}

export default fulfillOrdersActions;

import { Paper, Typography, Divider, Grid } from "@mui/material";
import React from "react";
import Utils from "../../Common/Utils";
import { IResourceShipmentLine } from "../../Models/IResourceShipmentLine";

export const ResourceData = (props: any) => {
    let shipmentData: IResourceShipmentLine = props?.resourceShipmentLineData;
    return(
    <Paper style={{ marginTop: "5px" }}>
            <Grid item xs={12} sm={12}>
                <div className="backdrop">
                    <div className="title">
                        <Typography>Item Details</Typography>
                    </div>
                    <div className="mainContainer" style={{ padding: "20px" }}>
                        <Grid container spacing={2} display={"flex"}>
                            <Grid item xs={11} sm={11} className='textLeft'>
                            </Grid>
                            <Grid item xs={1} sm={1}>
                                <Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>
                                    Qty
                                </Typography>
                            </Grid>
                        </Grid>
                        <Divider />
                                <Grid container spacing={2} display={"flex"}>
                                    <Grid item xs={8} sm={8} className='textLeft'>
                                        <Grid spacing={2} container style={{ margin: "0px" }} sx={{ flexGrow: 1 }}>
                                            <Grid item xs={4} sm={4} style={{ padding: "8px", borderRight: "1px solid #2f2d2d", display: "flex", justifyContent: "center" }}>
                                                <img alt="" style={{ height: "100px" }} src={Utils.convertToHttps(shipmentData.imageUrl ? shipmentData.imageUrl : "")} />
                                            </Grid>
                                            <Grid item xs={8} sm={8} style={{ padding: "8px", textAlign: "left" }}>
                                                <Typography style={{ fontSize: "15px", fontWeight: 500, color: "#D93A2F" }}>{shipmentData.itemDesc}</Typography>
                                                <Typography variant="body2" sx={{ fontSize: 15 }} gutterBottom>
                                                    ItemID # <b>{shipmentData.itemID}</b>
                                                </Typography>
                                                <Typography variant="body2" sx={{ fontSize: 15 }} gutterBottom>
                                                    SKU # <b>{shipmentData.skuID}</b>
                                                </Typography>
                                                <Typography variant="body2" sx={{ fontSize: 15 }} gutterBottom>
                                                    Price: <b>${shipmentData.unitPrice}</b>
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={3} sm={3}>
                                    </Grid>
                                    <Grid item xs={1} sm={1}>
                                        <Typography>
                                            {shipmentData.quantity}
                                        </Typography>
                                    </Grid>
                                </Grid>
                    </div>
                </div>
            </Grid>
    </Paper>
    )
}
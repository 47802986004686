import React from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { Divider, Grid, Typography, } from '@mui/material';
import Utils from '../../Common/Utils';
const OrderCountShimmer = (props: any) => {
    return (
        <React.Fragment>
            <Grid container spacing={2} display={"flex"} style={{ padding: "10px" }} >


                <Grid item xs={12} sm={12} className='textLeft'>
                    <Skeleton animation="wave" />
                </Grid>
                {!Utils.isMobile ?
                    <React.Fragment>
                        <Grid item xs={10} sm={12} className='textLeft'>
                            <Skeleton />
                        </Grid>
                        <Grid item xs={8} sm={12} className='textLeft'>
                            <Skeleton animation={false} />
                        </Grid>
                    </React.Fragment> : ""}

            </Grid>
        </React.Fragment>
    );

}
export default OrderCountShimmer;
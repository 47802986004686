import React, { useState } from 'react';
import './Routes.scss';
import { useTheme, withStyles } from '@material-ui/core/styles';
import { Button, Grid } from '@material-ui/core';
import Utils from '../Common/Utils';
import Box from '@material-ui/core/Box';
import { Card, CardContent, Divider, TextField } from '@mui/material';
import Stack from '@mui/material/Stack';
import Drawer from '@mui/material/Drawer';
import DataTable from 'react-data-table-component';
import { IconButton, Paper, Typography, Tooltip, Toolbar } from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import SiteBreadCrumbs from '../Subcomponent/Shared/SiteBreadCrumbs'
import Constants from '../Common/Constants';
import { PreviewIcon, FilterAltIcon, ArrowBackIcon, ReceiptLongIcon } from "../Subcomponent/Shared/SharedIcons";
const steps = [
    'Pick',
    'Pack'
];
const steps2 = [
    'Pick',
    'Customer Pickup'
]
const CreateButton = withStyles(({
    root: {
        color: 'white',
        fontWeight: 600,
        backgroundColor: "#D93A2F",
        '&:hover': { backgroundColor: "#bd3627" },
    },
}))(Button);
const SearchResult = (props: any) => {
    // generate random Data fro faker js

    var startProductBarPos = -1;
    window.onscroll = function () {
        var bar = document.getElementById('paginationWebId') as any;
        var dataTable = document.getElementById('containerDataTable') as any;
        if (bar && dataTable) {
            var dataTableWidth = dataTable.offsetWidth;
            if (startProductBarPos < 0) startProductBarPos = findPosY(bar);

            if (window.pageYOffset > startProductBarPos) {
                bar.style.width = (dataTableWidth - (0.8 * dataTableWidth / 100)) + "px";
                bar.classList.add("fixedPagination");
                bar.style.marginTop = Utils.getWebbarheight + "px";
            } else {
                bar.classList.remove("fixedPagination");
                bar.style.width = "";
                bar.style.marginTop = "";
            }
        }
        // Mobile View Fix the Tabs and FILTER
        if (Utils.isMobile) {
            console.log("scrolled");
            bar = document.getElementById('tabFilterContaoner') as any;
            var mblBar = document.getElementById('mblBar') as any;
            if (bar) {

                if (startProductBarPos < 0) startProductBarPos = findPosY(mblBar);

                if (window.pageYOffset > startProductBarPos) {

                    bar.classList.add("scrolledTopFix");

                } else {
                    bar.classList.remove("scrolledTopFix");
                }
            }
        }
    };
    function findPosY(obj: any) {
        var curtop = 0;
        if (obj && typeof (obj.offsetParent) != 'undefined' && obj.offsetParent) {
            while (obj.offsetParent) {
                curtop += obj.offsetTop;
                obj = obj.offsetParent;
            }
            curtop += obj.offsetTop;
        }
        else if (obj && obj.y)
            curtop += obj.y;
        return curtop;
    }
    const TestState = useSelector((state: any) => state.TestReducerState);
    console.log(TestState, "TestState");
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const intFilterWeb = {
        records: 69, fData: {
            Expedited: 25,
            InStore: 25,
            ShipToCustomer: 10,
            ShipToSTORE: 9,
            age1: 25,
            age2: 25,
            age3: 15,
            age4: 4,
            Ov1: 29,
            Ov2: 22,
            Ov3: 5,
            Ov4: 14
        } as any
    };
    const intFilterMobile = {
        records: 10, fData: {
            Expedited: 5,
            InStore: 5,
            ShipToCustomer: 5,
            ShipToSTORE: 5,
            age1: 5,
            age2: 5,
            age3: 5,
            age4: 5,
            Ov1: 5,
            Ov2: 5,
            Ov3: 5,
            Ov4: 5
        } as any
    };
    const intFilter = Utils.isMobile ? intFilterMobile : intFilterWeb;
    const theme = useTheme();
    const [value, setValue] = useState(props.defaultSelected ? props.defaultSelected : 0);
    const [filter, setFilter] = useState(intFilter);
    const [showDetail, setDetail] = useState(false);
    // Drawer Code
    const anchor = "right";
    const [openDrawer, setDrawer] = useState(false);
    const [data, setData] = useState([] as any);
    const toggleDrawer = (op: any) => {
        setDrawer(op);
    };
    // Pagination 
    const [page, setPage] = React.useState(2);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    //
    const getfilterTypeValue = (type: any) => {
        return filter.fData[type];
    }
    const getFilters = (value: Number) => {
        switch (value) {
            case 0:
                setFilter(intFilter);
                break;
            case 1:
                setFilter({
                    records: 5, fData: {
                        Expedited: 5,
                        InStore: 5,
                        age1: 5,
                        age2: 5,
                        age3: 5,
                        age4: 5,
                        Ov1: 5,
                        Ov2: 5,
                        Ov3: 5,
                        Ov4: 5
                    }
                });
                break;
            case 2:
                setFilter({
                    records: 5, fData: {
                        Expedited: 5,
                        InStore: 5,
                        age1: 5,
                        age2: 5,
                        Ov1: 5,
                        Ov2: 5,

                    }
                });
                break;
            case 3:
                setFilter({
                    records: 8, fData: {
                        Expedited: 8,
                        age1: 8,
                        Ov1: 8
                    }
                });
                break;
        }
    }
    const loadOrder = () => {
        navigate("/PendingReceipt");
    }
    const navigateToOrderDetail = () => {
        navigate("/OrderDetail")
    }
    const handleChangeOrder = () => {
        loadOrder();
    }
    const WebGridButton = (action: string) => {
        if (Utils.isMobile) {
            return <React.Fragment>
                <Button variant="contained" color="primary" size="small">{action}</Button>
            </React.Fragment >
        }
        else {
            return <React.Fragment>
                <Button variant="contained" color="primary" >{action}</Button>
            </React.Fragment >
        }
    }
    const detailView = () => {
        return <Tooltip onClick={() => navigateToOrderDetail()} title="View Detail">
            <IconButton
                style={{ paddingTop: 0, paddingBottom: 0, color: Constants.Colors.red }}
            >
                <PreviewIcon></PreviewIcon>
            </IconButton>
        </Tooltip>
    }
    // End Drawer Code
    const handleChange = (event: any, newValue: number) => {
        console.log(newValue, "handleChange");
        setValue(newValue);
        getFilters(newValue);
    };
    const getData = (filter: any) => {
        let fdata = [];
        fdata.push({
            OrderNumber: "GC99999273",
            CustomerName: "Sohaib Ahmad Javed",
            OrderedOn: "2022/08/23",
            QTY: "1",
            Status: "Order Released",
            Action: "Pick"
        });
        fdata.push({
            OrderNumber: "GC99999274",
            CustomerName: "Sohaib Ahmad Javed",
            OrderedOn: "2022/08/23",
            QTY: "1",
            Status: "Picked",
            Action: "Pack"
        });
        fdata.push({
            OrderNumber: "GC99999275",
            CustomerName: "Sohaib Ahmad Javed",
            OrderedOn: "2022/08/23",
            QTY: "1",
            Status: "Packed",
            Action: "Customer Pickup"
        });
        fdata.push({
            OrderNumber: "GC99999276",
            CustomerName: "Sohaib Ahmad Javed",
            OrderedOn: "2022/08/23",
            QTY: "1",
            Status: "Pending Receipt",
            Action: "Pending Receipt"
        });
        setData(fdata);
    }

    const getColumns = () => {
        const columns = [
            {
                name: 'Order #',
                selector: (row: any) => row.OrderNumber,
                sortable: true,
            },
            {
                name: 'Customer Name',
                selector: (row: any) => row.CustomerName,
                sortable: true,
                minWidth: "200px",
                width: "200px"
            },
            {
                name: 'Ordered On',
                selector: (row: any) => row.OrderedOn,
                sortable: true,
            },
            {
                name: 'QTY',
                selector: (row: any) => row.QTY,
                sortable: true,
            },
            {
                name: 'Status',
                selector: (row: any) => row.Status,
                sortable: true,
            },
            {
                name: 'Detail',
                cell: (row: any) => detailView(),
                ignoreRowClick: true,
                allowOverflow: true,
                button: true,
            },
            {
                name: 'Actions',
                cell: (row: any) => WebGridButton(row.Action),
                ignoreRowClick: true,
                allowOverflow: true,
                button: true,
                minWidth: "200px",
                width: "200px"
            },
        ];

        return columns;
    }
    React.useEffect(() => {
        getData(filter);
        return () => {

        }
    }, []);

    React.useEffect(() => {
        console.log(filter, "filterBew");
        getData(filter);
        return () => {
        }
    }, [filter]);
    const renderFilters = () => {
        return <Box sx={{ p: 3 }} style={{ padding: "0px" }}>
            <div className='fQuickFilters'>
                <div className='headingContainer'>
                    <Typography variant='h5' className='topheading'><FilterAltIcon className='fTabIcons' /> Quick Filters</Typography>
                </div>
                <Divider />
                <Grid container spacing={2} style={{ margin: "0px" }}>
                    <Grid item xs={12} sm={6} style={{ borderRight: Utils.isMobile ? "" : "1px dotted #949494", padding: "0px" }}>
                        <Typography variant='h6' className='heading' color='primary'>Order Type</Typography>
                        <Stack spacing={2} direction="row" className='btnStack'>
                            <Button variant="outlined" color="primary">All ({filter.records})</Button>
                            {getfilterTypeValue("Expedited") ? <Button variant="outlined" color="primary">Expedited ({getfilterTypeValue("Expedited")})</Button> : ""}
                            {getfilterTypeValue("InStore") ? <Button variant="outlined" color="primary">In Store ({getfilterTypeValue("InStore")})</Button> : ""}
                        </Stack>
                        <Stack spacing={2} direction="row" className='btnStack'>
                            {getfilterTypeValue("ShipToCustomer") ? <Button variant="outlined" color="primary">Ship To Customer ({getfilterTypeValue("ShipToCustomer")})</Button> : ""}
                            {getfilterTypeValue("ShipToSTORE") ? <Button variant="outlined" color="primary">Ship To Store ({getfilterTypeValue("ShipToSTORE")})</Button> : ""}
                        </Stack>
                        <Divider orientation="vertical" variant="middle" flexItem />
                    </Grid>
                    <Grid item xs={12} sm={3} style={{ borderRight: Utils.isMobile ? "" : "1px dotted #949494", padding: "0px" }}>
                        <Typography variant='h6' className='heading' color='primary'>Order Age (days)</Typography>
                        <Stack spacing={2} direction="row" className='btnStack'>
                            {getfilterTypeValue("age1") ? <Button variant="outlined" color="primary">0-1 ({getfilterTypeValue("age1")})</Button> : ""}
                            {getfilterTypeValue("age2") ? <Button variant="outlined" color="primary">1-2 ({getfilterTypeValue("age2")})</Button> : ""}
                        </Stack>
                        <Stack spacing={2} direction="row" className='btnStack'>
                            {getfilterTypeValue("age3") ? <Button variant="outlined" color="primary">2-3 ({getfilterTypeValue("age3")})</Button> : ""}
                            {getfilterTypeValue("age4") ? <Button variant="outlined" color="primary"> &gt; 3 ({getfilterTypeValue("age4")})</Button> : ""}
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={3} style={{ padding: "0px" }}>
                        <Typography variant='h6' className='heading' color='primary'>Order Value</Typography>
                        <Stack spacing={2} direction="row" className='btnStack'>
                            {getfilterTypeValue("Ov1") ? < Button variant="outlined" color="primary">&lt; $25 ({getfilterTypeValue("Ov1")})</Button> : ""}
                            {getfilterTypeValue("Ov2") ? <Button variant="outlined" color="primary"> $25 -$100 ({getfilterTypeValue("Ov2")})</Button> : ""}
                        </Stack>
                        <Stack spacing={2} direction="row" className='btnStack'>
                            {getfilterTypeValue("Ov3") ? <Button variant="outlined" color="primary">$100 -$500 ({getfilterTypeValue("Ov3")})</Button> : ""}
                            {getfilterTypeValue("Ov4") ? <Button variant="outlined" color="primary">&gt; $500 ({getfilterTypeValue("Ov4")})</Button> : ""}
                        </Stack>
                    </Grid>
                </Grid>

            </div>
        </Box >
    }
    const renderMobileFilters = () => {
        return <div>
            <Paper id='bar' style={{ color: "white", top: 0, left: 0, right: 0, zIndex: 999, borderRadius: 0 }} className="appBarPrimary">
                <Toolbar style={{ height: "46px", minHeight: "46px" }}>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}

                        onClick={() => { navigate(-1) }}
                    >
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} style={{ marginLeft: "-24px" }}>
                        Search
                    </Typography>

                    <IconButton onClick={() => { setDrawer(true); }} style={{ background: "#D93A2F", color: "white" }} sx={{ p: '10px' }} aria-label="actions">
                        <FilterAltIcon />
                    </IconButton>
                </Toolbar>
            </Paper>
            <React.Fragment>
                <Drawer
                    anchor={"top"}
                    open={openDrawer}
                    onClose={() => { }}
                    onClick={() => { setDrawer(false) }}
                    style={{ zIndex: 9999999 }}
                >
                    {renderFilters()}
                </Drawer>
            </React.Fragment>
        </div>
    }
    const renderWebDataTable = () => {
        return <div className='dataTable'>
            <DataTable
                className='dataTable'
                title=""
                data={data}
                columns={getColumns()}
            />
        </div>
    }
    const stepperButton = (step: any, stepstoShow: any) => {
        return <React.Fragment>
            <Button startIcon={<ReceiptLongIcon />} variant="contained" color="primary" onClick={() => { loadOrder(); }}>{stepstoShow[step]}</Button>
        </React.Fragment>
    }
    const renderMobileData = () => {
        return <div>
            {data.map((d: any) => {
                let stepRandom = 0; //faker.datatype.number({ min: 0, max: 1 });
                console.log(stepRandom, "stepRandom");
                let stepstoShow = [];
                if (stepRandom == 0) {
                    stepstoShow = steps;
                }
                else {
                    stepstoShow = steps2;
                }
                return <Card sx={{ mt: 2 }}>
                    <CardContent style={{ padding: "10px" }}>
                        <Typography variant="h6" component="div" style={{
                            display: 'inline-flex',
                            textAlign: 'center',
                        }}>
                            Order # {d.OrderNumber}
                            {detailView()}
                        </Typography>
                    </CardContent>
                    <Card sx={{ mt: 1 }} className="orderDetailMobileContainer">
                        <CardContent>
                            <Stack direction={"row"}>
                                <TextField
                                    id="customerName"
                                    label="Customer Name"
                                    defaultValue={d.CustomerName}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    variant="standard"
                                    style={{ margin: "4px" }}
                                />
                                <TextField
                                    id="outlined-read-only-input"
                                    label="Ordered On"
                                    defaultValue={d.OrderedOn}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    variant="standard"
                                    style={{ margin: "4px" }}
                                />
                            </Stack>
                            <Stack direction={"row"}>
                                <TextField
                                    id="outlined-read-only-input"
                                    label="QTY"
                                    defaultValue={d.QTY}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    variant="standard"
                                    style={{ margin: "4px" }}
                                />
                                <TextField
                                    id="outlined-read-only-input"
                                    label="Status"
                                    defaultValue={d.Status}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    variant="standard"
                                    style={{ margin: "4px" }}
                                />
                            </Stack>
                            <Box sx={{ p: 3 }} style={{ textAlign: "right", padding: "5px" }}>
                                {WebGridButton(d.Action)}
                            </Box>

                        </CardContent>
                    </Card>
                </Card>
            })
            }
        </div >
    }
    const renderPaginationAndSearch = () => {
        if (!Utils.isMobile) {
            return <React.Fragment >
                {pagination()}
            </React.Fragment>
        }
        else {
            return <React.Fragment >
            </React.Fragment>
        }
    }
    const pagination = () => {
        return < div id='paginationWebId' className="paginationReceiveOrdersWeb" style={{ background: "#D93A2F" }}>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <Stack spacing={1} style={{ paddingTop: 7 }}>
                    <TablePagination
                        component="div"
                        count={100}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Stack>
            </div>
        </div >
    }
    return (
        <div>
            {!Utils.isMobile ? <SiteBreadCrumbs siteBreadCrumbs={Constants.SiteBreadCrumbs.Search}></SiteBreadCrumbs> : <></>}
            <div style={{ marginTop: 0 }} className={Utils.isMobile ? "innerContianerMobile" : "innerContianer"}>
                <div id='tabFilterContaoner'>
                    {!Utils.isMobile ? renderFilters() : renderMobileFilters()}
                </div>
                <TabPanel value={value} index={value} dir={theme.direction}>
                    {renderPaginationAndSearch()}
                    {!Utils.isMobile ? renderWebDataTable() : renderMobileData()}
                </TabPanel>
            </div>
        </div>
    );
    interface TabPanelProps {
        children?: React.ReactNode;
        dir?: string;
        index: number;
        value: number;
    }
    function TabPanel(props: TabPanelProps) {
        const { children, value, index, ...other } = props;
        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`full-width-tabpanel-${index}`}
                aria-labelledby={`full-width-tab-${index}`}
                {...other}
                style={{ marginTop: Utils.isMobile ? "-14px" : "" }}
            >
                <Box sx={{ p: 3 }} style={{ padding: "5px" }} id='containerDataTable'>
                    <Typography component="div">{children}</Typography>
                </Box>

            </div>
        );
    }
}
export default SearchResult;
import React from "react";
import { FormHelperText, FormControl, Grid, InputAdornment, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, Stack, TextField, Toolbar, Tooltip, Typography } from "@mui/material";
import MuiInput from "@mui/material/Input";
import Constants from "../../Common/Constants";
import Utils from "../../Common/Utils";
import { CancelIcon, AddCircleIcon } from "../../Subcomponent/Shared/SharedIcons";
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../Store/Actions/AllActions';
import PFManager from "../../Managers/Process.Fulfillment.Manager";
import StoreManager from "../../Managers/Store.manager";
import IStoreInfo from "../../Models/IStoreInfo";
import IBoxCharacteristic from "../../Models/IBoxCharacteristic";
export const BoxCharacteristicts: React.FC<any> = (props) => {
    const dispatch = useDispatch();
    let boxCharacteristicList: IBoxCharacteristic[] = useSelector((state: any) => state.PackOrderReducersState.boxCharacteristicList);
    let boxValues: IBoxCharacteristic[] = boxCharacteristicList.filter((box: any) => { return box.index == props.index })
    let selectedItems = useSelector((state: any) => state.PackOrderReducersState.selectedItems);
    const changeWeight = (event: any, index: number) => {
        let weight = event.target.value.replace("-", "");
        let payload = {
            index: index,
            value: Number(weight) < 0 ? "0" : weight,
            key: "weight"
        }
        dispatch(allActions.packOrderActions.changeBoxCharacteristicByIndex(payload));
    }
    const changeDimentions = (event: any, index: number, dimType: any) => {
        let payload = {
            index: index,
            value: Number(event.target.value) <= 0 ? "1" : event.target.value,
            key: dimType
        }
        dispatch(allActions.packOrderActions.changeBoxCharacteristicByIndex(payload));
    }
    const changeBoxType = (event: any, index: number) => {
        let payloadBoxType = {
            index: index,
            value: event.target.value,
            key: "boxType"
        }
        //boxType
        dispatch(allActions.packOrderActions.changeBoxCharacteristicByIndex(payloadBoxType));
        let payloadDimensions = {
            index: index,
            value: event.target.value,
            key: "dimensions"
        }
        dispatch(allActions.packOrderActions.changeBoxCharacteristicByIndex(payloadDimensions));
        // L, W, H
        if (event.target.value == "Custom" || !event.target.value || event.target.value == "") {
            let payload = {
                index: index,
                value: "",
                key: "length"
            }
            dispatch(allActions.packOrderActions.changeBoxCharacteristicByIndex(payload));
            payload.key = "width";
            payload.value = "";
            dispatch(allActions.packOrderActions.changeBoxCharacteristicByIndex(payload));
            payload.key = "height";
            payload.value = "";
            dispatch(allActions.packOrderActions.changeBoxCharacteristicByIndex(payload));
        }
        else {

            let splittedDims: any = event.target.value.split("x");
            if (splittedDims && splittedDims.length > 0) {
                let payload = {
                    index: index,
                    value: splittedDims[0],
                    key: "length"
                }
                dispatch(allActions.packOrderActions.changeBoxCharacteristicByIndex(payload));
                payload.key = "width";
                payload.value = splittedDims[1];
                dispatch(allActions.packOrderActions.changeBoxCharacteristicByIndex(payload));
                payload.key = "height";
                payload.value = splittedDims[2];
                dispatch(allActions.packOrderActions.changeBoxCharacteristicByIndex(payload));
            }

        }

    }
    const addBox = () => {
        if (selectedItems.length <= 1 && props.isBundle == false && props.disableMultiBox == false) {
            props.addBox()
        }
    }
    const removeBox = () => {
        if (selectedItems.length > 0) {
            props.removeBox(props.index)
        }
    }
    return (
        <div>
            <Paper className={selectedItems.length > 0 ? "boxCharacterHeader" : "boxCharacterHeader disabledColor"}>
                <Typography>
                    Package Detail
                </Typography>
                {props.index == 0 ?
                    <Tooltip onClick={() => addBox()} title="Add Package">
                        <AddCircleIcon className={selectedItems.length > 1 || props.isBundle == true || props.disableMultiBox == true ? "disabledColor" : ""} />
                    </Tooltip>
                    :
                    <Tooltip onClick={() => removeBox()} title="Remove Package">
                        <CancelIcon />
                    </Tooltip>
                }
            </Paper>
            <Grid item xs={12} sm={12} className="inputField">
                <Typography className={selectedItems.length > 0 ? "" : "disabledColor"}>Container/Box Type</Typography>
                <FormControl>
                    <Select
                        disabled={selectedItems.length > 0 ? false : true}
                        error={boxValues[0].Error.length || boxValues[0].Error.width || boxValues[0].Error.height}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={boxValues[0].boxType}
                        onChange={(event: SelectChangeEvent) => changeBoxType(event, props.index)}
                        variant="outlined"
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                    >
                        <MenuItem value={0}>
                            <em>Select Container/Box Type</em>
                        </MenuItem>
                        {
                            Constants.SIZES.map((data: any, i: number) => <MenuItem value={data.value} key={i}>{data.name}</MenuItem>)
                        }
                    </Select>
                </FormControl>
            </Grid>
            <div style={{ padding: 5 }}>
                <Grid container style={{ display: boxValues[0].dimensions == "Custom" ? "" : "none" }}>
                    <Grid item xs={12} sm={12} className="inputField">
                        <Typography className={selectedItems.length > 0 ? "" : "disabledColor"}>Dimensions</Typography>
                        <table style={{ width: "100%" }}>
                            <tr>
                                <td>
                                    <TextField
                                        disabled={selectedItems.length > 0 && boxValues[0].dimensions == "Custom" ? false : true}
                                        error={boxValues[0].Error.length}
                                        helperText={boxValues[0].Error.length ? "Invalid" : ""}
                                        fullWidth
                                        className="remove-buttons"
                                        InputLabelProps={{ shrink: false }}
                                        id="dimentions_length"
                                        placeholder="L"
                                        variant="outlined"
                                        value={boxValues[0].length}
                                        onChange={(event: any) => changeDimentions(event, props.index, "length")}
                                        type={"number"}
                                    />
                                </td>
                                <td>
                                    x
                                </td>
                                <td>
                                    <TextField
                                        disabled={selectedItems.length > 0 && boxValues[0].dimensions == "Custom" ? false : true}
                                        error={boxValues[0].Error.width}
                                        helperText={boxValues[0].Error.width ? "Invalid" : ""}
                                        fullWidth
                                        InputLabelProps={{ shrink: false }}
                                        className="remove-buttons"
                                        id="dimentions_width"
                                        placeholder="W"
                                        variant="outlined"
                                        value={boxValues[0].width}
                                        onChange={(event: any) => changeDimentions(event, props.index, "width")}
                                        type={"number"}
                                    />
                                </td>
                                <td>
                                    x
                                </td>
                                <td>
                                    <TextField
                                        disabled={selectedItems.length > 0 && boxValues[0].dimensions == "Custom" ? false : true}
                                        error={boxValues[0].Error.height}
                                        helperText={boxValues[0].Error.height ? "Invalid" : ""}
                                        fullWidth
                                        InputLabelProps={{ shrink: false }}
                                        id="dimentions+heuight"
                                        className="remove-buttons"
                                        placeholder="H"
                                        variant="outlined"
                                        value={boxValues[0].height}
                                        onChange={(event: any) => changeDimentions(event, props.index, "height")}
                                        type={"number"}
                                    />
                                </td>
                            </tr>
                        </table>

                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} className="inputField">
                    <Typography className={selectedItems.length > 0 ? "" : "disabledColor"}>Weight</Typography>
                    <TextField
                        disabled={selectedItems.length > 0 ? false : true}
                        error={boxValues[0].Error.weight}
                        helperText={boxValues[0].Error.weight ? "Invalid" : ""}
                        type={"number"}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="start">lb</InputAdornment>
                            ),
                            inputProps: { min: 0 }
                        }}
                        fullWidth
                        InputLabelProps={{ shrink: false }}
                        id="weight"
                        placeholder="Weight"
                        variant="outlined"
                        value={boxValues[0].weight}
                        onChange={(event: any) => changeWeight(event, props.index)}
                    />
                </Grid>
            </div>
        </div>
    );
};

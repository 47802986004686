import { Box, Card, CardContent, Divider, Grid, Tooltip, Typography } from "@mui/material"
import React, { useEffect } from "react";
import { useState } from "react";
import Constants from "../../Common/Constants";
import Utils from "../../Common/Utils";
import { AttachMoneyIcon, InfoOutlinedIcon, MonetizationOnIcon, PlaylistAddCheck, ShoppingCartIcon, ZoomOutMapIcon } from "../Shared/SharedIcons"
import CustomChip from "./CustomChip";
import { OrdersDataGrid } from "./OrdersDataGrid";
import { log } from "console";

export const OrdersTile = (props: any) => {
    const { fulfilledData, isFulfilledOrdersLoaded } = props;
    const [gridPopUp, setGridPopUp] = useState(false);
    const distList = fulfilledData.districtfulfillData;

    const [sumOfDistOrders, setsumOfDistOrders] = useState(0)
    const [sumOfDistrictVale, setSumOfDistrictVale] = useState(0)
    const [useItemNumber, setUseItemNumber] = useState(0)
    const [usedItemsValue, setUsedItemsValue] = useState(0)
    const [usedItemsValuePerc, setUsedItemsValuePerc] = useState(0)
    const [shippedCount, setShippedCount] = useState(0)

    // same Store Pick up (BOPIS)
    const [inStorePickupCount, setInStorePickupCount] = useState(0)
    const [inStorePickupValue, setInStorePickupValue] = useState(0)

    // STS pickup (BOPIS)
    const [stsCount, setStsCount] = useState(0)
    const [stsValue, setStsValue] = useState(0)

    // total Fulfilled Orders
    const [totalFulfillOrder, setTotalFulfillOrder] = useState(0)
    const [totalFulfillOrderVal, setTotalFulfillOrderVal] = useState(0)


    const [ordersSummary, setOrdersSummary] = useState({ shippedPerc: 0, pickUpPerc: 0, totalShippedValue: 0, totalPickUpValue: 0 })
    const [usedItems, setUsedItems] = useState({ shippedUsedCount: 0, shippedUsedValue: 0, shippedUsedPerc: 0, inStoreUsedCount: 0, inStoreUsedValue: 0, inStoreUsedPerc: 0, STSUsedItemsNumbers: 0, STSUsedItemsValue: 0 })

    useEffect(() => {
        if (distList) {
            let orders = 0
            let value = 0
            let shippedUsedCount = 0;
            let shippedUsedValue = 0;
            let shippedUsedPerc = 0;
            let inStoreUsedCount = 0;
            let inStoreUsedValue = 0;
            let inStoreUsedPerc = 0;
            let shippedCount = 0;
            let inStorePickupCount = 0;
            let inStorePickupValue = 0;
            let STSNumbers = 0;
            let STSValue = 0;
            let STSUsedItemsNumbers = 0;
            let STSUsedItemsValue = 0;

            for (let i = 0; i < distList.length; i++) {
                orders = orders + (distList[i].shippedNumbers + distList[i].customerPickedupNumbers + distList[i].STSNumbers);
                value = value + (distList[i].shippedValue + distList[i].customerPickedupValue + distList[i].STSValue);
                shippedCount = shippedCount + distList[i].shippedNumbers;
                inStorePickupCount = inStorePickupCount + distList[i].customerPickedupNumbers;
                inStorePickupValue = inStorePickupValue + distList[i].customerPickedupValue
                shippedUsedCount = shippedUsedCount + distList[i].shippedUsedItemsNumbers;
                shippedUsedValue = shippedUsedValue + distList[i].shippedUsedItemsValue;
                inStoreUsedCount = inStoreUsedCount + distList[i].inStoreUsedItemsNumbers;
                inStoreUsedValue = inStoreUsedValue + distList[i].inStoreUsedItemsValue;
                STSNumbers = STSNumbers + distList[i].STSNumbers;
                STSValue = STSValue + distList[i].STSValue;;
                STSUsedItemsNumbers = STSUsedItemsNumbers + distList[i].STSUsedItemsNumbers;;
                STSUsedItemsValue = STSUsedItemsValue + distList[i].STSUsedItemsValue;;

            }
            setInStorePickupValue(inStorePickupValue)
            setShippedCount(shippedCount);
            setInStorePickupCount(inStorePickupCount)
            setTotalFulfillOrder(orders)
            setTotalFulfillOrderVal(value)

            if (orders > 0) {
                shippedUsedPerc = parseFloat(((shippedUsedCount / orders) * 100).toFixed(2));
                inStoreUsedPerc = parseFloat(((inStoreUsedCount / orders) * 100).toFixed(2));

                let usedItems = {
                    shippedUsedCount,
                    shippedUsedValue,
                    shippedUsedPerc,
                    inStoreUsedCount,
                    inStoreUsedValue,
                    inStoreUsedPerc,
                    shippedCount,
                    inStorePickupCount,
                    inStorePickupValue,
                    STSUsedItemsNumbers,
                    STSUsedItemsValue
                }
                setStsCount(STSNumbers);
                setStsValue(STSValue)
                setUsedItems(usedItems);
                calculateSummary(distList, orders);
            } else {
                let shippedPerc = 0;
                let pickUpPerc = 0;
                let totalShippedValue = 0;
                let totalPickUpValue = 0

                let summary = {
                    shippedPerc,
                    pickUpPerc,
                    totalShippedValue,
                    totalPickUpValue
                };
                setOrdersSummary(summary);

            }
            setsumOfDistOrders(orders)
            setSumOfDistrictVale(value)
        }
    }, [distList])

    const calculateSummary = (data: any, totalOrders: any) => {
        // Shipped Number
        let totalShippedNumbers = data.reduce((acc: any, currentObj: any) => {
            return acc + currentObj.shippedNumbers
        }, 0);

        // Shipped Percentage
        let shippedPerc = parseFloat(((totalShippedNumbers / totalOrders) * 100).toFixed(2));

        // Pick up Number
        let pickUpPerc = parseFloat((100 - shippedPerc).toFixed(2));

        let totalShippedValue = data.reduce((acc: any, currentObj: any) => {
            return acc + currentObj.shippedValue
        }, 0);

        let totalPickUpValue = data.reduce((acc: any, currentObj: any) => {
            return acc + currentObj.customerPickedupValue
        }, 0);


        let summary = {
            shippedPerc,
            pickUpPerc,
            totalShippedValue,
            totalPickUpValue
        };

        setOrdersSummary(summary);

    }

    const setPercentage = (number: number) => {
        return (number * 100).toFixed(2)
    }

    const closeGridPopup = () => {
        setGridPopUp(false);
    }

    return (
        <React.Fragment>

            <Card className='dCard' >
                <CardContent className='dCardContent' style={{ padding: 0 }}>
                    <div className='dCardLeftHeader'>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <ShoppingCartIcon style={{ color: Constants.Colors.red }}></ShoppingCartIcon>
                        </div>
                        <Typography style={{ display: "flex", alignItems: "center", fontWeight: "bold", color: "#5f6661", fontSize: 17, marginTop: "5px", marginLeft: "5px" }}>
                            Orders Fulfilled <Tooltip title={<h3 style={{ whiteSpace: 'pre-line' }}>{Utils.getToolTipValue("OrdersFulfilled") ? Utils.getToolTipValue("OrdersFulfilled") : ''}</h3>}>
                                <InfoOutlinedIcon style={{ color: Constants.Colors.blue, marginLeft: "5px" }} />
                            </Tooltip>
                        </Typography>
                        <div onClick={() => { setGridPopUp(true) }} style={{ cursor: "pointer", marginLeft: "auto" }}>
                            <ZoomOutMapIcon style={{ float: "right", padding: "5px", width: "20px", color: Constants.Colors.grey }} />
                        </div>
                    </div>

                    <Divider />

                    <Grid container>
                        <Grid item xs={6} sm={6} >
                            <Grid container>
                                <Grid item sm={4} style={{ display: "grid", alignItems: "center", justifyItems: "end" }}>
                                    <div className="tiles-icons" style={{ backgroundColor: Constants.Colors.green }}  >
                                        <PlaylistAddCheck />
                                    </div>
                                </Grid>
                                <Grid item sm={8} style={{ display: "grid", justifyItems: "start" }}>
                                    <p className="tiles-header-font">Total Orders</p>
                                    <p className="tiles-value-font">
                                        {sumOfDistOrders}
                                    </p>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                            <Grid container>
                                <Grid item sm={4} style={{ display: "grid", alignItems: "center", justifyItems: "end" }}>
                                    <div className="tiles-icons" style={{ backgroundColor: Constants.Colors.green }} >
                                        <AttachMoneyIcon />
                                    </div>
                                </Grid>
                                <Grid item sm={8} style={{ display: "grid", justifyItems: "start" }}>
                                    <p className="tiles-header-font">Value</p>
                                    <p className="tiles-value-font">
                                        {Utils.nFormatter(sumOfDistrictVale, 2)}
                                    </p>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={6} sm={6} >
                            <Grid container style={{ backgroundColor: "#5A5A5A", minHeight: "116px", display: "flex", justifyContent: "center", alignItems: "center", borderRight: "1px #8c8484 solid" }}>
                                <Grid container style={{ marginTop: "10px" }}>
                                    <Grid item sm={12}>
                                        {/* <Box> */}
                                        <div style={{ textAlign: "left" }}>
                                            <Typography style={{ color: "white", fontSize: "12px", marginLeft: "15px" }}>SFS - <b>{`${shippedCount}`} </b> {`(${ordersSummary.shippedPerc}%)`} </Typography>
                                        </div>
                                        {/* </Box> */}
                                    </Grid>
                                    <Grid item sm={12} style={{ textAlign: "left" }}>
                                        {/* <Box> */}
                                        <Typography style={{ color: "white", fontWeight: "bold", fontSize: "12px", marginLeft: "25px" }}>{'$'}{Utils.nFormatter(ordersSummary.totalShippedValue, 2)}</Typography>
                                        {/* </Box> */}
                                    </Grid>
                                </Grid>
                                <Grid container style={{}}>
                                    <Grid item sm={12}>
                                        {/* <Box> */}
                                        <div style={{ textAlign: "left" }}>
                                            <Typography style={{ color: "white", fontSize: "12px", marginLeft: "15px" }}>Used Items - <b>{`${usedItems.shippedUsedCount}`}</b> {`(${usedItems.shippedUsedPerc}%)`}  </Typography>
                                        </div>
                                        {/* </Box> */}
                                    </Grid>
                                    <Grid item sm={12} style={{ textAlign: "left" }}>
                                        {/* <Box> */}
                                        <Typography style={{ color: "white", fontWeight: "bold", fontSize: "12px", marginLeft: "25px" }}>{'$'}{Utils.nFormatter(usedItems.shippedUsedValue, 2)}</Typography>
                                        {/* </Box> */}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6} sm={6} >
                            <Grid container style={{ backgroundColor: "#5A5A5A", minHeight: "110px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                {/* {BOPIS - TOTAL - SECTION} */}
                                <Grid container style={{ marginTop: "5px" }}>
                                    <Grid item sm={12}>
                                        <div style={{ textAlign: "left", marginLeft: "15px" }}>
                                            <Typography style={{ color: "white", fontSize: "12px" }}>
                                                BOPIS
                                                - <b>{`${inStorePickupCount + stsCount} `} </b> {`(${ordersSummary.pickUpPerc}%)`}
                                            </Typography>
                                        </div>
                                    </Grid>
                                    <Grid item sm={12} style={{ textAlign: "left" }}>
                                        <Typography style={{ color: "white", fontWeight: "bold", marginLeft: "17px", fontSize: "12px" }}>{'$'}{Utils.nFormatter((inStorePickupValue + stsValue), 2)} </Typography>
                                    </Grid>
                                </Grid>
                                {/* {BOPIS - STS - SECTION} */}
                                <Grid container style={{ display: "flex", justifyContent: "space-around", marginTop: "3px" }}>
                                    <Grid item sm={12}>
                                        <div style={{ textAlign: "left", marginLeft: "25px" }}>
                                            <Typography style={{ color: "white", fontSize: "12px" }}>
                                                STS
                                                - <b>{`${stsCount} `} </b> {`(${stsCount ? setPercentage(stsCount / (stsCount + inStorePickupCount)) : 0}%)`}
                                            </Typography>

                                        </div>
                                    </Grid>
                                    <Grid item sm={12} style={{ textAlign: "left" }}>
                                        <Typography style={{ color: "white", fontWeight: "bold", marginLeft: "25px", fontSize: "12px" }}>{'$'}{Utils.nFormatter((stsValue), 2)}</Typography>
                                    </Grid>
                                </Grid>
                                {/* {BOPIS - USED ITEMS - SECTION} */}
                                <Grid container style={{ display: "flex", justifyContent: "space-around" }}>
                                    <Grid item sm={12}>
                                        <div style={{ textAlign: "left", marginLeft: "15px" }}>
                                            <Typography style={{ color: "white", fontSize: "12px" }}>Used Items - <b>{`${usedItems.STSUsedItemsNumbers + usedItems.inStoreUsedCount}`}</b>  {`(${(inStorePickupCount || stsCount) ? setPercentage((usedItems.STSUsedItemsNumbers + usedItems.inStoreUsedCount) / (inStorePickupCount + stsCount)) : 0}%)`} </Typography>
                                        </div>
                                    </Grid>
                                    <Grid item sm={12} style={{ textAlign: "left" }}>
                                        <Typography style={{ color: "white", fontWeight: "bold", marginLeft: "17px", fontSize: "12px" }}>{'$'}{Utils.nFormatter(usedItems.STSUsedItemsValue + usedItems.inStoreUsedValue, 2)}</Typography>
                                    </Grid>
                                </Grid>


                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            {
                isFulfilledOrdersLoaded && distList && <OrdersDataGrid dateFilter={props.dateFilter} open={gridPopUp} close={closeGridPopup} data={distList} isLoaded={isFulfilledOrdersLoaded} />
            }

        </React.Fragment>);
}
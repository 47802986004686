import { IconButton, Menu, MenuItem } from '@material-ui/core';
import React from 'react';
import Utils from '../../Common/Utils';
import './Navbar.scss';
import { AccountCircleOutlinedIcon, AddLocationAltIcon } from "../../Subcomponent/Shared/SharedIcons";
import { useNavigate } from 'react-router-dom';
import IdentityManager from '../../Managers/Identity.manager';
import AuthManager from '../../Managers/Auth.manager';
import SearchBar from './SeachBar';
import { FormControl, InputLabel, Select, Autocomplete, TextField, Box, Typography, Drawer, Card, CardContent } from '@mui/material';
import StoreManager from "../../Managers/Store.manager"
import { NotificationPopup } from '../../Subcomponent/Shared/NotificationPopup';
import { StoreListDropDown } from './StoreListDropDown';
import { CloseIcon } from '../../Subcomponent/Shared/SharedIcons';
import { INotificationPopupSettings, IButton } from '../../Models/INotificationPopupSettings';
import Constants from '../../Common/Constants';
import { DMStoreList } from './DMStoreList';
import { DistrictListDropDown } from './DistrictListDropDown';
import { RegionListDropDown } from './RegionListDropdown';
import { useDispatch } from 'react-redux';
import allActions from '../../Store/Actions/AllActions';
import { DrawerDropdowns } from './DrawerDropdowns';
// import tooltip
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import { styled } from '@mui/material/styles';


const WebAppbar = (props: any) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [anchorEl, setAnchorUserProfileEl] = React.useState<null | HTMLElement>(null);
    const user = IdentityManager.getUserProperty("name");
    const displayName = IdentityManager.getUserProperty("displayName");
    const [openStoreChagnePopup, setOpenStoreChagnePopup] = React.useState(false);
    const [districtPopup, setDistrictPopup] = React.useState(false);
    const [openDrawer, setDrawer] = React.useState(false);
    const handleMenuOpen = (event: any) => {
        setAnchorUserProfileEl(event.currentTarget)
    }
    const openStorePopup = () => {
        setOpenStoreChagnePopup(true)
    }


    const closeStorePopup = () => {
        setOpenStoreChagnePopup(false)
    }
    const openDistrictPopup = () => {
        setDistrictPopup(true);
    }
    const closeDistrictPopup = () => {
        setDistrictPopup(false);
    }

    const handleMenuClose = () => setAnchorUserProfileEl(null)
    const [age, setAge] = React.useState('');

    const handleChange = (event: any) => {
        setAge(event.target.value);
    };
    const toStart = (e: any) => {
        props.setSelectedOption('');
        navigate('/');
    }
    const renderSearch = () => {
        return (
            <SearchBar />
        );
    }

    const useStyles = makeStyles(theme => ({
        tooltip: {
            maxWidth: 400,
            overflowY: 'auto',
            maxHeight: 200,
        },
    }));

    const classes = useStyles();

    // Custom HTML tooltip
    const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
        <Tooltip PopperProps={{ style: { marginTop: 0 } }} {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#ffffff',
            color: 'rgba(0, 0, 0, 0.87)',
            maxWidth: 200,
            fontSize: theme.typography.pxToRem(12),
            border: '1px solid #dadde9',
            marginTop: "0px"
        },
    }));

    // Selected Stores data that needs to send in tooltip  
    const getSelectedStore = () => {

        if (!props.store.length) {
            return (<p>Please Select any store </p>)
        }


        const listItems = props.store.map((val: any) =>
            <li>{val.districtId} - {val.name}</li>
        );

        return (
            <React.Fragment>
                <ol style={{ listStyleType: 'none' }} className={classes.tooltip}>
                    {listItems}
                </ol>
            </React.Fragment >
        )

    }

    let changeStorePopupButtons: IButton[] = [
        {
            text: "Close",
            icon: <CloseIcon />,
            action: closeStorePopup,
            color: Constants.Colors.red,
        },
    ];
    let changeStore: INotificationPopupSettings = {
        open: openStoreChagnePopup,
        type: Constants.NotificationPopupType.Info,
        title: "Change Store",
        actions: changeStorePopupButtons,
        handleClose: closeStorePopup,
        isCustomJSX: true,
        msg: "",
        customJSX: <StoreListDropDown store={props.store} changeStoreList={props.changeStoreList}></StoreListDropDown>,
        //reason: reason,
        //description: description,
    }
    let changeDistrictPopupButtons: IButton[] = [
        {
            text: "Close",
            icon: <CloseIcon />,
            action: closeDistrictPopup,
            color: Constants.Colors.red,
        },
    ];
    let changeDistrict: INotificationPopupSettings = {
        open: districtPopup,
        type: Constants.NotificationPopupType.Info,
        title: "Change District",
        actions: changeDistrictPopupButtons,
        handleClose: closeDistrictPopup,
        isCustomJSX: true,
        msg: "",
        customJSX: <DistrictListDropDown district={props.district} changeDistrict={props.changeDistrict}></DistrictListDropDown>,
    }


    const renderStoreList = () => {
        return (
            <React.Fragment>
                {!Utils.isMobile ?
                    <StoreListDropDown store={props.store} changeStoreList={props.changeStoreList} ></StoreListDropDown>
                    :
                    <></>
                }
            </React.Fragment>
        );
    }

    const renderChangeDistrict = () => {
        return (
            // <Tooltip title={Constants.MESSAGES.SELECT_DISTRICT}>
            <div className={'profileContainer'}>
                <div className={'profileName'}>
                    <Typography title={Constants.MESSAGES.SELECT_DISTRICT} style={{ marginTop: 1, marginLeft: 5, cursor: "pointer" }} onClick={openDistrictPopup}><u>Choose District</u></Typography>
                </div>
            </div>)
        // </Tooltip>)
        // <Tooltip title="hello">
        // <Typography style={{marginTop: 22,marginLeft:5,cursor:"pointer"}} onClick={openDistrictPopup}><u>Choose District</u></Typography>
        // </Tooltip>

    }
    const openDrawerDropdowns = () => {
        dispatch(allActions.actionLoaderActions.setDrawerDropdowns(true));
    }

    const displayDrawer = () => {
        return (
            // <Tooltip title={Constants.MESSAGES.SELECT_DISTRICT}>
            <>
                {/* <HtmlTooltip title={getSelectedStore()}  > */}
                {/* <div className={'profileContainer'} style={{ marginLeft: "25px", marginTop: "20px", cursor: "pointer" }} onClick={openDrawerDropdowns}>
                    <Typography style={{ marginTop: 1, marginLeft: 5 }}>Choose Stores</Typography>
                    <AddLocationAltIcon style={{ marginLeft: "5px" }} />
                </div> */}

                <div style={{ textAlign: "center", marginLeft: "50px", marginTop: "5px", cursor: "pointer" }} onClick={openDrawerDropdowns}>
                    <div>
                        <AddLocationAltIcon style={{ fontSize: "30px" }} />
                    </div>
                    <div>
                        <Typography style={{ fontSize: "12px" }}>{props.store.length == 1 ? `${props.store[0].id}-${props.store[0].name}` : "Choose Stores"}</Typography>

                    </div>

                </div>

                {/* </HtmlTooltip> */}
            </>
        )
    }

    const renderWebBarTitle = () => {
        return <div style={{ display: 'flex' }}>
            <div className='logo-Container hvr-inner-spread' onClick={(e) => { toStart(e) }}>
            </div>
            {
                !Utils.isMobile && !props.loading &&
                <div onClick={props.openStoreInformation} className="storeDiv">
                    <div className='storeContainer'>
                        <div className='storeTitle'>Store Fulfillment</div>
                    </div>
                </div>
            }
        </div>
    }

    const renderWebbarProfile = () => {
        { }
        return user && !props.loading && (
            <div className={'profileContainer'}>
                <div className={'profileName'}><span style={{ fontWeight: 600 }}>Hi, </span>{displayName ? displayName : user}</div>
                <div className={'profileMenu'}>
                    <IconButton color="inherit" id="iconButton" onClick={handleMenuOpen}>
                        <AccountCircleOutlinedIcon style={{ fontSize: "40px" }} />
                    </IconButton>
                    <Menu
                        key={Utils.generateRandom(10, Constants.CryptoRandomTypes.numeric)}
                        open={open}
                        keepMounted
                        anchorEl={anchorEl}
                        getContentAnchorEl={null}
                        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                        transformOrigin={{ vertical: "top", horizontal: "center" }}
                        onClose={handleMenuClose}
                    >
                        <MenuItem onClick={() => props.setuserProfileDialog(true)} key={Utils.generateRandom(10, Constants.CryptoRandomTypes.numeric)}>Profile</MenuItem>
                        {/* {
                            IdentityManager.isAdmin ?
                                <MenuItem key={Utils.generateRandom(10, Constants.CryptoRandomTypes.numeric)} onClick={openStorePopup}>Change Store {props.store?.length == 1 ? "-(" + props.store[0].id + ")" : props.store?.length > 1 ? "-(Multiple)" : ""}</MenuItem>
                                :
                                ""
                        } */}
                        {/* <MenuItem key={Utils.generateRandom(10, Constants.CryptoRandomTypes.numeric)} onClick={openDistrictPopup}>Change District </MenuItem> */}

                        <MenuItem key={Utils.generateRandom(10, Constants.CryptoRandomTypes.numeric)} onClick={logout}>Sign Out</MenuItem>
                    </Menu>
                </div>
            </div>
        )
    }
    const webNavBar = () => {
        return (
            <React.Fragment>
                <NotificationPopup {...changeStore}></NotificationPopup>
                <NotificationPopup {...changeDistrict}></NotificationPopup>
                {renderWebBarTitle()}
                {/* <div style={{display:"flex",marginLeft:"20px"}}>
                    {Utils.isExternalRoute() || !IdentityManager.isAdmin ? "" : renderStoreList()}
                    {IdentityManager.isAdmin && renderChangeDistrict()}
                </div> */}
                <div style={{ display: "flex" }}>
                    {Utils.isExternalRoute() ? "" : renderSearch()}
                    {IdentityManager.showDrawerDropdown && displayDrawer()}
                </div>
                {Utils.isExternalRoute() ? "" : renderWebbarProfile()}
                <DrawerDropdowns region={props.region} changeRegion={props.changeRegion}
                    district={props.district} changeDistrict={props.changeDistrict}
                    store={props.store} changeStoreList={props.changeStoreList}></DrawerDropdowns>
            </React.Fragment>
        );
    }
    const logout = () => AuthManager.logout()
    const open = Boolean(anchorEl);
    return (
        <React.Fragment>
            {webNavBar()}
        </React.Fragment>
    );
}
export default WebAppbar;
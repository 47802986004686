import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Constants from '../../Common/Constants';
import { CloseIcon, SearchIcon } from "../../Subcomponent/Shared/SharedIcons"
import { InputBase, Paper, ListItemAvatar, ListItemIcon, ListItemSecondaryAction, } from '@mui/material';
import {
  Routes,
  Route,
  useLocation,
  useNavigate
} from "react-router-dom";
import allActions from '../../Store/Actions/AllActions';
import StoreManager from "../../Managers/Store.manager";
import OrderManager from "../../Managers/Orders.Manager"
import { TailSpin } from "react-loader-spinner";
import { useDispatch, useSelector } from 'react-redux';
export default function FullSearchSearch(prop: any) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchText, setSearchText] = React.useState("");
  const [showSuggestion, setShowSuggestion] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  let searchResult = useSelector((state: any) => state.SearchReducerState.searchResult);
  const [searchTimeout, setSearchTimeout] = React.useState<any | null>(null);
  const navigateToOrderDetail = (orderno: any, shipmentkey: any) => {
    navigate("/OrderDetail?orderno=" + orderno + "&shipmentkey=" + shipmentkey + "")
    prop.fullScreenClose()
  }
  const getCount = (row: any) => {
    let count = 0
    row._source.Shipment?.ShipmentLines?.ShipmentLine?.forEach((element: any) => {
      if (element.ExtnIsWarrantyItem == "N" && element.MinLineStatusDesc != "Cancelled" && element.BundleParentOrderLineKey == undefined) {
        count = count + Number(element.OrderedQty)
      }
    });
    return count
  }
  const getSearchResult = () => {
    if (searchText.length > 4) {
      let storeId = 0
      if (StoreManager.currentStore != undefined && StoreManager.currentStore.StoreId != undefined) {
        storeId = StoreManager.currentStore.StoreId
      }
      let query = {
        "search": searchText,
        "store": storeId
      }
      setLoading(true)
      OrderManager.searchOrder(query).then((result) => {
        if (result.success == true) {

          if (result?.data?.hits?.hits) {
            dispatch(allActions.searchActions.changeSearchResult(result?.data?.hits?.hits));
          }
        }
        setLoading(false)
      }).catch((error) => {
        console.log(error)
        setLoading(false)
      })
    }
  }
  React.useEffect(() => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    setSearchTimeout(
      setTimeout(() => {
        getSearchResult()
      }, 1000)
    );
    return () => clearTimeout(searchTimeout);
  }, [searchText]);
  const navigateToSearchResult = () => {
    navigate("/Search")
    prop.fullScreenClose()
  }
  const searchTextChange = (event: any) => {
    setSearchText(event.target.value)
    if (event.target.value.length > 2) {
      setShowSuggestion(true);
    }
    else {
      setShowSuggestion(false);
    }
  }
  return (
    <div>
      <Dialog
        fullScreen
        open={true}
        onClose={() => prop.fullScreenClose()}
      >
        <AppBar sx={{ position: 'relative' }} style={{ color: Constants.Colors.white, background: Constants.Colors.red }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => prop.fullScreenClose()}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Search
            </Typography>
            {/* <Button autoFocus color="inherit" onClick={() => navigateToSearchResult()}>
              Advance Search
            </Button> */}
          </Toolbar>
        </AppBar>
        <Paper style={{ marginTop: 5 }}
          sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}
        >
          <InputBase
            value={searchText}
            onChange={(event) => searchTextChange(event)}
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search Orders"
            inputProps={{ 'aria-label': 'search google maps' }}
            style={{ width: "100%" }}
          />
          <IconButton onClick={() => getSearchResult()} style={{ background: "#d6d3d3" }} sx={{ p: '10px' }} aria-label="search">
            <SearchIcon />
          </IconButton>
        </Paper>
        {
          loading == false ?
            <List style={{ padding: 0 }}>
              {
                searchResult.length == 0 && showSuggestion ?
                  <ListItem key={0} className="SearchHover" alignItems="flex-start" style={{ paddingTop: 2, paddingBottom: 2 }}>
                    <ListItemText
                      primary={"No Record Found"}
                      secondary={""}
                    />
                  </ListItem>
                  :
                  <React.Fragment>
                    {
                      searchResult.map((row: any, index: any) => {
                        return (
                          <div>
                            <ListItem key={index} onClick={() => navigateToOrderDetail(row._source.Shipment.OrderNo, row._source.Shipment.ShipmentKey)} className="SearchHover" alignItems="flex-start" style={{ paddingTop: 2, paddingBottom: 2 }}>
                              <ListItemText
                                primary={row._source.Shipment.OrderNo}
                                secondary={
                                  <React.Fragment>
                                    {"Customer Name:"}
                                    <Typography
                                      sx={{ display: "inline" }}
                                      component="span"
                                      color="text.primary"
                                      style={{ marginRight: 10 }}
                                    >
                                      {row._source.Shipment.CustomerInfo.FirstName + " " + row._source.Shipment.CustomerInfo.LastName}
                                    </Typography>
                                    {"QTY:"}
                                    <Typography
                                      sx={{ display: "inline" }}
                                      component="span"
                                      color="text.primary"
                                    >
                                      {getCount(row)}
                                    </Typography>
                                    <br/>
                                    {"Tracking ID:"}
                                    {row._source.Shipment?.Containers?.Container.map((container: any) => {
                                      return (
                                        <Typography
                                          sx={{ display: "inline" }}
                                          component="span"
                                          color="text.primary"
                                          style={{ marginRight: 10 }}
                                        >
                                          {row._source.Shipment?.Containers?.Container.length > 1 ? `${container?.TrackingNo},` : container?.TrackingNo}

                                        </Typography>
                                      )
                                    })}
                                  </React.Fragment>
                                }
                              />
                            </ListItem>
                            <Divider />
                          </div>
                        );
                      })
                    }
                  </React.Fragment>
              }
            </List>
            :
            <React.Fragment>
              {
                showSuggestion ?
                  <List style={{ paddingTop: 20, textAlign: "center" }}>
                    <TailSpin color="#D93A2F" height={80} width={80} />
                  </List>
                  :
                  <></>
              }
            </React.Fragment>
        }
      </Dialog>
    </div>
  );
}

import React, { useCallback, useState, useEffect } from 'react';
import { Button, IconButton, Paper, Toolbar, Typography, Drawer, Card, CardContent, TextField } from '@mui/material';
import Utils from '../../Common/Utils';
import { ArrowBackIcon, RestartAltIcon, FlashOffIcon, CheckIcon, } from "../Shared/SharedIcons";
import Constants from '../../Common/Constants';
import ScanditBarcodeScanner from "scandit-sdk-react";
import StoreManager from '../../Managers/Store.manager';
import {
    Barcode,
    BarcodePicker,
    Camera,
    CameraAccess,
    CameraSettings,
    ScanSettings,
    SingleImageModeSettings,
} from "scandit-sdk";
const MobileBarCodeScannerTrackingNumberScandit = (props: any) => {
    const licenseKey = StoreManager.config.scanditLicense;
    const [isOneScanned, setisOneScanned] = useState(false)
    const width = Utils.getWebbarWidth
    const [openDrawer, setDrawer] = useState(false);
    const barCode: any = '';
    const d: any = null;
    const [scanData, setData] = useState(d);
    const getScanSettings = () => {
        return new ScanSettings({
            enabledSymbologies: [
                Barcode.Symbology.QR,
                //Barcode.Symbology.GS1_DATABAR,
                //Barcode.Symbology.GS1_DATABAR_EXPANDED,
                //Barcode.Symbology.GS1_DATABAR_LIMITED,
                Barcode.Symbology.CODE39,
                //Barcode.Symbology.INTERLEAVED_2_OF_5,
                Barcode.Symbology.CODE128,
                Barcode.Symbology.UPCA,
                Barcode.Symbology.UPCE,
                Barcode.Symbology.EAN13,
                Barcode.Symbology.EAN8,
                Barcode.Symbology.CODE128
            ],
        });
    };
    const onScanned = (scan: any) => {
        let resultValue = scan.barcodes.reduce((string: string, barcode: { data: any; }) => {
            return string + `${barcode.data}`;
        }, "");
        if (scan.barcodes[0]?.symbology == "upca") {
            if (resultValue.charAt(0) === '0') {
                resultValue = resultValue.substring(1);
            }
        }
        setData(resultValue);
        setisOneScanned(true)
        //setDrawer(true);  
        props.onScanned(resultValue, false);
    }
    const getSingleImageModeSettings = () => {
        return {
            desktop: {
                usageStrategy: SingleImageModeSettings.UsageStrategy.FALLBACK,
            },
            mobile: {
                usageStrategy: SingleImageModeSettings.UsageStrategy.FALLBACK,
            },
        };
    };
    const handleError = (err: any) => {
        console.error(err);
    }
    const changeScanData = (event: any) => {
        setData(event.target.value)
    }
    return (<div>
        <Drawer
            style={{ zIndex: 99999 }}
            anchor={"top"}
            open={openDrawer}
            onClose={() => { }}
            onClick={() => { }}
        >
            <Card style={{ margin: "6px" }}>
                <CardContent style={{ padding: "10px" }}>
                    <Typography variant="h5" component="div" style={{ fontSize: "18px" }}>
                        Enter Bar Code
                        <br />
                    </Typography>
                    <br></br>
                    <TextField fullWidth variant={"outlined"} type={"number"} placeholder='SKU#' id="fullWidth" value={scanData} />
                    <br></br>
                    <br></br>
                    <Typography variant="h5" component="div" style={{ fontSize: "18px" }}>
                        Quantity
                        <br />
                    </Typography>
                    <br></br>
                    <TextField fullWidth variant={"outlined"} type={"number"} InputProps={{ inputProps: { min: 1 } }} defaultValue={1} InputLabelProps={{ shrink: false }} label="" placeholder='Quantity' id="fullWidth" />
                    <Button onClick={() => { setDrawer(false); setisOneScanned(false) }} style={{ marginTop: "20px", marginRight: "5px", width: "48%", color: Constants.Colors.red, borderColor: Constants.Colors.red }} variant='outlined' className='' startIcon={<RestartAltIcon />}>Retry</Button>
                    <Button onClick={() => { props.onScanned("", false); }} style={{ marginTop: "20px", width: "48%" }} variant='contained' className='btn' startIcon={<CheckIcon />}>Done</Button>
                </CardContent>
            </Card>
        </Drawer>
        <div className='containerTypeBarCodeMbl'>
        </div>
        <Paper id='bar' style={{ color: "white", top: 0, left: 0, right: 0, zIndex: 999 }} className="appBarPrimary">
            <Toolbar style={{ height: "46px", minHeight: "46px" }}>
                <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ mr: 2 }}
                    onClick={() => { props.onScanned("", false); }}
                >
                    <ArrowBackIcon />
                </IconButton>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} style={{ marginRight: 60 }}>
                    Scanner
                </Typography>
            </Toolbar>
        </Paper>
        {
            isOneScanned == false ?
                <ScanditBarcodeScanner
                    visible={true}
                    licenseKey={licenseKey}
                    playSoundOnScan={true}
                    enableTapToFocus={true}
                    vibrateOnScan={true}
                    engineLocation="https://cdn.jsdelivr.net/npm/scandit-sdk@5.x/build" // could also be a local folder, e.g. "build"            
                    //onReady={}
                    onScan={(scan: any) => onScanned(scan)}
                    onScanError={(error: any) => handleError(error)}
                    // Picker options
                    accessCamera={true}
                    enableCameraSwitcher={true}
                    enablePinchToZoom={true}
                    enableTorchToggle={true}
                    scanSettings={getScanSettings()}
                    videoFit={BarcodePicker.ObjectFit.COVER}
                    cameraType={Camera.Type.BACK}
                    singleImageModeSettings={getSingleImageModeSettings()}
                    guiStyle={BarcodePicker.GuiStyle.VIEWFINDER}
                />
                :
                <div></div>
        }
    </div>)
}
export default MobileBarCodeScannerTrackingNumberScandit;
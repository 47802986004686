import React, { useState } from "react";
import PFManager from "../Managers/Process.Fulfillment.Manager";
import IdentityManager from "../Managers/Identity.manager";
import DataTable from "react-data-table-component";
import { IDashboardGridData, IDistrictGridData, IEnterpriseDashboardGridData, IRegionGridData, IStoreGridData } from "../Models/IDashboardGridData";
import Utils from "../Common/Utils";
import StackGraph from "../Subcomponent/Dashboard/StackGraph";
import { Button, Card, CardContent, Paper, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";
import { DashboardGridHeader } from "./DashboardGridHeader";
import { useDispatch, useSelector } from "react-redux";
import StoreManager from "../Managers/Store.manager";
import allActions from "../Store/Actions/AllActions";
import Excel from 'exceljs';


export const DashboardGrid = (props: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [gridData, setGridData] = useState<IDistrictGridData[]>([]);
  const [regionGridData, setRegionGridData] = useState<IRegionGridData[]>([]);
  const districts = useSelector((state: any) => state.ActionLoaderReducersState.districts);
  const regions = useSelector((state: any) => state.ActionLoaderReducersState.regions);
  const isEnterprise = useSelector((state: any) => state.ActionLoaderReducersState.isEnterprise);

  // Make new Instance of Excel js
  const workbook = new Excel.Workbook();

  // Excel Sheet Columns Name

  let columns: any = []
  // Excel Data sheet
  const saveExcel = async () => {

    let excelData: any = [];
    // For region data
    if (regionGridData?.length) {
      columns = [
        { header: 'Region', key: 'regionName' },
        { header: 'District', key: 'districtName' },
        { header: 'Store', key: 'storeName' },
        { header: 'Pick', key: 'pick' },
        { header: 'Pack', key: 'pack' },
        { header: 'Ship', key: 'ship' },
        { header: 'Order Value ($)', key: 'orderValues' }

      ];
      for (let i = 0; i < regionGridData.length; i++) {
        for (let j = 0; j < regionGridData[i].regionDistrictList.length; j++) {
          for (let k = 0; k < regionGridData[i].regionDistrictList[j].districtStoresList.length; k++) {
            let singleDataObj: any = {}
            singleDataObj['regionName'] = regionGridData[i].region
            singleDataObj['districtName'] = regionGridData[i].regionDistrictList[j].district
            singleDataObj['storeName'] = regionGridData[i].regionDistrictList[j].districtStoresList[k].storeName
            singleDataObj['pick'] = regionGridData[i].regionDistrictList[j].districtStoresList[k].pick
            singleDataObj['pack'] = regionGridData[i].regionDistrictList[j].districtStoresList[k].pack
            singleDataObj['ship'] = regionGridData[i].regionDistrictList[j].districtStoresList[k].ship
            singleDataObj['orderValues'] = regionGridData[i].regionDistrictList[j].districtStoresList[k].dollarValue
            excelData.push(singleDataObj)
          }

        }
      }
    }
    // For district and single store
    if (gridData?.length) {
      columns = [
        { header: 'District', key: 'districtName' },
        { header: 'Store', key: 'storeName' },
        { header: 'Pick', key: 'pick' },
        { header: 'Pack', key: 'pack' },
        { header: 'Ship', key: 'ship' },
        { header: 'Order Value ($)', key: 'orderValues' }

      ];
      for (let i = 0; i < gridData.length; i++) {
        for (let j = 0; j < gridData[i].districtStoresList.length; j++) {
          let singleDataObj: any = {}
          singleDataObj['districtName'] = gridData[i].district
          singleDataObj['storeName'] = gridData[i].districtStoresList[j].storeName
          singleDataObj['pick'] = gridData[i].districtStoresList[j].pick
          singleDataObj['pack'] = gridData[i].districtStoresList[j].pack
          singleDataObj['ship'] = gridData[i].districtStoresList[j].ship
          singleDataObj['orderValues'] = gridData[i].districtStoresList[j].dollarValue
          excelData.push(singleDataObj)
        }

      }

    }

    const workSheetName = 'Worksheet-1';
    try {
      const fileName = 'Order_fulfilment';

      // creating one worksheet in workbook
      const worksheet = workbook.addWorksheet(workSheetName);

      // add worksheet columns
      // each columns contains header and its mapping key from data
      worksheet.columns = columns;

      // updated the font for first row.
      worksheet.getRow(1).font = { bold: true };

      // loop through all of the columns and set the alignment with width.
      worksheet.columns.forEach((column: any) => {
        column.width = column.header.length + 30;
        column.alignment = { horizontal: 'center' };
      });

      // loop through data and add each one to worksheet
      excelData.forEach((singleData: any) => {
        worksheet.addRow(singleData);
      });

      // loop through all of the rows and set the outline style.
      worksheet.eachRow({ includeEmpty: false }, (row: any) => {
        // store each cell to currentCell
        const currentCell = row._cells;

        // loop through currentCell to apply border only for the non-empty cell of excel
        currentCell.forEach((singleCell: any) => {
          // store the cell address i.e. A1, A2, A3, B1, B2, B3, ...
          const cellAddress = singleCell._address;

          // apply border
          worksheet.getCell(cellAddress).border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
          };
        });
      });

      // write the content using writeBuffer
      const buf = await workbook.xlsx.writeBuffer();

      // download the processed file
      const url = window.URL.createObjectURL(new Blob([buf]));
      const anchor = document.createElement('a');
      anchor.href = url;
      anchor.download = 'Order_fulfilment.xlsx';
      anchor.click();
      window.URL.revokeObjectURL(url);
      // saveAs(new Blob([buf]), `${fileName}.xlsx`);
    } catch (error) {
      console.error('<<<ERRROR>>>', error);
      console.error('Something Went Wrong', error);
    } finally {
      // removing worksheet's instance to create new one
      workbook.removeWorksheet(workSheetName);
    }
  };

  const storeNavigation = (store: number) => {
    const selectedStore = props.store.find((x: any) => x.id == store);
    dispatch(allActions.actionLoaderActions.setEnterprise(false));
    props.changeRegion(null, []);
    props.changeStore(null, [selectedStore]);
    navigate("/FulfillOrders", { state: { dashboardNavigate: true } });

  }

  const districtNavigation = (district: number) => {
    const selectedDistrict = districts.find((x: any) => x.id == district);
    dispatch(allActions.actionLoaderActions.setEnterprise(false));
    props.changeRegion(null, []);
    props.changeDistrict(null, [selectedDistrict]);
    navigate("/FulfillOrders", { state: { dashboardNavigate: true } });

  }

  const regionNavigation = (region: any) => {
    const selectedRegion = regions.find((x: any) => x.id == region);
    dispatch(allActions.actionLoaderActions.setEnterprise(false));
    props.changeRegion(null, [selectedRegion]);
    navigate("/FulfillOrders", { state: { dashboardNavigate: true } });

  }

  const getDollarValue = (value: any) => {
    let amount = Utils.nFormatter(value, 2);
    if (amount) {
      return '$' + amount;
    }
    return "";
  }

  const getRegionColumns = () => {
    const columns = [
      {
        id: "region",
        name: 'Region',
        sortable: true,
        selector: (row: IRegionGridData) => row.regionId,
        cell: (row: IRegionGridData) => <Button onClick={() => { regionNavigation(row.regionId) }} style={{ fontWeight: "bold", fontSize: "15px" }}>{row.region}</Button>,
        width: "300px",
        minWidth: "300px"
      },
      {
        id: "regionCount",
        name: <Tooltip title="Total Orders (Pick + Pack + Ship)"><div>Total Orders</div></Tooltip>,
        sortable: true,
        selector: (row: IRegionGridData) => (row.regionPick + row.regionPack + row.regionShip),
        cell: (row: IRegionGridData) => renderTotalOrders(row.regionPick, row.regionPack, row.regionShip)
      },
      {
        id: "regionAvgAge",
        name: 'Order Age (Avg)',
        sortable: true,
        selector: (row: IRegionGridData) => row.regionAvgAge,
        cell: (row: IRegionGridData) => row.regionAvgAge == 0 ? "-" : Utils.hoursTextFormatter(row.regionAvgAge),
      },
      {
        id: "regionDollarValue",
        name: 'Orders Value',
        sortable: true,
        selector: (row: IRegionGridData) => row.regionDollarValue,
        cell: (row: IRegionGridData) => row.regionDollarValue == 0 ? "-" : getDollarValue(row.regionDollarValue),
      },
      {
        id: "regionStackedGraph",
        name: '',
        cell: (row: IRegionGridData) => (!row.regionPick && !row.regionPack && !row.regionShip) ? '-' : <StackGraph pick={row.regionPick}
          pack={row.regionPack} ship={row.regionShip} />,
        minWidth: "300px"
      }
    ];
    return columns;
  }

  const getDistrictColumns = () => {
    const columns = [
      {
        id: "district",
        name: 'District',
        sortable: true,
        selector: (row: IDistrictGridData) => row.districtId,
        cell: (row: IDistrictGridData) => <Button onClick={() => { districtNavigation(row.districtId) }}>{row.district}</Button>,
        width: "300px",
        minWidth: "300px"
      },
      {
        id: "districtCount",
        name: <Tooltip title="Total Orders (Pick + Pack + Ship)"><div style={{ whiteSpace: "nowrap" }}>Total Orders</div></Tooltip>,
        sortable: true,
        selector: (row: IDistrictGridData) => (row.districtPick + row.districtPack + row.districtShip),
        cell: (row: IDistrictGridData) => renderTotalOrders(row.districtPick, row.districtPack, row.districtShip),
      },
      {
        id: "districtAvgAge",
        name: 'Order Age (Avg)',
        sortable: true,
        selector: (row: IDistrictGridData) => row.districtAvgAge,
        cell: (row: IDistrictGridData) => row.districtAvgAge == 0 ? "-" : Utils.hoursTextFormatter(row.districtAvgAge),
      },
      {
        id: "districtDollarValue",
        name: 'Orders Value',
        sortable: true,
        selector: (row: IDistrictGridData) => row.districtDollarValue,
        cell: (row: IDistrictGridData) => row.districtDollarValue == 0 ? "-" : getDollarValue(row.districtDollarValue),
      },
      {
        id: "stackedGraph",
        name: '',
        cell: (row: any) => (!row.districtPick && !row.districtPack && !row.districtShip) ? '-' : <StackGraph pick={row.districtPick}
          pack={row.districtPack} ship={row.districtShip} />,
        minWidth: "300px"
      }
    ];
    return columns;
  }

  const renderTotalOrders = (pick: number, pack: number, ship: number) => {
    let totalOrders = pick + pack + ship;
    return totalOrders == 0 ? "-" : totalOrders;
  }

  const getStoreColumns = () => {
    const columns = [
      {
        id: "store",
        name: 'Store',
        sortable: true,
        selector: (row: IStoreGridData) => row.storeId,
        cell: (row: IStoreGridData) => <Button onClick={() => { storeNavigation(row.storeId) }}>{row.storeName}</Button>,
        width: "250px",
        minWidth: "250px"
      },
      {
        id: "ordersCount",
        name: 'Total Orders',
        sortable: true,
        selector: (row: IStoreGridData) => row.pick + row.pack + row.ship,
        cell: (row: IStoreGridData) => renderTotalOrders(row.pick, row.pack, row.ship),
      },
      {
        id: "storeAvgAge",
        name: 'Order Age (Avg)',
        sortable: true,
        selector: (row: IStoreGridData) => row.storeAvgAge,
        cell: (row: IStoreGridData) => row.storeAvgAge == 0 ? "-" : Utils.hoursTextFormatter(row.storeAvgAge),
      },
      {
        id: "dollarValue",
        name: 'Orders Value',
        sortable: true,
        selector: (row: IStoreGridData) => row.dollarValue,
        cell: (row: IStoreGridData) => row.dollarValue == 0 ? "-" : getDollarValue(row.dollarValue),
      },
      {
        id: "stackedGraph",
        name: '',
        cell: (row: any) => (!row.pick && !row.pack && !row.ship) ? '-' : <StackGraph pick={row.pick}
          pack={row.pack} ship={row.ship} />,
        minWidth: "300px"
      }

    ];
    return columns;
  }

  const districtExpandedRows = (props: any) => {
    const { regionDistrictList } = props.data;

    return (
      <Card id='cardOrder' style={{ margin: "5px", width: "98%", height: "98%" }}>
        <CardContent style={{ padding: 3 }}>
          <div className='dataTableDashboardExpanded'>
            <DataTable
              className='dataTableDashboardExpanded'
              title=""
              data={regionDistrictList}
              columns={getDistrictColumns()}
              expandableRows
              expandableRowsComponent={storeExpandedRows}
            />
          </div>
        </CardContent >
      </Card >
    );
  }

  const storeExpandedRows = (props: any) => {
    const { districtStoresList } = props.data;
    return (

      <Card id='cardOrder' style={{ margin: "5px", marginLeft: "10px", width: "98%", height: "98%" }}>
        <CardContent style={{ padding: 3 }}>
          <div className="dataTableDashboardExpanded">
            <DataTable
              title=""
              className=''
              data={districtStoresList}
              columns={getStoreColumns()}
            // pagination
            // paginationRowsPerPageOptions={[5, 10, 15, 20]}
            // paginationPerPage={5}
            />
          </div >
        </CardContent >
      </Card >

    );
  }

  const groupRegionData = (data: IStoreGridData[]) => {
    // set the region Id, region Name, District Id and District Name
    data.forEach((store: IStoreGridData) => {
      let storeData: any = StoreManager.allStoredata.find((d: any) => d.StoreId == store.storeId);
      if (storeData) {
        store.storeName = `${storeData.StoreId}-${storeData.StoreName}`;
        store.regionId = storeData.RegionId;
        store.regionName = storeData.RegionName;
        store.districtId = storeData.DistrictId;
        store.districtName = storeData.DistrictName;
      }

    })

    let groupedData: any = Utils.groupByFields(data, ["regionName", "districtName"])
    console.log(groupedData, "groupedData");

    let dashboardGrid: IEnterpriseDashboardGridData = { regionData: [] };

    Object.keys(groupedData).forEach(function (regionName: any) {
      // Data for Regsion Districts
      let regionData: IRegionGridData = {
        regionId: "",
        region: "",
        regionPick: 0,
        regionPack: 0,
        regionShip: 0,
        regionCustomerPickup: 0,
        regionAvgAge: 0,
        regionDollarValue: 0,
        regionDistrictList: []
      };
      let districts: any = groupedData[regionName];
      Object.keys(districts).forEach(function (districtName: any) {

        // Data for indiviudal Districts - represents stores.
        let individualDistrictData: any[] = districts[districtName];

        let districtObj: IDistrictGridData = {
          districtId: "",
          district: "",
          districtPick: 0,
          districtPack: 0,
          districtShip: 0,
          districtCustomerPickup: 0,
          districtAvgAge: 0,
          districtDollarValue: 0,
          districtStoresList: []
        };

        individualDistrictData.forEach((store: IStoreGridData) => {
          // Push the individual Stores Data
          districtObj.districtStoresList.push(store);
        })

        districtObj.districtId = districtObj.districtStoresList[0].districtId;
        districtObj.district = districtObj.districtStoresList[0].districtName;
        districtObj.districtPick = districtObj.districtStoresList.reduce((accumulator: any, currentObject: IStoreGridData) => {
          return accumulator + currentObject.pick;
        }, 0);
        districtObj.districtPack = districtObj.districtStoresList.reduce((accumulator: any, currentObject: IStoreGridData) => {
          return accumulator + currentObject.pack;
        }, 0);
        districtObj.districtShip = districtObj.districtStoresList.reduce((accumulator: any, currentObject: IStoreGridData) => {
          return accumulator + currentObject.ship;
        }, 0);
        districtObj.districtCustomerPickup = districtObj.districtStoresList.reduce((accumulator: any, currentObject: IStoreGridData) => {
          return accumulator + currentObject.customerPickup;
        }, 0);
        districtObj.districtDollarValue = districtObj.districtStoresList.reduce((accumulator: any, currentObject: IStoreGridData) => {
          return accumulator + currentObject.dollarValue;
        }, 0);

        let storeAvgAgesLen: any = districtObj.districtStoresList.filter(x => x.storeAvgAge > 0).length;
        let storeAvgAgeSum: any = districtObj.districtStoresList.reduce((accumulator: any, currentObject: IStoreGridData) => {
          return accumulator + currentObject.storeAvgAge;
        }, 0);
        districtObj.districtAvgAge = storeAvgAgesLen > 0 ? (storeAvgAgeSum / storeAvgAgesLen) : 0;

        regionData.regionDistrictList.push(districtObj);

      })

      regionData.regionId = regionData.regionDistrictList[0].districtStoresList[0].regionId;
      regionData.region = regionData.regionDistrictList[0].districtStoresList[0].regionName;
      regionData.regionPick = regionData.regionDistrictList.reduce((accumulator: any, currentObject: IDistrictGridData) => {
        return accumulator + currentObject.districtPick;
      }, 0);
      regionData.regionPack = regionData.regionDistrictList.reduce((accumulator: any, currentObject: IDistrictGridData) => {
        return accumulator + currentObject.districtPack;
      }, 0);
      regionData.regionShip = regionData.regionDistrictList.reduce((accumulator: any, currentObject: IDistrictGridData) => {
        return accumulator + currentObject.districtShip;
      }, 0);
      regionData.regionCustomerPickup = regionData.regionDistrictList.reduce((accumulator: any, currentObject: IDistrictGridData) => {
        return accumulator + currentObject.districtCustomerPickup;
      }, 0);
      regionData.regionDollarValue = regionData.regionDistrictList.reduce((accumulator: any, currentObject: IDistrictGridData) => {
        return accumulator + currentObject.districtDollarValue;
      }, 0);

      let districtAvgAgesLen: any = regionData.regionDistrictList.filter(x => x.districtAvgAge > 0).length;
      let districtAvgAgeSum: any = regionData.regionDistrictList.reduce((accumulator: any, currentObject: IDistrictGridData) => {
        return accumulator + currentObject.districtAvgAge;
      }, 0);
      regionData.regionAvgAge = districtAvgAgesLen > 0 ? (districtAvgAgeSum / districtAvgAgesLen) : 0;

      dashboardGrid.regionData.push(regionData);

    })

    console.log(dashboardGrid, "dashboardGrid");
    return dashboardGrid.regionData;


  }

  const groupDistrictData = (data: IStoreGridData[]) => {
    let dashboardGrid: IDashboardGridData = { districtData: [] };
    data.forEach((store: IStoreGridData) => {
      const storeObj = props.store.find((s: any) => s.id == store.storeId);
      if (storeObj.districtId) {
        let district = dashboardGrid.districtData.find((x: IDistrictGridData) => x.districtId == storeObj.districtId)
        if (district) {
          store.storeName = `${store.storeId}-${storeObj.name}`;
          district.districtPick += store.pick;
          district.districtPack += store.pack;
          district.districtShip += store.ship;
          district.districtCustomerPickup += store.customerPickup;
          district.districtAvgAge += store.storeAvgAge;
          district.districtDollarValue += store.dollarValue;
          district.districtStoresList.push(store);
        }
        else {
          const districtName = districts.find((d: any) => d.id == storeObj.districtId)?.name;
          store.storeName = `${store.storeId}-${storeObj.name}`;
          let districtObj: IDistrictGridData = {
            districtId: storeObj.districtId,
            district: districtName,
            districtPick: store.pick,
            districtPack: store.pack,
            districtShip: store.ship,
            districtCustomerPickup: store.customerPickup,
            districtAvgAge: store.storeAvgAge,
            districtDollarValue: store.dollarValue,
            districtStoresList: [store]
          }
          dashboardGrid.districtData.push(districtObj);
        }
      }
    });

    dashboardGrid.districtData.forEach((district: IDistrictGridData) => {
      let storeAvgAgesLen = district.districtStoresList.filter(x => x.storeAvgAge > 0).length;
      district.districtAvgAge = storeAvgAgesLen > 0 ? (district.districtAvgAge / storeAvgAgesLen) : 0;
    })
    console.log(dashboardGrid.districtData, "dashboardGrid.districtData");
    return dashboardGrid.districtData;

  }

  const loadGridData = async () => {

    let storeId = IdentityManager.getMultipleStoresId(props.store);
    let payload = {
      "store": storeId
    };
    await PFManager.getDashboardGridData(payload)
      .then((response: any) => {
        if (response.success) {
          if (IdentityManager.isDM || IdentityManager.isRvp) {
            let groupedData = groupDistrictData(response.data);
            setGridData(groupedData);


          }
          else {
            let regionData = groupRegionData(response.data);
            console.log("regionData", regionData);

            setRegionGridData(regionData);
          }
        }
        else {
          console.log(response);
        }
      }).catch((error: any) => {
        console.log(error);
      })
  }

  const getHeaderData = () => {
    if (IdentityManager.isDM) {
      const dmData = {
        name: gridData[0].district,
        totalOrders: (gridData[0].districtPick + gridData[0].districtPack + gridData[0].districtShip + gridData[0].districtCustomerPickup),
        ordersAge: gridData[0].districtAvgAge,
        ordersValue: gridData[0].districtDollarValue,
        pick: gridData[0].districtPick,
        pack: gridData[0].districtPack,
        ship: gridData[0].districtShip
      }
      return dmData;
    }
    else if (IdentityManager.isRvp) {
      let rvpData: any = { totalOrders: 0, ordersAge: 0, ordersValue: 0, pick: 0, pack: 0, ship: 0 };
      gridData.forEach((row: IDistrictGridData) => {
        rvpData.totalOrders += (row.districtPick + row.districtPack + row.districtShip + row.districtCustomerPickup);
        rvpData.ordersAge += row.districtAvgAge;
        rvpData.ordersValue += row.districtDollarValue;
        rvpData.pick += row.districtPick;
        rvpData.pack += row.districtPack;
        rvpData.ship += row.districtShip;
      });
      let rvpDataAvgsLen = gridData.filter(x => x.districtAvgAge > 0).length;
      rvpData.ordersAge = rvpDataAvgsLen > 0 ? (rvpData.ordersAge / rvpDataAvgsLen) : 0;
      return rvpData;
    }
    else if (IdentityManager.isEnterprise || IdentityManager.isAdmin) {
      const regionData: any = { totalOrders: 0, ordersAge: 0, ordersValue: 0, pick: 0, pack: 0, ship: 0 };
      regionGridData.forEach((row: IRegionGridData) => {
        regionData.totalOrders += (row.regionPick + row.regionPack + row.regionShip);
        regionData.ordersAge += row.regionAvgAge;
        regionData.ordersValue += row.regionDollarValue;
        regionData.pick += row.regionPick;
        regionData.pack += row.regionPack;
        regionData.ship += row.regionShip;
      });
      let regionDataAvgsLen = regionGridData.filter(x => x.regionAvgAge > 0).length;
      regionData.ordersAge = regionDataAvgsLen > 0 ? (regionData.ordersAge / regionDataAvgsLen) : 0;
      return regionData;
    }
    return {};
  }

  React.useEffect(() => {
    loadGridData();
  }, [props.store])

  return (
    <>
      {
        gridData && gridData.length > 0 && IdentityManager.isDM ?
          <>
            <DashboardGridHeader data={getHeaderData()} />
            <div style={{ float: "right", marginBottom: "5px" }}>
              {!Utils.isMobile && <Button className="btn" variant="contained" onClick={saveExcel}>Export To Excel</Button>}
            </div>
            <div className='dataTableRounded'>
              <DataTable
                className='dataTableRounded'
                title=""
                data={gridData[0].districtStoresList}
                columns={getStoreColumns()}
              />
            </div>
          </>
          :
          gridData && gridData.length > 0 && (IdentityManager.isRvp) ?
            <>
              <DashboardGridHeader data={getHeaderData()} />
              <div style={{ float: "right", marginBottom: "5px" }}>
                {!Utils.isMobile && <Button className="btn" variant="contained" onClick={saveExcel}>Export To Excel</Button>}
              </div>
              <div className='dataTableRounded'>
                <DataTable
                  className='dataTableRounded'
                  title=""
                  data={gridData}
                  columns={getDistrictColumns()}
                  expandableRows
                  expandableRowsComponent={storeExpandedRows}
                />
              </div>
            </>
            :
            regionGridData && regionGridData.length > 0 && (IdentityManager.isEnterprise || IdentityManager.isAdmin) ?
              <>
                <DashboardGridHeader data={getHeaderData()} />
                <div style={{ float: "right", marginBottom: "5px" }}>
                  {!Utils.isMobile && <Button className="btn" variant="contained" onClick={saveExcel}>Export To Excel</Button>}
                </div>
                <div className='dataTableRounded'>
                  <DataTable
                    className='dataTableRounded'
                    title=""
                    data={regionGridData}
                    columns={getRegionColumns()}
                    expandableRows
                    expandableRowsComponent={districtExpandedRows}
                    expandableRowExpanded={() => true}
                  />
                </div>
              </>
              :
              <>
                <TailSpin color="#D93A2F" height={80} width={80} wrapperStyle={{ justifyContent: "center" }} />
              </>
      }
    </>

  );

}
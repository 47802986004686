import { Button, Card, CardContent, Checkbox, Divider, Drawer, FormControl, FormControlLabel, Grid, Paper, RadioGroup, TextField, Toolbar, Typography, Select, SelectChangeEvent, MenuItem, Tooltip, Box, SwipeableDrawer } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Utils from '../Common/Utils';
import Constants from '../Common/Constants';
import Radio from '@mui/material/Radio';
import { IconButton } from '@mui/material';
import Autocomplete, { AutocompleteRenderInputParams } from '@mui/material/Autocomplete';
import { VerticalLinearStepper } from '../Subcomponent/Stepper/VerticalLinearStepper'
import { IVerticalStepper, IStepOption } from '../Models/IVerticalStepper';
import SiteBreadCrumbs from '../Subcomponent/Shared/SiteBreadCrumbs'
import { Note } from "../Subcomponent/Shared/Note";
import { NotificationPopup } from '../Subcomponent/Shared/NotificationPopup'
import { INotificationPopupSettings, IButton } from '../Models/INotificationPopupSettings';
import LabelText from "../Common/LabelText"
import { ICustomerDetail } from '../Models/IPickOrder'
import allActions from "../Store/Actions/AllActions"
import OMSManager from "../Managers/Oms.manager";
import OrderLineShimmer from "../Subcomponent/PickOrder/OrderLineShimmer"
import OrderLineShimmerMobile from "../Subcomponent/PickOrder/OrderLineShimmerMobile"
import { PickupPrintSlip } from '../Subcomponent/CustomerPickup/PickupPrintSlip';
import CustomerDetailShimmer from "../Subcomponent/PickOrder/CustomerDetailShimmer"
import CustomerDetailShimmerMobile from "../Subcomponent/PickOrder/CustomerDetailShimmerMobile"
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { ICustomerPickupDetail, ContainerDetail, Component } from "../Models/ICustomerPickupDetail";
import StoreManager from "../Managers/Store.manager";
import ApiService from "../Common/ApiService";
import OrderManager from '../Managers/Orders.Manager';
import { AddCommentIcon, CloseIcon, CancelIcon, CommentIcon, PersonIcon, PrintIcon, AddLocationAltIcon, EmailIcon, LocalPhoneIcon, ArrowBackIcon, CheckIcon, DoneIcon, InfoOutlinedIcon } from "../Subcomponent/Shared/SharedIcons"
import OrderHistory from '../Subcomponent/Shared/OrderHistory';
import logger from '../Common/logger';
import IdentityManager from '../Managers/Identity.manager';
import PFManager from '../Managers/Process.Fulfillment.Manager';
import { CustomerDetails } from '../Subcomponent/Shared/CustomerDetails';
import { IImageViewerDetail, IImageViewerPopupSettings } from '../Models/IImageViewerPopupSettings';
import { ImageViewerPopup } from '../Subcomponent/Shared/ImageViewerPopup';
import { display } from '@mui/system';
var moment = require('moment');
let step: IStepOption[] = []
step.push({ label: 'Order Release', description: ``, time: 'Jan 20 2022 13:11:17 ' })
step.push({ label: 'Customer Contacted', description: "dummy", time: 'Jan 20 2022 13:11:17 ' })
step.push({ label: 'Note', description: "dummy", time: 'Jan 20 2022 13:11:17 ' })
step.push({ label: 'Picking', description: ``, time: 'Jan 20 2022 13:11:17' })
step.push({ label: 'Packing', description: ``, time: 'Jan 20 2022 13:11:17' })
step.push({ label: 'Customer Pickup', description: ``, time: '' })
let verticalStepperOptions: IVerticalStepper = {
    activeStep: 'Customer Pickup',
    stepsOptions: step
}
var momenttz = require('moment-timezone');
const today = momenttz.tz("America/Los_Angeles").format(Constants.DateFormat.DateOnly);
const todayDate = momenttz.tz("America/Los_Angeles");

const CustomerPickup = (props: any) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const orderno = urlParams.get('orderno');
    const shipmentkey = urlParams.get('shipmentkey');
    const queryParameterStore = urlParams.get('store');
    const [value, setValue] = React.useState("");
    const [idType, setIdType] = React.useState("-1");
    const [stateType, setstateType] = React.useState("0");
    const [openHistoryDrawer, setHistoryDrawer] = useState(false);
    const [openTrackingDrawer, setTrackingDrawer] = React.useState(false);
    const [openDrawer, setDrawer] = useState(false);
    const [showPickupSlip, setShowPickupSlip] = useState(0);
    const [notes, setNotes] = useState("")
    const [notePopup, setNotesPopup] = useState(false);
    const [orderDetailFromExStore, setOrderDetailFromExStore] = React.useState<any | null>(null);
    const [navigationFulfillment, setNavigationFulfillment] = useState(false);
    const [monthYear, setMonthYear] = React.useState<Date | null>(null);
    const [steps, setSteps] = useState<IVerticalStepper>(verticalStepperOptions)
    let orderLines: ContainerDetail[] = useSelector((state: any) => state.CustomerPickupState.orderLines);
    let customerDetail: ICustomerDetail = useSelector((state: any) => state.CustomerPickupState.customerDetail);
    let orderDetails: ICustomerPickupDetail = useSelector((state: any) => state.CustomerPickupState.orderDetails);
    const isHistoryLoading = useSelector((state: any) => state.OrderHistoryReducerState.isLoading);
    let selectedItems = useSelector((state: any) => state.CustomerPickupState.selectedItems);
    const [orderNumber, setOrderNumber] = useState("");
    const [loadingOrderLines, setLoadingOrderLines] = useState(true);
    const [isError, setIsError] = useState(false)
    const [errorMsg, setErrorMsg] = useState("Some thing went Wrong");
    const [openError, setOpenError] = useState(false);
    const [state, setState] = useState("-1");
    const [pickingVerification, setPickingVerification] = useState(false);
    const [expiryValue, setExpiryValue] = useState("");
    const [expiryValueYear, setExpiryValueYear] = useState("");
    const [saveType, setSaveType] = useState("");
    const [printPickupSlipData, setPrintPickupSlipData] = useState({} as any);
    const [imagePopUp, setImagePopUp] = useState(false);
    const [imageData, setImageData] = useState({} as IImageViewerDetail);

    const showActionLoader = () => {
        dispatch(allActions.actionLoaderActions.showLoader());
    }
    const hideActionLoader = () => {
        dispatch(allActions.actionLoaderActions.hideLoader());
    }
    const openLoadingOrderLines = () => {
        setLoadingOrderLines(true)
    }
    const closeLoadingOrderLines = () => {
        setLoadingOrderLines(false)
    }
    const closeNotes = () => {
        setNotesPopup(false)
    }
    const openNotes = () => {
        setNotesPopup(true)
        setNotes("")
    }
    const changeNote = (event: any) => {
        setNotes(event.target.value);
    }
    const addNotes = () => {
        if (notes != "") {
            let index = Utils.getStepperIndex(verticalStepperOptions, "Customer Pickup")
            step.splice(index, 0, { label: 'Note', description: notes, time: 'Jan 20 2022 13:11:17 ' })
            verticalStepperOptions.stepsOptions = step
            setSteps(verticalStepperOptions)
        }
        setNotesPopup(false)
    }
    let notesPopupButtons: IButton[] = [{
        text: "Add",
        icon: <AddCommentIcon />,
        action: addNotes,
        color: Constants.Colors.blue,
    },
    {
        text: "Close",
        icon: <CloseIcon />,
        action: closeNotes,
        color: Constants.Colors.grey,
    }
    ]
    let notesCustomerPopup: INotificationPopupSettings = {
        open: notePopup,
        type: Constants.NotificationPopupType.Info,
        title: LabelText.PickOrder.Note,
        msg: "",
        handleClose: closeNotes,
        isCustomJSX: true,
        customJSX: <Note note={notes} changeNote={changeNote}></Note>,
        actions: notesPopupButtons,
    }
    let stateOptions = [
        { label: 'Please Select Issuing State', id: "-1" }
    ];
    const changeSelectedItem = (event: any, id: any) => {
        let checked = event.target.checked
        if (checked) {
            let newSelectedItems = [...selectedItems]
            newSelectedItems.push(id)
            dispatch(allActions.customerPickupActions.changeSelectedItem(newSelectedItems));
        }
        else {
            let newSelectedItems = selectedItems.filter((listId: any) => { return listId != id })
            dispatch(allActions.customerPickupActions.changeSelectedItem(newSelectedItems));
        }
    }


    // Top Bar Scroll Handle
    // Scroll
    var startProductBarPos = -1;
    window.onscroll = function () {
        console.log("scrolled");
        var bar = document.getElementById('bar') as any;
        var mblBar = document.getElementById('mblBar') as any;
        if (bar) {

            if (startProductBarPos < 0) startProductBarPos = findPosY(mblBar);

            if (window.pageYOffset > startProductBarPos) {

                bar.classList.add("scrolledTopFix");

            } else {
                bar.classList.remove("scrolledTopFix");

            }
        }

    };

    function findPosY(obj: any) {
        var curtop = 0;
        if (obj && typeof (obj.offsetParent) != 'undefined' && obj.offsetParent) {
            while (obj.offsetParent) {
                curtop += obj.offsetTop;
                obj = obj.offsetParent;
            }
            curtop += obj.offsetTop;
        }
        else if (obj && obj.y)
            curtop += obj.y;
        return curtop;
    }

    //
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log(event.target.value, "valll");
        setValue((event.target as HTMLInputElement).value);
    };
    const generateTrackingNotes = () => {
        return <Paper style={{ padding: "5px", color: "#D93A2F", fontSize: "1rem", textAlign: "center" }}>
            <Typography><b>Note: Only the persons eligible to pick up the order are listed below</b></Typography>
        </Paper>;
    }

    const onChangeState = (event: any) => {
        setState(event.target.value);

    }

    const confirmCustomerPickup = async (type: string) => {
        setSaveType(type)
        let containerDetails = [] as any;
        orderLines.forEach((orderLine: ContainerDetail) => {
            if (selectedItems.indexOf(orderLine.UniqueContainerKey) > -1) {
                let containerDetail = {
                    "ContainerDetailsKey": orderLine.ContainerDetailsKey,
                    "DocumentType": orderLine.DocumentType,
                    "Quantity": Number(orderLine.Quantity),
                    "ShipmentKey": orderLine.ShipmentKey,
                    "ShipmentContainerKey": orderLine.ShipmentContainerKey,
                    "OrderHeaderKey": orderLine.OrderHeaderKey,
                    "OrderLineKey": orderLine.OrderLineKey,
                    "OrderReleaseKey": orderLine.OrderReleaseKey,
                } as any;
                if (orderLine.IsBundleParent) {
                    containerDetail.IsBundleParent = orderLine.IsBundleParent
                }
                if (orderLine.IsMultibox) {
                    containerDetail.IsMultibox = orderLine.IsMultibox
                }
                if (orderLine.hasOwnProperty("WarrantyComponent")) {
                    let warrentComponent = {
                        "ContainerDetailsKey": orderLine.WarrantyComponent.ContainerDetailsKey,
                        "Quantity": Number(orderLine.WarrantyComponent.Quantity),
                        "ShipmentContainerKey": orderLine.WarrantyComponent.ShipmentContainerKey,
                        "OrderLineKey": orderLine.WarrantyComponent.OrderLineKey,
                        "OrderReleaseKey": orderLine.WarrantyComponent.OrderReleaseKey
                    }
                    containerDetail.WarrantyComponent = warrentComponent

                }
                if (orderLine.hasOwnProperty("Component")) {
                    let ordercomponents = [] as any
                    if (orderLine.Component.length != undefined) {
                        orderLine.Component.forEach((comp: Component) => {
                            let compt = {
                                "ContainerDetailsKey": comp.ContainerDetailsKey,
                                "ShipmentContainerKey": comp.ShipmentContainerKey,
                                "DocumentType": comp.DocumentType,
                                "Quantity": Number(comp.Quantity),
                                "OrderHeaderKey": comp.OrderHeaderKey,
                                "OrderLineKey": comp.OrderLineKey,
                                "OrderReleaseKey": comp.OrderReleaseKey
                            }
                            ordercomponents.push(compt);
                        });
                        containerDetail.Component = ordercomponents
                    }
                    else {
                        containerDetail.Component = orderLine.Component
                    }
                    containerDetail.ProductClass = orderLine.ProductClass
                }
                containerDetails.push(containerDetail)
            }
        });
        //expiryValue + expiryValueYear

        let month = ""
        if (monthYear) {
            month = String((monthYear?.getMonth() + 1)).padStart(2, '0');
        }
        let year = ""
        if (monthYear) {
            year = (monthYear?.getFullYear().toString()).slice(-2)
        }
        let payload = {
            "ContainerDetails": {
                "ExtnPickedUpBy": value,
                "OrderHeaderKey": orderDetails.OrderHeaderKey,
                "ExtnIsPickUpPersonVerified": "Y",
                "UserName": "",
                "UserAuthenticated": OMSManager.GetUserIdFromLocalStorage(),
                "EmployeeID": OMSManager.GetEmployeeId(),
                "IdType": idType,
                "OrderNo": orderno,
                "ContainerDetail": containerDetails,
                "ExtnIdTypeStateIssued": state ? state : "",
                "ExtnIdTypeExpDate": month + year
            }
        }
        console.log(payload)

        showActionLoader();
        ApiService.post("/oms/GCCustomerPickedUpConfirm", payload).then(async (response) => {
            if (response.success == true) {
                await Utils.getPickupSlipDataFromPickup(orderLines, customerDetail, orderDetails, selectedItems, orderDetailFromExStore).then((printData: any) => {

                    setPrintPickupSlipData(printData);

                })
                OrderManager.saveOrderHistory({
                    shipmentKey: shipmentkey,
                    logType: "history",
                    transactionType: Constants.OrderHistoryTypes.Customer_Pickup,
                    message: notes,
                    transactionDate: moment.utc(moment.tz("America/Los_Angeles")).toISOString()
                });
                setShowPickupSlip(showPickupSlip + 1)
                hideActionLoader();
                if (saveType != "email") {
                    navigate("/FulfillOrders");
                }
            }
            else {
                hideActionLoader();
                setIsError(true)
                setErrorMsg("Some thing went wrong please contact the IT Help Desk for assistance")
                openErrorPopup();
            }
        }).catch(() => {
            hideActionLoader();
            setIsError(true)
            setErrorMsg("Some thing went wrong please contact the IT Help Desk for assistance")
            openErrorPopup();
        })
    }

    const getPickingPersons = (orderDetails: any) => {

        let pickingPersons: any[] = [];
        if (orderDetails && orderDetails.BillingCustomerName) {
            pickingPersons = [orderDetails.BillingCustomerName];
            if (pickingPersons.indexOf(orderDetails.PickUpPersonName) <= -1) {
                pickingPersons.push(orderDetails.PickUpPersonName);
            }
        }

        return pickingPersons;

    }

    const generateMobileSavePrintView = () => {
        // states
        let statesRaw = orderDetails.StatesList ? orderDetails.StatesList.CommonCodeList.CommonCode : [];
        let statesOptions = [
            { label: 'Please Select Issuing State', id: "-1" }
        ];

        statesRaw.forEach((val: any) => {

            statesOptions.push({
                label: val.CodeShortDescription,
                id: val.CodeValue
            });

        });

        // Ids Type
        let IdsTypeRaw = orderDetails.CommonCodeList ? orderDetails.CommonCodeList.CommonCode : [];
        let IdsOptions = [{ value: " Please Select ID Type", key: "-1" }];
        IdsTypeRaw.forEach((val: any) => {

            IdsOptions.push({ value: val.CodeValue, key: val.CodeValue });

        });

        // Picking Person
        let pickingPersons: any[] = getPickingPersons(orderDetails);


        console.log(pickingPersons, "pickingPersons");

        console.log(value, "pickingPersonsValue");

        let enableSavePrint = false;
        //(expiryValue && expiryValue.length == 2) && (expiryValueYear && expiryValueYear.length == 2)
        console.log(selectedItems, "selectedItems");
        console.log(monthYear, "monthYear");
        console.log(state, "state");

        if ((selectedItems && selectedItems.length > 0) && (value && value.length > 0) && (pickingVerification)
            && (idType && idType.length > 0) && (state && state.length > 0 && state != "-1") &&
            (monthYear != null && monthYear.toString() != "Invalid Date" && new Date(monthYear) >= new Date(today))) {
            enableSavePrint = true;
        }
        else {
            enableSavePrint = false;
        }


        return <div className="backdrop">
            <div className="title">
                <Grid item style={{ margin: "auto", textAlign: "left" }} xs={10} sm={10}>
                    <Typography>Person picking up the items</Typography>
                </Grid>
                <Grid item xs={2} sm={2} style={{ textAlign: "right" }}>
                    {Utils.isMobile ? <IconButton onClick={() => { if (Utils.isMobile) { setTrackingDrawer(false) } }} style={{ color: "white" }}>
                        <CancelIcon></CancelIcon>
                    </IconButton> : ""}
                </Grid>
            </div>
            <div className="mainContainer" style={{ padding: "10px" }}>
                {generateTrackingNotes()}
                <Grid container>
                    <Grid item xs={12} sm={12} className='inputField'>
                        {
                            pickingPersons && pickingPersons.length > 0 ?
                                <FormControl className='customRadio'>
                                    <RadioGroup
                                        aria-labelledby="demo-controlled-radio-buttons-group"
                                        name="controlled-radio-buttons-group"
                                        value={value}
                                        onChange={handleChange}
                                    >
                                        {pickingPersons.map((val: any) => {
                                            return <FormControlLabel value={val} control={<Radio style={{ color: "#f2554a" }} />} label={val} />
                                        })}


                                    </RadioGroup>
                                </FormControl> : ""
                        }

                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} sm={12}>
                        <Grid container>
                            <Grid item xs={2} sm={2} style={{ textAlign: "left" }}>
                                <Checkbox
                                    onChange={(event: any, checked: boolean) => {

                                        setPickingVerification(checked);

                                    }}
                                    checked={pickingVerification}
                                    style={{
                                        color: "#D93A2F",
                                    }} />
                            </Grid>
                            <Grid item xs={10} sm={10} style={{ textAlign: "center", paddingTop: "0.6rem" }}>
                                <Typography style={{ paddingLeft: "2px" }}>
                                    <b>I have verified the ID of the person picking up this Order</b>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Divider></Divider>
                    <Grid item xs={12} sm={12} className='inputField'>
                        <Typography>
                            ID Type *
                        </Typography>
                        <FormControl>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={idType.toString()}
                                onChange={(event: SelectChangeEvent) => {
                                    console.log(event.target.value, "event.target.value Id")
                                    setIdType(event.target.value as string);
                                }}
                                variant='outlined'
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                            >
                                {
                                    IdsOptions.map((val: any) => {

                                        return <MenuItem value={val.key}>{val.value}</MenuItem>

                                    })
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} className='inputField'>
                        <Typography >
                            Issuing State *
                        </Typography>
                        <FormControl>
                            <Select
                                labelId="issuing-state-label"
                                id="issuing-state-id"
                                value={state}
                                onChange={onChangeState}
                                variant='outlined'
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                            >
                                {
                                    statesOptions.map((val: any) => {

                                        return <MenuItem value={val.id}>{val.label}</MenuItem>

                                    })
                                }
                            </Select>
                        </FormControl>
                        {/* <Autocomplete
                            value={state}
                            onChange={onChangeState}
                            style={{ paddingTop: 0, paddingBottom: 0 }}
                            defaultValue={stateOptions[0]}
                            disablePortal
                            id="combo-box-demo"
                            options={statesOptions}
                            renderInput={(params) => {
                                return <TextField 
                                {...params} 
                                InputLabelProps={{ // this part
                                    shrink: false
                                }}
                                label="" variant="outlined"/>
                            }}
                        /> */}
                    </Grid>
                    <Grid item xs={12} sm={12} className='inputField'>
                        <Typography>
                            Exp. *
                        </Typography>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <Box m={0}>
                                <DatePicker
                                    inputFormat="MM-yy"
                                    mask="__-__"
                                    disableMaskedInput={false}
                                    views={['year', 'month']}
                                    label="Month-Year"
                                    minDate={new Date(today)}
                                    value={monthYear}
                                    onChange={setMonthYear}
                                    renderInput={(params: any) => <TextField {...params} helperText={null} />}
                                />
                            </Box>
                        </LocalizationProvider>
                        {/* <table style={{ width: "100%" }}>
                            <tbody>
                                <tr>
                                    <td style={{width: "50%"}}>
                                        <TextField value={expiryValue} onChange={(event: any) => {

                                            setExpiryValue(event.target.value.length > 2 ? event.target.value.slice(0, 2) : event.target.value);

                                        }} type={"number"} InputLabelProps={{ shrink: false }} inputProps={{ maxLength: 4, min:1, max:12 }} id="filled-basic" placeholder="MM" variant="outlined" />
                                    </td>
                                    <td style={{ fontSize: "30px", color: "#666363", fontWeight: "100" }}>
                                        /
                                    </td>
                                    <td style={{}}>
                                        <TextField value={expiryValueYear} onChange={(event: any) => {

                                            setExpiryValueYear(event.target.value.length > 2 ? event.target.value.slice(0, 2) : event.target.value);

                                        }} type={"number"} InputLabelProps={{ shrink: false }} inputProps={{ maxLength: 4, min:1 }} id="filled-basic" placeholder="YY" variant="outlined" />
                                    </td>
                                </tr>
                            </tbody>

                        </table> */}

                    </Grid>
                    <br></br>
                    <Grid item xs={12} sm={12} style={{ marginTop: "10px" }}>
                        {
                            !Utils.isMobile ?
                                <Button onClick={() => {
                                    if (Utils.isMobile) {
                                        setTrackingDrawer(false)
                                        confirmCustomerPickup("print");
                                    }
                                    else {
                                        confirmCustomerPickup("print");
                                    }
                                }} disabled={!enableSavePrint}
                                    style={{ width: "100%" }} variant='contained' className={!enableSavePrint ? "btn disableClass" : "btn"} startIcon={<PrintIcon />}>Save & Print</Button>
                                :
                                <></>
                        }
                        {/* <Button onClick={() => {
                            if (Utils.isMobile) {
                                setTrackingDrawer(false)
                                confirmCustomerPickupTest("email");
                            }
                            else {
                                confirmCustomerPickupTest("email");
                            }
                        }}
                            style={{ width: "100%", marginTop: "5px" }} variant='contained' startIcon={<EmailIcon />}>Save & Send Email Test</Button> */}
                        <Button onClick={() => {
                            if (Utils.isMobile) {
                                setTrackingDrawer(false)
                                confirmCustomerPickup("email");
                            }
                            else {
                                confirmCustomerPickup("email");
                            }
                        }} disabled={!enableSavePrint}
                            style={{ width: "100%", marginTop: "5px" }} variant='contained' className={!enableSavePrint ? "btn disableClass" : "btn"} startIcon={<EmailIcon />}>Save & Send Email</Button>
                    </Grid>
                </Grid>
            </div >
        </div >
    }

    const orderHistory = () => {
        return (
            <div>
                <OrderHistory store={props.store}></OrderHistory>
            </div>
        );
    }
    const closeErrorPopup = () => {
        setOpenError(false)
    }
    const openErrorPopup = () => {
        setOpenError(true)
    }
    const getCustomerDetails = (data: ICustomerPickupDetail) => {
        let initialCustomerDetail: ICustomerDetail = {
            name: "",
            city: "",
            email: "",
            phone: "",
            zipCode: "",
            address: "",
            state: ""
        }
        if (data.BillToAddress != undefined) {
            try {
                if (data.BillToAddress.hasOwnProperty("FirstName")) {
                    initialCustomerDetail.name = data.BillToAddress.FirstName
                }
                if (data.BillToAddress.hasOwnProperty("LastName")) {
                    initialCustomerDetail.name = initialCustomerDetail.name + " " + data.BillToAddress.LastName
                }
                if (data.BillToAddress.hasOwnProperty("City")) {
                    initialCustomerDetail.city = data.BillToAddress.City
                }
                if (data.BillToAddress.hasOwnProperty("EMailID")) {
                    initialCustomerDetail.email = data.BillToAddress.EMailID
                }
                if (data.BillToAddress.hasOwnProperty("DayPhone")) {
                    initialCustomerDetail.phone = data.BillToAddress.DayPhone
                }
                if (initialCustomerDetail.phone == "" && data.BillToAddress.hasOwnProperty("MobilePhone")) {
                    initialCustomerDetail.phone = data.BillToAddress.MobilePhone
                }
                if (data.BillToAddress.hasOwnProperty("ZipCode")) {
                    initialCustomerDetail.zipCode = data.BillToAddress.ZipCode
                }
                if (initialCustomerDetail.zipCode == "" && data.BillToAddress.hasOwnProperty("ShortZipCode")) {
                    initialCustomerDetail.zipCode = data.BillToAddress.ShortZipCode
                }
                if (data.BillToAddress.hasOwnProperty("AddressLine1")) {
                    initialCustomerDetail.address = data.BillToAddress.AddressLine1
                }
                if (data.BillToAddress.hasOwnProperty("AddressLine2") && data.BillToAddress.AddressLine2 != "") {
                    initialCustomerDetail.address = initialCustomerDetail.address + ", " + data.BillToAddress.AddressLine2
                }
                if (data.BillToAddress.hasOwnProperty("AddressLine3") && data.BillToAddress.AddressLine3 != "") {
                    initialCustomerDetail.address = initialCustomerDetail.address + ", " + data.BillToAddress.AddressLine3
                }
                if (data.BillToAddress.hasOwnProperty("AddressLine4") && data.BillToAddress.AddressLine4 != "") {
                    initialCustomerDetail.address = initialCustomerDetail.address + ", " + data.BillToAddress.AddressLine4
                }
                if (data.BillToAddress.hasOwnProperty("AddressLine5") && data.BillToAddress.AddressLine5 != "") {
                    initialCustomerDetail.address = initialCustomerDetail.address + ", " + data.BillToAddress.AddressLine5
                }
                if (data.BillToAddress.hasOwnProperty("AddressLine6") && data.BillToAddress.AddressLine6 != "") {
                    initialCustomerDetail.address = initialCustomerDetail.address + ", " + data.BillToAddress.AddressLine6
                }
                if (data.BillToAddress.hasOwnProperty("State")) {
                    initialCustomerDetail.state = data.BillToAddress.State
                }
            }
            catch {
                console.log("Error Getting Customer Detail")
            }

        }
        return initialCustomerDetail
    }
    const popUpBack = () => {
        navigate(-1)
    }
    let errorPopupButtons: IButton[] = [
        {
            text: "Close",
            icon: <CloseIcon />,
            action: closeErrorPopup,
            color: Constants.Colors.red,
        },
        {
            text: "Back",
            icon: <ArrowBackIcon />,
            action: popUpBack,
            color: Constants.Colors.red,
        },
    ];
    let Error: INotificationPopupSettings = {
        open: openError,
        type: Constants.NotificationPopupType.Error,
        title: "Error",
        msg: errorMsg,
        draggable: false,
        handleClose: closeErrorPopup,
        actions: errorPopupButtons
    }
    const getCustomerPickDetailFromOms = async () => {
        openLoadingOrderLines();
        let storeId = IdentityManager.getStoreId(props.store);
        if ((!storeId || storeId < 0) && queryParameterStore != null) {
            storeId = queryParameterStore
        }
        if (!storeId || storeId < 0) {
            setIsError(true);
            setErrorMsg("Please Select Store");
            openErrorPopup();
            return;
        }


        let result = [] as any
        try {
            result = await OMSManager.getGCCustomerPickupDetailView(storeId, orderno);
        }
        catch {
            setIsError(true);
            setErrorMsg("Some thing went wrong please contact the IT Help Desk for assistance");
            openErrorPopup();
        }
        if (result.success) {
            let data = result.data

            setOrderNumber(data.OrderNo);
            //addingUniqueIdForSelection             
            if (data?.ContainerDetails?.ContainerDetail && data?.ContainerDetails?.ContainerDetail.length > 0) {
                data.ContainerDetails.ContainerDetail.forEach((containerDetail: ContainerDetail) => {
                    containerDetail.UniqueContainerKey = Utils.generateUUID();
                });
            }
            dispatch(allActions.customerPickupActions.changeOrderLines(data.ContainerDetails.ContainerDetail));
            const customerDetail = getCustomerDetails(data)
            dispatch(allActions.customerPickupActions.changeCustomerDetails(customerDetail));
            dispatch(allActions.customerPickupActions.setOrderDetails(data));
            let pickingPersons = getPickingPersons(data);
            setValue(pickingPersons && pickingPersons.length > 0 ? pickingPersons[0] : "");
            dispatch(allActions.customerPickupActions.changeSelectedItem([]));
            if (data.ContainerDetails.ContainerDetail.length == 1) {
                let uniqueContainerKey = data.ContainerDetails.ContainerDetail[0].UniqueContainerKey;
                let newSelectedItems = [...selectedItems]
                newSelectedItems.push(uniqueContainerKey)
                dispatch(allActions.customerPickupActions.changeSelectedItem(newSelectedItems));
            }
            closeLoadingOrderLines();
        }
        else {
            setIsError(true)
            setErrorMsg("Some thing went wrong please contact the IT Help Desk for assistance")
            closeLoadingOrderLines()
            openErrorPopup();
        }

    }

    const navigateToFulfillment = () => {
        navigate("/FulfillOrders");
    }

    const openNavigationFulfillment = () => {
        setNavigationFulfillment(true);
    }

    let navigateToFulfillOrdersButtons: IButton[] = [
        {
            text: "To Fulfillment List",
            icon: <DoneIcon />,
            action: navigateToFulfillment,
            color: Constants.Colors.red,
        }
    ];
    let navigateToFulfillOrders: INotificationPopupSettings = {
        open: navigationFulfillment,
        type: Constants.NotificationPopupType.Info,
        title: "",
        msg: "This Order doesn't belong to selected Store.",
        draggable: false,
        handleClose: navigateToFulfillment,
        actions: navigateToFulfillOrdersButtons
    }

    const closeImageViewer = () => {
        setImagePopUp(false);
        setImageData({} as IImageViewerDetail);
    }

    let imageViewerSettings: IImageViewerPopupSettings = {
        open: imagePopUp,
        imageDetail: imageData,
        close: closeImageViewer
    }

    const imageViewer = (item: any) => {
        let imageData: IImageViewerDetail = {
            imageURL: item.ImageUrl,
            itemDesc: item.ItemDesc,
            itemID: item.OrderLine?.ItemDetails?.Extn?.ExtnPOSItemID ?? "-",
            skuID: item.ItemID,
            price: item.UnitPrice,
            qty: item.Quantity
        }

        setImagePopUp(true);
        setImageData(imageData);
    }
    const getItemTrackingNo = (item: any) => {
        let trackingNumberList = []; 
        if(item.TrackingNo)
        {
            trackingNumberList.push(item.TrackingNo);
        }
        if(item.Component?.length != undefined && item.Component.length>0)
        {
            item.Component.forEach((obj:any) => {
                if(obj.TrackingNo)
                {
                    trackingNumberList.push(obj.TrackingNo);
                }
            });
        }
        else if(item.Component?.TrackingNo)
        {
            trackingNumberList.push(item.Component.TrackingNo); 
        }
        return trackingNumberList;
    }

    const getMobileItemTrackingNo = (item: any) => {
        let trackingNumberList = getItemTrackingNo(item);
        if(trackingNumberList.length>0)
        {
            return trackingNumberList.join(", ");
        }
        return "";
       
    }
    React.useEffect(() => {
        async function getCustomerPickDetail() {
            await getCustomerPickDetailFromOms();
            let data: any = await PFManager.getShipmentDataFromShippingCenter(shipmentkey);
            setOrderDetailFromExStore((data.data.hits.hits.length > 0 ? data.data.hits.hits[0]._source.Shipment : null));
        }
        getCustomerPickDetail()

    }, [props.store]);

    React.useEffect(() => {
        if (queryParameterStore && props.store?.length == 1 && props.store[0].id != queryParameterStore) {
            openNavigationFulfillment();
        }
    }, [props.store])

    const webView = () => {
        return <div style={{ margin: "5px" }}>
            <Paper className='paperToBottomPadding' style={{ padding: "10px" }}>
                <Grid container spacing={2} display={"flex"}>
                    <Grid item xs={6} sm={6} className='textLeft'>
                        <Typography variant="h6" style={{ padding: "0px" }} className='scanColor'>
                            Order # {orderNumber}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} className='textRight'>
                        <Button
                            style={{ marginTop: "4px", marginBottom: "6px" }}
                            disabled={selectedItems && selectedItems.length > 0 ? false : true}
                            variant="contained"
                            className={selectedItems && selectedItems.length > 0 ? "btn" : "btn disableClass"}
                            onClick={async () => {
                                try {
                                    showActionLoader();
                                    console.log(orderDetails, "orderDetails")
                                    Utils.getPickupSlipDataFromPickup(orderLines, customerDetail, orderDetails, selectedItems, orderDetailFromExStore).then((printData: any) => {
                                        hideActionLoader();
                                        setPrintPickupSlipData(printData);
                                        setShowPickupSlip(showPickupSlip + 1);
                                        logger.info({
                                            tag: Constants.LOG_TYPES.CUSTOMER_PICKUP_SLIP,
                                            message: ""
                                        });

                                    })
                                }
                                catch (e) {
                                    hideActionLoader();
                                }


                            }}>
                            Print Pickup Slip
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
            <Paper style={{ marginTop: "26px" }}>
                <Grid container spacing={2} display={"flex"}>
                    <Grid item xs={9} sm={9} style={{ paddingTop: "0px", paddingRight: "4px" }} className='textLeft'>
                        <div className="backdrop">
                            <div className="title">
                                <Typography> Order Lines</Typography>
                            </div>
                            <div className="mainContainer" style={{ padding: "20px" }}>
                                <Grid container spacing={2} display={"flex"}>
                                    <Grid item xs={1} sm={1} className='textLeft'>
                                    </Grid>
                                    <Grid item xs={7} sm={7} className='textLeft'>
                                        <Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>
                                            Description
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2} sm={2} className='textCenter'>
                                        <Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>
                                            Qty Ready for Pickup
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2} sm={2} className='textCenter'>
                                        <Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>
                                            Tracking #
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Divider />
                                <div>
                                    {
                                        !loadingOrderLines ?
                                            <React.Fragment>
                                                {
                                                    orderLines.map((item: any, index: any) => {
                                                        return (
                                                            <React.Fragment>
                                                                <Grid container spacing={2} display={"flex"}>
                                                                    <Grid item xs={8} sm={8} className='textLeft'>
                                                                        <Grid container spacing={2} style={{ margin: "0px" }} sx={{ flexGrow: 1 }}>
                                                                            <Grid item xs={3} sm={3} style={{ padding: "8px", borderRight: "1px solid #2f2d2d", display: "flex", justifyContent: "center" }}>
                                                                                {Utils.disableUserAction(props.store) && <React.Fragment>
                                                                                    {orderLines.length != 1 && <Checkbox
                                                                                        onChange={(e) => changeSelectedItem(e, item.UniqueContainerKey)}
                                                                                        style={{
                                                                                            color: "#D93A2F",
                                                                                        }} />
                                                                                    }
                                                                                </React.Fragment>}
                                                                                <div className="image-zoom" onClick={() => imageViewer(item)}>
                                                                                    <img style={{ height: "auto", width: "100%" }} alt="" src={Utils.convertToHttps(item.ImageUrl)} />
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={9} sm={9} style={{ padding: "8px", textAlign: "left" }}>
                                                                                <Typography style={{ fontSize: "15px", fontWeight: 500, color: "#D93A2F" }}>{item.ItemDesc}</Typography>

                                                                                <Typography variant="body2" sx={{ fontSize: 15 }} gutterBottom>
                                                                                    SKU # <b>{item.ItemID}</b>
                                                                                </Typography>
                                                                                <Typography variant="body2" sx={{ fontSize: 15 }} gutterBottom>
                                                                                    Price: <b>{item.UnitPrice}</b>
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid item xs={2} sm={2} className='textCenter' style={{ marginTop: "8px" }}>
                                                                        <Typography variant="body2" sx={{ fontSize: 15 }} gutterBottom>
                                                                            {Number(item.Quantity)}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={2} sm={2} className='textCenter' style={{ marginTop: "8px"}}>
                                                                    {
                                                                        getItemTrackingNo(item).length == 1 ?
                                                                        <Typography style={{ wordBreak: "break-all" }} variant="body2" sx={{ fontSize: 15 }} gutterBottom>
                                                                            {
                                                                                getItemTrackingNo(item)[0]
                                                                            }
                                                                        </Typography>
                                                                        :
                                                                        getItemTrackingNo(item).length > 1 
                                                                        ?
                                                                        <div style={{ display: "flex", alignItems: "center",justifyContent:"center" }}>

                                                                        <Typography style={{ wordBreak: "break-all" }} variant="body2" sx={{ fontSize: 15 }} gutterBottom>
                                                                            {
                                                                                getItemTrackingNo(item)[0] 
                                                                            }
                                                                        </Typography>
                                                                        <Tooltip title={
                                                                        <React.Fragment>
                                                                            {getItemTrackingNo(item).map((value:any)=>{
                                                                                return <Typography variant="body2" sx={{ fontSize: 15 }}>{value}</Typography>
                                                                            })}
                                                                        </React.Fragment>}>
                                                                            <InfoOutlinedIcon style={{ color: Constants.Colors.blue, marginLeft: "5px",marginBottom:"5px" }} />
                                                                        </Tooltip>
                                                                        </div>
                                                                        :""

                                                                    }
                                                                    </Grid>
                                                                </Grid>
                                                                <Divider></Divider>
                                                            </React.Fragment>
                                                        );
                                                    })

                                                }
                                            </React.Fragment>
                                            :
                                            <OrderLineShimmer></OrderLineShimmer>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="backdrop">
                            <div className="title">
                                <Typography> Customer Details</Typography>
                            </div>
                            {
                                !loadingOrderLines ?
                                    <CustomerDetails customerDetail={customerDetail}></CustomerDetails>
                                    :
                                    <div className="mainContainer" style={{ padding: "20px" }}>
                                        <CustomerDetailShimmer></CustomerDetailShimmer>
                                    </div >

                            }
                        </div >
                    </Grid>
                    <Grid item xs={3} sm={3} className='textRight' style={{ paddingTop: "0px", paddingRight: "0px", paddingLeft: "6px" }}>
                        {generateMobileSavePrintView()}
                        {orderHistory()}
                    </Grid>
                </Grid>
            </Paper>
        </div>
    }

    const mobileView = () => {
        return <React.Fragment>
            <div style={{ paddingBottom: "150px" }}>
                <Paper id='bar' style={{ color: "white", top: 0, left: 0, right: 0, zIndex: 999, borderRadius: 0 }} className="appBarPrimary">
                    <Toolbar style={{ height: "46px", minHeight: "46px" }}>
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mr: 2 }}

                            onClick={() => { navigate(-1) }}
                        >
                            <ArrowBackIcon />
                        </IconButton>
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} style={{ marginLeft: "-48px" }}>
                            Customer Pickup Order
                        </Typography>
                        <IconButton style={{ background: "#D93A2F", color: "white" }} sx={{ p: '10px' }} aria-label="actions">
                        </IconButton>
                    </Toolbar>
                </Paper>
                <Paper style={{ marginTop: 2, paddingTop: 1, paddingBottom: 1 }}>
                    <Card style={{ margin: "6px" }} id='cardOrder'>
                        <CardContent style={{ padding: "10px" }}>
                            <Grid container spacing={2} style={{ margin: "0px" }} sx={{ flexGrow: 1 }}>
                                <Grid item xs={12} sm={12} style={{ padding: "8px" }}>
                                    <Typography variant="h6" style={{ padding: "0px" }} className='scanColor'>
                                        Order #  {orderNumber}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                    {Utils.disableUserAction(props.store) && <Button onClick={() => { setTrackingDrawer(true) }} style={{ marginTop: "4px", marginBottom: "4px", width: "98%" }} variant='contained' className='btn' startIcon={<CheckIcon />}>Confirm</Button>}

                    {/* <Button onClick={() => setShowPickupSlip(showPickupSlip + 1)} style={{ marginTop: "4px", marginBottom: "4px", width: "98%" }} variant='contained' className={selectedItems && selectedItems.length > 0 ? "btn" : "btn disableClass"} disabled={selectedItems && selectedItems.length > 0 ? false : true} startIcon={<PrintIcon />}>Print Pickup Slip</Button> */}
                </Paper>
                <Card style={{ marginTop: "6px" }} id='cardOrder'>
                    <CardContent style={{ padding: "1px" }}>
                        <div className="backdrop">
                            <div className="title">
                                <Typography>Order Lines</Typography>
                            </div>
                            <div className="mainContainer" style={{ padding: "5px" }}>
                                {
                                    !loadingOrderLines ?
                                        <React.Fragment>
                                            {
                                                orderLines.map((item: any, index: any) => {
                                                    return (
                                                        <Card style={{ margin: "6px" }} >
                                                            <CardContent style={{ padding: "10px" }}>
                                                                <Grid spacing={2} container style={{ margin: "0px" }} sx={{ flexGrow: 1 }}>
                                                                    <Grid item xs={3} sm={3} style={{ padding: "8px", borderRight: "1px solid #2f2d2d" }}>
                                                                        {Utils.disableUserAction(props.store) && <React.Fragment>
                                                                            {orderLines.length != 1 && <Checkbox style={{
                                                                                color: "#D93A2F",
                                                                            }}
                                                                                onChange={(e) => changeSelectedItem(e, item.UniqueContainerKey)}
                                                                            />}
                                                                        </React.Fragment>}
                                                                        <img style={{ height: "85px", cursor: "zoom-in" }} alt="" src={Utils.convertToHttps(item.ImageUrl)} onClick={() => imageViewer(item)} />
                                                                    </Grid>
                                                                    <Grid item xs={9} sm={9} style={{ padding: "8px", textAlign: "left" }}>
                                                                        <Typography style={{ fontSize: "13px", fontWeight: 500, color: "#D93A2F" }}><em>{item.ItemDesc}</em></Typography>
                                                                        <Typography style={{ fontSize: "11px" }}>Qty Ready for Pickup : <b>{item.Quantity}</b></Typography>
                                                                        <Typography style={{ fontSize: "11px" }}>SKU #: <b>{item.ItemID}</b></Typography>
                                                                        <Typography style={{ fontSize: "11px" }}>Tracking #: <b>{getMobileItemTrackingNo(item)}</b></Typography>
                                                                        <Typography style={{ fontSize: "11px" }}>Price: <b>$ {item.UnitPrice}</b></Typography>
                                                                    </Grid>

                                                                </Grid>
                                                            </CardContent>
                                                        </Card>
                                                    );
                                                })

                                            }
                                        </React.Fragment>
                                        :
                                        <OrderLineShimmerMobile></OrderLineShimmerMobile>
                                }



                            </div>
                        </div>
                    </CardContent>
                </Card>
            </div>
            <div style={{ display: "none" }}>
                <OrderHistory store={props.store}></OrderHistory>
            </div>
            <Card className='customerDetailsBoxPickOrder'>
                <CardContent style={{ padding: "1px", background: "#F3F3F4" }}>
                    <Button className="MobileDrawerButtons" onClick={() => { setDrawer(true) }} variant="outlined">Customer Details</Button>
                    <Button className="MobileDrawerButtons" onClick={() => { isHistoryLoading ? setHistoryDrawer(false) : setHistoryDrawer(true) }} variant="outlined">Fulfillment History</Button>
                </CardContent>
            </Card>
            <Drawer
                anchor={"top"}
                open={openTrackingDrawer}
                onClose={() => { setTrackingDrawer(false) }}
            >
                <Card style={{ margin: "6px" }}>
                    <CardContent style={{ padding: "10px" }}>
                        {generateMobileSavePrintView()}
                    </CardContent>
                </Card>
            </Drawer>
            <Drawer
                anchor={"bottom"}
                open={openDrawer}
                onClose={() => { }}
                onClick={() => { setDrawer(false) }}
            >
                <Card style={{ margin: "6px" }}>
                    {
                        !loadingOrderLines ?
                            <CustomerDetails customerDetail={customerDetail}></CustomerDetails>
                            :
                            <CustomerDetailShimmerMobile></CustomerDetailShimmerMobile>
                    }
                </Card>
            </Drawer>
            <SwipeableDrawer
                anchor={"bottom"}
                open={openHistoryDrawer}
                onClose={() => { }}
                onOpen={() => { }}
                ModalProps={{ onBackdropClick: () => { setHistoryDrawer(false) } }}
            >

                <OrderHistory store={props.store}></OrderHistory>

            </SwipeableDrawer>
        </React.Fragment>
    }

    return (
        <div>
            <NotificationPopup {...Error}>
            </NotificationPopup>
            <NotificationPopup {...notesCustomerPopup}>
            </NotificationPopup>
            <NotificationPopup {...navigateToFulfillOrders}>
            </NotificationPopup>
            <ImageViewerPopup {...imageViewerSettings}>
            </ImageViewerPopup>
            {Utils.isMobile ? <></> : <SiteBreadCrumbs siteBreadCrumbs={Constants.SiteBreadCrumbs.CustomerPickup}></SiteBreadCrumbs>}
            {Utils.isMobile ? mobileView() : webView()}
            {
                showPickupSlip > 0 ?
                    <PickupPrintSlip loadingOrderLines={loadingOrderLines} showPickupSlip={showPickupSlip} saveType={saveType} CustomIPickupSlip={printPickupSlipData}></PickupPrintSlip>
                    :
                    <div></div>
            }

        </div>
    );
}
export default CustomerPickup;
import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogContent, DialogContentText, DialogActions, Slide, DialogTitle, Typography, Grid, Paper } from '@material-ui/core';
import { IconButton } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { CloseIcon } from "../../Subcomponent/Shared/SharedIcons"
import Constants from "../../Common/Constants"
import Utils from "../../Common/Utils"
import allActions from '../../Store/Actions/AllActions';
import { useDispatch, useSelector } from 'react-redux';
const html2pdf = require('html2pdf.js');
const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const PdfIframe = (props: any) => {
    const [blobUrl, setBlobUrl] = useState("");
    const dispatch = useDispatch();
    let pickSlipGroupByModal = useSelector((state: any) => state.FulfillOrdersReducersState.pickSlipGroupByModal);
    const showActionLoader = () => {
        dispatch(allActions.actionLoaderActions.showLoader());
    }
    const hideActionLoader = () => {
        dispatch(allActions.actionLoaderActions.hideLoader());
    }
    const exportDataPdf = async () => {

        let container = props.containerName && props.containerName.length > 0 ? props.containerName : "PickSlipContainer";
        let el = document.getElementById(container) as any;
        let fileName = `Pick Slip`;
        let options = {
            pdfCallback: pdfDone,
            margin: [0.1, 0.1, 0.1, 0.1],  // tlbr
            filename: fileName + '.pdf',
            image: { type: 'jpeg', quality: 1 },
            html2canvas: { scale: 2, logging: true },
            jsPDF: { unit: 'in', format: 'a4', orientation: 'p' },
            pagebreak: { before: '.breakPage' }
        };

        let test = await html2pdf().set(options).from(el).save()
    }
    const preview = async () => {

        showActionLoader();
        let container = props.containerName && props.containerName.length > 0 ? props.containerName : "PickSlipContainer";
        let el = document.getElementById(container) as any;
        let fileName = `Pick Slip`;
        let options = {
            pdfCallback: pdfDone,
            margin: [0.1, 0.1, 0.1, 0.1],  // tlbr
            filename: fileName + '.pdf',
            image: { type: 'jpeg', quality: 1 },
            html2canvas: { scale: 2, logging: true },
            jsPDF: { unit: 'in', format: 'a4', orientation: 'p' },
            pagebreak: { before: '.breakPage' }
        };

        let worker = await html2pdf().set(options).from(el).toPdf().output('blob');
        var fileURL = URL.createObjectURL(worker);
        console.log(fileURL)
        setBlobUrl(fileURL);
        hideActionLoader();
    }
    const pdfDone = () => {

        console.log("Pdf generated successfully");

    }
    useEffect(() => {
        preview()
    }, []);
    return (
        <Dialog disableEnforceFocus fullWidth={true} maxWidth="lg" open={props.open} TransitionComponent={Transition} scroll={"body"} >
            <DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={() => {

                        props.close();
                        dispatch(allActions.fulfillOrdersActions.changePrintPickSlips({
                            ...pickSlipGroupByModal,
                            showPdf: false,
                            open: false
                        }));

                    }}
                    sx={{
                        position: 'absolute',
                        right: 0,
                        top: 0,
                        color: Constants.Colors.grey,
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent id="previewPDF">
                {props.open ?
                    <iframe title='test' width={"99%"} height={Utils.displayHeight} src={blobUrl}></iframe> :
                    <></>
                }
            </DialogContent>
        </Dialog>
    );
}
export default PdfIframe;


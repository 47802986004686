import { Divider } from "@material-ui/core";
import { Grid, Typography, Button, TextField, DialogActions } from "@mui/material";
import React, { useState } from "react";
import Constants from "../../Common/Constants";
import { TailSpin } from "react-loader-spinner";
import Utils from "../../Common/Utils";
import AuthManager from "../../Managers/Auth.manager";
import IdentityManager from "../../Managers/Identity.manager";
import { CloseIcon, DoneIcon, ArrowBackIcon } from "./SharedIcons";
import StoreManager from "../../Managers/Store.manager";
export const VerifyCredentials = (props: any) => {
    const [email, setEmailText] = useState("");
    const [password, setPasswordText] = useState("");
    const [isCurrentUser, setCurrentUser] = useState(false);
    const [enableConfirm, setEnableConfirm] = useState(false);
    const [invalid, setInvalidUser] = useState(false);
    const [loading, setLoading] = useState(false);
    const [invalidMsg, setInvalidMsg] = useState("Invalid Email or Password");
    React.useEffect(() => {
        if (email != "" && password != "") {
            setEnableConfirm(true)
        }
        else {
            setEnableConfirm(false)
        }
    }, [email, password]);

    const changeEmail = (e: any) => {
        if (isCurrentUser) {
            setCurrentUser(false);
        }
        setEmailText(e.target.value);
    }

    const changePassword = (e: any) => {
        setPasswordText(e.target.value);
    }
    const retry = () => {
        setCurrentUser(false);
        setEmailText("");
        setPasswordText("");
        setLoading(false);
        setInvalidUser(false);
        setInvalidMsg("Invalid Email or Password")
    }

    // const checkJobCode = () =>{
    //     if(IdentityManager.isAdmin)
    //     {
    //         return true;
    //     }
    //     let jobCode = IdentityManager.getUserProperty(Constants.JobCode); 
    //     let mgrOpsCode = StoreManager.config?.managerOpsJobCode;
    //     let validJobCode = false;
    //     if(jobCode&&mgrOpsCode)
    //     {
    //       if(mgrOpsCode.includes("-"))
    //       {
    //         validJobCode= jobCode>=mgrOpsCode.split("-")[0] && jobCode<=mgrOpsCode.split("-")[1];
    //       }
    //       else{
    //         validJobCode= jobCode==mgrOpsCode; 
    //       }
    //     }
    //     if(!validJobCode)
    //     {
    //         setInvalidMsg("You are not authorized to perform current operation.")
    //     }
    //     return validJobCode;

    // }

    const confirm = async () => {
        if (IdentityManager.verifyCredentialsException) {
            props.confirmCredentials();
            props.closePopUp();
            return;
        }
        if (!Utils.isCurrentUserEntered(email)) {
            let payload: any = {
                paramA: window.btoa(email),
                paramB: window.btoa(password),
                bypassJobCode: IdentityManager.bypassJobCodeException
            }
            setLoading(true)
            let response = await AuthManager.verifyCredentials(payload);
            if (response?.success == true && response?.data?.success == true) {
                setLoading(false)
                props.confirmCredentials();
                props.closePopUp()
            }
            else {
                setLoading(false)
                setInvalidUser(true)
                if (response?.data?.msg) {
                    setInvalidMsg(response.data.msg)
                }
            }
        } else {
            setCurrentUser(true);
            setEmailText("");
            setPasswordText("");
        }
    }
    const ValidationComponent = () => {
        return (
            <div style={{ marginTop: 20 }}>
                {
                    invalid ?
                        <React.Fragment>
                            <Grid item xs={12} sm={12} className='inputField' style={{ display: "flex", justifyContent: "center", marginBottom: "10px" }}>
                                <Typography variant="h5" style={{ color: '#D93A2F' }}>
                                    {invalidMsg}
                                </Typography>
                            </Grid>
                            <DialogActions>
                                <Button key={1} style={{ whiteSpace: 'nowrap', backgroundColor: Constants.Colors.red, color: "white" }} onClick={() => retry()} variant="contained" startIcon={<ArrowBackIcon />} >
                                    Retry
                                </Button>
                                <Button key={1} style={{ whiteSpace: 'nowrap', backgroundColor: Constants.Colors.grey, color: "white" }} onClick={() => props.closePopUp()} variant="contained" startIcon={<CloseIcon />}>
                                    Close
                                </Button>
                            </DialogActions>
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <Grid item xs={12} sm={12} className='inputField'>
                                <Typography>
                                    Email*
                                </Typography>
                                <TextField type="email" fullWidth InputLabelProps={{ shrink: false }} id="email" variant="outlined" value={email}
                                    onChange={(e) => changeEmail(e)} />
                            </Grid>
                            <Grid item xs={12} sm={12} className='inputField'>
                                <Typography>
                                    Password*
                                </Typography>
                                <TextField type="password" fullWidth InputLabelProps={{ shrink: false }} id="password" variant="outlined" value={password}
                                    onChange={(e) => changePassword(e)} />
                            </Grid>
                            {isCurrentUser &&
                                <Grid item xs={12} sm={12} className='inputField'>
                                    <Typography variant="caption" style={{ color: '#D93A2F' }}>
                                        Email shouldn't be the same as the current user.
                                    </Typography>
                                </Grid>
                            }
                            <DialogActions>
                                <Button className={enableConfirm ? "" : "disabledButton"} disabled={enableConfirm ? false : true} style={{ whiteSpace: 'nowrap', backgroundColor: Constants.Colors.green, color: "white" }} variant="contained" onClick={() => confirm()} startIcon={<DoneIcon />}>
                                    Confirm
                                </Button>
                                <Button key={1} style={{ whiteSpace: 'nowrap', backgroundColor: Constants.Colors.grey, color: "white" }} onClick={() => props.closePopUp()} variant="contained" startIcon={<CloseIcon />}>
                                    Close
                                </Button>
                            </DialogActions>
                        </React.Fragment>
                }



            </div >
        );
    }
    return (
        <React.Fragment>
            {
                loading ?
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <TailSpin color="#D93A2F" height={80} width={80} />
                    </div>
                    :
                    ValidationComponent()
            }
        </React.Fragment>
    )

}
import * as React from 'react';
import DataTable from 'react-data-table-component';
import { Card, CardContent, Divider, InputBase, TextField, Checkbox, IconButton, Typography, Stack, Box, Grid } from '@mui/material';
import { PreviewIcon } from "../../Subcomponent/Shared/SharedIcons"
import Constants from "../../Common/Constants"
import { useDispatch, useSelector } from 'react-redux';
export default function MobilePickDataTable(props: any) {
    const getCount = (row: any) => {
        let count = 0
        row._source.Shipment?.ShipmentLines?.ShipmentLine?.forEach((element: any) => {
            if (element.ExtnIsWarrantyItem == "N" && element.MinLineStatusDesc != "Cancelled" && element.BundleParentOrderLineKey == undefined) {
                count = count + Number(element.OrderedQty)
            }
        });
        return count
    }
    const getDescription = (row: any) => {
        let description = ""
        let count = row?._source?.Shipment?.ShipmentLines?.ShipmentLine?.length
        if (count) {
            for (let i = 0; i < count; i++) {
                let element = row._source.Shipment.ShipmentLines.ShipmentLine[i]
                if (element.ExtnIsWarrantyItem == "N" && element.MinLineStatusDesc != "Cancelled") {
                    description = element.ItemDesc
                    break
                }
            }
        }
        return description
    }
    let orderRows = useSelector((state: any) => state.FulfillOrdersReducersState.orderRows);
    return (
        <div>

            {orderRows.map((row: any) => {
                let stepstoShow = [];
                stepstoShow = props.steps;
                let activeStepCount = 1;
                if (props.value != 0) {
                    activeStepCount = props.value
                }
                return (
                    <Card style={{ marginTop: 4, marginBottom: 4 }}>
                        <CardContent style={{ paddingTop: 0, paddingBottom: 0, paddingLeft: 0, paddingRight: 0 }}>
                            <Grid container style={{ margin: "0px" }} >
                                <Grid onClick={() => { props.detailDrawerOpen(row._id) }} xs={3} sm={3} style={{ paddingTop: 4, paddingLeft: 0, color: Constants.Colors.red, background: Constants.Colors.smokeGrey, display: "flex", alignItems: "center", borderRight: "2px solid #C0C0C0" }}>
                                    <Typography style={{ fontSize: "10px", wordBreak: "break-all", fontWeight: "425", marginLeft: 1 }}>
                                        {row._source.Shipment.OrderNo}
                                    </Typography>
                                </Grid>
                                <Grid onClick={() => { props.detailDrawerOpen(row._id) }} item xs={7} sm={7} style={{ paddingTop: 3, paddingLeft: 3, textAlign: "left", verticalAlign: "center" }}>
                                    <Typography noWrap style={{ color: Constants.Colors.red, fontSize: "12px", fontWeight: "425" }}>{getDescription(row)}</Typography>
                                    <Typography style={{ fontSize: "11px" }}>
                                        {/* <span className="fulfillmentMobileLable">QTY:{getCount(row)}</span> */}
                                        <span className="fulfillmentMobileLable">QTY:{row._source.Shipment.qtyCountCalculated}</span>
                                    </Typography>
                                </Grid>
                                {props.monileGridButton(row._source.Shipment.Status, row._source.Shipment.ShipmentKey)}
                            </Grid>
                        </CardContent>
                    </Card>
                );
            })
            }
        </div >
    );
}
import React from 'react';
import './Navbar.scss';
import Utils from '../../Common/Utils';
import AuthManager from '../../Managers/Auth.manager';
import IdentityManager from '../../Managers/Identity.manager';
import { useNavigate } from "react-router-dom";
import { AppBar, IconButton, MenuItem, Menu, Paper } from '@material-ui/core';
import { ChatBubbleIcon, AccountCircleOutlinedIcon } from "../../Subcomponent/Shared/SharedIcons";
import logo from '../../Content/gc_logo05_alt.png';
import WebAppbar from './WebAppbar';
import MobileAppbar from './MobileAppbar';
import allActions from '../../Store/Actions/AllActions';
import { useDispatch, useSelector } from 'react-redux';
import Constants from '../../Common/Constants';
const Navbar = (props: any) => {
  const renderBar = () => {
    if (!Utils.isMobile) {
      return <AppBar id="topWebNav" key={Utils.generateRandom(10, Constants.CryptoRandomTypes.numeric)} position="fixed" style={{ display: 'flex', width: "100%", flexDirection: 'row', flexWrap: 'nowrap', justifyContent: 'space-between' }}>
        <WebAppbar setuserProfileDialog={props.setuserProfileDialog} 
          changeStoreList={props.changeStoreList} store={props.store} 
          district={props.district} changeDistrict={props.changeDistrict} 
          region={props.region} changeRegion={props.changeRegion} 
          setSelectedOption={props.setSelectedOption} 
          loading={props.loading} user={props.user} />
      </AppBar>
    }
    else {
      return <AppBar key={Utils.generateRandom(10, Constants.CryptoRandomTypes.numeric)} position="static" style={{ display: 'flex', width: "100%", flexDirection: 'row', flexWrap: 'nowrap', justifyContent: 'space-between' }}>
        <MobileAppbar setuserProfileDialog={props.setuserProfileDialog} 
          changeStoreList={props.changeStoreList} store={props.store} 
          district={props.district} changeDistrict={props.changeDistrict}  
          region={props.region} changeRegion={props.changeRegion} 
          openFullScreenSearch={props.openFullScreenSearch} 
          changeState={props.changeState} />
      </AppBar>
    }
  }
  return (
    renderBar()
  );
}
export default Navbar;

import React, { useState } from 'react';
import './Routes.scss';
import { useTheme, withStyles } from '@material-ui/core/styles';
import { AppBar, Button, Grid } from '@material-ui/core';
import Utils from '../Common/Utils';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { Card, CardContent, Divider, InputBase, TextField, Checkbox, Tooltip } from '@mui/material';
import Stack from '@mui/material/Stack';
import Drawer from '@mui/material/Drawer';
//import moment from 'moment';
import DataTable from 'react-data-table-component';
import { IconButton, Paper, Typography } from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import SiteBreadCrumbs from '../Subcomponent/Shared/SiteBreadCrumbs';
import Constants from '../Common/Constants';
import allActions from '../Store/Actions/AllActions';
import { NotificationPopup } from "../Subcomponent/Shared/NotificationPopup";
import { INotificationPopupSettings, IButton } from "../Models/INotificationPopupSettings";
import TableViewSwitch from '../Subcomponent/FulfillOrders/TableViewSwitch';
import PickDataTable from '../Subcomponent/FulfillOrders/PickDataTable';
import MobileDataTable from '../Subcomponent/FulfillOrders/MobileDataTable';
import MobilePickDataTable from '../Subcomponent/FulfillOrders/MobilePickDataTable';
import Chip from "@mui/material/Chip";
import MultiPickSlip from "../Subcomponent/FulfillOrders/MultiPickSlip"
import PdfIframe from "../Subcomponent/PickOrder/PdfIframe"
import StoreManager from "../Managers/Store.manager";
import { IOrderQuery, IFilterInfo, IDateFilterValue, IOrderValueFilter } from "../Models/IOrderQuery";
import OrderManager from '../Managers/Orders.Manager';
import ShipmentStatusConstant from "../Common/ShipmentStatusConstants"
import { CloseIcon, PlaylistAddCheck, ShoppingCart, ApartmentIcon, DirectionsCar, FilterAltIcon, LocalShippingIcon, PreviewIcon, SearchIcon, ExpandMoreIcon, ExpandLessIcon, PrintIcon } from "../Subcomponent/Shared/SharedIcons"
import IdentityManager from '../Managers/Identity.manager';
import ExpandedRow from '../Subcomponent/FulfillOrders/ExpandedRow';
import OMSManager from '../Managers/Oms.manager';
import { ICatalogCategory } from '../Models/ICatalogCategory';
import { ISorting } from '../Models/ISorting';
import PickSlipGroupBy from '../Subcomponent/FulfillOrders/PickSlipGroupBy';
import HideZeroOrders from '../Subcomponent/FulfillOrders/HideZeroOrders';
import { OrderDetailNotificationPopup } from '../Subcomponent/Shared/OrderDetailNotificationPopup';
var moment = require('moment');
const steps = [
    'Pack',
    'Pick',
    'Pack',
    'Pickup'
];
const FulfillOrders = (props: any) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    var startProductBarPos = -1;
    const defaultPageSize = Utils.isMobile ? 30 : 25;
    const theme = useTheme();
    let currentTab = useSelector((state: any) => state.FulfillOrdersReducersState.currentTab);
    let selectedOrderTypeFilter = useSelector((state: any) => state.FulfillOrdersReducersState.selectedOrderTypeFilter);
    let orderTypeFilters = useSelector((state: any) => state.FulfillOrdersReducersState.orderTypeFilters);
    let selectedOrderAgeFilter = useSelector((state: any) => state.FulfillOrdersReducersState.selectedOrderAgeFilter);
    let orderAgeFilters = useSelector((state: any) => state.FulfillOrdersReducersState.orderAgeFilters);
    let selectedOrderValueFilter = useSelector((state: any) => state.FulfillOrdersReducersState.selectedOrderValue);
    let orderValueFilters = useSelector((state: any) => state.FulfillOrdersReducersState.orderValueFilters);
    let totalRecords = useSelector((state: any) => state.FulfillOrdersReducersState.total);
    let orderRows = useSelector((state: any) => state.FulfillOrdersReducersState.orderRows);
    let tabCounts = useSelector((state: any) => state.FulfillOrdersReducersState.tabsCount);
    let pickSlipGroupByModal = useSelector((state: any) => state.FulfillOrdersReducersState.pickSlipGroupByModal);
    const [openDrawer, setDrawer] = useState(false);
    const [rowDetail, setRowDetail] = useState({} as any);
    const [openDetailDrawer, setOpenDetailDrawer] = useState(false);
    // const [hideZero, setHideZero] = useState(true);
    const [errorMsg, setErrorMsg] = useState("");
    const [openError, setOpenError] = useState(false);
    const [openInfo, setOpenInfo] = useState(false);
    const [openConstruction, setOpenConstruction] = useState(false);
    const [catalogCategories, setCataLogCategories] = useState<ICatalogCategory>({ Category: [] });

    const [lineType, setLineType] = useState(0);
    const [isOpenFilters, setOpenFilters] = useState(true);
    const [ordersToPrint, setordersToPrint] = useState<string[]>([]);
    const [blobUrl, setBlobUrl] = useState("")
    const [showPdf, SetShowPdf] = useState(false)
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(defaultPageSize);
    const [selectAllCheckBox, setSelectAllCheckBox] = useState(false);
    const [intermediate, setIntermediate] = useState(false);
    const [status, setStatus] = useState(0);
    const [sort, setSort] = useState<ISorting>({ sortField: "Age", sortOrder: "desc" } as any);
    const [loadingRvpStores, setLoadingRvpStores] = useState(true);
    const location: any = useLocation();
    window.onscroll = function () {
        var bar = document.getElementById('paginationWebId') as any;
        var dataTable = document.getElementById('containerDataTable') as any;
        if (bar && dataTable) {
            var dataTableWidth = dataTable.offsetWidth;
            if (startProductBarPos < 0) startProductBarPos = findPosY(bar);
            if (window.pageYOffset > startProductBarPos && (window.pageYOffset - startProductBarPos > 50)) {
                bar.style.width = (dataTableWidth - (0.8 * dataTableWidth / 100)) + "px";
                bar.classList.add("fixedPagination");
                bar.style.marginTop = Utils.getWebbarheight + "px";
            } else {
                bar.classList.remove("fixedPagination");
                bar.style.width = "";
                bar.style.marginTop = "";
            }
        }
        // Mobile View Fix the Tabs and FILTER
        if (Utils.isMobile) {
            console.log("scrolled");
            bar = document.getElementById('tabFilterContaoner') as any;
            var mblBar = document.getElementById('mblBar') as any;
            if (bar) {
                console.log("findPosY(mblBar);" + findPosY(mblBar))
                console.log("startProductBarPos=" + startProductBarPos)
                console.log("window.pageYOffset=" + window.pageYOffset)
                if (startProductBarPos < 0) startProductBarPos = findPosY(mblBar);
                if (window.pageYOffset > startProductBarPos) {
                    bar.classList.add("scrolledTopFixFulFillOrders");
                } else {
                    bar.classList.remove("scrolledTopFixFulFillOrders");
                }
            }
        }
    };
    // const onChangeHideZero = () => {
    //     setHideZero(!hideZero)
    // }
    const selectAll = (e: any) => {
        setSelectAllCheckBox(!selectAllCheckBox);
        if (e.target.checked) {
            let shipmentIds = [] as any
            orderRows.forEach((element: any) => {
                shipmentIds.push(element._id)
            });
            setordersToPrint(shipmentIds)
        }
        else {
            let shipmentIds = [] as any
            setordersToPrint(shipmentIds)
        }
    }
    const checkIntermediate = () => {
        if (ordersToPrint.length == 0) {
            setSelectAllCheckBox(false)
            setIntermediate(false)
        }
        else {
            if (orderRows.length == ordersToPrint.length) {
                setIntermediate(false)
                setSelectAllCheckBox(true)
            }
            else {
                setSelectAllCheckBox(false)
                setIntermediate(true)
            }
        }
    }


    const updateStatusState = () => {
        setStatus(status + 1);
    }

    const closeErrorPopup = () => {
        setOpenError(false)
    }


    const openInfoPopup = () => {
        setOpenInfo(true);
        updateStatusState();
    }

    const closeInfoPopup = () => {
        setOpenInfo(false)
    }

    const openErrorPopup = () => {
        setOpenError(true)
    }
    const closeConstructionPopup = () => {
        setOpenConstruction(false)
    }
    const openConstructionPopup = () => {
        setOpenConstruction(true)
    }
    let errorPopupButtons: IButton[] = [
        {
            text: "Close",
            icon: <CloseIcon />,
            action: closeErrorPopup,
            color: Constants.Colors.red,
        },
    ];
    let Error: INotificationPopupSettings = {
        open: openError,
        type: Constants.NotificationPopupType.Error,
        title: "Error",
        msg: errorMsg,
        draggable: false,
        handleClose: closeErrorPopup,
        actions: errorPopupButtons
    }

    let Info = {
        open: openInfo,
        msg: "The Order Status has been changed. Your screen will automatically refresh now.",
        handleClose: closeInfoPopup,
        zIndex: 10000000
    };


    let constructionButtons: IButton[] = [
        {
            text: "Close",
            icon: <CloseIcon />,
            action: closeConstructionPopup,
            color: Constants.Colors.red,
        },
    ];
    let underconstruction: INotificationPopupSettings = {
        open: openConstruction,
        type: Constants.NotificationPopupType.Info,
        title: "",
        msg: "Feature under construction",
        draggable: false,
        handleClose: closeConstructionPopup,
        actions: constructionButtons
    }
    function findPosY(obj: any) {
        var curtop = 0;
        if (obj && typeof (obj.offsetParent) != 'undefined' && obj.offsetParent) {
            while (obj.offsetParent) {
                curtop += obj.offsetTop;
                obj = obj.offsetParent;
            }
            curtop += obj.offsetTop;
        }
        else if (obj && obj.y)
            curtop += obj.y;
        return curtop;
    }
    const showActionLoader = () => {
        dispatch(allActions.actionLoaderActions.showLoader());
    }
    const hideActionLoader = () => {
        dispatch(allActions.actionLoaderActions.hideLoader());
    }
    const handleCheckboxChange = (e: any, orderNumber: string) => {
        if (e.target.checked) {
            let checkedOrders = [...ordersToPrint]
            checkedOrders.push(orderNumber)
            setordersToPrint(checkedOrders)
        }
        else {
            let checkedOrders = [...ordersToPrint]
            let removed = checkedOrders.filter(function (value, index, arr) {
                return value != orderNumber;
            });
            setordersToPrint(removed)
        }
    }
    const openFilters = () => {
        setOpenFilters(true)
    };
    const closePdfiframe = () => {

        SetShowPdf(false);
    }
    const openPdfiframe = async (isPickSlip: any) => {

        if (ordersToPrint.length > 0) {



            if (isPickSlip) {
                SetShowPdf(true);
            }
            else {
                dispatch(allActions.fulfillOrdersActions.changePrintPickSlips({
                    ...pickSlipGroupByModal,
                    open: true
                }));
            }

        }
    }
    const closeFilters = () => {
        setOpenFilters(false)
    };
    const toggleDrawer = (op: any) => {
        setDrawer(op);
    };
    const handleFilterChange = () => {
        setOpenFilters(!isOpenFilters)
    }
    const changeLineType = (e: any, newValue: any) => {
        setordersToPrint([]);
        setLineType(newValue);
    }
    // Pagination 
    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(+(parseInt(event.target.value)));
        setPage(0);
    };
    // End Drawer Code
    const handleChangeTab = (event: any, newValue: number) => {
        setPage(0);
        console.log(newValue, "handleChange");
        //setValue(newValue);
        dispatch(allActions.fulfillOrdersActions.changeFOTab(newValue));

    };
    const quickFilterClicked = (filterType: string, newSeletedFilter: string) => {
        if (filterType == "OrderType") {
            dispatch(allActions.fulfillOrdersActions.changeSelectedOrderType(newSeletedFilter));
            if (newSeletedFilter == "ALL") {
                dispatch(allActions.fulfillOrdersActions.changeSelectedOrderAge(""));
                dispatch(allActions.fulfillOrdersActions.changeSelectedOrderValue(""));
            }
        }
        if (filterType == "OrderAge") {
            dispatch(allActions.fulfillOrdersActions.changeSelectedOrderAge(newSeletedFilter));
        }
        if (filterType == "OrderValue") {
            dispatch(allActions.fulfillOrdersActions.changeSelectedOrderValue(newSeletedFilter));
        }
    }

    const getDate = (row: any) => {
        let date: string = ""
        if (row._source.Shipment.OrderDate) {
            date = moment.utc(row._source.Shipment.OrderDate).tz("America/Los_Angeles").format(Constants.DateFormat.Format);
        }
        return date
    }

    const getCustomerName = (row: any) => {
        let name: string = "";
        if (row?._source?.Shipment?.CustomerInfo?.FirstName) {
            name = row._source.Shipment.CustomerInfo.FirstName + ' ' + row._source.Shipment.CustomerInfo.LastName
        }
        return name;
    }

    // This method will clear the local storage data for batch picking
    const resetLocalStorage = () => {

        let currentDateTime = moment().toISOString();
        let storageDate = localStorage.getItem("storageCreateDate");
        if (storageDate && storageDate.length > 0) {
            let a = moment(currentDateTime);//now
            let b = moment(storageDate);
            let mins = a.diff(b, 'minutes');
            console.log(mins, "minutes");

            if (mins > 30) {
                localStorage.removeItem("1mobilePickSingleMultipleMarkedValues");
                localStorage.removeItem("2mobilePickSingleMultipleMarkedValues");
            }
        }


    }


    const getType = (row: any) => {
        let isWarranty = false;
        let isBundle = false;
        row._source.Shipment.ShipmentLines.ShipmentLine.forEach((element: any) => {
            if (element.ExtnIsWarrantyItem == "Y") {
                isWarranty = true
            }
            if (element.KitCode && element.KitCode != "") {
                isBundle = true
            }
        })
        let title = ""
        let cellValue = ""
        if (isBundle) {
            title = title + "SET"
            cellValue = cellValue + "S"
        }
        if (isWarranty) {
            if (isBundle) {
                title = title + "/PRO COV"
                cellValue = cellValue + "/PC"
            }
            else {
                title = title + "PRO COV"
                cellValue = cellValue + "PC"
            }
        }
        return <Tooltip title={title}>
            {
                isBundle || isWarranty ?
                    <Chip
                        className="chipTracking"
                        label={cellValue}
                        style={{
                            background: "white",
                            color: "#dc2b2b",
                            fontSize: "11px",
                            height: "15px",
                            border: "1px solid",
                            position: "absolute",
                            top: "2%",
                            right: "0"
                        }}
                    />
                    :
                    <></>
            }
        </Tooltip>
    }
    const getDescription = (row: any) => {
        let description = ""
        // let count = row?._source?.Shipment?.ShipmentLines?.ShipmentLine?.length
        // if (count) {
        //     for (let i = 0; i < count; i++) {
        //         let element = row._source.Shipment.ShipmentLines.ShipmentLine[i]
        //         if (element.ExtnIsWarrantyItem == "N" && element.MinLineStatusDesc != "Cancelled") {
        //             description = element.ItemDesc
        //             break
        //         }
        //     }
        // }
        description = row?._source?.Shipment?.ShipmentDescription
        return <Tooltip title={description}><p style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{description}</p></Tooltip>
    }
    const getDescriptionText = (row: any) => {
        let description = ""
        let count = row?._source?.Shipment?.ShipmentLines?.ShipmentLine?.length
        if (count) {
            for (let i = 0; i < count; i++) {
                let element = row._source.Shipment.ShipmentLines.ShipmentLine[i]
                if (element.ExtnIsWarrantyItem == "N" && element.MinLineStatusDesc != "Cancelled") {
                    description = element.ItemDesc
                    break
                }
            }
        }
        return description
    }
    const getLevelOfService = (row: any) => {
        let los = ""
        if (row._source.Shipment.IsSameStorePickup != undefined && row._source.Shipment.IsSameStorePickup == "Y") {
            los = "Store Pickup"
        }
        else {
            los = row._source.Shipment.LevelOfService
        }
        return los
    }
    const getAgeTextFormat = (ms: any) => {
        const obj = Utils.convertHoursToDays(ms)

        if (obj.Days && obj.Hours) {
            return `${obj.Days} d(s) - ${obj.Hours} hr(s)`
        }
        if (obj.Days) {
            return `${obj.Days} d(s) `
        }
        if (obj.Hours) {
            return `${obj.Hours} hr(s) `
        }
        if (obj.Minutes) {
            return `${obj.Minutes} min(s) `
        }
        if (obj.Sec) {
            return `${obj.Sec} sec(s) `
        } else { return `` }
    }
    const getOrderNumberWithType = (row: any) => {

        return [getType(row), <span>{row._source.Shipment.OrderNo}</span>];

    }
    const getColumns = () => {
        const columns = [
            {
                name: 'Action',
                cell: (row: any) => WebGridButton(row),
                ignoreRowClick: true,
                allowOverflow: true,
                button: true,
                minWidth: "200px",
                width: "200px"
            },
            {
                id: "OrderNo",
                name: 'Order Number',
                cell: (row: any) => getOrderNumberWithType(row),
                selector: (row: any) => row._source.Shipment.OrderNo,
                sortable: true,
                sortfield: "OrderNo",
                minWidth: "170px",
                width: "170px"
            },
            {
                id: "CustomerName",
                name: 'Customer Name',
                selector: (row: any) => getCustomerName(row),
                sortable: true,
                sortfield: "CustomerName",
                minWidth: "170px",
                width: "170px",
                omit: currentTab != 3
            },
            {
                id: "OrderDate",
                name: 'Ordered On',
                selector: (row: any) => getDate(row),
                sortable: true,
                sortfield: "OrderDate",
                minWidth: "180px",
                width: "180px"
            },
            {
                id: "LevelOfService",
                name: 'Ship Method',
                selector: (row: any) => getLevelOfService(row),
                sortable: true,
                sortfield: "LevelOfService",
                minWidth: "170px",
                width: "170px",
                omit: currentTab == 3
            },
            {
                id: "Qty",
                name: 'Qty',
                //selector: (row: any) => Utils.getCount(row),
                selector: (row: any) => row._source.Shipment.qtyCountCalculated,
                sortable: true,
                sortfield: "Qty",
                minWidth: "85px",
                width: "85px"
            },
            // {
            //     name: 'Line Type',
            //     cell: (row: any) => getType(row),
            //     sortable: true,
            //     minWidth: "120px",
            //     width: "120px"
            // },
            {
                id: "Age",
                name: 'Age',
                cell: (row: any) => getAgeTextFormat(row._source.Shipment.Age),
                selector: (row: any) => row._source.Shipment.Age,
                sortable: true,
                sortfield: "Age",
                minWidth: "140px",
                width: "140px"
            },
            {
                id: "Store",
                name: 'Store',
                selector: (row: any) => row._source.Shipment.Status == ShipmentStatusConstant.ReadyForCustomerPickup ? row._source.Shipment?.ExtnPickupStoreID : row._source.Shipment.ShipNode,
                sortable: true,
                sortfield: "Store",
                minWidth: "85px",
                width: "85px",
                omit: props.store?.length <= 1
            },
            {
                id: "Description",
                name: 'Description',
                cell: (row: any) => getDescription(row),
                sortable: true,
                sortfield: "Description",
                width: Utils.getDescriptionWidth("") + "px",
            }

        ];
        return columns;
    }
    const clearFilter = () => {
        dispatch(allActions.fulfillOrdersActions.changeSelectedOrderType("ALL"));
        dispatch(allActions.fulfillOrdersActions.changeSelectedOrderAge(""));
        dispatch(allActions.fulfillOrdersActions.changeSelectedOrderValue(""));
    }
    function getSum(total: number, item: any) {
        return total + item.doc_count;
    }

    const getDataFromElastic = async () => {

        let storeId = IdentityManager.getMultipleStoresId(props.store);
        if (!storeId) {
            return
        }



        console.log("getDataFromElastic")
        showActionLoader()
        let categors = OMSManager?.catalogCategories?.Category;
        if (!categors || categors.length == 0) {
            await getCategoryCatalog();
        }
        let aggPayload = {
            "store": storeId
        }
        let storeID = storeId// "110" 
        let allOrdersCount = 0;
        let customerPickOrdersCount = 0;
        let packOrdersCount = 0;
        let pickOrdersCount = 0;

        OrderManager.getOrdersAgg(aggPayload).then((aggResult: any) => {
            try {
                if (aggResult.success) {
                    let customerPickOrdersAggregate = aggResult.data.aggregations.CustomerPickOrders.unique_orders?.value;
                    let packOrdersAggregate = aggResult.data.aggregations.orderCountByType.buckets.PackOrders.doc_count;
                    let pickOrdersAggregate = aggResult.data.aggregations.orderCountByType.buckets.PickOrders.doc_count;
                    let allOrdersAggregate = Utils.parseIntCustom(customerPickOrdersAggregate)
                        + Utils.parseIntCustom(packOrdersAggregate) +
                        Utils.parseIntCustom(pickOrdersAggregate);


                    if (allOrdersAggregate != undefined) {
                        allOrdersCount = allOrdersAggregate
                    }
                    if (customerPickOrdersAggregate != undefined) {
                        customerPickOrdersCount = customerPickOrdersAggregate
                    }
                    if (packOrdersAggregate != undefined) {
                        packOrdersCount = packOrdersAggregate
                    }
                    if (pickOrdersAggregate != undefined) {
                        pickOrdersCount = pickOrdersAggregate
                    }

                    let tabCounts = [
                        {
                            name: Constants.TabCounts[0].name,
                            valueCount: allOrdersCount
                        },
                        {
                            name: Constants.TabCounts[1].name,
                            valueCount: pickOrdersCount
                        },
                        {
                            name: Constants.TabCounts[2].name,
                            valueCount: packOrdersCount
                        },
                        {
                            name: Constants.TabCounts[3].name,
                            valueCount: customerPickOrdersCount
                        }
                    ]
                    dispatch(allActions.fulfillOrdersActions.changeFOTabCounts(tabCounts));
                }
                else {
                    resetTabsCount();
                    console.log("Error Order Aggregate Count=" + aggResult.msg)
                }
            }
            catch {
                resetTabsCount();
                console.log("Error Order Aggregate Count")
            }
        });



        let orderTypeDecision = ""
        if (currentTab == 1) {
            orderTypeDecision = ShipmentStatusConstant.ReadyForPickString
        }
        else if (currentTab == 2) {
            orderTypeDecision = ShipmentStatusConstant.ReadyForPackString
        }
        else if (currentTab == 3) {
            orderTypeDecision = ShipmentStatusConstant.ReadyForCustomerPickupString
        }

        let isExpaditedInfo: IFilterInfo = {
            include: false,
            value: ""
        }
        let isSameStorePickupInfo: IFilterInfo = {
            include: false,
            value: ""
        }
        let isShipToCustomerInfo: IFilterInfo = {
            include: false,
            value: ""
        }
        let isShipToStorePickupInfo: IFilterInfo = {
            include: false,
            value: ""
        }
        if (selectedOrderTypeFilter == Constants.OrderTypeFilters[1].name) {
            isExpaditedInfo.include = true;
            isExpaditedInfo.value = "Y"
        }
        if (selectedOrderTypeFilter == Constants.OrderTypeFilters[2].name) {
            isSameStorePickupInfo.include = true;
            isSameStorePickupInfo.value = "Y";
        }
        if (selectedOrderTypeFilter == Constants.OrderTypeFilters[3].name) {
            isShipToCustomerInfo.include = true;
            isShipToCustomerInfo.value = "Y"
        }
        if (selectedOrderTypeFilter == Constants.OrderTypeFilters[4].name) {
            isShipToStorePickupInfo.include = true;
            isShipToStorePickupInfo.value = "Y"
        }
        let orderAgeFilter: IDateFilterValue = {
            include: false,
            startDate: "",
            endDate: "",
        }

        if (selectedOrderAgeFilter != "") {
            if (selectedOrderAgeFilter == Constants.OrderAgeFilters[3].name) {
                let startDateEndDate = Utils.GetStartEndDate(selectedOrderAgeFilter)
                orderAgeFilter.include = true
                orderAgeFilter.endDate = startDateEndDate.end
            }
            else {
                let startDateEndDate = Utils.GetStartEndDate(selectedOrderAgeFilter)
                orderAgeFilter.include = true
                orderAgeFilter.startDate = startDateEndDate.start
                orderAgeFilter.endDate = startDateEndDate.end
            }
        }
        let orderValueFilter: IOrderValueFilter = {
            include: false,
            min: "",
            max: ""
        }
        if (selectedOrderValueFilter == Constants.OrderValueFilters[0].name) {
            orderValueFilter.include = true
            orderValueFilter.min = "0"
            orderValueFilter.max = "25"
        }
        if (selectedOrderValueFilter == Constants.OrderValueFilters[1].name) {
            orderValueFilter.include = true
            orderValueFilter.min = "25"
            orderValueFilter.max = "100"
        }
        if (selectedOrderValueFilter == Constants.OrderValueFilters[2].name) {
            orderValueFilter.include = true
            orderValueFilter.min = "100"
            orderValueFilter.max = "500"
        }
        if (selectedOrderValueFilter == Constants.OrderValueFilters[3].name) {
            orderValueFilter.include = true
            orderValueFilter.min = "500"
            orderValueFilter.max = "500000"
        }
        let from = page * rowsPerPage;
        let size = Utils.isMobile ? 5000 : rowsPerPage;
        let queryParameters: IOrderQuery = {
            store: storeID,
            isExpedite: isExpaditedInfo,
            isShipToCustomer: isShipToCustomerInfo,
            isSameStorePickup: isSameStorePickupInfo,
            isShipToStore: isShipToStorePickupInfo,
            orderType: orderTypeDecision,
            pFrom: from,
            pSize: size,
            orderAge: orderAgeFilter,
            orderValue: orderValueFilter,
            sortField: sort.sortField,
            sortOrder: sort.sortOrder
            //hideZero: hideZero,
        }

        let resultResponse = {} as any;
        try {
            resultResponse = await OrderManager.getOrders(queryParameters);
            if (resultResponse.success) {
                let result = resultResponse.data
                let total = result.hits.total.value;
                console.log(total, "total2");
                let dataRows = result.hits.hits;
                // dataRows.forEach((element: any) => {
                //     //element._source.Shipment["Age"] = Utils.getOrderAge(element);
                //element._source.Shipment["defaultExpanded"] = Utils.getRowCollaprsedItemDistinctBase(element);
                // });
                let isExpediteCount = result.nAggregate.IsExpediteCount;
                let isSameStorePickupCount = result.nAggregate.IsSameStorePickupCount;
                let isShipToCustomerCount = result.nAggregate.IsShipToCustomerCount;
                let isShipToStorePickupCount = result.nAggregate.IsShipToStoreCount;

                let orderTypeFiler = [
                    {
                        name: Constants.OrderTypeFilters[0].name,
                        valueCount: total
                    },
                    {
                        name: Constants.OrderTypeFilters[1].name,
                        valueCount: isExpediteCount
                    },
                    {
                        name: Constants.OrderTypeFilters[2].name,
                        valueCount: isSameStorePickupCount
                    },
                    {
                        name: Constants.OrderTypeFilters[3].name,
                        valueCount: isShipToCustomerCount
                    },
                    {
                        name: Constants.OrderTypeFilters[4].name,
                        valueCount: isShipToStorePickupCount
                    },

                ]
                let oneDayCount = 0;
                let twoDayCount = 0;
                let threeDayCount = 0;
                let otherDayCount = 0;
                let oneDayAggregate = result.aggregations.orderAge.buckets.OneDay.doc_count
                let twoDayAggregate = result.aggregations.orderAge.buckets.TwoDay.doc_count
                let threeDayAggregate = result.aggregations.orderAge.buckets.ThreeDay.doc_count
                let otherDayAggregate = result.aggregations.orderAge.buckets.OtherDay.doc_count
                if (oneDayAggregate != undefined) {
                    oneDayCount = oneDayAggregate
                }
                if (twoDayAggregate != undefined) {
                    twoDayCount = twoDayAggregate
                }
                if (threeDayAggregate != undefined) {
                    threeDayCount = threeDayAggregate
                }
                if (otherDayAggregate != undefined) {
                    otherDayCount = otherDayAggregate
                }
                let orderageFilter = [
                    {
                        name: Constants.OrderAgeFilters[0].name,
                        valueCount: oneDayCount
                    },
                    {
                        name: Constants.OrderAgeFilters[1].name,
                        valueCount: twoDayCount
                    },
                    {
                        name: Constants.OrderAgeFilters[2].name,
                        valueCount: threeDayCount
                    },
                    {
                        name: Constants.OrderAgeFilters[3].name,
                        valueCount: otherDayCount
                    }
                ]
                let zeroToTwentyFive = 0
                let twentyFiveToHundred = 0
                let hundredToFiveHundred = 0
                let fiveHundredToInfinity = 0
                // zeroToTwentyFive = result.aggregations['0_25'].buckets.reduce(getSum, 0)
                // twentyFiveToHundred = result.aggregations['25_100'].buckets.reduce(getSum, 0)
                // hundredToFiveHundred = result.aggregations['100_500'].buckets.reduce(getSum, 0)
                // fiveHundredToInfinity = result.aggregations['500_5000000'].buckets.reduce(getSum, 0)
                zeroToTwentyFive = result.orderValueAggregate.lessThan25;
                twentyFiveToHundred = result.orderValueAggregate.between25_100;
                hundredToFiveHundred = result.orderValueAggregate.between100_500;
                fiveHundredToInfinity = result.orderValueAggregate.greaterThan500;

                let orderValueFilter = [
                    {
                        name: Constants.OrderValueFilters[0].name,
                        valueCount: zeroToTwentyFive
                    },
                    {
                        name: Constants.OrderValueFilters[1].name,
                        valueCount: twentyFiveToHundred
                    },
                    {
                        name: Constants.OrderValueFilters[2].name,
                        valueCount: hundredToFiveHundred
                    },
                    {
                        name: Constants.OrderValueFilters[3].name,
                        valueCount: fiveHundredToInfinity
                    }
                ]
                dispatch(allActions.fulfillOrdersActions.changeOrderValueFilter(orderValueFilter));
                dispatch(allActions.fulfillOrdersActions.changeOrderTypeFilter(orderTypeFiler));
                dispatch(allActions.fulfillOrdersActions.changeOrderAgeFilter(orderageFilter));
                dispatch(allActions.fulfillOrdersActions.changeTotal(total));
                dispatch(allActions.fulfillOrdersActions.changeOrderRows(dataRows));
            }
            else {
                hideActionLoader()
                let message = "Some thing went wrong please contact the IT Help Desk for assistance"
                console.log(resultResponse.msg)
                setErrorMsg(message);
                openErrorPopup()
            }
        }
        catch (e) {
            hideActionLoader()
            let message = "Some thing went wrong please contact the IT Help Desk for assistance"

            console.log(resultResponse.msg)
            setErrorMsg(message);
            openErrorPopup()
        }

        hideActionLoader()
    }

    const getCategoryCatalog = async () => {

        await OMSManager.GCGetEComCatalog().then((data: any) => {
            OMSManager.catalogCategories = data.data;
            console.log(OMSManager.catalogCategories, "OMSManager.catalogCategories");
        })

    }

    const setLocalStorageAwayTime = () => {

        localStorage.setItem("storageCreateDate", moment().toISOString());
    }

    React.useEffect(() => {
        resetLocalStorage();
        return () => {
            // Destructor
            setLocalStorageAwayTime();
        }
    }, [])

    React.useEffect(() => {
        checkIntermediate()
    }, [ordersToPrint])

    React.useEffect(() => {
        async function sendRequest() {
            await getDataFromElastic()
        }
        sendRequest()
        return () => {
        }
    }, [currentTab, selectedOrderTypeFilter, selectedOrderAgeFilter, selectedOrderValueFilter, props.store, sort, page, rowsPerPage, status]);
    const renderTabCount = (tab: number) => {
        return " (" + tabCounts[tab].valueCount + ")"
    }

    const resetTabsCount = () => {
        let tabCounts = [
            {
                name: Constants.TabCounts[0].name,
                valueCount: 0
            },
            {
                name: Constants.TabCounts[1].name,
                valueCount: 0
            },
            {
                name: Constants.TabCounts[2].name,
                valueCount: 0
            },
            {
                name: Constants.TabCounts[3].name,
                valueCount: 0
            }];
        dispatch(allActions.fulfillOrdersActions.changeFOTabCounts(tabCounts));
    }

    const renderOrderTypeTabs = () => {
        return <Box sx={{ bgcolor: 'background.paper' }}>
            <AppBar position="static" style={{ background: "white" }}>
                <Tabs
                    value={currentTab}
                    onChange={handleChangeTab}
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                    className='fTabs'
                >
                    <Tab icon={<PlaylistAddCheck className='fTabIcons' />} label={"All Orders" + renderTabCount(Constants.TabCounts[0].name)} />
                    <Tab icon={<ShoppingCart className='fTabIcons' />} label={"Picking" + renderTabCount(Constants.TabCounts[1].name)} />
                    <Tab icon={<ApartmentIcon className='fTabIcons' />} label={"Packing" + renderTabCount(Constants.TabCounts[2].name)} />
                    <Tab icon={<DirectionsCar className='fTabIcons' />} label={"Customer Pickup" + renderTabCount(Constants.TabCounts[3].name)} />
                </Tabs>
            </AppBar>
        </Box>
    }
    const detailDrawerOpen = (id: any) => {
        //
        let selectedOrder = orderRows.filter((order: any) => {
            return order._id == id
        })
        if (selectedOrder && selectedOrder.length > 0) {
            setRowDetail(selectedOrder[0])
            setOpenDetailDrawer(true)
        }
    }
    const renderDetailDrawer = () => {
        return (
            <Card sx={{ mt: 1 }} className="orderDetailMobileContainer">
                <CardContent>
                    <Stack direction={"row"}>
                        <TextField
                            id="outlined-read-only-input"
                            label="Order #"
                            defaultValue={rowDetail._source.Shipment.OrderNo}
                            InputProps={{
                                readOnly: true,
                            }}
                            variant="standard"
                            style={{ margin: "4px" }}
                        />
                        <TextField
                            id="outlined-read-only-input"
                            label="Ship Via"
                            defaultValue={rowDetail._source.Shipment.LevelOfService}
                            InputProps={{
                                readOnly: true,
                            }}
                            variant="standard"
                            style={{ margin: "4px" }}
                        />
                    </Stack>
                    <Stack direction={"row"}>
                        <TextField
                            id="outlined-read-only-input"
                            label="Qty"
                            defaultValue={Utils.getCount(rowDetail)}
                            InputProps={{
                                readOnly: true,
                            }}
                            variant="standard"
                            style={{ margin: "4px" }}
                        />
                        <TextField
                            id="outlined-read-only-input"
                            label="Ordered On"
                            defaultValue={getDate(rowDetail)}
                            InputProps={{
                                readOnly: true,
                            }}
                            variant="standard"
                            style={{ margin: "4px" }}
                        />
                    </Stack>

                    <ExpandedRow data={rowDetail}></ExpandedRow>

                </CardContent>
            </Card>
        );
    }
    const renderCountOfOrderTypeFilter = (filter: any) => {

        if (selectedOrderTypeFilter == "ALL" || selectedOrderTypeFilter == filter.name) {
            let renderCount = filter.valueCount == 0 ? "" : filter.valueCount;
            return renderCount != "" ? <Box style={{
                backgroundColor: selectedOrderTypeFilter == filter.name ? Constants.Colors.white : Constants.Colors.red
                , color: selectedOrderTypeFilter == filter.name ? Constants.Colors.red : Constants.Colors.white
                , width: "25px"
                , height: "25px"
                , textAlign: "center"
                , display: "flex"
                , justifyContent: "center"
            }} borderRadius="50%"><b style={{ fontSize: 12, marginTop: 3 }}>{renderCount}</b></Box>
                :
                ""
        }
        else {
            return ''
        }
    }
    const renderCountOfOrderAgeFilter = (filter: any) => {
        if (selectedOrderAgeFilter == "" || selectedOrderAgeFilter == filter.name) {
            let renderCount = filter.valueCount == 0 ? "" : filter.valueCount;
            return renderCount != "" ? <Box style={{
                backgroundColor: selectedOrderAgeFilter == filter.name ? Constants.Colors.white : Constants.Colors.red
                , color: selectedOrderAgeFilter == filter.name ? Constants.Colors.red : Constants.Colors.white
                , width: "25px"
                , height: "25px"
                , textAlign: "center"
                , display: "flex"
                , justifyContent: "center"
            }} borderRadius="50%"><b style={{ fontSize: 12, marginTop: 3 }}>{renderCount}</b></Box>
                :
                ""
        }
        else {
            return ''
        }
    }
    const renderCountOfOrderValueFilter = (filter: any) => {
        if (selectedOrderValueFilter == "" || selectedOrderValueFilter == filter.name) {
            let renderCount = filter.valueCount == 0 ? "" : filter.valueCount;
            return renderCount != "" ? <Box style={{
                backgroundColor: selectedOrderValueFilter == filter.name ? Constants.Colors.white : Constants.Colors.red
                , color: selectedOrderValueFilter == filter.name ? Constants.Colors.red : Constants.Colors.white
                , width: "25px"
                , height: "25px"
                , textAlign: "center"
                , display: "flex"
                , justifyContent: "center"
            }} borderRadius="50%"><b style={{ fontSize: 12, marginTop: 3 }}>{renderCount}</b></Box>
                :
                ""
        }
        else {
            return ''
        }
    }
    const renderFilters = () => {
        let open = isOpenFilters;
        let orderAgeTotalCount = orderAgeFilters.reduce(function (sum: any, current: any) {
            return sum + Number(current.valueCount);
        }, 0)
        let orderValueTotalCount = orderValueFilters.reduce(function (sum: any, current: any) {
            return sum + Number(current.valueCount);
        }, 0)
        if (Utils.isMobile) {
            open = true
        }
        return <Box sx={{ p: 3, bgcolor: 'background.paper' }} style={{ padding: "0px" }}>
            <Accordion expanded={open} className='fQuickFiltersFulfillOrders'>
                <AccordionSummary
                    //onChange={() => handleFilterChange()}
                    // expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className="headingContainer"
                >
                    <Grid container>
                        <Grid item xs={6} sm={6}>
                            <Box display="flex" justifyContent="flex-start" style={{ float: "left" }}>
                                <Typography style={{ marginTop: "8px" }} variant='h5' className='topheading'><FilterAltIcon className='fTabIcons' /> Quick Filters</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={6} sm={6} style={{}}>
                            <Box display="flex" justifyContent="flex-end" onClick={() => clearFilter()} style={{ float: "right" }}>
                                <Button variant="outlined" color="primary" style={{ margin: 4 }}>Clear Filter</Button>
                                {
                                    !Utils.isMobile ?
                                        <IconButton onClick={() => handleFilterChange()} style={{ padding: 0, background: Constants.Colors.lightGrey, margin: 8 }}>
                                            {
                                                open ?
                                                    <ExpandLessIcon style={{ color: Constants.Colors.red }} />
                                                    :
                                                    <ExpandMoreIcon style={{ color: Constants.Colors.red }} />

                                            }
                                        </IconButton>
                                        :
                                        ""
                                }
                            </Box>
                        </Grid>
                    </Grid>

                </AccordionSummary>
                <AccordionDetails>
                    <Grid spacing={2} container style={{ margin: "0px" }}>
                        <Grid item xs={12} sm={6} style={{ borderRight: Utils.isMobile ? "" : "1px dotted #949494", padding: "0px" }}>
                            <Typography variant='h6' className='heading' color='primary'>Order Type</Typography>
                            <Stack spacing={2} direction="row" className='btnStack'>
                                <Button onClick={() => quickFilterClicked("OrderType", orderTypeFilters[0].name)} variant={selectedOrderTypeFilter == "ALL" ? "contained" : "outlined"} color="primary">All&nbsp;{renderCountOfOrderTypeFilter(orderTypeFilters[0])}</Button>
                                <Button onClick={() => quickFilterClicked("OrderType", orderTypeFilters[1].name)} variant={selectedOrderTypeFilter == "EXPEDITED" ? "contained" : "outlined"} color="primary">Expedited&nbsp;{renderCountOfOrderTypeFilter(orderTypeFilters[1])}</Button>
                                <Button onClick={() => quickFilterClicked("OrderType", orderTypeFilters[2].name)} variant={selectedOrderTypeFilter == "INSTORE" ? "contained" : "outlined"} color="primary">In Store&nbsp;{renderCountOfOrderTypeFilter(orderTypeFilters[2])}</Button>
                            </Stack>
                            <Stack spacing={2} direction="row" className='btnStack'>
                                <Button onClick={() => quickFilterClicked("OrderType", orderTypeFilters[3].name)} variant={selectedOrderTypeFilter == "SHIPTOCUSTOMER" ? "contained" : "outlined"} color="primary">Ship To Customer&nbsp;{renderCountOfOrderTypeFilter(orderTypeFilters[3])}</Button>
                                <Button onClick={() => quickFilterClicked("OrderType", orderTypeFilters[4].name)} variant={selectedOrderTypeFilter == "SHIPTOSTORE" ? "contained" : "outlined"} color="primary">Ship To Store&nbsp;{renderCountOfOrderTypeFilter(orderTypeFilters[4])}</Button>
                            </Stack>
                            <Divider orientation="vertical" variant="middle" flexItem />
                        </Grid>
                        <Grid item xs={12} sm={3} style={{ borderRight: Utils.isMobile ? "" : "1px dotted #949494", padding: "0px" }}>
                            <Typography variant='h6' className='heading' color='primary'>Order Age (days)</Typography>
                            {
                                orderAgeTotalCount == 0 && selectedOrderAgeFilter == "" ?
                                    <Typography className='' style={{ color: "black", marginTop: 10, marginBottom: 10, fontSize: "16px", fontWeight: "250" }}>No Order Age</Typography>
                                    :
                                    <React.Fragment>
                                        <Stack spacing={2} direction="row" className='btnStack'>
                                            <Button onClick={() => quickFilterClicked("OrderAge", orderAgeFilters[0].name)} variant={selectedOrderAgeFilter == "0-1" ? "contained" : "outlined"} color="primary">0-1&nbsp;{renderCountOfOrderAgeFilter(orderAgeFilters[0])}</Button>
                                            <Button onClick={() => quickFilterClicked("OrderAge", orderAgeFilters[1].name)} variant={selectedOrderAgeFilter == "1-2" ? "contained" : "outlined"} color="primary">1-2&nbsp;{renderCountOfOrderAgeFilter(orderAgeFilters[1])}</Button>
                                        </Stack>
                                        <Stack spacing={2} direction="row" className='btnStack'>
                                            <Button onClick={() => quickFilterClicked("OrderAge", orderAgeFilters[2].name)} variant={selectedOrderAgeFilter == "2-3" ? "contained" : "outlined"} color="primary">2-3&nbsp;{renderCountOfOrderAgeFilter(orderAgeFilters[2])}</Button>
                                            <Button onClick={() => quickFilterClicked("OrderAge", orderAgeFilters[3].name)} variant={selectedOrderAgeFilter == "g3" ? "contained" : "outlined"} color="primary"> &gt; 3&nbsp;{renderCountOfOrderAgeFilter(orderAgeFilters[3])}</Button>
                                        </Stack>
                                    </React.Fragment>
                            }
                        </Grid>
                        <Grid item xs={12} sm={3} style={{ padding: "0px" }}>
                            <Typography variant='h6' className='heading' color='primary'>Order Value</Typography>
                            {
                                orderValueTotalCount == 0 && selectedOrderValueFilter == "" ?
                                    <Typography className='' style={{ color: "black", marginTop: 10, marginBottom: 10, fontSize: "16px", fontWeight: "250" }}>No Order Value</Typography>
                                    :
                                    <React.Fragment>
                                        <Stack spacing={2} direction="row" className='btnStack'>
                                            <Button style={{ whiteSpace: "nowrap" }} onClick={() => quickFilterClicked("OrderValue", orderValueFilters[0].name)} variant={selectedOrderValueFilter == orderValueFilters[0].name ? "contained" : "outlined"} color="primary">&lt; $25&nbsp;{renderCountOfOrderValueFilter(orderValueFilters[0])}</Button>
                                            <Button style={{ whiteSpace: "nowrap" }} onClick={() => quickFilterClicked("OrderValue", orderValueFilters[1].name)} variant={selectedOrderValueFilter == orderValueFilters[1].name ? "contained" : "outlined"} color="primary"> $25 -$100&nbsp;{renderCountOfOrderValueFilter(orderValueFilters[1])}</Button>
                                        </Stack>
                                        <Stack spacing={2} direction="row" className='btnStack'>
                                            <Button style={{ whiteSpace: "nowrap" }} onClick={() => quickFilterClicked("OrderValue", orderValueFilters[2].name)} variant={selectedOrderValueFilter == orderValueFilters[2].name ? "contained" : "outlined"} color="primary">$100 -$500&nbsp;{renderCountOfOrderValueFilter(orderValueFilters[2])}</Button>
                                            <Button style={{ whiteSpace: "nowrap" }} onClick={() => quickFilterClicked("OrderValue", orderValueFilters[3].name)} variant={selectedOrderValueFilter == orderValueFilters[3].name ? "contained" : "outlined"} color="primary">&gt; $500&nbsp;{renderCountOfOrderValueFilter(orderValueFilters[3])}</Button>
                                        </Stack>
                                    </React.Fragment>
                            }
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </Box >
    }
    const renderMobileFilters = () => {
        return <Box sx={{ p: 3 }} style={{ textAlign: "left", padding: "5px", paddingLeft: "5px", paddingTop: "10px", marginBottom: 5 }}>
            <React.Fragment>
                <Button className='btn' onClick={() => {
                    setDrawer(true);
                }} style={{ width: "100%" }} startIcon={<FilterAltIcon />} variant="outlined" color="primary">Quick Filters</Button>
                <Drawer
                    anchor={"top"}
                    open={openDrawer}
                    onClose={() => { }}
                    onClick={() => { setDrawer(false) }}
                    style={{ zIndex: 9999999 }}
                >
                    {renderFilters()}
                </Drawer>
            </React.Fragment>
        </Box>
    }
    const ExpandedComponent = (data: any) => {
        return <ExpandedRow data={data.data}></ExpandedRow>
    };
    const rowClicked = (row: any) => {
        //
        console.log(row, "rowClicked");
        let data = [...orderRows];
        if (data && data.length > 0) {
            data.forEach((item) => {
                if (item._id == row._id) {
                    item._source.Shipment.defaultExpanded = !item._source.Shipment.defaultExpanded
                }
            })
        }
        dispatch(allActions.fulfillOrdersActions.changeOrderRows(data));
    }
    const conditionalRowStyles = [

        // You can also pass a callback to style for additional customization
        {
            when: (row: any) => row._id,
            style: (row: any) => ({
                cursor: 'pointer',
            }),
        }
    ];

    const handleSort = (column: any, sortOrder: any) => {
        if (column.sortfield) {
            setSort({ sortField: column.sortfield, sortOrder: sortOrder });
        }
    };

    const renderWebDataTable = () => {
        if (currentTab == 1) {
            return <div className='dataTable'>
                <PickDataTable
                    selectedStores={props.store}
                    selectAll={selectAll}
                    selectAllCheckBox={selectAllCheckBox}
                    setSelectAllCheckBox={setSelectAllCheckBox}
                    intermediate={intermediate}
                    getDate={getDate}
                    WebGridButton={WebGridButton}
                    lineType={lineType}
                    ordersToPrint={ordersToPrint}
                    setordersToPrint={setordersToPrint}
                    handleCheckboxChange={handleCheckboxChange}
                    handleSort={handleSort}
                    defaultSortFieldId={sort.sortField}
                    defaultSortAsc={sort.sortOrder == "asc" ? true : false}>
                </PickDataTable>
            </div>
        }
        else {
            return <div className='dataTable'>
                <DataTable
                    className='dataTable'
                    title=""
                    data={orderRows}
                    columns={getColumns()}
                    onRowDoubleClicked={rowClicked}
                    expandableRows
                    conditionalRowStyles={conditionalRowStyles}
                    highlightOnHover
                    // expandableRowExpanded={row => row._source.Shipment.defaultExpanded}
                    expandableRowsComponent={ExpandedComponent}
                    sortServer
                    onSort={handleSort}
                    defaultSortFieldId={sort.sortField}
                    defaultSortAsc={sort.sortOrder == "asc" ? true : false}
                />
            </div>
        }
    }
    const PackButton = (status: any) => {
        if (status == ShipmentStatusConstant.ShipmentPacked) {
            return "Ship";
        }
        else {
            return "Pack";
        }
    }
    const PackIcon = (status: any) => {
        if (status == ShipmentStatusConstant.ShipmentPacked) {
            return <LocalShippingIcon className='fTabIcons' />;
        }
        else {
            return <ApartmentIcon className='fTabIcons' />;
        }
    }
    const WebGridButton = (row: any) => {
        if (row._source.Shipment.Status) {
            switch (row._source.Shipment.Status) {
                case ShipmentStatusConstant.ReadyForPick:
                    return <React.Fragment>
                        <Button startIcon={<ShoppingCart className='fTabIcons' />} variant="contained" color="primary" onClick={() => { loadOrder(row._source.Shipment.ShipmentKey, row._source.Shipment.Status, row._source.Shipment.OrderNo, row._source.Shipment.ShipNode) }}>Pick</Button>
                    </React.Fragment >
                case ShipmentStatusConstant.ReadyForPack: case ShipmentStatusConstant.ShipmentBeingPacked: case ShipmentStatusConstant.ShipmentPacked:
                    return <React.Fragment>
                        <Button startIcon={PackIcon(row._source.Shipment.Status)} variant="contained" color="primary" onClick={() => { loadOrder(row._source.Shipment.ShipmentKey, row._source.Shipment.Status, row._source.Shipment.OrderNo, row._source.Shipment.ShipNode) }}>{PackButton(row._source.Shipment.Status)}</Button>
                    </React.Fragment>
                case ShipmentStatusConstant.ReadyForCustomerPickup:
                    return <React.Fragment>
                        <Button startIcon={<DirectionsCar className='fTabIcons' />} variant="contained" color="primary" onClick={() => { loadOrder(row._source.Shipment.ShipmentKey, row._source.Shipment.Status, row._source.Shipment.OrderNo, row._source.Shipment.ExtnPickupStoreID ? row._source.Shipment.ExtnPickupStoreID : 0) }}>Customer Pickup</Button>
                    </React.Fragment>
            }
        }
    }
    const loadOrder = async (shipmentKey: any, type: any, orderNo?: any, storeId: any = 0) => {
        showActionLoader();
        let detailStatus = true;
        await Utils.checkOrderDetailStatus({
            shipmentKey: shipmentKey,
            status: type
        }).then((res: any) => {
            if (res?.success) {
                detailStatus = res.data;
            }
        }).catch((err: any) => {
            console.log("Order Detail Status Error", err);
        })

        switch (type) {
            case ShipmentStatusConstant.ReadyForPick:
                if (detailStatus) {
                    hideActionLoader();
                    if (storeId != 0) {
                        lineType == 0 ? navigate("/PickOrder?orderno=" + orderNo + "&shipmentkey=" + shipmentKey + "&store=" + storeId + "") : navigate("/BulkPickOrder?shipmentkey=" + shipmentKey + "");
                    }
                    else {
                        lineType == 0 ? navigate("/PickOrder?orderno=" + orderNo + "&shipmentkey=" + shipmentKey + "") : navigate("/BulkPickOrder?shipmentkey=" + shipmentKey + "");
                    }
                }
                else {
                    hideActionLoader();
                    openInfoPopup();
                }
                break;

            case ShipmentStatusConstant.ReadyForPack: case ShipmentStatusConstant.ShipmentBeingPacked: case ShipmentStatusConstant.ShipmentPacked:
                if (detailStatus) {
                    hideActionLoader();
                    if (storeId != 0) {
                        navigate("/PackOrder?orderno=" + orderNo + "&shipmentkey=" + shipmentKey + "&store=" + storeId);
                    }
                    else {
                        navigate("/PackOrder?orderno=" + orderNo + "&shipmentkey=" + shipmentKey + "");
                    }
                }
                else {
                    hideActionLoader();
                    openInfoPopup();
                }
                break;
            case ShipmentStatusConstant.ReadyForCustomerPickup:
                if (detailStatus) {
                    hideActionLoader();
                    if (storeId != 0) {
                        navigate("/CustomerPickup?orderno=" + orderNo + "&shipmentkey=" + shipmentKey + "" + "&store=" + storeId + "");
                    }
                    else {
                        navigate("/CustomerPickup?orderno=" + orderNo + "&shipmentkey=" + shipmentKey + "");
                    }

                }
                else {
                    hideActionLoader();
                    openInfoPopup();
                }
                break;
        }
    }

    const stepperButtonIcon = (type: any) => {
        switch (type) {
            case ShipmentStatusConstant.ReadyForPick:
                return <ShoppingCart style={{ fontSize: 20 }} />;
            case ShipmentStatusConstant.ReadyForPack: case ShipmentStatusConstant.ShipmentBeingPacked:
                return <ApartmentIcon style={{ fontSize: 20 }} />;
            case ShipmentStatusConstant.ReadyForCustomerPickup:
                return <DirectionsCar style={{ fontSize: 20 }} />;
            case ShipmentStatusConstant.ShipmentPacked:
                return <LocalShippingIcon className='fTabIcons' />;
        }
    }
    const stepstoShow = (type: any) => {
        switch (type) {
            case ShipmentStatusConstant.ReadyForPick:
                return "Pick";
            case ShipmentStatusConstant.ReadyForPack: case ShipmentStatusConstant.ShipmentBeingPacked:
                return "Pack";
            case ShipmentStatusConstant.ShipmentPacked:
                return "Ship";
            case ShipmentStatusConstant.ReadyForCustomerPickup:
                return "Pickup";
        }
    }
    const monileGridButton = (status: any, shipmentKey: any, orderNo: any, storeId: any) => {
        return <React.Fragment>
            <Grid item xs={2} sm={2} onClick={() => { loadOrder(shipmentKey, status, orderNo, storeId) }} style={{ display: "flex", justifyContent: "left", padding: 0, alignItems: "center", marginTop: 0, background: Constants.Colors.red, color: Constants.Colors.white, borderLeft: "2px solid #D93A2F" }}>
                {stepperButtonIcon(status)}
                <Typography style={{ fontSize: "12px" }}>
                    {stepstoShow(status)}
                </Typography>
            </Grid>
        </React.Fragment>
    }
    const renderMobileData = () => {
        //MobilePickDataTable
        console.log(lineType, "lineType");
        if (lineType == 1) {
            return <MobilePickDataTable data={orderRows} steps={steps} detailDrawerOpen={detailDrawerOpen} monileGridButton={monileGridButton} value={currentTab}></MobilePickDataTable>
        }
        else {
            return <MobileDataTable data={orderRows} steps={steps} detailDrawerOpen={detailDrawerOpen} monileGridButton={monileGridButton} value={currentTab}></MobileDataTable>
        }
    }
    const renderPaginationAndSearch = () => {
        if (!Utils.isMobile) {
            return <React.Fragment >
                {pagination()}
            </React.Fragment>
        }
        else {
            return <React.Fragment >
            </React.Fragment>
        }
    }
    const pagination = () => {
        return < div id='paginationWebId' className="paginationWeb" style={{ background: "#D93A2F", display: "flex" }
        }>
            <div>
                <Stack spacing={1} style={{}}>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                        component="div"
                        count={totalRecords}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Stack>
            </div>
        </div >
    }
    return (
        <div>
            <NotificationPopup {...underconstruction}></NotificationPopup>
            <NotificationPopup {...Error}>
            </NotificationPopup>
            <OrderDetailNotificationPopup {...Info}>
            </OrderDetailNotificationPopup>
            <PickSlipGroupBy></PickSlipGroupBy>
            {!Utils.isMobile ? <SiteBreadCrumbs siteBreadCrumbs={Constants.SiteBreadCrumbs.FulfillOrders}></SiteBreadCrumbs> : <></>}
            <div style={{ marginTop: 0 }} className={Utils.isMobile ? "innerContianerMobile" : "innerContianer"}>
                <div id='tabFilterContaoner'>
                    {renderOrderTypeTabs()}
                    {!Utils.isMobile ? renderFilters() : renderMobileFilters()}
                </div>
                <TabPanel value={currentTab} index={currentTab} dir={theme.direction} >
                    {
                        currentTab == 1 ?
                            <Paper style={{ paddingLeft: 3, paddingRight: 3, marginBottom: 1, marginTop: Utils.isMobile ? 8 : 0 }}>
                                <Grid container>
                                    <Grid item xs={6} sm={6} style={{ display: "flex", justifyContent: "left" }}>

                                    </Grid>
                                    <Grid item xs={6} sm={6} style={{}}>
                                        {Utils.disableUserAction(props.store) && <React.Fragment>
                                            {
                                                !Utils.isMobile ?
                                                    <Box display="flex" justifyContent="flex-end" style={{ float: "right" }}>
                                                        <Button startIcon={<PrintIcon />} style={{ marginTop: 5, marginBottom: 4 }} disabled={ordersToPrint.length > 0 ? false : true} className={ordersToPrint.length > 0 ? 'btn' : "btn disabledButton"} variant="outlined" color="primary" onClick={() => openPdfiframe(true)} >
                                                            Pick Slip
                                                        </Button>
                                                        &nbsp;&nbsp;
                                                        <Button startIcon={<PrintIcon />} style={{ marginTop: 5, marginBottom: 4 }} disabled={ordersToPrint.length > 0 ? false : true} className={ordersToPrint.length > 0 ? 'btn' : "btn disabledButton"} variant="outlined" color="primary" onClick={() => openPdfiframe(false)} >
                                                            Shelve Pick List
                                                        </Button>
                                                    </Box> : ""
                                            }
                                        </React.Fragment>}
                                    </Grid>
                                </Grid>
                            </Paper>
                            :
                            <></>
                    }
                    {renderPaginationAndSearch()}
                    {!Utils.isMobile ? renderWebDataTable() : renderMobileData()}
                </TabPanel>
                <Drawer
                    anchor={"bottom"}
                    open={openDetailDrawer}
                    onClose={() => { }}
                    onClick={() => { setOpenDetailDrawer(false) }}
                    style={{ zIndex: 9999999 }}
                >
                    {openDetailDrawer == true ?
                        renderDetailDrawer()
                        :
                        <></>
                    }
                </Drawer>
            </div>
            {pickSlipGroupByModal.showPdf || showPdf ?
                <div>
                    <MultiPickSlip store={props.store} isPickSlip={showPdf} ordersToPrint={ordersToPrint} setordersToPrint={setordersToPrint}></MultiPickSlip>
                    <PdfIframe open={pickSlipGroupByModal.showPdf || showPdf} close={closePdfiframe} ></PdfIframe>
                </div>
                :
                <></>}

        </div>
    );
    interface TabPanelProps {
        children?: React.ReactNode;
        dir?: string;
        index: number;
        value: number;
    }
    function TabPanel(props: TabPanelProps) {
        const { children, value, index, ...other } = props;
        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`full-width-tabpanel-${index}`}
                aria-labelledby={`full-width-tab-${index}`}
                {...other}
                style={{ marginTop: Utils.isMobile ? "-14px" : "" }}
            >
                <Box sx={{ p: 3 }} style={{ padding: "5px" }} id='containerDataTable'>
                    <Typography component="div">{children}</Typography>
                </Box>
            </div>
        );
    }
}
export default FulfillOrders;
import React from 'react';
import './Drawer.scss';
import { useNavigate } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import { Avatar } from '@material-ui/core';
import Constants from "../../Common/Constants"
import { useSelector } from 'react-redux';
const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, .03)',
    },
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '10px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
}))(MuiAccordionDetails);

export default function DrawerRow(props: any): any {
  const navigate = useNavigate();
  const panel = 'currentPanel';
  const [expanded, setExpanded] = React.useState<string | false>();
  const kcdcQuantity = useSelector((state: any) => state.ActionLoaderReducersState.kcdcTotalQuantity);
  const handleClick = () => {
    props.setSelectedOption(props.data.Header);
    if (props.data.Data != "") {
      setExpanded(expanded ? false : panel);
    } else {
      changeRoute(props.data.Route, null);
    }
  }
  const changeRoute = (route: string, id: string | null) => {
    if (id !== null) {
      navigate(route + ":" + id);
    } else {
      navigate(route);
    }
  }
  const isSelected = props.selected === props.data.Header;
  return (
    <div
      onClick={() => { handleClick() }}
      className={isSelected ? 'DrawerLine SelectedDrawerLine hvr-bounce-to-right' : 'DrawerLine hvr-bounce-to-right'}
      style={{display:'flex', justifyContent:"space-between"}}
    >
      {props.data.Header}
      {props.data.Route==Constants.RouteLabels.KCDCReturns && kcdcQuantity>0 &&
          <Avatar
          style={{ backgroundColor: Constants.Colors.red, width: 25, height: 25, fontSize: "0.95rem" }}
          >
          {kcdcQuantity}
        </Avatar>
      }
    </div>
  );
}
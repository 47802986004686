import React from "react";
import {
    Bar,
    XAxis,
    YAxis,
    Tooltip,
    BarChart,
    Legend,
    ResponsiveContainer
} from "recharts";

const StackGraph = (props: any) => {
    let data = []
    const { pick, pack, ship, customerPickup } = props;

    const graphValuesObj = {
        Pick: pick ? pick : 0,
        Pack: pack ? pack : 0,
        Ship: ship ? ship : 0,
        CustomerPickup: customerPickup ? customerPickup : 0,
    }

    data.push(graphValuesObj)

    const getLegentName = (value: string) => {
        switch (value) {
            case 'Pick':
                return " Pick - " + (pick ? pick : 0)
            case 'Pack':
                return ' Pack - ' + (pack ? pack : 0)
            case 'Ship':
                return ' Ship - ' + (ship ? ship : 0)
            case 'CustomerPickup':
                return ' Customer Pickup - ' + (customerPickup ? customerPickup : 0)
        }
    }
    // Custom legend Styling
    const renderLegend = (props: any) => {
        const { payload } = props;
        if (payload.length) {
            return (
                <div>
                    {
                        payload.map((entry: any, index: any) => (
                            <span key={`item-${index}`} style={{ marginLeft: '5px' }}><div style={{ display: 'inline-block', width: '12px', height: '12px', backgroundColor: entry.color }}></div>{getLegentName(entry.value)}</span>
                        ))
                    }
                </div>
            );
        }

        return null
    }
    // Custom Round Left Corners
    const LeftRoundCorner = (props: any) => {
        const { x, y, width, height, fill, barRadius } = props;

        const radius = Math.min(barRadius, Math.abs(width), Math.abs(height));
        const barPath = `M${x + radius},${y}
                    H${x + width}
                    V${y + height}
                    H${x + radius}
                    A${radius},${radius} 0 0 1 ${x},${y + radius}
                    V${y + height - radius}
                    A${radius},${radius} 0 0 1 ${x + radius},${y}
                    Z`;

        return <path d={barPath} fill={fill} />;
    };

    // Custom Round Right Corners
    const RightRoundCorner = (props: any) => {
        const { x, y, width, height, fill, barRadius } = props;

        const radius = Math.min(barRadius, Math.abs(width), Math.abs(height));
        const barPath = `M${x},${y}
                          H${x + width - radius}
                          A${radius},${radius} 0 0 1 ${x + width},${y + radius}
                          V${y + height - radius}
                          A${radius},${radius} 0 0 1 ${x + width - radius},${y + height}
                          H${x}
                          Z`;

        return <path d={barPath} fill={fill} />;
    };

    const getBarColour = (value: any) => {
        switch (value) {
            case "Pick":
                return "#FF9800";
            case "Pack":
                return "#4CAF50";
            case "Ship":
                return "#673AB7";
            default:
                return "#D93A2F";
        }
    };

    const getCustomBarsWithRoundCorners = () => {
        let capitalDataKeys = {
            Pick: pick,
            Pack: pack,
            Ship: ship
        }
        const result = Object.fromEntries(Object
            .entries(capitalDataKeys)
            .filter(([k, v]) => v !== 0)
        );

        if (Object.keys(result).length === 1) {
            return <Bar radius={5} stackId="a" dataKey={Object.keys(result)[0]} barSize={25} fill={getBarColour(Object.keys(result)[0])} />
        }
        if (Object.keys(result).length === 2) {
            return <>
                <Bar shape={<LeftRoundCorner barRadius={5} />} stackId="a" dataKey={Object.keys(result)[0]} barSize={25} fill={getBarColour(Object.keys(result)[0])} />
                <Bar shape={<RightRoundCorner barRadius={5} />} stackId="a" dataKey={Object.keys(result)[1]} fill={getBarColour(Object.keys(result)[1])} />
            </>
        }
        return <>
            <Bar shape={<LeftRoundCorner barRadius={5} />} stackId="a" dataKey={Object.keys(result)[0]} barSize={25} fill={getBarColour(Object.keys(result)[0])} />
            <Bar stackId="a" dataKey={Object.keys(result)[1]} barSize={25} fill={getBarColour(Object.keys(result)[1])} />
            <Bar shape={<RightRoundCorner barRadius={5} />} stackId="a" dataKey={Object.keys(result)[2]} barSize={25} fill={getBarColour(Object.keys(result)[2])} />
        </>
    }
    return (
        <>
            {(pick || pack || ship || customerPickup) &&
                <ResponsiveContainer>
                    < BarChart
                        layout="vertical"
                        width={280}
                        height={props.height ? props.height : 50}
                        data={data}
                    >
                        <XAxis type="number" hide />
                        <YAxis dataKey="count" type="category" scale="band" hide />
                        {/* <Tooltip /> */}
                        <Legend content={renderLegend} />

                        {getCustomBarsWithRoundCorners()}

                    </BarChart>
                </ResponsiveContainer>
            }
        </>
    );
}

export default StackGraph;







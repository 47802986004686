import React, { useEffect, useState } from 'react';
import './Routes.scss';
import { createStyles, makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import { Button, Chip, FormHelperText, Grid } from '@material-ui/core';
import Utils from '../Common/Utils';
import Box from '@material-ui/core/Box';
import { Card, CardContent, Divider, InputBase, TextField, Theme, Dialog, DialogContent, DialogActions, DialogTitle } from '@mui/material';
import Stack from '@mui/material/Stack';
import DataTable from 'react-data-table-component';
import { IconButton, Paper, Typography, Tooltip } from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import SiteBreadCrumbs from '../Subcomponent/Shared/SiteBreadCrumbs'
import { ReceiptLongIcon, FilterAltIcon, DocumentScannerIcon, SearchIcon, CloseIcon, DoneIcon, InfoIcon } from "../Subcomponent/Shared/SharedIcons"
import MobileBarCodeScannerTrackingNumberScandit from '../Subcomponent/ReceiveOrders/MobileBarCodeScannerTrackingNumberScandit';
import { NotificationPopup } from "../Subcomponent/Shared/NotificationPopup";
import { INotificationPopupSettings, IButton } from "../Models/INotificationPopupSettings";
import DummyDataManager from "../Managers/DummyDataManager";
import allActions from '../Store/Actions/AllActions';
import Constants from '../Common/Constants';
import Drawer from '@mui/material/Drawer';
import { ITecAudits } from "../Models/ITecAudit"
import OrderManager from '../Managers/Orders.Manager';
import StoreManager from "../Managers/Store.manager";
import AuditLogsManager from '../Managers/Audit.Logs.Manager';
import ReactJson from 'react-json-view'
import IdentityManager from '../Managers/Identity.manager';
var moment = require('moment');
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        chip: {
            fontSize: '15px',
            margin: theme.spacing(0.4),
            borderRadius: '2px !important'
        },
    }),
);
const TechAudits = (props: any) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const auditLines = useSelector((state: any) => state.TecAuditsReducersState.auditLines);
    const tags = useSelector((state: any) => state.TecAuditsReducersState.tags);
    const [selectedTag, setSelectedTag] = useState({
        key: "All",
        label: "All"
    }); //useSelector((state: any) => state.TecAuditsReducersState.selectedTag);
    const logTypes = ["All", "Info", "Error"];
    const [selectedLogType, setSelectedLogType] = useState("All");
    const [filter, setFilter] = useState({
        tag: "",
        logType: "",
        searchTerm: ""
    });
    const [searchTerm, setSearchTerm] = useState("");
    const [errorMsg, setErrorMsg] = useState("");
    const [openError, setOpenError] = useState(false);
    const [searchTracking, setSearchTracking] = useState("")
    console.log(selectedTag, "selectedTag");
    const [data, setData] = useState([] as any);
    const [totalRecords, setTotalRecords] = React.useState(0);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(50);
    const [openDetail, setOpenDetail] = React.useState(false);
    const [detail, setDetail] = React.useState({} as any);
    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(+(parseInt(event.target.value)));
        setPage(0);
    };
    const showActionLoader = () => {
        dispatch(allActions.actionLoaderActions.showLoader());
    }
    const hideActionLoader = () => {
        dispatch(allActions.actionLoaderActions.hideLoader());
    }
    var startProductBarPos = -1;
    window.onscroll = function () {
        var bar = document.getElementById('paginationWebId') as any;
        var dataTable = document.getElementById('containerDataTable') as any;
        if (bar && dataTable) {
            var dataTableWidth = dataTable.offsetWidth;
            if (startProductBarPos < 0) startProductBarPos = findPosY(bar);
            if (window.pageYOffset > startProductBarPos) {
                bar.style.width = (dataTableWidth - (0.8 * dataTableWidth / 100)) + "px";
                bar.classList.add("fixedPagination");
                bar.style.marginTop = Utils.getWebbarheight + "px";
            } else {
                bar.classList.remove("fixedPagination");
                bar.style.width = "";
                bar.style.marginTop = "";
            }
        }
        // Mobile View Fix the Tabs and FILTER
        if (Utils.isMobile) {
            console.log("scrolled");
            bar = document.getElementById('tabFilterContaoner') as any;
            var mblBar = document.getElementById('mblBar') as any;
            if (bar) {
                if (startProductBarPos < 0) startProductBarPos = findPosY(mblBar);
                if (window.pageYOffset > startProductBarPos) {
                    bar.classList.add("scrolledTopFix");
                } else {
                    bar.classList.remove("scrolledTopFix");
                }
            }
        }
    };
    function findPosY(obj: any) {
        var curtop = 0;
        if (obj && typeof (obj.offsetParent) != 'undefined' && obj.offsetParent) {
            while (obj.offsetParent) {
                curtop += obj.offsetTop;
                obj = obj.offsetParent;
            }
            curtop += obj.offsetTop;
        }
        else if (obj && obj.y)
            curtop += obj.y;
        return curtop;
    }
    const onSearchChange = (event: any, value: any) => {
        setSearchTerm(value);
    }
    const keyPress = (e: any) => {
        if (e.keyCode == 13) {
            let val = e.target.value;
            // put the login here
            let fil = { ...filter };
            fil.searchTerm = val;
            setFilter(fil);
            loadData(fil);

        }
    }
    const getEmployeeID = (row: any) => {
        let result = ""
        if (row._source.userId == "") {
            result = row._source.upn
        }
        else {
            result = row._source.userId
        }
        return result
    }
    const getDate = (row: any) => {
        let date: string = ""
        if (row._source.createdDate) {
            date = moment.utc(row._source.createdDate).tz("America/Los_Angeles").format(Constants.DateFormat.Format);
        }
        return date
    }
    const showDetail = (id: any) => {
        let detail = auditLines.filter((order: any) => {
            return order._id == id
        })[0]
        setDetail(detail);
        setOpenDetail(true);
    }
    const logType = (row: any) => {
        return (
            <b>
                <ReceiptLongIcon color="info" style={{ cursor: "pointer" }} onClick={() => showDetail(row._id)} />
                {
                    row._source.logSub == "info" ?
                        <DoneIcon color="success" />
                        :
                        <CloseIcon color='error' />
                }
                {row._source.logSub}
            </b>
        );
    }
    const renderParameters = (row: any) => {
        return (
            row._source.orderno
        );
    }
    const getColumns = () => {
        const columns = [
            {
                name: 'Log Type',
                cell: (row: any) => logType(row),
                sortable: true,
                width: "120px"
            },
            {
                name: 'Action',
                selector: (row: any) => row._source.tag,
                sortable: true,
                maxWidth: "200px"
            },
            {
                name: 'Order No.',
                selector: (row: any) => renderParameters(row),
                sortable: true,
                maxWidth: "200px"
            },
            {
                name: 'Store',
                selector: (row: any) => row._source.store,
                sortable: true,
                width: "90px"
            },
            {
                name: 'Employee Id',
                selector: (row: any) => getEmployeeID(row),
                sortable: true,
                maxWidth: "150px"
            },
            {
                name: 'Created Date',
                selector: (row: any) => getDate(row),
                sortable: true,
                maxWidth: "200px"
            },
            {
                name: 'Message',
                cell: (row: any) => <Tooltip title={row._source.message}><p style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{row._source.message}</p></Tooltip>,
                sortable: true,
                maxWidth: Utils.getMessageWidthTecAudits() + "px",
            }
        ];
        return columns;
    }
    const renderWebDataTable = () => {
        return <div className='dataTable' id='containerDataTable'>
            <DataTable
                className='dataTable'
                title=""
                data={auditLines}
                columns={getColumns()}
            />
        </div>
    }
    const renderMobileData = () => {
        return <div style={{ marginTop: 10 }}>
            {auditLines.map((row: any) => {
                return (
                    <Card style={{ marginTop: 4, marginBottom: 4 }}>
                        <CardContent style={{ paddingTop: 0, paddingBottom: 0, paddingLeft: 0, paddingRight: 0 }}>
                            <Grid container style={{ margin: "0px" }} >
                                <Grid xs={3} sm={3} style={{ paddingTop: 4, paddingLeft: 0, color: Constants.Colors.red, background: Constants.Colors.smokeGrey, display: "flex", alignItems: "center", borderRight: "2px solid #C0C0C0" }}>
                                    <Typography style={{ fontSize: "11px", wordBreak: "break-all", fontWeight: "425", marginLeft: 3 }}>
                                        {row._source?.Shipment?.Containers?.Container[0]?.TrackingNo}
                                    </Typography>
                                </Grid>
                                <Grid item xs={7} sm={7} style={{ paddingTop: 3, paddingLeft: 3, textAlign: "left", verticalAlign: "center" }}>
                                    <Typography noWrap style={{ color: Constants.Colors.red, fontSize: "12px", fontWeight: "425" }}>{row._source?.Shipment?.ShipmentLines?.ShipmentLine[0]?.ItemDesc}</Typography>
                                    <Typography style={{ fontSize: "11px" }}>
                                        <span className="fulfillmentMobileLable">Order #: {row._source?.Shipment?.OrderNo}</span>
                                    </Typography>
                                    <Typography style={{ fontSize: "11px" }}>
                                        <span className="fulfillmentMobileLable">Status: {row._source?.Shipment?.StatusName}</span>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                );
            })
            }
        </div >
    }
    const closeErrorPopup = () => {
        setOpenError(false)
    }
    const openErrorPopup = () => {
        setOpenError(true)
    }
    let errorPopupButtons: IButton[] = [
        {
            text: "Close",
            icon: <CloseIcon />,
            action: closeErrorPopup,
            color: Constants.Colors.red,
        },
    ];
    let Error: INotificationPopupSettings = {
        open: openError,
        type: Constants.NotificationPopupType.Error,
        title: "Error",
        msg: errorMsg,
        draggable: false,
        handleClose: closeErrorPopup,
        actions: errorPopupButtons
    }

    const changeSearchTracking = (e: any) => {
        console.log(e.target.value)
        setSearchTracking(e.target.value)
    }
    const renderPaginationAndSearch = () => {
        return <React.Fragment >
            {pagination()}
        </React.Fragment>
    }
    const pagination = () => {
        return < div id='paginationWebId' className="paginationReceiveOrdersWeb" style={{ background: "#D93A2F" }}>
            <div style={{}}>
                <Stack spacing={1} style={{ paddingTop: 7 }}>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                        component="div"
                        count={totalRecords}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}

                    />
                </Stack>
            </div>
        </div >
    }
    // load tech audits tags
    const renderTags = () => {
        return <React.Fragment>
            <Paper className='AuditFiltersContainer'>
                <div className='chipFilters'>
                    <Grid container>
                        <Grid item xs={6} sm={6} style={{ minHeight: "100px", borderRight: "1px solid #eee" }}>
                            <div>
                                {tags.map((data: any) =>
                                    <Chip
                                        clickable
                                        key={data.key}
                                        label={data.value}
                                        color="primary"
                                        className={classes.chip}
                                        onClick={() => {
                                            setSelectedTag(data);
                                            let fil = { ...filter };
                                            fil.tag = data.key == "All" ? "" : data.key;
                                            setFilter(fil);
                                            // call here the generic method to send call to back end and pass the fil
                                            loadData(fil);


                                        }}
                                        variant={selectedTag.key === data.key ? 'default' : 'outlined'}
                                    />
                                )}
                            </div>
                        </Grid>
                        <Grid item xs={3} sm={3} style={{ minHeight: "100px", borderRight: "1px solid #eee" }}>
                            <div>
                                {logTypes.map((data: any) =>
                                    <Chip
                                        clickable
                                        key={data}
                                        label={data}
                                        color="primary"
                                        className={classes.chip}
                                        onClick={() => {
                                            setSelectedLogType(data);
                                            let fil = { ...filter };
                                            fil.logType = data == "All" ? "" : data.toLowerCase();
                                            setFilter(fil);
                                            // call here the generic method to send call to back end and pass the fil
                                            loadData(fil);

                                        }}
                                        variant={selectedLogType === data ? 'default' : 'outlined'}
                                    />
                                )}
                            </div>
                        </Grid>
                        <Grid item xs={3} sm={3}>
                            <TextField
                                style={{ width: "80%" }}
                                id="outlined-multiline-flexible"
                                placeholder="Search"
                                variant={"outlined"}
                                InputLabelProps={{ shrink: false }}
                                onChange={(event) => {
                                    onSearchChange(event, event.target.value);
                                }}
                                onKeyDown={keyPress}
                                value={searchTerm}
                                helperText="Type keyword and press Enter to search"
                            />
                        </Grid>
                    </Grid>
                </div>
            </Paper>
        </React.Fragment>

    }

    const loadData = async (filter: any) => {
        let storeId = IdentityManager.getStoreId(props.store)
        if (storeId == undefined) {
            storeId = ""
        }
        showActionLoader()
        let resultResponse = {} as any;
        try {
            let from = page * rowsPerPage;
            let size = rowsPerPage;
            let auditQuery: ITecAudits = {
                store: storeId,
                pFrom: from,
                pSize: size,
                searchTerm: filter.searchTerm,
                tag: filter.tag,
                logType: filter.logType
            }
            resultResponse = await AuditLogsManager.getTecAudits(auditQuery);
            if (resultResponse.success) {
                let result = resultResponse.data
                let total = result.hits.total.value;
                let dataRows = result.hits.hits;
                if (dataRows.length > 0) {
                    dispatch(allActions.tecAuditsActions.changeTecLines(dataRows));
                    setTotalRecords(total)
                }
                else {
                    setTotalRecords(0)
                    dispatch(allActions.tecAuditsActions.changeTecLines([]));
                }
            }
            else {
                hideActionLoader()
                let message = "Some thing went wrong please contact the IT Help Desk for assistance"
                console.log(resultResponse.msg)
                setErrorMsg(message);
                openErrorPopup()
            }
        }
        catch (e) {
            hideActionLoader()
            let message = "Some thing went wrong please contact the IT Help Desk for assistance"
            console.log(resultResponse.msg)
            setErrorMsg(message);
            openErrorPopup()
        }

        hideActionLoader()
    }

    const loadTechAuditsTags = async () => {

        AuditLogsManager.getTechAuditsTags().then((data: any[]) => {
            console.log(data, "AuditLogsManager");
            dispatch(allActions.tecAuditsActions.changeTagsList(data));

        })

    }

    React.useEffect(() => {
        async function Init() {
            loadTechAuditsTags();
            loadData(filter);
        }
        Init();
    }, [props.store, page, rowsPerPage]);
    return (
        <div className={Utils.isMobile ? "innerContianerMobile" : "innerContianer"}>
            <NotificationPopup {...Error}></NotificationPopup>
            {!Utils.isMobile ? <SiteBreadCrumbs siteBreadCrumbs={Constants.SiteBreadCrumbs.TechAudits} ></SiteBreadCrumbs> : <></>}
            {renderTags()}
            {renderPaginationAndSearch()}
            {renderWebDataTable()}
            {
                openDetail == true ?
                    <Dialog style={{ zIndex: "99999" }} disableEnforceFocus fullWidth={true} maxWidth="lg" open={true} onClose={() => setOpenDetail(false)}>
                        <DialogTitle id="draggable-dialog-title" style={{ background: Constants.Colors.blue }}>
                            <Grid container direction="row" alignItems="center" style={{ height: 35 }}>
                                <Grid item>
                                    <InfoIcon style={{ color: "white", fontSize: 30 }} />
                                </Grid>
                                <Grid item style={{ paddingBottom: 7, paddingLeft: 7 }}>
                                    <p style={{ color: "white", fontSize: Utils.isMobile ? 16 : 22, margin: 0 }}>Detail</p>
                                </Grid>
                            </Grid>
                            <IconButton
                                aria-label="close"
                                onClick={() => setOpenDetail(false)}
                                sx={{
                                    position: 'absolute',
                                    right: 0,
                                    top: 0,
                                    color: "white",
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent className={`itemDialogBorder dialogMinHeight`} style={{ textAlign: "left", padding: 30 }}>

                            <table style={{ width: "100%" }}>
                                <tbody>
                                    <tr className='techAuditDetailTableRowHeight'>
                                        <td className='techAuditDetailfirstTd'>
                                            <Typography style={{ fontSize: 18 }}><b>Log Type:</b>


                                            </Typography>
                                        </td>
                                        <td className='techAuditDetailsecondTd'>
                                            {
                                                detail._source.logSub == "info" ?
                                                    <DoneIcon color="success" />
                                                    :
                                                    <CloseIcon color='error' />
                                            }
                                            {detail._source.logSub}
                                        </td>
                                    </tr>

                                    <tr className='techAuditDetailTableRowHeight'>
                                        <td className='techAuditDetailfirstTd'>
                                            <Typography style={{ fontSize: 18 }}><b>Shipment Key:</b>


                                            </Typography>
                                        </td>
                                        <td className='techAuditDetailsecondTd'>
                                            {detail._source.shipmentkey}
                                        </td>
                                    </tr>

                                    <tr className='techAuditDetailTableRowHeight'>
                                        <td className='techAuditDetailfirstTd'>
                                            <Typography style={{ fontSize: 18 }}><b>Order No:</b>


                                            </Typography>
                                        </td>
                                        <td className='techAuditDetailsecondTd'>
                                            {detail._source.orderno}
                                        </td>
                                    </tr>

                                    <tr className='techAuditDetailTableRowHeight'>
                                        <td className='techAuditDetailfirstTd'>
                                            <Typography style={{ fontSize: 18 }}><b>User Id:</b>


                                            </Typography>
                                        </td>
                                        <td className='techAuditDetailsecondTd'>

                                            {detail._source.userId && detail._source.userId.length > 0 ? detail._source.userId : detail._source.upn}


                                        </td>
                                    </tr>

                                    <tr className='techAuditDetailTableRowHeight'>
                                        <td className='techAuditDetailfirstTd'>
                                            <Typography style={{ fontSize: 18 }}><b>Store:</b>


                                            </Typography>
                                        </td>
                                        <td className='techAuditDetailsecondTd'>
                                            {detail._source.store}
                                        </td>
                                    </tr>

                                    <tr className='techAuditDetailTableRowHeight'>
                                        <td className='techAuditDetailfirstTd'>
                                            <Typography style={{ fontSize: 18 }}><b>Message:</b>


                                            </Typography>
                                        </td>
                                        <td className='techAuditDetailsecondTd'>
                                            {detail._source.message && detail._source.message.length > 0 ? detail._source.message : ""}
                                        </td>
                                    </tr>

                                    <tr className='techAuditDetailTableRowHeight'>
                                        <td className='techAuditDetailfirstTd'>
                                            <Typography style={{ fontSize: 18 }}><b>Created Date:</b>

                                            </Typography>
                                        </td>
                                        <td className='techAuditDetailsecondTd'>
                                            {Utils.convertElasticDateToPst(detail._source.createdDate)}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <Typography style={{ fontSize: 18 }}><b>Payload</b></Typography>
                            <br></br>
                            <ReactJson src={detail._source.payLoad?.payLoad && detail._source.payLoad.payLoad.length > 0 ? JSON.parse(detail._source.payLoad.payLoad) : {}} />

                            {detail._source?.responseOMS && <React.Fragment>
                            <Typography style={{ fontSize: 18 }}><b>Reponse</b></Typography>
                            <br></br>
                            <ReactJson src={detail._source.responseOMS?.length>0 ? JSON.parse(detail._source.responseOMS) : {}} />
                            </React.Fragment>}

                        </DialogContent>
                    </Dialog>
                    :
                    ""
            }

        </div >
    );
}

export default TechAudits;
import React from 'react';
import IdentityManager from '../../Managers/Identity.manager';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, TableContainer, Table, Paper, TableRow, TableCell, TableBody, Slide } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import Constants from '../../Common/Constants';
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function UserInfoDialog(props: any): any {
  const closeDialogBox = () => props.setuserProfileDialog(false)
  const renderTable = () => {
    return (
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell><b>Name</b></TableCell>
              <TableCell align="right">{IdentityManager.getUserProperty('displayName') ? IdentityManager.getUserProperty('displayName') : IdentityManager.getUserProperty('name')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell><b>Email</b></TableCell>
              <TableCell align="right">{IdentityManager.getUserProperty(Constants.Preferred_UserName)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell><b>Employee Id</b></TableCell>
              <TableCell align="right">{IdentityManager.getUserProperty(Constants.EmployeeID)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell><b>Store</b></TableCell>
              <TableCell align="right">{IdentityManager.getUserProperty(Constants.Location)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
  return (
    <Dialog open={props.open} onClose={() => { closeDialogBox() }} TransitionComponent={Transition}>
      <DialogTitle className="itemDialogBorder"><div className="dialogHeader">User Profile</div></DialogTitle>
      <DialogContent className="itemDialogBorder">
        {renderTable()}
      </DialogContent>
      <DialogActions>
        <Button size="small" onClick={() => { closeDialogBox() }} variant="contained" color="primary">
          <span className="dialogButton">Close</span>
        </Button>
      </DialogActions>
    </Dialog>
  );
}
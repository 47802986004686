import React from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { Divider, Grid, Typography, CardContent } from '@mui/material';
const CustomerDetailShimmerMobile = (props: any) => {
    return (
        <React.Fragment>
            <CardContent style={{ padding: "10px" }}>
                <Typography variant="h5" component="div">
                    <Skeleton animation="wave" />
                    <br />
                </Typography>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    <Skeleton animation="wave" />
                </Typography>
                <hr></hr>
                <Typography variant="body2">
                    <Skeleton animation="wave" />
                </Typography>
                <Typography variant="body2">
                    <Skeleton animation="wave" />
                </Typography>
            </CardContent>
        </React.Fragment>
    );

}
export default CustomerDetailShimmerMobile;
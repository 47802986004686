import { Autocomplete, Button, Checkbox, FormControl, Grid, List, ListItem, ListItemText, MenuItem, Paper, Select, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Constants from "../Common/Constants";
import SiteBreadCrumbs from "../Subcomponent/Shared/SiteBreadCrumbs";
import { CheckBoxOutlineBlankIcon, CheckBoxIcon } from "../Subcomponent/Shared/SharedIcons";
import PFManager from "../Managers/Process.Fulfillment.Manager";
import allActions from "../Store/Actions/AllActions";

const OrdersSyncFlow = (props: any) => {
    const dispatch = useDispatch();
    const stores = useSelector((state: any) => state.ActionLoaderReducersState.stores);
    const orderTypeOptions = [
        { label: "All", value: "All" },
        { label: "Pick", value: "Pick" },
        { label: "Pack", value: "Pack" },
        { label: "Customer Pickup", value: "Customer Pickup" },
        { label: "Pending Receipt", value: "Pending Receipt" },
        { label: "KCDC", value: "KCDC" },
    ];
    const [orderType, setOrderType] = useState("All");
    const [selectedStores, setSelectedStores] = useState([]);
    const [failedSyncStores, setFailedSyncStores] = useState([]);
    const [error, setError] = useState("");
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    const showActionLoader = () => {
        dispatch(allActions.actionLoaderActions.showLoader());
    }
    const hideActionLoader = () => {
        dispatch(allActions.actionLoaderActions.hideLoader());
    }
    const changeOrderType = (event: any) => {
        setOrderType(event.target.value);
    }
    const changeSelectedStores = (event: any, value: any) => {
        setSelectedStores(value);
    }
    const reset = () =>
    {
        setError("");
        setFailedSyncStores([]);
    }

    const syncData = async () => {
        try {
            let storeIds = selectedStores.map((a: any) => a.id);
            let payload = {
                Stores: storeIds,
                Type: orderType
            };
            console.log(payload, "syncData");
            reset();
            showActionLoader();
            await PFManager.omsDeltaSync(payload)
                .then((result) => {
                    hideActionLoader();
                    if (result && result.success) {

                        let msg = "Data has been synced successfully." + (result.data?.length > 0 ? "Some stores data could not be synced due to some error." : "");
                        dispatch(allActions.genericNotificationActions.showSuccessMessage(msg));

                        if (result.data?.length > 0)
                            setFailedSyncStores(result.data)
                    }
                    else if (result.success == false) {
                        setError("Order Sync Flow Failed.");
                    }
                })
        }
        catch (e) {
            hideActionLoader();
        }

    }
    return (
        <div className="innerContianer">
            <SiteBreadCrumbs
                siteBreadCrumbs={Constants.SiteBreadCrumbs.OrdersSyncFlow}>
            </SiteBreadCrumbs>
            <Paper
                style={{
                    paddingLeft: 3,
                    paddingRight: 3,
                    marginBottom: 10,
                    paddingBottom: 10,
                    padding: '20px'
                }}
            >

                <Grid container>
                    <Grid item xs={5} sm={5} className='inputField'>
                        <Typography variant="h5" style={{ marginTop: '10px' }}>
                            Store(s)
                        </Typography>
                        <FormControl>
                            <Autocomplete
                                multiple
                                id="store-multiselect"
                                limitTags={1}
                                value={selectedStores}
                                options={stores}
                                getOptionDisabled={() => (selectedStores.length === 10 ? true : false)}
                                onChange={(e, value) => { changeSelectedStores(e, value) }}
                                disableCloseOnSelect
                                getOptionLabel={(option: any) => option.id + "-" + option.name}
                                renderOption={(props, option: any, { selected }) => (
                                    <li {...props}>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option.id}-{option.name}
                                    </li>
                                )}
                                renderInput={(params) => (
                                    <TextField {...params} label="" placeholder={stores && stores.length == 0 ? "Loading Stores..." : "Select Store"} />
                                )}
                            />
                        </FormControl>

                    </Grid>

                    <Grid item xs={2} sm={2}>

                    </Grid>
                    <Grid item xs={5} sm={5} className='inputField'>
                        <Typography variant="h5" style={{ marginTop: '10px' }}>
                            Order Types
                        </Typography>
                        <FormControl>
                            <Select
                                labelId="dropdown-label"
                                id="dropdown-id"
                                value={orderType}
                                onChange={changeOrderType}
                                variant='outlined'
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                            >
                                {
                                    orderTypeOptions.map((val: any) => {

                                        return <MenuItem value={val.value}>{val.label}</MenuItem>

                                    })
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <Button style={{ marginTop: "5px", backgroundColor: Constants.Colors.red }} onClick={syncData}
                            disabled={selectedStores.length === 0} className={selectedStores.length === 0 ? "btn disableClass" : "btn"} variant='contained'>Sync Data</Button>

                    </Grid>

                </Grid>
                <Grid container>
                    {error &&
                        <Typography style={{ color: Constants.Colors.red, marginTop: "10px" }}>
                            {error}
                        </Typography>
                    }

                    {failedSyncStores.length > 0 && <List sx={{ listStyleType: 'disc', pl: 4 }}>
                        {failedSyncStores.map((value, index) => (
                            <ListItem sx={{ display: 'list-item', color: Constants.Colors.red }}
                                key={index}
                                disableGutters
                            >
                                <ListItemText primary={`Failed to sync order for Store#${value}`} />
                            </ListItem>
                        ))}
                    </List>}
                </Grid>
            </Paper>

        </div>
    );
}
export default OrdersSyncFlow;
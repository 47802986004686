import { Menu } from '@material-ui/core';
import { IconButton, ListItemIcon, MenuItem } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { MoreHorizIcon, EscalatorIcon, EmailIcon, CancelIcon } from "../../Subcomponent/Shared/SharedIcons";
import Utils from '../../Common/Utils';
import allActions from '../../Store/Actions/AllActions';
import { useDispatch, useSelector } from 'react-redux';
import { IReasonState } from '../../Models/IReasons';
const ItemAction = (props: any) => {

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const dispatch = useDispatch();
    let cancelReason: IReasonState = useSelector((state: any) => state.ActionLoaderReducersState.cancelReason);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <div>
            <IconButton
                style={{ background: Utils.isMobile ? "White" : "", paddingTop: 5 }}
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined} onClick={handleClick}><MoreHorizIcon /></IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={() => props.openResources(props.ShipmentLineKey)}>
                    <ListItemIcon>
                        <EscalatorIcon fontSize="small" />
                    </ListItemIcon>Resource</MenuItem>
                <MenuItem onClick={props.openCustomerContact}>
                    <ListItemIcon>
                        <EmailIcon fontSize="small" />
                    </ListItemIcon>Contact Customer</MenuItem>
                <MenuItem onClick={() => {

                    dispatch(allActions.actionLoaderActions.setCancelReason(
                        {
                            ...cancelReason,
                            showModal: true,
                            cancelItem: props.item
                        }
                    ))

                }}>
                    <ListItemIcon>
                        <CancelIcon fontSize="small" />
                    </ListItemIcon>Cancel Line
                </MenuItem>
            </Menu>
        </div>
    );

}
export default ItemAction;
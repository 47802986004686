import { Grid, Typography } from "@mui/material";
import React from "react";
import Utils from "../../Common/Utils";
import OrderDetailShimmer from "./OrderDetailShimmer";
import OrderDetailShimmerMobile from "./OrderDetailShimmerMobile";
export const OrderDetailGrid = (props: any) => {
    const { fulfillmentType, levelOfService, pickUpStore, storeNumber } = props.data;
    const webView = () => {
        return (
            <div className="backdrop">
                <div className='title'>
                    <Typography>Order Details</Typography>
                </div>
                {
                    !props.loading ?

                        <div className="mainContainer" style={{ padding: "20px" }}>

                            {
                                props.orderDetailRoute ?
                                    <Grid container spacing={2} display={"flex"} style={{ padding: "10px" }}>
                                        <Grid item md={6} sm={12} xs={12} display={"flex"}>
                                            <Typography style={{ fontSize: "18px", fontWeight: 500, marginLeft: "10px" }}>Fulfillment Location:</Typography>
                                            <Typography style={{ fontSize: "18px", marginLeft: "10px" }}>{storeNumber}</Typography>
                                        </Grid>
                                        <Grid item md={6} sm={12} xs={12} display={"flex"}>
                                            {pickUpStore ?
                                                <React.Fragment>
                                                    <Typography style={{ fontSize: "18px", fontWeight: 500, marginLeft: "10px" }}>Pickup Store:</Typography>
                                                    <Typography style={{ fontSize: "18px", marginLeft: "10px" }}>{pickUpStore}</Typography>
                                                </React.Fragment>
                                                :
                                                <></>
                                            }

                                        </Grid>
                                    </Grid>
                                    :
                                    <React.Fragment>
                                        {pickUpStore &&
                                            <Grid container spacing={2} display={"flex"} style={{ padding: "10px" }}>
                                               <Grid item md={6} sm={12} xs={12} display={"flex"}>
                                                    <Typography style={{ fontSize: "18px", fontWeight: 500, marginLeft: "10px" }}>Fulfillment Location:</Typography>
                                                    <Typography style={{ fontSize: "18px", marginLeft: "10px" }}>{storeNumber}</Typography>
                                                </Grid>
                                                <Grid item md={6} sm={12} xs={12} display={"flex"}>
                                                    <Typography style={{ fontSize: "18px", fontWeight: 500, marginLeft: "10px" }}>Pickup Store:</Typography>
                                                    <Typography style={{ fontSize: "18px", marginLeft: "10px" }}>{pickUpStore}</Typography>
                                                </Grid>
                                            </Grid>
                                        }
                                    </React.Fragment>

                            }

                            <Grid container spacing={2} display={"flex"} style={{ padding: "10px" }}>
                                <Grid item md={6} sm={12} xs={12}  display={"flex"}>
                                    <Typography style={{ fontSize: "18px", fontWeight: 500, marginLeft: "10px" }}>Level of Service:</Typography>
                                    <Typography style={{ fontSize: "18px", marginLeft: "10px" }}>{levelOfService}</Typography>
                                </Grid>
                                <Grid item md={6} sm={12} xs={12}  display={"flex"}>
                                    <Typography style={{ fontSize: "18px", fontWeight: 500, marginLeft: "10px" }}>Fulfilment Type:</Typography>
                                    <Typography style={{ fontSize: "18px", marginLeft: "10px" }}> {Utils.changeFulFillmentType(fulfillmentType)}</Typography>
                                </Grid>
                            </Grid>
                            {/* <Grid container display={"flex"} style={{ justifyContent: "space-around" }}>
                                {props.pickUpStore &&
                                    <React.Fragment>

                                    
                                    <Grid item style={{ display: "flex" }}>
                                        <Typography style={{ fontSize: "15px", fontWeight: 500 }}>
                                            Fulfillment Location:
                                        </Typography>
                                        <Typography style={{ fontSize: "15px", marginLeft: "2px" }}>
                                            {props.storeNumber}
                                        </Typography>
                                    </Grid>
                                    <Grid item style={{ display: "flex" }}>
                                        <Typography style={{ fontSize: "15px", fontWeight: 500 }}>
                                            Pickup Store:
                                        </Typography>
                                        <Typography style={{ fontSize: "15px", marginLeft: "2px" }}>
                                            {props.pickUpStore}
                                        </Typography>
                                    </Grid>
                                    </React.Fragment>
                                 }
                                <Grid item style={{ display: "flex" }}>
                                    <Typography style={{ fontSize: "15px", fontWeight: 500 }}>
                                        Level of Service:
                                    </Typography>
                                    <Typography style={{ fontSize: "15px", marginLeft: "2px" }}>
                                        {props.levelOfService}
                                    </Typography>

                                </Grid>
                                <Grid item style={{ display: "flex" }}>
                                    <Typography style={{ fontSize: "15px", fontWeight: 500 }}>
                                        Fulfilment Type:
                                    </Typography>
                                    <Typography style={{ fontSize: "15px", marginLeft: "2px" }}>
                                        {Utils.changeFulFillmentType(props.fulfillmentType)}
                                    </Typography>
                                </Grid>

                            </Grid> */}
                        </div>
                        :
                        <div className="mainContainer" style={{ padding: "20px" }}>
                            <OrderDetailShimmer></OrderDetailShimmer>
                        </div >
                }


            </div>
        )
    }
    const mobileView = () => {
        return (
            <div className="backdrop">
                <div className='title'>
                    <Typography>Order Details</Typography>
                </div>
                {
                    !props.loading ?
                        <div className="mainContainer" style={{ padding: "20px" }}>
                            <Grid container style={{ margin: "0px" }}>
                                {
                                    props.orderDetailRoute ?
                                        <React.Fragment>
                                            <Grid item xs={12} sm={12}>
                                                <Typography style={{ fontSize: "11px" }}>Fulfillment Location: <b>{storeNumber}</b></Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={12}>
                                                <Typography style={{ fontSize: "11px" }}>Pickup Store: <b>{pickUpStore}</b></Typography>
                                            </Grid>
                                        </React.Fragment>
                                        :
                                        <React.Fragment>
                                            {pickUpStore &&
                                                <React.Fragment>
                                                    <Grid item xs={12} sm={12}>
                                                        <Typography style={{ fontSize: "11px" }}>Fulfillment Location: <b>{storeNumber}</b></Typography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12}>
                                                        <Typography style={{ fontSize: "11px" }}>Pickup Store: <b>{pickUpStore}</b></Typography>
                                                    </Grid>
                                                </React.Fragment>
                                            }
                                        </React.Fragment>

                                }
                                <Grid item xs={12} sm={12}>
                                    <Typography style={{ fontSize: "11px" }}>Level of Service: <b>{levelOfService}</b></Typography>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <Typography style={{ fontSize: "11px" }}>Fulfilment Type: <b>{Utils.changeFulFillmentType(fulfillmentType)}</b></Typography>
                                </Grid>
                            </Grid>
                        </div>
                        :
                        <div className="mainContainer" style={{ padding: "20px" }}>
                            <OrderDetailShimmerMobile></OrderDetailShimmerMobile>
                        </div >
                }
            </div>
        )

    }
    return (
        <React.Fragment>
            {Utils.isMobile ? mobileView() : webView()}
        </React.Fragment>
    )
}
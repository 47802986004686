import { Grid } from "@mui/material"
import React from "react";
import { useState } from "react";
import { ShippingTile } from "./ShippingTile";
import { OrdersTile } from "./OrdersTile";
import { CommunicationTile } from "./CommunicationTile";
import { ReleasedOrdersTile } from "./ReleasedOrdersTile";
import { UsedItemsOrdersTile } from "./UsedItemsOrdersTile";
import { PickedUpOrdersTile } from "./PickedUpOrdersTile";
export const Tiles = (props: any) => {
    return (
        <React.Fragment>
            <Grid container>
                {/* {Released total} */}
                <Grid item xs={12} sm={9} style={{ padding: "3px" }}>
                    <ReleasedOrdersTile
                        stsCount={props.stsCount}
                        stsCountValue={props.stsCountValue}
                        stsUsedCount={props.stsUsedCount}
                        stsUsedCountValue={props.stsUsedCountValue}
                        bopiCount={props.bopiCount}
                        bopiCountValue={props.bopiCountValue}
                        bopiUsedCount={props.bopiUsedCount}
                        bopiUsedCountValue={props.bopiUsedCountValue}
                        sfsCount={props.sfsCount}
                        sfsCountValue={props.sfsCountValue}
                        sfsUsedCount={props.sfsUsedCount}
                        sfsUsedCountValue={props.sfsUsedCountValue}
                        released={props.released}
                        pickedUp={props.pickedUp}></ReleasedOrdersTile>
                </Grid>
                {/* {Picked Up total} */}
                <Grid item xs={12} sm={3} style={{ padding: "3px" }}>
                    <PickedUpOrdersTile released={props.released} pickedUp={props.pickedUp}></PickedUpOrdersTile>
                </Grid>
                {/* {Used Items total} */}
                {/* <Grid item xs={12} sm={4} style={{ padding: "3px" }}>
                    <UsedItemsOrdersTile usedReleased={props.usedReleased}></UsedItemsOrdersTile>
                </Grid> */}
            </Grid>
            <Grid container>
                <Grid item xs={12} sm={4} style={{ padding: "3px" }}>
                    <OrdersTile dateFilter={props.dateFilter} isFulfilledOrdersLoaded={props.isFulfilledOrdersLoaded} fulfilledData={props.fulfilledData}></OrdersTile>
                </Grid>
                <Grid item xs={12} sm={4} style={{ padding: "3px" }}>
                    <ShippingTile isShippingDataLoaded={props.isShippingDataLoaded} shippingData={props.shippingData}></ShippingTile>
                </Grid>
                <Grid item xs={12} sm={4} style={{ padding: "3px" }}>
                    <CommunicationTile isccDataLoaded={props.isccDataLoaded} ccData={props.ccData}></CommunicationTile>
                </Grid>
            </Grid>
        </React.Fragment>);
}

import { Button, Card, CardContent, Divider, Drawer, Grid, Paper, SwipeableDrawer, Toolbar, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton } from '@mui/material';
import Utils from '../Common/Utils';
import { createTheme, makeStyles } from "@material-ui/core/styles";
import { DoneIcon, CloseIcon, ArrowBackIcon, LocationOnIcon, ReportIcon, PersonIcon, AddLocationAltIcon, EmailIcon, LocalPhoneIcon, ReceiptLongIcon, PlaylistAddCheck } from "../Subcomponent/Shared/SharedIcons"
import { NotificationPopup } from '../Subcomponent/Shared/NotificationPopup'
import Constants from '../Common/Constants';
import { INotificationPopupSettings, IButton } from '../Models/INotificationPopupSettings';
import LabelText from "../Common/LabelText";
import SiteBreadCrumbs from '../Subcomponent/Shared/SiteBreadCrumbs';
import PickSlip from '../Subcomponent/FulfillOrders/PickSlip';
import { StatusApproval } from "../Subcomponent/PendingReceipt/StatusApproval";
import OrderLineShimmer from "../Subcomponent/PickOrder/OrderLineShimmer"
import OrderLineShimmerMobile from "../Subcomponent/PickOrder/OrderLineShimmerMobile"
import CustomerDetailShimmer from "../Subcomponent/PickOrder/CustomerDetailShimmer"
import CustomerDetailShimmerMobile from "../Subcomponent/PickOrder/CustomerDetailShimmerMobile"
import StoreManager from "../Managers/Store.manager";
import { IReceiveOrderDetail, ContainerDetail } from '../Models/IReceiveOrderDetail';
import OMSManager from "../Managers/Oms.manager";
import allActions from '../Store/Actions/AllActions';
import { ReceivePrintSlip } from "../Subcomponent/ReceiveOrders/ReceivePrintSlip";
import ApiService from '../Common/ApiService';
import IdentityManager from '../Managers/Identity.manager';
import OrderHistory from '../Subcomponent/Shared/OrderHistory';
import AddressDetailsShimmerMobile from '../Subcomponent/PickOrder/AddressDetailsShimmerMobile';
import BulletShimmer from '../Subcomponent/Shared/BulletShimmer';
import OrderManager from '../Managers/Orders.Manager';
import { IImageViewerDetail, IImageViewerPopupSettings } from '../Models/IImageViewerPopupSettings';
import { ImageViewerPopup } from '../Subcomponent/Shared/ImageViewerPopup';

var moment = require('moment');
const PendingReceipt = (props: any) => {
    const [openDrawer, setDrawer] = useState(false);
    const [openAddressDrawer, setAddressDrawer] = useState(false);
    const [openHistoryDrawer, setHistoryDrawer] = useState(false);
    const [isConfirmPopup, setIsConfirmPopup] = useState(false);
    const [loadingOrderLines, setLoadingOrderLines] = useState(true);
    const [isConfirmProblemPopup, setIsConfirmProblemPopup] = useState(false);
    const [openError, setOpenError] = useState(false);
    const [showPickupSlip, setShowPickupSlip] = useState(0);
    const [errorMsg, setErrorMsg] = useState("");
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const shipmentKey = urlParams.get('shipmentkey');
    const queryParamStore = urlParams.get('store');
    const [openSuccess, setOpenSuccess] = useState(false);
    const shipmentContainerKey = urlParams.get('shipmentcontainerkey');
    const [navigationReceiveOrders, setNavigationReceiveOrders] = useState(false);
    let orderDetail: IReceiveOrderDetail = useSelector((state: any) => state.ReceiveOrdersReducersState.receiveOrderDetail);
    const isHistoryLoading = useSelector((state: any) => state.OrderHistoryReducerState.isLoading);
    const [imagePopUp, setImagePopUp] = useState(false);
    const [imageData, setImageData] = useState({} as IImageViewerDetail);
    const openLoadingOrderLines = () => {
        setLoadingOrderLines(true)
    }
    const closeLoadingOrderLines = () => {
        setLoadingOrderLines(false)
    }
    const closeErrorPopup = () => {
        setOpenError(false)
    }
    const openErrorPopup = () => {
        setOpenError(true)
    }
    const showActionLoader = () => {
        dispatch(allActions.actionLoaderActions.showLoader());
    }
    const hideActionLoader = () => {
        dispatch(allActions.actionLoaderActions.hideLoader());
    }
    const useStyles = makeStyles((theme) => ({
        root: {
            backgroundColor: theme.palette.primary.main
        }
    }));
    const classes = useStyles();
    const theme = createTheme({
        palette: {
            primary: {
                // main: window.innerWidth > 1023 ? Constants.COLORS.DESKTOP_RED: Constants.COLORS.MOBILE_RED,
                // main: '#da0c0c',
                main: '#D93A2F',
            },
            secondary: {
                main: '#FFFFFF',
            },
        },
    })
    console.log(classes.root, "asas");
    const TestState = useSelector((state: any) => state.TestReducerState);
    console.log(TestState, "TestState");
    const dispatch = useDispatch();
    const navigate = useNavigate();
    window.scrollTo(0, 0);

    // Scroll
    var startProductBarPos = -1;
    window.onscroll = function () {
        console.log("scrolled");
        var bar = document.getElementById('bar') as any;
        var mblBar = document.getElementById('mblBar') as any;
        if (bar) {

            if (startProductBarPos < 0) startProductBarPos = findPosY(mblBar);

            if (window.pageYOffset > startProductBarPos) {

                bar.classList.add("scrolledTopFix");

            } else {
                bar.classList.remove("scrolledTopFix");
            }
        }
    };
    function findPosY(obj: any) {
        var curtop = 0;
        if (obj && typeof (obj.offsetParent) != 'undefined' && obj.offsetParent) {
            while (obj.offsetParent) {
                curtop += obj.offsetTop;
                obj = obj.offsetParent;
            }
            curtop += obj.offsetTop;
        }
        else if (obj && obj.y)
            curtop += obj.y;
        return curtop;
    }
    const closeConform = () => {
        setIsConfirmPopup(false)
    }
    const openConform = () => {
        setIsConfirmPopup(true)
    }
    const navigateToReceiveOrders = () => {
        navigate("/ReceiveOrders");
    }
    const navigateToFulfillOrders = () => {
        navigate("/FulfillOrders");
    }
    const openSuccessPopup = () => {
        setOpenSuccess(true)
    }
    const closeSuccessPopup = () => {
        setOpenSuccess(false)
    }

    const closeConfirmProblem = () => {
        setIsConfirmProblemPopup(false)
    }
    const openConfirmProblem = () => {
        setIsConfirmProblemPopup(true)
    }
    const popUpBack = () => {
        navigate(-1)
    }
    let errorPopupButtons: IButton[] = [
        {
            text: "Close",
            icon: <CloseIcon />,
            action: closeErrorPopup,
            color: Constants.Colors.red,
        },
        {
            text: "Back",
            icon: <ArrowBackIcon />,
            action: popUpBack,
            color: Constants.Colors.red,
        },
    ];
    let Error: INotificationPopupSettings = {
        open: openError,
        type: Constants.NotificationPopupType.Error,
        title: "Error",
        msg: errorMsg,
        draggable: false,
        handleClose: closeErrorPopup,
        actions: errorPopupButtons
    }

    const openNavigationReceiveOrders = () => {
        setNavigationReceiveOrders(true);
    }

    let navigateToReceiveOrdersButtons: IButton[] = [
        {
            text: "To Pending Receipts List",
            icon: <DoneIcon />,
            action: navigateToReceiveOrders,
            color: Constants.Colors.red,
        }
    ];

    let navigationToReceiveOrders: INotificationPopupSettings = {
        open: navigationReceiveOrders,
        type: Constants.NotificationPopupType.Info,
        title: "",
        msg: "This Order doesn't belong to selected Store.",
        draggable: false,
        handleClose: navigateToReceiveOrders,
        actions: navigateToReceiveOrdersButtons
    }

    const getReceiveDetailFromOms = async () => {
        let result = [] as any
        openLoadingOrderLines();
        //
        try {
            result = await OMSManager.getReceiveOrdersDetail(shipmentKey, shipmentContainerKey)
        }
        catch {
            //
            setErrorMsg("Some thing went wrong please contact the IT Help Desk for assistance");
            closeLoadingOrderLines();
            openErrorPopup();
        }
        //ContainerDetail       
        //
        if (result.success == true) {
            let data: IReceiveOrderDetail = result.data
            if (data.ContainerDetail.length > 0) {
                dispatch(allActions.receiveOrdersActions.changeReceiveOrderDetail(data));
                closeLoadingOrderLines();
            }
            else {
                setErrorMsg("Some thing went wrong please contact the IT Help Desk for assistance")
                openErrorPopup();
            }
        }
        else {
            setErrorMsg("Some thing went wrong please contact the IT Help Desk for assistance")
            openErrorPopup();
        }
    }
    const getServiceCode = (service: any) => {
        switch (service) {
            case 'NEXT DAY AIR':
                return 'red';

            case 'SECOND DAY EXPRESS':
                return 'green';

            case 'ECONOMY GROUND':
                return "blue"
            case 'STANDARD GROUND':
                return 'blue'
            case 'EMPLOYEE PICKUP':
                return 'blue'
            case 'DIGITAL DELIVERY':
                return 'blue'
            case 'STANDARD INTL':
                return 'blue'
            default:
                return 'blue';
        }
    }
    const getPayload = () => {
        let storeId = IdentityManager.getStoreId(props.store);
        let result: IReceiveOrderDetail = JSON.parse(JSON.stringify(orderDetail));
        let containerDetailPayload = [] as any
        result.LoginID = OMSManager.GetUserIdFromLocalStorage();
        result.EmployeeID = OMSManager.GetEmployeeId();
        result.ShipmentKey = shipmentKey + "";
        result.StoreID = storeId;
        result.ShipmentContainerKey = shipmentContainerKey + "";
        result.IsManagerApproval = "N";
        delete result.BillToAddress;
        delete result.PaymentMethods;
        delete result.ToAddress;
        delete result.PhoneNo;
        delete result.DeliveryMethod;
        delete result.ShipDate;
        delete result.LastName
        result.ContainerDetail.forEach((containerLines: ContainerDetail) => {
            if (containerLines.hasOwnProperty("WarrantyComponent")) {
                let WarrantyComponent = {
                    "WarrantyComponent": {
                        "ShipmentLineKey": containerLines?.WarrantyComponent?.ShipmentLineKey,
                        "Quantity": Number(containerLines?.WarrantyComponent?.Quantity),
                        "OrderLineKey": containerLines?.WarrantyComponent?.OrderLineKey,
                        "OrderReleaseKey": containerLines?.WarrantyComponent?.OrderReleaseKey
                    } as any
                }
                if (containerLines?.WarrantyComponent?.hasOwnProperty("ParentShipmentLineKey")) {
                    WarrantyComponent.WarrantyComponent.ParentShipmentLineKey = containerLines?.WarrantyComponent?.ParentShipmentLineKey
                }
                containerDetailPayload.push(WarrantyComponent)
            }
            else {
                let itemDetail = {
                    "ItemID": containerLines.ItemID,
                    "OrderLineKey": containerLines.OrderLineKey,
                    "OrderReleaseKey": containerLines.OrderReleaseKey,
                    "Quantity": Number(containerLines.Quantity),
                    "SerialNumber": containerLines.SerialNumber,
                    "ShipmentLineKey": containerLines.ShipmentLineKey
                } as any
                if (containerLines.hasOwnProperty("SetComponent")) {
                    itemDetail.SetComponent = containerLines.SetComponent
                }
                if (containerLines.hasOwnProperty("ParentShipmentLineKey")) {
                    itemDetail.ParentShipmentLineKey = containerLines.ParentShipmentLineKey
                }
                containerDetailPayload.push(itemDetail)
            }
        })
        result.ContainerDetail = containerDetailPayload
        let payload = {
            "ContainerDetails": result
        }
        return payload
    }
    const problemWithCarton = () => {
        //alert("problemWithCarton")
        let payload = getPayload();
        payload.ContainerDetails.ExtnIsProblemWithCarton = "Y";
        //
        console.log(payload);
        showActionLoader();
        ApiService.post("/oms/GCReceiptCartonProblem", payload).then((response: any) => {
            //
            if (response.success == true) {
                setShowPickupSlip(showPickupSlip + 1)
                closeConfirmProblem()
                hideActionLoader()
                openSuccessPopup()
            }
            else {
                closeConfirmProblem()
                hideActionLoader()
                setErrorMsg("Some thing went wrong please contact the IT Help Desk for assistance")
                openErrorPopup();
            }
        }).catch(() => {
            closeConfirmProblem()
            hideActionLoader()
            setErrorMsg("Some thing went wrong please contact the IT Help Desk for assistance")
            openErrorPopup();
        })

    }
    const conformReceive = () => {
        //alert("conformReceive")
        let payload = getPayload();
        //
        console.log(payload);
        showActionLoader();
        ApiService.post("/oms/GCReceiptConfirmCarton", payload).then((response: any) => {
            if (response.success == true) {
                OrderManager.saveOrderHistory({
                    shipmentKey: shipmentKey,
                    logType: "history",
                    transactionType: Constants.OrderHistoryTypes.Pending_Receive,
                    message: "",
                    transactionDate: moment.utc(moment.tz("America/Los_Angeles")).toISOString()
                });
                setShowPickupSlip(showPickupSlip + 1)
                closeConform()
                hideActionLoader()
                openSuccessPopup()
            }
            else {
                let errorMsg = "";
                closeConform()
                hideActionLoader()
                if (!response.success && response.msg) {
                    errorMsg = response.msg;
                }
                else {
                    errorMsg = "Some thing went wrong please contact the IT Help Desk for assistance";
                }
                setErrorMsg(errorMsg);
                openErrorPopup();
            }
        }).catch(() => {
            closeConform()
            hideActionLoader()
            setErrorMsg("Some thing went wrong please contact the IT Help Desk for assistance")
            openErrorPopup();
        })
    }
    let successPopupButtons: IButton[] = [
        {
            text: Utils.isMobile ? "Fulfillment" : "Back to Fulfillment",
            icon: <PlaylistAddCheck />,
            action: navigateToFulfillOrders,
            color: Constants.Colors.red,
        },
        {
            text: Utils.isMobile ? "Pending Receipt" : "Back to Pending Receipt",
            icon: <ReceiptLongIcon />,
            action: navigateToReceiveOrders,
            color: Constants.Colors.green,
        }
    ]
    let successPopup: INotificationPopupSettings = {
        open: openSuccess,
        type: Constants.NotificationPopupType.Success,
        title: "Success",
        msg: "Order Received Successfully",
        handleClose: closeSuccessPopup,
        isCustomJSX: false,
        actions: successPopupButtons,
    }
    let confirmPopupButtons: IButton[] = [{
        text: "Receive",
        icon: <DoneIcon />,
        action: conformReceive,
        color: Constants.Colors.green,
    },
    {
        text: "Cancel",
        icon: <CloseIcon />,
        action: closeConform,
        color: Constants.Colors.grey,
    }
    ]
    let confirmPopup: INotificationPopupSettings = {
        open: isConfirmPopup,
        type: Constants.NotificationPopupType.Success,
        title: "Receive",
        msg: "You are about to mark this order as Received",
        handleClose: closeConform,
        isCustomJSX: false,
        //customJSX: <StatusApproval></StatusApproval>,
        actions: confirmPopupButtons,
    }
    let confirmProblemPopupButtons: IButton[] = [{
        text: "Problem With Carton",
        icon: <DoneIcon />,
        action: problemWithCarton,
        color: Constants.Colors.red,
    },
    {
        text: "Cancel",
        icon: <CloseIcon />,
        action: closeConfirmProblem,
        color: Constants.Colors.grey,
    }
    ]
    let confirmProblemPopup: INotificationPopupSettings = {
        open: isConfirmProblemPopup,
        type: Constants.NotificationPopupType.Warning,
        title: "Problem With Carton",
        msg: "You are about to mark order with Problem With Carton",
        handleClose: closeConfirmProblem,
        isCustomJSX: false,
        //customJSX: <StatusApproval></StatusApproval>,
        actions: confirmProblemPopupButtons,
    }
    React.useEffect(() => {
        async function getReceiveDetail() {
            await getReceiveDetailFromOms()
        }
        getReceiveDetail()
        return () => {

        }

    }, []);

    React.useEffect(() => {
        if (queryParamStore && props.store?.length == 1 && props.store[0].id != queryParamStore) {
            openNavigationReceiveOrders();
        }
    }, [props.store]);

    const mobileView = () => {
        return (
            <React.Fragment>
                {
                    loadingOrderLines ?
                        <React.Fragment>
                            {<div style={{ paddingBottom: "150px" }}>
                                <Paper id='bar' style={{ color: "white", top: 0, left: 0, right: 0, zIndex: 999, borderRadius: 0 }} className="appBarPrimary">
                                    <Toolbar style={{ height: "46px", minHeight: "46px" }}>
                                        <IconButton
                                            size="large"
                                            edge="start"
                                            color="inherit"
                                            aria-label="menu"
                                            sx={{ mr: 2 }}
                                            onClick={() => { navigate(-1) }}
                                        >
                                            <ArrowBackIcon />
                                        </IconButton>
                                        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} style={{ marginLeft: "-48px" }}>
                                            Pending Receipt
                                        </Typography>
                                    </Toolbar>
                                </Paper>
                                <Paper style={{ marginTop: 2, paddingTop: 1, paddingBottom: 1 }}>
                                    <Card style={{ margin: "6px" }} id='cardOrder'>
                                        <CardContent style={{ padding: "10px" }}>
                                            <Grid spacing={2} container style={{ margin: "0px" }} sx={{ flexGrow: 1 }}>
                                                <Grid item xs={10} sm={9} style={{ padding: "8px" }}>
                                                    <Typography variant="h6" style={{ padding: "0px" }} className='scanColor'>
                                                        Tracking #
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Paper>
                                <Card style={{ marginTop: "6px" }} id='cardOrder'>
                                    <CardContent style={{ padding: "1px" }}>
                                        <div className="backdrop">
                                            <div className="title">
                                                <Typography>Order # </Typography>
                                            </div>
                                            <div className="mainContainer" style={{ padding: "5px" }}>
                                                <OrderLineShimmerMobile></OrderLineShimmerMobile>
                                            </div>
                                        </div>
                                    </CardContent>
                                </Card>
                                <Card style={{ marginTop: "6px" }} id='cardOrder'>
                                    <CardContent style={{ padding: "1px" }}>
                                        <div className="backdrop">
                                            <div className="title">
                                                <Typography>Address Details</Typography>
                                            </div>
                                            <div className="mainContainer" style={{ padding: "5px" }}>
                                                <AddressDetailsShimmerMobile></AddressDetailsShimmerMobile>
                                            </div>
                                        </div>
                                    </CardContent>
                                </Card>
                                <div style={{ display: "none" }}>
                                    <OrderHistory store={props.store}></OrderHistory>
                                </div>
                                <Card className='customerDetailsBoxPickOrder'>
                                    <CardContent style={{ padding: "1px", background: "#F3F3F4" }}>
                                        <Button onClick={() => { setDrawer(true) }} className="MobileDrawerButtons" variant="outlined">Customer Details</Button>
                                        <Button onClick={() => { isHistoryLoading ? setHistoryDrawer(false) : setHistoryDrawer(true) }} className="MobileDrawerButtons" variant="outlined">Fulfillment History</Button>
                                        {/* <Button onClick={() => { setAddressDrawer(true) }} className="MobileDrawerButtons" variant="outlined">Address Details</Button> */}
                                    </CardContent>
                                </Card>
                                <Drawer
                                    anchor={"bottom"}
                                    open={openAddressDrawer}
                                    onClose={() => { }}
                                    ModalProps={{ onBackdropClick: () => { setHistoryDrawer(false) } }}
                                >
                                    <Card style={{ margin: "6px" }}>
                                        <CardContent style={{ padding: "10px" }}>
                                            <Grid item xs={12} sm={12} className='inputField'>
                                                <CustomerDetailShimmerMobile></CustomerDetailShimmerMobile>
                                            </Grid>
                                            <hr></hr>
                                        </CardContent>
                                    </Card>
                                </Drawer>
                                <Drawer
                                    anchor={"bottom"}
                                    open={openDrawer}
                                    onClose={() => { }}
                                    onClick={() => { setDrawer(false) }}
                                >
                                    <Card style={{ margin: "6px" }}>
                                        <CardContent style={{ padding: "10px" }}>
                                            <CustomerDetailShimmerMobile></CustomerDetailShimmerMobile>
                                        </CardContent>
                                    </Card>
                                </Drawer>
                            </div>}
                        </React.Fragment>
                        :
                        <React.Fragment>
                            {<div style={{ paddingBottom: "150px" }}>
                                <Paper id='bar' style={{ color: "white", top: 0, left: 0, right: 0, zIndex: 999, borderRadius: 0 }} className="appBarPrimary">
                                    <Toolbar style={{ height: "46px", minHeight: "46px" }}>
                                        <IconButton
                                            size="large"
                                            edge="start"
                                            color="inherit"
                                            aria-label="menu"
                                            sx={{ mr: 2 }}
                                            onClick={() => { navigate(-1) }}
                                        >
                                            <ArrowBackIcon />
                                        </IconButton>
                                        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} style={{ marginLeft: "-48px" }}>
                                            Pending Receipt
                                        </Typography>
                                    </Toolbar>
                                </Paper>
                                <Paper style={{ marginTop: 2, paddingTop: 1, paddingBottom: 1 }}>
                                    <Card style={{ margin: "6px" }} id='cardOrder'>
                                        <CardContent style={{ padding: "10px" }}>
                                            <Grid container style={{ margin: "0px" }}>
                                                <Grid item sm={12} style={{ padding: "8px", textAlign: "left" }}>
                                                    <Typography variant="h6" style={{ padding: "0px" }} className='scanColor'>
                                                        Tracking # {orderDetail.TrackingNo}
                                                    </Typography>
                                                    <Typography variant="h6" style={{ padding: "0px" }} className='scanColor'>
                                                        Ordering Store # {orderDetail.OrderingStore}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                    {Utils.disableUserAction(props.store) && <React.Fragment>
                                        <Button onClick={() => openConform()} variant='contained' className='btn' style={{ width: "98%", marginBottom: 8 }} startIcon={<DoneIcon />}>Receive</Button>
                                        <Button onClick={() => openConfirmProblem()} variant='contained' style={{ backgroundColor: Constants.Colors.grey, width: "98%", marginBottom: 4 }} startIcon={<ReportIcon />}>Problem With Carton</Button>
                                    </React.Fragment>}
                                </Paper>
                                <Card style={{ marginTop: "6px" }} id='cardOrder'>
                                    <CardContent style={{ padding: "1px" }}>
                                        <div className="backdrop">
                                            <div className="title">
                                                <Typography>Order # {orderDetail.OrderNo}</Typography>
                                            </div>

                                            <div className="mainContainer" style={{ padding: "5px" }}>
                                                <Card style={{ margin: "6px" }} >
                                                    <CardContent style={{ padding: "10px" }}>
                                                        {
                                                            orderDetail.ContainerDetail.map((container) => {
                                                                if (!container.hasOwnProperty("WarrantyComponent")) {
                                                                    return (
                                                                        <Grid spacing={2} container style={{ margin: "0px" }} sx={{ flexGrow: 1 }}>
                                                                            <Grid item xs={3} sm={3} style={{ padding: "8px", borderRight: "1px solid #2f2d2d" }}>
                                                                                <img style={{ height: "70px", cursor: "zoom-in" }} alt="" src={Utils.convertToHttps(container.ImageUrl)} onClick={() => imageViewer(container)} />
                                                                            </Grid>
                                                                            <Grid item xs={9} sm={9} style={{ padding: "8px", textAlign: "left" }}>
                                                                                <Typography style={{ fontSize: "13px", fontWeight: 500, color: "#D93A2F" }}>{container.ItemDesc}</Typography>
                                                                                <Typography style={{ fontSize: "11px" }}>Shipped Qty: <b>{container.Quantity}</b></Typography>
                                                                                <Typography style={{ fontSize: "11px" }}>Item ID <b>{container.ItemID}</b></Typography>
                                                                                <Typography style={{ fontSize: "11px" }}>Brand: <b>{container.BrandName}</b></Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                    );
                                                                }
                                                            })
                                                        }
                                                    </CardContent>
                                                </Card>
                                            </div>
                                        </div>
                                    </CardContent>
                                </Card>
                                <Card style={{ marginTop: "6px" }} id='cardOrder'>
                                    <CardContent style={{ padding: "1px" }}>
                                        <div className="backdrop">
                                            <div className="title">
                                                <Typography>Address Details</Typography>
                                            </div>
                                            <div className="mainContainer" style={{ padding: "5px" }}>
                                                <Grid item xs={12} sm={12} className='inputField'>
                                                    <Typography sx={{ fontSize: "13px", fontWeight: 500, color: Constants.Colors.red, display: "flex" }}>
                                                        <LocationOnIcon></LocationOnIcon> Billing Address
                                                    </Typography>
                                                    <Typography style={{ fontSize: "11px", paddingLeft: "25px" }}>
                                                        {orderDetail?.BillToAddress?.FirstName} {orderDetail?.BillToAddress?.MiddleName} {orderDetail?.BillToAddress?.LastName}
                                                    </Typography>
                                                    <Typography style={{ fontSize: "11px", paddingLeft: "25px" }}>
                                                        {orderDetail?.BillToAddress?.AddressLine1}
                                                        {
                                                            orderDetail?.BillToAddress?.AddressLine2 == "" ?
                                                                ""
                                                                :
                                                                "," + orderDetail?.BillToAddress?.AddressLine2
                                                        }
                                                    </Typography>
                                                    <Typography style={{ fontSize: "11px", paddingLeft: "25px" }}>
                                                        {orderDetail?.BillToAddress?.City},{orderDetail?.BillToAddress?.State},{orderDetail?.BillToAddress?.ShortZipCode}
                                                    </Typography>
                                                    <Typography style={{ fontSize: "11px", paddingLeft: "25px" }}>
                                                        {orderDetail?.BillToAddress?.Country}
                                                    </Typography>
                                                </Grid>
                                                <hr></hr>
                                                <Grid item xs={12} sm={12} className='inputField'>
                                                    <Typography sx={{ fontSize: "13px", fontWeight: 500, color: Constants.Colors.red, display: "flex" }}>
                                                        <LocationOnIcon></LocationOnIcon> Shipping Address
                                                    </Typography>
                                                    <Typography style={{ fontSize: "11px", paddingLeft: "25px" }}>
                                                        {orderDetail?.ToAddress?.FirstName} {orderDetail?.ToAddress?.MiddleName} {orderDetail?.ToAddress?.LastName}
                                                    </Typography>
                                                    <Typography style={{ fontSize: "11px", paddingLeft: "25px" }}>
                                                        {orderDetail?.ToAddress?.Company}
                                                    </Typography>
                                                    <Typography style={{ fontSize: "11px", paddingLeft: "25px" }}>
                                                        {orderDetail?.ToAddress?.AddressLine1}
                                                        {
                                                            orderDetail?.ToAddress?.AddressLine2 == "" ?
                                                                ""
                                                                :
                                                                "," + orderDetail?.ToAddress?.AddressLine2
                                                        }
                                                    </Typography>
                                                    <Typography style={{ fontSize: "11px", paddingLeft: "25px" }}>
                                                        {orderDetail?.ToAddress?.City},{orderDetail?.ToAddress?.State},{orderDetail?.ToAddress?.ZipCode}
                                                    </Typography>
                                                    <Typography style={{ fontSize: "11px", paddingLeft: "25px" }}>
                                                        {orderDetail?.ToAddress?.Country}
                                                    </Typography>

                                                </Grid>
                                            </div>
                                        </div>
                                    </CardContent>
                                </Card>
                                <Card className='customerDetailsBoxPickOrder'>
                                    <CardContent style={{ padding: "1px", background: "#F3F3F4" }}>
                                        <Button onClick={() => { setDrawer(true) }} className="MobileDrawerButtons" variant="outlined">Customer Details</Button>
                                        <Button onClick={() => { setHistoryDrawer(true) }} className="MobileDrawerButtons" variant="outlined">Fulfillment History</Button>
                                        {/* <Button onClick={() => { setAddressDrawer(true) }} className="MobileDrawerButtons" variant="outlined">Address Details</Button> */}
                                    </CardContent>
                                </Card>
                                <SwipeableDrawer
                                    anchor={"bottom"}
                                    open={openHistoryDrawer}
                                    onClose={() => { }}
                                    onOpen={() => { }}
                                    ModalProps={{ onBackdropClick: () => { setHistoryDrawer(false) } }}
                                >
                                    <OrderHistory store={props.store}></OrderHistory>
                                </SwipeableDrawer>
                                {/* <Drawer
                                    anchor={"bottom"}
                                    open={openAddressDrawer}
                                    onClose={() => { }}
                                    onClick={() => { setAddressDrawer(false) }}
                                >
                                    <Card style={{ margin: "6px" }}>
                                        <CardContent style={{ padding: "10px" }}>
                                            <Grid item xs={12} sm={12} className='inputField'>
                                                <Typography sx={{ fontWeight: 'bold', color: Constants.Colors.red, display: "flex" }}>
                                                    <LocationOnIcon></LocationOnIcon> Billing Address
                                                </Typography>
                                                <Typography style={{ paddingLeft: "25px" }}>
                                                    {orderDetail?.BillToAddress?.FirstName} {orderDetail?.BillToAddress?.MiddleName} {orderDetail?.BillToAddress?.LastName}
                                                </Typography>
                                                <Typography style={{ paddingLeft: "25px" }}>
                                                    {orderDetail?.BillToAddress?.AddressLine1}
                                                    {
                                                        orderDetail?.BillToAddress?.AddressLine2 == "" ?
                                                            ""
                                                            :
                                                            "," + orderDetail?.BillToAddress?.AddressLine2
                                                    }
                                                </Typography>
                                                <Typography style={{ paddingLeft: "25px" }}>
                                                    {orderDetail?.BillToAddress?.City},{orderDetail?.BillToAddress?.State},{orderDetail?.BillToAddress?.ShortZipCode}
                                                </Typography>
                                                <Typography style={{ paddingLeft: "25px" }}>
                                                    {orderDetail?.BillToAddress?.Country}
                                                </Typography>
                                            </Grid>
                                            <hr></hr>
                                            <Grid item xs={12} sm={12} className='inputField'>
                                                <Typography sx={{ fontWeight: 'bold', color: Constants.Colors.red, display: "flex" }}>
                                                    <LocationOnIcon></LocationOnIcon> Shipping Address
                                                </Typography>
                                                <Typography style={{ paddingLeft: "25px" }}>
                                                    {orderDetail?.ToAddress?.FirstName} {orderDetail?.ToAddress?.MiddleName} {orderDetail?.ToAddress?.LastName}
                                                </Typography>
                                                <Typography style={{ paddingLeft: "25px" }}>
                                                    {orderDetail?.ToAddress?.Company}
                                                </Typography>
                                                <Typography style={{ paddingLeft: "25px" }}>
                                                    {orderDetail?.ToAddress?.AddressLine1}
                                                    {
                                                        orderDetail?.ToAddress?.AddressLine2 == "" ?
                                                            ""
                                                            :
                                                            "," + orderDetail?.ToAddress?.AddressLine2
                                                    }
                                                </Typography>
                                                <Typography style={{ paddingLeft: "25px" }}>
                                                    {orderDetail?.ToAddress?.City},{orderDetail?.ToAddress?.State},{orderDetail?.ToAddress?.ZipCode}
                                                </Typography>
                                                <Typography style={{ paddingLeft: "25px" }}>
                                                    {orderDetail?.ToAddress?.Country}
                                                </Typography>

                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Drawer> */}
                                <Drawer
                                    anchor={"bottom"}
                                    open={openDrawer}
                                    onClose={() => { }}
                                    onClick={() => { setDrawer(false) }}
                                >
                                    <Card style={{ margin: "6px" }}>
                                        <CardContent style={{ padding: "10px" }}>
                                            <Typography variant="h5" component="div">
                                                {orderDetail.FirstName} {orderDetail.LastName}
                                                <br />
                                            </Typography>

                                            <Typography variant="body2">
                                                Email: {orderDetail.EMailID}
                                            </Typography>
                                            <Typography variant="body2">
                                                Phone: {orderDetail?.PhoneNo}
                                            </Typography>

                                        </CardContent>
                                    </Card>
                                </Drawer>
                            </div>}
                        </React.Fragment>
                }
            </React.Fragment>
        );
    }

    const closeImageViewer = () => {
        setImagePopUp(false);
        setImageData({} as IImageViewerDetail);
    }

    let imageViewerSettings: IImageViewerPopupSettings = {
        open: imagePopUp,
        imageDetail: imageData,
        close: closeImageViewer
    }


    const imageViewer = (item: any) => {
        let imageData: IImageViewerDetail = {
            imageURL: item.ImageUrl,
            itemDesc: item.ItemDesc,
            itemID: item.OrderLine?.ItemDetails?.Extn?.ExtnPOSItemID ?? "-",
            skuID: item.ItemID,
            price: item.UnitPrice,
            qty: item.Quantity
        }

        setImagePopUp(true);
        setImageData(imageData);
    }

    const webView = () => {
        return (
            <div>
                <SiteBreadCrumbs siteBreadCrumbs={Constants.SiteBreadCrumbs.PendingReceipt}></SiteBreadCrumbs>
                {
                    loadingOrderLines ?
                        <React.Fragment>
                            <div style={{ margin: "5px" }}>
                                <Paper className='paperToBottomPadding' style={{ padding: "10px" }}>
                                    <Grid container spacing={2} display={"flex"}>
                                        <Grid item xs={6} sm={6} className='textLeft'>
                                            <Typography variant="h6" style={{ padding: "0px" }} className='scanColor'>
                                                Tracking #
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} sm={6} className='textRight'>
                                            {/* <Button variant='contained' onClick={() => openConfirmProblem()} style={{ backgroundColor: Constants.Colors.grey }} startIcon={<ReportIcon />}>Problem With Carton</Button>
                                &nbsp;&nbsp;
                                <Button variant='contained' onClick={() => openConform()} className='btn' startIcon={<DoneIcon />}>Receive</Button>  */}
                                        </Grid>
                                    </Grid>
                                </Paper>
                                <Paper style={{ marginTop: "26px" }}>
                                    <Grid container spacing={2} display={"flex"}>
                                        <Grid item xs={9} sm={9} style={{ paddingTop: "0px", paddingRight: "4px" }} className='textLeft'>
                                            <div className="backdrop">
                                                <div className="title">
                                                    <Typography>Order #</Typography>
                                                </div>
                                                <div className="mainContainer" style={{ padding: "20px" }}>
                                                    <Grid container spacing={2} display={"flex"}>
                                                        <Grid item xs={9} sm={9} className='textLeft'>
                                                        </Grid>
                                                        <Grid item xs={3} sm={3} className='textCenter'>
                                                            <Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Divider />
                                                    <div>
                                                        <OrderLineShimmer></OrderLineShimmer>
                                                    </div>
                                                </div >
                                            </div >
                                            <div className="backdrop">
                                                <div className="title">
                                                    <Typography> Customer Details</Typography>
                                                </div>
                                                <div className="mainContainer" style={{ padding: "20px" }}>
                                                    <CustomerDetailShimmer></CustomerDetailShimmer>
                                                </div >
                                            </div >
                                        </Grid>
                                        <Grid item xs={3} sm={3} className='textRight' style={{ paddingTop: "0px", paddingRight: "0px", paddingLeft: "6px" }}>
                                            <div className="backdrop">
                                                <div className="title">
                                                    <Typography>Address Details</Typography>
                                                </div>
                                                <div className="mainContainer" style={{ padding: "10px" }}>
                                                    <Grid container>
                                                        <Grid item xs={12} sm={12} className='inputField'>
                                                            <CustomerDetailShimmer></CustomerDetailShimmer>
                                                        </Grid>
                                                    </Grid>
                                                </div>

                                            </div>
                                            <div>
                                                <BulletShimmer></BulletShimmer>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </div >

                        </React.Fragment>
                        :
                        <React.Fragment>
                            <div style={{ margin: "5px" }}>
                                <Paper className='paperToBottomPadding' style={{ padding: "10px" }}>
                                    <Grid container spacing={2} display={"flex"}>
                                        <Grid item xs={4} sm={4} className='textLeft'>
                                            <Typography variant="h6" style={{ padding: "0px" }} className='scanColor'>
                                                Tracking # {orderDetail.TrackingNo}
                                            </Typography>
                                        </Grid>
                                        {Utils.disableUserAction(props.store) && <Grid item xs={8} sm={8} className='textRight'>
                                            <Button variant='contained' onClick={() => openConfirmProblem()} style={{ backgroundColor: Constants.Colors.grey,marginTop:1 }} startIcon={<ReportIcon />}>Problem With Carton</Button>
                                            <Button variant='contained' onClick={() => openConform()} className='btn' startIcon={<DoneIcon />} style={{ marginLeft: "5px",marginTop:1 }}>Receive</Button>
                                            {/* <Button variant='contained' onClick={() => setShowPickupSlip(showPickupSlip + 1)} className='btn' startIcon={<DoneIcon />}>Print</Button> */}
                                        </Grid>}
                                    </Grid>
                                </Paper>
                                <Paper style={{ marginTop: "26px" }}>
                                    <Grid container spacing={2} display={"flex"}>
                                        <Grid item xs={9} sm={9} style={{ paddingTop: "0px", paddingRight: "4px" }} className='textLeft'>
                                            <div className="backdrop">
                                                <div className="title">
                                                    <Typography>Order # {orderDetail.OrderNo}</Typography>
                                                </div>
                                                <div className="mainContainer" style={{ padding: "20px" }}>
                                                    <Grid container spacing={2} display={"flex"}>
                                                        <Grid item xs={9} sm={9} className='textLeft'>
                                                            <Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>
                                                                Ordering Store # {orderDetail.OrderingStore}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={3} sm={3} className='textCenter'>
                                                            <Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>
                                                                Shipped Qty
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Divider />
                                                    <div>
                                                        {
                                                            orderDetail.ContainerDetail.map((container) => {
                                                                if (!container.hasOwnProperty("WarrantyComponent")) {
                                                                    return (
                                                                        <React.Fragment>
                                                                            <Grid container spacing={2} display={"flex"}>
                                                                                <Grid item xs={9} sm={9} className='textLeft'>
                                                                                    <Grid spacing={2} container style={{ margin: "0px" }} sx={{ flexGrow: 1 }}>
                                                                                        <Grid item xs={3} sm={3} style={{ padding: "8px", borderRight: "1px solid #2f2d2d", display: "flex", justifyContent: "center" }}>
                                                                                            <div className="image-zoom" onClick={() => imageViewer(container)}>
                                                                                                <img alt="" src={Utils.convertToHttps(container.ImageUrl)} style={{ height: "auto", width: "100%"}} />
                                                                                            </div>

                                                                                        </Grid>
                                                                                        <Grid item xs={9} sm={9} style={{ padding: "8px", textAlign: "left" }}>
                                                                                            <Typography style={{ fontSize: "15px", fontWeight: 500, color: "#D93A2F" }}>{container.ItemDesc}</Typography>
                                                                                            <Typography variant="body2" sx={{ fontSize: 15 }} gutterBottom>
                                                                                                Item ID <b>{container.ItemID}</b>
                                                                                            </Typography>
                                                                                            <Typography variant="body2" sx={{ fontSize: 15 }} gutterBottom>
                                                                                                Brand: <b>{container.BrandName}</b>
                                                                                            </Typography>
                                                                                        </Grid>

                                                                                    </Grid>
                                                                                </Grid>
                                                                                <Grid item xs={3} sm={3} className='textCenter'>
                                                                                    <Typography variant="body2" sx={{ fontSize: 15 }} gutterBottom>
                                                                                        {container.Quantity}
                                                                                    </Typography>
                                                                                </Grid>
                                                                            </Grid>
                                                                            <Divider></Divider>
                                                                        </React.Fragment>
                                                                    );
                                                                }
                                                            })
                                                        }
                                                    </div>
                                                </div >
                                            </div >
                                            <div className="backdrop">
                                                <div className="title">
                                                    <Typography> Customer Details</Typography>
                                                </div>
                                                <div className="mainContainer" style={{ padding: "20px" }}>
                                                    <Grid container spacing={2} display={"flex"} style={{ padding: "10px" }}>
                                                        <Grid item xs={6} sm={6} className='textLeft' style={{ display: "flex" }}>
                                                            <PersonIcon></PersonIcon>  <Typography style={{ fontSize: "18px", fontWeight: 500, marginLeft: "10px" }}>{orderDetail.FirstName} {orderDetail.LastName}</Typography>
                                                        </Grid>
                                                        <Grid item xs={6} sm={6} className='textLeft' style={{ display: "flex" }}>
                                                            <EmailIcon></EmailIcon><Typography style={{ fontSize: "18px", fontWeight: 500, marginLeft: "10px" }}> {orderDetail.EMailID}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={2} display={"flex"} style={{ padding: "10px" }}>
                                                        <Grid item xs={6} sm={6} className='textLeft' style={{ display: "flex" }}>
                                                            <LocalPhoneIcon></LocalPhoneIcon>   <Typography style={{ fontSize: "18px", fontWeight: 500, marginLeft: "10px" }}>{orderDetail?.PhoneNo}</Typography>
                                                        </Grid>
                                                        <Grid item xs={6} sm={6} className='textLeft'>

                                                        </Grid>
                                                    </Grid>
                                                </div >
                                            </div >
                                        </Grid>
                                        <Grid item xs={3} sm={3} className='textRight' style={{ paddingTop: "0px", paddingRight: "0px", paddingLeft: "6px" }}>
                                            <div className="backdrop">
                                                <div className="title">
                                                    <Typography>Address Details</Typography>
                                                </div>
                                                <div className="mainContainer" style={{ padding: "10px" }}>
                                                    <Grid container>
                                                        <Grid item xs={12} sm={12} className='inputField'>
                                                            <Typography sx={{ fontWeight: 'bold', color: Constants.Colors.red, display: "flex" }}>
                                                                <LocationOnIcon></LocationOnIcon> Billing Address
                                                            </Typography>
                                                            <Typography style={{ paddingLeft: "25px" }}>
                                                                {orderDetail?.BillToAddress?.FirstName} {orderDetail?.BillToAddress?.MiddleName} {orderDetail?.BillToAddress?.LastName}
                                                            </Typography>
                                                            <Typography style={{ paddingLeft: "25px" }}>
                                                                {orderDetail?.BillToAddress?.AddressLine1}
                                                                {
                                                                    orderDetail?.BillToAddress?.AddressLine2 == "" ?
                                                                        ""
                                                                        :
                                                                        "," + orderDetail?.BillToAddress?.AddressLine2
                                                                }
                                                            </Typography>
                                                            <Typography style={{ paddingLeft: "25px" }}>
                                                                {orderDetail?.BillToAddress?.City},{orderDetail?.BillToAddress?.State},{orderDetail?.BillToAddress?.ShortZipCode}
                                                            </Typography>
                                                            <Typography style={{ paddingLeft: "25px" }}>
                                                                {orderDetail?.BillToAddress?.Country}
                                                            </Typography>
                                                        </Grid>
                                                        <hr></hr>
                                                        <Grid item xs={12} sm={12} className='inputField'>
                                                            <Typography sx={{ fontWeight: 'bold', color: Constants.Colors.red, display: "flex" }}>
                                                                <LocationOnIcon></LocationOnIcon>  Shipping Address
                                                            </Typography>
                                                            <Typography style={{ paddingLeft: "25px" }}>
                                                                {orderDetail?.ToAddress?.FirstName} {orderDetail?.ToAddress?.MiddleName} {orderDetail?.ToAddress?.LastName}
                                                            </Typography>
                                                            <Typography style={{ paddingLeft: "25px" }}>
                                                                {orderDetail?.ToAddress?.Company}
                                                            </Typography>
                                                            <Typography style={{ paddingLeft: "25px" }}>
                                                                {orderDetail?.ToAddress?.AddressLine1}
                                                                {
                                                                    orderDetail?.ToAddress?.AddressLine2 == "" ?
                                                                        ""
                                                                        :
                                                                        "," + orderDetail?.ToAddress?.AddressLine2
                                                                }
                                                            </Typography>
                                                            <Typography style={{ paddingLeft: "25px" }}>
                                                                {orderDetail?.ToAddress?.City},{orderDetail?.ToAddress?.State},{orderDetail?.ToAddress?.ZipCode}
                                                            </Typography>
                                                            <Typography style={{ paddingLeft: "25px" }}>
                                                                {orderDetail?.ToAddress?.Country}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </div >
                                            </div >
                                            <div>
                                                <OrderHistory store={props.store}></OrderHistory>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </div >
                        </React.Fragment>
                }
            </div >
        );
    }
    return (
        <div>
            <NotificationPopup {...successPopup}>
            </NotificationPopup>
            <NotificationPopup {...confirmProblemPopup}>
            </NotificationPopup>
            <NotificationPopup {...confirmPopup}>
            </NotificationPopup>
            <NotificationPopup {...Error}>
            </NotificationPopup>
            <NotificationPopup {...navigationToReceiveOrders}>
            </NotificationPopup>
            <ImageViewerPopup {...imageViewerSettings}>
            </ImageViewerPopup>
            {Utils.isMobile ? mobileView() : webView()}
            {loadingOrderLines == false ?
                <ReceivePrintSlip loadingOrderLines={loadingOrderLines} showPickupSlip={showPickupSlip}></ReceivePrintSlip>
                :
                <div></div>
            }

            {/* {!Utils.isMobile ? <PickSlip></PickSlip> : ""} */}
        </div>
    );
}
export default PendingReceipt;
import React from "react";
import { Grid, Typography, Card, CardContent } from "@mui/material";
import { PersonIcon, AddLocationAltIcon, EmailIcon, LocalPhoneIcon } from "../Shared/SharedIcons"
import CustomerDetailShimmer from "../PickOrder/CustomerDetailShimmer"
import { ICustomerDetail } from '../../Models/IPickOrder'
import { useSelector } from 'react-redux';
import CustomerDetailShimmerMobile from "../PickOrder/CustomerDetailShimmerMobile"
import Utils from "../../Common/Utils";
import { CustomerDetails } from "../Shared/CustomerDetails";
export const PackOrderCustomerDetail: React.FC<any> = (props) => {
    let customerDetail: ICustomerDetail = useSelector((state: any) => state.PackOrderReducersState.customerDetail);
    const webView = () => {
        return (
            <div className="backdrop">
                <div className="title">
                    <Typography> Customer Details</Typography>
                </div>
                {
                    props.loadingOrderLines == true ?
                        <div className="mainContainer" style={{ padding: "20px" }}>
                            <CustomerDetailShimmer></CustomerDetailShimmer>
                        </div>
                        :
                        <div>
                            <CustomerDetails customerDetail={customerDetail}></CustomerDetails>
                        </div>
                }

            </div>
        );
    }
    const mobileView = () => {
        return (
            <React.Fragment>
                {
                    props.loadingOrderLines == true ?
                        <div className="mainContainer" style={{ padding: "20px" }}>
                            <CustomerDetailShimmerMobile></CustomerDetailShimmerMobile>
                        </div>
                        :
                        <div>
                            <CustomerDetails customerDetail={customerDetail}></CustomerDetails>
                        </div>
                }
            </React.Fragment>
        )
    }
    return (
        <React.Fragment>
            {Utils.isMobile ? mobileView() : webView()}
        </React.Fragment>
    )
}
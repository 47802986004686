import {
    CHANGE_FO_TAB, CHNAGE_FO_PAGINATION, CHANGE_FO_TOTAL, CHANGE_FO_ORDERROWS, CHANGE_SELECTED_ORDER_TYPE,
    CHANGE_ORDER_TYPE_FILTER, CHANGE_SELECTED_ORDER_AGE, CHANGE_ORDER_AGE_FILTER,
    CHANGE_SELECTED_ORDER_VALUE, CHANGE_ORDER_VALUE_FILTER, CHANGE_TAB_Counts, CHANGE_PRINT_PICKSLIP,
    CHANGE_PICK_SINGLE_MULTIPLE_VAL,
    CHANGE_PICK_SINGLE_MULTIPLE_MARKED_VALUES
} from "../ActionTypes";
import Constants from "../../Common/Constants";
const initialState = {
    total: 0,
    orderRows: [],
    currentTab: 0,
    tabsCount: Constants.TabCounts,
    //pagination: Constants.LABELS.PAGINATION.DEFAULT,
    selectedOrderTypeFilter: "ALL",
    orderTypeFilters: Constants.OrderTypeFilters,
    selectedOrderAgeFilter: "",
    orderAgeFilters: Constants.OrderAgeFilters,
    selectedOrderValue: "",
    orderValueFilters: Constants.OrderValueFilters,
    pickSlipGroupByModal: {
        open: false,
        value: "0",
        showPdf: false
    },
    mobilePickSingleMultipleVal:"0",
    mobilePickSingleMultipleMarkedValues: []
}
const FulfillOrdersReducers = (state: any = initialState,
    action: { type: any; payLoad: any }) => {
    let clone = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case CHANGE_TAB_Counts:
            return {
                ...state,
                tabsCount: action.payLoad
            }
        case CHANGE_FO_TOTAL:
            return {
                ...state,
                total: action.payLoad
            }
        case CHANGE_FO_ORDERROWS:
            return {
                ...state,
                orderRows: action.payLoad
            }
        case CHANGE_FO_TAB:
            return {
                ...state,
                currentTab: action.payLoad
            }
        case CHANGE_SELECTED_ORDER_TYPE:
            return {
                ...state,
                selectedOrderTypeFilter: action.payLoad
            }
        case CHANGE_ORDER_TYPE_FILTER:
            return {
                ...state,
                orderTypeFilters: action.payLoad
            }
        case CHANGE_SELECTED_ORDER_AGE:
            return {
                ...state,
                selectedOrderAgeFilter: action.payLoad
            }
        case CHANGE_ORDER_AGE_FILTER:
            return {
                ...state,
                orderAgeFilters: action.payLoad
            }
        case CHANGE_SELECTED_ORDER_VALUE:
            return {
                ...state,
                selectedOrderValue: action.payLoad
            }
        case CHANGE_ORDER_VALUE_FILTER:
            return {
                ...state,
                orderValueFilters: action.payLoad
            }
         case CHANGE_PRINT_PICKSLIP:
            return {
                ...state,
                pickSlipGroupByModal: action.payLoad
            }
        case CHANGE_PICK_SINGLE_MULTIPLE_VAL:
            return {
                ...state,
                mobilePickSingleMultipleVal: action.payLoad
            }
        case CHANGE_PICK_SINGLE_MULTIPLE_MARKED_VALUES:
            return {
                ...state,
                mobilePickSingleMultipleMarkedValues: action.payLoad
            }
    }

    return state;
}

export default FulfillOrdersReducers;
import { styled } from '@mui/material/styles';
import React, { useState } from "react";
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Constants from "../../Common/Constants";
import { IconButton } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, Divider, Grid, Tooltip, Typography, TextField, Card, CardContent, Button, Chip } from "@mui/material";
import { CancelIcon, ArrowForwardIosSharpIcon } from "../Shared/SharedIcons"
import allActions from '../../Store/Actions/AllActions';
import IitemQty from '../../Models/IItemQty';
import OrderLineShimmer from "../PickOrder/OrderLineShimmer"
import { OmsPackOrderDetail, ShipmentLine2 } from "../../Models/OmsPackOrderDetails";
import CancelLine from '../Shared/CancelLine';
import OMSManager from '../../Managers/Oms.manager';
import { IReasonState } from '../../Models/IReasons';
import Utils from '../../Common/Utils';
import OrderLineShimmerMobile from "../PickOrder/OrderLineShimmerMobile"
import OrderManager from '../../Managers/Orders.Manager';
import { IImageViewerDetail, IImageViewerPopupSettings } from '../../Models/IImageViewerPopupSettings';
import { ImageViewerPopup } from '../Shared/ImageViewerPopup';
var moment = require('moment');

const Accordion = styled((props: any) => (
    <MuiAccordion style={{ minHeight: "35px !important" }} disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem', color: Constants.Colors.white }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor: Constants.Colors.red,
    color: Constants.Colors.white,
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
        marginTop: 6,
        marginBottom: 6,
    },
}));
const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));
export const UnPackagedItems: React.FC<any> = (props) => {
    const dispatch = useDispatch();
    // cancel line popup
    let cancelReason: IReasonState = useSelector((state: any) => state.ActionLoaderReducersState.cancelReason);

    //
    let unpackagedItemsList: OmsPackOrderDetail = useSelector((state: any) => state.PackOrderReducersState.unpackagedItems);
    let selectedItems = useSelector((state: any) => state.PackOrderReducersState.selectedItems);
    let itemQty: IitemQty[] = useSelector((state: any) => state.PackOrderReducersState.itemQty);
    let isResidential: boolean = useSelector((state: any) => state.PackOrderReducersState.isResidential);
    const [expandedUnPacked, setExpandedUnPacked] = React.useState(true);
    const [imagePopUp, setImagePopUp] = React.useState(false);
    const [imageData, setImageData] = React.useState({} as IImageViewerDetail);
    function getSumQtyAvailableToPack(total: number, item: any) {
        return total + Number(item.QtyAvailableToPack);
    }
    const getTotalQtyToPack = () => {
        if (props.loadingOrderLines == false && unpackagedItemsList.ShipmentLines.ShipmentLine.length > 0) {
            let total = unpackagedItemsList.ShipmentLines.ShipmentLine ? unpackagedItemsList.ShipmentLines.ShipmentLine.reduce(getSumQtyAvailableToPack, 0) : 0;
            return total
        }
        else {
            return 0
        }

    }

    const logCancelOrderHistory = async () => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const shipmentkey = urlParams.get('shipmentkey');
        await OrderManager.saveOrderHistory({
            shipmentKey: shipmentkey,
            logType: "history",
            cancelAction: "Pack",
            transactionType: Constants.OrderHistoryTypes.Cancel_Order,
            transactionDate: moment.utc(moment.tz("America/Los_Angeles")).toISOString()
        });
    }

    const getCancelReasonValues = async (code: any, text: any) => {
        dispatch(allActions.actionLoaderActions.showLoader());
        console.log(code, text);
        let itemToCancel = { ...cancelReason.cancelItem };
        let payLoad = getCancelPayload(itemToCancel, code, text);
        //
        if (payLoad) {

            await cancelLineCall(payLoad).then((response: any) => {
                if (payLoad?.Shipment?.IsCompleteCancel == "Y" && response.success) {
                    logCancelOrderHistory();
                }
            })

            dispatch(allActions.actionLoaderActions.setCancelReason(
                Utils.initCanceReasonState()
            ))
            dispatch(allActions.actionLoaderActions.hideLoader());
            if (props.getPackDetailFromOms) {
                props.getPackDetailFromOms(true);
            }

        }
    }

    const cancelLineCall = async (payLoad: any) => {
        return await OMSManager.GCPackCancelLine(payLoad);
    }
    const showCancelPopup = (item: any) => {

        dispatch(allActions.actionLoaderActions.setCancelReason(
            {
                ...cancelReason,
                showModal: true,
                cancelItem: item
            }
        ))
    }

    const getCancelPayload = (item: any, code: any, text: any) => {

        let obj: any = {
            "Shipment": {
                "ShipmentKey": unpackagedItemsList.ShipmentKey,
                "OrderHeaderKey": unpackagedItemsList.OrderHeaderKey,
                "ReasonCode": code,
                "ReasonText": text,
                "UserID": OMSManager.GetUserIdFromLocalStorage(),
                "EmployeeID": OMSManager.GetEmployeeId(),
                "IsManagerApproval": "Y",
                "UserIdDomain": OMSManager.GetUserIdDoamin().domain,
                "ShipmentLines": {
                    "ShipmentLine": {
                        "ShipmentLineKey": item?.ShipmentLineKey,
                        "Quantity": parseInt(item?.QtyAvailableToPack)
                    }
                },
                "loggedInUserName": OMSManager.GetUserIdDoamin().user,
            }
        }

        if (unpackagedItemsList.ShipmentLines?.ShipmentLine && unpackagedItemsList.ShipmentLines?.ShipmentLine.length == 1) {
            obj["Shipment"]["IsCompleteCancel"] = "Y"
        }

        return obj;
    }

    const handleChangeUnPacked = () => {
        setExpandedUnPacked(!expandedUnPacked);
    };
    const changeSelectedItem = (event: any, id: any) => {
        let checked = event.target.checked
        if (checked) {
            let newSelectedItems = [...selectedItems]
            newSelectedItems.push(id)
            dispatch(allActions.packOrderActions.changeSelectedItems(newSelectedItems));
            if (newSelectedItems.length > 1) {
                resetBoxSide()
            }
        }
        else {
            let newSelectedItems = selectedItems.filter((listId: any) => { return listId != id })
            dispatch(allActions.packOrderActions.changeSelectedItems(newSelectedItems));
            if (newSelectedItems.length > 1) {
                resetBoxSide()
            }
        }
    }
    const itemQuantityMultiBoxDisableCheck = () => {
        let disable = false
        itemQty.forEach((item: IitemQty) => {
            if (selectedItems.indexOf(item.id) > -1) {
                if (Number(item.toPack) > 1) {
                    disable = true
                }
            }
        })
        props.setdisableMultiBox(disable);
        if (disable) {
            resetBoxSide()
        }
    }
    const changeQuantity = (event: any, id: string) => {
        let newQuantity: IitemQty[] = [...itemQty]
        newQuantity.filter((qty) => { return qty.id.toString() == id.toString() })[0].toPack = event.target.value;
        dispatch(allActions.packOrderActions.changeItemQuantity(newQuantity));
    }
    const changeResidential = () => {
        let changeIsResidential = !isResidential
        dispatch(allActions.packOrderActions.changeResidential(changeIsResidential));
    }
    const changeShipDate = (shipDate: any) => {
        dispatch(allActions.packOrderActions.changeShipDate(shipDate));
    }
    const resetBoxSide = () => {
        dispatch(allActions.packOrderActions.initilizeBoxCharacteristicList(Constants.boxCharacteristic));
        if (isResidential == false) {
            changeResidential()
        }
        changeShipDate(new Date())
    }

    const closeImageViewer = () => {
        setImagePopUp(false);
        setImageData({} as IImageViewerDetail);
    }

    let imageViewerSettings: IImageViewerPopupSettings = {
        open: imagePopUp,
        imageDetail: imageData,
        close: closeImageViewer
    }

    const imageViewer = (item: any) => {
        let imageData: IImageViewerDetail = {
            imageURL: item.ImageUrl,
            itemDesc: item.ItemDesc,
            itemID: item.OrderLine?.ItemDetails?.Extn?.ExtnPOSItemID ?? "-",
            skuID: item.ItemID,
            price: item.UnitPrice,
            qty: item.Quantity
        }

        setImagePopUp(true);
        setImageData(imageData);
    }

    React.useEffect(() => {
        itemQuantityMultiBoxDisableCheck()
        return () => {
        }
    }, [selectedItems, itemQty]);
    const webView = () => {
        return (
            <div className="backdrop">
                <div className="mainContainer" style={{ marginBottom: 5, display: getTotalQtyToPack() == 0 && props.loadingOrderLines == false ? "none" : "block" }}>
                    <div style={{ boxShadow: "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)" }}>
                        <Accordion expanded={expandedUnPacked} onChange={() => handleChangeUnPacked()}>
                            <AccordionSummary aria-controls="panel1d-content">
                                <Typography>Un-Packaged Items</Typography>
                            </AccordionSummary>
                            <AccordionDetails style={{ display: "block" }}>
                                <Grid
                                    container
                                    className="headerRow"
                                    style={{ paddingLeft: 5 }}
                                >
                                    <Grid item xs={3} sm={3} style={{}}>
                                        <Typography
                                            sx={{ fontSize: 15 }}
                                            color="text.secondary"
                                            gutterBottom
                                        >
                                            Item Description
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={7} sm={7} style={{}}>
                                        <Grid container>
                                            <Grid item xs={4} sm={4}>
                                                <Typography
                                                    sx={{ fontSize: 14 }}
                                                    color="text.secondary"
                                                    gutterBottom
                                                >
                                                    Qty to Pack: <b style={{ color: "black" }}>{getTotalQtyToPack()}</b>
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={2}
                                        sm={2}
                                        style={{ textAlign: "right" }}
                                    >

                                    </Grid>
                                </Grid>
                                <Divider></Divider>
                                {props.loadingOrderLines == false ?
                                    <React.Fragment>
                                        {
                                            unpackagedItemsList.ShipmentLines.ShipmentLine.map((item: ShipmentLine2) => {
                                                let qtyOfItem: IitemQty = itemQty.filter((qty: any) => { return qty.id == item.ShipmentLineKey })[0]
                                                let qtyToPack = qtyOfItem.toPack
                                                return (
                                                    <React.Fragment key={item.ShipmentLineKey}>
                                                        <Grid container spacing={2} display={"flex"} style={{ display: Number(item.QtyAvailableToPack) > 0 ? "block" : "none" }}>
                                                            <Grid item xs={12} sm={12} className='textLeft'>
                                                                <Grid spacing={2} container style={{ margin: "0px" }} sx={{ flexGrow: 1 }}>
                                                                    <Grid item xs={2} sm={2} style={{ padding: "8px", borderRight: "1px solid #2f2d2d", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                                        {Utils.disableUserAction(props.store) && <Checkbox
                                                                            onChange={(e: any) => changeSelectedItem(e, item.ShipmentLineKey)}
                                                                            checked={selectedItems.indexOf(item.ShipmentLineKey) > -1 ? true : false}
                                                                            style={{
                                                                                color: "#D93A2F",
                                                                                maxHeight: "40px",
                                                                            }} />}
                                                                        <div className="image-zoom" onClick={() => imageViewer(item)}>
                                                                            <img style={{ height: "auto", width: "100%" }} alt="" src={Utils.convertToHttps(item.ImageUrl)} />
                                                                        </div>
                                                                    </Grid>
                                                                    <Grid item xs={10} sm={10} style={{ padding: "8px", textAlign: "left" }}>
                                                                        {Utils.disableUserAction(props.store) && <Tooltip title="Cancel Item">
                                                                            <IconButton onClick={() => { showCancelPopup(item) }}
                                                                                style={{ paddingTop: 0, paddingBottom: 0, position: "absolute", right: "10px" }}
                                                                            >
                                                                                <CancelIcon></CancelIcon>
                                                                            </IconButton>
                                                                        </Tooltip>}
                                                                        {
                                                                            item.KitCode != undefined && item.KitCode != "" ?
                                                                                <Chip
                                                                                    className="chipTracking"
                                                                                    label={item.KitCode}
                                                                                    style={{
                                                                                        background: "#dc2b2b",
                                                                                        color: "white",
                                                                                        fontSize: "11px",
                                                                                        height: "20px"
                                                                                    }}
                                                                                />
                                                                                :
                                                                                ""
                                                                        }
                                                                        <Typography style={{ fontSize: "15px", fontWeight: 500, color: "#D93A2F" }}>{item.ItemDesc}</Typography>
                                                                        <Grid container>
                                                                            <Grid item xs={6} sm={6}>
                                                                                <Typography variant="body2" sx={{ fontSize: 15, marginTop: 1 }} gutterBottom>
                                                                                    Item #: <b>{item.OrderLine?.ItemDetails?.Extn?.ExtnPOSItemID ? item.OrderLine?.ItemDetails?.Extn?.ExtnPOSItemID : '-'}</b>
                                                                                </Typography>
                                                                                <Typography variant="body2" sx={{ fontSize: 15 }} gutterBottom>
                                                                                    Serial #: <b>{item?.SerialNumber ? item.SerialNumber : ""}</b>
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid item xs={3} sm={3}>
                                                                                <Typography variant="body2" sx={{ fontSize: 15 }} gutterBottom>
                                                                                    Price: <b>$ {item.UnitPrice}</b>
                                                                                </Typography>
                                                                                <Typography variant="body2" sx={{ fontSize: 15, marginTop: 1 }} gutterBottom>
                                                                                    Brand #: <b>{item.OrderLine?.ItemDetails?.Extn?.ExtnBrandName ? item.OrderLine?.ItemDetails?.Extn?.ExtnBrandName : '-'}</b>
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid item xs={3} sm={3}>
                                                                                <Typography variant="body2" sx={{ fontSize: 15, marginTop: 1 }} gutterBottom>
                                                                                    {/* QTY to Ship: <b>{qtyToShip}</b> */}
                                                                                    QTY to Ship: <b>{Number(item.QtyAvailableToPack)}</b>
                                                                                </Typography>
                                                                                <div style={{ display: "flex" }}>
                                                                                    <Typography variant="body2" sx={{ fontSize: 15, marginTop: 1.5 }} gutterBottom>
                                                                                        QTY to Pack:
                                                                                    </Typography>
                                                                                    <TextField id="order"
                                                                                        className="remove-buttons"
                                                                                        onChange={(event: any) => changeQuantity(event, item.ShipmentLineKey)}
                                                                                        value={Number(qtyToPack)}
                                                                                        type={"number"}
                                                                                        inputProps={{ style: { padding: "0px !important" } }}
                                                                                        InputProps={{ inputProps: { min: 1, max: Number(item.QtyAvailableToPack) }, readOnly: false, style: { padding: "0px !important" } }}
                                                                                        style={{ marginLeft: "5px", width: "35%", padding: "0px !importnat" }}
                                                                                    />
                                                                                </div>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Divider></Divider>
                                                    </React.Fragment>
                                                );
                                            })
                                        }
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                        <OrderLineShimmer></OrderLineShimmer>
                                    </React.Fragment>
                                }
                            </AccordionDetails>
                        </Accordion>

                    </div>
                </div>
            </div>
        );
    }
    const mobileView = () => {
        return (
            <Card style={{ marginTop: "6px", display: getTotalQtyToPack() == 0 && props.loadingOrderLines == false ? "none" : "block" }} id="cardOrder">
                <CardContent style={{ padding: "1px" }}>
                    <div className="backdrop">
                        <div className="title">
                            <Typography>Un-Packaged Items</Typography>
                        </div>
                        <div className="mainContainer" style={{ padding: "5px" }}>
                            {props.loadingOrderLines == false ?
                                <React.Fragment>
                                    {
                                        unpackagedItemsList.ShipmentLines.ShipmentLine.map((item: ShipmentLine2) => {
                                            let qtyOfItem: IitemQty = itemQty.filter((qty: any) => { return qty.id == item.ShipmentLineKey })[0]
                                            let qtyToPack = qtyOfItem.toPack
                                            return (
                                                <Card style={{ margin: "6px", display: Number(item.QtyAvailableToPack) > 0 ? "block" : "none" }}>
                                                    <CardContent style={{ padding: "10px" }}>
                                                        <Grid
                                                            spacing={2}
                                                            container
                                                            style={{ margin: "0px" }}
                                                            sx={{ flexGrow: 1 }}
                                                        >
                                                            <Grid
                                                                item
                                                                xs={3}
                                                                sm={3}
                                                                style={{
                                                                    padding: "6px",
                                                                    borderRight: "1px solid #2f2d2d",
                                                                }}
                                                            >
                                                                {Utils.disableUserAction(props.store) && 
                                                                    <Checkbox
                                                                        onChange={(e: any) => changeSelectedItem(e, item.ShipmentLineKey)}
                                                                        checked={selectedItems.indexOf(item.ShipmentLineKey) > -1 ? true : false}
                                                                        style={{
                                                                            color: "#D93A2F",
                                                                        }} />
                                                                }
                                                                {" "}
                                                                <img alt=""
                                                                    style={{ height: "78px", cursor: "zoom-in" }}
                                                                    src={Utils.convertToHttps(item.ImageUrl)}
                                                                    onClick={() => imageViewer(item)}
                                                                />
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={9}
                                                                sm={9}
                                                                style={{ padding: "8px", textAlign: "left" }}
                                                            >
                                                                <Typography
                                                                    style={{
                                                                        fontSize: "13px",
                                                                        fontWeight: 500,
                                                                        color: "#D93A2F",
                                                                    }}
                                                                >
                                                                    <em>
                                                                        {item.ItemDesc}
                                                                    </em>
                                                                </Typography>
                                                                <Typography style={{ fontSize: "11px" }}>
                                                                    Item #: <b>{item.OrderLine?.ItemDetails?.Extn?.ExtnPOSItemID ? item.OrderLine?.ItemDetails?.Extn?.ExtnPOSItemID : '-'}</b>
                                                                </Typography>
                                                                <Typography style={{ fontSize: "11px" }}>
                                                                    Brand: <b>-</b>
                                                                </Typography>
                                                                <Typography style={{ fontSize: "11px" }}>
                                                                    Serial #: <b>{item?.SerialNumber ? item.SerialNumber : ""}</b>
                                                                </Typography>
                                                                <Typography style={{ fontSize: "11px" }}>
                                                                    Price: <b>$ {item.UnitPrice}</b>
                                                                </Typography>
                                                                <Typography style={{ fontSize: "11px" }}>
                                                                    QTY to Ship: <b>{Number(item.QtyAvailableToPack)}</b>
                                                                </Typography>
                                                                <div style={{ display: "flex" }}>
                                                                    <Typography variant="body2" sx={{ fontSize: 11, marginTop: 1.5 }} gutterBottom>
                                                                        QTY to Pack:
                                                                    </Typography>
                                                                    <TextField id="order"
                                                                        onChange={(event: any) => changeQuantity(event, item.ShipmentLineKey)}
                                                                        value={Number(qtyToPack)}
                                                                        type={"number"}
                                                                        inputProps={{ style: { padding: "0px !important" } }}
                                                                        InputProps={{ inputProps: { min: 1, max: Number(item.QtyAvailableToPack) }, readOnly: false, style: { padding: "0px !important" } }}
                                                                        style={{ marginLeft: "5px", width: "55%", padding: "0px !importnat" }}
                                                                    />
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                        {Utils.disableUserAction(props.store) &&  <Grid container className="rightAlign">
                                                            <Button
                                                                onClick={() => { showCancelPopup(item) }}
                                                                startIcon={<CancelIcon fontSize="small" />}
                                                                className="btn btnMblCancel"
                                                                style={{
                                                                    color: "#ffffff",
                                                                    border: "1px solid #D93A2F",
                                                                    width: "100%",
                                                                }}
                                                                variant="contained"
                                                            >
                                                                Cancel Line
                                                            </Button>
                                                        </Grid>}
                                                    </CardContent>
                                                </Card>
                                            );
                                        })
                                    }
                                </React.Fragment>

                                :
                                <OrderLineShimmerMobile></OrderLineShimmerMobile>
                            }

                        </div>
                    </div>
                </CardContent>
            </Card>
        );
    }
    return (
        <React.Fragment>
            {Utils.isMobile ? mobileView() : webView()}
            {cancelReason.showModal ? <CancelLine getCancelReasonValues={getCancelReasonValues} isExternal={false}></CancelLine> : ""}
            <ImageViewerPopup {...imageViewerSettings}>
            </ImageViewerPopup>
        </React.Fragment>
    );
}
import { react } from "@babel/types";
import { Card, CardContent, Grid, Tooltip, Typography } from "@mui/material";
import ChartShimmer from "../Shared/ChartShimmer";
import OrderTimeChart from "./OrderTimeChart";
import { OrderTimeGrid } from "./OrderTimeGrid";
import { useEffect, useState } from "react";
import SingleStoreProcessTime from "./SingleStoreProcessTime";
import Constants from "../../Common/Constants";
import { CardGiftcardIcon, InfoOutlinedIcon, LocalShippingIcon, ShoppingCart } from "../Shared/SharedIcons";
import Utils from "../../Common/Utils";
import DataNotFound from "../Shared/DataNotFound";

const OrderProcessingTime = (props: any) => {
    const { isLoaded, data } = props
    const [showOneStoreDisplay, setShowOneStoreDisplay] = useState(false);

    const distListData = data.districtOrderTimeData;


    useEffect(() => {
        if (distListData) {
            if (distListData[0]?.orderTimeStoresData.length == 1) {
                setShowOneStoreDisplay(true)
            } else {
                setShowOneStoreDisplay(false)
            }

        }
    }, [distListData, isLoaded])

    const getDaysAndHours = (ms: number) => {
        const obj = Utils.convertHoursToDays(ms)

        if (obj.Days && obj.Hours) {
            return `${obj.Days}(days) - ${obj.Hours}(hrs)`
        }
        if (obj.Days) {
            return `${obj.Days}(days) `
        }
        if (obj.Hours) {
            return `${obj.Hours}(hrs) `
        }
        if (obj.Minutes) {
            return `${obj.Minutes}(mins) `
        }
        if (obj.Sec) {
            return `${obj.Sec}sec(s) `
        } else { return `-` }
    }

    return (
        <>
            {<Grid container>
                <Grid item xs={12} style={{ paddingTop: 5 }}>
                    <Card className='dCard' style={{ height: "auto" }}>
                        <CardContent className='dCardContent'>
                            <div className='dCardHeader' style={{ justifyContent: "center" }}>
                                <div className='header'>
                                    <Typography variant="h5" gutterBottom className='number' style={{ marginTop: 0, cursor: "pointer", display: "flex", alignItems: "center" }}>
                                        {"Order Processing Time"}
                                        <Tooltip title={<h3 style={{ whiteSpace: 'pre-line' }}>{Utils.getToolTipValue("OrderProcessingTime") ? Utils.getToolTipValue("OrderProcessingTime") : ''}</h3>}>
                                            <InfoOutlinedIcon style={{ color: Constants.Colors.blue, marginLeft: "5px" }} />
                                        </Tooltip>
                                    </Typography>
                                </div>
                            </div>

                            {/* {For Multiple Store} */}

                            {!showOneStoreDisplay && <Grid container>
                                <Grid item xs={12} sm={4} style={{ paddingRight: 5 }}>
                                    <Card>
                                        <CardContent style={{ height: "350px", overflowY: "scroll", padding: 5 }}>
                                            {!isLoaded && <ChartShimmer></ChartShimmer>}
                                            {isLoaded && <OrderTimeChart isLoaded={isLoaded} data={distListData} />}
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={8} style={{ paddingLeft: 5 }}>
                                    <Card>
                                        <CardContent style={{ height: "350px", overflowY: "scroll", padding: 5 }}>
                                            {!isLoaded && <ChartShimmer></ChartShimmer>}
                                            {isLoaded && distListData && <OrderTimeGrid isLoaded={isLoaded} data={distListData}></OrderTimeGrid>}
                                            {(isLoaded && !distListData) && <DataNotFound></DataNotFound>}
                                        </CardContent>
                                    </Card>

                                </Grid>
                            </Grid>}

                            {/* {For Single Store} */}

                            {showOneStoreDisplay ? <Grid container>
                                <Grid item xs={4} sm={4} style={{ paddingRight: 5 }}>
                                    <Card>
                                        <CardContent>
                                            {isLoaded && <Grid item sm={12} xs={12}>
                                                <Card className='dCard' style={{ backgroundColor: "#eee", height: "90%" }}>
                                                    <CardContent className='dCardContent' style={{ padding: 3 }}>
                                                        <Grid container alignItems="center" minHeight={105}>
                                                            <Grid item sm={12} xs={12}>
                                                                {<Grid container style={{ display: "flex", justifyContent: "center" }}>
                                                                    <Grid item sm={4} xs={4} style={{ display: "grid", alignItems: "center", justifyItems: "end" }}>
                                                                        <div className="grid-icons" style={{ backgroundColor: Constants.Colors.green }}  >
                                                                            <ShoppingCart />
                                                                        </div>
                                                                    </Grid>
                                                                    <Grid item sm={7} xs={7} style={{ display: "grid", justifyItems: "start" }}>
                                                                        <div style={{ display: "flex", alignItems: "flex-end", width: "100%" }}>
                                                                            <p style={{ width: "100%" }} className="grid-header-font">Picking</p>

                                                                        </div>
                                                                        <p style={{ width: "100%" }} className="grid-value-font-reports">
                                                                            {getDaysAndHours(distListData[0]?.orderTimeStoresData[0].pick)}
                                                                        </p>
                                                                    </Grid>
                                                                </Grid>}
                                                            </Grid>
                                                        </Grid>
                                                    </CardContent>
                                                </Card>
                                            </Grid>}
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={4} sm={4} style={{ paddingRight: 5 }}>
                                    <Card>
                                        <CardContent>
                                            {isLoaded && <Grid item sm={12} xs={12}>
                                                <Card className='dCard' style={{ backgroundColor: "#eee", height: "90%" }}>
                                                    <CardContent className='dCardContent' style={{ padding: 3 }}>
                                                        <Grid container alignItems="center" minHeight={105}>
                                                            <Grid item sm={12} xs={12}>
                                                                {<Grid container style={{ display: "flex", justifyContent: "center" }}>
                                                                    <Grid item sm={4} xs={4} style={{ display: "grid", alignItems: "center", justifyItems: "end" }}>
                                                                        <div className="grid-icons" style={{ backgroundColor: Constants.Colors.green }}  >
                                                                            <CardGiftcardIcon />
                                                                        </div>
                                                                    </Grid>
                                                                    <Grid item sm={7} xs={7} style={{ display: "grid", justifyItems: "start" }}>
                                                                        <div style={{ display: "flex", alignItems: "flex-end", width: "100%" }}>
                                                                            <p style={{ width: "100%" }} className="grid-header-font">Packing</p>

                                                                        </div>
                                                                        <p style={{ width: "100%" }} className="grid-value-font-reports">
                                                                            {getDaysAndHours(distListData[0]?.orderTimeStoresData[0].pack)}
                                                                        </p>
                                                                    </Grid>
                                                                </Grid>}
                                                            </Grid>
                                                        </Grid>
                                                    </CardContent>
                                                </Card>
                                            </Grid>}
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={4} sm={4} style={{ paddingRight: 5 }}>
                                    <Card>
                                        <CardContent>
                                            {isLoaded && <Grid item sm={12} xs={12}>
                                                <Card className='dCard' style={{ backgroundColor: "#eee", height: "90%" }}>
                                                    <CardContent className='dCardContent' style={{ padding: 3 }}>
                                                        <Grid container alignItems="center" minHeight={105}>
                                                            <Grid item sm={12} xs={12}>
                                                                {<Grid container style={{ display: "flex", justifyContent: "center" }}>
                                                                    <Grid item sm={4} xs={4} style={{ display: "grid", alignItems: "center", justifyItems: "end" }}>
                                                                        <div className="grid-icons" style={{ backgroundColor: Constants.Colors.green }}  >
                                                                            <LocalShippingIcon />
                                                                        </div>
                                                                    </Grid>
                                                                    <Grid item sm={7} xs={7} style={{ display: "grid", justifyItems: "start" }}>
                                                                        <div style={{ display: "flex", alignItems: "flex-end", width: "100%" }}>
                                                                            <p style={{ width: "100%" }} className="grid-header-font">Shipping</p>

                                                                        </div>
                                                                        <p style={{ width: "100%" }} className="grid-value-font-reports">
                                                                            {getDaysAndHours(distListData[0]?.orderTimeStoresData[0].ship)}
                                                                        </p>
                                                                    </Grid>
                                                                </Grid>}
                                                            </Grid>
                                                        </Grid>
                                                    </CardContent>
                                                </Card>
                                            </Grid>}
                                        </CardContent>
                                    </Card>

                                </Grid>

                            </Grid> : <></>
                            }

                        </CardContent>
                    </Card>
                </Grid>
            </Grid>}
        </>
    )
}

export default OrderProcessingTime;
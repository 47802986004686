import Constants from "../Common/Constants";
import IdentityManager from "./Identity.manager";
import ApiService from "../Common/ApiService"
import ApiURLS from "../Common/ApiURLS";
import jwt from 'jwt-decode';
import IStoreInfo from "../Models/IStoreInfo"
import DummyDataManager from "./DummyDataManager";
import Utils from "../Common/Utils";
export default class StoreManager {

    public static config: any = {};
    public static DCList: any[] = [];
    public static storeList: any[] = [];
    public static currentStore = {} as IStoreInfo;
    public static allStoredata: IStoreInfo[] = [];


    public static getConfigs = async () => {
        await ApiService.nomGet(ApiURLS.Index.config.URL)
            .then(async (value: any) => {
                StoreManager.config = value.data;
                console.log("StoreManager.config", StoreManager.config);
            })
            .catch(error => console.log("/config", error));
    }

    public static getStoreList = async () => {
        let token = IdentityManager.AccessToken;
        let _res = await fetch(`/store/storeList`, { headers: { 'Authorization': `Bearer ${token}` } })
        if (_res.status !== 500 && _res.status !== 400) {
            let _result = await _res.json();
            if (_result.length > 0) {
                let storeList: any[] = _result;
                StoreManager.storeList = storeList.sort((a: any, b: any) => a.id > b.id ? 1 : -1);
            }
        }
    }

    public static getStoreDetails = async (storeId: string) => {
        let token = IdentityManager.AccessToken;
        let _res = await fetch(`/store/storeInformation/${storeId}`, { headers: { 'Authorization': `Bearer ${token}` } })
        let _result = await _res.json();
        let result = Constants.SHIP_DETAILS;
        if (_result.length > 0) {
            result = _result[0]
            result['Address2'] = "";
            result.Fax = result.Fax !== null && result.Fax !== undefined ? result.Fax.replace(/[^0-9]/g, '') : '';
            result.Phone = result.Phone !== null && result.Phone !== undefined ? result.Phone.replace(/[^0-9]/g, '') : '';
        }
        return result;
    }


    public static getDCList = async () => {
        let token = IdentityManager.AccessToken;
        let _res = await fetch(`/store/DCList`, { headers: { 'Authorization': `Bearer ${token}` } })
        if (_res.status !== 500 && _res.status !== 400) {
            let _result = await _res.json();
            if (_result.length > 0) {
                _result.forEach((data: any) => {
                    data['Address'] = data.Address1 !== undefined && data.Address1 !== null ? data.Address1 : "";
                    data['Address2'] = "";
                    data.Fax = data.Fax !== undefined && data.Fax !== null ? data.Fax.replace(/[^0-9]/g, '') : "";
                    data.Phone = data.Phone !== undefined && data.Phone !== null ? data.Phone.replace(/[^0-9]/g, '') : "";
                    data.id = data.StoreID !== undefined && data.StoreID !== null ? data.StoreID : "";
                });
                StoreManager.DCList = _result.sort((a: any, b: any) => a.StoreID > b.StoreID ? 1 : -1);
            }
        }
    }

    public static getAllSCStores = async () => {
        let stores: any = [];
        if (StoreManager.storeList.length == 0 || StoreManager.allStoredata.length == 0) {
            let _result = await ApiService.get(ApiURLS.Store.getAllSCStores.URL)

            if (_result.success) {
                if (_result.data.Stores != undefined && _result.data.Stores.length > 0) {
                    _result.data.Stores.forEach((data: any) => {
                        data['Address'] = data.Address1 !== undefined && data.Address1 !== null ? data.Address1 : "";
                        data.Address2 = data.Address2 !== undefined && data.Address2 !== null ? data.Address2 : "";
                        data.Fax = data.Fax !== undefined && data.Fax !== null ? data.Fax.replace(/[^0-9]/g, '') : "";
                        data.Phone = data.Phone !== undefined && data.Phone !== null ? data.Phone.replace(/[^0-9]/g, '') : "";
                        data.Zip = data.ZIP !== undefined && data.ZIP !== null ? data.ZIP : "";
                        data.id = data.StoreId !== undefined && data.StoreId !== null ? data.StoreId : "";
                        data.StoreEmail = data.StoreEmail !== undefined && data.StoreEmail !== null ? data.StoreEmail : "";
                    });
                    _result.data.Stores = _result.data.Stores.filter((s: any) => s.Company != "MAA");
                    if (IdentityManager.isRvp) {
                        const employeeId = IdentityManager.getUserProperty(Constants.EmployeeID)?.toString().padStart(6, "0")
                        _result.data.Stores = StoreManager.getUpdatedStoreDMRVP(_result.data.Stores)
                        _result.data.Stores = _result.data.Stores.filter((store: any) => store.RMId === employeeId)
                    }
                    else if (IdentityManager.isDM) {
                        const employeeId = IdentityManager.getUserProperty(Constants.EmployeeID)?.toString().padStart(6, "0")
                        _result.data.Stores = StoreManager.getUpdatedStoreDMRVP(_result.data.Stores)
                        _result.data.Stores = _result.data.Stores.filter((store: any) => store.DMId === employeeId)
                    }
                    StoreManager.storeList = _result.data.Stores.map((r: any) => ({ id: r.StoreId.toString(), name: r.City, districtId: r.DistrictId })).sort((a: any, b: any) => a.id > b.id ? 1 : -1);
                    StoreManager.allStoredata = _result.data.Stores.sort((a: any, b: any) => a.StoreID > b.StoreID ? 1 : -1);
                    stores = StoreManager.storeList;
                }
            }
            else {
                console.log("Error: getAllSCStores=" + _result.msg)
            }
        }
        else {
            stores = StoreManager.storeList;
        }

        return stores;
    }
    public static getUpdatedStoreDMRVP(storeList: any) {
        const updatedDMRVPList = JSON.parse(Utils.getConfigurationWithKey(Constants.Configurations.StoreDMRVP))
        updatedDMRVPList.forEach((DRP: any) => {
            for (let i = 0; i < storeList.length; i++) {
                if (DRP.DistrictID === storeList[i].DistrictId) {
                    storeList[i].DMId = DRP.DM
                    storeList[i].RMId = DRP.RVP;
                }

            }
        })
        console.log("storeList", storeList);
        return storeList
    }
    public static getDistrictList = () => {
        let districts: any = [];
        StoreManager.allStoredata.map((store: any) => {
            let districtName: string = store.DistrictName;
            if (!districts.some((x: any) => x.id == store.DistrictId) && !districtName?.toLowerCase().includes("store support")) {
                districts.push({
                    id: store.DistrictId,
                    name: store.DistrictName || store.DistrictId,
                    regionId: store.RegionId
                });
            }
        });
        if (districts.length > 0) {
            districts.sort((a: any, b: any) => a.id > b.id ? 1 : -1);
        }
        return districts;
    }

    public static getRegionsList = () => {
        let regions: any = [];
        StoreManager.allStoredata.map((store: any) => {
            let resgionName: string = store.RegionName;
            if (!regions.some((x: any) => x.id == store.RegionId) && !resgionName?.toLowerCase().includes("mgmt")) {
                regions.push({
                    id: store.RegionId,
                    name: store.RegionName || store.DistrictId
                });
            }
        });
        if (regions.length > 0) {
            regions.sort((a: any, b: any) => a.id > b.id ? 1 : -1);
        }
        return regions;
    }

    public static getStorePreFixByStoreId = (storeId: any) => {

        let prefix = "";

        let filter = StoreManager.allStoredata.filter(a => a.StoreId == storeId);
        if (filter && filter.length > 0) {
            if (filter[0].Company == "GC") {
                prefix = "G"
            }
            else {
                prefix = "M"
            }
        }

        return prefix;


    }

    public static getSelectedStoreDetails = (inputDropdownSelectedStore: any) => {

        let response = null;
        let store = IdentityManager.getStoreId(inputDropdownSelectedStore);
        if (store) {
            let filter = StoreManager.allStoredata.filter(a => a.StoreId == store);
            if (filter && filter.length > 0) {
                response = filter[0];
            }
        }

        return response;

    }
}
import { CHANGE_CUSTOMER_DETAIL, CHANGE_ORDER_DETAIL, CHANGE_COMMON_CODE_LIST, CHANGE_SELECTED_SHIPMENTLINEKEY, CHANGE_SCANNED_SHIPMENTLINES } from "../ActionTypes";
const initialState = {
    // orderLines: [],
    customerDetail: {},
    orderDetail: {},
    selectedShipmentLineKey: "",
    scanedShipmentLines: [],
}
const PickOrderReducer = (state: any = initialState,
    action: { type: any; payLoad: any }) => {
    let clone = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        // case CHANGE_ORDER_LINES:
        //     return {
        //         ...state,
        //         orderLines: action.payLoad
        //     }
        case CHANGE_CUSTOMER_DETAIL:
            return {
                ...state,
                customerDetail: action.payLoad
            }
        case CHANGE_COMMON_CODE_LIST:
            return {
                ...state,
                commonCodeReasons: action.payLoad
            }
        case CHANGE_SELECTED_SHIPMENTLINEKEY:
            return {
                ...state,
                selectedShipmentLineKey: action.payLoad
            }

        case CHANGE_ORDER_DETAIL:
            return {
                ...state,
                orderDetail: action.payLoad
            }
        case CHANGE_SCANNED_SHIPMENTLINES:
            return {
                ...state,
                scanedShipmentLines: action.payLoad
            }
    }
    return state;
}

export default PickOrderReducer;
import { Card, CardContent, Dialog, DialogContent, DialogTitle, Divider, IconButton, Typography } from "@mui/material";
import { Slide } from '@material-ui/core';
import { TransitionProps } from "@mui/material/transitions";
import React from "react";
import DataTable from "react-data-table-component";
import { CloseIcon } from "../Shared/SharedIcons";
import IdentityManager from "../../Managers/Identity.manager";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const ResourceOrderDataGrid = (props: any) => {
    const { isLoaded, data } = props;
    const distList = data.districtROData;



    const close = () => {
        props.close()
    }

    const getColumns = () => {
        const columns = [
            {
                id: "district",
                name: 'District',
                sortable: true,
                cell: (row: any) => row.district
            },
            {
                id: "totalResourceOrder",
                name: 'Total Resource Order',
                sortable: true,
                selector: (row: any) => row.totalResourceOrderCount
            }
        ];
        return columns;
    }

    const getExpandedColumns = () => {
        const columns = [
            {
                id: "store",
                name: 'Store',
                sortable: true,
                cell: (row: any) => row.storeName
            },
            {
                id: "send",
                name: 'Total Resource Order',
                sortable: true,
                selector: (row: any) => row.storeTotalResourceOrderCount
            }
        ];
        return columns;
    }

    const getUpperCaseReason = (name: any) => {
        switch (name) {

            case "storeFloorModelReasonCount":
                return "Floor Model"
            case 'storeBlemishedReasonCount':
                return "Blemished"
            case 'storeOpenBoxReasonCount':
                return "Open Box"
            case 'storeDamagedReasonCount':
                return "Damaged "
            case "storeMissingPartsReasonCount":
                return "Missing Parts"
            case "storeDemoReasonCount":
                return "Demo"
            case "storeCustomerCommittedReasonCount":
                return "Customer Committed"
            case "storeCannotLocateReasonCount":
                return "Cannot Locate"
        }
    }

    const getExpandedReasonColumns = () => {
        const columns = [
            {
                id: "reason",
                name: 'Reason',
                sortable: true,
                cell: (row: any) => getUpperCaseReason(row.name)
            },
            {
                id: "count",
                name: 'Count',
                sortable: true,
                cell: (row: any) => row.count
            },

        ];
        return columns;
    }

    const expandedRows = (props: any) => {
        const { data } = props

        return (
            <>
                {<Card id='cardOrder' style={{ margin: "5px", width: "98%", height: "98%" }}>
                    <CardContent style={{ padding: 3 }}>
                        <div className="dataTableDashboardExpanded">
                            <DataTable
                                title=""
                                className=''
                                data={data.roStoresData}
                                columns={getExpandedColumns()}
                                expandableRows
                                expandableRowsComponent={expandedRowsForReason}
                            />
                        </div >
                    </CardContent >
                </Card >}
            </>

        );
    }

    const expandedRowsForReason = (props: any) => {
        const { data } = props;
        let resourceReasonArray = []
        for (let i = 0; i < Object.keys(data).length; i++) {
            if (Object.keys(data)[i] != 'storeName' && Object.keys(data)[i] != 'storeId' && Object.keys(data)[i] != "storeTotalResourceOrderCount") {

                resourceReasonArray.push({ ['name']: Object.keys(data)[i], ['count']: Object.values(data)[i] })
            }

        }


        return (
            <>
                {<Card id='cardOrder' style={{ margin: "5px", width: "98%", height: "98%" }}>
                    <CardContent style={{ padding: 3 }}>
                        <div className="dataTableDashboardExpanded">
                            <DataTable
                                title=""
                                className=''
                                data={resourceReasonArray}
                                columns={getExpandedReasonColumns()}
                            />
                        </div >
                    </CardContent >
                </Card >}
            </>

        );
    }


    return (
        <Dialog disableEnforceFocus open={props.open} fullWidth maxWidth={"md"} TransitionComponent={Transition}>
            <DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={() => { close() }}
                    sx={{
                        position: 'absolute',
                        right: 0,
                        top: 0,
                        color: "#D93A2F",
                    }}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Typography style={{ display: "flex", justifyContent: "center", marginBottom: "5px", color: "#D93A2F", fontWeight: 500 }} variant="h5">Resource Order Summary</Typography>
                {
                    isLoaded && data?.districtROData ? <div className='dataTableRounded'>
                        <DataTable
                            className='dataTableRounded'
                            title=""
                            data={distList}
                            columns={getColumns()}
                            expandableRows
                            expandableRowsComponent={expandedRows}

                        />
                    </div>
                        :
                        <Card id='cardOrder' style={{ margin: "5px", width: "98%", height: "98%" }}>
                            <CardContent style={{ padding: 3 }}>
                                <div className="dataTableDashboardExpanded">
                                    <DataTable
                                        title=""
                                        className=''
                                        data={props.data[0] && props.data[0].roStoresData ? props.data[0].roStoresData : []}
                                        columns={getExpandedColumns()}

                                    />
                                </div >
                            </CardContent >
                        </Card >
                }
            </DialogContent>
        </Dialog>
    );
}
import { Navigate, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { textAlign } from '@mui/system';
import { Typography } from '@material-ui/core';
import StoreManager from '../../Managers/Store.manager';
import allActions from '../../Store/Actions/AllActions';
import ApiService from '../../Common/ApiService'
import PdfIframe from "../../Subcomponent/PickOrder/PdfIframe";
import Constants from '../../Common/Constants';
import { Card, CardContent, Grid } from '@mui/material';
import Utils from '../../Common/Utils';
import OMSManager from '../../Managers/Oms.manager';

const ExpandedRow = (props: any) => {

    const renderCategory = (item: any) => {

        let cat: any = "";
        cat = item?.ItemDetails?.CategoryList?.Category?.ParentCategoryID;
        if (cat && cat.length > 0) {

            if (OMSManager.catalogCategories && OMSManager.catalogCategories.Category && OMSManager.catalogCategories.Category.length > 0) {
                let fCategory: any = OMSManager.catalogCategories.Category.filter(a => a.CategoryID == cat);
                if (fCategory && fCategory.length > 0) {
                    cat = fCategory[0].ShortDescription;
                }
            }
        }

        return cat == null || cat == undefined ? "-" : cat;

    }

    const renderItemID = (item: any) => {
        let itemID:any = item.ItemDetails?.Extn?.ExtnPOSItemID;
        return itemID? itemID:"-"; 
    }

    const renderExpandedData = () => {
        let shipmentsLinesToRender: any[] = [];

        props.data._source.Shipment.ShipmentLines.ShipmentLine.forEach((element: any) => {
            if (element.MaxLineStatusDesc != "Backordered" && element.ExtnIsWarrantyItem == "N" && element.MinLineStatusDesc != "Cancelled" && element.BundleParentOrderLineKey == undefined) {
                shipmentsLinesToRender.push(element);
            }
        });

        console.log(props, "ExpandedRowProps");
        return <div style={{ paddingLeft: props.pickTab ? "6.5%" : "4%", paddingTop: "10px", paddingBottom: "10px" }}>
            {

                shipmentsLinesToRender.map((item: any) => {

                    return <Card id='cardOrder' style={{ background: "#eee", marginTop: "8px" }}>
                        <CardContent style={{ padding: "10px" }}>
                            <Grid container style={{ margin: "0px" }} sx={{}}>
                                <Grid item xs={12} sm={2} style={{ textAlign: "left" }}>
                                    <Typography style={{ fontSize: "12px" }}>ItemID : <b>{renderItemID(item)}</b></Typography>

                                </Grid>
                                <Grid item xs={12} sm={1} style={{ textAlign: "left" }}>
                                    <Typography style={{ fontSize: "12px" }}>Qty: <b>{item.OrderedQty}</b></Typography>
                                </Grid>


                                <Grid item xs={12} sm={2} style={{ textAlign: "left" }}>
                                    <Typography style={{ fontSize: "12px" }}>Category: <b>{renderCategory(item)}</b></Typography>
                                </Grid>
                                <Grid item xs={12} sm={1} style={{ textAlign: "left" }}>
                                    <Typography style={{ fontSize: "12px" }}>Price: <b>${item.UnitPrice}</b></Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} style={{ textAlign: "left" }}>
                                    <Typography style={{ fontSize: "13px", fontWeight: 500, color: "#D93A2F" }}>{item.ItemDesc}</Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>

                })
            }
        </div>

    }
    return (
        <React.Fragment>
            {renderExpandedData()}
        </React.Fragment>
    )

}

export default ExpandedRow;
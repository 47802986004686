import moment from 'moment';
import Constants from "../Common/Constants";
import PFManager from "../Managers/Process.Fulfillment.Manager"
import StoreManager from './Store.manager';
import Utils from '../Common/Utils';

export default class IdentityManager {

    public static userData: any = null;

    public static loadUserData = () => {
        try {
            if (IdentityManager.userData === null)
                IdentityManager.userData = {};

            if (Object.keys(IdentityManager.userData).length == 0) {
                let userInfoString = localStorage.getItem(Constants.USER_PROFILE);
                if (typeof userInfoString == "string") {
                    IdentityManager.userData = JSON.parse(userInfoString);
                } else {
                    IdentityManager.userData = userInfoString;
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    public static get AccessToken(): string | null {
        let AccessToken = null;
        let userInfoString = localStorage.getItem(Constants.USER_PROFILE)
        if (typeof userInfoString == "string") {
            let userInfo: any = JSON.parse(userInfoString);
            AccessToken = userInfo.accessToken;
        }
        return AccessToken;
    }

    public static get isTokenValid(): boolean {
        let result = true;
        let userInfoString = localStorage.getItem(Constants.USER_PROFILE);
        if (typeof userInfoString == "string") {
            let userInfo: any = JSON.parse(userInfoString);
            let currentTime = moment().toDate().getTime();
            let tokenTime = moment(userInfo.expiresOn).toDate().getTime();
            result = currentTime > tokenTime;
        }
        return result;
    }

    public static getUserProperty = (property: string) => {
        let result = "";
        IdentityManager.loadUserData();
        if (IdentityManager.userData) {
            let value: string = IdentityManager.userData[property];
            if (value != "" && value != null && value != undefined) {
                result = value;
            }
        }

        //if (property == Constants.Location) { // remove after testing and
        //    result = "999-test store"
        //}
        if (property == Constants.EmployeeID) {
            const empIdSwitchConfig = Utils.getConfigurationWithKey(Constants.Configurations.EmpIdSwitch)
            if (empIdSwitchConfig) {
                result = IdentityManager.switchEmpId(result, empIdSwitchConfig)
            }
        }
        return result;
    }
    public static switchEmpId = (empId: any, switchConfig: any) => {
        const parsedSwitchConfig = JSON.parse(switchConfig)
        if (parsedSwitchConfig) {
            parsedSwitchConfig.forEach((switchConfig: any) => {
                if (empId === switchConfig.empId) {
                    empId = switchConfig.toSwitch
                }
            });
        }
        return empId
    }
    public static get storeNumber(): string {
        let result = "";
        let value = IdentityManager.getUserProperty(Constants.Location);
        if (value.includes('-')) {
            result = value.split('-')[0];
        }
        else {

            let isNumber = Number(+value);
            if (!(isNaN(isNumber))) {
                result = value;
            }

        }



        return result;
    }
    public static getUserAllGroups = () => {
        let groups: any[] = []
        if (PFManager.groupsRights.length > 0) {
            let value: any = IdentityManager.getUserProperty('groups');
            if (value !== undefined) {
                for (const element of value) {
                    let filter = PFManager.groupsRights.filter(a => a.GroupId == element);
                    if (filter && filter.length > 0) {
                        groups = [...groups, ...filter];
                    }
                }
                groups = groups.sort((n1: any, n2: any) => {
                    if (n1.Precedence > n2.Precedence) {
                        return 1;
                    }

                    if (n1.Precedence < n2.Precedence) {
                        return -1;
                    }

                    return 0;
                });
            }
        }
        return groups;
    }

    public static getUserNavigation = () => {
        let navigations = [];
        let allFoundGroups: any[] = [];
        if (PFManager.groupsRights.length > 0) {
            allFoundGroups = IdentityManager.getUserAllGroups();
            let targetGroup = allFoundGroups.length > 0 ? allFoundGroups[0] : null;
            if (targetGroup) {
                let routesData = PFManager.navigationData.filter(x => x.IsExternal != true).sort(function (a, b) {
                    return a.Position - b.Position;
                })
                for (const element of routesData) {
                    if (targetGroup[element.Route]) {
                        if (targetGroup[element.Route] == "1") {
                            navigations.push(element);
                        }
                    }
                }
            }
        }
        return navigations;
    }

    public static get isAdmin(): boolean {
        let result = false;
        let groups = IdentityManager.getUserAllGroups();
        if (groups.length > 0) {
            let adminGroup = groups.filter(a => a.isAdmin != null && a.isAdmin != undefined && a.isAdmin == "1");
            result = adminGroup.length > 0 ? true : false;
        }
        return result;
    }

    public static get isRvp(): boolean {
        let groups = IdentityManager.getUserAllGroups();
        if (groups.length > 0) {
            return groups.some(a => a.isRvp == "1");
        }
        return false;
    }

    public static get isDM(): boolean {
        let groups = IdentityManager.getUserAllGroups();
        if (groups.length > 0) {
            return groups.some(a => a.isDM == "1");
        }
        return false;
    }

    public static get verifyCredentialsException(): boolean {
        let result = false;
        let groups = IdentityManager.getUserAllGroups();
        if (groups.length > 0) {
            let adminGroup = groups.filter(a => a.BypassVerifyCredentials != null && a.BypassVerifyCredentials != undefined && a.BypassVerifyCredentials == "1");
            result = adminGroup.length > 0 ? true : false;
        }
        return result;
    }

    public static get bypassJobCodeException(): boolean {
        let groups = IdentityManager.getUserAllGroups();
        if (groups.length > 0) {
            return groups.some(a => a.BypassJobCode == "1");
        }
        return false;
    }

    public static get showRegionDropdown(): boolean {
        let groups = IdentityManager.getUserAllGroups();
        if (groups.length > 0) {
            return groups.some(a => a.RegionsDropdown == "1");
        }
        return false;
    }

    public static get showDistrictDropdown(): boolean {
        let groups = IdentityManager.getUserAllGroups();
        if (groups.length > 0) {
            return groups.some(a => a.DistrictsDropdown == "1");
        }
        return false;
    }

    public static get showOrderProcessTime(): boolean {
        let groups = IdentityManager.getUserAllGroups();
        if (groups.length > 0) {
            return groups.some(a => a.ShowOrderProcessTime == "1");
        }
        return false;
    }

    public static get showStoreDropdown(): boolean {
        let groups = IdentityManager.getUserAllGroups();
        if (groups.length > 0) {
            return groups.some(a => a.StoresDropdown == "1");
        }
        return false;
    }

    public static get showDrawerDropdown() {
        return (IdentityManager.showRegionDropdown || IdentityManager.showDistrictDropdown || IdentityManager.showStoreDropdown);
    }

    public static get isEnterprise(): boolean {
        let groups = IdentityManager.getUserAllGroups();
        if (groups.length > 0) {
            return groups.some(a => a.isEnterprise == "1");
        }
        return false;
    }

    public static get isUser(): boolean {
        let result = false;
        let groups = IdentityManager.getUserAllGroups();
        if (groups.length > 0) {
            let adminGroup = groups.filter(a => a.isAdmin != null && a.isAdmin != undefined && a.isAdmin == "1");
            result = adminGroup.length > 0 ? false : true;
        }
        return result;
    }

    public static getStoreId(storesList: any): any {
        let store = undefined
        if (storesList && storesList.length == 1) {
            let selectedStore = storesList[0]
            store = selectedStore ? selectedStore.id : (StoreManager.currentStore.StoreId > 0 ? StoreManager.currentStore.StoreId : IdentityManager.storeNumber);
        }
        else {
            store = StoreManager.currentStore.StoreId > 0 ? StoreManager.currentStore.StoreId : IdentityManager.storeNumber
        }
        return store
    }
    public static getMultipleStoresId = (stores: any) => {
        if (stores && stores.length > 0) {
            let _storesId = stores.map((store: any) => store.id);
            return _storesId;
        }
        let store = StoreManager.currentStore.StoreId > 0 ? StoreManager.currentStore.StoreId : IdentityManager.storeNumber;
        return store ? store.toString().split(" ") : [];
    }
}
import Utils from "../../Common/Utils";
import { SHOW_ACTIONLOADER, HIDE_ACTIONLOADER,SET_CANCEL_REASON,SET_STORES, SET_KCDC_TOTALQUANTITY, SET_ATTACHMENT_IMAGES, SET_DISTRICTS, SET_DRAWER_DROPDOWNS, SET_REGIONS, SET_ENTERPRISE } from "../ActionTypes";
const initialState = {
    show: 0,
    cancelReason: Utils.initCanceReasonState(),
    stores:[],
    districts:[],
    kcdcTotalQuantity: 0,
    attachmentImages:[],
    openDrawerDropdowns: false,
    regions:[],
    isEnterprise:false
}
const ActionLoaderReducer = (state: any = initialState,
    action: { type: any; payLoad: any }) => {
    let clone = JSON.parse(JSON.stringify(state));
    let show = clone.show;
    switch (action.type) {
        case SHOW_ACTIONLOADER:
            return {
                ...state,
                show: show + 1
            }
        case HIDE_ACTIONLOADER:
            return {
                ...state,
                show: show == 0 ? 0 : show - 1
            }
        case SET_CANCEL_REASON:
            return{
                ...state,
                cancelReason:action.payLoad
            }
        case SET_STORES:
            return{
                ...state,
                stores:action.payLoad
            }
        case SET_DISTRICTS:
            return{
                ...state,
                districts:action.payLoad
            }
        case SET_KCDC_TOTALQUANTITY:
            return{
                ...state,
                kcdcTotalQuantity:action.payLoad
            }
        case SET_ATTACHMENT_IMAGES:
            return {
                ...state,
                attachmentImages: action.payLoad
            }
        case SET_DRAWER_DROPDOWNS:
            return {
                ...state,
                openDrawerDropdowns: action.payLoad
            }
        case SET_REGIONS:
            return{
                ...state,
                regions:action.payLoad
            }
        case SET_ENTERPRISE:
            return{
                ...state,
                isEnterprise:action.payLoad
            }
    }

    return state;
}

export default ActionLoaderReducer;
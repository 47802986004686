import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import { Typography, CardContent, Grid } from '@mui/material';
const AddressDetailsShimmerMobile = (props: any) => {
    return (
        <React.Fragment>
            <CardContent style={{ padding: "10px" }}>
                <Grid spacing={2} container style={{ margin: "0px" }} sx={{ flexGrow: 1 }}>
                    <Grid item xs={5} sm={5} style={{ padding: "8px", textAlign: "left" }}>
                        <Typography style={{ fontSize: "13px", fontWeight: 500, color: "#D93A2F" }}><Skeleton animation="wave" /></Typography>
                        <Typography style={{ fontSize: "11px" }}><Skeleton animation="wave" /></Typography>
                        <Typography style={{ fontSize: "11px" }}><Skeleton animation="wave" /></Typography>
                    </Grid>
                </Grid>
                <hr></hr>
                <Grid spacing={2} container style={{ margin: "0px" }} sx={{ flexGrow: 1 }}>
                    <Grid item xs={5} sm={5} style={{ padding: "8px", textAlign: "left" }}>
                        <Typography style={{ fontSize: "13px", fontWeight: 500, color: "#D93A2F" }}><Skeleton animation="wave" /></Typography>
                        <Typography style={{ fontSize: "11px" }}><Skeleton animation="wave" /></Typography>
                        <Typography style={{ fontSize: "11px" }}><Skeleton animation="wave" /></Typography>
                    </Grid>
                </Grid>
            </CardContent>
        </React.Fragment>
    );

}
export default AddressDetailsShimmerMobile;
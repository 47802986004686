
import "./PickOrder.scss";
import { Button, Card, CardContent, Divider, Drawer, Grid, Paper, TextField, Toolbar, Typography, InputAdornment, Box, List, ListItem, ListItemText, Dialog, DialogTitle, DialogContent, SwipeableDrawer } from '@mui/material';
import { Slide } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { TailSpin } from "react-loader-spinner";
import React, { useState } from 'react';
import { TransitionProps } from '@mui/material/transitions';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton } from '@mui/material';
import Utils from '../../Common/Utils';
import { createTheme, makeStyles } from "@material-ui/core/styles";
import MobileBarCodeScanner from '../FulfillOrders/MobileBarCodeScanner';
import MobileBarCodeScannerScandit from '../FulfillOrders/MobileBarCodeScannerScandit';
import ItemAction from './ItemAction';
import { NotificationPopup } from '../Shared/NotificationPopup'
import Constants from '../../Common/Constants';
import { INotificationPopupSettings, IButton } from '../../Models/INotificationPopupSettings';
import { Reasons } from "../Shared/Reasons";
import { ICustomerContact, Item } from '../../Models/ICustomerContact'
import { IReasons, IReasonState } from '../../Models/IReasons';
import { IContactCustomer } from '../../Models/IContactCustomer';
import { ContactCustomer } from '../Shared/ContactCustomer';
import LabelText from "../../Common/LabelText";
import { IVerticalStepper, IStepOption } from '../../Models/IVerticalStepper';
import SiteBreadCrumbs from '../Shared/SiteBreadCrumbs';
import { BallotIcon } from '../Shared/SharedIcons';
import PickSlip from '../FulfillOrders/PickSlip';
import { Note } from "../Shared/Note";
import Tooltip from '@mui/material/Tooltip';
import { ResourceItem } from './ResourceItem';
import OMSManager from "../../Managers/Oms.manager";
import ContactCustomerManager from '../../Managers/ContactCustomer.Manager';
import { PickupPrintSlip } from '../../Subcomponent/CustomerPickup/PickupPrintSlip';
import DummyDataManager from '../../Managers/DummyDataManager';
import { DirectionsCar, ShoppingCart, ApartmentIcon, DoneIcon, CloseIcon, SendIcon, AddCommentIcon, ArrowBackIcon, DocumentScannerIcon, CommentIcon, HighlightOffIcon, PersonIcon, AddLocationAltIcon, KeyboardReturnIcon, EmailIcon, LocalPhoneIcon, CheckIcon, PrintIcon } from "../Shared/SharedIcons"
import PdfIframe from "./PdfIframe";
import StoreManager from "../../Managers/Store.manager";
import { ICustomerDetail } from '../../Models/IPickOrder'
import allActions from "../../Store/Actions/AllActions"
import OrderLineShimmer from "./OrderLineShimmer"
import OrderLineShimmerMobile from "./OrderLineShimmerMobile"
import CustomerDetailShimmer from "./CustomerDetailShimmer"
import CustomerDetailShimmerMobile from "./CustomerDetailShimmerMobile"
import OrderHistory from '../Shared/OrderHistory';
import OrderManager from '../../Managers/Orders.Manager';
import Chip from "@mui/material/Chip";
import { IResourceView } from '../../Models/IResourceView';
import { OmsPickOrderDetail, ShipmentLine } from '../../Models/OmsPickOrderDetail';
import CancelLine from '../Shared/CancelLine';
import logger from '../../Common/logger';
import IdentityManager from '../../Managers/Identity.manager';
import PFManager from '../../Managers/Process.Fulfillment.Manager';
import OrderDetailShimmer from './OrderDetailShimmer';
import OrderDetailMobileShimmer from './OrderDetailMobileShimmer';
import ApiService from '../../Common/ApiService';
import { OrderDetailGrid } from '../Shared/OrderDetailGrid';
import { CustomerDetails } from '../Shared/CustomerDetails';
import { IImageAttachment } from "../../Models/IImageAttachment";
import { IImageViewerDetail, IImageViewerPopupSettings } from "../../Models/IImageViewerPopupSettings";
import { ImageViewerPopup } from "../Shared/ImageViewerPopup";
import POSInventory from "./POSInventory";


var moment = require('moment');

let step: IStepOption[] = []
step.push({ label: 'Order Release', description: ``, time: 'Jan 20 2022 13:11:17 ' })
step.push({ label: 'Customer Contacted', description: "dummy", time: 'Jan 20 2022 13:11:17 ' })
step.push({ label: 'Picking', description: "", time: '' })
step.push({ label: 'Packing', description: ``, time: '' })
step.push({ label: 'Shipped', description: ``, time: '' })
let verticalStepperOptions: IVerticalStepper = {
    activeStep: "Picking",
    stepsOptions: step
}
const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const PickOrder = (props: any) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const shipmentkey = urlParams.get('shipmentkey');
    const orderFromUrl = urlParams.get('orderno');
    const queryParamStore = urlParams.get('store');
    console.log(orderFromUrl, "orderFromUrl_PickOrder");
    const [openDrawer, setDrawer] = useState(false);
    const [openHistoryDrawer, setHistoryDrawer] = useState(false);
    const [isScan, setScan] = useState(false);
    const [pickComplete, setPickComplete] = useState(false);
    const [cancel, setCancel] = useState(false);
    const [resource, setResource] = useState(false);
    const [verifyCredentials, setVerifyCredentials] = useState(false);
    const [dynamicHtml, setDynamicHtml] = useState("");
    const [resourceData, setResourceData] = useState<IResourceView>();
    const [contactCustomer, setContactCustomer] = useState(false);
    const [notes, setNotes] = useState("");
    const [notePopup, setNotesPopup] = useState(false);
    const [openEmailTemplate, setOpenEmailTemplate] = useState(false);
    const [steps, setSteps] = useState<IVerticalStepper>(verticalStepperOptions);
    const [blobUrl, setBlobUrl] = useState("");
    const [showPdf, SetShowPdf] = useState(false);
    const [showPickupSlip, setShowPickupSlip] = useState(0);
    const [printPickupSlipData, setPrintPickupSlipData] = useState({} as any);
    const isHistoryLoading = useSelector((state: any) => state.OrderHistoryReducerState.isLoading);
    let customerDetail: ICustomerDetail = useSelector((state: any) => state.PickOrdersReducersState.customerDetail);

    let orderDetail: OmsPickOrderDetail = useSelector((state: any) => state.PickOrdersReducersState.orderDetail);

    let scanedShipmentLines = useSelector((state: any) => state.PickOrdersReducersState.scanedShipmentLines);
    // cancel line popup
    let cancelReason: IReasonState = useSelector((state: any) => state.ActionLoaderReducersState.cancelReason);
    // contact customer
    let storeEmail = useSelector((state: any) => state.ContactCustomerReducersState.storeEmail);
    let selectedTemplate = useSelector((state: any) => state.ContactCustomerReducersState.selectedTemplate);
    let selectedOrderLines: ICustomerContact = useSelector((state: any) => state.ContactCustomerReducersState.selectedOrderLines);
    let contactCustomerHistory = useSelector((state: any) => state.ContactCustomerReducersState.contactCustomerHistory);
    let customBody = useSelector((state: any) => state.ContactCustomerReducersState.customBody);
    let customSub = useSelector((state: any) => state.ContactCustomerReducersState.customSub);
    let parentContactCustomer = useSelector((state: any) => state.ContactCustomerReducersState.parentContactCustomer);
    const attachmentImages = useSelector((state: any) => state.ActionLoaderReducersState.attachmentImages);
    //
    const [orderNumber, setOrderNumber] = useState("");
    const [loadingOrderLines, setLoadingOrderLines] = useState(true);
    const [isError, setIsError] = useState(false)
    const [errorMsg, setErrorMsg] = useState("Some thing went Wrong");
    const [openError, setOpenError] = useState(false);
    const [skuToScan, setSKUNumberForScan] = useState("");
    const [scannedQty, setScannedQty] = useState("1");
    const [pickedItems, setPickedItems] = useState([] as any);
    const [showLoader, setShowLoader] = useState(false);
    const [openGeneralError, setOpenGeneralError] = useState(false);
    const [openSuccess, setSuccess] = useState(false);
    const [navigationFulfillment, setNavigationFulfillment] = useState(false);
    const [errorGeneralMsg, setErrorGeneralMsg] = useState("");
    const [orderDetails, setOrderDetails] = useState({ fulfillmentType: "", levelOfService: "", pickUpStore: "", storeNumber: "" });
    const [navigateTo, setNavigateTo] = useState("");
    const [imagePopUp, setImagePopUp] = useState(false);
    const [imageData, setImageData] = useState({} as IImageViewerDetail);
    const openLoadingOrderLines = () => {
        setLoadingOrderLines(true)
    }
    const closeLoadingOrderLines = () => {
        setLoadingOrderLines(false)
    }
    const useStyles = makeStyles((theme) => ({
        root: {
            backgroundColor: theme.palette.primary.main
        }
    }));
    const classes = useStyles();
    const theme = createTheme({
        palette: {
            primary: {
                main: '#D93A2F',
            },
            secondary: {
                main: '#FFFFFF',
            },
        },
    })
    const navigateToFulfillment = () => {
        navigate("/FulfillOrders");
    }
    const toPack = () => {
        //dispatch(allActions.fulfillOrdersActions.changeFOTab(2));
        //navigate("/FulfillOrders");
        navigate("/PackOrder?orderno=" + orderNumber + "&shipmentkey=" + shipmentkey + "&store=" + queryParamStore + "");
    }
    const toPickup = () => {
        //dispatch(allActions.fulfillOrdersActions.changeFOTab(2));
        //navigate("/FulfillOrders");
        navigate("/CustomerPickup?orderno=" + orderNumber + "&shipmentkey=" + shipmentkey + "&store=" + queryParamStore + "");
    }
    console.log(classes.root, "asas");
    const TestState = useSelector((state: any) => state.TestReducerState);
    console.log(TestState, "TestState");
    var startProductBarPos = -1;
    window.onscroll = function () {
        console.log("scrolled");
        var bar = document.getElementById('bar') as any;
        var mblBar = document.getElementById('mblBar') as any;
        if (bar) {
            if (startProductBarPos < 0) startProductBarPos = findPosY(mblBar);
            if (window.pageYOffset > startProductBarPos) {
                bar.classList.add("scrolledTopFix");
            } else {
                bar.classList.remove("scrolledTopFix");
            }
        }
    };
    function findPosY(obj: any) {
        var curtop = 0;
        if (obj && typeof (obj.offsetParent) != 'undefined' && obj.offsetParent) {
            while (obj.offsetParent) {
                curtop += obj.offsetTop;
                obj = obj.offsetParent;
            }
            curtop += obj.offsetTop;
        }
        else if (obj && obj.y)
            curtop += obj.y;
        return curtop;
    }
    const showActionLoader = () => {
        dispatch(allActions.actionLoaderActions.showLoader());
    }
    const hideActionLoader = () => {
        dispatch(allActions.actionLoaderActions.hideLoader());
    }
    const openSuccessNotification = (message: any) => {
        dispatch(allActions.genericNotificationActions.showSuccessMessage(message));
    }

    const onScannedComplete = (data: any, isError: any) => {
        //
        if (isError == false) {
            scanItemId();
        }
        setScan(false);
    }
    const closePickComplete = () => {
        setPickComplete(false)
    }
    const closeNotes = () => {
        setNotesPopup(false)
    }
    const openNotes = () => {
        setNotesPopup(true)
        setNotes("")
    }
    const changeNote = (event: any) => {
        setNotes(event.target.value);
    }
    const pickCompleteClick = () => {
        GCPickConfirm();
    }
    const cancelClick = (item: any) => {
        setCancel(true)
    }
    const openNavigationFulfillment = () => {
        setNavigationFulfillment(true);
    }
    const resourceClick = (shipmentKey: any) => {
        dispatch(allActions.pickOrderActions.chagneSelectedShipmentKey(shipmentKey))
        setResource(true);
    }
    const contactCustomerClick = () => {
        if ((props.store == undefined || props.store?.length == 0) && StoreManager.currentStore.StoreId == undefined) {
            showGeneralErrorModel("Please select store");
            return
        }
        else {
            setContactCustomer(true)
        }
    }

    const closeContactCustomer = () => {
        dispatch(allActions.actionLoaderActions.setAttachmentImages([]));
        setContactCustomer(false)
    }

    const validateTemplateParameters = () => {
        let valid = true
        let anyChecked = false
        let errorMessage = ""
        if (StoreManager.currentStore.StoreEmail == "" && storeEmail == "") {
            valid = false
            errorMessage = "Please add store email"
        }
        else if (selectedTemplate.filename == undefined || selectedTemplate.filename == "") {
            valid = false
            errorMessage = "Please select template"

        }
        selectedOrderLines.items.forEach((item: Item) => {
            if (item.selected) {
                anyChecked = true
            }
        })
        if (anyChecked == false && selectedTemplate.filename != Constants.ContactCustomerFileName.FollowUp) {
            valid = false
            errorMessage = "Please check an item"
        }
        if ((selectedTemplate.filename == Constants.ContactCustomerFileName.FollowUp
            || selectedTemplate.filename == Constants.ContactCustomerFileName.Custom)
            && customBody == "") {
            valid = false
            errorMessage = "Please add Body"
        }
        if ((selectedTemplate.filename == Constants.ContactCustomerFileName.FollowUp
            || selectedTemplate.filename == Constants.ContactCustomerFileName.Custom)
            && customSub == "") {
            valid = false
            errorMessage = "Please add Subject"
        }
        if (valid) {
            return true
        }
        else {
            showGeneralErrorModel(errorMessage);
            return false
        }
    }

    const getImageAttachments = () => {
        let attachments: IImageAttachment[] = [];
        let payload = {} as IImageAttachment;
        let fileName = "";
        attachmentImages?.forEach((image: any) => {
            fileName = `${Utils.generateUUID()}.${image?.type?.split('/')[1]}`;
            payload = {
                content: image?.binary?.substring(image?.binary?.indexOf(',') + 1),
                filename: fileName,
                type: image.type,
                path: `${orderFromUrl}/${shipmentkey}/${fileName}`
            }
            attachments.push(payload);
        });
        return attachments;
    }

    const getCustomerContactPayload = () => {
        let payload = JSON.parse(JSON.stringify(selectedOrderLines))
        let ccShipmentLineKeys = [] as any;
        if (payload.storeemail == "") {
            payload.storeemail = storeEmail
        }
        if (payload.filename == "") {
            payload.filename = selectedTemplate.filename
            payload.emailsubject = selectedTemplate.filename == Constants.ContactCustomerFileName.Custom ? customSub : selectedTemplate.subject
        }
        if (selectedTemplate.filename == Constants.ContactCustomerFileName.Custom) {
            payload.customBody = customBody
        }
        let selectedItems = [] as any
        selectedOrderLines.items.forEach((item) => {
            if (item.selected) {
                selectedItems.push(item);
                ccShipmentLineKeys.push(item.shipmentlinekey);
            }
        })
        payload.items = selectedItems;
        payload["ccShipmentLineKeys"] = ccShipmentLineKeys;
        return payload
    }
    const getFollowupCustomerPayload = () => {
        let payload = JSON.parse(JSON.stringify(selectedOrderLines))
        if (payload.storeemail == "") {
            payload.storeemail = storeEmail
        }
        if (payload.filename == "") {
            payload.filename = selectedTemplate.filename
            payload.emailsubject = customSub
        }
        payload.customBody = customBody
        payload.contactCustomerHistory = Utils.sortContactCustomerHistory(JSON.parse(JSON.stringify(contactCustomerHistory)))
        return payload
    }
    const getOrderLinesNote = () => {
        let selectedNotes = ""
        if (selectedTemplate.filename == Constants.ContactCustomerFileName.FollowUp) {
            selectedNotes = customSub + "-" + customBody;
        }
        if (selectedTemplate.filename == Constants.ContactCustomerFileName.Custom) {
            selectedNotes = customSub + "-" + customBody + "-" + selectedOrderLines.items.filter((item) => item.selected && item.message).map((a) => a.message).join(",");
        }
        else {
            selectedNotes = selectedTemplate.subject + "-" + selectedOrderLines.items.filter((item) => item.selected && item.message).map((a) => a.message).join(",");
        }
        return selectedNotes;
    }

    const getNotesPayload = () => {
        let payload = {
            Order:
            {
                OrderHeaderKey: orderDetail.OrderHeaderKey,
                Notes: {
                    Note:
                    {
                        AuditTransactionid: Constants.AuditTransactionidTypes.Associate,
                        ContactType: "E-Mail",
                        ContactTime: moment.utc(moment.tz("America/Los_Angeles")).toISOString(),
                        ContactUser: OMSManager.GetUserIdDoamin().user,
                        Priority: "0.00",
                        VisibleToAll: "Y",
                        NoteText: getOrderLinesNote()
                    }
                }
            }
        }
        return payload;
    }

    const saveContactCustomer = async () => {
        if (validateTemplateParameters()) {
            if (selectedTemplate.filename == Constants.ContactCustomerFileName.FollowUp) {
                let payload = getFollowupCustomerPayload()
                let imagesPayload: IImageAttachment[] = getImageAttachments();
                let tempHistory = payload.contactCustomerHistory
                payload.shipmentKey = shipmentkey;
                payload.logType = "history";
                payload.transactionType = Constants.OrderHistoryTypes.Customer_Contacted;
                payload.message = "";
                payload.transactionDate = moment.utc(moment.tz("America/Los_Angeles")).toISOString();
                payload.responded = "false";
                payload.customerresponse = {};
                payload.customermessage = "";
                payload.isFollowup = true;
                payload.isCustom = false;
                payload.urlDisabled = false;
                payload.customerresponsedate = moment.utc(moment.tz("America/Los_Angeles")).toISOString();
                if (imagesPayload.length > 0) {
                    payload.imageAttachments = imagesPayload.map(image => {
                        const { filename, type, path } = image;
                        return { filename, type, path };
                    });
                }
                if (payload.hasOwnProperty('contactCustomerHistory')) {
                    delete payload.contactCustomerHistory
                }
                if (parentContactCustomer) {
                    payload.parentContactCustomerGuid = parentContactCustomer;
                }

                showActionLoader()
                if (payload.orderno && payload.orderno != "" && payload.parentContactCustomerGuid && payload.parentContactCustomerGuid != "") {
                    let disableUrlsResult = await OrderManager.getUrlDisabledByGuid({ guid: payload.parentContactCustomerGuid, order: payload.orderno })
                }
                OrderManager.saveOrderHistory(payload).then((result: any) => {
                    if (result.success) {
                        payload.contactCustomerHistory = tempHistory
                        if (payload.hasOwnProperty("imageAttachments")) {
                            payload.imageAttachments = imagesPayload;
                        }
                        dispatch(allActions.orderHistoryActions.forceHistoryLoad());
                        ContactCustomerManager.setFollowupTemplateHtml(payload).then((resultMail) => {
                            if (resultMail.success && resultMail?.data?.response?.success == true) {
                                // show message to use success 
                                openSuccessNotification("Email sent to customer")
                                hideActionLoader()
                            }
                            else {
                                hideActionLoader()
                                showGeneralErrorModel(LabelText.generalErrorMessage);
                            }
                            closeContactCustomer();
                        }).catch((error) => {
                            hideActionLoader()
                            showGeneralErrorModel(LabelText.generalErrorMessage);
                            closeContactCustomer();
                        })
                    }
                    else {
                        hideActionLoader()
                        showGeneralErrorModel(LabelText.generalErrorMessage);
                    }
                })
                let notesPayload = getNotesPayload();
                // we have to send oms log as well 
                OMSManager.omsGeneral({ urlName: "GCAddNoteOnEmailTrigger", payLoad: notesPayload }).then((result: any) => {
                    // if (!result.success) {
                    //     showGeneralErrorModel(LabelText.generalErrorMessage);
                    // }
                });
            }
            else {
                let payload = getCustomerContactPayload()
                let imagesPayload: IImageAttachment[] = getImageAttachments();
                payload.shipmentKey = shipmentkey;
                payload.logType = "history";
                payload.transactionType = Constants.OrderHistoryTypes.Customer_Contacted;
                payload.message = "";
                payload.transactionDate = moment.utc(moment.tz("America/Los_Angeles")).toISOString();
                payload.responded = "false";
                payload.customerresponse = {};
                payload.customermessage = "";
                payload.isFollowup = false;
                payload.urlDisabled = false;
                payload.isCustom = selectedTemplate.filename == Constants.ContactCustomerFileName.Custom ? true : false;
                payload.customerresponsedate = moment.utc(moment.tz("America/Los_Angeles")).toISOString();
                if (imagesPayload.length > 0) {
                    payload.imageAttachments = imagesPayload.map(image => {
                        const { filename, type, path } = image;
                        return { filename, type, path };
                    });
                }
                showActionLoader()
                OrderManager.saveOrderHistory(payload).then((result: any) => {
                    if (result.success) {
                        dispatch(allActions.orderHistoryActions.forceHistoryLoad());
                        if (payload.hasOwnProperty("imageAttachments")) {
                            payload.imageAttachments = imagesPayload;
                        }
                        ContactCustomerManager.sendCustomerEmail(payload).then((resultMail) => {
                            if (resultMail.success && resultMail?.data?.response?.success == true) {
                                // show message to use success 
                                openSuccessNotification("Email sent to customer")
                                hideActionLoader()
                            }
                            else {
                                hideActionLoader()
                                showGeneralErrorModel(LabelText.generalErrorMessage);
                            }
                            closeContactCustomer();
                        }).catch((error) => {
                            hideActionLoader()
                            showGeneralErrorModel(LabelText.generalErrorMessage);
                            closeContactCustomer();
                        })
                    }
                    else {
                        hideActionLoader()
                        showGeneralErrorModel(LabelText.generalErrorMessage);
                    }
                })

                let notesPayload = getNotesPayload();
                OMSManager.omsGeneral({ urlName: "GCAddNoteOnEmailTrigger", payLoad: notesPayload }).then((result: any) => {
                    // if (!result.success) {
                    //     showGeneralErrorModel(LabelText.generalErrorMessage);
                    // }
                });
            }
        }
    }

    const viewEmailTemplate = async () => {
        //
        if (validateTemplateParameters()) {
            showActionLoader()
            if (selectedTemplate.filename == Constants.ContactCustomerFileName.FollowUp) {
                let payload = getFollowupCustomerPayload()
                ContactCustomerManager.getFollowupTemplateHtml(payload).then((result) => {
                    if (result.success == true) {
                        if (result?.data?.response?.success == true && result?.data?.response?.data) {
                            setDynamicHtml(result?.data?.response?.data)
                            setOpenEmailTemplate(true)
                            hideActionLoader()
                        }
                        else {
                            showGeneralErrorModel(LabelText.generalErrorMessage);
                            hideActionLoader()
                        }
                    } else {
                        showGeneralErrorModel(LabelText.generalErrorMessage);
                        hideActionLoader()
                    }
                    //
                }).catch((error) => {
                    showGeneralErrorModel(LabelText.generalErrorMessage);
                    console.log(error)
                    hideActionLoader()
                    //
                })

            }
            else {
                let payload = getCustomerContactPayload()
                if (selectedTemplate.filename == Constants.ContactCustomerFileName.Custom) {
                    payload.isCustom = true;
                }
                ContactCustomerManager.getTemplateHtml(payload).then((result) => {
                    if (result.success == true) {
                        if (result?.data?.response?.success == true && result?.data?.response?.data) {
                            setDynamicHtml(result?.data?.response?.data)
                            setOpenEmailTemplate(true)
                            hideActionLoader()
                        }
                        else {
                            showGeneralErrorModel(LabelText.generalErrorMessage);
                            hideActionLoader()
                        }
                    } else {
                        showGeneralErrorModel(LabelText.generalErrorMessage);
                        hideActionLoader()
                    }
                    //
                }).catch((error) => {
                    showGeneralErrorModel(LabelText.generalErrorMessage);
                    console.log(error)
                    hideActionLoader()
                    //
                })
            }
        }
    }
    const closeCancel = () => {
        setCancel(false)
    }
    const closeResource = () => {
        // check validation         
        setResource(false)
    }

    const keyPress = (e: any) => {
        if (e.keyCode == 13) {
            scanItemId();
        }
    }
    const checkSkuOlderOrder = async (ItemId: any) => {

        try {
            let storeId: any = IdentityManager.getStoreId(props.store);
            PFManager.checkSkuOlderOrder(storeId, ItemId).then((_data: any) => {
                console.log(_data, "checkSkuOlderOrder");
                hideActionLoader();
                if (_data.success && _data.data && _data.data.length > 0) {
                    // show the popup heer
                    console.log(orderFromUrl, "orderFromUrl");
                    if (orderFromUrl != _data.data) {
                        let msg = "Same ItemId/sku # has been waiting to be picked in order # " + _data.data;
                        dispatch(allActions.genericNotificationActions.showSuccessMessage(msg));
                    }
                }
                else {
                    hideActionLoader();
                }
            })
        }
        catch (e) {
            hideActionLoader();
        }


    }
    const scanItemId = async () => {
        // get quantity 
        // and ScanNo 
        //QuantityPicked: string, ScanNo: string, ShipmentKey: string, ShipNode: string

        if ((props.store == undefined || props.store?.length == 0) && StoreManager.currentStore.StoreId == undefined) {
            showGeneralErrorModel("Please select store");
            return
        }
        let storeId = IdentityManager.getStoreId(props.store)
        let quantityPicked = scannedQty
        let ScanNo = skuToScan.trim();
        let ShipmentKey = orderDetail.ShipmentKey
        let ShipNode = storeId
        let response: any = {};
        try {

            showActionLoader()
            response = await OMSManager.scanSkueUpdated(quantityPicked, ScanNo, ShipmentKey, ShipNode);
        }
        catch {
            showGeneralErrorModel(LabelText.generalErrorMessage);

            hideActionLoader();
            return;
        }
        if (response.success && response.data) {
            let responseData = response.data;
            if (responseData?.Shipment[0]?.ShipmentLines?.ShipmentLine != undefined && responseData?.Shipment[0]?.ShipmentLines?.ShipmentLine.length > 0) {
                let lines = responseData?.Shipment[0]?.ShipmentLines?.ShipmentLine
                let isSuccess = true
                let errorMessage = ""
                if (lines.length > 0) {
                    const checkResult = Utils.checkPosScanResult(lines);  // Loop over all lines and check if we any any error against POSScanResult
                    isSuccess = checkResult.isSuccess
                    errorMessage = checkResult.errorMessage
                }
                else {
                    isSuccess = false
                }
                if (isSuccess) {
                    // loop over 
                    try {
                        let isSuccessByQuantity = true;
                        //let POSScanResult = lines[0].POSScanResult // first scan // now we are not doing it
                        let oneScanDone = false
                        let isWarrentyinScanResult = Utils.isWarrantyItem(lines) // if we have any warrenty line in result                       
                        let filteredLines = Utils.filterWarrantyLines(lines) // filtered Lines without warranty lines
                        filteredLines.forEach((scannedLine: any) => {
                            if (oneScanDone) {
                                return
                            }
                            for (let i = 0; i < orderDetail.ShipmentLines.ShipmentLine.length; i++) { // loop over shipment lines
                                //(Number(item.Quantity) - Number(item.ScannedQty)) == 0
                                if (Utils.shipmentLineNeedScanning(orderDetail.ShipmentLines.ShipmentLine[i])) { // skipping the already scanned line
                                    if (orderDetail.ShipmentLines.ShipmentLine[i].ShipmentLineKey == scannedLine.ShipmentLineKey) { // comparing shipment line key
                                        let POSScanResult = Utils.getPocScanResult(lines, scannedLine)// checking for duplicate result
                                        let totatQtyScanned = 0
                                        if (orderDetail?.ShipmentLines?.ShipmentLine[i]?.ScannedQty) { // check if quantity already exists
                                            totatQtyScanned = Number(orderDetail?.ShipmentLines?.ShipmentLine[i]?.ScannedQty)
                                        }
                                        totatQtyScanned = Number(totatQtyScanned) + Number(quantityPicked) // sum of old quantity with new one
                                        if (Number(totatQtyScanned) <= Number(orderDetail.ShipmentLines.ShipmentLine[i].Quantity)) { // is scanning is done
                                            orderDetail.ShipmentLines.ShipmentLine[i].IsScanned = true
                                            orderDetail.ShipmentLines.ShipmentLine[i].ScannedQty = totatQtyScanned
                                            orderDetail.ShipmentLines.ShipmentLine[i].GCPickScanSkuResult = POSScanResult
                                            if (pickedItems.filter((a: any) => a.skuToScan == skuToScan && a.shipmentLineKey == orderDetail.ShipmentLines.ShipmentLine[i].ShipmentLineKey).length == 0) {
                                                pickedItems.push({ skuToScan: skuToScan, Quantity: totatQtyScanned, SKUNumber: POSScanResult?.SKUNumber, shipmentLineKey: orderDetail.ShipmentLines.ShipmentLine[i].ShipmentLineKey, ItemDesc: POSScanResult?.POSItemDesc, posItemId: POSScanResult?.POSItemID });
                                                setPickedItems(pickedItems);
                                                oneScanDone = true
                                            }
                                            else {
                                                for (let p = 0; p < pickedItems.length; p++) { // if scanning is partially done updating quantity
                                                    if (pickedItems[p].skuToScan == skuToScan) {
                                                        pickedItems[p].Quantity = totatQtyScanned
                                                        setPickedItems(pickedItems);
                                                        oneScanDone = true
                                                    }
                                                }
                                            }
                                        }
                                        else { // if there is issue in quantity
                                            isSuccessByQuantity = false
                                            hideActionLoader()
                                            showGeneralErrorModel("Invalid Picked Quantity");
                                            oneScanDone = true
                                        }

                                    }
                                    if (oneScanDone && orderDetail.ShipmentLines.ShipmentLine[i].hasOwnProperty("WarrantyComponents")) { // for warranty component                                         
                                        if (isWarrentyinScanResult) {
                                            if (Array.isArray(orderDetail.ShipmentLines.ShipmentLine[i].WarrantyComponents?.ShipmentLine)) {
                                                for (let w = 0; w < orderDetail.ShipmentLines.ShipmentLine[i].WarrantyComponents?.ShipmentLine.length; w++) {
                                                    const warrantyPOSScanResult = Utils.getWarrantyPOSScanResult(lines, orderDetail.ShipmentLines.ShipmentLine[i].WarrantyComponents?.ShipmentLine[w]?.ShipmentLineKey)
                                                    if (warrantyPOSScanResult && warrantyPOSScanResult.SKUNumber && warrantyPOSScanResult.SKUNumber != "") {
                                                        orderDetail.ShipmentLines.ShipmentLine[i].WarrantyComponents.ShipmentLine[w].IsScanned = true;
                                                        orderDetail.ShipmentLines.ShipmentLine[i].WarrantyComponents.ShipmentLine[w].ScannedQty = scannedLine.Quantity;
                                                        orderDetail.ShipmentLines.ShipmentLine[i].WarrantyComponents.ShipmentLine[w].GCPickScanSkuResult = warrantyPOSScanResult
                                                    }
                                                    else {
                                                        isSuccessByQuantity = false
                                                        hideActionLoader()
                                                        showGeneralErrorModel("Warranty not found in Scan Result");
                                                        oneScanDone = true
                                                    }
                                                }
                                            }
                                            else {
                                                const warrantyPOSScanResult = Utils.getWarrantyPOSScanResult(lines, orderDetail.ShipmentLines.ShipmentLine[i].WarrantyComponents?.ShipmentLine?.ShipmentLineKey)
                                                if (warrantyPOSScanResult && warrantyPOSScanResult.SKUNumber && warrantyPOSScanResult.SKUNumber != "") {
                                                    orderDetail.ShipmentLines.ShipmentLine[i].WarrantyComponents.ShipmentLine.IsScanned = true;
                                                    orderDetail.ShipmentLines.ShipmentLine[i].WarrantyComponents.ShipmentLine.ScannedQty = scannedLine.Quantity;
                                                    orderDetail.ShipmentLines.ShipmentLine[i].WarrantyComponents.ShipmentLine.GCPickScanSkuResult = warrantyPOSScanResult
                                                }
                                                else {
                                                    isSuccessByQuantity = false
                                                    hideActionLoader()
                                                    showGeneralErrorModel("Warranty not found in Scan Result");
                                                    oneScanDone = true
                                                }
                                            }
                                        }
                                        else {
                                            isSuccessByQuantity = false
                                            hideActionLoader()
                                            showGeneralErrorModel("Warranty not found in Scan Result");
                                            oneScanDone = true
                                        }
                                    }
                                    if (orderDetail.ShipmentLines.ShipmentLine[i].hasOwnProperty("CustomComponents")) { // for custom component same as main component

                                        for (let j = 0; j < orderDetail.ShipmentLines.ShipmentLine[i].CustomComponents.ShipmentLine.length; j++) {
                                            if (Utils.customComponentShipmentLineNeedScanning(orderDetail.ShipmentLines.ShipmentLine[i].CustomComponents.ShipmentLine[j])) {
                                                if (orderDetail.ShipmentLines.ShipmentLine[i].CustomComponents.ShipmentLine[j].ShipmentLineKey == scannedLine.ShipmentLineKey) {
                                                    let POSScanResult = Utils.getPocScanResult(lines, scannedLine) // add error check
                                                    let totatQtyScanned = 0
                                                    //checkSkuOlderOrder(orderDetail.ShipmentLines.ShipmentLine[i].CustomComponents.ShipmentLine[j].ItemID);
                                                    if (orderDetail?.ShipmentLines?.ShipmentLine[i]?.CustomComponents?.ShipmentLine[j]?.ScannedQty) {
                                                        totatQtyScanned = Number(orderDetail?.ShipmentLines?.ShipmentLine[i]?.CustomComponents?.ShipmentLine[j]?.ScannedQty)
                                                    }
                                                    totatQtyScanned = totatQtyScanned + Number(quantityPicked)
                                                    if (Number(totatQtyScanned) <= Number(orderDetail.ShipmentLines.ShipmentLine[i].CustomComponents.ShipmentLine[j].Quantity)) {
                                                        orderDetail.ShipmentLines.ShipmentLine[i].CustomComponents.ShipmentLine[j].IsScanned = true
                                                        orderDetail.ShipmentLines.ShipmentLine[i].CustomComponents.ShipmentLine[j].ScannedQty = totatQtyScanned
                                                        orderDetail.ShipmentLines.ShipmentLine[i].CustomComponents.ShipmentLine[j].GCPickScanSkuResult = POSScanResult
                                                        if (pickedItems.filter((a: any) => a.skuToScan == skuToScan && a.shipmentLineKey == orderDetail.ShipmentLines.ShipmentLine[i].CustomComponents.ShipmentLine[j].ShipmentLineKey).length == 0) {
                                                            pickedItems.push({ skuToScan: skuToScan, Quantity: totatQtyScanned, SKUNumber: POSScanResult.SKUNumber, shipmentLineKey: orderDetail.ShipmentLines.ShipmentLine[i].CustomComponents.ShipmentLine[j].ShipmentLineKey, ItemDesc: POSScanResult.POSItemDesc, posItemId: POSScanResult.POSItemID });
                                                            setPickedItems(pickedItems);
                                                            oneScanDone = true
                                                        }
                                                        else {
                                                            for (let p = 0; p < pickedItems.length; p++) {
                                                                if (pickedItems[p].skuToScan == skuToScan) {
                                                                    pickedItems[p].Quantity = totatQtyScanned
                                                                    setPickedItems(pickedItems);
                                                                    oneScanDone = true
                                                                }
                                                            }
                                                        }
                                                    }
                                                    else {
                                                        isSuccessByQuantity = false
                                                        hideActionLoader()
                                                        showGeneralErrorModel("Invalid Picked Quantity");
                                                        oneScanDone = true
                                                    }
                                                }
                                                if (orderDetail.ShipmentLines.ShipmentLine[i].CustomComponents.ShipmentLine[j].hasOwnProperty("WarrantyComponents")) {
                                                    if (isWarrentyinScanResult) {
                                                        if (Array.isArray(orderDetail.ShipmentLines.ShipmentLine[i].CustomComponents.ShipmentLine[j].WarrantyComponents?.ShipmentLine)) {
                                                            for (let w = 0; w < orderDetail.ShipmentLines.ShipmentLine[i].CustomComponents.ShipmentLine[j].WarrantyComponents?.ShipmentLine.length; w++) {
                                                                const warrantyPOSScanResult = Utils.getWarrantyPOSScanResult(lines, orderDetail.ShipmentLines.ShipmentLine[i].CustomComponents.ShipmentLine[j].WarrantyComponents?.ShipmentLine[w]?.ShipmentLineKey)
                                                                if (warrantyPOSScanResult && warrantyPOSScanResult.SKUNumber && warrantyPOSScanResult.SKUNumber != "") {
                                                                    orderDetail.ShipmentLines.ShipmentLine[i].CustomComponents.ShipmentLine[j].WarrantyComponents.ShipmentLine[w].IsScanned = true;
                                                                    orderDetail.ShipmentLines.ShipmentLine[i].CustomComponents.ShipmentLine[j].WarrantyComponents.ShipmentLine[w].ScannedQty = scannedLine.Quantity;
                                                                    orderDetail.ShipmentLines.ShipmentLine[i].CustomComponents.ShipmentLine[j].WarrantyComponents.ShipmentLine[w].GCPickScanSkuResult = warrantyPOSScanResult
                                                                }
                                                                else {
                                                                    isSuccessByQuantity = false
                                                                    hideActionLoader()
                                                                    showGeneralErrorModel("Warranty not found in Scan Result");
                                                                    oneScanDone = true
                                                                }
                                                            }
                                                        }
                                                        else {
                                                            const warrantyPOSScanResult = Utils.getWarrantyPOSScanResult(lines, orderDetail.ShipmentLines.ShipmentLine[i].CustomComponents.ShipmentLine[j].WarrantyComponents?.ShipmentLine?.ShipmentLineKey)
                                                            if (warrantyPOSScanResult && warrantyPOSScanResult.SKUNumber && warrantyPOSScanResult.SKUNumber != "") {
                                                                orderDetail.ShipmentLines.ShipmentLine[i].CustomComponents.ShipmentLine[j].WarrantyComponents.ShipmentLine.IsScanned = true;
                                                                orderDetail.ShipmentLines.ShipmentLine[i].CustomComponents.ShipmentLine[j].WarrantyComponents.ShipmentLine.ScannedQty = scannedLine.Quantity;
                                                                orderDetail.ShipmentLines.ShipmentLine[i].CustomComponents.ShipmentLine[j].WarrantyComponents.ShipmentLine.GCPickScanSkuResult = warrantyPOSScanResult
                                                            }
                                                            else {
                                                                isSuccessByQuantity = false
                                                                hideActionLoader()
                                                                showGeneralErrorModel("Warranty not found in Scan Result");
                                                                oneScanDone = true
                                                            }
                                                        }
                                                    }
                                                    else {
                                                        isSuccessByQuantity = false
                                                        hideActionLoader()
                                                        showGeneralErrorModel("Warranty not found in Scan Result");
                                                        oneScanDone = true
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        });
                        setScannedQty("1");
                        setSKUNumberForScan("");
                        if (isSuccessByQuantity) {
                            dispatch(allActions.pickOrderActions.changeOMSOrderDetails(orderDetail));
                        }

                        hideActionLoader();
                    }
                    catch {
                        hideActionLoader()
                        if (errorMessage == "") {
                            errorMessage = LabelText.generalErrorMessage
                        }
                        showGeneralErrorModel(errorMessage);
                    }

                }
                else {
                    if (errorMessage != "" && errorMessage != undefined) {
                        hideActionLoader()
                        showGeneralErrorModel(errorMessage);
                    }
                    else {

                        hideActionLoader()
                        showGeneralErrorModel(LabelText.generalErrorMessage);
                    }
                }
            }
            else {

                hideActionLoader()
                showGeneralErrorModel("Scanned item not found");
            }
        }
        else {

            hideActionLoader()
            showGeneralErrorModel(LabelText.generalErrorMessage);
        }
    }
    const cancelPickScan = (shipmentLineKey: any) => {
        for (let i = 0; i < orderDetail.ShipmentLines.ShipmentLine.length; i++) {
            if (orderDetail.ShipmentLines.ShipmentLine[i].ShipmentLineKey == shipmentLineKey) {
                orderDetail.ShipmentLines.ShipmentLine[i].IsScanned = false
                orderDetail.ShipmentLines.ShipmentLine[i].ScannedQty = 0
                orderDetail.ShipmentLines.ShipmentLine[i].GCPickScanSkuResult = {} as any
            }
            else {
                if (orderDetail.ShipmentLines.ShipmentLine[i].hasOwnProperty("CustomComponents")) {
                    for (let j = 0; j < orderDetail.ShipmentLines.ShipmentLine[i].CustomComponents.ShipmentLine.length; j++) {
                        if (orderDetail.ShipmentLines.ShipmentLine[i].CustomComponents.ShipmentLine[j].ShipmentLineKey == shipmentLineKey) {
                            orderDetail.ShipmentLines.ShipmentLine[i].CustomComponents.ShipmentLine[j].IsScanned = false
                            orderDetail.ShipmentLines.ShipmentLine[i].CustomComponents.ShipmentLine[j].ScannedQty = 0
                            orderDetail.ShipmentLines.ShipmentLine[i].CustomComponents.ShipmentLine[j].GCPickScanSkuResult = {} as any
                        }
                    }
                }
            }
        }
        let filtered = pickedItems.filter((scanned: any) => {
            return scanned.shipmentLineKey != shipmentLineKey
        })
        setPickedItems(filtered)
        dispatch(allActions.pickOrderActions.changeOMSOrderDetails(orderDetail));
    }

    const GCPickConfirm = async () => {
        let response: any = {};

        try {
            showActionLoader()
            response = await OMSManager.GCPickConfirm(orderDetail)
        }
        catch (error) {
            //
            showGeneralErrorModel(LabelText.generalErrorMessage);
            hideActionLoader()
            return;
        }

        if (response.success && response.data) {
            await OrderManager.saveOrderHistory({
                shipmentKey: shipmentkey,
                logType: "history",
                transactionType: Constants.OrderHistoryTypes.Picking,
                message: notes,
                transactionDate: moment.utc(moment.tz("America/Los_Angeles")).toISOString()
            });
            setPickComplete(true);
            hideActionLoader()
            //navigateToFulfillment();

        }
        else {
            let errorMsg = "";
            hideActionLoader()
            //showGeneralErrorModel(LabelText.generalErrorMessage);
            if (!response.success && response.msg) {
                errorMsg = response.msg;
            }
            else {
                errorMsg = LabelText.generalErrorMessage;
            }
            showGeneralErrorModel(errorMsg);
        }


    }

    let orderNumner = urlParams.get('orderno');
    let pickCompleteButtons: IButton[] = [{
        text: "Pick Another",
        icon: <ShoppingCart />,
        action: navigateToFulfillment,
        color: Constants.Colors.red,
    },
    {
        text: navigateTo == Constants.OrderFlowConstants.PICK_PICKUP ? "Continue to Pickup" : "Continue to Pack",
        icon: navigateTo == Constants.OrderFlowConstants.PICK_PICKUP ? <DirectionsCar /> : <ApartmentIcon />,
        action: navigateTo == Constants.OrderFlowConstants.PICK_PICKUP ? toPickup : toPack,
        color: Constants.Colors.red,
    }
    ]
    let pickCompleteDialog: INotificationPopupSettings = {
        open: pickComplete,
        type: Constants.NotificationPopupType.Success,
        title: LabelText.NotificationPopupHeaders.Success,
        msg: LabelText.PickOrder.PickCompleteSuccess,
        handleClose: closePickComplete,
        actions: pickCompleteButtons
    }

    let resourcePopupButtons: IButton[] = []
    let resourcePopup: INotificationPopupSettings = {
        open: resource,
        type: Constants.NotificationPopupType.Info,
        title: LabelText.NotificationPopupHeaders.Resource,
        msg: LabelText.PickOrder.PickResourceOrderWarning,
        handleClose: closeResource,
        isCustomJSX: true,
        customJSX: <ResourceItem closeResource={closeResource} setSuccess={setSuccess} shipmentKey={shipmentkey}></ResourceItem>,
        actions: resourcePopupButtons,
        fullWidth: true,
        maxWidth: "md"

    }

    let contactCustomerSettings: IContactCustomer = {
        customerEmail: "customer.email@mail.com",
        storeEmail: "storemail.email@mail.com",
        template: LabelText.MAILTEMPLATES.notOriginalPackaging,
        templateSubject: LabelText.MAILSUBJECTS.notOriginalPackaging
    }
    let contactCustomerPopupButtons: IButton[] = [{
        text: "Send",
        icon: <SendIcon />,
        action: saveContactCustomer,
        color: Constants.Colors.red,
    },
    {
        text: "View Template",
        icon: "",
        action: viewEmailTemplate,
        color: Constants.Colors.blue,
    },
    {
        text: "Close",
        icon: <CloseIcon />,
        action: closeContactCustomer,
        color: Constants.Colors.grey,
    }
    ]
    let contactCustomerPopup: INotificationPopupSettings = {
        open: contactCustomer,
        type: Constants.NotificationPopupType.Info,
        title: LabelText.PickOrder.ContactCustomerTitle,
        msg: "",
        handleClose: closeContactCustomer,
        isCustomJSX: true,
        customJSX: <ContactCustomer store={props.store}></ContactCustomer>,
        actions: contactCustomerPopupButtons,
        fullWidth: true,
        maxWidth: "lg"
    }
    const addNotes = () => {
        if (notes != "") {
            let index = Utils.getStepperIndex(verticalStepperOptions, "Picking")
            step.splice(index, 0, { label: 'Note', description: notes, time: 'Jan 20 2022 13:11:17 ' })
            verticalStepperOptions.stepsOptions = step
            setSteps(verticalStepperOptions)
        }
        setNotesPopup(false)
    }
    let notesPopupButtons: IButton[] = [{
        text: "Add",
        icon: <AddCommentIcon />,
        action: addNotes,
        color: Constants.Colors.blue,
    },
    {
        text: "Close",
        icon: <CloseIcon />,
        action: closeNotes,
        color: Constants.Colors.grey,
    }
    ]
    let notesCustomerPopup: INotificationPopupSettings = {
        open: notePopup,
        type: Constants.NotificationPopupType.Info,
        title: LabelText.PickOrder.Note,
        msg: "",
        handleClose: closeNotes,
        isCustomJSX: true,
        customJSX: <Note note={notes} changeNote={changeNote}></Note>,
        actions: notesPopupButtons,
    }

    const closePdfiframe = () => {
        SetShowPdf(false);
    }

    const closeSuccess = () => {
        setSuccess(false)
        openLoadingOrderLines()
        getPickDetailFromOms()
    }

    let successPopupButtons: IButton[] = [
        {
            text: "Close",
            icon: <CloseIcon />,
            action: closeSuccess,
            color: Constants.Colors.grey,
        },
        {
            text: "To Fulfillment List",
            icon: <DoneIcon />,
            action: navigateToFulfillment,
            color: Constants.Colors.red,
        }
    ];
    let success: INotificationPopupSettings = {
        open: openSuccess,
        type: Constants.NotificationPopupType.Success,
        title: "Success",
        msg: "Order Resource Complete",
        draggable: false,
        handleClose: closeSuccess,
        actions: successPopupButtons
    }
    let navigateToFulfillOrdersButtons: IButton[] = [
        {
            text: "To Fulfillment List",
            icon: <DoneIcon />,
            action: navigateToFulfillment,
            color: Constants.Colors.red,
        }
    ];
    let navigateToFulfillOrders: INotificationPopupSettings = {
        open: navigationFulfillment,
        type: Constants.NotificationPopupType.Info,
        title: "",
        msg: "This Order doesn't belong to selected Store.",
        draggable: false,
        handleClose: navigateToFulfillment,
        actions: navigateToFulfillOrdersButtons
    }

    const closeImageViewer = () => {
        setImagePopUp(false);
        setImageData({} as IImageViewerDetail);
    }

    let imageViewerSettings: IImageViewerPopupSettings = {
        open: imagePopUp,
        imageDetail: imageData,
        close: closeImageViewer
    }

    const getPickDetailFromOms = async () => {
        let result = [] as any
        try {
            // Get Email Templates
            ContactCustomerManager.getEmailTemplates().then((result) => {
                dispatch(allActions.contactCustomerActions.changeEmailTemplates(result.data.response.data));
            })
            result = await OMSManager.getPickOrdersDetail(shipmentkey)
            console.log("Result Oms", result);
            //
            if (result.success && result.data.ShipmentLines != undefined && result.data.ShipmentLines.ShipmentLine != undefined) {
                let data = result.data
                setOrderNumber(data.ShipmentLines.ShipmentLine[0].OrderNo);
                let isAnyBulkOrder = false
                data.ShipmentLines.ShipmentLine.forEach((line: any) => {
                    if (line.hasOwnProperty("CustomComponents")) {
                        isAnyBulkOrder = true
                    }
                });
                let orderDetails = {
                    fulfillmentType: data?.FulfillmentType,
                    levelOfService: data?.LevelOfService,
                    pickUpStore: data?.Extn?.ExtnPickupStoreID,
                    storeNumber: data?.ShipNode?.ShipNode
                }
                setOrderDetails(orderDetails);

                data.isAnyBulkOrder = isAnyBulkOrder
                dispatch(allActions.pickOrderActions.changeOMSOrderDetails(data));
                dispatch(allActions.pickOrderActions.changeCommonCodeReasons(data?.CommonCodeList?.CommonCode));
                dispatch(allActions.pickOrderActions.changeCustomerDetails(OMSManager.getCustomerDetails(data)));

                closeLoadingOrderLines();
                if (data.CommonCodeList == undefined) {
                    setErrorMsg(LabelText.noCommonCodeListError);
                    openErrorPopup();
                }
            }
            else {
                let message = ""
                if (result.success && result.data.Status == "9000") {
                    message = "This Order has been canceled"
                }
                else if (!result.success && result.msg) {
                    message = result.msg;
                }
                else if ((result.success && result.data?.IsShipmentListEmpty == 'Y')
                    || (result.success && !result.data.ShipmentLines.ShipmentLine)) {
                    message = LabelText.noShipmentLines;
                }
                else {
                    message = LabelText.generalErrorMessage
                }
                showPickDetailErrorCommon(message);
                closeLoadingOrderLines();
            }
        }
        catch {
            closeLoadingOrderLines();
            showPickDetailErrorCommon(LabelText.generalErrorMessage);
        }


    }

    const showPickDetailErrorCommon = async (msg: string) => {

        setIsError(true);
        setErrorMsg(msg);
        openErrorPopup();

    }

    const showGeneralErrorModel = async (msg: any) => {

        setOpenGeneralError(true);
        setErrorGeneralMsg(msg);

    }

    const closeGeneralErrorModel = () => {
        setOpenGeneralError(false);
        setErrorGeneralMsg("");
    }

    const closeErrorPopup = () => {
        setOpenError(false)
    }
    const openErrorPopup = () => {
        setOpenError(true)
    }
    const popUpBack = () => {
        navigate(-1)
    }
    let errorPopupButtons: IButton[] = [
        {
            text: "Close",
            icon: <CloseIcon />,
            action: closeErrorPopup,
            color: Constants.Colors.red,
        },
        {
            text: "Back",
            icon: <ArrowBackIcon />,
            action: popUpBack,
            color: Constants.Colors.red,
        },
    ];
    let Error: INotificationPopupSettings = {
        open: openError,
        type: Constants.NotificationPopupType.Error,
        title: "Error",
        msg: errorMsg,
        draggable: false,
        handleClose: closeErrorPopup,
        actions: errorPopupButtons
    }



    let errorGeneralPopupButtons: IButton[] = [
        {
            text: "Close",
            icon: <CloseIcon />,
            action: closeGeneralErrorModel,
            color: Constants.Colors.red,
        }
    ];
    let GeneralError: INotificationPopupSettings = {
        open: openGeneralError,
        type: Constants.NotificationPopupType.Error,
        title: "Error",
        msg: errorGeneralMsg,
        draggable: false,
        handleClose: closeGeneralErrorModel,
        actions: errorGeneralPopupButtons
    }

    React.useEffect(() => {
        async function getPickDetail() {
            await getPickDetailFromOms()
            await ApiService.get(`/pf/getOrderFromExStore?shipmentKey=` + shipmentkey).then((response: any) => {
                let orderDetail = (response.data.hits.hits.length > 0 ? response.data.hits.hits[0]._source.Shipment : [])
                let navigationDecision = Utils.OrderFlow(orderDetail, false, null);
                setNavigateTo(navigationDecision)
            })
        }
        getPickDetail()

    }, []);

    React.useEffect(() => {
        if (queryParamStore && props.store?.length == 1 && props.store[0].id != queryParamStore) {
            openNavigationFulfillment();
        }
    }, [props.store])

    const getEnableConformPick = () => {
        let nonScannedItems = [];
        let enable = false
        if (orderDetail.isAnyBulkOrder) {
            if (orderDetail && orderDetail?.ShipmentLines) {
                orderDetail.ShipmentLines.ShipmentLine.forEach((line: ShipmentLine) => {
                    if (line.hasOwnProperty("CustomComponents")) {
                        if (line.CustomComponents.ShipmentLine.length > 0) {
                            line.CustomComponents.ShipmentLine.forEach((customLine: ShipmentLine) => {
                                if (customLine.IsScanned == undefined || customLine.IsScanned == false || (Number(customLine.ScannedQty) != Number(customLine.Quantity))) {
                                    nonScannedItems.push(true)
                                }
                            })
                        }
                    }
                    else {
                        if (line.IsScanned == undefined || line.IsScanned == false || (Number(line.ScannedQty) != Number(line.Quantity))) {
                            nonScannedItems.push(true)
                        }
                    }
                })
                enable = nonScannedItems.length > 0 ? false : true;
            }
        }
        else {
            if (orderDetail && orderDetail?.ShipmentLines) {
                nonScannedItems = orderDetail?.ShipmentLines?.ShipmentLine?.filter((a: ShipmentLine) => { return !a.IsScanned || a.IsScanned == false || a.ScannedQty != a.Quantity });
                enable = nonScannedItems.length > 0 ? false : true;
            }
        }

        return enable
    }
    const mobileView = () => {
        let enableConfirmPick = getEnableConformPick();
        const height = Utils.displayHeight + 'px';
        const loaderLeft = Utils.isMobile ? "42%" : "48%"
        return <div className="landscape">
            {showLoader ? <div style={{ position: "absolute", top: "42%", left: loaderLeft }}>
                <TailSpin color="#D93A2F" height={80} width={80} />
            </div> : ""}
            {isScan ? <MobileBarCodeScannerScandit scannedQty={scannedQty} setScannedQty={setScannedQty} onScanned={onScannedComplete} setSKUNumberForScan={setSKUNumberForScan} skuToScan={skuToScan} /> : <div style={{ paddingBottom: "150px" }}>
                <Paper id='bar' style={{ color: "white", top: 0, left: 0, right: 0, zIndex: 999, borderRadius: 0 }} className="appBarPrimary">
                    <Toolbar style={{ height: "46px", minHeight: "46px" }}>
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mr: 2 }}

                            onClick={() => { navigate(-1) }}
                        >
                            <ArrowBackIcon />
                        </IconButton>
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} style={{ marginLeft: "-48px" }}>
                            Pick Order
                        </Typography>
                        <IconButton style={{ background: "#D93A2F", color: "white" }} sx={{ p: '10px' }} aria-label="actions">
                        </IconButton>
                    </Toolbar>
                </Paper>
                <Paper style={{ marginTop: 2, paddingTop: 1, paddingBottom: 1 }}>
                    <Card style={{ margin: "6px" }} id='cardOrder'>
                        <CardContent style={{ padding: "10px" }}>
                            <Grid spacing={2} container style={{ margin: "0px" }} sx={{ flexGrow: 1 }}>
                                <Grid item xs={10} sm={9} style={{ padding: "8px" }}>
                                    <Typography variant="h6" style={{ padding: "0px" }} className='scanColor'>
                                        Order # {orderNumber}
                                    </Typography>
                                </Grid>
                                {Utils.disableUserAction(props.store) && <Grid item xs={2} sm={3} style={{ padding: "0px" }}>
                                    <IconButton
                                        onClick={() => { setScan(true) }}
                                        size="large"
                                        edge="start"
                                        color="inherit"
                                        aria-label="menu"
                                        sx={{ mr: 4 }}
                                        className='scanColor'

                                    >
                                        <DocumentScannerIcon style={{ transform: "rotate(90deg)" }} />
                                    </IconButton>
                                </Grid>}
                            </Grid>
                        </CardContent>
                    </Card>
                    {
                        !loadingOrderLines ?
                            <React.Fragment>
                                {
                                    isError == false ?
                                        <React.Fragment>
                                            {
                                                orderDetail?.ShipmentLines?.ShipmentLine?.map((item: any, index: any) => {
                                                    return (
                                                        <Card style={{ margin: "6px" }} >
                                                            <CardContent style={{ padding: "10px" }}>
                                                                <Grid spacing={2} container style={{ margin: "0px" }} sx={{ flexGrow: 1 }}>
                                                                    <Grid item xs={4} sm={4} style={{ padding: "8px", borderRight: "1px solid #2f2d2d" }}>
                                                                        <img style={{ height: "100px", cursor: "zoom-in" }} alt="" src={Utils.convertToHttps(item.ImageUrl)} onClick={() => { imageViewer(item) }} />
                                                                    </Grid>
                                                                    <Grid item xs={6} sm={6} style={{ padding: "8px", textAlign: "left" }}>
                                                                        <Typography style={{ fontSize: "13px", fontWeight: 500, color: "#D93A2F" }}>{item.ItemDesc}</Typography>
                                                                        {
                                                                            renderExtendedDescription(item)
                                                                        }
                                                                        {
                                                                            item.KitCode == "" ?
                                                                                <Typography style={{ fontSize: "11px" }}>Status: {item.IsScanned ? <React.Fragment><b style={{ color: (Number(item.Quantity) - Number(item.ScannedQty)) == 0 ? "green" : "#ADB301" }}>Scanned/In Picking</b></React.Fragment> : <React.Fragment><b>Created</b></React.Fragment>}</Typography>
                                                                                :
                                                                                ""
                                                                        }

                                                                        <Typography style={{ fontSize: "11px" }}>Qty: <b>{item.Quantity}</b></Typography>
                                                                        {
                                                                            item.KitCode == "" ?
                                                                                <Typography style={{ fontSize: "11px" }}>Remaining: <b>{
                                                                                    item.ScannedQty ?
                                                                                        Number(item.Quantity) - Number(item.ScannedQty)
                                                                                        :
                                                                                        item.Quantity
                                                                                }</b></Typography>
                                                                                :
                                                                                ""
                                                                        }
                                                                        <Typography style={{ fontSize: "11px" }}>EID: <b>{item.ItemID}</b></Typography>
                                                                        <Typography style={{ fontSize: "11px" }}>Item ID: <b>{item.OrderLine?.ItemDetails?.Extn?.ExtnPOSItemID}</b></Typography>
                                                                        <POSInventory data={item} main="main"></POSInventory>

                                                                    </Grid>
                                                                    {Utils.disableUserAction(props.store) && <Grid item xs={2} sm={2} style={{ padding: 0 }}>
                                                                        <ItemAction item={item} openResources={resourceClick} openCancel={cancelClick} openCustomerContact={contactCustomerClick} ShipmentLineKey={item.ShipmentLineKey} />
                                                                    </Grid>}
                                                                </Grid>
                                                                <div style={{ padding: "", background: "#ececec", marginTop: "", border: "" }}>
                                                                    {
                                                                        item.CustomComponents && item.CustomComponents.ShipmentLine.length > 0 ?
                                                                            item.CustomComponents?.ShipmentLine?.map((item: any) => {
                                                                                return (
                                                                                    <Grid spacing={2} container style={{ margin: "0px" }} sx={{ flexGrow: 1 }}>
                                                                                        <Grid item xs={4} sm={4} style={{ padding: "8px", borderRight: "1px solid #2f2d2d" }}>
                                                                                            <img style={{ height: "100px", cursor: "zoom-in" }} alt="" src={Utils.convertToHttps(item.ImageUrl)} onClick={() => { imageViewer(item) }} />
                                                                                        </Grid>
                                                                                        <Grid item xs={6} sm={6} style={{ padding: "8px", textAlign: "left" }}>
                                                                                            <Typography style={{ fontSize: "13px", fontWeight: 500, color: "#D93A2F", paddingLeft: "0px" }}>{item.ItemDesc}</Typography>
                                                                                            <Typography style={{ fontSize: "11px" }}>Status: {item.IsScanned ? <React.Fragment><b style={{ color: (Number(item.Quantity) - Number(item.ScannedQty)) == 0 ? "green" : "#ADB301" }}>Scanned/In Picking</b></React.Fragment> : <React.Fragment><b>Created</b></React.Fragment>}</Typography>
                                                                                            <Typography style={{ fontSize: "11px" }}>Remaining: <b style={{}}>{
                                                                                                item.ScannedQty ?
                                                                                                    Number(item.Quantity) - Number(item.ScannedQty)
                                                                                                    :
                                                                                                    item.Quantity
                                                                                            }</b></Typography>
                                                                                            <Typography style={{ fontSize: "11px" }}>Sku: <b>{item.ItemID}</b></Typography>
                                                                                            <Typography style={{ fontSize: "11px" }}>Item ID: <b>{item.OrderLine?.ItemDetails?.Extn?.ExtnPOSItemID}</b></Typography>
                                                                                            <POSInventory data={item} main="main"></POSInventory>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                );
                                                                            })
                                                                            :
                                                                            <div></div>
                                                                    }
                                                                </div>
                                                            </CardContent>
                                                        </Card>
                                                    );
                                                })
                                            }
                                            {Utils.disableUserAction(props.store) && <Card style={{ margin: "6px" }}>
                                                <CardContent style={{ padding: "0px" }}>
                                                    <Button onClick={() => pickCompleteClick()} style={{ width: "100%" }} variant='contained' className={enableConfirmPick ? "btn" : "btn disabledButton"} disabled={!enableConfirmPick} startIcon={<CheckIcon />}>Confirm Pick</Button>
                                                </CardContent>
                                            </Card>}
                                        </React.Fragment>
                                        :
                                        <OrderLineShimmerMobile></OrderLineShimmerMobile>
                                }
                            </React.Fragment>
                            :
                            <OrderLineShimmerMobile></OrderLineShimmerMobile>

                    }
                    <OrderDetailGrid loading={loadingOrderLines} data={orderDetails}></OrderDetailGrid>
                    {/* <div className="backdrop">
                        <div className='title'>
                            <Typography>Order Details</Typography>
                        </div>
                        {
                            !loadingOrderLines && !isError ?
                                <div className="mainContainer" style={{ padding: "20px" }}>
                                    <Grid container style={{ margin: "0px" }}>
                                        {pickUpStore &&
                                            <Grid item xs={12} sm={12}>
                                                <Typography style={{ fontSize: "11px" }}>Store Number: <b>{pickUpStore}</b></Typography>
                                            </Grid>}
                                        <Grid item xs={12} sm={12}>
                                            <Typography style={{ fontSize: "11px" }}>Level of Service: <b>{levelOfService}</b></Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <Typography style={{ fontSize: "11px" }}>Fulfilment Type: <b>{Utils.changeFulFillmentType(fulfillmentType)}</b></Typography>
                                        </Grid>
                                    </Grid>
                                </div>
                                :
                                <div className="mainContainer" style={{ padding: "20px" }}>
                                    <OrderDetailMobileShimmer></OrderDetailMobileShimmer>
                                </div >
                        }
                    </div> */}
                    <div style={{ display: "none" }}>
                        <OrderHistory store={props.store}></OrderHistory>
                    </div>

                    <Card className='customerDetailsBoxPickOrder'>
                        <CardContent style={{ padding: "1px", background: "#F3F3F4" }}>
                            <Button onClick={() => { setDrawer(true) }} className="MobileDrawerButtons" variant="outlined">Customer Details</Button>
                            <Button onClick={() => { isHistoryLoading ? setHistoryDrawer(false) : setHistoryDrawer(true) }} className="MobileDrawerButtons" variant="outlined">Fulfillment History</Button>
                        </CardContent>
                    </Card>
                </Paper>
                <SwipeableDrawer
                    anchor={"bottom"}
                    open={openHistoryDrawer}
                    onClose={() => { }}
                    onOpen={() => { }}
                    ModalProps={{ onBackdropClick: () => { setHistoryDrawer(false) } }}
                >
                    <OrderHistory store={props.store}></OrderHistory>
                </SwipeableDrawer>
                <Drawer
                    anchor={"bottom"}
                    open={openDrawer}
                    onClose={() => { }}
                    onClick={() => { setDrawer(false) }}
                >
                    <Card style={{ margin: "6px" }}>
                        {
                            !loadingOrderLines ?
                                <React.Fragment>
                                    {
                                        isError == false ?
                                            <CustomerDetails customerDetail={customerDetail}></CustomerDetails>
                                            :
                                            <CustomerDetailShimmerMobile></CustomerDetailShimmerMobile>
                                    }
                                </React.Fragment>
                                :
                                <CustomerDetailShimmerMobile></CustomerDetailShimmerMobile>
                        }
                    </Card>
                </Drawer>
            </div>}
        </div>
    }
    const openPickupSlip = () => {
        const printData = Utils.getPickupSlipDataFromPick(customerDetail, orderDetail)
        setPrintPickupSlipData(printData);
        setShowPickupSlip(showPickupSlip + 1)
    }
    const openPickSlip = () => {
        if (loadingOrderLines == false && isError == false) {
            SetShowPdf(true);
        }
        SetShowPdf(true);

        logger.info({ message: "", tag: Constants.LOG_TYPES.PICK_SLIP });
    }
    const cancelOrder = () => {

        dispatch(allActions.actionLoaderActions.setCancelReason(
            {
                ...cancelReason,
                showModal: true,
                cancelItem: null,
                cancelCompleteOrder: true
            }
        ))

    }

    const logCancelOrderHistory = async () => {
        await OrderManager.saveOrderHistory({
            shipmentKey: shipmentkey,
            logType: "history",
            cancelAction: "Pick",
            transactionType: Constants.OrderHistoryTypes.Cancel_Order,
            transactionDate: moment.utc(moment.tz("America/Los_Angeles")).toISOString()
        });
    }

    const getCancelReasonValues = async (code: any, text: any) => {

        console.log(code, text);
        let cancelReasonObject = { ...cancelReason };

        let payLoad = OMSManager.GetCancelPayload(orderDetail, cancelReasonObject, code, text);

        dispatch(allActions.actionLoaderActions.showLoader());
        if (cancelReasonObject.cancelCompleteOrder) {
            logger.info({ payLoad: payLoad, message: "", tag: Constants.LOG_TYPES.CANCEL_PICK_ORDER });
        }

        await cancelLineCall(payLoad).then((response: any) => {
            if (payLoad?.Shipment?.Action == "cancelAll" && response.success) {
                logCancelOrderHistory();
            }
        })

        dispatch(allActions.actionLoaderActions.setCancelReason(
            Utils.initCanceReasonState()
        ))
        dispatch(allActions.actionLoaderActions.hideLoader());

        if (cancelReasonObject.cancelCompleteOrder || (orderDetail.ShipmentLines && orderDetail.ShipmentLines.ShipmentLine && orderDetail.ShipmentLines.ShipmentLine.length == 1)) {
            navigate("/FulfillOrders");
        }
        else {
            await getPickDetailFromOms()
        }


    }

    const cancelLineCall = async (payLoad: any) => {
        return await OMSManager.GCPickCancelLine(payLoad);
    }

    const imageViewer = (item: any) => {

        let imageData: IImageViewerDetail = {
            imageURL: item.ImageUrl,
            itemDesc: item.ItemDesc,
            itemID: item.OrderLine?.ItemDetails?.Extn?.ExtnPOSItemID,
            skuID: item.ItemID,
            price: item.UnitPrice,
            qty: item.Quantity
        }

        setImagePopUp(true);
        setImageData(imageData);
    }

    const renderExtendedDescription = (item: any) => {
        let extnDesc = "";
        if (item?.OrderLine?.ItemDetails?.Extn?.ExtnIsUsedOrVintageItem == "Y") {
            let description = item?.OrderLine?.ItemDetails?.PrimaryInformation?.ExtendedDescription;
            if (description?.toLowerCase()?.includes(Constants.MESSAGES.PRODUCT_CASE.toLowerCase())) {
                extnDesc = Constants.MESSAGES.PRODUCT_CASE;
            }
            else if (description?.toLowerCase()?.includes(Constants.MESSAGES.PRODUCT_BAG.toLowerCase())) {
                extnDesc = Constants.MESSAGES.PRODUCT_BAG;
            }
        }
        return (extnDesc != ""
            ? <Typography style={{ fontSize: "15px", color: "#D93A2F" }}><b>{extnDesc}</b></Typography>
            : <></>
        );
    }

    const webView = () => {
        let enableConfirmPick = getEnableConformPick();
        const loaderLeft = Utils.isMobile ? "42%" : "48%"

        return (
            <div>
                {showLoader ? <div style={{ position: "absolute", top: "42%", left: loaderLeft }}>
                    <TailSpin color="#D93A2F" height={80} width={80} />
                </div> : ""}
                <SiteBreadCrumbs siteBreadCrumbs={Constants.SiteBreadCrumbs.Pick}></SiteBreadCrumbs>
                <div style={{ margin: "5px" }}>
                    <Paper className='paperToBottomPadding' style={{ padding: "10px" }}>
                        <Grid container spacing={2} display={"flex"}>
                            <Grid item xs={4} sm={4} className='textLeft'>
                                <Typography variant="h6" style={{ padding: "0px" }} className='scanColor'>
                                    Order # {orderNumber}
                                </Typography>
                            </Grid>
                            {Utils.disableUserAction(props.store) && <Grid item xs={8} sm={8} className='textRight'>
                                <Button onClick={() => contactCustomerClick()} style={{ marginTop: 1 }} variant='contained' className='btn' startIcon={<EmailIcon />}>Contact Customer</Button>
                                <Button onClick={() => openPickSlip()} variant='contained' style={{ marginLeft: "5px", marginTop: 1 }} className='btn' startIcon={<BallotIcon />}>Pick Slip</Button>
                                {(navigateTo == Constants.OrderFlowConstants.PICK_PICKUP || navigateTo == Constants.OrderFlowConstants.PICK_PACK_SHIP_RECEIVE_PICKUP) ?
                                    <React.Fragment>
                                        <Button onClick={() => openPickupSlip()} style={{ marginLeft: "5px", marginTop: 1 }} variant='contained' className='btn' startIcon={<PrintIcon />}>Print Pickup Slip</Button>
                                    </React.Fragment>
                                    :
                                    <React.Fragment></React.Fragment>
                                }
                                <Button onClick={() => {
                                    cancelOrder()
                                }} style={{ marginLeft: "5px", marginTop: 1 }} variant='contained' className='btn' startIcon={<HighlightOffIcon />}>Cancel Order</Button>
                            </Grid>}
                        </Grid>
                    </Paper>
                    <Paper style={{ marginTop: "26px" }}>
                        <Grid container spacing={2} display={"flex"} style={{ padding: "0px" }}>
                            <Grid item xs={9} sm={9} style={{ paddingTop: "0px", paddingRight: "4px" }} className='textLeft'>
                                <div className="backdrop">
                                    <div className="title">
                                        <Typography> Order Lines</Typography>
                                    </div>
                                    <div className="mainContainer" style={{ padding: "20px" }}>
                                        <Grid container spacing={2} display={"flex"}>
                                            <Grid item xs={1} sm={1} className='textCenter'>
                                            </Grid>
                                            <Grid item xs={5} sm={5} className='textLeft'>
                                            </Grid>
                                            <Grid item xs={1} sm={1} className='textCenter'>
                                                <Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>
                                                    Qty
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2} sm={2} className='textCenter'>
                                                <Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>
                                                    Remaining
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2} sm={2} className='textCenter'>
                                                <Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>
                                                    Status
                                                </Typography>
                                            </Grid>
                                            {Utils.disableUserAction(props.store) && <Grid item xs={1} sm={1} className='textCenter'>
                                                <Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>
                                                    Actions
                                                </Typography>
                                            </Grid>}
                                        </Grid>
                                        <Divider />
                                        <div>
                                            {
                                                !loadingOrderLines ?
                                                    <React.Fragment>
                                                        {
                                                            isError == false ?
                                                                <React.Fragment>
                                                                    {
                                                                        orderDetail?.ShipmentLines?.ShipmentLine?.map((item: any, index: any) => {
                                                                            return (
                                                                                <React.Fragment>
                                                                                    <Grid container spacing={2} display={"flex"} key={index}>
                                                                                        <Grid item xs={6} sm={6} className='textLeft'>
                                                                                            <Grid container spacing={2} style={{ margin: "0px" }} sx={{ flexGrow: 1 }}>
                                                                                                <Grid item xs={4} sm={4} style={{ padding: "8px", borderRight: "1px solid #2f2d2d", display: "flex", justifyContent: "center" }}>
                                                                                                    <div className="image-zoom" onClick={() => imageViewer(item)}>
                                                                                                        <img style={{ height: "auto", width: "100%" }} alt="" src={Utils.convertToHttps(item.ImageUrl)} />
                                                                                                    </div>
                                                                                                </Grid>
                                                                                                <Grid item xs={8} sm={8} style={{ padding: "8px", textAlign: "left" }}>
                                                                                                    {
                                                                                                        item.KitCode != "" ?
                                                                                                            <Chip
                                                                                                                className="chipTracking"
                                                                                                                label={item.KitCode == "BUNDLE" ? "SET" : item.KitCode}
                                                                                                                style={{
                                                                                                                    background: "#dc2b2b",
                                                                                                                    color: "white",
                                                                                                                    fontSize: "11px",
                                                                                                                    height: "20px"
                                                                                                                }}
                                                                                                            />
                                                                                                            :
                                                                                                            ""
                                                                                                    }
                                                                                                    {
                                                                                                        item.WarrantyComponents ?
                                                                                                            <Chip
                                                                                                                className="chipTracking"
                                                                                                                label={"PRO COV"}
                                                                                                                style={{
                                                                                                                    background: "#dc2b2b",
                                                                                                                    color: "white",
                                                                                                                    fontSize: "11px",
                                                                                                                    height: "20px"
                                                                                                                }}
                                                                                                            />
                                                                                                            :
                                                                                                            ""
                                                                                                    }
                                                                                                    <Typography style={{ fontSize: "15px", fontWeight: 500, color: "#D93A2F" }}>{item.ItemDesc}</Typography>
                                                                                                    {
                                                                                                        renderExtendedDescription(item)
                                                                                                    }

                                                                                                    <Typography variant="body2" sx={{ fontSize: 15 }} gutterBottom>
                                                                                                        Item ID: <b>{item.OrderLine?.ItemDetails?.Extn?.ExtnPOSItemID}</b>
                                                                                                    </Typography>
                                                                                                    <Typography variant="body2" sx={{ fontSize: 15 }} gutterBottom>
                                                                                                        EID: <b>{item.ItemID}</b>
                                                                                                    </Typography>
                                                                                                    <Typography variant="body2" sx={{ fontSize: 15 }} gutterBottom>
                                                                                                        Price: <b>${item.UnitPrice} </b>
                                                                                                    </Typography>
                                                                                                    <POSInventory data={item} main="main" ></POSInventory>

                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                        <Grid item xs={1} sm={1} className='textCenter' style={{ marginTop: "8px" }}>
                                                                                            <Typography variant="body2" sx={{ fontSize: 15 }} gutterBottom>
                                                                                                {item.Quantity}
                                                                                            </Typography>
                                                                                        </Grid>
                                                                                        <Grid item xs={2} sm={2} className='textCenter' style={{ marginTop: "8px" }}>
                                                                                            <Typography variant="body2" sx={{ fontSize: 15 }} gutterBottom style={{}}>
                                                                                                {
                                                                                                    item.KitCode == "" ?
                                                                                                        <React.Fragment>
                                                                                                            {
                                                                                                                item.ScannedQty ?
                                                                                                                    Number(item.Quantity) - Number(item.ScannedQty)
                                                                                                                    :
                                                                                                                    item.Quantity
                                                                                                            }
                                                                                                        </React.Fragment>
                                                                                                        :
                                                                                                        ""
                                                                                                }
                                                                                            </Typography>
                                                                                        </Grid>
                                                                                        <Grid item xs={2} sm={2} className='textCenter' style={{ marginTop: "8px" }}>
                                                                                            <Typography variant="body2" sx={{ fontSize: 15 }} gutterBottom>
                                                                                                {
                                                                                                    item.KitCode == "" ?
                                                                                                        <React.Fragment>
                                                                                                            {item.IsScanned ?
                                                                                                                <p style={{ color: (Number(item.Quantity) - Number(item.ScannedQty)) == 0 ? "green" : "#ADB301", margin: 0 }}><b>Scanned/In Picking</b></p>
                                                                                                                :
                                                                                                                <b>Created</b>
                                                                                                            }
                                                                                                        </React.Fragment>
                                                                                                        :
                                                                                                        ""
                                                                                                }
                                                                                            </Typography>
                                                                                        </Grid>
                                                                                        {Utils.disableUserAction(props.store) && <Grid item xs={1} sm={1} className='textCenter'>
                                                                                            <ItemAction item={item} openResources={resourceClick} openCustomerContact={contactCustomerClick} ShipmentLineKey={item.ShipmentLineKey} />
                                                                                        </Grid>}
                                                                                    </Grid>
                                                                                    <div style={{ padding: "", background: "#ececec", marginTop: "", border: "" }}>

                                                                                        {
                                                                                            item.CustomComponents && item.CustomComponents.ShipmentLine.length > 0 ?
                                                                                                item.CustomComponents?.ShipmentLine?.map((item: any) => {
                                                                                                    return (

                                                                                                        <React.Fragment>
                                                                                                            <Grid container spacing={2} display={"flex"} key={index}>
                                                                                                                <Grid item xs={6} sm={6} className='textLeft' style={{ paddingTop: 0 }}>
                                                                                                                    <Grid container spacing={2} style={{ margin: "0px" }} sx={{ flexGrow: 1 }}>
                                                                                                                        <Grid item xs={4} sm={4} style={{ padding: "8px", borderRight: "1px solid #2f2d2d", display: "flex", justifyContent: "center" }}>
                                                                                                                            <div className="image-zoom" onClick={() => imageViewer(item)}>
                                                                                                                                <img style={{ height: "auto", width: "100%" }} alt="" src={Utils.convertToHttps(item.ImageUrl)} />
                                                                                                                            </div>
                                                                                                                        </Grid>
                                                                                                                        <Grid item xs={8} sm={8} style={{ padding: "8px", textAlign: "left" }}>
                                                                                                                            {
                                                                                                                                item.WarrantyComponents ?
                                                                                                                                    <Chip
                                                                                                                                        className="chipTracking"
                                                                                                                                        label={"PRO COV"}
                                                                                                                                        style={{
                                                                                                                                            background: "#dc2b2b",
                                                                                                                                            color: "white",
                                                                                                                                            fontSize: "11px",
                                                                                                                                            height: "20px"
                                                                                                                                        }}
                                                                                                                                    />
                                                                                                                                    :
                                                                                                                                    ""
                                                                                                                            }
                                                                                                                            <Typography style={{ fontSize: "15px", fontWeight: 500, color: "#D93A2F" }}>{item.ItemDesc}</Typography>
                                                                                                                            <Typography variant="body2" sx={{ fontSize: 15 }} gutterBottom>
                                                                                                                                Item ID: <b>{item.OrderLine?.ItemDetails?.Extn?.ExtnPOSItemID}</b>
                                                                                                                            </Typography>
                                                                                                                            <Typography variant="body2" sx={{ fontSize: 15 }} gutterBottom>
                                                                                                                                EID: <b>{item.ItemID}</b>
                                                                                                                            </Typography>
                                                                                                                            <Typography variant="body2" sx={{ fontSize: 15 }} gutterBottom>
                                                                                                                                Price: <b>${item.UnitPrice}</b>
                                                                                                                            </Typography>
                                                                                                                            <POSInventory data={item} custom="custom" ></POSInventory>
                                                                                                                        </Grid>
                                                                                                                    </Grid>
                                                                                                                </Grid>
                                                                                                                <Grid item xs={1} sm={1} className='textCenter' style={{ marginTop: "8px" }}>
                                                                                                                    <Typography variant="body2" sx={{ fontSize: 15 }} gutterBottom>
                                                                                                                        {item.Quantity}
                                                                                                                    </Typography>
                                                                                                                </Grid>
                                                                                                                <Grid item xs={2} sm={2} className='textCenter' style={{ marginTop: "8px" }}>
                                                                                                                    <Typography variant="body2" sx={{ fontSize: 15 }} gutterBottom>
                                                                                                                        {
                                                                                                                            item.ScannedQty ?
                                                                                                                                Number(item.Quantity) - Number(item.ScannedQty)
                                                                                                                                :
                                                                                                                                item.Quantity
                                                                                                                        }
                                                                                                                    </Typography>
                                                                                                                </Grid>
                                                                                                                <Grid item xs={2} sm={2} className='textCenter' style={{ marginTop: "8px" }}>
                                                                                                                    <Typography variant="body2" sx={{ fontSize: 15 }} gutterBottom>
                                                                                                                        {item.IsScanned ? <p style={{ color: (Number(item.Quantity) - Number(item.ScannedQty)) == 0 ? "green" : "#ADB301", margin: 0 }}><b>Scanned/In Picking</b></p> : <b>Created</b>}
                                                                                                                    </Typography>
                                                                                                                </Grid>
                                                                                                            </Grid>
                                                                                                        </React.Fragment>
                                                                                                    );
                                                                                                })
                                                                                                :
                                                                                                <div></div>
                                                                                        }
                                                                                    </div>
                                                                                    <Divider></Divider>
                                                                                </React.Fragment>
                                                                            );
                                                                        })
                                                                    }
                                                                </React.Fragment>
                                                                :
                                                                <OrderLineShimmer></OrderLineShimmer>
                                                        }
                                                    </React.Fragment>
                                                    :
                                                    <OrderLineShimmer></OrderLineShimmer>

                                            }

                                        </div>
                                    </div >
                                </div>
                                <OrderDetailGrid loading={loadingOrderLines} data={orderDetails}></OrderDetailGrid>
                                {/* <div className="backdrop">
                                    <div className='title'>
                                        <Typography>Order Details</Typography>
                                    </div>
                                    {
                                        !loadingOrderDetails && !isError ?

                                            <div className="mainContainer" style={{ padding: "20px" }}>
                                                <Grid container display={"flex"} style={{ justifyContent: "space-around" }}>
                                                    {storeNumber && <Grid style={{ display: "flex" }}>
                                                        <Typography style={{ fontSize: "15px", fontWeight: 500 }}>
                                                            Store Number:
                                                        </Typography>
                                                        <Typography style={{ fontSize: "15px", marginLeft: "2px" }}>
                                                            {storeNumber}
                                                        </Typography>
                                                    </Grid>}
                                                    <Grid item style={{ display: "flex" }}>
                                                        <Typography style={{ fontSize: "15px", fontWeight: 500 }}>
                                                            Level of Service:
                                                        </Typography>
                                                        <Typography style={{ fontSize: "15px", marginLeft: "2px" }}>
                                                            {levelOfService}
                                                        </Typography>

                                                    </Grid>
                                                    <Grid item style={{ display: "flex" }}>
                                                        <Typography style={{ fontSize: "15px", fontWeight: 500 }}>
                                                            Fulfilment Type:
                                                        </Typography>
                                                        <Typography style={{ fontSize: "15px", marginLeft: "2px" }}>
                                                            {Utils.changeFulFillmentType(fulfillmentType)}
                                                        </Typography>
                                                    </Grid>

                                                </Grid>
                                            </div>
                                            :
                                            <div className="mainContainer" style={{ padding: "20px" }}>
                                                <OrderDetailShimmer></OrderDetailShimmer>
                                            </div >
                                    }


                                </div> */}
                                <div className="backdrop">
                                    <div className="title">
                                        <Typography> Customer Details</Typography>
                                    </div>
                                    {
                                        !loadingOrderLines ?
                                            <CustomerDetails customerDetail={customerDetail}></CustomerDetails>
                                            :
                                            <div className="mainContainer" style={{ padding: "20px" }}>
                                                <CustomerDetailShimmer></CustomerDetailShimmer>
                                            </div >

                                    }
                                </div >
                            </Grid>
                            <Grid item xs={3} sm={3} style={{ paddingTop: "0px", paddingRight: "0px", paddingLeft: "6px" }} className='textRight' >
                                <div className="backdrop">
                                    <div className="title">
                                        <Typography>Scan/Enter Bar Codes</Typography>
                                    </div>
                                    <div className="mainContainer" style={{ padding: "10px" }}>
                                        <Grid container>
                                            {Utils.disableUserAction(props.store) && <Grid item xs={12} sm={12} className='inputField'>
                                                <Typography>
                                                    SKU # / UPC /Serial #
                                                </Typography>

                                                <TextField value={skuToScan} onKeyDown={keyPress} onChange={(event) => setSKUNumberForScan(event.target.value)} variant={"outlined"} InputLabelProps={{ shrink: false }} fullWidth label="" placeholder='SKU # / UPC /Serial #' id="fullWidth" InputProps={{
                                                    endAdornment: (<InputAdornment position="end"><IconButton onClick={() => {

                                                        scanItemId();

                                                    }} aria-label="Enter"> <KeyboardReturnIcon style={{ color: Constants.Colors.red, cursor: "pointer" }} /> </IconButton></InputAdornment>)
                                                }} />
                                            </Grid>}
                                            <Grid item xs={12} sm={12} className='inputField'>
                                                <Typography>
                                                    Quantity
                                                </Typography>

                                                <TextField value={scannedQty} onChange={(event) => setScannedQty(event.target.value)} variant={"outlined"} type={"number"} InputProps={{ inputProps: { min: 1 } }} defaultValue={1} InputLabelProps={{ shrink: false }} fullWidth label="" placeholder='Quantity' id="fullWidth" />
                                            </Grid>
                                        </Grid>
                                    </div >
                                </div>
                                <div className="backdrop">
                                    <div className="title">
                                        <Typography>
                                            Picked Items
                                        </Typography>
                                    </div>
                                    <div className="mainContainer" style={{ padding: "10px" }}>
                                        <Grid item xs={12} md={12}>
                                            <List >
                                                {
                                                    pickedItems.length == 0 ?
                                                        <Typography style={{ textAlign: "center" }}>Scan Item ID To Continue</Typography>
                                                        :
                                                        <></>
                                                }
                                                {
                                                    pickedItems.map((pickedItem: any) => {
                                                        return (
                                                            <Card style={{ marginBottom: 3 }}>
                                                                <CardContent style={{ padding: 0 }}>
                                                                    <ListItem
                                                                        style={{ paddingLeft: 8, paddingTop: 0, paddingBottom: 0 }}
                                                                        secondaryAction={
                                                                            <IconButton edge="end" aria-label="delete">
                                                                                <CloseIcon onClick={() => cancelPickScan(pickedItem.shipmentLineKey)} color='error' />
                                                                            </IconButton>
                                                                        }
                                                                    >
                                                                        <ListItemText
                                                                            primary={
                                                                                <React.Fragment>
                                                                                    <Typography style={{ color: "#D93A2F", fontSize: "12px" }}>
                                                                                        {pickedItem.ItemDesc}
                                                                                    </Typography>
                                                                                    <React.Fragment>
                                                                                        <b>Item ID: </b>{pickedItem.posItemId}
                                                                                    </React.Fragment>
                                                                                </React.Fragment>
                                                                            }
                                                                            secondary={
                                                                                <React.Fragment>
                                                                                    <Typography
                                                                                        sx={{ display: "inline" }}
                                                                                        component="span"
                                                                                        variant="body2"
                                                                                        color="text.primary"
                                                                                    >
                                                                                        <b>{"SKU: "}</b>{pickedItem.SKUNumber}{" "}<b>{"QTY: "}</b>{pickedItem.Quantity}
                                                                                    </Typography>
                                                                                </React.Fragment>
                                                                            }
                                                                        />
                                                                    </ListItem>
                                                                </CardContent>
                                                            </Card>
                                                        );
                                                    })
                                                }
                                            </List>

                                        </Grid>
                                        <Button onClick={() => pickCompleteClick()} style={{ marginTop: "20px", width: "100%" }} variant='contained' className={enableConfirmPick ? "btn" : "btn disabledButton"} disabled={!enableConfirmPick} startIcon={<CheckIcon />}>Confirm Pick</Button>
                                    </div>
                                </div>
                                <div>
                                    <OrderHistory store={props.store}></OrderHistory>
                                </div>
                            </Grid>
                        </Grid>
                    </Paper>
                </div >
            </div >
        );
    }
    return (
        <div>
            <NotificationPopup {...success}></NotificationPopup>
            <NotificationPopup {...pickCompleteDialog}>
            </NotificationPopup>
            <NotificationPopup {...resourcePopup}>
            </NotificationPopup>
            <NotificationPopup {...contactCustomerPopup}>
            </NotificationPopup>
            <NotificationPopup {...notesCustomerPopup}>
            </NotificationPopup>
            <NotificationPopup {...Error}>
            </NotificationPopup>
            <NotificationPopup {...navigateToFulfillOrders}>
            </NotificationPopup>
            <ImageViewerPopup {...imageViewerSettings}>
            </ImageViewerPopup>
            {Utils.isMobile ? mobileView() : webView()}
            {
                showPdf ?
                    <React.Fragment>
                        <PickSlip shipmentKey={shipmentkey} close={closePdfiframe} ></PickSlip>
                    </React.Fragment>
                    :
                    ""
            }
            <NotificationPopup {...GeneralError}>
            </NotificationPopup>
            {cancelReason.showModal ? <CancelLine getCancelReasonValues={getCancelReasonValues} isExternal={false}></CancelLine> : ""}
            {
                dynamicHtml != "" ?
                    <Dialog fullScreen={Utils.isMobile ? true : false} disableEnforceFocus fullWidth={true} maxWidth="lg" open={openEmailTemplate} TransitionComponent={Transition} scroll={"body"} >
                        <DialogTitle>
                            <IconButton
                                aria-label="close"
                                onClick={() => setOpenEmailTemplate(false)}
                                sx={{
                                    position: 'absolute',
                                    right: 0,
                                    top: 0,
                                    color: Constants.Colors.grey,
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent id="" >
                            <React.Fragment>
                                <div dangerouslySetInnerHTML={{ __html: dynamicHtml }} />
                            </React.Fragment>
                        </DialogContent>
                    </Dialog>
                    :
                    <></>
            }
            {
                showPickupSlip > 0 ?
                    <PickupPrintSlip loadingOrderLines={loadingOrderLines} showPickupSlip={showPickupSlip} saveType={"print"} CustomIPickupSlip={printPickupSlipData}></PickupPrintSlip>
                    :
                    <div></div>
            }
        </div>

    );
}
export default PickOrder;
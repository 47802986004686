import { Box, Card, CardContent, Dialog, DialogContent, DialogTitle, Divider, IconButton, Link, Tooltip, Typography } from "@mui/material";
import { Slide } from '@material-ui/core';
import { TransitionProps } from "@mui/material/transitions";
import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import DataTable from "react-data-table-component";
import { CloseIcon } from "../Shared/SharedIcons";
import Utils from "../../Common/Utils";
import DataNotFound from "../Shared/DataNotFound";
import { OrderFulFilmentDetailsGrid } from "./OrderFulFilmentDetailsGrid";
import ReportManager from "../../Managers/Reports.Manager";
import Constants from "../../Common/Constants";
import ChartShimmer from "../Shared/ChartShimmer";
import { Bars } from "react-loader-spinner";
import allActions from "../../Store/Actions/AllActions"
var moment = require('moment');


const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const OrdersDataGrid = (props: any) => {
  const dispatch = useDispatch();
  const [gridPopUp, setGridPopUp] = useState(false);

  const [orderFulfilmentLoader, setOrderFulfilmentLoader] = useState(false);
  const [selectedStoreName, setSelectedStoreName] = useState("");
  const [orderType, setOrderType] = useState("");
  const [ordersData, setOrdersData] = useState([] as any);
  const showActionLoader = () => {
    dispatch(allActions.actionLoaderActions.showLoader());
  }
  const hideActionLoader = () => {
    dispatch(allActions.actionLoaderActions.hideLoader());
  }
  const handleShippedClick = async (row: any) => {
    setOrderFulfilmentLoader(true);
    setSelectedStoreName(row.storeName)
    showActionLoader()
    let payload = {
      store: [row.storeId],
      timeZone: moment().format('Z'),
      startDate: props.dateFilter.start,
      endDate: props.dateFilter.end,
      orderType: Constants.ORDER_TYPE.SHIPPING
    };
    setOrderType("Shipped")
    const orderFulFillmentRes = await ReportManager.getFulfilledOrdersView(payload)
    setOrdersData(orderFulFillmentRes)
    setOrderFulfilmentLoader(false);
    hideActionLoader()
    setGridPopUp(true)
  }

  const handleUsedClick = async (row: any) => {
    setOrderFulfilmentLoader(true);
    setSelectedStoreName(row.storeName)
    showActionLoader()
    let payload = {
      store: [row.storeId],
      timeZone: moment().format('Z'),
      startDate: props.dateFilter.start,
      endDate: props.dateFilter.end,
      orderType: Constants.ORDER_TYPE.USED
    };
    setOrderType("Used")
    const orderFulFillmentRes = await ReportManager.getFulfilledOrdersView(payload)
    setOrdersData(orderFulFillmentRes)
    setOrderFulfilmentLoader(false);
    hideActionLoader()
    setGridPopUp(true)

  }

  const handlePickupClick = async (row: any) => {
    setOrderFulfilmentLoader(true);
    setSelectedStoreName(row.storeName)
    showActionLoader()
    let payload = {
      store: [row.storeId],
      timeZone: moment().format('Z'),
      startDate: props.dateFilter.start,
      endDate: props.dateFilter.end,
      orderType: Constants.ORDER_TYPE.IN_STORE
    };
    setOrderType("Pickup")
    const orderFulFillmentRes = await ReportManager.getFulfilledOrdersView(payload)
    setOrdersData(orderFulFillmentRes)
    setOrderFulfilmentLoader(false);
    hideActionLoader()
    setGridPopUp(true)

  }

  const closeGridPopup = () => {
    setGridPopUp(false);
  }


  const close = () => {
    props.close()
  }

  const getColumns = () => {
    const columns = [
      {
        id: "district",
        name: 'District',
        sortable: true,
        width: "160px",
        cell: (row: any) => row.district
      },
      {
        id: "totalNumbers",
        name: 'Total Orders',
        sortable: true,
        width: "140px",
        selector: (row: any) => (row.shippedNumbers + row.customerPickedupNumbers)
      },
      {
        id: "value",
        name: 'Total Value',
        sortable: true,
        width: "150px",
        selector: (row: any) => "$" + Utils.nFormatter((row.shippedValue + row.customerPickedupValue), 2)
      },
      {
        id: "shiped",
        name: <Tooltip title="Ship from Store"><div>SFS</div></Tooltip>,
        sortable: true,
        width: "150px",
        selector: (row: any) => row.shippedNumbers
      },
      {
        id: "shippedUsedItemsNumber",
        name: 'SFS Used Items Total',
        sortable: true,
        width: "170px",
        selector: (row: any) => row.shippedUsedItemsNumbers
      },
      {
        id: "shippedUsedItemsValue",
        name: 'SFS Used Items Value',
        width: "170px",
        selector: (row: any) => "$" + Utils.nFormatter(row.shippedUsedItemsValue, 2)
      },
      {
        id: "customerPickedup",
        name: <Tooltip title="Buy online Pickup in Store"><div>BOPIS</div></Tooltip>,
        sortable: true,
        width: "150px",
        selector: (row: any) => row.customerPickedupNumbers
      },
      {
        id: "inStoreUsedItemsNumber",
        name: 'In-Store Used Items Total',
        sortable: true,
        width: "170px",
        selector: (row: any) => row.inStoreUsedItemsNumbers
      },
      {
        id: "inStoreUsedItemsValue",
        name: 'In-Store Used Items Value',
        sortable: true,
        width: "170px",
        selector: (row: any) => "$" + Utils.nFormatter(row.inStoreUsedItemsValue, 2)
      },

    ];
    return columns;
  }

  const viewOrders = (row: any) => {
    return <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center'
      }}
    >

      {/* {orderFulfilmentLoader && <Bars color="#D93A2F" height={50} width={50} />} */}
      {<Link underline="always" style={{ marginRight: "5px", cursor: "pointer" }} onClick={() => handleShippedClick(row)} >
        Shipped
      </Link>}
      {<Link underline="always" style={{ marginRight: "5px", cursor: "pointer" }} onClick={() => handlePickupClick(row)} >
        Pickup
      </Link>}
      {<Link underline="always" style={{ cursor: "pointer" }} onClick={() => handleUsedClick(row)}>
        Used
      </Link>}

    </Box>
  }

  const getExpandedColumns = () => {
    const columns = [
      {
        id: "viewOrders",
        name: 'View Orders',
        sortable: false,
        width: "180px",
        cell: (row: any) => viewOrders(row)
      },
      {
        id: "store",
        name: 'Store',
        sortable: true,
        width: "160px",
        cell: (row: any) => row.storeName
      },
      {
        id: "totalNumbers",
        name: 'Total Orders',
        sortable: true,
        width: "140px",
        selector: (row: any) => (row.shippedNumbers + row.customerPickedupNumbers)
      },
      {
        id: "value",
        name: 'Total Value',
        sortable: true,
        width: "150px",
        selector: (row: any) => "$" + Utils.nFormatter((row.shippedValue + row.customerPickedupValue), 2)
      },
      {
        id: "shipped",
        name: 'Shipped',
        sortable: true,
        width: "140px",
        selector: (row: any) => row.shippedNumbers
      },
      {
        id: "storeShippedUsedItemsNumber",
        name: 'Shipped Used Items Total',
        sortable: true,
        width: "170px",
        selector: (row: any) => row.shippedUsedItemsNumbers
      },
      {
        id: "storeShippedUsedItemsValue",
        name: 'Shipped Used Items Value',
        sortable: true,
        width: "170px",
        selector: (row: any) => "$" + Utils.nFormatter(row.shippedUsedItemsValue, 2)
      },
      {
        id: "customerPickedup",
        name: 'In-Store Pickup',
        sortable: true,
        width: "150px",
        selector: (row: any) => row.customerPickedupNumbers
      },
      {
        id: "inStoreUsedItemsNumberStore",
        name: 'In-Store Used Items Total',
        sortable: true,
        width: "170px",
        selector: (row: any) => row.inStoreUsedItemsNumbers
      },
      {
        id: "inStoreUsedItemsValueStore",
        name: 'In-Store Used Items Value',
        sortable: true,
        width: "170px",
        selector: (row: any) => "$" + Utils.nFormatter(row.inStoreUsedItemsValue, 2)
      },
    ];
    return columns;
  }

  const expandedRows = (props: any) => {
    const { storeFulfilledData } = props.data;
    return (

      <Card id='cardOrder' style={{ margin: "5px", width: "98%", height: "98%" }}>
        <CardContent style={{ padding: 3 }}>
          <div className="dataTableDashboardExpanded">
            <DataTable
              title=""
              className=''
              noDataComponent={<DataNotFound></DataNotFound>}
              data={storeFulfilledData}
              columns={getExpandedColumns()}
            />
          </div >
        </CardContent >
      </Card >

    );
  }
  return (
    <React.Fragment>
      <Dialog disableEnforceFocus open={props.open} fullWidth maxWidth={"xl"} TransitionComponent={Transition}>
        <DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => { close() }}
            sx={{
              position: 'absolute',
              right: 0,
              top: 0,
              color: "#D93A2F",
            }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography style={{ display: "flex", justifyContent: "center", marginBottom: "5px", color: "#D93A2F", fontWeight: 500 }} variant="h5">Orders Fulfilled Summary</Typography>
          {props.data && props.data.length > 1 ? <div className='dataTableRounded'>
            <DataTable
              className='dataTableRounded'
              title=""
              data={props.data}
              columns={getColumns()}
              noDataComponent={<DataNotFound></DataNotFound>}
              expandableRows
              expandableRowsComponent={expandedRows}
            />
          </div>
            :
            <Card id='cardOrder' style={{ margin: "5px", width: "98%", height: "98%" }}>
              <CardContent style={{ padding: 3 }}>
                <div className="dataTableDashboardExpanded">
                  <DataTable
                    title=""
                    className=''
                    data={props.data[0] && props.data[0].storeFulfilledData ? props.data[0].storeFulfilledData : []}
                    columns={getExpandedColumns()}
                    noDataComponent={<DataNotFound></DataNotFound>}
                  />
                </div >
              </CardContent >
            </Card >
          }
        </DialogContent>
      </Dialog>
      {
        gridPopUp && <OrderFulFilmentDetailsGrid open={gridPopUp} close={closeGridPopup} data={ordersData} selectedStore={selectedStoreName} orderType={orderType} />
      }
    </React.Fragment>
  );
}
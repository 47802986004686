import { Card, CardContent, Dialog, DialogContent, DialogTitle, Divider, IconButton, Typography } from "@mui/material";
import { Slide } from '@material-ui/core';
import { TransitionProps } from "@mui/material/transitions";
import React from "react";
import DataTable from "react-data-table-component";
import { CloseIcon } from "../Shared/SharedIcons";
import IdentityManager from "../../Managers/Identity.manager";
import { width } from "@mui/system";
import Utils from "../../Common/Utils";
import { IDistrictOrderTimeData } from "../../Models/IOrderTimeReport"
import DataNotFound from "../Shared/DataNotFound";

export const OrderTimeGrid = (props: any) => {

  const getDaysAndHours = (ms: number) => {
    const obj = Utils.convertHoursToDays(ms)
    if (obj.Days && obj.Hours) {
      return `${obj.Days}day(s) - ${obj.Hours}hr(s)`
    }
    if (obj.Days) {
      return `${obj.Days}day(s) `
    }
    if (obj.Hours) {
      return `${obj.Hours}hr(s) `
    }
    if (obj.Minutes) {
      return `${obj.Minutes}min(s) `
    } if (obj.Sec) {
      return `${obj.Sec}sec(s) `
    }
    else { return `-` }
  }
  const getDistrictPickAverage = (row: IDistrictOrderTimeData) => {
    let districtAverage = 0
    if (row.orderTimeStoresData) {
      let storesCount = row.orderTimeStoresData.filter((x => x.pick > 0))
      if (row.pick > 0 && storesCount.length) {
        districtAverage = row.pick / storesCount.length
      }
    }

    return getDaysAndHours(districtAverage)
  }
  const getDistrictPackAverage = (row: IDistrictOrderTimeData) => {
    let districtAverage = 0
    if (row.orderTimeStoresData) {
      let storesCount = row.orderTimeStoresData.filter((x => x.pack > 0))
      if (row.pack > 0 && storesCount.length) {
        districtAverage = row.pack / storesCount.length
      }
    }
    return getDaysAndHours(districtAverage)
  }
  const getDistrictShipAverage = (row: IDistrictOrderTimeData) => {

    console.log(row, "row")
    let districtAverage = 0
    if (row.orderTimeStoresData) {
      let storesCount = row.orderTimeStoresData.filter((x => x.ship > 0))
      console.log(storesCount, "storesCount");

      if (row.ship > 0 && storesCount.length) {
        districtAverage = row.ship / storesCount.length
      }
    }

    return getDaysAndHours(districtAverage)
  }
  const getColumns = () => {
    const columns = [
      {
        id: "district",
        name: 'District',
        cell: (row: any) => row.district,
        width: "220px",

      },
      {
        id: "pick",
        name: 'Pick',
        selector: (row: any) => getDistrictPickAverage(row)
      },
      {
        id: "pack",
        name: 'Pack',
        selector: (row: any) => getDistrictPackAverage(row)
      },
      {
        id: "ship",
        name: 'Ship',
        selector: (row: any) => getDistrictShipAverage(row)
      }
    ];
    return columns;
  }

  const getExpandedColumns = () => {
    const columns = [
      {
        id: "store",
        name: 'Store',
        cell: (row: any) => row.storeName,
        width: "220px"
      },
      {
        id: "send",
        name: 'Pick',
        selector: (row: any) => getDaysAndHours(row.pick)
      },
      {
        id: "responses",
        name: 'Pack',
        selector: (row: any) => getDaysAndHours(row.pack)
      },
      {
        id: "responses",
        name: 'Ship',
        selector: (row: any) => getDaysAndHours(row.ship)
      }
    ];
    return columns;
  }

  const expandedRows = (props: any) => {

    const { orderTimeStoresData } = props.data;

    return (
      <>
        {/* {<Card id='cardOrder' style={{ margin: "5px", width: "98%", height: "98%" }}>
          <CardContent style={{ padding: 3 }}> */}
        <div className="dataTableDashboardExpanded">
          <DataTable
            title=""
            className=''
            data={orderTimeStoresData}
            columns={getExpandedColumns()}
            noDataComponent={<DataNotFound></DataNotFound>}
            dense
          />
        </div >
        {/* </CardContent >
        </Card >} */
        }
      </>

    );
  }
  return (
    <>
      {
        props.data && props.data.length > 1 ? <div className='dataTableRounded'>
          <DataTable
            className='dataTableRounded'
            title=""
            data={props.data}
            columns={getColumns()}
            expandableRows
            expandableRowsComponent={expandedRows}
            noDataComponent={<DataNotFound></DataNotFound>}
            dense
          />
        </div>
          :
          // <Card id='cardOrder' style={{ margin: "5px", width: "98%", height: "98%" }}>
          //   <CardContent style={{ padding: 3 }}>
          <div className="dataTableDashboardExpanded">
            <DataTable
              title=""
              className=''
              data={props.data[0] && props.data[0].orderTimeStoresData ? props.data[0].orderTimeStoresData : []}
              columns={getExpandedColumns()}
              noDataComponent={<DataNotFound></DataNotFound>}
              dense
            />
          </div >
        //   </CardContent >
        // </Card >
      }</>
  );
}

import { Button, Card, CardContent, Divider, Drawer, Grid, Paper, TextField, Toolbar, Typography, InputAdornment, FormControl, InputLabel, Select, SelectChangeEvent, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton } from '@mui/material';
import Utils from '../../Common/Utils';
import { createTheme, makeStyles } from "@material-ui/core/styles";
import BulkPickBarCodeScannerScandit from '../FulfillOrders/BulkPickBarCodeScannerScandit';
import ItemAction from './ItemAction';
import { NotificationPopup } from '../Shared/NotificationPopup'
import Constants from '../../Common/Constants';
import { INotificationPopupSettings, IButton } from '../../Models/INotificationPopupSettings';
import { Reasons } from "../Shared/Reasons";
import { IReasons } from '../../Models/IReasons';
import { IContactCustomer } from '../../Models/IContactCustomer';
import { ContactCustomer } from '../Shared/ContactCustomer';
import LabelText from "../../Common/LabelText"
import { VerticalLinearStepper } from '../Stepper/VerticalLinearStepper'
import { IVerticalStepper, IStepOption } from '../../Models/IVerticalStepper';
import SiteBreadCrumbs from '../Shared/SiteBreadCrumbs';
import { BallotIcon } from '../Shared/SharedIcons';
import PickSlip from '../FulfillOrders/PickSlip';
import { Note } from "../Shared/Note";
import Tooltip from '@mui/material/Tooltip';
import { ResourceItem } from './ResourceItem';
import { ShoppingCart, ApartmentIcon, DoneIcon, CloseIcon, SendIcon, AddCommentIcon, ArrowBackIcon, DocumentScannerIcon, CommentIcon, HighlightOffIcon, PersonIcon, AddLocationAltIcon, KeyboardReturnIcon, EmailIcon, LocalPhoneIcon, CheckIcon } from "../Shared/SharedIcons"
import PdfIframe from "./PdfIframe"
let step: IStepOption[] = []
step.push({ label: 'Order Release', description: ``, time: 'Jan 20 2022 13:11:17 ' })
step.push({ label: 'Customer Contacted', description: "dummy", time: 'Jan 20 2022 13:11:17 ' })
step.push({ label: 'Picking', description: "", time: '' })
step.push({ label: 'Packing', description: ``, time: '' })
step.push({ label: 'Shipped', description: ``, time: '' })
let verticalStepperOptions: IVerticalStepper = {
    activeStep: "Picking",
    stepsOptions: step
}
const BulkPickOrder = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const orderType = urlParams.get('oType');
    const [openDrawer, setDrawer] = useState(false);
    const [openHistoryDrawer, setHistoryDrawer] = useState(false);
    const [isScan, setScan] = useState(false);
    const [pickComplete, setPickComplete] = useState(false);
    const [cancel, setCancel] = useState(false);
    const [resource, setResource] = useState(false);
    const [contactCustomer, setContactCustomer] = useState(false);
    const [notes, setNotes] = useState("")
    const [notePopup, setNotesPopup] = useState(false);
    const [steps, setSteps] = useState<IVerticalStepper>(verticalStepperOptions)
    const [blobUrl, setBlobUrl] = useState("")
    const [showPdf, SetShowPdf] = useState(false)
    const [Order, setOrder] = React.useState("0");
    const [Order1, setOrder1] = React.useState("1");
    const [Order2, setOrder2] = React.useState("2");
    const useStyles = makeStyles((theme) => ({
        root: {
            backgroundColor: theme.palette.primary.main
        }
    }));
    const classes = useStyles();
    const theme = createTheme({
        palette: {
            primary: {
                main: '#D93A2F',
            },
            secondary: {
                main: '#FFFFFF',
            },
        },
    })
    console.log(classes.root, "asas");
    const TestState = useSelector((state: any) => state.TestReducerState);
    console.log(TestState, "TestState");
    const dispatch = useDispatch();
    const navigate = useNavigate();
    window.scrollTo(0, 0);
    // Scroll
    var startProductBarPos = -1;
    window.onscroll = function () {
        console.log("scrolled");
        var bar = document.getElementById('bar') as any;
        var mblBar = document.getElementById('mblBar') as any;
        if (bar) {
            if (startProductBarPos < 0) startProductBarPos = findPosY(mblBar);
            if (window.pageYOffset > startProductBarPos) {
                bar.classList.add("scrolledTopFix");
            } else {
                bar.classList.remove("scrolledTopFix");
            }
        }
    };
    function findPosY(obj: any) {
        var curtop = 0;
        if (obj && typeof (obj.offsetParent) != 'undefined' && obj.offsetParent) {
            while (obj.offsetParent) {
                curtop += obj.offsetTop;
                obj = obj.offsetParent;
            }
            curtop += obj.offsetTop;
        }
        else if (obj && obj.y)
            curtop += obj.y;
        return curtop;
    }
    const onScannedComplete = (data: any, isError: any) => {
        console.log(data, "Parentdata");
        setScan(false);
    }
    const closePickComplete = () => {
        setPickComplete(false)
    }
    const closeNotes = () => {
        setNotesPopup(false)
    }
    const openNotes = () => {
        setNotesPopup(true)
        setNotes("")
    }
    const changeNote = (event: any) => {
        setNotes(event.target.value);
    }
    const pickCompleteClick = () => {
        setPickComplete(true)
    }
    const cancelClick = () => {
        setCancel(true)
    }
    const resourceClick = () => {
        setResource(true)
    }
    const contactCustomerClick = () => {
        setContactCustomer(true)
    }
    const closeContactCustomer = () => {
        setContactCustomer(false)
    }
    const closeCancel = () => {
        setCancel(false)
    }
    const closeResource = () => {
        setResource(false)
    }
    let orderNumner = "GCW92863826"
    let pickCompleteButtons: IButton[] = [{
        text: "Pick Another",
        icon: <ShoppingCart />,
        action: closePickComplete,
        color: Constants.Colors.red,
    },
    {
        text: "Continue to Pack",
        icon: <ApartmentIcon />,
        action: closePickComplete,
        color: Constants.Colors.red,
    }
    ]
    let pickCompleteDialog: INotificationPopupSettings = {
        open: pickComplete,
        type: Constants.NotificationPopupType.Success,
        title: LabelText.NotificationPopupHeaders.Success,
        msg: LabelText.PickOrder.PickCompleteSuccess,
        handleClose: closePickComplete,
        actions: pickCompleteButtons
    }
    let cancelReasonCodes = ["Customer Changed Mind", "Duplicate Order", "Inventory Error", "NLA Item"]
    let cancelReasons: IReasons = {
        reasonsCodes: cancelReasonCodes
    }

    let cancelPopupButtons: IButton[] = [{
        text: "Yes",
        icon: <DoneIcon />,
        action: closeCancel,
        color: Constants.Colors.yellow,
    },
    {
        text: "No",
        icon: <CloseIcon />,
        action: closeCancel,
        color: Constants.Colors.grey,
    }
    ]
    let cancelPopup: INotificationPopupSettings = {
        open: cancel,
        type: Constants.NotificationPopupType.Warning,
        title: LabelText.NotificationPopupHeaders.Warning,
        msg: LabelText.PickOrder.PickCancelOrderWarning,
        handleClose: closeCancel,
        isCustomJSX: true,
        customJSX: <Reasons {...cancelReasons}></Reasons>,
        actions: cancelPopupButtons
    }
    let resourcePopupButtons: IButton[] = [{
        text: "Yes",
        icon: <DoneIcon />,
        action: closeResource,
        color: Constants.Colors.blue,
    },
    {
        text: "No",
        icon: <CloseIcon />,
        action: closeResource,
        color: Constants.Colors.grey,
    }]
    let resourcePopup: INotificationPopupSettings = {
        open: resource,
        type: Constants.NotificationPopupType.Info,
        title: LabelText.NotificationPopupHeaders.Resource,
        msg: LabelText.PickOrder.PickResourceOrderWarning,
        handleClose: closeResource,
        isCustomJSX: true,
        customJSX: <ResourceItem></ResourceItem>,
        actions: resourcePopupButtons,
        fullWidth: true,
    }
    let contactCustomerSettings: IContactCustomer = {
        customerEmail: "customer.email@mail.com",
        storeEmail: "storemail.email@mail.com",
        template: LabelText.MAILTEMPLATES.notOriginalPackaging,
        templateSubject: LabelText.MAILSUBJECTS.notOriginalPackaging
    }
    let contactCustomerPopupButtons: IButton[] = [{
        text: "Send",
        icon: <SendIcon />,
        action: closeContactCustomer,
        color: Constants.Colors.blue,
    },
    {
        text: "Close",
        icon: <CloseIcon />,
        action: closeContactCustomer,
        color: Constants.Colors.grey,
    }
    ]
    let contactCustomerPopup: INotificationPopupSettings = {
        open: contactCustomer,
        type: Constants.NotificationPopupType.Info,
        title: LabelText.PickOrder.ContactCustomerTitle,
        msg: "",
        handleClose: closeContactCustomer,
        isCustomJSX: true,
        customJSX: <ContactCustomer {...contactCustomerSettings}></ContactCustomer>,
        actions: contactCustomerPopupButtons,
        fullWidth: true,
    }
    const addNotes = () => {
        if (notes != "") {
            let index = Utils.getStepperIndex(verticalStepperOptions, "Picking")
            step.splice(index, 0, { label: 'Note', description: notes, time: 'Jan 20 2022 13:11:17 ' })
            verticalStepperOptions.stepsOptions = step
            setSteps(verticalStepperOptions)
        }
        setNotesPopup(false)
    }
    let notesPopupButtons: IButton[] = [{
        text: "Add",
        icon: <AddCommentIcon />,
        action: addNotes,
        color: Constants.Colors.blue,
    },
    {
        text: "Close",
        icon: <CloseIcon />,
        action: closeNotes,
        color: Constants.Colors.grey,
    }
    ]
    let notesCustomerPopup: INotificationPopupSettings = {
        open: notePopup,
        type: Constants.NotificationPopupType.Info,
        title: LabelText.PickOrder.Note,
        msg: "",
        handleClose: closeNotes,
        isCustomJSX: true,
        customJSX: <Note note={notes} changeNote={changeNote}></Note>,
        actions: notesPopupButtons,
    }
    const closePdfiframe = () => {
        SetShowPdf(false);
    }
    const mobileView = () => {
        const height = Utils.displayHeight + 'px';
        return <React.Fragment >
            {isScan ? <BulkPickBarCodeScannerScandit onScanned={onScannedComplete} /> : <div style={{ paddingBottom: "150px" }}>
                <Paper id='bar' style={{ color: "white", top: 0, left: 0, right: 0, zIndex: 999, borderRadius: 0 }} className="appBarPrimary">
                    <Toolbar style={{ height: "46px", minHeight: "46px" }}>
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mr: 2 }}

                            onClick={() => { navigate(-1) }}
                        >
                            <ArrowBackIcon />
                        </IconButton>
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} style={{ marginLeft: "-48px" }}>
                            {orderType} Order
                        </Typography>
                        <IconButton style={{ background: "#D93A2F", color: "white" }} sx={{ p: '10px' }} aria-label="actions">
                        </IconButton>
                    </Toolbar>
                </Paper>
                <Paper style={{ marginTop: 2, paddingTop: 1, paddingBottom: 1 }}>
                    <Card style={{ margin: "6px" }} id='cardOrder'>
                        <CardContent style={{ padding: "10px" }}>
                            <Grid spacing={2} container style={{ margin: "0px" }} sx={{ flexGrow: 1 }}>
                                <Grid item xs={10} sm={9} style={{ padding: "8px" }}>
                                    <Typography variant="h6" style={{ padding: "0px" }} className='scanColor'>
                                        SKU # 92863826
                                    </Typography>
                                </Grid>
                                <Grid item xs={2} sm={3} style={{ padding: "0px" }}>
                                    <IconButton
                                        onClick={() => { setScan(true) }}
                                        size="large"
                                        edge="start"
                                        color="inherit"
                                        aria-label="menu"
                                        sx={{ mr: 4 }}
                                        className='scanColor'

                                    >
                                        <DocumentScannerIcon style={{ transform: "rotate(90deg)" }} />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                    <Card style={{ margin: "6px" }} >
                        <CardContent style={{ padding: "10px" }}>
                            <Grid spacing={2} container style={{ margin: "0px" }} sx={{ flexGrow: 1 }}>
                                <Grid item xs={4} sm={4} style={{ padding: "8px", borderRight: "1px solid #2f2d2d" }}>
                                    <img style={{ height: "100px" }} alt="" src="https://media.guitarcenter.com/is/image/MMGS7/GC82-Handcrafted-Classical-Guitar-Cedar/J00164000001000-00-220x220.jpg" />
                                </Grid>
                                <Grid item xs={6} sm={6} style={{ padding: "8px", textAlign: "left" }}>
                                    <Typography style={{ fontSize: "13px", fontWeight: 500, color: "#D93A2F" }}>Yamaha GC82 Handcrafted Classical Guitar</Typography>
                                    <Typography style={{ fontSize: "11px" }}>Status: <b>Created</b></Typography>
                                    <Typography style={{ fontSize: "11px" }}>Qty: <b>1</b></Typography>
                                    <Typography style={{ fontSize: "11px" }}>Remaining: <b>1</b></Typography>
                                    <Typography style={{ fontSize: "11px" }}>Order #: <b>GC5456465</b></Typography>
                                </Grid>
                                <Grid item xs={2} sm={2} style={{ padding: 0 }}>
                                    <ItemAction openResources={resourceClick} openCancel={cancelClick} openCustomerContact={contactCustomerClick} />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                    <Card style={{ margin: "6px" }}>
                        <CardContent style={{ padding: "10px" }}>
                            <Grid spacing={2} container style={{ margin: "0px" }} sx={{ flexGrow: 1 }}>
                                <Grid item xs={4} sm={4} style={{ padding: "8px", borderRight: "1px solid #2f2d2d" }}>
                                    <img style={{ height: "100px" }} alt="" src="https://media.guitarcenter.com/is/image/MMGS7/GC82-Handcrafted-Classical-Guitar-Cedar/J00164000001000-00-220x220.jpg" />
                                </Grid>
                                <Grid item xs={6} sm={6} style={{ padding: "8px", textAlign: "left" }}>
                                    <Typography style={{ fontSize: "13px", fontWeight: 500, color: "#D93A2F" }}>Yamaha GC82 Handcrafted Classical Guitar</Typography>
                                    <Typography style={{ fontSize: "11px" }}>Status: <b>Picked</b></Typography>
                                    <Typography style={{ fontSize: "11px" }}>Qty: <b>12</b></Typography>
                                    <Typography style={{ fontSize: "11px" }}>Remaining: <b>1</b></Typography>
                                    <Typography style={{ fontSize: "11px" }}>Order #<b>GC5456465</b></Typography>
                                </Grid>
                                <Grid item xs={2} sm={2} style={{ padding: 0 }}>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                    <Card style={{ margin: "6px" }}>
                        <CardContent style={{ padding: "10px" }}>
                            <Grid spacing={2} container style={{ margin: "0px" }} sx={{ flexGrow: 1 }}>
                                <Grid item xs={4} sm={4} style={{ padding: "8px", borderRight: "1px solid #2f2d2d" }}>
                                    <img style={{ height: "100px" }} alt="" src="https://media.guitarcenter.com/is/image/MMGS7/GC82-Handcrafted-Classical-Guitar-Cedar/J00164000001000-00-220x220.jpg" />
                                </Grid>
                                <Grid item xs={6} sm={6} style={{ padding: "8px", textAlign: "left" }}>
                                    <Typography style={{ fontSize: "13px", fontWeight: 500, color: "#D93A2F" }}>Yamaha GC82 Handcrafted Classical Guitar</Typography>
                                    <Typography style={{ fontSize: "11px" }}>Status: <b>In Picking</b></Typography>
                                    <Typography style={{ fontSize: "11px" }}>Qty: <b>13</b></Typography>
                                    <Typography style={{ fontSize: "11px" }}>Remaining: <b>1</b></Typography>
                                    <Typography style={{ fontSize: "11px" }}>Order #<b>GC5456465</b></Typography>
                                </Grid>
                                <Grid item xs={2} sm={2} style={{ padding: 0 }}>
                                    <ItemAction openResources={resourceClick} openCancel={cancelClick} openCustomerContact={contactCustomerClick} />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                    <Card className='customerDetailsBoxPickOrder' style={{ display: "none" }}>
                        <CardContent style={{ padding: "1px", background: "#F3F3F4" }}>
                            <Button onClick={() => { setDrawer(true) }} className="MobileDrawerButtons" variant="outlined">Customer Details</Button>
                            <Button onClick={() => { setHistoryDrawer(true) }} className="MobileDrawerButtons" variant="outlined">Fulfillment History</Button>
                        </CardContent>
                    </Card>
                </Paper>
                <Drawer
                    anchor={"bottom"}
                    open={openHistoryDrawer}
                    onClose={() => { }}
                    ModalProps={{ onBackdropClick: () => { setHistoryDrawer(false) } }}
                    onClick={() => { setHistoryDrawer(false) }}
                >
                    <Card style={{ margin: "6px" }}>
                        <CardContent style={{ padding: "10px" }}>
                            <Tooltip title="Add Note">
                                <IconButton onClick={() => openNotes()} style={{ padding: 0, float: "right" }}>
                                    <CommentIcon style={{ color: Constants.Colors.red }} />
                                </IconButton>
                            </Tooltip>
                            <VerticalLinearStepper {...steps}></VerticalLinearStepper>
                        </CardContent>
                    </Card>
                </Drawer>
                <Drawer
                    anchor={"bottom"}
                    open={openDrawer}
                    onClose={() => { }}
                    onClick={() => { setDrawer(false) }}
                >
                    <Card style={{ margin: "6px" }}>
                        <CardContent style={{ padding: "10px" }}>
                            <Typography variant="h5" component="div">
                                Sohaib Javed
                                <br />
                            </Typography>
                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                Westlake Vlg 1220 Lindero Canyon Road 91362
                            </Typography>
                            <hr></hr>
                            <Typography variant="body2">
                                Email: &nbsp;&nbsp; Sohaib.Javed@guitarcenter.com
                            </Typography>
                            <Typography variant="body2">
                                Phone: +1 949-4z4-4545
                            </Typography>
                        </CardContent>
                    </Card>
                </Drawer>
            </div>}
        </React.Fragment>
    }
    const webView = () => {
        return (
            <div>
                <SiteBreadCrumbs siteBreadCrumbs={Constants.SiteBreadCrumbs.BulkPick}></SiteBreadCrumbs>
                <div style={{ margin: "5px" }}>
                    <Paper className='paperToBottomPadding' style={{ padding: "10px" }}>
                        <Grid container spacing={2} display={"flex"}>
                            <Grid item xs={6} sm={6} className='textLeft'>
                                <Typography variant="h6" style={{ padding: "0px" }} className='scanColor'>
                                    SKU # W92863826
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={6} className='textRight'>
                                <Button onClick={() => { SetShowPdf(true); }} variant='contained' className='btn' startIcon={<BallotIcon />}>Pick Slip</Button>
                                &nbsp;&nbsp;
                            </Grid>
                        </Grid>
                    </Paper>
                    <Paper style={{ marginTop: "26px" }}>
                        <Grid container spacing={2} display={"flex"}>
                            <Grid item xs={9} sm={9} style={{ paddingTop: "0px", paddingRight: "4px" }} className='textLeft'>
                                <div className="backdrop">
                                    <div className="title">
                                        <Typography>Item Lines</Typography>
                                    </div>
                                    <div className="mainContainer" style={{ padding: "20px" }}>
                                        <Grid container spacing={2} display={"flex"}>
                                            <Grid item xs={1} sm={1} className='textCenter'>
                                            </Grid>
                                            <Grid item xs={5} sm={5} className='textLeft'>
                                            </Grid>
                                            <Grid item xs={1} sm={1} className='textCenter'>
                                                <Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>
                                                    Qty
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2} sm={2} className='textCenter'>
                                                <Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>
                                                    Remaining
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2} sm={2} className='textCenter'>
                                                <Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>
                                                    Status
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={1} sm={1} className='textCenter'>
                                                <Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>
                                                    Actions
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Divider />
                                        <div>
                                            <Grid container spacing={2} display={"flex"}>
                                                <Grid item xs={6} sm={6} className='textLeft'>
                                                    <Grid container spacing={2} style={{ margin: "0px" }} sx={{ flexGrow: 1 }}>
                                                        <Grid item xs={4} sm={4} style={{ padding: "8px", borderRight: "1px solid #2f2d2d", display: "flex", justifyContent: "center" }}>
                                                            <img style={{ height: "100px" }} alt="" src="https://media.guitarcenter.com/is/image/MMGS7/GC82-Handcrafted-Classical-Guitar-Cedar/J00164000001000-00-220x220.jpg" />
                                                        </Grid>
                                                        <Grid item xs={8} sm={8} style={{ padding: "8px", textAlign: "left" }}>
                                                            <Typography style={{ fontSize: "15px", fontWeight: 500, color: "#D93A2F" }}>Fender GC-1 Stratocaster Solid Body Electric Guitar Used</Typography>
                                                            <Typography variant="body2" sx={{ fontSize: 15 }} style={{ marginBottom: 0 }} gutterBottom>
                                                                Price: <b>$1465</b>
                                                            </Typography>
                                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                                <Typography variant="body2" sx={{ fontSize: 15 }} style={{ margin: 0, marginRight: 3 }} gutterBottom>
                                                                    Order #:GC3456463
                                                                </Typography>
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={1} sm={1} className='textCenter' style={{ marginTop: "8px" }}>
                                                    <Typography variant="body2" sx={{ fontSize: 15 }} gutterBottom>
                                                        1
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={2} sm={2} className='textCenter' style={{ marginTop: "8px" }}>
                                                    <Typography variant="body2" sx={{ fontSize: 15 }} gutterBottom>
                                                        1
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={2} sm={2} className='textCenter' style={{ marginTop: "8px" }}>
                                                    <Typography variant="body2" sx={{ fontSize: 15 }} gutterBottom>
                                                        Created
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={1} sm={1} className='textCenter'>
                                                    <ItemAction openResources={resourceClick} openCancel={cancelClick} openCustomerContact={contactCustomerClick} bulk={true} />
                                                </Grid>
                                            </Grid>
                                            <Divider></Divider>
                                            <Grid container spacing={2} display={"flex"}>
                                                <Grid item xs={6} sm={6} className='textLeft'>
                                                    <Grid spacing={2} container style={{ margin: "0px" }} sx={{ flexGrow: 1 }}>
                                                        <Grid item xs={4} sm={4} style={{ padding: "8px", borderRight: "1px solid #2f2d2d", display: "flex", justifyContent: "center" }}>
                                                            <img style={{ height: "100px" }} alt="" src="https://media.guitarcenter.com/is/image/MMGS7/GC82-Handcrafted-Classical-Guitar-Cedar/J00164000001000-00-220x220.jpg" />
                                                        </Grid>
                                                        <Grid item xs={8} sm={8} style={{ padding: "8px", textAlign: "left" }}>
                                                            <Typography style={{ fontSize: "15px", fontWeight: 500, color: "#D93A2F" }}>Fender GC-1 Stratocaster Solid Body Electric Guitar Used</Typography>
                                                            <Typography variant="body2" sx={{ fontSize: 15 }} style={{ marginBottom: 0 }} gutterBottom>
                                                                Price: <b>$1465</b>
                                                            </Typography>
                                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                                <Typography variant="body2" sx={{ fontSize: 15 }} style={{ margin: 0, marginRight: 3 }} gutterBottom>
                                                                    Order #: GC1456461
                                                                </Typography>
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={1} sm={1} className='textCenter' style={{ marginTop: "8px" }}>
                                                    <Typography variant="body2" sx={{ fontSize: 15 }} gutterBottom>
                                                        12
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={2} sm={2} className='textCenter' style={{ marginTop: "8px" }}>
                                                    <Typography variant="body2" sx={{ fontSize: 15 }} gutterBottom>
                                                        1
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={2} sm={2} className='textCenter' style={{ marginTop: "8px" }}>
                                                    <Typography variant="body2" sx={{ fontSize: 15 }} gutterBottom>
                                                        Picked
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={1} sm={1} className='textCenter'>
                                                </Grid>
                                            </Grid>
                                            <Divider></Divider>
                                            <Grid container spacing={2} display={"flex"}>
                                                <Grid item xs={6} sm={6} className='textLeft'>
                                                    <Grid spacing={2} container style={{ margin: "0px" }} sx={{ flexGrow: 1 }}>
                                                        <Grid item xs={4} sm={4} style={{ padding: "8px", borderRight: "1px solid #2f2d2d", display: "flex", justifyContent: "center" }}>
                                                            <img style={{ height: "100px" }} alt="" src="https://media.guitarcenter.com/is/image/MMGS7/GC82-Handcrafted-Classical-Guitar-Cedar/J00164000001000-00-220x220.jpg" />
                                                        </Grid>
                                                        <Grid item xs={8} sm={8} style={{ padding: "8px", textAlign: "left" }}>
                                                            <Typography style={{ fontSize: "15px", fontWeight: 500, color: "#D93A2F" }}>Fender GC-1 Stratocaster Solid Body Electric Guitar Used</Typography>
                                                            <Typography variant="body2" sx={{ fontSize: 15 }} style={{ marginBottom: 0 }} gutterBottom>
                                                                Price: <b>$1465</b>
                                                            </Typography>
                                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                                <Typography variant="body2" sx={{ fontSize: 15 }} style={{ margin: 0, marginRight: 3 }} gutterBottom>
                                                                    Order #: GC2456462
                                                                </Typography>
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={1} sm={1} className='textCenter' style={{ marginTop: "8px" }}>
                                                    <Typography variant="body2" sx={{ fontSize: 15 }} gutterBottom>
                                                        13
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={2} sm={2} className='textCenter' style={{ marginTop: "8px" }}>
                                                    <Typography variant="body2" sx={{ fontSize: 15 }} gutterBottom>
                                                        1
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={2} sm={2} className='textCenter' style={{ marginTop: "8px" }}>
                                                    <Typography variant="body2" sx={{ fontSize: 15 }} gutterBottom>
                                                        In Picking
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={1} sm={1} className='textCenter'>
                                                    <ItemAction openResources={resourceClick} openCancel={cancelClick} openCustomerContact={contactCustomerClick} />
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </div >
                                </div >
                            </Grid>
                            <Grid item xs={3} sm={3} style={{ paddingTop: "0px", paddingRight: "0px", paddingLeft: "6px" }} className='textRight'>
                                <div className="backdrop">
                                    <div className="title">
                                        <Typography>Scan/Enter Bar Codes</Typography>
                                    </div>
                                    <div className="mainContainer" style={{ padding: "10px" }}>
                                        <Grid container>
                                            <Grid item xs={12} sm={12} className='inputField'>
                                                <Typography>
                                                    SKU #
                                                </Typography>
                                                <TextField variant={"outlined"} InputLabelProps={{ shrink: false }} fullWidth label="" placeholder='SKU #' id="fullWidth" InputProps={{ endAdornment: (<InputAdornment position="end"><IconButton aria-label="Enter"> <KeyboardReturnIcon style={{ color: Constants.Colors.red, cursor: "pointer" }} /> </IconButton></InputAdornment>) }} />
                                            </Grid>
                                            <Grid container >
                                                <Grid item xs={4} sm={4} className="inputFieldQuantity" style={{ marginRight: "auto", minWidth: "98% !important", width: "98% !important" }}>
                                                    <Typography>Quantity</Typography>
                                                    <TextField
                                                        style={{}}
                                                        type={"number"}
                                                        InputProps={{ inputProps: { min: 1 } }}
                                                        InputLabelProps={{ shrink: false }}
                                                        defaultValue={1}
                                                        id="filled-basic"
                                                        placeholder="Quantity"
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                                <Grid item xs={8} sm={8} className="inputField">
                                                    <Typography>Order #</Typography>
                                                    <FormControl>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={Order2.toString()}
                                                            onChange={(event: SelectChangeEvent) => {
                                                                setOrder2(event.target.value as string);
                                                            }}
                                                            variant="outlined"
                                                            displayEmpty
                                                            inputProps={{ "aria-label": "Without label" }}
                                                        >
                                                            <MenuItem value={0}>
                                                                <em>Select Order</em>
                                                            </MenuItem>
                                                            <MenuItem value={0}>GC1456461</MenuItem>
                                                            <MenuItem value={1}>GC2456462</MenuItem>
                                                            <MenuItem value={2}>GC3456463</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} sm={12} className='inputField'>
                                                <Typography>
                                                    Picked Items
                                                </Typography>
                                                <TextField
                                                    style={{ width: "100%" }}
                                                    id="outlined-multiline-flexible"
                                                    placeholder="Picked Items"
                                                    multiline
                                                    variant={"outlined"}
                                                    maxRows={9}
                                                    InputLabelProps={{ shrink: false }}
                                                    fullWidth
                                                />
                                            </Grid>
                                        </Grid>
                                        <Button onClick={() => pickCompleteClick()} style={{ marginTop: "20px", width: "100%" }} variant='contained' className='btn' startIcon={<CheckIcon />}>Confirm Pick</Button>
                                    </div >
                                </div >
                            </Grid>
                        </Grid>
                    </Paper>
                </div >
            </div >
        );
    }
    return (
        <div>
            <PdfIframe open={showPdf} close={closePdfiframe} blobUrl={blobUrl} ></PdfIframe>
            <NotificationPopup {...pickCompleteDialog}>
            </NotificationPopup>
            <NotificationPopup {...resourcePopup}>
            </NotificationPopup>
            <NotificationPopup {...cancelPopup}>
            </NotificationPopup>
            <NotificationPopup {...contactCustomerPopup}>
            </NotificationPopup>
            <NotificationPopup {...notesCustomerPopup}>
            </NotificationPopup>
            {Utils.isMobile ? mobileView() : webView()}
            {!Utils.isMobile ? <PickSlip></PickSlip> : ""}
        </div>
    );
}
export default BulkPickOrder;
import React, { useEffect, useState } from 'react';
import './Routes.scss';
import { useTheme, withStyles } from '@material-ui/core/styles';
import { Button, Grid } from '@material-ui/core';
import Utils from '../Common/Utils';
import Box from '@material-ui/core/Box';
import { Card, CardContent, Divider, InputBase, TextField, Tooltip } from '@mui/material';
import Stack from '@mui/material/Stack';
import DataTable from 'react-data-table-component';
import { IconButton, Paper, Typography } from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import SiteBreadCrumbs from '../Subcomponent/Shared/SiteBreadCrumbs'
import { ReceiptLongIcon, FilterAltIcon, DocumentScannerIcon, SearchIcon, CloseIcon } from "../Subcomponent/Shared/SharedIcons"
import MobileBarCodeScannerTrackingNumberScandit from '../Subcomponent/ReceiveOrders/MobileBarCodeScannerTrackingNumberScandit';
import { NotificationPopup } from "../Subcomponent/Shared/NotificationPopup";
import { INotificationPopupSettings, IButton } from "../Models/INotificationPopupSettings";
import DummyDataManager from "../Managers/DummyDataManager";
import allActions from '../Store/Actions/AllActions';
import Constants from '../Common/Constants';
import Drawer from '@mui/material/Drawer';
import OrderManager from '../Managers/Orders.Manager';
import StoreManager from "../Managers/Store.manager";
import IdentityManager from '../Managers/Identity.manager';
import { OrderDetailNotificationPopup } from '../Subcomponent/Shared/OrderDetailNotificationPopup';

const ReceiveOrders = (props: any) => {
    const [openInfo, setOpenInfo] = useState(false);
    const [status, setStatus] = useState(0);
    // generate random Data fro faker js
    const defaultPageSize = Utils.isMobile ? 30 : 25
    const showActionLoader = () => {
        dispatch(allActions.actionLoaderActions.showLoader());
    }
    const hideActionLoader = () => {
        dispatch(allActions.actionLoaderActions.hideLoader());
    }
    var startProductBarPos = -1;
    window.onscroll = function () {
        var bar = document.getElementById('paginationWebId') as any;
        var dataTable = document.getElementById('containerDataTable') as any;
        if (bar && dataTable) {
            var dataTableWidth = dataTable.offsetWidth;
            if (startProductBarPos < 0) startProductBarPos = findPosY(bar);
            if (window.pageYOffset > startProductBarPos && (window.pageYOffset - startProductBarPos > 50)) {
                bar.style.width = (dataTableWidth - (0.8 * dataTableWidth / 100)) + "px";
                bar.classList.add("fixedPagination");
                bar.style.marginTop = Utils.getWebbarheight + "px";
            } else {
                bar.classList.remove("fixedPagination");
                bar.style.width = "";
                bar.style.marginTop = "";
            }
        }
        // Mobile View Fix the Tabs and FILTER
        if (Utils.isMobile) {
            console.log("scrolled");
            bar = document.getElementById('tabFilterContaoner') as any;
            var mblBar = document.getElementById('mblBar') as any;
            if (bar) {
                if (startProductBarPos < 0) startProductBarPos = findPosY(mblBar);
                if (window.pageYOffset > startProductBarPos) {
                    bar.classList.add("scrolledTopFix");
                } else {
                    bar.classList.remove("scrolledTopFix");
                }
            }
        }
    };
    function findPosY(obj: any) {
        var curtop = 0;
        if (obj && typeof (obj.offsetParent) != 'undefined' && obj.offsetParent) {
            while (obj.offsetParent) {
                curtop += obj.offsetTop;
                obj = obj.offsetParent;
            }
            curtop += obj.offsetTop;
        }
        else if (obj && obj.y)
            curtop += obj.y;
        return curtop;
    }
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const theme = useTheme();
    const [openDetailDrawer, setOpenDetailDrawer] = useState(false);
    const [rowDetail, setRowDetail] = useState({} as any);
    let orderRows = useSelector((state: any) => state.ReceiveOrdersReducersState.receiveOrders);
    let allOrderRows = useSelector((state: any) => state.ReceiveOrdersReducersState.allReceiveOrders);
    // Drawer Code
    const anchor = "right";
    const [openDrawer, setDrawer] = useState(false);
    const [isScan, setScan] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [openError, setOpenError] = useState(false);
    const [searchTracking, setSearchTracking] = useState("")
    const [data, setData] = useState([] as any);

    const toggleDrawer = (op: any) => {
        setDrawer(op);
    };
    // Pagination 
    const [totalRecords, setTotalRecords] = React.useState(0);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(defaultPageSize);
    const openDetailDrawerClick = (id: any) => {
        let selectedOrder = orderRows.filter((order: any) => {
            return order._id == id
        })
        if (selectedOrder && selectedOrder.length > 0) {
            setRowDetail(selectedOrder[0])
            setOpenDetailDrawer(true)
        }
        setOpenDetailDrawer(true)
    }
    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(+(parseInt(event.target.value)));
        setPage(0);
    };


    const updateStatusState = () => {
        setStatus(status + 1);
    }

    const openInfoPopup = () => {
        setOpenInfo(true)
        updateStatusState();
    }

    const closeInfoPopup = () => {
        setOpenInfo(false)
    }

    let Info = {
        open: openInfo,
        msg: "The Order Status has been changed. Your screen will automatically refresh now.",
        handleClose: closeInfoPopup,
        zIndex: 10000000
    };

    const handleChangeOrder = async (row: any) => {

        showActionLoader();
        let detailStatus = true;
        await Utils.checkOrderDetailStatus({
            shipmentKey: row._source?.Shipment?.ShipmentKey,
            status: row._source?.Shipment?.Status
        }).then((res: any) => {
            if (res?.success) {
                detailStatus = res.data;
            }
        }).catch((err: any) => {
            console.log("Order Detail Status Error", err);
        })
        if (detailStatus) {
            hideActionLoader();
            if (row._source.Shipment.ExtnPickupStoreID && row._source.Shipment.ExtnPickupStoreID > 0) {
                let extnPickupStoreID = row._source.Shipment.ExtnPickupStoreID;
                navigate("/PendingReceipt?orderno=" + row._source?.Shipment?.OrderNo + "&shipmentkey=" + row._source?.Shipment?.ShipmentKey + "&shipmentcontainerkey=" + row._source?.Shipment?.Containers?.Container[0]?.ShipmentContainerKey + "&store=" + extnPickupStoreID)
            }
            else {
                navigate("/PendingReceipt?orderno=" + row._source?.Shipment?.OrderNo + "&shipmentkey=" + row._source?.Shipment?.ShipmentKey + "&shipmentcontainerkey=" + row._source?.Shipment?.Containers?.Container[0]?.ShipmentContainerKey)
            }

        }
        else {
            hideActionLoader();
            openInfoPopup();
        }
    }
    const WebGridButton = (row: any) => {
        return <React.Fragment>
            <Button startIcon={<ReceiptLongIcon className='fTabIcons' />} variant="contained" color="primary" onClick={() => { handleChangeOrder(row) }}>Pending Receipt</Button>
        </React.Fragment >
    }
    const getItemDescription = (row: any) => {
        let { ItemDesc } = row._source?.Shipment?.ShipmentLines?.ShipmentLine[0]
        return <Tooltip title={ItemDesc}><p style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{ItemDesc}</p></Tooltip>
    }
    // End Drawer Code
    // const handleChange = (event: any, newValue: number) => {
    //     console.log(newValue, "handleChange");
    //     setValue(newValue);

    // };   
    const getColumns = () => {
        const columns = [
            {
                name: 'Actions',
                cell: (row: any) => WebGridButton(row),
                ignoreRowClick: true,
                allowOverflow: true,
                button: true,
                minWidth: "200px",
                width: "200px"
            },
            {
                name: 'Tracking #',
                selector: (row: any) => row._source?.Shipment?.Containers?.Container[0]?.TrackingNo,
                sortable: true,
                minWidth: "180px",
                width: "180px"

            },
            {
                name: 'Order #',
                selector: (row: any) => row._source?.Shipment?.OrderNo,
                sortable: true,
                minWidth: "160px",
                width: "160px"
            },
            {
                name: 'Status',
                selector: () => "Pending Receipt",
                //selector: (row: any) => row._source?.Shipment?.StatusName == "PENDING_RECEIPT" ? "Pending Receipt" : row._source?.Shipment?.StatusName,
                sortable: true,
                minWidth: "170px",
                width: "170px"
            },
            {
                name: 'Age (days)',
                selector: (row: any) => row._source?.Shipment?.Age <= 0 ? "Today" : row._source?.Shipment?.Age,
                sortable: true,
                minWidth: "120px",
                width: "120px",
            },
            {
                id: "Store",
                name: 'Store',
                selector: (row: any) => row._source.Shipment?.ExtnPickupStoreID,
                sortable: true,
                minWidth: "85px",
                width: "85px",
                omit: props.store?.length <= 1
            },
            {
                name: 'Item Description',
                cell: (row: any) => getItemDescription(row),
                sortable: true,
                width: Utils.getDescriptionWidthReceiveOrders("") + "px",
            }
        ];
        return columns;
    }

    const renderMobileFilters = () => {
        return <Box sx={{ p: 3 }} style={{ textAlign: "left", padding: "5px", paddingLeft: "5px", paddingTop: "5px" }}>
            {searchBar()}
        </Box>
    }
    const renderWebDataTable = () => {
        return <div className='dataTable'>
            <DataTable
                className='dataTable'
                title=""
                data={orderRows}
                columns={getColumns()}
            />
        </div>
    }
    const renderMobileData = () => {
        return <div style={{ marginTop: 10 }}>
            {orderRows.map((row: any) => {
                return (
                    <Card style={{ marginTop: 4, marginBottom: 4 }}>
                        <CardContent style={{ paddingTop: 0, paddingBottom: 0, paddingLeft: 0, paddingRight: 0 }} onClick={() => { openDetailDrawerClick(row._id) }}>
                            <Grid container style={{ margin: "0px" }} >
                                <Grid xs={3} sm={3} style={{ paddingTop: 4, paddingLeft: 0, color: Constants.Colors.red, background: Constants.Colors.smokeGrey, display: "flex", alignItems: "center", borderRight: "2px solid #C0C0C0" }}>
                                    <Typography style={{ fontSize: "11px", wordBreak: "break-all", fontWeight: "425", marginLeft: 3 }}>
                                        {row._source?.Shipment?.OrderNo}
                                    </Typography>
                                </Grid>
                                <Grid item xs={7} sm={7} style={{ paddingTop: 3, paddingLeft: 3, textAlign: "left", verticalAlign: "center" }}>
                                    <Typography noWrap style={{ color: Constants.Colors.red, fontSize: "12px", fontWeight: "425" }}>{row._source?.Shipment?.ShipmentLines?.ShipmentLine[0]?.ItemDesc}</Typography>
                                    <Typography style={{ fontSize: "11px" }}>
                                        <span className="fulfillmentMobileLable">Tracking #: {row._source?.Shipment?.Containers?.Container[0]?.TrackingNo}</span>
                                    </Typography>
                                    <Typography style={{ fontSize: "11px" }}>
                                        <span className="fulfillmentMobileLable">Status: {"Pending Receipt"}</span>
                                    </Typography>
                                </Grid>
                                <Grid item xs={2} sm={2} onClick={() => { handleChangeOrder(row) }} style={{ display: "flex", justifyContent: "left", padding: 0, alignItems: "center", marginTop: 0, background: Constants.Colors.red, color: Constants.Colors.white, borderLeft: "2px solid #D93A2F" }}>
                                    {<ReceiptLongIcon style={{ fontSize: 17 }} />}
                                    <Typography style={{ fontSize: "12px" }}>
                                        Receipt
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                );
            })
            }
        </div >
    }

    const closeErrorPopup = () => {
        setOpenError(false)
    }

    const openErrorPopup = () => {
        setOpenError(true)
    }

    let errorPopupButtons: IButton[] = [
        {
            text: "Close",
            icon: <CloseIcon />,
            action: closeErrorPopup,
            color: Constants.Colors.red,
        },
    ];

    let Error: INotificationPopupSettings = {
        open: openError,
        type: Constants.NotificationPopupType.Error,
        title: "Error",
        msg: errorMsg,
        draggable: false,
        handleClose: closeErrorPopup,
        actions: errorPopupButtons
    }

    const changeSearchTracking = (e: any) => {
        console.log(e.target.value)
        setSearchTracking(e.target.value)
    }

    const onScannedComplete = (data: any, isError: any) => {
        setSearchTracking(data)
        setScan(false);
    }

    const renderPaginationAndSearch = () => {
        if (!Utils.isMobile) {
            return <React.Fragment >
                {pagination()}
            </React.Fragment>
        }
        else {
            return <React.Fragment >
            </React.Fragment>
        }
    }

    const renderDetailDrawer = () => {
        return (
            <Card sx={{ mt: 2 }}>
                <CardContent style={{ padding: "5px" }}>
                    <Typography variant="h6" component="div">
                        Tracking # {rowDetail._source?.Shipment?.Containers?.Container[0]?.TrackingNo}
                    </Typography>
                </CardContent>
                <Card sx={{ mt: 1 }} className="orderDetailMobileContainer">
                    <CardContent style={{ padding: 8 }}>
                        <Stack direction={"row"}>
                            <TextField
                                id="order"
                                label="Order #"
                                defaultValue={rowDetail._source?.Shipment?.OrderNo}
                                InputProps={{
                                    readOnly: true,
                                }}
                                variant="standard"
                                style={{ margin: "4px" }}
                            />
                            <TextField
                                id="outlined-read-only-input"
                                label="Status"
                                defaultValue={rowDetail._source?.Shipment?.StatusName == "PENDING_RECEIPT" ? "Pending Receipt" : rowDetail._source?.Shipment?.StatusName}
                                InputProps={{
                                    readOnly: true,
                                }}
                                variant="standard"
                                style={{ margin: "4px" }}
                            />
                        </Stack>
                        <Stack direction={"row"}>
                        </Stack>
                        <TextField
                            id="outlined-read-only-input"
                            label="Item Description"
                            defaultValue={rowDetail._source?.Shipment?.ShipmentLines?.ShipmentLine[0]?.ItemDesc}
                            InputProps={{
                                readOnly: true,
                            }}
                            variant="standard"
                            multiline
                            style={{ margin: "4px", width: "100%" }}
                        />
                    </CardContent>
                </Card>
            </Card>
        );
    }

    const pagination = () => {
        return < div id='paginationWebId' className="paginationReceiveOrdersWeb" style={{ background: "#D93A2F" }}>
            <div style={{ marginLeft: "auto" }}>
                <Stack spacing={1} style={{ paddingTop: 7 }}>
                    <TablePagination
                        rowsPerPageOptions={[1, 5, 10, 25, 50, 100]}
                        component="div"
                        count={totalRecords}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}

                    />
                </Stack>
            </div>
            <div style={{ marginLeft: "auto" }}>
                {searchBar()}
            </div>
        </div >
    }

    const searchBar = () => {
        console.log("searchBar")
        return <Paper style={{ width: "auto", marginRight: Utils.isMobile ? 0 : 10 }} className={!Utils.isMobile ? "searchWeb" : ""}
            sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}
        >
            <InputBase
                value={searchTracking}
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search Tracking #"
                inputProps={{ 'aria-label': 'search google maps' }}
                style={{ marginLeft: 10 }}
                onChange={(e: any) => changeSearchTracking(e)}
            />
            {Utils.isMobile ? <IconButton style={{ color: Constants.Colors.red }} type="submit" sx={{ p: '10px' }} aria-label="search" onClick={() => setScan(true)}>
                <DocumentScannerIcon style={{ transform: "rotate(90deg)" }} />
            </IconButton> : <></>}
            <IconButton style={{ background: "#d6d3d3" }} type="submit" sx={{ p: '10px' }} aria-label="search">
                <SearchIcon />
            </IconButton>
        </Paper>
    }
    const getDataFromElastic = async () => {
        //
        let storeId = IdentityManager.getMultipleStoresId(props.store);
        if (!storeId) {
            return
        }

        showActionLoader()
        let storeID = storeId// "110"        
        let resultResponse = {} as any;
        try {
            resultResponse = await OrderManager.getReceiveOrders(storeID);
            if (resultResponse.success) {
                let result = resultResponse.data
                let total = result.hits.total.value;
                let dataRows = result.hits.hits;
                dataRows.forEach((element: any) => {
                    element._source.Shipment["Age"] = Utils.getOrderAge(element);
                });

                dataRows.sort((a: any, b: any) => {
                    return b._source.Shipment.Age - a._source.Shipment.Age;
                })

                if (dataRows.length > 0) {
                    let from = (page) * rowsPerPage
                    let to = from + rowsPerPage
                    let paginated = dataRows.slice(from, to);
                    dispatch(allActions.receiveOrdersActions.allReceiveOrders(dataRows));
                    dispatch(allActions.receiveOrdersActions.receiveOrders(paginated));
                    setTotalRecords(total)
                }
                else {
                    dispatch(allActions.receiveOrdersActions.allReceiveOrders([]));
                    dispatch(allActions.receiveOrdersActions.receiveOrders([]));
                }
            }
            else {
                hideActionLoader()
                let message = "Some thing went wrong please contact the IT Help Desk for assistance"
                console.log(resultResponse.msg)
                setErrorMsg(message);
                openErrorPopup()
            }
        }
        catch (e) {
            hideActionLoader()
            let message = "Some thing went wrong please contact the IT Help Desk for assistance";
            console.log(resultResponse.msg)
            setErrorMsg(message);
            openErrorPopup()
        }

        hideActionLoader()
    }
    const searchInStore = () => {
        let filterd = [] as any
        if (searchTracking == "") {
            let from = (page) * rowsPerPage
            let to = from + rowsPerPage
            let paginated = allOrderRows.slice(from, to);
            dispatch(allActions.receiveOrdersActions.receiveOrders(paginated));
            setTotalRecords(allOrderRows.length)
        } else {
            allOrderRows.forEach((order: any) => {
                order._source?.Shipment?.Containers?.Container?.forEach((container: any) => {
                    console.log(container.TrackingNo)
                    //if(container.TrackingNo.toLowerCase().indexOf(searchTracking.toLowerCase()) > -1 )
                    if (container.TrackingNo.toLowerCase() == searchTracking.toLowerCase()) {
                        filterd.push(order);
                    }

                });
            });
            if (filterd.length > 0) {
                //
                let from = (page) * rowsPerPage
                let to = from + rowsPerPage
                let paginated = filterd.slice(from, to);
                dispatch(allActions.receiveOrdersActions.receiveOrders(paginated));
                setTotalRecords(filterd.length)
            }
        }
    }
    React.useEffect(() => {
        async function search() {
            await searchInStore()
        }
        search()
    }, [searchTracking])

    React.useEffect(() => {
        async function sendRequest() {
            await getDataFromElastic()
        }
        sendRequest()
        return () => {
        }
    }, [props.store, page, rowsPerPage, status]);
    return (<div>
        {
            isScan ?
                <MobileBarCodeScannerTrackingNumberScandit onScanned={onScannedComplete} />
                :
                <div>
                    <NotificationPopup {...Error}></NotificationPopup>
                    <OrderDetailNotificationPopup {...Info}></OrderDetailNotificationPopup>
                    {!Utils.isMobile ? <SiteBreadCrumbs siteBreadCrumbs={Constants.SiteBreadCrumbs.ReceiveOrders}></SiteBreadCrumbs> : <></>}
                    <div style={{ marginTop: 0 }} className={Utils.isMobile ? "innerContianerMobile" : "innerContianer"}>
                        <div id='tabFilterContaoner'>
                            {!Utils.isMobile ? <div></div> : renderMobileFilters()}
                        </div>
                        {renderPaginationAndSearch()}
                        {!Utils.isMobile ? renderWebDataTable() : renderMobileData()}
                    </div>
                </div>
        }
        {
            openDetailDrawer ?
                <Drawer
                    anchor={"bottom"}
                    open={openDetailDrawer}
                    onClose={() => { }}
                    onClick={() => { setOpenDetailDrawer(false) }}
                    style={{ zIndex: 9999999 }}
                >
                    {(renderDetailDrawer())}
                </Drawer>
                :
                <></>
        }
    </div>);
    interface TabPanelProps {
        children?: React.ReactNode;
        dir?: string;
        index: number;
        value: number;
    }
    function TabPanel(props: TabPanelProps) {
        const { children, value, index, ...other } = props;
        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`full-width-tabpanel-${index}`}
                aria-labelledby={`full-width-tab-${index}`}
                {...other}
                style={{ marginTop: Utils.isMobile ? "-14px" : "" }}
            >
                <Box sx={{ p: 3 }} style={{ padding: "5px" }} id='containerDataTable'>
                    <Typography component="div">{children}</Typography>
                </Box>

            </div>
        );
    }
}
export default ReceiveOrders;
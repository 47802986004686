import { Box, Card, CardContent, Divider, Grid, Typography } from "@mui/material"
import React, { useEffect } from "react";
import { useState } from "react";
import Constants from "../../Common/Constants";
import Utils from "../../Common/Utils";
import { ChatBubbleIcon, ChatIcon, MarkEmailReadIcon, MarkunreadIcon, ShoppingCartIcon, ZoomOutMapIcon } from "../Shared/SharedIcons"
import { CommunicationDataGrid } from "./CommunicationDataGrid";
import CustomChip from "./CustomChip";

import IdentityManager from "../../Managers/Identity.manager";


export const CommunicationTile = (props: any) => {
    const { ccData, isccDataLoaded } = props;
    const distList = ccData.districtCCData;
    const [gridPopUp, setGridPopUp] = useState(false);
    const [sumOfDistMsgSent, setSumOfDistMsgSent] = useState(0)
    const [sumOfDistMsgRespond, setSumOfDistMsgRespond] = useState(0)
    const [emailResponsePerc, setEmailResponsePerc] = useState(0)

    useEffect(() => {
        if (distList) {
            let distMsgSent = 0
            let distMsgRespnd = 0
            for (let i = 0; i < distList.length; i++) {
                distMsgSent = distMsgSent + distList[i].emailsSent;
                distMsgRespnd = distMsgRespnd + distList[i].customerResponded;
            }
            calculateSummary(distMsgSent, distMsgRespnd)
            setSumOfDistMsgSent(distMsgSent)
            setSumOfDistMsgRespond(distMsgRespnd)
        }
    }, [distList])

    const calculateSummary = (sent: any, response: any) => {
        if (sent <= 0) {
            setEmailResponsePerc(0);
            return;
        }
        let responsePerc = parseFloat(((response / sent) * 100).toFixed(2));
        setEmailResponsePerc(responsePerc);
    }

    const closeGridPopup = () => {
        setGridPopUp(false);
    }

    return (
        <React.Fragment>
            {/* <Card className='dCard' >
                <CardContent className='dCardContent' style={{ padding: 0 }} >
                    <div className='dCardHeader' style={{ background: Constants.Colors.red }} >
                        <div>
                            <Typography style={{ fontWeight: "500", color: "white" }}>
                                Customer Communications
                            </Typography>
                        </div>
                        <div onClick={() => { setGridPopUp(true) }} style={{ cursor: "pointer" }}>
                            <ZoomOutMapIcon style={{ float: "right", padding: "5px", width: "20px", color: Constants.Colors.white }} />
                        </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-around", padding: "10px" }}>
                        <div>
                            <Typography style={{ fontWeight: "500", color: "" }}>
                                Email Sent
                            </Typography>

                            <CustomChip size={1.2} icon={<MarkunreadIcon style={{ color: Constants.Colors.blue }} />} label={sumOfDistMsgSent ?? 0} variant="outlined" color="success"></CustomChip>
                        </div>
                        <div>
                            <Typography style={{ fontWeight: "500", color: "" }}>
                                Response Received
                            </Typography>
                            <CustomChip size={1.2} icon={<MarkEmailReadIcon style={{ color: Constants.Colors.green }} />} label={sumOfDistMsgRespond ?? 0} variant="outlined" color="success"></CustomChip>
                        </div>
                    </div>
                </CardContent>
            </Card> */}
            <Card className='dCard' >
                <CardContent className='dCardContent' style={{ padding: 0 }}  >

                    <div className='dCardLeftHeader'>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <ChatIcon style={{ color: Constants.Colors.red }} />
                        </div>
                        <Typography style={{ fontWeight: "bold", color: "#5f6661", fontSize: 17, marginTop: "5px", marginLeft: "5px" }}>
                            Customer Communications
                        </Typography>
                        <div onClick={() => { setGridPopUp(true) }} style={{ cursor: "pointer", marginLeft: "auto" }}>
                            <ZoomOutMapIcon style={{ float: "right", padding: "5px", width: "20px", color: Constants.Colors.grey }} />
                        </div>
                    </div>

                    <Divider />

                    <Grid container>
                        <Grid item xs={6} sm={6} >
                            <Grid container>
                                <Grid item sm={4} style={{ display: "grid", alignItems: "center", justifyItems: "end" }}>
                                    <div className="tiles-icons" style={{ backgroundColor: Constants.Colors.blue }}  >
                                        <MarkunreadIcon />
                                    </div>
                                </Grid>
                                <Grid item sm={8} style={{ display: "grid", justifyItems: "start" }}>
                                    <p className="tiles-header-font">Email Sent</p>
                                    <p className="tiles-value-font">
                                        {sumOfDistMsgSent ?? 0}
                                    </p>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                            <Grid container>
                                <Grid item sm={4} style={{ display: "grid", alignItems: "center", justifyItems: "end" }}>
                                    <div className="tiles-icons" style={{ backgroundColor: Constants.Colors.green }} >
                                        <MarkEmailReadIcon />
                                    </div>
                                </Grid>
                                <Grid item sm={8} style={{ display: "grid", justifyItems: "start" }}>
                                    <p className="tiles-header-font">Responses</p>
                                    <p className="tiles-value-font">
                                        {sumOfDistMsgRespond ?? 0}
                                    </p>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>
                    <Grid container style={{ backgroundColor: "#5A5A5A", minHeight: "116px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Grid container style={{ marginTop: "10px", display: "flex", justifyContent: "space-around" }}>
                            <Grid item sm={8}>
                                {/* <Box> */}
                                <div style={{ float: "left", marginLeft: "25px" }}>
                                    <Typography style={{ color: "white" , fontSize: "12px" }}>Responses Received</Typography>
                                </div>
                                {/* </Box> */}
                            </Grid>
                            <Grid item sm={4}>
                                <Box>
                                    <Typography style={{ color: "white", fontWeight: "bold", wordBreak: "break-all" , fontSize: "12px"}}>{emailResponsePerc}{'%'}</Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            {isccDataLoaded && distList && <CommunicationDataGrid open={gridPopUp} close={closeGridPopup} data={distList} isLoaded={isccDataLoaded} />}
        </React.Fragment >);
}
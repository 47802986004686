import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import BottomNavigation from "@material-ui/core/BottomNavigation";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import RestoreIcon from "@material-ui/icons/Restore";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import HomeIcon from '@material-ui/icons/Home';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import CloudDownload from '@material-ui/icons/CloudDownload';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import AssignmentIcon from '@material-ui/icons/Assignment';
import MenuIcon from '@material-ui/icons/Menu';
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Constants from '../../Common/Constants';
import IdentityManager from '../../Managers/Identity.manager';
import './Shared.scss';
import { Paper } from '@material-ui/core';
import { useSelector } from 'react-redux';
const MobileNavbar = (props: any) => {
    const displayDrawerData = props.displayDrawerData;
    const navigate = useNavigate();
    const location = useLocation();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [value, setMobileNavSelectedValue] = React.useState(0);    
    const kcdcQuantity = useSelector((state: any) => state.ActionLoaderReducersState.kcdcTotalQuantity);
    const renderMobileNavbar = (displayDrawerData: any) => {      
        let visibleData = displayDrawerData.length > 3 ? displayDrawerData.slice(0, 3) : displayDrawerData;
        return <Paper style={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 999 }} className="mobileNavigationBarss" elevation={13}>
            <BottomNavigation
                style={{ position: "fixed", bottom: 0, width: "100%" }}
                showLabels={true}
                value={props.selected}
                onChange={(e: any, newValue) => {
                    
                    props.setMobileNavSelectedValue(newValue);
                    let currentTarget = e.currentTarget.innerText?.toString();
                    console.log(newValue, "newValue");
                    console.log(e, "value of e");
                    console.log(currentTarget,"- Current Target");
                    console.log(e.currentTarget.innerText,"- Inner Text");


                    if (currentTarget.trim()==="More") {
                        console.log("In Navbar If Condition");
                        setAnchorEl(e.currentTarget);
                    }
                    else {
                        console.log("In Navbar Else Condition");
                        let selectedRoute = IdentityManager.getUserNavigation().filter((e: any) => {
                            return e.Position == newValue;
                        })
                        if (selectedRoute && selectedRoute.length > 0) {
                            navigate(selectedRoute[0].Route);
                        }
                    }
                }}
            >
                {
                    visibleData.map((item: any, i: number) =>
                        <BottomNavigationAction className='mobileNavbarButton' style={{ padding: "0px !important", background: "black !important" }} key={i} label={getItemHeader(item)} icon={renderMobileIcon(item.Position)} />
                )}

                {renderMobilMoreOptions(displayDrawerData)}
                {renderMoreDialogOptions(displayDrawerData)}

            </BottomNavigation>
        </Paper>
    }

    const getItemHeader = (item: any) =>{
        if(item.Route==Constants.RouteLabels.KCDCReturns && kcdcQuantity>0)
        {
            return `${item.Header} (${kcdcQuantity})`
        }
        return item.Header;
    }

    const renderMobilMoreOptions = (displayDrawerData: any) => {

        if (displayDrawerData && displayDrawerData.length > 3) {
            return <BottomNavigationAction className='mobileNavcarMoreButton' label={"More"} icon={<MenuIcon />} />
        }

    }

    const renderMoreDialogOptions = (displayDrawerData: any) => {

        if (displayDrawerData && displayDrawerData.length > 3) {
            let mobileToShowMoreOptions = displayDrawerData.slice(3, displayDrawerData.length);
            return <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMoreOptionClose}
            >
                {mobileToShowMoreOptions.map((item: any, i: number) => {
                    return <MenuItem key={i} onClick={() => { mobileMoreOptionsClicked(item) }}>{getItemHeader(item)}</MenuItem>
                })}
            </Menu>
        }

    }

    const renderMobileIcon = (type: any) => {

        switch (type) {
            case 0:
                return <HomeIcon></HomeIcon>;
            case 1:
                return <FormatListBulletedIcon></FormatListBulletedIcon>;
            case 2:
                return <CloudDownload></CloudDownload>;
            case 3:
                return <DoubleArrowIcon></DoubleArrowIcon>;
            case 4:
                return <AssignmentIcon></AssignmentIcon>;
        }

    }

    const handleMoreOptionClose = () => {
        setAnchorEl(null);
    }

    const mobileMoreOptionsClicked = (item: any) => {
        navigate(item.Route);
    }

    return (
        renderMobileNavbar(displayDrawerData)
    );

}

export default MobileNavbar;
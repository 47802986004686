import React, { useState } from 'react';
import { Button, Card, CardContent, Divider, Drawer, Grid, Paper, TextField, Toolbar, Typography, InputAdornment } from '@mui/material';
import Loader from "react-loader-spinner";
import ShipmentStatusConstant from "../../Common/ShipmentStatusConstants";
import { DynamicFeedIcon, CloseIcon, PlaylistAddCheck, ShoppingCart, ApartmentIcon, DirectionsCar, FilterAltIcon, PreviewIcon, SearchIcon, ExpandMoreIcon, ExpandLessIcon } from "../../Subcomponent/Shared/SharedIcons";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { IButton, INotificationPopupSettings } from '../../Models/INotificationPopupSettings';
import Constants from '../../Common/Constants';
import { NotificationPopup } from './NotificationPopup';


const NoDataFound = (props: any) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [openConstruction, setOpenConstruction] = useState(false);



    // Construction Popup





    return (

        <React.Fragment>
            <div style={{ paddingTop: "10px", color: "#5a5959"}}>
                <DynamicFeedIcon style={{ fontSize: "3rem" }}></DynamicFeedIcon>
                <Typography style={{ marginTop: "8px"}} variant='h6' className='topheading'>{props.msg}</Typography>
            </div>

        </React.Fragment>


    );
}

export default NoDataFound;


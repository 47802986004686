import { Button, Card, CardContent, Divider, Drawer, Grid, Paper, TextField, Toolbar, Typography, InputAdornment, SwipeableDrawer } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton } from '@mui/material';
import Utils from '../Common/Utils';
import Constants from '../Common/Constants';
import allActions from '../Store/Actions/AllActions';
import SiteBreadCrumbs from '../Subcomponent/Shared/SiteBreadCrumbs';
import StoreManager from "../Managers/Store.manager";
import OrderHistory from '../Subcomponent/Shared/OrderHistory';
import OrderManager from '../Managers/Orders.Manager';
import ShipmentStatusConstant from "../Common/ShipmentStatusConstants";
import { NotificationPopup } from "../Subcomponent/Shared/NotificationPopup";
import { INotificationPopupSettings, IButton } from "../Models/INotificationPopupSettings";
import { CloseIcon, ArrowBackIcon, ManageSearchIcon, PersonIcon, AddLocationAltIcon, EmailIcon, LocalPhoneIcon, CheckIcon, KeyboardReturnIcon } from "../Subcomponent/Shared/SharedIcons";
import { OrderDetailGrid } from '../Subcomponent/Shared/OrderDetailGrid';
import { CustomerDetails } from '../Subcomponent/Shared/CustomerDetails';
import { IImageViewerDetail, IImageViewerPopupSettings } from '../Models/IImageViewerPopupSettings';
import { ImageViewerPopup } from '../Subcomponent/Shared/ImageViewerPopup';
import DataTable from 'react-data-table-component';
import { color } from '@mui/system';

const OrderDetail = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const shipmentkey = urlParams.get('shipmentkey');
    const [openDrawer, setDrawer] = useState(false);
    const [openHistoryDrawer, setHistoryDrawer] = useState(false);
    const [openError, setOpenError] = useState(false);
    const [loading, setLoading] = useState(false)
    const [errorMsg, setErrorMsg] = useState("");
    const [orderDetail, setOrderDetail] = useState({} as any)
    const [orderDetailGridData, setOrderDetailGridData] = useState({ fulfillmentType: "", levelOfService: "", pickUpStore: "", storeNumber: "" });
    const [customerDetail, setCustomerDetail] = useState({} as any)
    const [imagePopUp, setImagePopUp] = useState(false);
    const [imageData, setImageData] = useState({} as IImageViewerDetail);

    const location = useLocation();
    const navigateToSearchResult = () => {
        navigate("/Search")
    }
    const navigate = useNavigate();
    const dispatch = useDispatch();
    // Scroll
    var startProductBarPos = -1;
    window.onscroll = function () {
        console.log("scrolled");
        var bar = document.getElementById('bar') as any;
        var mblBar = document.getElementById('mblBar') as any;
        if (bar) {
            if (startProductBarPos < 0) startProductBarPos = findPosY(mblBar);
            if (window.pageYOffset > startProductBarPos) {
                bar.classList.add("scrolledTopFix");
            } else {
                bar.classList.remove("scrolledTopFix");
            }
        }
    };
    function findPosY(obj: any) {
        var curtop = 0;
        if (obj && typeof (obj.offsetParent) != 'undefined' && obj.offsetParent) {
            while (obj.offsetParent) {
                curtop += obj.offsetTop;
                obj = obj.offsetParent;
            }
            curtop += obj.offsetTop;
        }
        else if (obj && obj.y)
            curtop += obj.y;
        return curtop;
    }

    const showNavigation = () => {
        if (orderDetail?._source?.Shipment) {
            let { Status } = orderDetail._source.Shipment;
            let store = StoreManager.currentStore?.StoreId;
            return ((Status == ShipmentStatusConstant.ReadyForPick ||
                Status == ShipmentStatusConstant.ReadyForPack ||
                Status == ShipmentStatusConstant.ShipmentBeingPacked ||
                Status == ShipmentStatusConstant.ShipmentPacked) &&
                store == orderDetail._source.Shipment.ShipNode) ||
                (Status == ShipmentStatusConstant.ReadyForCustomerPickup &&
                    store == orderDetail._source.Shipment.ExtnPickupStoreID);
        }
        return false;
    }

    const showPendingRcepit = () => {
        if (orderDetail?._source?.Shipment) {
            let { Status } = orderDetail._source.Shipment;
            return (Status == ShipmentStatusConstant.PartialPendingReceive ||
                Status == ShipmentStatusConstant.PendingReceipts)

        }
        return false;
    }

    const navigateToOrder = () => {
        if (orderDetail?._source?.Shipment) {
            let { OrderNo, ShipmentKey, ShipNode, ExtnPickupStoreID } = orderDetail._source.Shipment;
            switch (orderDetail._source.Shipment.Status) {
                case ShipmentStatusConstant.ReadyForPick:
                    navigate("/PickOrder?orderno=" + OrderNo + "&shipmentkey=" + ShipmentKey + "&store=" + ShipNode + "")
                    break;

                case ShipmentStatusConstant.ReadyForPack: case ShipmentStatusConstant.ShipmentBeingPacked: case ShipmentStatusConstant.ShipmentPacked:
                    navigate("/PackOrder?orderno=" + OrderNo + "&shipmentkey=" + ShipmentKey + "&store=" + ShipNode + "");
                    break;

                case ShipmentStatusConstant.ReadyForCustomerPickup:
                    navigate("/CustomerPickup?orderno=" + OrderNo + "&shipmentkey=" + ShipmentKey + "&store=" + (ExtnPickupStoreID ? ExtnPickupStoreID : 0) + "");
                    break;
            }
        }
    }

    const showActionLoader = () => {
        dispatch(allActions.actionLoaderActions.showLoader());
    }
    const hideActionLoader = () => {
        dispatch(allActions.actionLoaderActions.hideLoader());
    }
    const closeErrorPopup = () => {
        setOpenError(false)
    }
    const openErrorPopup = () => {
        setOpenError(true)
    }
    const getCustomerDetails = (shipment: any) => {
        let data = shipment?._source?.Shipment
        let initialCustomerDetail = {
            name: "",
            city: "",
            email: "",
            phone: "",
            zipCode: "",
            address: "",
            Country: "",
            state: ""
        }
        if (data.CustomerInfo != undefined) {
            try {
                if (data.CustomerInfo.hasOwnProperty("FirstName")) {
                    initialCustomerDetail.name = data.CustomerInfo.FirstName
                }
                if (data.CustomerInfo.hasOwnProperty("LastName")) {
                    initialCustomerDetail.name = initialCustomerDetail.name + " " + data.CustomerInfo.LastName
                }
                if (data.CustomerInfo.hasOwnProperty("City")) {
                    initialCustomerDetail.city = data.CustomerInfo.City
                }
                if (data.CustomerInfo.hasOwnProperty("State")) {
                    initialCustomerDetail.state = data.CustomerInfo.State
                }
                if (data.CustomerInfo.hasOwnProperty("Country")) {
                    initialCustomerDetail.Country = data.CustomerInfo.Country
                }
                if (data.CustomerInfo.hasOwnProperty("CustomerEMailID")) {
                    initialCustomerDetail.email = data.CustomerInfo.CustomerEMailID
                }
                if (data.CustomerInfo.hasOwnProperty("ZipCode")) {
                    initialCustomerDetail.zipCode = data.CustomerInfo.ZipCode
                }
                if (data.CustomerInfo.hasOwnProperty("AddressLine1")) {
                    initialCustomerDetail.address = data.CustomerInfo.AddressLine1
                }
                if (data.CustomerInfo.hasOwnProperty("AddressLine2") && data.CustomerInfo.AddressLine2 != "") {
                    initialCustomerDetail.address = initialCustomerDetail.address + ", " + data.CustomerInfo.AddressLine2
                }
                if (data.CustomerInfo.hasOwnProperty("AddressLine3") && data.CustomerInfo.AddressLine3 != "") {
                    initialCustomerDetail.address = initialCustomerDetail.address + ", " + data.CustomerInfo.AddressLine3
                }
            }
            catch {
                console.log("Error Getting Customer Detail")
            }

        }
        return initialCustomerDetail
    }
    const getStatusLabel = (status: any) => {
        let statusLabel = "";
        switch (status) {
            case ShipmentStatusConstant.ReadyForPick:
                statusLabel = "Pick"
                break
            case ShipmentStatusConstant.ReadyForPack: case ShipmentStatusConstant.ShipmentBeingPacked: case ShipmentStatusConstant.ShipmentPacked:
                statusLabel = "Pack"
                break;
            case ShipmentStatusConstant.ReadyForCustomerPickup:
                statusLabel = "Customer Pickup"
                break;
            case ShipmentStatusConstant.CustomerPickedup:
                statusLabel = "Customer Pickedup"
                break;
            case ShipmentStatusConstant.PendingReceipts:
                statusLabel = "Pending Receipts"
                break;
            case ShipmentStatusConstant.PartialPendingReceive:
                statusLabel = "Pending Receive"
                break;
            case ShipmentStatusConstant.Shipped:
                statusLabel = "Shipped"
                break;
            case ShipmentStatusConstant.Cancelled:
                statusLabel = "Cancelled"
                break;
            default:
                statusLabel = status;
                break;
        }
        return statusLabel
    }
    const getDetail = () => {
        if (shipmentkey != undefined && shipmentkey != "") {
            let payload = {
                "id": shipmentkey
            }
            showActionLoader()
            setLoading(true)
            OrderManager.orderById(payload).then((result) => {
                if (result.success) {
                    if (result?.data?.hits?.hits != undefined && result?.data?.hits?.hits.length > 0) {
                        //let customerDetail = result?.data?.hits?.hits[0]
                        setCustomerDetail(getCustomerDetails(result?.data?.hits?.hits[0]))
                        setOrderDetail(result?.data?.hits?.hits[0])

                        let orderDetailGrid = {
                            fulfillmentType: result.data.hits.hits[0]?._source?.Shipment?.ShipmentLines?.ShipmentLine[0]?.FulfillmentType,
                            levelOfService: result.data.hits.hits[0]?._source?.Shipment?.LevelOfService,
                            pickUpStore: result.data.hits.hits[0]?._source?.Shipment?.ExtnPickupStoreID,
                            storeNumber: result.data.hits.hits[0]?._source?.Shipment?.ShipNode
                        };
                        setOrderDetailGridData(orderDetailGrid);
                    }
                    else {
                        let message = "Some thing went wrong please contact the IT Help Desk for assistance"
                        setErrorMsg(message);
                        openErrorPopup()
                    }
                }
                hideActionLoader()
                setLoading(false)
            }).catch((error) => {
                console.log(error)
                hideActionLoader()
                let message = "Some thing went wrong please contact the IT Help Desk for assistance"
                setErrorMsg(message);
                openErrorPopup()
                setLoading(false)
            })
        }
    }
    const imageViewer = (item: any) => {
        let imageData: IImageViewerDetail = {
            imageURL: item.ImageUrl,
            itemDesc: item.ItemDesc,
            itemID: item.ItemDetails?.Extn?.ExtnPOSItemID,
            skuID: item.ItemID,
            price: item.UnitPrice,
            qty: item.OrderedQty
        }

        setImagePopUp(true);
        setImageData(imageData);
    }
    const handleNavigation = (row: any) => {

        navigate("/PendingReceipt?orderno=" + orderDetail._source?.Shipment?.OrderNo + "&shipmentkey=" + orderDetail._source?.Shipment?.ShipmentKey + "&shipmentcontainerkey=" + row.ShipmentContainerKey + "&store=" + orderDetail._source?.Shipment?.ExtnPickupStoreID)
    }

    const getColumns = () => {
        const columns = [
            {
                name: 'Tracking # Details',
                cell: (row: any) => row.TrackingNo,
                minWidth: Utils.isMobile ? "200px" : "250px",
                width: Utils.isMobile ? "200px" : "250px",

            },

            {
                name: '',
                cell: (row: any) => WebGridButton(row),
                button: true,
                minWidth: Utils.isMobile ? "200px" :  "250px",
                width: Utils.isMobile ? "200px" :  "250px",
            },

        ];
        return columns;
    }

    const WebGridButton = (row: any) => {
        return <React.Fragment>
            <Button variant="contained" color="primary" className='btn' onClick={() => { handleNavigation(row) }}>Go to Order</Button>
        </React.Fragment >
    }

    React.useEffect(() => {
        async function getOrderDetail() {
            await getDetail()
        }
        getOrderDetail()
    }, [location]);
    let errorPopupButtons: IButton[] = [
        {
            text: "Close",
            icon: <CloseIcon />,
            action: closeErrorPopup,
            color: Constants.Colors.red,
        },
    ];
    let Error: INotificationPopupSettings = {
        open: openError,
        type: Constants.NotificationPopupType.Error,
        title: "Error",
        msg: errorMsg,
        draggable: false,
        handleClose: closeErrorPopup,
        actions: errorPopupButtons
    }

    const closeImageViewer = () => {
        setImagePopUp(false);
        setImageData({} as IImageViewerDetail);
    }

    let imageViewerSettings: IImageViewerPopupSettings = {
        open: imagePopUp,
        imageDetail: imageData,
        close: closeImageViewer
    }

    const mobileView = () => {
        const height = Utils.displayHeight + 'px';
        return <React.Fragment >
            {<div style={{ paddingBottom: "150px" }}>
                <Paper id='bar' style={{ color: "white", top: 0, left: 0, right: 0, zIndex: 999, borderRadius: 0 }} className="appBarPrimary">
                    <Toolbar style={{ height: "46px", minHeight: "46px" }}>
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mr: 2 }}

                            onClick={() => { navigate(-1) }}
                        >
                            <ArrowBackIcon />
                        </IconButton>
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} style={{ marginLeft: "-48px" }}>
                            Order Detail
                        </Typography>
                        <IconButton style={{ background: "#D93A2F", color: "white" }} sx={{ p: '10px' }} aria-label="actions">
                        </IconButton>
                    </Toolbar>
                </Paper>

                {showPendingRcepit() && <Paper className='paperToBottomPadding' style={{ marginTop: "5px", padding: "10px" }}>
                    <Grid container spacing={2}   >
                        <Grid item xs={12} sm={12}   >
                            <div className='dataTable'>
                                <DataTable
                                    className='dataTable'
                                    title=""
                                    data={orderDetail?._source?.Shipment.Containers.Container}
                                    columns={getColumns()}
                                />
                            </div>
                        </Grid>

                    </Grid>
                </Paper>}

                <Paper style={{ marginTop: 2, paddingTop: 1, paddingBottom: 1 }}>
                    <Card style={{ margin: "6px" }} id='cardOrder'>
                        <CardContent style={{ padding: "10px" }}>
                            <Grid spacing={2} container style={{ margin: "0px" }} sx={{ flexGrow: 1 }}>
                                <Grid item xs={10} sm={9} style={{ padding: "8px" }}>
                                    <Typography variant="h6" style={{ padding: "0px" }} className=''>
                                        <b>Order # </b> {
                                            orderDetail?._source?.Shipment?.OrderNo != undefined && orderDetail?._source?.Shipment?.OrderNo != "" ?
                                                orderDetail?._source?.Shipment?.OrderNo
                                                :
                                                ""
                                        }
                                    </Typography>
                                    <Typography variant="h6" style={{ padding: "0px" }} className=''>
                                        <b>Status: </b>{
                                            orderDetail?._source?.Shipment?.OrderNo != undefined && orderDetail?._source?.Shipment?.OrderNo != "" ?
                                                getStatusLabel(orderDetail?._source?.Shipment?.Status)
                                                :
                                                ""
                                        }
                                    </Typography>
                                </Grid>
                                <Grid item xs={2} sm={3} style={{ padding: "0px" }}>
                                    {/* <IconButton
                                        onClick={() => navigateToSearchResult()}
                                        size="large"
                                        edge="start"
                                        color="inherit"
                                        aria-label="menu"
                                        sx={{ mr: 4 }}
                                        className='scanColor'
                                    >
                                        <ManageSearchIcon />
                                    </IconButton> */}
                                </Grid>
                            </Grid>

                            <Grid spacing={2} container alignItems="center" justifyContent="center" marginTop="2px" sx={{ flexGrow: 1 }}>
                                {showNavigation() && <Button variant='contained' className='btn' size='small'
                                    onClick={() => navigateToOrder()}>Go To Order</Button>}
                            </Grid>
                        </CardContent>
                    </Card>
                    {
                        orderDetail?._source?.Shipment?.OrderNo != undefined && orderDetail?._source?.Shipment?.OrderNo != "" ?
                            <React.Fragment>
                                {
                                    Utils.getValidShipLines(orderDetail?._source?.Shipment?.ShipmentLines?.ShipmentLine, true).map((line: any) => {

                                        return (
                                            <React.Fragment>
                                                <Card style={{ margin: "6px" }} >
                                                    <CardContent style={{ padding: "10px" }}>
                                                        <Grid spacing={2} container style={{ margin: "0px" }} sx={{ flexGrow: 1 }}>
                                                            <Grid item xs={4} sm={4} style={{ padding: "8px", borderRight: "1px solid #2f2d2d" }}>
                                                                <img alt="" style={{ height: "100px", cursor: "zoom-in" }} src={Utils.convertToHttps(line.ImageUrl ? line.ImageUrl : "")} onClick={() => imageViewer(line)} />
                                                            </Grid>
                                                            <Grid item xs={8} sm={8} style={{ padding: "8px", textAlign: "left" }}>
                                                                <Typography style={{ fontSize: "13px", fontWeight: 500, color: "#D93A2F" }}>{line.ItemDesc}</Typography>
                                                                <Typography style={{ fontSize: "11px" }}>Qty: <b> {line.OrderedQty}</b></Typography>
                                                                <Typography style={{ fontSize: "11px" }}>ItemID#: <b> {line.ItemID}</b></Typography>
                                                                <Typography style={{ fontSize: "11px" }}>Price: <b> {line.UnitPrice}</b></Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </CardContent>
                                                    {
                                                        line.KitCode == "BUNDLE" && line.ExtnIsWarrantyItem != "Y" && line.MinLineStatus != "9000" && line.MaxLineStatus != "9000" ?
                                                            <React.Fragment>
                                                                {
                                                                    orderDetail?._source?.Shipment?.ShipmentLines.ShipmentLine.map((bLine: any) => {
                                                                        if (bLine.BundleParentOrderLineKey != undefined && line.OrderLineKey != undefined && bLine.BundleParentOrderLineKey == line.OrderLineKey) {
                                                                            return (
                                                                                <CardContent style={{ padding: "10px" }}>
                                                                                    <Grid spacing={2} container style={{ margin: "0px" }} sx={{ flexGrow: 1 }}>
                                                                                        <Grid item xs={4} sm={4} style={{ padding: "8px", borderRight: "1px solid #2f2d2d" }}>
                                                                                            <img alt="" style={{ height: "100px" }} src={Utils.convertToHttps(bLine.ImageUrl ? bLine.ImageUrl : "")} onClick={() => imageViewer(bLine)} />
                                                                                        </Grid>
                                                                                        <Grid item xs={8} sm={8} style={{ padding: "8px", textAlign: "left" }}>
                                                                                            <Typography style={{ fontSize: "13px", fontWeight: 500, color: "#D93A2F" }}>{bLine.ItemDesc}</Typography>
                                                                                            <Typography style={{ fontSize: "11px" }}>Qty: <b> {bLine.OrderedQty}</b></Typography>
                                                                                            <Typography style={{ fontSize: "11px" }}>ItemID#: <b> {bLine.ItemID}</b></Typography>
                                                                                            <Typography style={{ fontSize: "11px" }}>Price: <b> {bLine.UnitPrice}</b></Typography>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </CardContent>
                                                                            )
                                                                        }
                                                                    })
                                                                }
                                                            </React.Fragment>
                                                            :
                                                            <Divider></Divider>
                                                    }
                                                </Card>
                                            </React.Fragment>
                                        );

                                    })
                                }
                            </React.Fragment>
                            :
                            <></>
                    }
                    <OrderDetailGrid loading={loading} orderDetailRoute={true} data={orderDetailGridData}></OrderDetailGrid>
                    <Card className='customerDetailsBoxPickOrder'>
                        <CardContent style={{ padding: "1px", background: "#F3F3F4" }}>
                            <Button onClick={() => { setDrawer(true) }} className="MobileDrawerButtons" variant="outlined">Customer Details</Button>
                            <Button onClick={() => { setHistoryDrawer(true) }} className="MobileDrawerButtons" variant="outlined">Fulfillment History</Button>
                        </CardContent>
                    </Card>
                </Paper>

               

                <SwipeableDrawer
                    anchor={"bottom"}
                    open={openHistoryDrawer}
                    onClose={() => { }}
                    onOpen={() => { }}
                    ModalProps={{ onBackdropClick: () => { setHistoryDrawer(false) } }}
                    onClick={() => { setHistoryDrawer(false) }}
                >
                    <Card style={{ margin: "6px" }}>
                        <CardContent style={{ padding: "10px" }}>
                            {
                                loading == false ?
                                    <OrderHistory orderDetail={true}></OrderHistory>
                                    :
                                    <></>
                            }
                        </CardContent>
                    </Card>
                </SwipeableDrawer>
                <Drawer
                    anchor={"bottom"}
                    open={openDrawer}
                    onClose={() => { }}
                    onClick={() => { setDrawer(false) }}
                >
                    <Card style={{ margin: "6px" }}>
                        <CardContent style={{ padding: "10px" }}>
                            {
                                orderDetail?._source?.Shipment?.OrderNo != undefined && orderDetail?._source?.Shipment?.OrderNo != "" ?
                                    <CustomerDetails customerDetail={customerDetail}></CustomerDetails>
                                    :
                                    <></>


                            }
                        </CardContent>
                    </Card>
                </Drawer>
            </div>}
        </React.Fragment>
    }
    const webView = () => {
        return (
            <div>
                <SiteBreadCrumbs siteBreadCrumbs={Constants.SiteBreadCrumbs.OrderDetail}></SiteBreadCrumbs>
                <div style={{ margin: "5px" }}>
                    <Paper className='paperToBottomPadding' style={{ padding: "10px" }}>
                        <Grid container spacing={2} display={"flex"}>
                            <Grid item xs={6} sm={6} className='textLeft'>
                                <Typography variant="h6" style={{ padding: "0px" }} className='scanColor'>
                                    Order #  {
                                        orderDetail?._source?.Shipment?.OrderNo != undefined && orderDetail?._source?.Shipment?.OrderNo != "" ?
                                            orderDetail?._source?.Shipment?.OrderNo
                                            :
                                            ""

                                    }
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={6} className='textRight'>
                                {/* <Button variant='contained' className='btn' onClick={() => navigateToSearchResult()} startIcon={<ManageSearchIcon />}>Advance Search</Button> */}
                                {showNavigation() && <Button variant='contained' className='btn' onClick={() => navigateToOrder()}>Go To Order</Button>}
                            </Grid>
                        </Grid>
                    </Paper>
                    {showPendingRcepit() && <Paper className='paperToBottomPadding' style={{ marginTop: "5px", padding: "10px" }}>
                        <Grid container spacing={2}   >
                            <Grid item xs={8} sm={12}   >
                                <div className='dataTable'>
                                    <DataTable
                                        className='dataTable'
                                        title=""
                                        data={orderDetail?._source?.Shipment.Containers.Container}
                                        columns={getColumns()}
                                    />
                                </div>
                            </Grid>

                        </Grid>
                    </Paper>}
                    <Paper style={{ marginTop: "10px" }}>
                        <Grid container spacing={2} display={"flex"}>
                            <Grid item xs={9} sm={9} className='textLeft'>
                                <div className="backdrop">
                                    <div className="title">
                                        <Typography> Order Lines</Typography>
                                    </div>
                                    <div className="mainContainer" style={{ padding: "20px" }}>
                                        <Grid container spacing={2} display={"flex"}>
                                            <Grid item xs={6} sm={6} className='textLeft'>
                                                <b>Status: </b> {
                                                    orderDetail?._source?.Shipment?.OrderNo != undefined && orderDetail?._source?.Shipment?.OrderNo != "" ?
                                                        getStatusLabel(orderDetail?._source?.Shipment?.Status)
                                                        :
                                                        ""
                                                }
                                            </Grid>
                                            <Grid item xs={1} sm={1} className='textCenter'>
                                            </Grid>
                                            <Grid item xs={2} sm={2} className='textCenter'>
                                            </Grid>
                                            <Grid item xs={2} sm={2} className='textCenter'>
                                            </Grid>
                                            <Grid item xs={1} sm={1} className='textCenter'>
                                                <Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>
                                                    Qty
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Divider />
                                        <div>
                                            {
                                                orderDetail?._source?.Shipment?.OrderNo != undefined && orderDetail?._source?.Shipment?.OrderNo != "" ?
                                                    <React.Fragment>
                                                        {
                                                            Utils.getValidShipLines(orderDetail?._source?.Shipment?.ShipmentLines?.ShipmentLine, true).map((line: any) => {

                                                                return (
                                                                    <React.Fragment>
                                                                        <Grid container spacing={2} display={"flex"}>
                                                                            <Grid item xs={6} sm={6} className='textLeft'>
                                                                                <Grid spacing={2} container style={{ margin: "0px" }} sx={{ flexGrow: 1 }}>
                                                                                    <Grid item xs={4} sm={4} style={{ padding: "8px", borderRight: "1px solid #2f2d2d", display: "flex", justifyContent: "center" }}>
                                                                                        <div className="image-zoom" onClick={() => imageViewer(line)}>
                                                                                            <img alt="" style={{ height: "auto", width: "100%" }} src={Utils.convertToHttps(line.ImageUrl ? line.ImageUrl : "")} />
                                                                                        </div>
                                                                                    </Grid>
                                                                                    <Grid item xs={8} sm={8} style={{ padding: "8px", textAlign: "left" }}>
                                                                                        <Typography style={{ fontSize: "15px", fontWeight: 500, color: "#D93A2F" }}>{line.ItemDesc}</Typography>
                                                                                        <Typography variant="body2" sx={{ fontSize: 15 }} gutterBottom>
                                                                                            ItemID # <b>{line.ItemID}</b>
                                                                                        </Typography>
                                                                                        <Typography variant="body2" sx={{ fontSize: 15 }} gutterBottom>
                                                                                            Price: <b>${line.UnitPrice}</b>
                                                                                        </Typography>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Grid>
                                                                            <Grid item xs={1} sm={1} className='textCenter' style={{ padding: "8px" }}>
                                                                            </Grid>
                                                                            <Grid item xs={2} sm={2} className='textCenter' style={{ padding: "8px" }}>
                                                                            </Grid>
                                                                            <Grid item xs={2} sm={2} className='textCenter' style={{ padding: "8px" }}>
                                                                            </Grid>
                                                                            <Grid item xs={1} sm={1} className='textCenter'>
                                                                                <Typography variant="body2" sx={{ fontSize: 15 }} gutterBottom>
                                                                                    {line.OrderedQty}
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                        {
                                                                            line.KitCode == "BUNDLE" && line.ExtnIsWarrantyItem != "Y" && line.MinLineStatus != "9000" && line.MaxLineStatus != "9000" ?
                                                                                <React.Fragment>
                                                                                    {
                                                                                        orderDetail?._source?.Shipment?.ShipmentLines.ShipmentLine.map((bLine: any) => {
                                                                                            if (bLine.BundleParentOrderLineKey != undefined && line.OrderLineKey != undefined && bLine.BundleParentOrderLineKey == line.OrderLineKey) {
                                                                                                return (
                                                                                                    <Grid container spacing={2} display={"flex"}>
                                                                                                        <Grid item xs={6} sm={6} className='textLeft'>
                                                                                                            <Grid spacing={2} container style={{ margin: "0px" }} sx={{ flexGrow: 1 }}>
                                                                                                                <Grid item xs={4} sm={4} style={{ padding: "8px", borderRight: "1px solid #2f2d2d", display: "flex", justifyContent: "center" }}>
                                                                                                                    <div className="image-zoom" onClick={() => imageViewer(bLine)}>
                                                                                                                        <img alt="" style={{ height: "auto", width: "100%" }} src={Utils.convertToHttps(bLine.ImageUrl ? bLine.ImageUrl : "")} />
                                                                                                                    </div>
                                                                                                                </Grid>
                                                                                                                <Grid item xs={8} sm={8} style={{ padding: "8px", textAlign: "left" }}>
                                                                                                                    <Typography style={{ fontSize: "15px", fontWeight: 500, color: "#D93A2F" }}>{bLine.ItemDesc}</Typography>
                                                                                                                    <Typography variant="body2" sx={{ fontSize: 15 }} gutterBottom>
                                                                                                                        ItemID # <b>{bLine.ItemID}</b>
                                                                                                                    </Typography>
                                                                                                                    <Typography variant="body2" sx={{ fontSize: 15 }} gutterBottom>
                                                                                                                        Price: <b>${bLine.UnitPrice}</b>
                                                                                                                    </Typography>
                                                                                                                </Grid>
                                                                                                            </Grid>
                                                                                                        </Grid>
                                                                                                        <Grid item xs={1} sm={1} className='textCenter' style={{ padding: "8px" }}>
                                                                                                        </Grid>
                                                                                                        <Grid item xs={2} sm={2} className='textCenter' style={{ padding: "8px" }}>
                                                                                                        </Grid>
                                                                                                        <Grid item xs={2} sm={2} className='textCenter' style={{ padding: "8px" }}>
                                                                                                        </Grid>
                                                                                                        <Grid item xs={1} sm={1} className='textCenter'>
                                                                                                            <Typography variant="body2" sx={{ fontSize: 15 }} gutterBottom>
                                                                                                                {line.OrderedQty}
                                                                                                            </Typography>
                                                                                                        </Grid>
                                                                                                    </Grid>
                                                                                                )
                                                                                            }
                                                                                        })
                                                                                    }
                                                                                </React.Fragment>
                                                                                :
                                                                                <Divider></Divider>
                                                                        }
                                                                    </React.Fragment>
                                                                );

                                                            })
                                                        }
                                                    </React.Fragment>
                                                    :
                                                    <></>
                                            }

                                        </div>
                                    </div>
                                </div>
                                <OrderDetailGrid loading={loading} orderDetailRoute={true} data={orderDetailGridData}></OrderDetailGrid>
                                <div className="backdrop">
                                    <div className="title">
                                        <Typography> Customer Details</Typography>
                                    </div>
                                    {
                                        orderDetail?._source?.Shipment?.OrderNo != undefined && orderDetail?._source?.Shipment?.OrderNo != "" ?
                                            <CustomerDetails customerDetail={customerDetail}></CustomerDetails>
                                            :
                                            <></>
                                    }
                                </div >
                            </Grid>
                            <Grid item xs={3} sm={3} className='textRight' style={{}}>
                                <div>
                                    {
                                        loading == false ?
                                            <OrderHistory orderDetail={true}></OrderHistory>
                                            :
                                            <></>
                                    }
                                </div>
                            </Grid>
                        </Grid>
                    </Paper>
                </div >
            </div >
        );
    }
    return (
        <div>
            <NotificationPopup {...Error}></NotificationPopup>
            <ImageViewerPopup {...imageViewerSettings}>
            </ImageViewerPopup>
            {Utils.isMobile ? mobileView() : webView()}
        </div>
    );

}

export default OrderDetail;
import React, { useState } from "react";
import "./ContactCustomer.scss";
import {
    Grid,
    TextField,
    Typography,
} from "@mui/material";
import { PersonIcon, AddLocationAltIcon, EmailIcon, LocalPhoneIcon } from "./SharedIcons";
import { CardContent } from "@material-ui/core";
import Utils from "../../Common/Utils";
export const CustomerDetails: React.FC<any> = (props: any) => {

    const { customerDetail } = props
    console.log(customerDetail, "customerDetail");

    const renderWebDetails = () => {

        return <React.Fragment>
            {

                <div className="mainContainer" style={{ padding: "20px" }}>
                    <Grid container spacing={2} display={"flex"} style={{ padding: "10px" }}>
                        <Grid item xs={6} sm={6} className='textLeft' style={{ display: "flex" }}>
                            <PersonIcon></PersonIcon>  <Typography style={{ fontSize: "18px", fontWeight: 500, marginLeft: "10px" }}>{customerDetail.name}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} className='textLeft' style={{ display: "flex" }}>
                            <AddLocationAltIcon /> <Typography style={{ fontSize: "18px", fontWeight: 500, marginLeft: "7px" }}>{customerDetail.address}</Typography>

                        </Grid>
                    </Grid>
                    <Grid container spacing={2} display={"flex"} style={{ padding: "10px" }}>
                        <Grid item xs={6} sm={6} style={{ display: "flex"}}>
                            <EmailIcon></EmailIcon><Typography style={{ fontSize: "18px", fontWeight: 500, marginLeft: "10px",wordBreak: "break-all"}}>{customerDetail.email}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} className='textLeft'>
                            <Typography style={{ fontSize: "18px", fontWeight: 500, marginLeft: "35px" }}>{customerDetail.city}, {customerDetail.state}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} display={"flex"} style={{ padding: "10px" }}>
                        <Grid item xs={6} sm={6} className='textLeft' style={{ display: "flex" }}>
                            <LocalPhoneIcon></LocalPhoneIcon>   <Typography style={{ fontSize: "18px", fontWeight: 500, marginLeft: "10px" }}>{customerDetail.phone}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} className='textLeft'>
                            <Typography style={{ fontSize: "18px", fontWeight: 500, marginLeft: "35px" }}>{customerDetail.zipCode}</Typography>
                        </Grid>
                    </Grid>
                </div >

            }
        </React.Fragment>

    }

    const renderMobileDetails = () => {

        return <React.Fragment>
            {

                <CardContent style={{ padding: "10px" }}>
                    <Typography variant="h5" component="div">
                        {customerDetail.name}
                        <br />
                    </Typography>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        {customerDetail.address} {customerDetail.city} {customerDetail.state} {customerDetail.zipCode}
                    </Typography>
                    <hr></hr>
                    <Typography variant="body2">
                        Email:{customerDetail.email}
                    </Typography>
                    <Typography variant="body2">
                        Phone: {customerDetail.phone}
                    </Typography>
                </CardContent>
            }
        </React.Fragment>

    }

    return (
        <div>
            {Utils.isMobile ? renderMobileDetails() : renderWebDetails()}
        </div>
    );
};

import React, { useCallback, useState } from 'react';
import './App.scss';
import { TailSpin } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import {
    Routes,
    Route,
    useLocation
} from "react-router-dom";
import Utils from './Common/Utils';
import Constants from './Common/Constants';
import CacheBuster from './Managers/Cache.Buster'
import AuthManager from './Managers/Auth.manager';
import IdentityManager from './Managers/Identity.manager';
import Dashboard from './Routes/Dashboard';
import FulfillOrders from './Routes/FulfillOrders';
import ReceiveOrders from './Routes/ReceiveOrders';
import Reports from './Routes/Reports';
import OrdersSyncFlow from './Routes/OrdersSyncFlow';
import KCDCReturns from './Routes/KCDCReturns';
import Navbar from './Subcomponent/Shared/Navbar';
import DrawerRow from './Subcomponent/Shared/DrawerRow';
import UserInfoDialog from './Subcomponent/Dialog/UserInfoDialog';
import ErrorIcon from '@material-ui/icons/Error';
import MobileNavbar from './Subcomponent/Shared/MobileNavbar';
import FullScreenSearch from './Subcomponent/MobileSearchBar/FullScreenSearch'
import KCDCReturnDetail from './Routes/KCDCReturnDetail'
import { useDispatch, useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import { render } from '@testing-library/react';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import PickOrder from './Subcomponent/PickOrder/PickOrder';
import BulkPickOrder from './Subcomponent/PickOrder/BulkPickOrder';
import PackOrder from './Routes/PackOrder';
import NotAuthorized from './Routes/NotAuthorized';
import OrderDetail from './Routes/OrderDetail'
import CustomerPickup from './Routes/CustomerPickup';
import PendingReceipt from './Routes/PendingReceipt';
import ExternalCustomerView from './Routes/ExternalCustomerView';
import SearchResult from './Routes/Search'
import ActionLoader from './Subcomponent/Shared/ActionLoader';
import allActions from './Store/Actions/AllActions';
import PFManager from './Managers/Process.Fulfillment.Manager';
import TechAudits from "./Routes/TechAudits"
import StoreManager from './Managers/Store.manager';
import { GenericSuccessNotification } from './Subcomponent/Shared/GenericSuccessNotification';
import OMSManager from './Managers/Oms.manager';
import { usePickerState } from '@material-ui/pickers';
import LabelText from './Common/LabelText';
import ResizeObserver from 'resize-observer-polyfill';
import OrderManager from './Managers/Orders.Manager';
import IStoreInfo from './Models/IStoreInfo';

if (!window.ResizeObserver) {
    window.ResizeObserver = ResizeObserver;
}
const App = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [store, setStore] = React.useState([]);
    const [district, setDistrict] = React.useState([]);
    const [region, setRegion] = React.useState([]);
    const [count, setCount] = React.useState(0);
    const [selected, setSelectedHeader] = useState("");
    const [mobileSelected, setMobileSelected] = useState(0);
    const [displayDrawer, setdisplayDrawer] = useState(false);
    const [userProfileDialog, setuserProfileDialog] = useState(false);
    const [isNavigationLoaded, setNavigationLoaded] = useState(false);
    const [omsProfileNotSetUpError, setOmsProfileNotSetUpError] = useState("");
    const [data, setData] = useState(null);
    const boxRef = React.useRef<HTMLInputElement>(null);
    const [showFullScreenSearch, setFullScreenShowSearch] = useState(false);
    //const stores = useSelector((state: any) => state.ActionLoaderReducersState.stores);
    const kcdcQuantity = useSelector((state: any) => state.ActionLoaderReducersState.kcdcTotalQuantity);
    const districtsList = useSelector((state: any) => state.ActionLoaderReducersState.districts);
    const regions = useSelector((state: any) => state.ActionLoaderReducersState.regions);

    const changeStoreList = (event: any, value: any) => {
        if (value && value.length == 1 && (value[0].id !== undefined || value[0].id !== "")) {
            let res = StoreManager.allStoredata.find(r => r.StoreId.toString() === value[0].id)
            if (res !== undefined && res !== null) {
                StoreManager.currentStore = res;
            }
        }
        else if (value && value.length == 0) {
            StoreManager.currentStore = {} as IStoreInfo;
            setDistrict([]);
            setRegion([]);
            navigate("/Dashboard")
        }
        else if (value && value.length > 1) {
            StoreManager.currentStore = {} as IStoreInfo;
        }
        setStore(value)
    }

    const changeDistrict = (event: any, value: any) => {
        let _districtStores: any = [];
        value.forEach((district: any) => {
            const stores = StoreManager.storeList.filter(s => s.districtId.toString() == district.id);
            _districtStores.push(...stores);
        });
        if (value && value.length == 0) {
            StoreManager.currentStore = {} as IStoreInfo;
            setRegion([]);
        }
        setDistrict(value);
        setStore(_districtStores);
    }

    const changeRegion = (event: any, value: any) => {
        let _districts: any = [];
        value.forEach((region: any) => {
            const districts: any = districtsList.filter((d: any) => d.regionId == region.id);
            _districts.push(...districts);
        });
        setRegion(value);
        changeDistrict(null, _districts);
    }

    const fullScreenSearchClose = () => {
        setFullScreenShowSearch(false)
    }
    const fullScreenSearchOpen = () => {
        setFullScreenShowSearch(true)
    }
    const errorFromOtherScreen = () => {

        setOmsProfileNotSetUpError(LabelText.omsProfileNotConfigured);
        setError(true);

    }
    const resumeInit = useCallback(async () => {
        if (!Utils.isExternalRoute()) {
            console.log("get initial data");
            setHeader();
            await getConfigurations();
            getRights().then(async () => {
                if (IdentityManager.isAdmin || IdentityManager.storeNumber !== '') {
                    setNavigationLoaded(true);
                    routeGuard(location);
                    setLoading(false);
                    let _stores: any = await Utils.getInitialData(errorFromOtherScreen);
                    dispatch(allActions.actionLoaderActions.setStores(_stores));
                    let _districts: any = StoreManager.getDistrictList();
                    dispatch(allActions.actionLoaderActions.setDistricts(_districts));
                    let _regions: any = StoreManager.getRegionsList();
                    dispatch(allActions.actionLoaderActions.setRegions(_regions));
                    setLoading(false);

                }
                else {
                    setError(true);
                }
            })

        }
        else {
            Utils.getInitialDataExternalLink();
            setLoading(false);
        }


    }, []);
    const selectProfileStores = () => {
        let employeeID = IdentityManager.getUserProperty(Constants.EmployeeID)
        if (employeeID && employeeID != "") {
            employeeID = IdentityManager.getUserProperty(Constants.EmployeeID)?.toString().padStart(6, "0")
            const isRvp = IdentityManager.isRvp
            const isDM = IdentityManager.isDM
            if (isRvp) {
                const empRegions: any = StoreManager.allStoredata.filter(r => r.RMId === employeeID)// multiple region                
                if (empRegions && empRegions.length > 0) {
                    const mapEmpRegions = Utils.mapRegionsForDropdown(empRegions)
                    changeRegion(null, mapEmpRegions)
                }

            }
            if (isDM) {
                const empDistricts = StoreManager.allStoredata.filter(r => r.DMId.toString() === employeeID)// multiple districts                
                if (empDistricts && empDistricts.length > 0) {
                    const district = districtsList.filter((d: any) => d.id == empDistricts[0].DistrictId);
                    changeDistrict(null, district);
                }
            }
        }
    }

    const selectAllRegions = () => {
        dispatch(allActions.actionLoaderActions.setEnterprise(true));
        changeRegion(null, regions);
    }

    const authorizeUser = useCallback(async () => {
        console.log("authorizeUser");
        if (Utils.checkUserProfile_LocalStorage()) {
            let userInfoString = localStorage.getItem(Constants.USER_PROFILE);
            if (typeof userInfoString == "string") {
                await resumeInit();
            }
        } else {
            localStorage.removeItem(Constants.createLogin);
            AuthManager.signin();
        }
    }, [resumeInit]);

    const routeGuard = useCallback((_location: any) => {
        console.log("routeGuard");
        let path = _location.pathname.replace("/", "");
        if (path && path.length > 0 && path != "NotAuthorized") {
            if (!Utils.isExternalRoute()) {
                const allFoundGroups = IdentityManager.getUserAllGroups();
                let targetGroup = allFoundGroups.length > 0 ? allFoundGroups[0] : null;
                if (targetGroup) {
                    try {
                        if (targetGroup[path] == "0" || targetGroup[path] == undefined) {
                            setError(true);
                        }
                    }
                    catch {
                        setError(true);
                    }
                }
                else {
                    setError(true);
                }
            }
        }
    }, [navigate]);
    const checkAuth = useCallback(async () => {
        //
        console.log("checkAuth");
        if (IdentityManager.isTokenValid) {
            localStorage.removeItem(Constants.USER_PROFILE);
            await authorizeUser();
        } else {
            await resumeInit();
        }
    }, [authorizeUser, resumeInit]);

    async function getRights() {
        await PFManager.setGroupsRights();
    }

    const getConfigurations = async () => {
        await PFManager.getConfigurations();
    }


    const resetKcdcQuantity = () => {
        dispatch(allActions.actionLoaderActions.setKcdcTotalQuantity(0));
    }

    const getKcdcTotalQuantity = () => {
        if (StoreManager.currentStore?.StoreId) {
            let storeId = StoreManager.currentStore.StoreId.toString().split(" ");
            OrderManager.getkcdcOrders(storeId).then((res: any) => {
                if (res.success && res.data?.hits?.totalShipmentLines > 0) {
                    let totalShipmentLines = res.data?.hits.totalShipmentLines;
                    dispatch(allActions.actionLoaderActions.setKcdcTotalQuantity(totalShipmentLines));
                }
                else {
                    resetKcdcQuantity();
                }
            }).catch(err => {
                console.log("KCDC Total Quanty Error", err);
            });
        }
        else {
            resetKcdcQuantity();
        }
    }

    React.useEffect(() => {
        if (Utils.isExternalRoute()) {
            resumeInit();
            routeGuard(location);
            setNavigationLoaded(true);

        }
        else {
            if (!Utils.checkUserProfile_LocalStorage()) {
                // check if the user has profile in localstorage otherwise redirect to checkAuth
                checkAuth();
            }
            else {
                CacheBuster.checkVersion();
                resumeInit();
            }

        }
        return () => {
            // TODO document why this arrow function is empty


        }
    }, [location, checkAuth, routeGuard]);

    React.useEffect(() => {

        getKcdcTotalQuantity();
        const intervalId = setInterval(() => {
            getKcdcTotalQuantity();
        }, 1000 * 30)

        return () => {
            clearInterval(intervalId);
        }
    }, [StoreManager.currentStore.StoreId]);

    const setHeader = () => {
        let list = PFManager.navigationData
        let name = window.location.pathname.includes('/') && window.location.pathname.length > 1 ? window.location.pathname.split('/')[1] : window.location.pathname;
        if (name == "PickOrder" || name == "PackOrder" || name == "CustomerPickup" || name == "BulkPickOrder") {
            name = "FulfillOrders"
        }
        if (name == "PendingReceipt") {
            name = "ReceiveOrders"
        }
        let selected = list.find(r => name === r.Route);
        if (selected !== undefined && selected !== null) {
            setSelectedHeader(selected.Header);
            setMobileSelected(selected.Position > 3 ? 3 : selected.Position)
        }
    }
    const toggleDrawer = () => {
        setdisplayDrawer(!displayDrawer);
    }

    const setSelectedOption = (newSelected: string) => {

        setSelectedHeader(newSelected);
        setdisplayDrawer(false);
    }
    const changeState = (data: any) => setData(data)
    const renderDrawer = () => {

        const height = Utils.displayHeight + 'px';
        let path = location.pathname.replace("/", "");
        let n = 1.15;
        if (path && path.length > 0) {

            if (path.toLowerCase() !== "dashboard") {
                n = 1.09
            }
        }

        const webbarHeight = Utils.isMobile ? (Utils.getMobilebarheight * n) + "px" : Utils.getWebbarheight + 'px';

        const loaderLeft = Utils.isMobile ? "42%" : "48%"
        const displayDrawerData = IdentityManager.getUserNavigation()

        return (
            <React.Fragment>
                <React.Fragment>
                    {
                        Utils.isMobile && displayDrawer &&
                        <div style={{ height: height, width: '100vw', position: 'absolute', zIndex: 2, top: '62px', background: 'rgba(0,0,0,0.7)' }} onClick={toggleDrawer}>
                            <div style={{ height: height, width: '200px', background: '#FFFFFF', zIndex: 3 }}>
                                {
                                    displayDrawerData.map((item: any, _i: number) =>
                                        <DrawerRow data={item} key={Utils.generateRandom(10, Constants.CryptoRandomTypes.numeric)} selected={selected} setSelectedOption={setSelectedOption}></DrawerRow>)
                                }
                            </div>
                        </div>
                    }
                </React.Fragment>
                {Utils.isExternalRoute() ? "" : <React.Fragment>
                    <Navbar
                        key={Utils.generateRandom(10, Constants.CryptoRandomTypes.numeric)}
                        loading={loading}
                        changeState={changeState}
                        toggleDrawer={toggleDrawer}
                        setSelectedOption={setSelectedOption}
                        openFullScreenSearch={fullScreenSearchOpen}
                        changeStoreList={changeStoreList}
                        store={store}
                        district={district}
                        changeDistrict={changeDistrict}
                        region={region}
                        changeRegion={changeRegion}
                        setuserProfileDialog={setuserProfileDialog}
                    />
                </React.Fragment>}
                {
                    loading
                        ?
                        <div style={{ position: "absolute", top: "42%", left: loaderLeft }}>
                            <TailSpin color="#D93A2F" height={80} width={80} />
                        </div>
                        :
                        renderBody(displayDrawerData, height, webbarHeight)
                }
            </React.Fragment >
        );
    }

    const renderBody = (displayDrawerData: any, height: any, webbarHeight: any) => {
        if (!Utils.isMobile) {
            return <div className="drawerDiv" style={{ marginTop: webbarHeight }}>
                {Utils.isExternalRoute() ? "" : renderNavigation(displayDrawerData, height)}
                <div className={Utils.isExternalRoute() ? "renderExternalComponent" : "renderComponent"} style={{ minHeight: height, marginLeft: Utils.isExternalRoute() ? "0px" : "240px" }}>
                    <div style={{ overflowY: "hidden" }}>
                        {renderRoutes()}
                    </div>
                </div>
            </div>
        }
        else {
            return <div className="drawerDiv" style={{ marginTop: webbarHeight }}>
                {Utils.isExternalRoute() ? "" : renderNavigation(displayDrawerData, height)}
                < Box sx={{ pb: 7 }
                }>
                    <CssBaseline />
                    <div className="renderComponent" style={{ minHeight: height }}>
                        <div>
                            {renderRoutes()}
                        </div>
                    </div>
                </Box >
            </div >
        }
    }
    const changeNavigationFromDashboard = (route: string, type: number) => {
        let selectedRoute = IdentityManager.getUserNavigation().filter(e => e.Route == route)[0]
        setSelectedHeader(selectedRoute.Header);
        setMobileSelected(selectedRoute.position)
        setdisplayDrawer(false);
        if (type != 0) {
            dispatch(allActions.fulfillOrdersActions.changeFOTab(type));
        }
        navigate(route)
    }

    const setMobileNavSelectedValue = (mobile: number) => {
        setMobileSelected(mobile)
    }
    const renderRoutes = () => {
        return <Routes>
            <Route path="/" element={<Dashboard navigateToDrawer={changeNavigationFromDashboard} store={store} district={district} changeDistrict={changeDistrict} changeStore={changeStoreList} changeRegion={changeRegion} selectProfileStores={selectProfileStores} selectAllRegions={selectAllRegions} />} />
            <Route path="/Dashboard" element={<Dashboard navigateToDrawer={changeNavigationFromDashboard} store={store} district={district} changeDistrict={changeDistrict} changeStore={changeStoreList} changeRegion={changeRegion} selectProfileStores={selectProfileStores} selectAllRegions={selectAllRegions} />} />
            <Route path="/FulfillOrders" element={<FulfillOrders store={store} />} />
            <Route path="/PickOrder" element={<PickOrder store={store} />} />
            <Route path="/BulkPickOrder" element={<BulkPickOrder />} />
            <Route path="/PackOrder" element={<PackOrder store={store} />} />
            <Route path="/ReceiveOrders" element={<ReceiveOrders store={store} />} />
            <Route path="/KCDCReturns" element={<KCDCReturns store={store} />} />
            <Route path="/Reports" element={<Reports store={store} />} />
            <Route path="/OrdersSyncFlow" element={<OrdersSyncFlow store={store} />} />
            <Route path="/CustomerPickup" element={<CustomerPickup store={store} />} />
            <Route path="/NotAuthorized" element={<NotAuthorized />} />
            <Route path="/PendingReceipt" element={<PendingReceipt store={store} />} />
            <Route path="/Search" element={<SearchResult />} />
            <Route path="/OrderDetail" element={<OrderDetail />} />
            <Route path="/KCDCReturnDetail" element={<KCDCReturnDetail store={store} />} />
            <Route path="/ExternalCustomerView" element={<ExternalCustomerView />} />
            <Route path="/TechAudits" element={<TechAudits store={store} />} />
        </Routes>
    }

    const renderNavigation = (displayDrawerData: any, height: any) => {
        if (!Utils.isMobile) {
            return <div className="drawer" style={{ minHeight: height }}>
                {
                    displayDrawerData.map((item: any, i: number) =>
                        <DrawerRow data={item} key={i} selected={selected} setSelectedOption={setSelectedOption}></DrawerRow>)
                }
            </div>
        }
        else {
            return <MobileNavbar displayDrawerData={displayDrawerData} selected={mobileSelected} setMobileNavSelectedValue={setMobileNavSelectedValue} />
        }

    }
    const renderError = () => {
        return (
            <div className='UserError'>
                <div className='title'><ErrorIcon style={{ fontSize: '60px' }} />ERROR</div>
                <div className='subtitle'>{omsProfileNotSetUpError && omsProfileNotSetUpError.length > 0 ? `${omsProfileNotSetUpError}` : ""}Your profile is not configured to access this resource</div>
                <div className='subtitle'>Please <a onClick={() => {

                    AuthManager.logout();

                }} href='#'>click here</a> to Logout and Login again. In case if issue still persist contact the IT Help Desk (818) 735-8800 ext. 2660 or email <a href={"mailto:gwitickets@guitarcenter.com"}>gwitickets@guitarcenter.com</a></div>
            </div>
        );
    }
    return (
        <div className="App">
            <GenericSuccessNotification></GenericSuccessNotification>
            {isNavigationLoaded ?
                <React.Fragment>
                    <ActionLoader></ActionLoader>
                    {showFullScreenSearch ? <FullScreenSearch fullScreenClose={fullScreenSearchClose}></FullScreenSearch> : <></>}
                    <UserInfoDialog
                        changeState={changeState}
                        open={userProfileDialog}
                        setuserProfileDialog={setuserProfileDialog}
                    ></UserInfoDialog>
                    {
                        error
                            ?
                            renderError()
                            :
                            renderDrawer()
                    }
                </React.Fragment>
                :
                <div style={{ position: "absolute", top: "42%", left: Utils.isMobile ? "42%" : "48%" }}>
                    <TailSpin color="#D93A2F" height={80} width={80} />
                </div>
            }
        </div>
    );


}

export default App;


import React, { useEffect, useState } from 'react';
import './Routes.scss';
import { useTheme, withStyles } from '@material-ui/core/styles';
import { Button, Grid } from '@material-ui/core';
import Utils from '../Common/Utils';
import Box from '@material-ui/core/Box';
import { Card, CardContent, Divider, InputBase, TextField } from '@mui/material';
import Stack from '@mui/material/Stack';
import DataTable from 'react-data-table-component';
import { IconButton, Paper, Typography } from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import SiteBreadCrumbs from '../Subcomponent/Shared/SiteBreadCrumbs'
import { ReceiptLongIcon, FilterAltIcon, DocumentScannerIcon, SearchIcon, AssignmentIcon, CloseIcon } from "../Subcomponent/Shared/SharedIcons"
import MobileBarCodeScannerTrackingNumberScandit from '../Subcomponent/ReceiveOrders/MobileBarCodeScannerTrackingNumberScandit';
import allActions from '../Store/Actions/AllActions';
import Constants from '../Common/Constants';
import Drawer from '@mui/material/Drawer';
import OMSManager from '../Managers/Oms.manager';
import OrderManager from '../Managers/Orders.Manager';
import IdentityManager from '../Managers/Identity.manager';
import { IButton, INotificationPopupSettings } from '../Models/INotificationPopupSettings';
import { NotificationPopup } from '../Subcomponent/Shared/NotificationPopup';
const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const isAfterShip = urlParams.get('ship');

const steps = [
    'Pick',
    'Pack'
];
const steps2 = [
    'Pick',
    'Customer Pickup'
]
const CreateButton = withStyles(({
    root: {
        color: 'white',
        fontWeight: 600,
        backgroundColor: "#D93A2F",
        '&:hover': { backgroundColor: "#bd3627" },
    },
}))(Button);
const KCDCReturns = (props: any) => {
    // generate random Data fro faker js
    const dispatch = useDispatch();
    const showActionLoader = () => {
        dispatch(allActions.actionLoaderActions.showLoader());
    }
    const hideActionLoader = () => {
        dispatch(allActions.actionLoaderActions.hideLoader());
    }

    var startProductBarPos = -1;
    window.onscroll = function () {
        var bar = document.getElementById('paginationWebId') as any;
        var dataTable = document.getElementById('containerDataTable') as any;
        if (bar && dataTable) {
            var dataTableWidth = dataTable.offsetWidth;
            if (startProductBarPos < 0) startProductBarPos = findPosY(bar);
            if (window.pageYOffset > startProductBarPos && (window.pageYOffset - startProductBarPos > 50)) {
                bar.style.width = (dataTableWidth - (0.8 * dataTableWidth / 100)) + "px";
                bar.classList.add("fixedPagination");
                bar.style.marginTop = Utils.getWebbarheight + "px";
            } else {
                bar.classList.remove("fixedPagination");
                bar.style.width = "";
                bar.style.marginTop = "";
            }
        }
        // Mobile View Fix the Tabs and FILTER
        if (Utils.isMobile) {
            console.log("scrolled");
            bar = document.getElementById('tabFilterContaoner') as any;
            var mblBar = document.getElementById('mblBar') as any;
            if (bar) {
                if (startProductBarPos < 0) startProductBarPos = findPosY(mblBar);
                if (window.pageYOffset > startProductBarPos) {
                    bar.classList.add("scrolledTopFix");
                } else {
                    bar.classList.remove("scrolledTopFix");
                }
            }
        }
    };
    function findPosY(obj: any) {
        var curtop = 0;
        if (obj && typeof (obj.offsetParent) != 'undefined' && obj.offsetParent) {
            while (obj.offsetParent) {
                curtop += obj.offsetTop;
                obj = obj.offsetParent;
            }
            curtop += obj.offsetTop;
        }
        else if (obj && obj.y)
            curtop += obj.y;
        return curtop;
    }
    const TestState = useSelector((state: any) => state.TestReducerState);
    console.log(TestState, "TestState");
    const navigate = useNavigate();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [searchTracking, setSearchTracking] = useState("")
    let orderRows = useSelector((state: any) => state.KcdcReducerState.allkcdcOrders);
    if (searchTracking && searchTracking.length > 0) {
        orderRows = orderRows.filter((a: any) => a._source.Shipment.ShipmentNo.indexOf(searchTracking) > -1)
    }

    let from = (page) * rowsPerPage
    let to = from + rowsPerPage
    let paginated = orderRows.slice(from, to);
    const theme = useTheme();
    // Drawer Code
    const anchor = "right";
    const [openDrawer, setDrawer] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [openError, setOpenError] = useState(false);

    const [openDetailDrawer, setOpenDetailDrawer] = useState(false);
    const [value, setValue] = useState("");

    const toggleDrawer = (op: any) => {
        setDrawer(op);
    };
    // Pagination 

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    const loadOrder = (row: any) => {
        if (row._source.Shipment.ShipNode && row._source.Shipment.ShipNode > 0) {
            let shipNode = row._source.Shipment.ShipNode;
            navigate("/KCDCReturnDetail?shipmentkey=" + row._source.Shipment.ShipmentKey + "&store=" + shipNode);
        }
        else {
            navigate("/KCDCReturnDetail?shipmentkey=" + row._source.Shipment.ShipmentKey);
        }
    }

    const handleChangeOrder = (row: any) => {
        loadOrder(row);
    }

    // End Drawer Code
    const handleChange = (event: any) => {
        setValue(event.target.value);
    };

    const getData = () => {
        let storeId = IdentityManager.getMultipleStoresId(props.store);
        if (!storeId) {
            return
        }

        showActionLoader()
        setTimeout(() => {
            OrderManager.getkcdcOrders(storeId).then((resultResponse: any) => {

                if (resultResponse.success) {
                    let result = resultResponse.data
                    let dataRows = result.hits.hits;

                    if (result.hits?.totalShipmentLines > 0) {
                        dispatch(allActions.actionLoaderActions.setKcdcTotalQuantity(result.hits.totalShipmentLines));
                    }

                    if (dataRows.length > 0) {

                        dispatch(allActions.kcdcOrdersActions.loadKcdcOrders(dataRows));


                    }
                    else {

                        dispatch(allActions.kcdcOrdersActions.loadKcdcOrders([]));

                    }

                    hideActionLoader();
                }
                else {
                    hideActionLoader()
                    let message = "Some thing went wrong please contact the IT Help Desk for assistance"
                    console.log(resultResponse.msg)
                    setErrorMsg(message);
                    openErrorPopup()
                }


            });
        }, isAfterShip ? 6000 : 2500);


    }
    const getColumns = () => {
        const columns = [

            {
                name: 'Actions',
                cell: (row: any) => WebGridButton(row),
                ignoreRowClick: true,
                allowOverflow: true,
                button: true,
                minWidth: "200px",
                width: "200px"
            },
            {
                name: 'Shipment #',
                selector: (row: any) => row._source.Shipment.ShipmentNo,
                sortable: true,
                minWidth: "135px"

            },
            {
                name: 'Status',
                selector: (row: any) => "Ship Back to KCDC",
                sortable: true,
                minWidth: "145px"

            },
            {
                name: 'Qty',
                selector: (row: any) => row._source.Shipment.qtyCountCalculated,
                sortable: true,
                minWidth: "160px",
                width: "150px"

            },
            {
                id: "Store",
                name: 'Store',
                selector: (row: any) => row._source.Shipment.ShipNode,
                sortable: true,
                minWidth: "85px",
                width: "85px",
                omit: props.store?.length <= 1
            },
            {
                name: 'Tracking #',
                selector: (row: any) => row._source.Shipment.trackingNo,
                sortable: true,
                minWidth: "160px"

            }

        ];
        return columns;
    }

    const WebGridButton = (row: any) => {
        return <React.Fragment>
            <Button startIcon={<ReceiptLongIcon className='fTabIcons' />} variant="contained" color="primary" onClick={() => { handleChangeOrder(row) }}>Details</Button>
        </React.Fragment >
    }

    const closeErrorPopup = () => {
        setOpenError(false)
    }
    const openErrorPopup = () => {
        setOpenError(true)
    }
    let errorPopupButtons: IButton[] = [
        {
            text: "Close",
            icon: <CloseIcon />,
            action: closeErrorPopup,
            color: Constants.Colors.red,
        },
    ];
    let Error: INotificationPopupSettings = {
        open: openError,
        type: Constants.NotificationPopupType.Error,
        title: "Error",
        msg: errorMsg,
        draggable: false,
        handleClose: closeErrorPopup,
        actions: errorPopupButtons
    }

    React.useEffect(() => {
        getData();
        return () => {
        }
    }, [props.store]);
    const renderWebDataTable = () => {
        return <div className='dataTable'>
            <DataTable
                className='dataTable'
                title=""
                data={paginated}
                columns={getColumns()}
            />
        </div>
    }

    const renderMobileFilters = () => {
        return <Box sx={{ p: 3 }} style={{ textAlign: "left", padding: "5px", paddingLeft: "5px", paddingTop: "5px" }}>
            {searchBar()}
        </Box>
    }

    const renderMobileData = () => {
        let storeId = IdentityManager.getStoreId(props.store);
        if (paginated && paginated.length == 0) {
            return <div style={{ marginTop: 10 }}>
                <Typography variant='body2'>No Data to display</Typography>
                {!storeId ? <Typography variant='h5'>Please select a Store #</Typography> : ""}
            </div>
        }
        else {
            return <div style={{ marginTop: 10 }}>
                {paginated.map((d: any) => {

                    return (
                        <Card style={{ marginTop: 4, marginBottom: 4 }}>
                            <CardContent style={{ paddingTop: 0, paddingBottom: 0, paddingLeft: 0, paddingRight: 0 }} onClick={() => { setOpenDetailDrawer(true) }}>
                                <Grid container style={{ margin: "0px", minHeight: "50px" }} >
                                    <Grid xs={4} sm={4} style={{ paddingTop: 4, paddingLeft: 0, color: Constants.Colors.red, background: Constants.Colors.smokeGrey, display: "flex", alignItems: "center", borderRight: "2px solid #C0C0C0" }}>
                                        <Typography style={{ fontSize: "11px", wordBreak: "break-all", fontWeight: "425", marginLeft: 3 }}>
                                            shp # {d._source.Shipment.ShipmentNo}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} sm={6} style={{ paddingTop: 3, paddingLeft: 3, textAlign: "left", verticalAlign: "center" }}>
                                        <Typography noWrap style={{ color: Constants.Colors.red, fontSize: "12px", fontWeight: "425" }}>{Utils.getContainerTrackingNumber(d)}</Typography>
                                        <Typography style={{ fontSize: "11px" }}>
                                            <span className="fulfillmentMobileLable">Qty:{Utils.getCount(d)}</span>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2} sm={2} onClick={() => { handleChangeOrder(d) }} style={{ display: "flex", justifyContent: "left", padding: 0, alignItems: "center", marginTop: 0, background: Constants.Colors.red, color: Constants.Colors.white, borderLeft: "2px solid #D93A2F" }}>
                                        {<ReceiptLongIcon style={{ fontSize: 17 }} />}
                                        <Typography style={{ fontSize: "12px" }}>
                                            Details
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    );
                })



                }
            </div >
        }

    }
    const renderDetailDrawer = () => {
        return (
            <Card sx={{ mt: 2 }}>
                <CardContent style={{ padding: "5px" }}>
                    <Typography variant="h6" component="div">
                        Shipment # {"552019292"}
                    </Typography>
                </CardContent>
                <Card sx={{ mt: 1 }} className="orderDetailMobileContainer">
                    <CardContent style={{ padding: 8 }}>
                        <Stack direction={"row"}>
                            <TextField
                                id="order"
                                label="Return Order #"
                                defaultValue={"GC9999679"}
                                InputProps={{
                                    readOnly: true,
                                }}
                                variant="standard"
                                style={{ margin: "4px" }}
                            />
                            <TextField
                                id="outlined-read-only-input"
                                label="VPO Reference #"
                                defaultValue={"VPOGC27062756"}
                                InputProps={{
                                    readOnly: true,
                                }}
                                variant="standard"
                                style={{ margin: "4px" }}
                            />
                        </Stack>
                        <Stack direction={"row"}>
                        </Stack>
                        <TextField
                            id="outlined-read-only-input"
                            label="Item Description"
                            defaultValue={"Musician's Gear GCQAR1 Deluxe Electric Guitar Case 090418 update Black"}
                            InputProps={{
                                readOnly: true,
                            }}
                            variant="standard"
                            multiline
                            style={{ margin: "4px", width: "100%" }}
                        />
                    </CardContent>
                </Card>
            </Card>
        );
    }
    const changeSearchTracking = (e: any) => {
        setSearchTracking(e.target.value)
    }

    const renderPaginationAndSearch = () => {
        if (!Utils.isMobile) {
            return <React.Fragment >
                {pagination()}
            </React.Fragment>
        }
        else {
            return <React.Fragment >
            </React.Fragment>
        }
    }
    const pagination = () => {
        return < div id='paginationWebId' className="paginationReceiveOrdersWeb" style={{ background: "#D93A2F" }}>
            <div style={{ marginLeft: "auto" }}>
                <Stack spacing={1} style={{ paddingTop: 7 }}>
                    <TablePagination
                        component="div"
                        count={orderRows.length}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}

                    />
                </Stack>
            </div>
            <div style={{ marginLeft: "auto" }}>
                {searchBar()}
            </div>
        </div >
    }
    const searchBar = () => {
        return <Paper style={{ width: "auto", marginRight: Utils.isMobile ? 0 : 10 }} className={!Utils.isMobile ? "searchWeb" : ""}
            sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}
        >
            <InputBase
                value={searchTracking}
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search Shipment #"
                inputProps={{ 'aria-label': 'search google maps' }}
                style={{ marginLeft: 10 }}
                onChange={(e) => changeSearchTracking(e)}
            />
            <IconButton style={{ background: "#d6d3d3" }} type="submit" sx={{ p: '10px' }} aria-label="search">
                <SearchIcon />
            </IconButton>
        </Paper>
    }

    return (<div>

        <NotificationPopup {...Error}></NotificationPopup>

        {!Utils.isMobile ? <SiteBreadCrumbs siteBreadCrumbs={Constants.SiteBreadCrumbs.KCDCReturns}></SiteBreadCrumbs> : <></>}
        <div style={{ marginTop: 0 }} className={Utils.isMobile ? "innerContianerMobile" : "innerContianer"}>

            {!Utils.isMobile ? <div></div> : renderMobileFilters()}
            {renderPaginationAndSearch()}
            {!Utils.isMobile ? renderWebDataTable() : renderMobileData()}

        </div>
        <Drawer
            anchor={"bottom"}
            open={openDetailDrawer}
            onClose={() => { }}
            onClick={() => { setOpenDetailDrawer(false) }}
            style={{ zIndex: 9999999 }}
        >
            {(renderDetailDrawer())}
        </Drawer>
    </div >
    );

}
export default KCDCReturns;
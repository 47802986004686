import React from 'react';
import './NotificationPopup.scss';
import { Button, Dialog, DialogContent, DialogContentText, DialogActions, Slide, DialogTitle, Typography } from '@material-ui/core';
import { TransitionProps } from '@mui/material/transitions';
import { Grid, Paper } from '@mui/material';
import { DangerousIcon, InfoIcon, WarningIcon, CheckCircleIcon, CloseIcon } from "../../Subcomponent/Shared/SharedIcons";
import Draggable from "react-draggable";
import IconButton from '@mui/material/IconButton';
import { useDispatch, useSelector } from 'react-redux';
import { INotificationPopupSettings, IButton } from '../../Models/INotificationPopupSettings';
import Utils from '../../Common/Utils';
const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});
function colorDecision(type: string) {
    switch (type) {
        case "error":
            return "#D93A2F";
        case "info":
            return "#2196F3";
        case "warning":
            return "#ff9800";
        case "success":
            return "#4CAF50";
        case "conformation":
            return "#2196F3";
        case "cancelwithreason":
            return "#ff9800";
    }
}
export const NotificationPopup: React.FC<INotificationPopupSettings> = (settings) => {
    const fontSize = Utils.isMobile ? 30 : 35

    const iconColor = "white"
    const color = colorDecision(settings.type.toLowerCase())
    const isError = settings.type.toLowerCase() === 'error';
    const isInfo = settings.type.toLowerCase() === 'info';
    const isWarning = settings.type.toLowerCase() === 'warning';
    const isSuccess = settings.type.toLowerCase() === 'success';
    const isConformation = settings.type.toLowerCase() === 'conformation';
    const iscancelWithReason = settings.type.toLowerCase() === 'cancelwithreason';
    let isExternal = useSelector((state: any) => state.GenericNotificationReducersState.isExternal);
    const PaperComponent = (props: any) => {
        return (
            <Draggable
                handle="#draggable-dialog-title"
                cancel={'[class*="MuiDialogContent-root"]'}
            >
                <Paper {...props} />
            </Draggable>
        );
    }
    const close = () => {
        settings.handleClose()
    }
    const renderButton = (button: IButton, index: number) => {
        return <Button key={index} style={{ whiteSpace: 'nowrap', backgroundColor: button.color ? button.color : "#D93A2F", color: "white" }} onClick={() => { if (button.action) button.action() }} variant="contained" startIcon={button.icon}>
            {button.text ? button.text : "Proceed"}
        </Button>
    }
    return (
        <Dialog disableEnforceFocus fullWidth={settings.fullWidth ? settings.fullWidth : false} maxWidth={settings.maxWidth? "md": "sm"} open={settings.open} onClose={settings.enableBackDropClick ? () => { settings.handleClose() } : () => { }} TransitionComponent={settings.draggable ? undefined : Transition} PaperComponent={settings.draggable ? PaperComponent : undefined}>
            <DialogTitle id="draggable-dialog-title" style={{ cursor: settings.draggable ? "move" : "", padding: '5px 15px', background: color }}>
                <Grid container direction="row" alignItems="center" style={{ height: 35 }}>
                    <Grid item>
                        {isError && <DangerousIcon style={{ color: iconColor, fontSize: fontSize }} />}
                        {isInfo && <InfoIcon style={{ color: iconColor, fontSize: fontSize }} />}
                        {isWarning && <WarningIcon style={{ color: iconColor, fontSize: fontSize }} />}
                        {isSuccess && <CheckCircleIcon style={{ color: iconColor, fontSize: fontSize }} />}
                        {isConformation && <InfoIcon style={{ color: iconColor, fontSize: fontSize }} />}
                        {iscancelWithReason && <WarningIcon style={{ color: iconColor, fontSize: fontSize }} />}
                    </Grid>
                    <Grid item style={{ paddingBottom: 7, paddingLeft: 7 }}>
                        <p style={{ color: iconColor, fontSize: Utils.isMobile ? 16 : 22, margin: 0 }}>{settings.title}</p>
                    </Grid>
                </Grid>
                {
                    isExternal == false ?
                        < IconButton
                            aria-label="close"
                            onClick={close}
                            sx={{
                                position: 'absolute',
                                right: 0,
                                top: 0,
                                color: "white",
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                        :
                        <></>
                }
            </DialogTitle>
            {
                settings.isCustomJSX ?
                    <DialogContent className={`itemDialogBorder dialogMinHeight ${settings.fullWidth ? "" : "dialogWidth"}`} style={{ textAlign: "center", height: settings.height ? settings.height : "", width: settings.width ? settings.width : "" }}>
                        <Typography style={{ fontSize: 18 }}>{settings.msg}</Typography>
                        {settings.customJSX}
                    </DialogContent>
                    :
                    <DialogContent className={`itemDialogBorder dialogMinHeight ${settings.fullWidth ? "" : "dialogMinWidth"}`} style={{ display: "flex", justifyContent: "center" }}>
                        <Typography style={{ fontSize: 16, alignSelf: "center" }}>{settings.msg}</Typography>
                    </DialogContent>
            }
            {
                settings.actions.length > 0 ?
                    <DialogActions>
                        {settings.actions?.map((button, index) => {
                            return renderButton(button, index)
                        })
                        }
                    </DialogActions>
                    :
                    ""
            }

        </Dialog >
    );
}
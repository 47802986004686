import React, { useState } from "react";
import "./Reasons.scss";
import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  TextField,
} from "@mui/material";
import Utils from "../../Common/Utils";
import { useSelector } from "react-redux";
import { CheckBoxIcon, CheckBoxOutlineBlankIcon } from "./SharedIcons";
export const DistrictListDropDown = (props: any) => { 

  const districtsList = useSelector((state: any) => state.ActionLoaderReducersState.districts); 
  const isEnterprise = useSelector((state: any) => state.ActionLoaderReducersState.isEnterprise);
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  interface dropDownOptions {id:string,name:string,regionId:string}
  return (
    <Autocomplete
      id="district-dropdown"
      className="selectStore"
      value={props.district}
      multiple
      limitTags={1}

      style={{ marginTop: 10, borderRadius: 4, width: "100%"}}
      getOptionLabel={(option) => option.name}
      onChange={(e, value) => { props.changeDistrict(e, value) }}
      disabled={isEnterprise}
      disableCloseOnSelect
      options={districtsList}
      isOptionEqualToValue={(option: dropDownOptions, value: dropDownOptions) => option.id === value.id}
      autoHighlight

      renderOption={(props, option: any, { selected }) => (
        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
            <li style={{fontSize:"small"}}>
                <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    //style={{ marginRight: 8 }}
                    checked={selected}
                />
                {option.name}
            </li>
        </Box>
      )}
      renderTags={(value:any, getTagProps) => {
        const numTags = value.length;
        const limitTags = 1;

        return (
          <>
            {value.slice(0, limitTags).map((option:any, index:any) => (
              <Chip
                {...getTagProps({ index })}
                size="small"
                key={index}
                label={option.name}
              />
            ))}

            {numTags > limitTags && ` +${numTags - limitTags}`}
          </>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={props.district.length==0 ? "Choose District":""}
          style={{ background: "white", borderRadius: 4 }}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'off'
          }}
        />
      )}
    />
  );
};

import React from "react";
import { Grid, InputAdornment, Paper, TextField, Tooltip, Typography } from "@mui/material";
import "./AddBox.scss";
import IBoxCharacteristic from "../../Models/IBoxCharacteristic";
import { BoxCharacteristicts } from './BoxCharacteristicts';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../Store/Actions/AllActions';
import Constants from "../../Common/Constants";
export const BoxCharacteristics: React.FC<any> = (props) => {
    const dispatch = useDispatch();
    let boxCharacteristicList = useSelector((state: any) => state.PackOrderReducersState.boxCharacteristicList);
    const [boxesCount, setBoxesCount] = React.useState(0);
    const addBox = (isInitial?: Boolean) => {
        let boxCharacteristic = {
            index: 0,
            boxType: "0",
            quantity: 1,
            dimensions: "",
            weight: "",
            Error: {
                quantity: false,
                dimensions: false,
                weight: false,
                length: false,
                width: false,
                height: false,
            },
            length: "",
            width: "",
            height: ""
        }
        if (isInitial) {
            dispatch(allActions.packOrderActions.addBoxToList([boxCharacteristic]))
            setBoxesCount(boxesCount + 1)
        }
        else {
            let addBoxCharacteristic: IBoxCharacteristic = boxCharacteristic;
            addBoxCharacteristic.index = boxesCount
            boxCharacteristicList.push(addBoxCharacteristic)
            dispatch(allActions.packOrderActions.addBoxToList(boxCharacteristicList));
            setBoxesCount(boxesCount + 1)
        }

    }
    const removeBox = (index: any) => {
        dispatch(allActions.packOrderActions.removeBoxFromList(index));
    }
    React.useEffect(() => {
        addBox(true)
        return () => {
            dispatch(allActions.packOrderActions.removeAllBoxFromList());
        }
    }, []);
    return (
        <div>
            {
                boxCharacteristicList.map((key: any, index: any) => {
                    return (
                        <div key={key.index} data-attribute={key.index} className="orderCharaterBoxDiv">
                            <BoxCharacteristicts index={key.index} addBox={addBox} removeBox={removeBox} isBundle={props.isBundle} disableMultiBox={props.disableMultiBox}></BoxCharacteristicts>
                        </div>
                    );
                })
            }
        </div>
    );
};

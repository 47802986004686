import React, { useEffect, useState } from 'react';
import { Button, Card, CardContent, Divider, Drawer, Grid, Paper, TextField, Toolbar, InputAdornment } from '@mui/material';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Typography from '@mui/material/Typography';
import './VerticalLinearStepper.scss';
import { IVerticalStepper } from '../../Models/IVerticalStepper';
import { NotificationPopup } from '../../Subcomponent/Shared/NotificationPopup'
import { INotificationPopupSettings, IButton } from '../../Models/INotificationPopupSettings';
import { CustomerResponse } from '../Shared/CustomerResponse'
import { ResourceData } from '../Shared/ResourceData'
import Constants from '../../Common/Constants'
import Tooltip from '@mui/material/Tooltip';
import { IconButton } from '@mui/material';
import {
  MarkEmailUnreadIcon, MessageIcon, MarkEmailReadIcon, DirectionsCar, LocalShippingIcon, ShoppingCart, ApartmentIcon,
  DoneIcon, CloseIcon, SendIcon, AddCommentIcon, ArrowBackIcon, DocumentScannerIcon, CommentIcon,
  HighlightOffIcon, PersonIcon, AddLocationAltIcon, KeyboardReturnIcon, EmailIcon, LocalPhoneIcon, CheckIcon, PlaylistAddCheck,
  ReceiptLongIcon, PlaylistAddIcon, EscalatorIcon, CancelIcon, ForwardToInboxIcon
} from "../Shared/SharedIcons"
import Utils from '../../Common/Utils';
import LabelText from '../../Common/LabelText';
import { Note } from '../Shared/Note';
import OrderHistory from '../Shared/OrderHistory';
import ContactCustomerManager from '../../Managers/ContactCustomer.Manager';
import { useDispatch } from 'react-redux';
import allActions from '../../Store/Actions/AllActions';
var moment = require('moment');
export const VerticalLinearStepper: React.FC<IVerticalStepper> = (setting) => {
  const [detail, setDetail] = useState(false);
  const [customerResponse, setCustomerResponse] = useState(false);
  const [title, setTitle] = useState("");
  const [msg, setMsg] = useState("");
  const [contactCustomerData, setContactCustomerData] = useState({} as any);
  const [resourceData, setResourceData] = useState({} as any);
  const [showResourcePopup, setShowResourcePopup] = useState(false);
  const dispatch = useDispatch();

  const openDetail = () => {
    setDetail(true)
  }
  const closeDetail = () => {
    setDetail(false)
  }
  const closeCustomerResponse = () => {
    setCustomerResponse(false)
  }
  const openCustomerResponse = () => {
    setCustomerResponse(true)
  }
  const closeResourcePopup = () => {
    setShowResourcePopup(false)
  }
  const openResourcePopup = () => {
    setShowResourcePopup(true)
  }

  const clickCustomerResponseIcon = async (e: any, data: any) => {
    console.log(data)
    e.stopPropagation()
    setContactCustomerData(data)
    openCustomerResponse()
  }

  const showCursor = (step: any) => {
    if ((step.description || step.label === Constants.OrderHistoryTypes.Customer_Contacted) ||
      (step.label === Constants.OrderHistoryTypes.Resource_Item &&
        step.data?.hasOwnProperty("resourceShipmentLineData"))) {
      return "pointer";
    }
    return "default";
  }

  const clickLable = async (e: any, step: any) => {
    e.stopPropagation()
    if (step.description && step.description != "") {
      setTitle(step.label);
      setMsg(step.description)
      openDetail()
    }
    if (step.label == Constants.OrderHistoryTypes.Customer_Contacted) {

      setContactCustomerData(step)
      openCustomerResponse()
    }
    else if (step.label == Constants.OrderHistoryTypes.Resource_Item && step.data?.hasOwnProperty("resourceShipmentLineData")) {
      setResourceData(step.data);
      openResourcePopup();
    }
  }

  let detailPopupButtons: IButton[] = [
    {
      text: "Close",
      icon: <CloseIcon />,
      action: closeDetail,
      color: Constants.Colors.blue,
    }]
  let detailPopup: INotificationPopupSettings = {
    open: detail,
    type: Constants.NotificationPopupType.Info,
    title: title,
    msg: msg,
    handleClose: closeDetail,
    isCustomJSX: false,
    actions: detailPopupButtons
  }

  let customerResponseButtons: IButton[] = [
    {
      text: "Close",
      icon: <CloseIcon />,
      action: closeCustomerResponse,
      color: Constants.Colors.blue,
    }]
  let showCustomerResponse: INotificationPopupSettings = {
    open: customerResponse,
    type: Constants.NotificationPopupType.Info,
    title: "Customer Response",
    msg: "",
    handleClose: closeCustomerResponse,
    isCustomJSX: true,
    customJSX: <CustomerResponse {...contactCustomerData}></CustomerResponse>,
    actions: customerResponseButtons,
    fullWidth: true,
    maxWidth: "lg"
  }


  let resourcePopupButtons: IButton[] = [
    {
      text: "Close",
      icon: <CloseIcon />,
      action: closeResourcePopup,
      color: Constants.Colors.blue,
    }]

  let resourceDataPopup: INotificationPopupSettings = {
    open: showResourcePopup,
    type: Constants.NotificationPopupType.Info,
    title: "Resourced",
    msg: "",
    handleClose: closeResourcePopup,
    isCustomJSX: true,
    customJSX: <ResourceData {...resourceData}></ResourceData>,
    actions: resourcePopupButtons,
    fullWidth: true,
    maxWidth: "lg"
  }

  const activeIndex = Utils.getStepperIndex(setting, setting.activeStep);
  const iconDecision = (type: string, active: boolean) => {
    switch (type) {
      case Constants.OrderHistoryTypes.Order_Release:
        return (
          <PlaylistAddCheck style={{ color: active ? Constants.Colors.red : '' }} />
        );
      case Constants.OrderHistoryTypes.Order_Queue:
        return (
          <PlaylistAddIcon style={{ color: active ? Constants.Colors.red : '' }} />
        );
      case Constants.OrderHistoryTypes.Picking:
        return (
          <ShoppingCart style={{ color: active ? Constants.Colors.red : '' }} />
        );
      case Constants.OrderHistoryTypes.Packing:
        return (
          <ApartmentIcon style={{ color: active ? Constants.Colors.red : '' }} />
        );
      case Constants.OrderHistoryTypes.Shipped:
        return (
          <LocalShippingIcon style={{ color: active ? Constants.Colors.red : '' }} />
        );
      case Constants.OrderHistoryTypes.Customer_Pickup:
        return (
          <DirectionsCar style={{ color: active ? Constants.Colors.red : '' }} />
        );
      case Constants.OrderHistoryTypes.Customer_Contacted:
        return (
          <MarkEmailUnreadIcon style={{ color: Constants.Colors.red }} />
        );
      case Constants.OrderHistoryTypes.Note:
        return (
          <MessageIcon style={{ color: Constants.Colors.red }} />
        );
      case Constants.OrderHistoryTypes.Pending_Receive:
        return (
          <ReceiptLongIcon style={{ color: active ? Constants.Colors.red : '' }} />
        );
      case Constants.OrderHistoryTypes.Resource_Item:
        return (
          <EscalatorIcon style={{ color: active ? Constants.Colors.red : '' }} />
        );
      case Constants.OrderHistoryTypes.Cancel_Order:
        return (
          <CancelIcon style={{ color: active ? Constants.Colors.red : '' }} />
        );
    }
  }

  const getDate = (dateTime: any) => {
    if (dateTime && dateTime != "") {
      //
      return moment(dateTime).format(Constants.DateFormat.Format);
    }
    else {
      return ""
    }

  }

  const renderTransactionType = (text: any, store: any, resourceStore?: any, resourceShipmentNode?: any) => {
    // Order Release
    if (text == Constants.OrderHistoryTypes.Order_Release) {
      text = "Order Created"
    }
    if (text == Constants.OrderHistoryTypes.Order_Queue) {
      if (store != "") {
        text = "Order Released to Store " + store
      }
      else {
        text = "Order Release"
      }
    }
    if (text == Constants.OrderHistoryTypes.Resource_Item && resourceStore && resourceShipmentNode) {
      text = `Resourced from Store ${resourceStore} to ${resourceShipmentNode}`
    }
    return text;
  }
  const getContactResponse = (step: any) => {
    return <>
      {
        step.map((item: any) => {
          return <StepContent style={{ color: Constants.Colors.red }}>
            <React.Fragment>
              <div style={{ display: "flex" }}>
                {
                  item.isResponse ?
                    <MarkEmailReadIcon style={{ cursor: "pointer" }} onClick={(e) => clickCustomerResponseIcon(e, item.data)} />
                    :
                    <ForwardToInboxIcon style={{ cursor: "pointer" }} onClick={(e) => clickCustomerResponseIcon(e, item.data)} />
                }

                <Typography variant="caption" style={{ marginTop: 5, marginLeft: 3 }}>
                  {
                    item.label
                  }
                </Typography>
              </div>
              <Typography variant="caption" style={{ color: Constants.Colors.grey }}>
                {getDate(item.date)}
              </Typography>
            </React.Fragment>
          </StepContent>
        })
      }
    </>
  }

  const getSortedData = (step: any) => {
    let sortedData = []
    if (step?.data?.responded == true || step?.data?.responded == "true") {
      let contactCustomerResponseObj = {
        data: step.data,
        dateJson: moment(step?.data?.customerresponsedate).valueOf(),
        date: step?.data?.customerresponsedate,
        isResponse: true,
        label: "Customer Responded"
      }
      sortedData.push(contactCustomerResponseObj)
    }
    if (step?.historyFollowupsSteps && step?.historyFollowupsSteps.length > 0) {
      step?.historyFollowupsSteps.forEach((element: any) => {
        let followupObj = {
          data: element.data,
          dateJson: moment(element?.data?.transactionDate).valueOf(),
          date: element?.data?.transactionDate,
          isResponse: false,
          label: "Follow up"
        }
        sortedData.push(followupObj)
        if (element?.data?.responded == true || element?.data?.responded == "true") {
          let contactCustomerResponseObj = {
            data: element.data,
            dateJson: moment(element?.data?.customerresponsedate).valueOf(),
            date: element?.data?.customerresponsedate,
            isResponse: true,
            label: "Customer Responded (Follow up)"
          }
          sortedData.push(contactCustomerResponseObj)
        }
      });
    }
    sortedData.sort((a: any, b: any) => { return a.dateJson - b.dateJson })
    return sortedData
  }
  const showNestedData = (step: any, active: boolean) => {
    // This is for Normal Cases for Customer Responded etc.
    if (step.label == Constants.OrderHistoryTypes.Customer_Contacted) {
      let sortedData = getSortedData(step)
      return <>
        {getContactResponse(sortedData)}
      </>
    }
    else if (step.data?.resourceShipmentLineData && step.label == Constants.OrderHistoryTypes.Resource_Item) {
      let resourceData = step.data?.resourceShipmentLineData;
      return <StepContent style={{ color: active ? Constants.Colors.red : "" }}>
        {
          <React.Fragment>
            <div>
              <Typography variant="caption" style={{ marginLeft: 3, marginRight: 10 }}>Item ID: {resourceData.itemID}</Typography>
            </div>
            <div>
              <Typography variant="caption" style={{ marginLeft: 3, marginRight: 10 }}>SKU #: {resourceData.skuID}</Typography>
            </div>
          </React.Fragment>
        }

      </StepContent>
    }
    else {
      // This is the case of merged data
      if (step?.mergedData && step?.mergedData.length > 0) {

        return <StepContent style={{ color: active ? Constants.Colors.red : "" }}>
          {
            step?.mergedData.map((item: any) => {
              return (
                <div>
                  <div style={{ display: "flex" }}>
                    <div style={{ display: "flex" }}>
                      <Typography variant="caption" style={{ marginLeft: 3, marginRight: 10 }}>Partial {renderTransactionType(item._source ? item._source.transactionType : item.transactionType, item.shipNode ? item.shipNode : "")}</Typography>
                    </div>
                    <Typography variant="caption">
                      {getDate(item._source ? item._source.transactionDate : item.transactionDate)}
                    </Typography>
                  </div>
                  <div>
                    <div style={{ display: "flex" }}>
                      <Typography variant="caption" style={{ marginLeft: 3, marginRight: 10 }}>Fulfillment Location: {item.orderData ? item.orderData?._source?.Shipment?.ShipNode : ""}</Typography>
                      {item.orderData?._source?.Shipment?.ExtnPickupStoreID && <Typography variant="caption">Pickup Store: {item.orderData._source.Shipment.ExtnPickupStoreID}</Typography>}
                    </div>
                  </div>
                </div>
              )

            })



          }

        </StepContent >

      }

    }



    return "";

  }

  return (

    <div>
      <NotificationPopup {...detailPopup}>
      </NotificationPopup>
      <NotificationPopup {...showCustomerResponse}>
      </NotificationPopup>
      <NotificationPopup {...resourceDataPopup}>
      </NotificationPopup>
      <Stepper activeStep={activeIndex} orientation='vertical'>
        {setting.stepsOptions.map((step, index) => {
          console.log(step)
          let active = index <= activeIndex ? true : false
          const labelProps: any = {};
          labelProps.optional = (
            <Typography variant="caption" >
              {getDate(step.time)}
            </Typography>
          );
          labelProps.icon = (iconDecision(step.label, active))
          return (
            <Step key={step.label} active={true}>
              <StepLabel style={{ cursor: showCursor(step) }}
                onClick={(e: any) => clickLable(e, step)}  {...labelProps}>{renderTransactionType(step.label, step.data?.shipNode ? step.data.shipNode : "", step.data?.store, step.data?.resourceShipmentNode)}</StepLabel>
              {showNestedData(step, active)}
            </Step>
          );
        })}
      </Stepper>
    </div >


  );
}




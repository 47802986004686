import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from "@mui/material";
import { OmsPickOrderDetail } from "../../Models/OmsPickOrderDetail";
import OMSManager from "../../Managers/Oms.manager";

import xml from "xml-js"
import { Bars } from "react-loader-spinner";
import Utils from "../../Common/Utils";

const POSInventory = (props: any) => {
    const { data, main, custom } = props

    const [posInventoryResponse, setPosInventoryResponse] = useState();

    const [uniqItemCheck, setUniqItemCheck] = useState(false);

    const [inputValue, setInputValue] = React.useState('');

    const [isUnqLoader, setIsUnqLoader] = React.useState(false);

    const [posInvenLoader, setPosInvenLoader] = React.useState(false);

    const [showError, setShowError] = React.useState(false);

    const [showCheckAvailablityDialog, setShowCheckAvailablityDialog] = React.useState(false);

    let orderDetail: OmsPickOrderDetail = useSelector((state: any) => state.PickOrdersReducersState.orderDetail);

    // const handleInputChange = (event: any) => {
    //     const value = event.target.value;
    //     setInputValue(value);
    // };

    const getPosInventroyResponse = (value: any) => {

        setPosInvenLoader(true)
        const jsonToXml = {
            XMLDocIn: {
                Products: {
                    Product: {
                        LineNumber: 1,
                        POSLocation: orderDetail?.ShipNode?.ShipNode,
                        "Inv.input": value ? value : data?.OrderLine?.ItemDetails?.Extn?.ExtnPOSItemID
                    },

                }
            }
        }
        const options = { compact: true, ignoreComment: true, spaces: 0 };
        const xmlString = xml.js2xml(jsonToXml, options);
        const replceXmlns = xmlString.replace("<Products>", "<Products xmlns=\"\">")
        if (replceXmlns) {
            OMSManager.OMSGetPosInventory({ payload: replceXmlns }).then((res: any) => {
                console.log("===>>" , res);

                setIsUnqLoader(false)
                setPosInvenLoader(false)

                if (res.success) {
                    setPosInventoryResponse(res.data.qty)
                    setShowError(false)
                }
                if (!res.success) {
                    setPosInventoryResponse(res.msg)
                    setShowError(true)
                }

            }).catch((error) => {
                console.log("custom", error);

            })

        }
    }

    // const handleButtonClick = () => {
    //     if (data.OrderLine?.ItemDetails?.Extn?.ExtnPOSItemID) {
    //         const itemID = data.OrderLine.ItemDetails.Extn.ExtnPOSItemID
    //         setInputValue(itemID);
    //     }
    //     setShowCheckAvailablityDialog(true)
    // };

    // const handleDialogBtn = () => {
    //     setIsUnqLoader(true)
    //     getPosInventroyResponse(inputValue)

    // }

    // const closeDialogBox = () => {
    //     setShowCheckAvailablityDialog(false)
    // }


    useEffect(() => {

        // let checkForUniq = null;

        // if (data.OrderLine.ItemDetails.Extn.ExtnIsUniqueItem === "Y" && !data.CustomComponents) {
        //     checkForUniq = true
        //     setUniqItemCheck(true)
        // }


        // if (!checkForUniq) {

        // setUniqItemCheck(false)
        if (custom === "custom") {
            console.log("called");
            
            getPosInventroyResponse(null)

        }

        if (main === "main" && !data.CustomComponents) {
            console.log("main");
            getPosInventroyResponse(null)
        }
        // }

    }, [])




    return (
        <>

            {posInvenLoader && <div style={{ display: "inline-block", marginLeft: "5px" }}><Bars color="#D93A2F" height={Utils.isMobile ? 30 : 50} width={Utils.isMobile ? 30 : 50} /></div>}
            {(posInventoryResponse) ?
                <Typography variant="body2" sx={{ fontSize: Utils.isMobile ? 11 : 15 }} gutterBottom>
                    POS Inventory on-hand: <b style={{ color: showError ? "red" : "black" }}>{posInventoryResponse} </b>
                </Typography>
                : <Typography variant="body2" sx={{ fontSize: Utils.isMobile ? 11 : 15 }} gutterBottom>
                    <></>
                </Typography>
            }
            {/* {(showCheckAvailablityDialog) &&
                <div>
                    <Dialog maxWidth={false} open={showCheckAvailablityDialog} onClose={() => { closeDialogBox() }}>
                        <DialogTitle className="itemDialogBorder"><div className="dialogHeader">Check Availablity</div></DialogTitle>
                        <DialogContent className="itemDialogBorder" >

                            <div style={{ marginTop: "10px" }}>
                                <div style={{ display: "flex" }}>
                                    <TextField
                                        label="Please Enter POS ID"
                                        variant="outlined"
                                        value={inputValue}

                                        onChange={handleInputChange}
                                        type="tel"

                                    />
                                    {!isUnqLoader && < Button
                                        style={{ marginLeft: "5px" }}
                                        variant="contained"
                                        color="primary"
                                        onClick={handleDialogBtn}
                                        name="submit-button"
                                        className={inputValue && inputValue.length > 0 ? 'btn' : "btn disabledButton"}
                                        size="small"
                                        disabled={inputValue && inputValue.length > 0 ? false : true}
                                    > Check
                                    </Button>}
                                    {isUnqLoader && <div style={{ marginLeft: "5px" }}><Bars color="#D93A2F" height={Utils.isMobile ? 30 : 50} width={Utils.isMobile ? 30 : 50} /></div>}
                                </div>
                                {posInventoryResponse && <Typography style={{ marginTop: "3px" }} variant="body2" sx={{ fontSize: Utils.isMobile ? 11 : 15 }} gutterBottom>
                                    POS Inventory on-hand: <b style={{ color: showError ? "red" : "black" }}> {posInventoryResponse}</b>
                                </Typography>}
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button size="small" onClick={() => { closeDialogBox() }} variant="contained" className="btn">
                                <span className="dialogButton">Close</span>
                            </Button>
                        </DialogActions>
                    </Dialog>

                </div>

            } */}
        </>
    )
}

export default POSInventory





import { Button, Card, CardContent, Divider, Drawer, Grid, Paper, TextField, Toolbar, Typography, InputAdornment } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton } from '@mui/material';
import Utils from '../Common/Utils';
import { createTheme, makeStyles } from "@material-ui/core/styles";

import ItemAction from '../Subcomponent/PickOrder/ItemAction';
import Constants from '../Common/Constants';
import { INotificationPopupSettings, IButton } from '../Models/INotificationPopupSettings';

import { IContactCustomer } from '../Models/IContactCustomer';
import { ContactCustomer } from '../Subcomponent/Shared/ContactCustomer';
import LabelText from "../Common/LabelText"

import SiteBreadCrumbs from '../Subcomponent/Shared/SiteBreadCrumbs';
import { BallotIcon, MailLockIcon } from '../Subcomponent/Shared/SharedIcons';

import { LockIcon, ShoppingCart, ApartmentIcon, DoneIcon, CloseIcon, SendIcon, AddCommentIcon, ArrowBackIcon, QrCodeScannerIcon, CommentIcon, HighlightOffIcon, PersonIcon, AddLocationAltIcon, KeyboardReturnIcon, EmailIcon, LocalPhoneIcon, CheckIcon } from "../Subcomponent/Shared/SharedIcons"
import ExternalItemAction from '../Subcomponent/ExternalCustomerView/ExternalItemAction';
import { IReasons, IReasonState } from '../Models/IReasons';
import { Reasons } from '../Subcomponent/Shared/Reasons';
import { NotificationPopup } from '../Subcomponent/Shared/NotificationPopup';
import ReactInputVerificationCode from "react-input-verification-code";
import '../Subcomponent/ExternalCustomerView/ExternalCustomerView.scss';
import OMSManager from '../Managers/Oms.manager';
import allActions from '../Store/Actions/AllActions';
import { ICustomerDetail } from '../Models/IPickOrder';
import { OmsPickOrderDetail } from '../Models/OmsPickOrderDetail';
import CancelLine from '../Subcomponent/Shared/CancelLine';
import OrderManager from '../Managers/Orders.Manager';
import logger from '../Common/logger';
import ApiService from '../Common/ApiService';
import ApiURLS from '../Common/ApiURLS';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { CustomerDetails } from '../Subcomponent/Shared/CustomerDetails';
import ShipmentStatusConstants from '../Common/ShipmentStatusConstants';
import { ImageAttachments } from '../Subcomponent/Shared/ImageAttachments';
var CryptoJS = require("crypto-js");
var momenttz = require('moment-timezone');
var moment = require('moment');
const ExternalCustomerView = () => {
    const queryString = window.location.search;

    const urlParams = new URLSearchParams(queryString);
    let rawParam: any = urlParams.get('k');
    let params: any = decodeURIComponent(rawParam);
    const [encrypted, iv, key] = params.split("|");
    var keyev = CryptoJS.enc.Base64.parse(key);
    var ive = CryptoJS.enc.Base64.parse(iv);
    var decrypted = CryptoJS.AES.decrypt(encrypted, keyev, { iv: ive });
    var decrypted_data = decrypted.toString(CryptoJS.enc.Utf8);

    var paramsObject = JSON.parse(decrypted_data);

    const { shipmentkey, guid, code, customeremail } = paramsObject as any;
    //
    const [userCode, setUserCode] = useState("");
    const [codeError, setCodeError] = useState("");
    const [openDrawer, setDrawer] = useState(false);
    const [openHistoryDrawer, setHistoryDrawer] = useState(false);
    const [isScan, setScan] = useState(false);
    const [pickComplete, setPickComplete] = useState(false);
    const [cancel, setCancel] = useState(false);
    const [resource, setResource] = useState(false);
    const [contactCustomer, setContactCustomer] = useState(false);
    const [notes, setNotes] = useState("");
    const [notePopup, setNotesPopup] = useState(false);
    const [blobUrl, setBlobUrl] = useState("")
    const [showPdf, SetShowPdf] = useState(false);
    const [isVerified, setIsVerified] = useState(false);
    const [orderNumber, setOrderNumber] = useState("");
    const [isError, setIsError] = useState(false)
    const [errorMsg, setErrorMsg] = useState("Some thing went Wrong");
    const [openError, setOpenError] = useState(false);
    const [isLoaded, setLoaded] = useState(false);
    const ccd: any = null;
    const [ccData, setCCData] = useState(ccd);
    const followupHistory: any = null;
    const [followupHistoryList, setFollowupHistoryList] = useState(followupHistory);
    const [value, setValue] = useState("");
    const [imageAttachments, setImageAttachments] = useState([]);
    const choicesValues = [
        {
            id: "1",
            val: "Yes, please send me what you've got"
        },
        {
            id: "2",
            val: "I want it new, in box (and I'm willing to wait)"
        },
        {
            id: "3",
            val: "Contact me to discuss"
        },
        {
            id: "4",
            val: "Cancel Order"
        }
    ];

    const [customerMessage, setCustomerMessage] = useState("");
    let customerDetail: ICustomerDetail = useSelector((state: any) => state.PickOrdersReducersState.customerDetail);
    let orderDetail: OmsPickOrderDetail = useSelector((state: any) => state.PickOrdersReducersState.orderDetail);
    // cancel line popup
    let cancelReason: IReasonState = useSelector((state: any) => state.ActionLoaderReducersState.cancelReason);
    const useStyles = makeStyles((theme) => ({
        root: {
            backgroundColor: theme.palette.primary.main
        }
    }));
    const classes = useStyles();
    const theme = createTheme({
        palette: {
            primary: {

                main: '#D93A2F',
            },
            secondary: {
                main: '#FFFFFF',
            },
        },
    })
    console.log(classes.root, "asas");
    const TestState = useSelector((state: any) => state.TestReducerState);
    console.log(TestState, "TestState");
    const dispatch = useDispatch();
    const navigate = useNavigate();




    window.scrollTo(0, 0);
    // Scroll
    var startProductBarPos = -1;
    window.onscroll = function () {
        console.log("scrolled");
        var bar = document.getElementById('bar') as any;
        var mblBar = document.getElementById('mblBar') as any;
        if (bar) {
            if (startProductBarPos < 0) startProductBarPos = findPosY(mblBar);
            if (window.pageYOffset > startProductBarPos) {
                bar.classList.add("scrolledTopFix");
            } else {
                bar.classList.remove("scrolledTopFix");
            }
        }
    };
    function findPosY(obj: any) {
        var curtop = 0;
        if (obj && typeof (obj.offsetParent) != 'undefined' && obj.offsetParent) {
            while (obj.offsetParent) {
                curtop += obj.offsetTop;
                obj = obj.offsetParent;
            }
            curtop += obj.offsetTop;
        }
        else if (obj && obj.y)
            curtop += obj.y;
        return curtop;
    }
    const closeCancel = () => {
        setCancel(false)
    }
    const onScannedComplete = (data: any, isError: any) => {
        console.log(data, "Parentdata");
        setScan(false);
    }
    const closePickComplete = () => {
        setPickComplete(false)
    }
    const closeNotes = () => {
        setNotesPopup(false)
    }
    const openNotes = () => {
        setNotesPopup(true)
        setNotes("")
    }
    const changeNote = (event: any) => {
        setNotes(event.target.value);
    }
    const pickCompleteClick = () => {
        setPickComplete(true)
    }
    const cancelClick = () => {
        setCancel(true)
    }
    const resourceClick = () => {
        setResource(true)
    }
    const contactCustomerClick = () => {
        setContactCustomer(true)
    }
    const openSuccessNotification = (message: any) => {
        dispatch(allActions.genericNotificationActions.showSuccessMessage(message));
    }
    const codeSubmitClick = () => {
        if (code == userCode) {
            setIsVerified(true);
            setCodeError("");
            loadData();
        }
        else {
            setCodeError("Invalid Code");
        }

    }
    const showPickDetailErrorCommon = async (msg: string) => {

        setIsError(true);
        setErrorMsg(msg);
        setOpenError(true);

    }
    const closeErrorPopup = () => {
        setOpenError(false)
    }

    const getPickDetailFromOms = async () => {
        let result = [] as any
        try {
            result = await OMSManager.getPickOrdersDetailNom(shipmentkey)
            if (result.success && result.data.ShipmentLines.ShipmentLine != undefined) {
                setLoaded(true);
                let data = result.data
                setOrderNumber(data.ShipmentLines.ShipmentLine[0].OrderNo);
                let isAnyBulkOrder = false
                data.ShipmentLines.ShipmentLine.forEach((line: any) => {
                    if (line.hasOwnProperty("CustomComponents")) {
                        isAnyBulkOrder = true
                    }
                });
                data.isAnyBulkOrder = isAnyBulkOrder
                dispatch(allActions.pickOrderActions.changeOMSOrderDetails(data));
                //dispatch(allActions.pickOrderActions.changeOrderLines(data.ShipmentLines.ShipmentLine));
                dispatch(allActions.pickOrderActions.changeCommonCodeReasons(data.CommonCodeList.CommonCode));
                dispatch(allActions.pickOrderActions.changeCustomerDetails(OMSManager.getCustomerDetails(data)));
                dispatch(allActions.actionLoaderActions.hideLoader());

            }
            else {
                if (!result.data?.ShipmentLines?.ShipmentLine ||
                    result.data?.ShipmentLines?.Status == ShipmentStatusConstants.Cancelled) {
                    showPickDetailErrorCommon(LabelText.externalCancelOrder);
                }
                else {

                    showPickDetailErrorCommon(LabelText.generalErrorMessage);
                }
                dispatch(allActions.actionLoaderActions.hideLoader());
                setLoaded(false);
            }
        }
        catch {
            dispatch(allActions.actionLoaderActions.hideLoader());
            showPickDetailErrorCommon(LabelText.generalErrorMessage);
            setLoaded(false);
        }
    }

    // Cancel Order

    const cancelOrder = () => {

        dispatch(allActions.actionLoaderActions.setCancelReason(
            {
                ...cancelReason,
                code: "Customer Changed Mind",
                reasonText: "Customer Changed Mind",
                showModal: true,
                cancelItem: null,
                cancelCompleteOrder: true
            }
        ))

    }
    const cancelLineCall = async (payLoad: any) => {
        return await OMSManager.GCPickCancelLineNom(payLoad);
    }

    const logCancelOrderHistory = async () => {
        const payload = {
            shipmentKey: shipmentkey,
            logType: "history",
            cancelAction: "External View",
            transactionType: Constants.OrderHistoryTypes.Cancel_Order,
            transactionDate: moment.utc(moment.tz("America/Los_Angeles")).toISOString()
        }

        await ApiService.nomPost(ApiURLS.NOM.saveOrderHistory.URL, payload);
    }

    const getCancelReasonValues = async (code: any, text: any) => {

        console.log(code, text);
        let cancelReasonObject = { ...cancelReason };

        // If customer has types some message then send it otherwise use the hardcoded returned value
        let payLoad = OMSManager.GetCancelPayload(orderDetail, cancelReasonObject, code, customerMessage && customerMessage.length > 0 ? customerMessage : text);

        dispatch(allActions.actionLoaderActions.showLoader());

        if (cancelReasonObject.cancelCompleteOrder) {
            logger.info({ payLoad: payLoad, message: "", tag: Constants.LOG_TYPES.CANCEL_PICK_ORDER });
        }
        await cancelLineCall(payLoad).then((response: any) => {
            if (payLoad?.Shipment?.Action == "cancelAll" && response.success) {
                logCancelOrderHistory();
            }
        })


        await saveResponseAndSendEmail(value);

        dispatch(allActions.actionLoaderActions.hideLoader());
        dispatch(allActions.actionLoaderActions.setCancelReason(
            Utils.initCanceReasonState()
        ))

        openSuccessNotification("Thank you! Your response has been submitted successfully.You may close your browser now.");
        setLoaded(false);
    }


    const getImageAttachments = (data: any) => {
        let images: any = [];
        data.forEach((history: any) => {
            if (history?._source.hasOwnProperty("imageAttachments")) {
                images.push(...history._source.imageAttachments);
            }
        });
        setImageAttachments(images);
    }
    const getParentContactCustomer = (data: any, parentGuid: any) => {
        let parentItems = []
        let parentCustomerContact = data.filter((x: any) => x._source.guid == parentGuid);
        if (parentCustomerContact.length > 0) {
            parentItems = parentCustomerContact[0]._source.items
        }
        return parentItems
    }
    const loadData = async () => {
        //isExternal
        dispatch(allActions.genericNotificationActions.isExternal());
        dispatch(allActions.actionLoaderActions.showLoader());
        setLoaded(false);
        await ApiService.nomGet(ApiURLS.NOM.getOntactedCustomerDataByguid.URL + "?guid=" + guid).then(async (response) => {
            if (response.success && response.data.length > 0) {
                let singleData = response.data[0];
                getImageAttachments(response.data);
                if (singleData._source && (singleData._source.responded == "true" || singleData._source.responded == true)) {
                    showPickDetailErrorCommon("You have already submitted your response on this request.You may close your browser now.");
                    dispatch(allActions.actionLoaderActions.hideLoader());
                    setLoaded(false);
                }
                else if (singleData._source && singleData?._source?.urlDisabled && (singleData?._source?.urlDisabled == true || singleData?._source?.urlDisabled == "true")) {
                    showPickDetailErrorCommon("This link is no longer valid please refer to latest link sent you in email.");
                    dispatch(allActions.actionLoaderActions.hideLoader());
                    setLoaded(false);
                }
                else {
                    if (singleData?._source?.isFollowup && singleData?._source?.isFollowup == true) {
                        let ccList = await ApiService.nomGet(ApiURLS.NOM.getFollowupDataByGuid.URL + "?guid=" + singleData?._source?.parentContactCustomerGuid + "&order=" + singleData?._source?.orderno)
                        if (ccList.success && ccList.data.length > 0) {
                            singleData._source.items = getParentContactCustomer(ccList.data, singleData?._source?.parentContactCustomerGuid)
                            //ccList = ccList.data.filter((x: any) => x._source.guid != singleData?._source?.guid)
                            ccList = Utils.sortContactCustomerHistory(ccList.data)
                            setFollowupHistoryList(ccList)
                        }
                    }
                    setCCData(singleData);
                    await getPickDetailFromOms();

                }
            }
            else {
                dispatch(allActions.actionLoaderActions.hideLoader());
                showPickDetailErrorCommon(LabelText.generalErrorMessage + ": No Record Found");
                setLoaded(false);
            }
        })
    }

    //

    // Error Popup


    let errorPopupButtons: IButton[] = [
    ];
    let Error: INotificationPopupSettings = {
        open: openError,
        type: Constants.NotificationPopupType.Error,
        title: "Error",
        msg: errorMsg,
        draggable: false,
        handleClose: closeErrorPopup,
        actions: errorPopupButtons
    }

    // Error Popup





    let cancelReasonCodes = ["Customer Changed Mind", "Duplicate Order", "Inventory Error", "NLA Item"];
    let cancelReasons: IReasons = {
        reasonsCodes: cancelReasonCodes
    }
    let cancelPopupButtons: IButton[] = [{
        text: "Yes",
        icon: <DoneIcon />,
        action: closeCancel,
        color: Constants.Colors.yellow,
    },
    {
        text: "No",
        icon: <CloseIcon />,
        action: closeCancel,
        color: Constants.Colors.grey,
    }
    ]
    let cancelPopup: INotificationPopupSettings = {
        open: cancel,
        type: Constants.NotificationPopupType.Warning,
        title: LabelText.NotificationPopupHeaders.Warning,
        msg: LabelText.ExternalOrderView.CancelOrderWarning,
        handleClose: closeCancel,
        isCustomJSX: true,
        customJSX: <Reasons {...cancelReasons}></Reasons>,
        actions: cancelPopupButtons
    }


    const mobileView = () => {
        const height = Utils.displayHeight + 'px';
        return <React.Fragment >
            <div style={{ paddingBottom: "150px" }}>
                {isLoaded ?
                    <Paper style={{ marginTop: 2, paddingTop: 1, paddingBottom: 1 }}>
                        <Card style={{ margin: "6px" }} id='cardOrder'>
                            <CardContent style={{ padding: "10px" }}>
                                <Grid spacing={2} container style={{ margin: "0px" }} sx={{ flexGrow: 1 }}>
                                    <Grid item xs={10} sm={9} style={{ padding: "8px" }}>
                                        <Typography variant="h6" style={{ padding: "0px" }} className='scanColor tealColor'>
                                            Order # {orderNumber}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2} sm={3} style={{ padding: "0px" }}>

                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                        {selectChoiceView()}
                        <React.Fragment>
                            {
                                orderDetail?.ShipmentLines?.ShipmentLine?.map((item: any, index: any) => {
                                    return mobileItemsRender(item)
                                })
                            }

                        </React.Fragment>


                        <Card className='customerDetailsBoxExternal' style={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}>
                            <CardContent style={{ padding: "1px", background: "#F3F3F4" }}>
                                <Button onClick={() => { setDrawer(true) }} className="MobileDrawerButtonsExternalLink tealBackground" variant="outlined">Customer Details</Button>
                                <Button disabled={value == "" ? true : false} onClick={() => { SaveResponse() }} className={value == "" ? "MobileDrawerButtonsExternalLinkDisabled" : "MobileDrawerButtonsExternalLink tealBackground"} variant="outlined">Submit Response</Button>

                            </CardContent>
                        </Card>
                    </Paper> : ""}


            </div>
            <Drawer
                anchor={"bottom"}
                open={openDrawer}
                onClose={() => { }}
                onClick={() => { setDrawer(false) }}
            >
                <Card style={{ margin: "6px" }}>
                    <CustomerDetails customerDetail={customerDetail}></CustomerDetails>
                </Card>
            </Drawer>
        </React.Fragment>
    }


    const mobileItemsRender = (item: any) => {


        if (ccData._source && ccData._source.items.length > 0) {

            let targetShipmentLine = ccData._source.items.filter((a: any) => a.shipmentlinekey == item.ShipmentLineKey);

            if (targetShipmentLine && targetShipmentLine.length > 0) {
                return <React.Fragment>
                    <Card style={{ margin: "6px" }} >
                        <CardContent style={{ padding: "10px" }}>
                            <Grid spacing={2} container style={{ margin: "0px" }} sx={{ flexGrow: 1 }}>
                                <Grid item xs={4} sm={4} style={{ padding: "8px", borderRight: "1px solid #2f2d2d" }}>

                                    <img style={{ height: "100px" }} alt="" src={Utils.convertToHttps(item.ImageUrl)} />
                                </Grid>
                                <Grid item xs={6} sm={6} style={{ padding: "8px", textAlign: "left" }}>
                                    <Typography className='tealColor' style={{ fontSize: "13px", fontWeight: 500 }}>{item.ItemDesc}</Typography>
                                    <Typography style={{ fontSize: "11px" }}>Qty: <b>{item.Quantity}</b></Typography>
                                    <Typography variant="body2" sx={{ fontSize: 11 }} gutterBottom>
                                        Price: <b>${item.UnitPrice}</b>
                                    </Typography>
                                    {targetShipmentLine[0].message && targetShipmentLine[0].message.length > 0}
                                    {
                                        <Typography variant="body2" sx={{ fontSize: 15, color: "red" }} gutterBottom>
                                            Note: <b>{targetShipmentLine[0].message}</b>
                                        </Typography>
                                    }

                                </Grid>
                                <Grid item xs={2} sm={2} style={{ padding: 0 }}>
                                    {/* <ExternalItemAction item={item} ShipmentLineKey={item.ShipmentLineKey} /> */}
                                </Grid>
                            </Grid>
                            <div style={{ padding: "", background: "#ececec", marginTop: "", border: "" }}>
                                {
                                    item.CustomComponents && item.CustomComponents.ShipmentLine.length > 0 ?
                                        item.CustomComponents?.ShipmentLine?.map((iitem: any) => {
                                            return (
                                                <Grid spacing={2} container style={{ margin: "0px" }} sx={{ flexGrow: 1 }}>
                                                    <Grid item xs={4} sm={4} style={{ padding: "8px", borderRight: "1px solid #2f2d2d" }}>

                                                        <img style={{ height: "100px" }} alt="" src={Utils.convertToHttps(iitem.ImageUrl)} />
                                                    </Grid>
                                                    <Grid item xs={6} sm={6} style={{ padding: "8px", textAlign: "left" }}>
                                                        <Typography className='tealColor' style={{ fontSize: "13px", fontWeight: 500 }}>{iitem.ItemDesc}</Typography>
                                                        <Typography style={{ fontSize: "11px" }}>Qty: <b>{iitem.Quantity}</b></Typography>
                                                        <Typography variant="body2" sx={{ fontSize: 11 }} gutterBottom>
                                                            Price: <b>${iitem.UnitPrice}</b>
                                                        </Typography>

                                                    </Grid>
                                                    <Grid item xs={2} sm={2} style={{ padding: 0 }}>

                                                    </Grid>
                                                </Grid>
                                            );
                                        })
                                        :
                                        <div></div>
                                }
                            </div>
                        </CardContent>
                    </Card>
                </React.Fragment>
            }
            else {
                return "";
            }


        }

        return "";




    }

    const webItemsRender = (item: any) => {



        if (ccData._source && ccData._source.items.length > 0) {

            let targetShipmentLine = ccData._source.items.filter((a: any) => a.shipmentlinekey == item.ShipmentLineKey);

            if (targetShipmentLine && targetShipmentLine.length > 0) {
                return <React.Fragment>
                    <Grid container spacing={2} display={"flex"} style={{ marginTop: 0 }}>
                        <Grid item xs={12} sm={12} className='textLeft' style={{ paddingTop: 0 }}>
                            <Grid spacing={2} container style={{ margin: "0px" }} sx={{ flexGrow: 1 }}>
                                <Grid item xs={3} sm={3} style={{ padding: "8px", borderRight: "1px solid #2f2d2d", display: "flex", justifyContent: "center" }}>
                                    <img style={{ height: "100px" }} alt="" src={Utils.convertToHttps(item.ImageUrl)} />
                                </Grid>
                                <Grid item xs={9} sm={9} style={{ padding: "8px", textAlign: "left" }}>
                                    <Typography style={{ fontSize: "15px", fontWeight: 500, color: "rgb(110 109 109)" }}>{item.ItemDesc}</Typography>
                                    <Typography variant="body2" sx={{ fontSize: 15 }} gutterBottom>
                                        Qty: <b> {item.Quantity}</b>
                                    </Typography>
                                    <Typography variant="body2" sx={{ fontSize: 15 }} gutterBottom>
                                        Price: <b>${item.UnitPrice}</b>
                                    </Typography>

                                    {targetShipmentLine[0].message && targetShipmentLine[0].message.length > 0}
                                    {
                                        <Typography variant="body2" sx={{ fontSize: 15, color: "red" }} gutterBottom>
                                            Note: <b>{targetShipmentLine[0].message}</b>
                                        </Typography>
                                    }

                                </Grid>
                            </Grid>
                        </Grid>
                        {/* <Grid item xs={3} sm={3} className='textCenter' style={{ padding: "22px" }}>
                            <Button variant='contained' className='btn submitBtn' onClick={() => {


                                dispatch(allActions.actionLoaderActions.setCancelReason(
                                    {
                                        ...cancelReason,
                                        showModal: true,
                                        cancelItem: item
                                    }
                                ))


                            }} startIcon={<HighlightOffIcon />}>Cancel</Button>
                        </Grid> */}
                    </Grid>

                    <div style={{ padding: "", background: "#ececec", marginTop: "", border: "" }}>

                        {
                            item.CustomComponents && item.CustomComponents.ShipmentLine.length > 0 ?
                                item.CustomComponents?.ShipmentLine?.map((iItem: any) => {
                                    return (

                                        <React.Fragment>
                                            <Grid container spacing={2} display={"flex"} style={{ marginTop: 0 }}>
                                                <Grid item xs={12} sm={12} className='textLeft' style={{ paddingTop: 0 }}>
                                                    <Grid spacing={2} container style={{ margin: "0px" }} sx={{ flexGrow: 1 }}>
                                                        <Grid item xs={3} sm={3} style={{ padding: "8px", borderRight: "1px solid #2f2d2d", display: "flex", justifyContent: "center" }}>
                                                            <img style={{ height: "100px" }} alt="" src={Utils.convertToHttps(iItem.ImageUrl)} />
                                                        </Grid>
                                                        <Grid item xs={9} sm={9} style={{ padding: "8px", textAlign: "left" }}>
                                                            <Typography style={{ fontSize: "15px", fontWeight: 500, color: "rgb(110 109 109)" }}>{iItem.ItemDesc}</Typography>
                                                            <Typography variant="body2" sx={{ fontSize: 15 }} gutterBottom>
                                                                Qty: <b> {iItem.Quantity}</b>
                                                            </Typography>
                                                            <Typography variant="body2" sx={{ fontSize: 15 }} gutterBottom>
                                                                Price: <b>${iItem.UnitPrice}</b>
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </React.Fragment>
                                    );
                                })
                                :
                                <div></div>
                        }
                    </div>
                    <Divider></Divider>
                </React.Fragment>
            }
            else {
                return "";
            }


        }

        return "";





    }
    const getNoteText = (value: string) => {
        // change it to one GetUserIdDoamin by external
        let returnValue = ""
        let addedBy = ""
        if (localStorage.getItem(Constants.UserId)) {
            addedBy = "Added by " + localStorage.getItem(Constants.UserId) + " : ";
        }
        if (customerMessage) {
            returnValue = addedBy + value + "-" + customerMessage;
        }
        else {
            returnValue = addedBy + value
        }
        return returnValue;
    }
    const getNotesPayload = (selectedValue: any) => {
        let payload = {
            Order:
            {
                OrderHeaderKey: orderDetail.OrderHeaderKey,
                Notes: {
                    Note:
                    {
                        AuditTransactionid: Constants.AuditTransactionidTypes.Customer,
                        ContactType: "E-Mail",
                        ContactTime: moment.utc(moment.tz("America/Los_Angeles")).toISOString(),
                        ContactUser: OMSManager.GetUserIdDoamin().user,
                        Priority: "0.00",
                        VisibleToAll: "Y",
                        NoteText: getNoteText(selectedValue)
                    }
                }
            }
        }
        return payload;
    }

    const SaveResponse = async () => {
        if (!value || value.toString().length == 0) {

            showPickDetailErrorCommon("Please select an action from below list to submit your response");

        }
        else {
            console.log(value);
            if (value == "4") {
                cancelOrder();
                // Dont forget to updaet the order for cancel one
            }
            else {

                dispatch(allActions.actionLoaderActions.showLoader());
                await saveResponseAndSendEmail(value);
                dispatch(allActions.actionLoaderActions.hideLoader());
                setLoaded(false);
                openSuccessNotification("Thank you! Your response has been submitted successfully.You may close your browser now.")

            }
        }
    }

    const getShipmentLinesData = () => {
        let shipmentLines = [] as any;
        orderDetail.ShipmentLines.ShipmentLine.forEach(element => {
            let shipmentData = {
                itemDesc: element.ItemDesc,
                itemID: element.OrderLine.ItemDetails.Extn.ExtnPOSItemID,
                skuID: element.ItemID,
                quantity: element.Quantity
            }
            shipmentLines.push(shipmentData);
        });
        return shipmentLines;
    }

    const saveResponseAndSendEmail = async (val: any) => {
        // update the elastic data and then send the email
        //;

        let selectedChoice = choicesValues.filter((a: any) => a.id == val);
        if (selectedChoice && selectedChoice.length > 0) {
            var tDate = moment().tz("America/Los_Angeles");
            let data = {

                responded: true.toString(),
                customerresponse: selectedChoice[0],
                customermessage: customerMessage,
                customerresponsedate: moment.utc(tDate).toISOString()

            }
            // Send the Email
            let customerContactData = { ...ccData._source };
            customerContactData.responseData = data;

            //For Cancel Order
            if (value == "4") {
                customerContactData.shipmentLinesData = getShipmentLinesData();
            }
            if (followupHistoryList != null && followupHistoryList.length > 0) {
                customerContactData.contactCustomerHistory = followupHistoryList
            }

            ApiService.nomPost(ApiURLS.NOM.sendCustomerResponseEmail.URL, customerContactData);
            let payLoad = getNotesPayload(selectedChoice[0].val);
            ApiService.nomPost(ApiURLS.NOM.GCAddNoteOnEmailTrigger.URL, { urlName: "GCAddNoteOnEmailTrigger", payLoad });

            // Update Data in Elastic
            await ApiService.nomPost(ApiURLS.NOM.updateCustomerContacted.URL, {

                id: ccData._id,
                data: data

            })


        }


    }



    const webView = () => {
        return (
            <div>
                {isLoaded ?
                    <div style={{ margin: "5px" }}>
                        <div>

                            <Paper style={{ marginTop: "10px", background: "none" }}>
                                <Grid container spacing={2} display={"flex"} style={{ justifyContent: "center", display: "flex" }}>
                                    <Grid item xs={6} sm={6} className='textLeft'>
                                        <Paper className='paperToBottomPadding' style={{ padding: "10px", marginBottom: "10px" }}>
                                            <Grid container spacing={2} display={"flex"}>
                                                <Grid item xs={6} sm={6} className='textLeft'>
                                                    <Typography variant="h6" style={{ padding: "0px" }} className='scanColor tealColor'>
                                                        Order # {orderNumber}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6} sm={6} className='textRight'>
                                                </Grid>
                                            </Grid>
                                        </Paper>

                                        {selectChoiceView()}
                                        <Paper className='paperToBottomPadding' style={{ padding: "10px", marginBottom: "10px", marginTop: 5 }}>
                                            <Grid container spacing={2} display={"flex"}>
                                                <Grid item xs={12} sm={12} className='textCenter'>
                                                    <Button style={{ width: 450 }} disabled={value == "" ? true : false} variant='contained' className={value == "" ? "btn submitBtnDisabled" : "btn submitBtn"} onClick={SaveResponse} startIcon={<DoneIcon />}>Submit Response</Button>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                        {
                                            imageAttachments.length > 0 &&
                                            <div className="backdrop tealBackground">
                                                <div className="title">
                                                    <Typography>Attachments</Typography>
                                                </div>
                                                <ImageAttachments imageAttachments={imageAttachments} />
                                            </div>
                                        }
                                        <div className="backdrop tealBackground">
                                            <div className="title">
                                                <Typography> Order Items</Typography>
                                            </div>
                                            <div className="mainContainer" style={{ padding: "20px" }}>
                                                <Grid container spacing={2} display={"flex"}>
                                                    <Grid item xs={1} sm={1} className='textCenter'>
                                                    </Grid>
                                                    <Grid item xs={9} sm={9} className='textLeft'>
                                                    </Grid>
                                                    {/* <Grid item xs={3} sm={3} className='textCenter'>
                                                        <Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>
                                                            Actions
                                                        </Typography>
                                                    </Grid> */}
                                                </Grid>
                                                <Divider />
                                                <div>


                                                    {
                                                        orderDetail?.ShipmentLines?.ShipmentLine?.map((item: any, index: any) => {

                                                            return webItemsRender(item)

                                                        })
                                                    }

                                                </div>
                                            </div >
                                        </div >
                                        <div className="backdrop tealBackground">
                                            <div className="title">
                                                <Typography> Customer Details</Typography>
                                            </div>
                                            <CustomerDetails customerDetail={customerDetail}></CustomerDetails>
                                        </div >
                                    </Grid>

                                </Grid>
                            </Paper>
                        </div>

                    </div > : ""
                }

            </div >
        );
    }

    const codeView = () => {

        return (
            <Paper className='paperToBottomPadding' style={{ padding: Utils.isMobile ? "0%" : "5%", background: "none", boxShadow: "none" }}>
                <Grid container spacing={2} display={"flex"} style={{ justifyContent: "center", display: "flex" }}>
                    <Grid item xs={Utils.isMobile ? 12 : 6} sm={Utils.isMobile ? 12 : 6} className="innerCodeDiv" style={{ justifyContent: "center", background: "white" }}>
                        <MailLockIcon style={{ fontSize: "3rem", color: "#008080" }} />
                        <Typography>
                            <h2>
                                Enter Your Verification Code
                            </h2>
                        </Typography>
                        <Typography style={{ fontSize: "18px", fontWeight: 500 }} variant="body2" sx={{ fontSize: 15 }} gutterBottom>
                            We have sent a 4-digit verification code to your email:
                        </Typography>
                        <Typography variant="body2" sx={{ fontSize: 15 }} gutterBottom>
                            Please enter the code you received below:
                        </Typography>
                        <div className="custom-styles">
                            <ReactInputVerificationCode placeholder="" onChange={(val: any) => {

                                console.log(val);
                                setCodeError("");
                                setUserCode(val);

                            }} />
                        </div>

                        <Typography style={{ marginTop: "30px", color: "red" }} variant="body2" sx={{ fontSize: 15 }} gutterBottom>
                            {codeError}
                        </Typography>
                        <Button style={{ marginTop: "30px", marginBottom: "30px" }} variant='contained' className='btn submitBtn' onClick={codeSubmitClick} startIcon={<DoneIcon />}>Submit</Button>


                    </Grid>
                </Grid>
            </Paper>
        );

    }

    const selectChoiceView = () => {

        return <Paper style={{ padding: "5px" }}>
            <Grid container>
                <Grid item xs={12} sm={12} className='inputField'>
                    {
                        choicesValues && choicesValues.length > 0 ?
                            <FormControl className='externalcustomRadio'>
                                <RadioGroup
                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                    name="controlled-radio-buttons-group"
                                    value={value}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setValue((event.target as HTMLInputElement).value);
                                    }}
                                >
                                    {choicesValues.map((val: any) => {
                                        return <FormControlLabel value={val.id} control={<Radio style={{ color: "#f2554a" }} />} label={val.val} />
                                    })}


                                </RadioGroup>
                            </FormControl> : ""
                    }

                    <TextField onChange={(event: any) => {

                        setCustomerMessage(event.target.value);

                    }} style={{ textAlign: "left" }} multiline fullWidth InputLabelProps={{ shrink: false }} id="filled-basic" placeholder='Please type your message here' variant="outlined" value={customerMessage} />

                </Grid>
            </Grid>
        </Paper>

    }

    return (
        <div>
            <NotificationPopup {...cancelPopup}></NotificationPopup>
            <NotificationPopup {...Error}></NotificationPopup>
            {cancelReason.showModal ? <CancelLine getCancelReasonValues={getCancelReasonValues} isExternal={true}></CancelLine> : ""}
            <div className='externalView'>
                <div className="custom-heading" style={{ fontSize: Utils.isMobile ? "30px" : "39px" }}>Customer Self Care Service Portal</div>
                {!isVerified ? codeView() : Utils.isMobile ? mobileView() : webView()}
            </div>

        </div >
    );
}
export default ExternalCustomerView;
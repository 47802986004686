import { Card, CardContent, Checkbox, Drawer, Grid, Typography } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import IdentityManager from "../../Managers/Identity.manager";
import allActions from "../../Store/Actions/AllActions";
import { DistrictListDropDown } from "./DistrictListDropDown";
import { RegionListDropDown } from "./RegionListDropdown";
import { StoreListDropDown } from "./StoreListDropDown";
import { CloseIcon } from "../../Subcomponent/Shared/SharedIcons";

export const DrawerDropdowns = (props: any) => {
    const openDrawer = useSelector((state: any) => state.ActionLoaderReducersState.openDrawerDropdowns);
    const isEnterprise = useSelector((state: any) => state.ActionLoaderReducersState.isEnterprise);
    const regions = useSelector((state: any) => state.ActionLoaderReducersState.regions);
    const dispatch = useDispatch();
    const closeDrawer = () => {
        dispatch(allActions.actionLoaderActions.setDrawerDropdowns(false));
    }
    const changeEnterprise = () => {
        let changeIsEnterprise = !isEnterprise;
        if (changeIsEnterprise) {
            props.changeRegion(null, regions)
        }
        dispatch(allActions.actionLoaderActions.setEnterprise(changeIsEnterprise));
    }

    return (
        <Drawer
            anchor={"right"}
            open={openDrawer}
            onClose={() => { closeDrawer() }}
        >
            <Card style={{ margin: "2px", width: "280px" }}>
                <div onClick={() => { closeDrawer() }} style={{ cursor: "pointer" }}>
                    <CloseIcon style={{ float: "right" }} />
                </div>
                <CardContent>
                    {(IdentityManager.isEnterprise) && <Grid container style={{ marginBottom: "10px" }}>
                        <Grid item xs={12} sm={12} style={{ display: "flex", alignItems: "center" }}>
                            <Checkbox
                                disabled={regions.length == 0}
                                checked={isEnterprise}
                                onChange={() => changeEnterprise()}
                            />
                            <Typography style={{ marginTop: "2px" }}>
                                Enterprise Data View
                            </Typography>
                        </Grid>
                    </Grid>}
                    {IdentityManager.showRegionDropdown &&
                        <React.Fragment>
                            <Typography component="div">
                                Regions
                            </Typography>
                            <RegionListDropDown region={props.region} changeRegion={props.changeRegion}></RegionListDropDown>
                        </React.Fragment>}
                    {IdentityManager.showDistrictDropdown &&
                        <React.Fragment>
                            <Typography component="div" style={{ marginTop: "20px" }}>
                                Districts
                            </Typography>
                            <DistrictListDropDown district={props.district} changeDistrict={props.changeDistrict}></DistrictListDropDown>
                        </React.Fragment>}
                    {IdentityManager.showStoreDropdown &&
                        <React.Fragment>
                            <Typography component="div" style={{ marginTop: "20px" }}>
                                Stores
                            </Typography>
                            <StoreListDropDown store={props.store} changeStoreList={props.changeStoreList}></StoreListDropDown>
                        </React.Fragment>}
                </CardContent>
            </Card>
        </Drawer>
    );
}
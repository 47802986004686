import { Card, CardContent, Grid, Tooltip } from "@mui/material";
import React from "react";
import Constants from "../Common/Constants";
import Utils from "../Common/Utils";
import StackGraph from "../Subcomponent/Dashboard/StackGraph";
import './DashboardGridHeader.scss';
import { AttachMoneyIcon, InfoOutlinedIcon, PlaylistAddCheckIcon, QueryBuilderIcon } from "../Subcomponent/Shared/SharedIcons";
export const DashboardGridHeader = (props: any) => {
    const { data } = props;

    const renderAvgAge = (avgAge: any) => {
        return (
            <>
                {
                    avgAge.days >= 1 ?
                        <>
                            {avgAge.days}
                            <span style={{ fontSize: 18 }}>&nbsp;day(s)</span>
                        </>
                        :
                        <></>
                }
                {
                    avgAge.remainingHours >= 1 ?
                        <>
                            &nbsp;{Math.floor(avgAge.remainingHours)}
                            <span style={{ fontSize: 18 }}>&nbsp;hr(s)</span>
                        </>

                        :
                        <></>
                }

            </>
        )
    }
    const renderMobileView = () => {

        return (
            <Grid container spacing={2} style={{ marginBottom: "10px" }}>
                <Grid item xs={12}>
                    <Card className='dCard' style={{ backgroundColor: "#eee" }}>
                        <CardContent className='dCardContent'>
                            <Grid item xs={12}>
                                <Grid container display="grid" alignItems="center" minHeight={105}>
                                    <Grid item xs={12}>
                                        <Grid container>
                                            <Grid item xs={4} style={{ display: "grid", alignItems: "center", justifyItems: "end" }}>
                                                <div className="grid-icons" style={{ backgroundColor: Constants.Colors.blue }}  >
                                                    <PlaylistAddCheckIcon />
                                                </div>
                                            </Grid>
                                            <Grid item xs={8} style={{ display: "grid", justifyItems: "start", alignItems: "center" }}>
                                                <div style={{ display: "flex", alignItems: "flex-end" }}>
                                                    <p className="grid-header-font">Total Orders</p>
                                                    <Tooltip title="Pick + Pack + Ship">
                                                        <InfoOutlinedIcon style={{ color: Constants.Colors.blue, marginTop: "2px", marginLeft: "10px" }} />
                                                    </Tooltip>
                                                </div>
                                                <p className="grid-value-font">
                                                    {data.totalOrders ?? "-"}
                                                </p>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container display="grid" alignItems="center" minHeight={105}>
                                    <Grid item sm={12} xs={12}>
                                        <Grid container>
                                            <Grid item sm={4} xs={4} style={{ display: "grid", alignItems: "center", justifyItems: "end" }}>
                                                <div className="grid-icons" style={{ backgroundColor: "rgb(103, 58, 183)" }}  >
                                                    <QueryBuilderIcon />
                                                </div>
                                            </Grid>
                                            <Grid item sm={8} xs={8} style={{ display: "grid", justifyItems: "start" }}>
                                                <div style={{ display: "flex", alignItems: "flex-end" }}>
                                                    <p className="grid-header-font" style={{ whiteSpace: "nowrap" }}>Order Age (Avg)</p>
                                                    <Tooltip title="Pick + Pack + Ship">
                                                        <InfoOutlinedIcon style={{ color: Constants.Colors.blue, marginTop: "2px", marginLeft: "10px" }} />
                                                    </Tooltip>
                                                </div>
                                                <div style={{ display: "flex" }}>

                                                    <p className="grid-value-font" style={{ overflowWrap: "anywhere" }}>
                                                        {data.ordersAge && data.ordersAge > 0 ? renderAvgAge(Utils.hoursTextFormatterHeading(data.ordersAge)) : "-"}
                                                    </p>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container display="grid" alignItems="center" minHeight={105}>
                                    <Grid item xs={12}>
                                        <Grid container>
                                            <Grid item xs={4} style={{ display: "grid", alignItems: "center", justifyItems: "end" }}>
                                                <div className="grid-icons" style={{ backgroundColor: Constants.Colors.green }}  >
                                                    <AttachMoneyIcon />
                                                </div>
                                            </Grid>
                                            <Grid item xs={8} style={{ display: "grid", justifyItems: "start" }}>
                                                <div style={{ display: "flex", alignItems: "flex-end" }}>
                                                    <p className="grid-header-font">Orders Value</p>
                                                    <Tooltip title="Pick + Pack + Ship">
                                                        <InfoOutlinedIcon style={{ color: Constants.Colors.blue, marginTop: "2px", marginLeft: "10px" }} />
                                                    </Tooltip>
                                                </div>
                                                <p className="grid-value-font">
                                                    {data.ordersValue ? Utils.nFormatter(data.ordersValue, 2) : "-"}
                                                </p>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container minHeight={110}>
                                    <Grid item xs={12} display="flex" alignItems="center" justifyContent="center">
                                        <StackGraph pick={data.pick}
                                            pack={data.pack} ship={data.ship} height={70} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        );
    }

    const renderWebView = () => {
        return (
            <Grid container spacing={2}>
                <Grid item sm={3} xs={12}>
                    <Card className='dCard' style={{ backgroundColor: "#eee", height: "90%" }}>
                        <CardContent className='dCardContent'>
                            <Grid container display="grid" alignItems="center" minHeight={105}>
                                <Grid item sm={12} xs={12}>
                                    <Grid container>
                                        <Grid item sm={4} xs={4} style={{ display: "grid", alignItems: "center", justifyItems: "end" }}>
                                            <div className="grid-icons" style={{ backgroundColor: Constants.Colors.blue }}  >
                                                <PlaylistAddCheckIcon />
                                            </div>
                                        </Grid>
                                        <Grid item sm={8} xs={8} style={{ display: "grid", justifyItems: "start", alignItems: "center" }}>
                                            <div style={{ display: "flex", alignItems: "flex-end" }}>
                                                <p className="grid-header-font">Total Orders</p>
                                                <Tooltip title="Pick + Pack + Ship">
                                                    <InfoOutlinedIcon style={{ color: Constants.Colors.blue, marginTop: "2px", marginLeft: "10px" }} />
                                                </Tooltip>
                                            </div>
                                            <p className="grid-value-font">
                                                {data.totalOrders ?? "-"}
                                            </p>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item sm={3} xs={12}>
                    <Card className='dCard' style={{ backgroundColor: "#eee", height: "90%" }}>
                        <CardContent className='dCardContent'>
                            <Grid container display="grid" alignItems="center" minHeight={105}>
                                <Grid item sm={12} xs={12}>
                                    <Grid container>
                                        <Grid item sm={4} xs={4} style={{ display: "grid", alignItems: "center", justifyItems: "end" }}>
                                            <div className="grid-icons" style={{ backgroundColor: "rgb(103, 58, 183)" }}  >
                                                <QueryBuilderIcon />
                                            </div>
                                        </Grid>
                                        <Grid item sm={8} xs={8} style={{ display: "grid", justifyItems: "start" }}>
                                            <div style={{ display: "flex", alignItems: "flex-end" }}>
                                                <p className="grid-header-font" style={{ whiteSpace: "nowrap" }}>Order Age (Avg)</p>
                                                <Tooltip title="Pick + Pack + Ship">
                                                    <InfoOutlinedIcon style={{ color: Constants.Colors.blue, marginTop: "2px", marginLeft: "10px" }} />
                                                </Tooltip>
                                            </div>
                                            <div style={{ display: "flex" }}>
                                                <p className="grid-value-font" style={{ overflowWrap: "anywhere" }}>
                                                    {data.ordersAge && data.ordersAge > 0 ? renderAvgAge(Utils.hoursTextFormatterHeading(data.ordersAge)) : "-"}
                                                </p>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item sm={3} xs={12}>
                    <Card className='dCard' style={{ backgroundColor: "#eee", height: "90%" }}>
                        <CardContent className='dCardContent'>
                            <Grid container display="grid" alignItems="center" minHeight={105}>
                                <Grid item sm={12} xs={12}>
                                    <Grid container>
                                        <Grid item sm={4} xs={4} style={{ display: "grid", alignItems: "center", justifyItems: "end" }}>
                                            <div className="grid-icons" style={{ backgroundColor: Constants.Colors.green }}  >
                                                <AttachMoneyIcon />
                                            </div>
                                        </Grid>
                                        <Grid item sm={8} xs={8} style={{ display: "grid", justifyItems: "start" }}>
                                            <div style={{ display: "flex", alignItems: "flex-end" }}>
                                                <p className="grid-header-font">Orders Value</p>
                                                <Tooltip title="Pick + Pack + Ship">
                                                    <InfoOutlinedIcon style={{ color: Constants.Colors.blue, marginTop: "2px", marginLeft: "10px" }} />
                                                </Tooltip>
                                            </div>
                                            <p className="grid-value-font">
                                                {data.ordersValue ? Utils.nFormatter(data.ordersValue, 2) : "-"}
                                            </p>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Card className='dCard' style={{ backgroundColor: "#eee", height: "90%" }}>
                        <CardContent className='dCardContent'>
                            <Grid container minHeight={110}>
                                <Grid item sm={12} xs={12} display="flex" alignItems="center" justifyContent="center" marginTop={"10px"}>
                                    <StackGraph pick={data.pick}
                                        pack={data.pack} ship={data.ship} height={70} />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        );
    }

    return (
        <div>
            <React.Fragment>
                {Utils.isMobile ? renderMobileView() : renderWebView()}
            </React.Fragment>
        </div>
    );
}
export default class ShipmentStatusConstants {

    public static ReadyForPick: string = "1100.70.06.10";
    public static ReadyForPack: string = "1100.70.06.50";
    public static ShipmentBeingPacked: string = "1100.70.06.70";
    public static ShipmentPacked: string = "1300";
    public static ShipmentShipped = "1400";
    public static PendingReceipts: string = "1400.100";
    public static ReadyForCustomerPickup: string = "1400.200";
    public static CustomerPickedup: string = "1400.300"
    public static Shipped = "1400.50";
    public static ReadyForPickString: string = "Pick";
    public static ReadyForPackString: string = "Pack";
    public static ReadyForCustomerPickupString: string = "CustomerPickup";
    public static PendingReceiptsString: string = "Pending";
    public static BackOrderedOMS: string = "1300.10";
    public static SentToFulfillmentCenter = "3350.50";
    public static Cancelled: string = "9000";
    public static PartialPendingReceive: string = "1400.150"
}


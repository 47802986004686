import React, { useState } from "react";
import "./ResourceItem.scss";
import {
    Grid,
    Typography,
    Radio,
    TextField,
    FormControl,
    Select,
    MenuItem,
    Checkbox,
    DialogActions,
    Button
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Slider from "@mui/material/Slider";
import MuiInput from "@mui/material/Input";
import { useDispatch, useSelector } from 'react-redux';
import { DataGrid, GridColDef, GridSelectionModel } from "@mui/x-data-grid";
import Constants from "../../Common/Constants";
import Utils from "../../Common/Utils";
import { IResourceView, Node, ShipNodePersonInfo, Availability } from '../../Models/IResourceView';
import { OmsPickOrderDetail, ShipmentLine, CommonCode } from '../../Models/OmsPickOrderDetail';
import { TailSpin } from "react-loader-spinner";
import { NotificationPopup } from '../Shared/NotificationPopup'
import allActions from "../../Store/Actions/AllActions"
import { VerifyCredentials } from '../../Subcomponent/Shared/VerifyCredentials';
import { INotificationPopupSettings, IButton } from '../../Models/INotificationPopupSettings';
import { SocialDistanceIcon, LocationOnIcon, MailIcon, LocalPhoneIcon, StoreIcon, DoneIcon, CloseIcon } from "../../Subcomponent/Shared/SharedIcons";
import StoreManager from "../../Managers/Store.manager"
import OMSManager from "../../Managers/Oms.manager";
import { useNavigate } from "react-router-dom";
import './ResourceItem.scss';
import OrderManager from "../../Managers/Orders.Manager";
import { IResourceShipmentLine } from "../../Models/IResourceShipmentLine";
import IdentityManager from "../../Managers/Identity.manager";
const Input = styled(MuiInput)`
  width: 60px;
`;
var moment = require('moment');
export const ResourceItem: React.FC<any> = (props: any) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let resourceDefault = Number(StoreManager.config.resourceDefault)
    let resourceMax = Number(StoreManager.config.resourceMax)
    let orderDetail: OmsPickOrderDetail = useSelector((state: any) => state.PickOrdersReducersState.orderDetail);
    let selectedShipmentLineKey = useSelector((state: any) => state.PickOrdersReducersState.selectedShipmentLineKey);
    //let radioChecked = [rows[0].id];
    const [value, setValue] = React.useState(resourceDefault);
    const [valueConformed, setValueConformed] = React.useState(resourceDefault);
    const [reasonCode, setReasonCode] = React.useState(0);
    const [reasonText, setReasonText] = React.useState("");
    const [selectionModel, setSelectionModel] = React.useState([-1]);
    const [checkboxChecked, setCheckboxChecked] = React.useState(-1);
    const [selectedStoreDetail, setSelectedStoreDetail] = React.useState<Node>();
    const [resourceViewData, setResourceViewData] = React.useState<IResourceView>({} as IResourceView);
    const [loading, setLoading] = React.useState(true);
    const [enableYesButton, setYesButton] = React.useState(false);
    const [openGeneralError, setOpenGeneralError] = useState(false);
    const [errorGeneralMsg, setErrorGeneralMsg] = useState("");
    const [verifyCredentials, setVerifyCredentials] = useState(false);
    const [pageSize, setPageSize] = useState<number>(15);

    const showActionLoader = () => {
        dispatch(allActions.actionLoaderActions.showLoader());
    }
    const hideActionLoader = () => {
        dispatch(allActions.actionLoaderActions.hideLoader());
    }
    const columnsWeb = [
        {
            field: "radiobutton",
            headerName: "",
            width: 100,
            sortable: false,
            renderCell: (params: any) => {
                return (
                    <Radio checked={selectionModel != undefined && selectionModel[0] === params.id} value={params.ShipNode} />
                )
            }
        },
        { field: "ShipNode", headerName: "Store", width: 200 },
        { field: "Quantity", headerName: "Available Quantity", width: 250, resizable: true },
        {
            field: "checkbox",
            headerName: "Force Sourcing",
            width: 300, resizable: true,
            renderCell: (params: any) => {
                if (params.row.EnableForcedSourcing == "Y") {
                    return (
                        <Checkbox
                            checked={checkboxChecked == params.row.id}
                            onChange={(e) => checkboxChange(e, params.row.id)}
                            style={{
                                color: "#D93A2F",
                            }} />
                    )
                }
                else {
                    return ("");
                }

            }
        }
    ];
    const columnsMobile = [
        {
            field: "radiobutton",
            headerName: "",
            width: 50,
            sortable: false,
            renderCell: (params: any) => {
                return (
                    <Radio checked={selectionModel != undefined && selectionModel[0] === params.id} value={params.ShipNode} />
                )
            }
        },
        { field: "ShipNode", headerName: "Store" },
        { field: "Quantity", headerName: "Available Quantity", description: "Available Quantity", width: 150, resizable: true },
        {
            field: "checkbox",
            headerName: "Force Sourcing",
            width: 200, resizable: true,
            renderCell: (params: any) => {
                if (params.row.EnableForcedSourcing == "Y") {
                    return (
                        <Checkbox
                            checked={checkboxChecked == params.row.id}
                            onChange={(e) => checkboxChange(e, params.row.id)}
                            style={{
                                color: "#D93A2F",
                            }} />
                    )
                }
                else {
                    return ("");
                }

            }
        }
    ];
    const checkboxChange = (e: any, id: any) => {
        if (e.target.checked) {
            setCheckboxChecked(id)
        }
        else {
            setCheckboxChecked(-1)
        }
    }
    const columns = Utils.isMobile ? columnsMobile : columnsWeb
    let resourceReasonCodes = [] as any;
    orderDetail?.CommonCodeList?.CommonCode.forEach((code: CommonCode) => {
        resourceReasonCodes.push({ name: code.CodeShortDescription, value: code.CodeValue })
    });
    const handleInputChange = (event: any) => {
        setValue(Number(event.target.value));
        setValueConformed(Number(event.target.value));
    };

    const closeVerificationPopUp = () => {
        setVerifyCredentials(false);
    }

    const openVerifyCredentials = () => {
        if (IdentityManager.verifyCredentialsException) {
            confirmCredentials();
        }
        else {
            setVerifyCredentials(true);
        }
    }

    const confirmCredentials = () => {
        resourceItem()
    }
    let verifyCredentialsButtons: IButton[] = [];

    let verifyCredentialsPopUp: INotificationPopupSettings = {
        open: verifyCredentials,
        type: Constants.NotificationPopupType.Conformation,
        title: "Status Approved Credentials",
        msg: "",
        handleClose: closeVerificationPopUp,
        isCustomJSX: true,
        customJSX: <VerifyCredentials confirmCredentials={confirmCredentials} closePopUp={closeVerificationPopUp}></VerifyCredentials>,
        actions: verifyCredentialsButtons
    }
    const handleBlur = () => {
        if (value < 0) {
            setValue(0);
            setValueConformed(0);
        } else if (value > resourceMax) {
            setValue(resourceMax);
            setValueConformed(resourceMax);
        }
    };
    function calculateValue(value: number) {
        return value * 10;
    }
    const handleChange = (event: any, newValue: any) => {
        if (typeof newValue === "number") {
            setValue(newValue);
        }
    };
    const handleChangeConformed = (event: any, newValue: any) => {
        if (typeof newValue === "number") {
            setValueConformed(newValue);
        }
    };
    const changeRadioSelection = (newSelectionModel: any) => {
        setSelectionModel(newSelectionModel);
        let selectedStore: Node = resourceViewData?.NodeList?.Node[newSelectionModel[0]]
        setSelectedStoreDetail(selectedStore)
        setCheckboxChecked(-1)
    }
    const renderWebDataTable = () => {
        // const rows = [
        //     { id: 1, store: "914", available: "31" },
        //     { id: 2, store: "KCDC", available: "513" },
        // ];
        let rows = [] as any;
        resourceViewData?.NodeList?.Node?.forEach((store: Node, index: any) => {
            let storeData = {
                id: index,
                ShipNode: store.Availability.ShipNode,
                EnableForcedSourcing: store.EnableForcedSourcing,
                Quantity: store.Availability.AvailableQty
            }
            rows.push(storeData)
        });

        return (
            <div className="" style={{ height: 300 }}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowsPerPageOptions={[15, 30, 50]}
                    pagination
                    selectionModel={selectionModel}
                    onSelectionModelChange={(newSelectionModel: any, value: any) => changeRadioSelection(newSelectionModel)}
                />
            </div>
        );
    };

    const changeReasonText = (e: any) => {
        setReasonText(e.target.value)
    }
    const changeReasonCode = (e: any) => {
        setReasonCode(e.target.value)
    }
    const renderReasons = () => {
        return (
            <div style={{ marginTop: 20 }}>
                <Grid item xs={12} sm={12} className='inputField'>
                    <Typography>
                        Reason Code*
                    </Typography>
                    <FormControl>
                        <Select
                            onChange={(e) => changeReasonCode(e)}
                            displayEmpty
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={reasonCode}
                            variant='outlined'
                            inputProps={{ 'aria-label': 'Without label' }}          >
                            <MenuItem disabled value={0}>
                                <em>Please Select ID Type</em>
                            </MenuItem>
                            {resourceReasonCodes.map((item: any, i: number) => <MenuItem value={item.value}>{item.name}</MenuItem>)}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} className='inputField'>
                    <Typography>
                        Reason Text*
                    </Typography>
                    <TextField onChange={(e) => changeReasonText(e)} value={reasonText} fullWidth InputLabelProps={{ shrink: false }} id="filled-basic" placeholder='Reason Text' variant="outlined" multiline />
                </Grid>
            </div>
        );
    }

    const callResourceViewData = () => {
        setLoading(true)
        setYesButton(false)
        OMSManager.GCPickResourceView(orderDetail, selectedShipmentLineKey, valueConformed).then((result) => {
            if (result.success == true) {
                setResourceViewData(result.data)
                // setReasonText("")
                // setReasonCode(0)
                setCheckboxChecked(-1)
                setSelectionModel([-1])
                setLoading(false)
            }
            else {
                //show Error 
                setLoading(false)
                setOpenGeneralError(true)
                setErrorGeneralMsg("Something went wrong");
            }
        }).catch(() => {
            setLoading(false)
            setOpenGeneralError(true)
            setErrorGeneralMsg("Something went wrong");
        });
    }
    const closeGeneralErrorModel = () => {
        setOpenGeneralError(false);
        setErrorGeneralMsg("");
    }

    let errorGeneralPopupButtons: IButton[] = [
        {
            text: "Close",
            icon: <CloseIcon />,
            action: closeGeneralErrorModel,
            color: Constants.Colors.red,
        }
    ];
    let GeneralError: INotificationPopupSettings = {
        open: openGeneralError,
        type: Constants.NotificationPopupType.Error,
        title: "Error",
        msg: errorGeneralMsg,
        draggable: false,
        handleClose: closeGeneralErrorModel,
        actions: errorGeneralPopupButtons
    }

    const logOrderHistory = async (input: any) => {
        await OrderManager.saveOrderHistory({
            shipmentKey: props.shipmentKey,
            logType: "history",
            transactionType: Constants.OrderHistoryTypes.Resource_Item,
            transactionDate: moment.utc(moment.tz("America/Los_Angeles")).toISOString(),
            selectedShipmentLineKey: input.selectedShipmentLineKey,
            resourceShipmentNode: input.resourceShipmentNode,
            resourceShipmentLineData: input.resourceShipmentLineData,
            reasonCode: input.reasonCode,
            reasonText: input.reasonText
        });
        dispatch(allActions.orderHistoryActions.forceHistoryLoad());
    }

    const resourceItem = () => {
        if (StoreManager.currentStore.StoreId == undefined) {
            return
        }
        let selectedNode: Node = resourceViewData?.NodeList?.Node[selectionModel[0]]
        let resourceShipmentNode = selectedNode.Availability.ShipNode;
        let availability = selectedNode.Availability.AvailableQty;
        let forceSourcing = checkboxChecked == -1 ? "N" : "Y";
        let firstAvailableDate = selectedNode?.Availability?.FirstAvailableDate ? selectedNode?.Availability?.FirstAvailableDate : ""
        showActionLoader()
        OMSManager.GCPickResourceLine(orderDetail, selectedShipmentLineKey, reasonCode, reasonText, resourceShipmentNode, availability, forceSourcing, StoreManager.currentStore.StoreId, firstAvailableDate).then((response: any) => {
            if (response.success == true) {
                if (response.data.IsSuccess == "Y") {
                    let selectedShipmentLine: ShipmentLine = orderDetail?.ShipmentLines?.ShipmentLine?.filter((shipmentLine: ShipmentLine) => {
                        return shipmentLine.ShipmentLineKey == selectedShipmentLineKey
                    })[0];

                    let resourceShipmentLineData: IResourceShipmentLine = {
                        imageUrl: selectedShipmentLine.ImageUrl,
                        itemDesc: selectedShipmentLine.ItemDesc,
                        itemID: selectedShipmentLine?.OrderLine?.ItemDetails?.Extn?.ExtnPOSItemID,
                        skuID: selectedShipmentLine.ItemID,
                        unitPrice: selectedShipmentLine.UnitPrice,
                        quantity: selectedShipmentLine.Quantity
                    };

                    logOrderHistory({ selectedShipmentLineKey, reasonCode, reasonText, resourceShipmentNode, resourceShipmentLineData });
                    hideActionLoader();
                    props.closeResource();
                    if (orderDetail.ShipmentLines && orderDetail.ShipmentLines.ShipmentLine && orderDetail.ShipmentLines.ShipmentLine.length == 1) {
                        navigate("/FulfillOrders");
                    }
                    else {
                        props.setSuccess(true)
                    }
                }
                else {
                    setOpenGeneralError(true)
                    setErrorGeneralMsg("Something went wrong");
                    hideActionLoader();
                }
            }
            else {
                let errorMsg = "";
                setOpenGeneralError(true)
                if (!response.success && response.msg) {
                    errorMsg = response.msg;
                }
                else {
                    errorMsg = "Something went wrong";
                }
                setErrorGeneralMsg(errorMsg);
                hideActionLoader();
            }
        }).catch(() => {
            setOpenGeneralError(true)
            setErrorGeneralMsg("Something went wrong");
            hideActionLoader();
        })
    }
    React.useEffect(() => {
        if (reasonCode != 0 && reasonText != "" && selectionModel.length != 0 && selectionModel[0] != -1) {
            setYesButton(true)
        }
        else {
            setYesButton(false)
        }
    }, [reasonCode, reasonText, selectionModel]);

    React.useEffect(() => {
        async function getResourceData() {
            await callResourceViewData()
        }
        getResourceData()
    }, [valueConformed]);
    return (
        <div>
            <NotificationPopup {...verifyCredentialsPopUp}>
            </NotificationPopup>
            <NotificationPopup {...GeneralError}></NotificationPopup>
            {renderReasons()}
            <Typography style={{ textAlign: "left", marginTop: 5 }}>Radius (Miles)</Typography>
            <Grid container spacing={2} alignItems="center">
                <Grid item>
                    <SocialDistanceIcon />
                </Grid>
                <Grid item xs>
                    <Slider
                        value={value}
                        min={5}
                        step={1}
                        max={resourceMax}
                        scale={calculateValue}
                        onChange={(e, newValue) => handleChange(e, newValue)}
                        style={{ color: Constants.Colors.red }}
                        onChangeCommitted={(e, newValue) => handleChangeConformed(e, newValue)}
                    />
                </Grid>
                <Grid item>
                    <Input
                        value={value}
                        size="small"
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        inputProps={{
                            step: 5,
                            min: 1,
                            max: resourceMax,
                            type: "number",
                            "aria-labelledby": "input-slider",
                        }}
                    />
                </Grid>
            </Grid>
            {loading == false ?
                <React.Fragment>
                    <Grid container alignItems="center">
                        <div className="backdrop">
                            <div className="title">
                                <Typography>Available Stores</Typography>
                            </div>
                            <div className="mainContainer" style={{ padding: "10px" }}>
                                <Grid sm={12} xs={12} item>
                                    {renderWebDataTable()}
                                </Grid>
                            </div>
                        </div>
                    </Grid>
                    {selectedStoreDetail?.NodeOrgCode != undefined ?
                        <Grid container>
                            <div className="backdrop">
                                <div className="title" style={{ display: "flex", justifyContent: "space-between" }}>
                                    <Typography>Resource From</Typography>
                                    <Typography style={{ color: Constants.Colors.white }}>{selectedStoreDetail.DistanceFromShipToAddress} Miles Away</Typography>
                                </div>
                                <div className="mainContainer" style={{ padding: "10px", display: Utils.isMobile ? "" : "flex" }}>
                                    <Grid sm={7} xs={12} item>
                                        <Typography
                                            style={{ textAlign: "left", display: "flex", marginBottom: 5 }}
                                        >
                                            <StoreIcon style={{ color: Constants.Colors.red }}></StoreIcon> <b style={{ color: Constants.Colors.red }}>{selectedStoreDetail.Description}</b>

                                        </Typography>
                                        <Typography style={{ textAlign: "left", paddingLeft: "10px", color: Constants.Colors.grey }}>
                                            {selectedStoreDetail.DistanceFromShipToAddress} Miles Away
                                        </Typography>
                                        {
                                            selectedStoreDetail.ShipNodePersonInfo.EMailID != "" ?
                                                <Typography style={{ textAlign: "left", display: "flex", marginBottom: 5 }}><MailIcon style={{ color: Constants.Colors.red }} ></MailIcon>  {selectedStoreDetail.ShipNodePersonInfo.EMailID}</Typography>
                                                :
                                                ""
                                        }
                                        {
                                            selectedStoreDetail.ShipNodePersonInfo.DayPhone != "" ?
                                                <Typography style={{ textAlign: "left", display: "flex", marginBottom: 5 }}>
                                                    <LocalPhoneIcon style={{ color: Constants.Colors.red }}></LocalPhoneIcon> {selectedStoreDetail.ShipNodePersonInfo.DayPhone}
                                                </Typography>
                                                :
                                                ""
                                        }
                                    </Grid>
                                    <Grid style={{ marginTop: Utils.isMobile ? "10px" : "0px" }} sm={5} xs={12} item>
                                        <Typography
                                            sx={{
                                                fontWeight: "bold",
                                                color: Constants.Colors.red,
                                                display: "flex",
                                            }}
                                        >
                                            <LocationOnIcon></LocationOnIcon> Store Address
                                        </Typography>
                                        <Typography style={{ textAlign: "left", paddingLeft: "25px" }}>
                                            {selectedStoreDetail.ShipNodePersonInfo.AddressLine1}
                                        </Typography>
                                        <Typography style={{ textAlign: "left", paddingLeft: "25px" }}>
                                            {selectedStoreDetail.ShipNodePersonInfo.AddressLine2}
                                        </Typography>
                                        <Typography style={{ textAlign: "left", paddingLeft: "25px" }}>
                                            {selectedStoreDetail.ShipNodePersonInfo.City},{selectedStoreDetail.ShipNodePersonInfo.State},{selectedStoreDetail.ShipNodePersonInfo.ZipCode}
                                        </Typography>
                                        <Typography style={{ textAlign: "left", paddingLeft: "25px" }}>
                                            {selectedStoreDetail.ShipNodePersonInfo.Country}
                                        </Typography>
                                    </Grid>
                                </div>
                            </div>
                        </Grid>
                        :
                        <div></div>
                    }

                </React.Fragment>
                :
                <div>
                    <TailSpin color="#D93A2F" height={80} width={80} />
                </div>

            }
            <DialogActions>
                <Button className={enableYesButton ? "" : "disabledButton"} disabled={enableYesButton ? false : true} key={0} style={{ whiteSpace: 'nowrap', backgroundColor: Constants.Colors.blue, color: "white" }} onClick={() => openVerifyCredentials()} variant="contained" startIcon={<DoneIcon />}>
                    Yes
                </Button>
                <Button key={1} style={{ whiteSpace: 'nowrap', backgroundColor: Constants.Colors.grey, color: "white" }} onClick={() => props.closeResource()} variant="contained" startIcon={<CloseIcon />}>
                    No
                </Button>
            </DialogActions>
        </div>
    );
};

import React, { useState } from 'react';
import './Routes.scss';
import { Avatar, Badge, Divider, Grid, List, ListItem, ListItemSecondaryAction, ListItemText, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Constants from "../Common/Constants"
import { styled } from '@mui/material/styles';
import { IVerticalStepper } from '../Models/IVerticalStepper';
import { NotificationPopup } from '../Subcomponent/Shared/NotificationPopup'
import { INotificationPopupSettings, IButton } from '../Models/INotificationPopupSettings';
import { CustomerResponse } from '../Subcomponent/Shared/CustomerResponse'
import { useDispatch, useSelector } from 'react-redux';
import './Dashboard.scss'
import { MarkEmailUnreadIcon, CloseIcon, ShoppingCart, PendingActionsIcon, CallReceived, NextPlan, DirectionsCar, ExpandMoreIcon, DateRangeIcon, AssignmentLateIcon, MarkEmailReadIcon, HistoryToggleOffIcon } from "../Subcomponent/Shared/SharedIcons";

import { makeStyles } from "@material-ui/core/styles";
import { useNavigate } from 'react-router-dom';
import CustomerWaiting from '../Subcomponent/Dashboard/CustomerWaiting';
import Utils from '../Common/Utils';
import allActions from '../Store/Actions/AllActions';
import StoreManager from '../Managers/Store.manager';
import OrderManager from '../Managers/Orders.Manager';
import OrderLineShimmer from '../Subcomponent/PickOrder/OrderLineShimmer';

import OrderCountShimmer from '../Subcomponent/Dashboard/OrderCountShimmer';
import StatusBtn from '../Subcomponent/Shared/StatusBtn';
import NoDataFound from '../Subcomponent/Shared/NoDataFound';
import BulletShimmer from '../Subcomponent/Shared/BulletShimmer';
import IdentityManager from '../Managers/Identity.manager';
import ShipmentStatusConstant from "../Common/ShipmentStatusConstants";
import { OrderDetailNotificationPopup } from '../Subcomponent/Shared/OrderDetailNotificationPopup';
import { DashboardGrid } from './DashboardGrid';

const Dashboard = (props: any) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let orderCount = useSelector((state: any) => state.DashboardReducerState.orderCount);
    let expedited = useSelector((state: any) => state.DashboardReducerState.expedited);
    let resourced = useSelector((state: any) => state.DashboardReducerState.resourced);
    let pendingCustomers = useSelector((state: any) => state.DashboardReducerState.pendingCustomers);
    let pendingCustomerDecisions = useSelector((state: any) => state.DashboardReducerState.pendingCustomerDecisions);
    const stores = useSelector((state: any) => state.ActionLoaderReducersState.stores);
    const districtsList = useSelector((state: any) => state.ActionLoaderReducersState.districts);
    const regionsList = useSelector((state: any) => state.ActionLoaderReducersState.regions);
    let [isOrderCountLoaded, setOrderCountLoaded] = useState(false);
    let [isExpeditedloaded, setExpediteLoaded] = useState(false);
    let [isResourcedloaded, setResourcedLoaded] = useState(false);
    let [isPendingCustomersloaded, setPendingCustomersLoaded] = useState(false);
    let [isPendingCustomerDecisionsloaded, setPendingCustomerDecisionsLoaded] = useState(false);
    const [status, setStatus] = useState(0);
    const [contactCustomerData, setContactCustomerData] = useState({} as any)
    const [customerResponse, setCustomerResponse] = useState(false);
    const [openInfo, setOpenInfo] = useState(false);
    const closeCustomerResponse = () => {
        setCustomerResponse(false)
    }
    const openCustomerResponse = () => {
        setCustomerResponse(true)
    }

    const openInfoPopup = () => {
        setOpenInfo(true);
        updateStatusState();
    }

    const closeInfoPopup = () => {
        setOpenInfo(false)
    }

    const updateStatusState = () => {
        setStatus(status + 1);
    }

    const clickCustomerResponseIcon = (e: any, data: any) => {
        console.log(data)
        e.stopPropagation()
        setContactCustomerData(data)
        openCustomerResponse()
    }
    let customerResponseButtons: IButton[] = [
        {
            text: "Close",
            icon: <CloseIcon />,
            action: closeCustomerResponse,
            color: Constants.Colors.blue,
        }]
    let showCustomerResponse: INotificationPopupSettings = {
        open: customerResponse,
        type: Constants.NotificationPopupType.Info,
        title: "Customer Response",
        msg: "",
        handleClose: closeCustomerResponse,
        isCustomJSX: true,
        customJSX: <CustomerResponse {...contactCustomerData}></CustomerResponse>,
        actions: customerResponseButtons,
        fullWidth: true,
        maxWidth: "lg"
    }

    let Info = {
        open: openInfo,
        msg: "The Order Status has been changed. Your screen will automatically refresh now.",
        handleClose: closeInfoPopup,
        zIndex: 10000000
    };

    const showActionLoader = () => {
        dispatch(allActions.actionLoaderActions.showLoader());
    }
    const hideActionLoader = () => {
        dispatch(allActions.actionLoaderActions.hideLoader());
    }
    const useStyles = makeStyles((theme) => ({
        root: {
            backgroundColor: theme.palette.primary.main
        }
    }));
    const classes = useStyles();
    const [expandResource, setExpandResource] = React.useState(Utils.isDesktop);
    const handleResource = () => {
        setExpandResource(!expandResource);
    };
    const [expandExpedited, setExpandExpedited] = React.useState(Utils.isDesktop);
    const [expandedDecisions, setExpandedDecisions] = React.useState(Utils.isDesktop);
    const [expandedPickup, setExpandedPickup] = React.useState(Utils.isDesktop);
    const handleExpedited = () => {
        setExpandExpedited(!expandExpedited);
    };
    const handleDecisions = () => {
        setExpandedDecisions(!expandedDecisions);
    };
    const handlePickup = () => {
        setExpandedPickup(!expandedPickup);
    };

    const navigateToOrder = async (shipmentKey: any, orderNo: any, type: any, storeId: any) => {
        showActionLoader();
        let detailStatus = true;

        await Utils.checkOrderDetailStatus({
            shipmentKey: shipmentKey,
            status: type,
        }).then((res: any) => {
            if (res?.success) {
                detailStatus = res.data;
            }
        }).catch((err: any) => {
            console.log("Order Detail Status Error", err);
        })

        switch (type) {
            case ShipmentStatusConstant.ReadyForPick:
                if (detailStatus) {
                    hideActionLoader();
                    navigate("/PickOrder?orderno=" + orderNo + "&shipmentkey=" + shipmentKey + "&store=" + storeId + "");
                }
                else {
                    hideActionLoader();
                    openInfoPopup();
                }
                break;
            case ShipmentStatusConstant.ReadyForCustomerPickup:
                if (detailStatus) {
                    hideActionLoader();
                    navigate("/CustomerPickup?orderno=" + orderNo + "&shipmentkey=" + shipmentKey + "&store=" + storeId + "");
                }
                else {
                    hideActionLoader();
                    openInfoPopup();
                }
                break;
            default:
                break;
        }
    }
    const quickActionsMobile = () => {
        return (
            <Grid container spacing={2} className={Utils.isMobile ? "1" : "2"} style={{ paddingLeft: 3, paddingRight: 3 }}>
                <Grid item xs={6} sm={3} style={{ paddingRight: 2, paddingBottom: 0 }}>
                    <Card className='dCard'>
                        <CardContent className='dCardContent' style={{ padding: 25 }} onClick={() => props.navigateToDrawer("FulfillOrders", 1)}>
                            <Button startIcon={<ShoppingCart />} style={{ marginTop: 0 }} variant="contained" size="small" color="primary" className='btn'>Pick</Button>
                            {isOrderCountLoaded ? <React.Fragment>
                                <Typography variant="h4" component="div" className='number' style={{ marginTop: 5 }}>
                                    {orderCount.readyForPicking}
                                </Typography>
                            </React.Fragment> : <OrderCountShimmer></OrderCountShimmer>}

                        </CardContent>
                    </Card>
                </Grid>
                {/* <Grid item xs={6} sm={3} style={{ paddingLeft: 2, paddingBottom: 0 }}>
                    <Card className='dCard'>
                        <CardContent className='dCardContent' style={{ padding: 8 }} onClick={() => props.navigateToDrawer("FulfillOrders", 2)} >
                            <Button startIcon={<PendingActionsIcon />} style={{ marginTop: 0 }} variant="contained" size="small" color="primary" className='btn'>Pack</Button>
                            {isOrderCountLoaded ? <React.Fragment>
                                <Typography variant="h4" component="div" className='number' style={{ marginTop: 5 }}>
                                    {orderCount.readyForPacking}
                                </Typography>
                            </React.Fragment> : <OrderCountShimmer></OrderCountShimmer>}

                        </CardContent>
                    </Card>
                </Grid> */}
                <Grid item xs={6} sm={3} style={{ paddingRight: 2 }}>
                    <Card className='dCard'>
                        <CardContent className='dCardContent' style={{ padding: 25 }} onClick={() => props.navigateToDrawer("FulfillOrders", 3)} >
                            <Button startIcon={<NextPlan></NextPlan>} style={{ marginTop: 0 }} size="small" variant="contained" color="primary" className='btn'>PickUp

                            </Button>
                            {isOrderCountLoaded ? <React.Fragment>
                                <Typography variant="h4" component="div" className='number' style={{ marginTop: 5 }}>
                                    {orderCount.readyForCustomerPickup}
                                </Typography>
                            </React.Fragment> : <OrderCountShimmer></OrderCountShimmer>}

                        </CardContent>
                    </Card>
                </Grid>
                {/* <Grid item xs={6} sm={3} style={{ paddingLeft: 2 }}>
                    <Card className='dCard'>
                        <CardContent className='dCardContent' style={{ padding: 8 }} onClick={() => props.navigateToDrawer("ReceiveOrders", 0)}>
                            <Button startIcon={<CallReceived />} style={{ marginTop: 0 }} variant="contained" color="primary" className='btn' size="small" >Receive</Button>
                            {isOrderCountLoaded ? <React.Fragment>
                                <Typography variant="h4" component="div" className='number' style={{ marginTop: 5 }}>
                                    {orderCount.pendingReceipts}
                                </Typography>
                            </React.Fragment> : <OrderCountShimmer></OrderCountShimmer>}
                        </CardContent>
                    </Card>
                </Grid> */}
            </Grid>
        );
    }
    const quickActionsWeb = () => {
        return (
            <Grid container spacing={2} className={Utils.isMobile ? "1" : "2"}>
                <Grid item xs={6} sm={3}>
                    <Card className='dCard'>

                        <CardContent className='dCardContent'>

                            <div className='dCardHeader'>
                                <div className='icon'>
                                    <ShoppingCart></ShoppingCart>
                                </div>
                                <div className='header'>
                                    <Typography gutterBottom>
                                        Pending Picking
                                    </Typography>
                                </div>
                            </div>
                            <Divider />
                            {isOrderCountLoaded ?
                                <React.Fragment>
                                    <Typography variant="h4" component="div" className='number'>
                                        {orderCount.readyForPicking}
                                    </Typography>
                                    <Button onClick={() => props.navigateToDrawer("FulfillOrders", 1)} variant="contained" color="primary" className='btn'>Pick</Button>
                                </React.Fragment> : <OrderCountShimmer></OrderCountShimmer>}

                        </CardContent>
                    </Card>

                </Grid>
                <Grid item xs={6} sm={3}>
                    <Card className='dCard'>
                        <CardContent className='dCardContent'>
                            <div className='dCardHeader'>
                                <div className='icon'>
                                    <PendingActionsIcon></PendingActionsIcon>
                                </div>
                                <div className='header'>
                                    <Typography gutterBottom>
                                        Pending Packing
                                    </Typography>
                                </div>
                            </div>
                            <Divider />
                            {isOrderCountLoaded ? <React.Fragment>
                                <Typography variant="h4" component="div" className='number'>
                                    {orderCount.readyForPacking}
                                </Typography>
                                <Button onClick={() => props.navigateToDrawer("FulfillOrders", 2)} variant="contained" color="primary" className='btn'>Pack</Button>
                            </React.Fragment> : <OrderCountShimmer></OrderCountShimmer>}

                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={6} sm={3}>
                    <Card className='dCard'>
                        <CardContent className='dCardContent'>
                            <div className='dCardHeader'>
                                <div className='icon'>
                                    <CallReceived></CallReceived>
                                </div>
                                <div className='header'>
                                    <Typography gutterBottom>
                                        Pending Receipt
                                    </Typography>
                                </div>
                            </div>
                            <Divider />
                            {isOrderCountLoaded ? <React.Fragment>
                                <Typography variant="h4" component="div" className='number'>
                                    {orderCount.pendingReceipts}
                                </Typography>
                                <Button variant="contained" onClick={() => props.navigateToDrawer("ReceiveOrders", 0)} color="primary" className='btn'>Receive</Button>
                            </React.Fragment> : <OrderCountShimmer></OrderCountShimmer>}

                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={6} sm={3}>
                    <Card className='dCard'>
                        <CardContent className='dCardContent'>
                            <div className='dCardHeader'>
                                <div className='icon'>
                                    <NextPlan></NextPlan>
                                </div>
                                <div className='header'>
                                    <Typography gutterBottom>
                                        Customer PickUp
                                    </Typography>
                                </div>
                            </div>
                            <Divider />
                            {isOrderCountLoaded ? <React.Fragment>
                                <Typography variant="h4" component="div" className='number'>
                                    {orderCount.readyForCustomerPickup}
                                </Typography>
                                <Button onClick={() => props.navigateToDrawer("FulfillOrders", 3)} variant="contained" color="primary" className='btn'>PickUp</Button>
                            </React.Fragment> : <OrderCountShimmer></OrderCountShimmer>}
                        </CardContent>
                    </Card>
                </Grid>
            </Grid >
        );
    }

    const loadOrderCountData = async () => {
        let storeId = IdentityManager.getMultipleStoresId(props.store);
        let aggPayload = {
            "store": storeId
        };
        if (storeId) {
            OrderManager.getOrdersAggregatesWrapper(aggPayload).then((orderCount: any) => {
                setOrderCountLoaded(true);
                if (orderCount) {
                    dispatch(allActions.dashboardActions.loadDashboardOrdersCount(orderCount));
                }
            });

        }
        else {
            setOrderCountLoaded(true);
        }


    }

    const renderResourceCounr = (shipment: any) => {


        const result = shipment?.ShipmentLines?.ShipmentLine.reduce((accumulator: any, obj: any) => {
            return accumulator + Number(obj.ExtnRescheduleReqCount ? obj.ExtnRescheduleReqCount : "0");
        }, 0);
        return <React.Fragment>
            <span className='resourceCount' title='Order Resource Count'>
                {result}
            </span>
        </React.Fragment>

    }

    const loadDasboardOrders = async () => {
        let storeId = IdentityManager.getMultipleStoresId(props.store);
        let pSize = StoreManager.config.pSize
        let payload = {
            "store": storeId,
            "pSize": Number(pSize)
        };
        if (storeId && storeId.length == 1) {
            OrderManager.getReSourcedOrders(payload).then((orders: any) => {
                if (orders) {
                    dispatch(allActions.dashboardActions.loadDashboardResourceOrders(orders));
                    setResourcedLoaded(true);
                }
            });

            OrderManager.getExpeditedIrders(payload).then((orders: any) => {
                if (orders) {
                    dispatch(allActions.dashboardActions.loadDashboardExpeditedOrders(orders));
                    setExpediteLoaded(true);
                }
            });

            OrderManager.getPendingCustomerPickupOrders(payload).then((orders: any) => {
                if (orders) {
                    dispatch(allActions.dashboardActions.loadDashboardPendingCustomerOrders(orders));
                    setPendingCustomersLoaded(true);
                }
            });

            OrderManager.getPendingCustomerDecisionsOrders(payload).then((orders: any) => {

                if (orders) {
                    dispatch(allActions.dashboardActions.loadDashboardPendingCustomerDecisionsOrders(orders));
                    setPendingCustomerDecisionsLoaded(true);
                }
            });

        }
        else {
            setResourcedLoaded(true);
            setExpediteLoaded(true);
            setPendingCustomersLoaded(true);
            setPendingCustomerDecisionsLoaded(true);
        }


    }

    const initailizeCall = async () => {
        loadOrderCountData();
        loadDasboardOrders();
    }

    React.useEffect(() => {

        setOrderCountLoaded(false);
        setExpediteLoaded(false);
        setResourcedLoaded(false);
        setPendingCustomerDecisionsLoaded(false);
        setPendingCustomersLoaded(false);
        // if (props.store.length > 1) {
        //     loadDasboardOrders();
        // }
        async function sendRequest() {
            initailizeCall();
        }

        // This will help to keep shimmers loading untill we fetch the stores and set the user store
        if ((stores && stores.length > 0)) {
            sendRequest();

        }
        if (props.store.length == 1) {
            const intervalId = setInterval(() => {
                sendRequest()
            }, 1000 * 30) // in milliseconds
            return () => {
                clearInterval(intervalId);
            }
        }
    }, [props.store, stores, status]);
    React.useEffect(() => {
        if (stores && stores.length > 0 && districtsList && districtsList.length > 0) {
            props.selectProfileStores();
        }
    }, [stores, districtsList])

    React.useEffect(() => {
        if (regionsList.length > 0 && IdentityManager.isEnterprise) {
            props.selectAllRegions();
        }
    }, [regionsList])
    return (
        <div className={Utils.isMobile ? "innerContianerMobile" : "innerContianer"}>
            <NotificationPopup {...showCustomerResponse}>
            </NotificationPopup>
            <OrderDetailNotificationPopup {...Info}>
            </OrderDetailNotificationPopup>
            {Utils.isMobile ? quickActionsMobile() : quickActionsWeb()}
            <Grid container spacing={2} style={{ paddingLeft: 3, paddingRight: 3 }}>
                <Grid item xs={12} sm={6} style={{ display: "none" }}>
                    <Card className='dCard' >
                        <CardContent className='dCardContent' style={{ minHeight: 490 }}>
                            <div className='dCardHeader'>
                                <div className='icon'>
                                    <DirectionsCar></DirectionsCar>
                                </div>
                                <div className='header' style={{ marginTop: "4px" }}>
                                    <Typography gutterBottom>
                                        Customer Waiting
                                    </Typography>
                                </div>
                            </div>
                            <Divider />
                            <CustomerWaiting></CustomerWaiting>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={12}>
                    {props.store.length <= 1 && <Card className='dCard'>
                        <CardContent className='dCardContent'>
                            <div className='dCardHeader' style={{ marginBottom: 7 }}>
                                <div className='icon'>
                                    <AssignmentLateIcon fontSize={"large"}></AssignmentLateIcon>
                                </div>
                                <div className='header' style={{}}>
                                    <h2 style={{ fontSize: 26 }}>
                                        Orders Need Attention
                                    </h2>
                                </div>
                            </div>
                            <Grid container spacing={4}>
                                <Grid item xs={12} sm={3} style={{ paddingLeft: 3, paddingRight: 3 }}>
                                    <Accordion expanded={expandResource} className={resourced && resourced.length > 0 && "scroll-class"} onChange={() => handleResource()} style={{ height: expandResource ? 350 : 80, boxShadow: "0px 1px 1px 1px rgb(0 0 0 / 20%), 0px 1px 1px 1px rgb(0 0 0 / 14%), 0px 1px 1px 2px rgb(0 0 0 / 12%)" }}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                            style={{ color: Constants.Colors.red, height: 80 }}
                                        >
                                            <CallReceived></CallReceived>
                                            <Typography style={{ paddingRight: 3 }}><b>Resourced Orders</b></Typography>
                                            <Avatar
                                                style={{ backgroundColor: Constants.Colors.red, width: 25, height: 25, fontSize: "0.95rem" }}
                                            >
                                                {resourced ? resourced.length : ""}
                                            </Avatar>
                                        </AccordionSummary>

                                        <Divider style={{ minWidth: "250px" }} />
                                        <AccordionDetails style={{ display: "block", padding: 0, minHeight: "250px", minWidth: resourced && resourced.length > 0 && "250px", overflow: "scroll" }}>

                                            {isResourcedloaded ? <React.Fragment>
                                                {resourced && resourced.length > 0 ? <React.Fragment>
                                                    <List style={{ height: "250px" }}>
                                                        {resourced.map((item: any, index: any) => (
                                                            <ListItem
                                                                style={{ maxHeight: 72.02 }}
                                                                key={index}>
                                                                <ListItemText disableTypography
                                                                    primary={
                                                                        <React.Fragment>
                                                                            <Typography style={{ fontSize: "15px" }}><b>{item._source.Shipment.OrderNo}</b>
                                                                                {renderResourceCounr(item._source.Shipment)}
                                                                            </Typography>
                                                                        </React.Fragment>

                                                                    }
                                                                    secondary={
                                                                        <div style={{ display: "" }}>
                                                                            <Typography style={{ display: "", fontSize: "12px", color: "rgba(0, 0, 0, 0.54)" }} >{"Created On:"}
                                                                            </Typography>
                                                                            <Typography style={{ display: "", fontSize: "12px", color: "rgba(0, 0, 0, 0.54)" }} >{Utils.convertElasticDateToPst(item._source.Shipment?.OrderDate)}
                                                                            </Typography>
                                                                        </div>
                                                                    } />
                                                                <ListItemSecondaryAction>
                                                                    <StatusBtn item={item} openInfo={openInfoPopup}></StatusBtn>
                                                                </ListItemSecondaryAction>
                                                            </ListItem>
                                                        ))}
                                                    </List>
                                                </React.Fragment> : <NoDataFound msg='No Resourced Orders'></NoDataFound>}
                                            </React.Fragment> : <BulletShimmer></BulletShimmer>}


                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                                <Grid item xs={12} sm={3} style={{ paddingLeft: 3, paddingRight: 3 }}>
                                    <Accordion expanded={expandExpedited} className={resourced && resourced.length > 0 && "scroll-class"} onChange={() => handleExpedited()} style={{ height: expandExpedited ? 350 : 80, boxShadow: "0px 1px 1px 1px rgb(0 0 0 / 20%), 0px 1px 1px 1px rgb(0 0 0 / 14%), 0px 1px 1px 2px rgb(0 0 0 / 12%)" }}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                            style={{ color: Constants.Colors.red, height: 80 }}
                                        >
                                            <CallReceived></CallReceived>
                                            <Typography style={{ paddingRight: 3 }}><b>Expedited Shipping</b></Typography>
                                            <Avatar
                                                style={{ backgroundColor: Constants.Colors.red, width: 25, height: 25, fontSize: "0.95rem" }}
                                            >
                                                {expedited ? expedited.length : ""}
                                            </Avatar>
                                        </AccordionSummary>
                                        <Divider style={{ minWidth: "250px" }} />
                                        <AccordionDetails style={{ display: "block", padding: 0, minHeight: "250px", minWidth: resourced && resourced.length > 0 && "250px", overflowY: "scroll" }}>
                                            {isExpeditedloaded ? <React.Fragment>
                                                {expedited && expedited.length > 0 ? <React.Fragment>
                                                    <List style={{ height: "250px" }}>
                                                        {expedited.map((item: any, index: any) => (
                                                            <ListItem
                                                                key={index}>
                                                                <ListItemText disableTypography primary={<Typography style={{ fontSize: "15px" }}><b>{item._source.Shipment.OrderNo}</b></Typography>} secondary={
                                                                    <div style={{ display: "" }}>
                                                                        <Typography style={{ display: "", fontSize: "12px", color: "rgba(0, 0, 0, 0.54)" }} >{"Ship By End of Day"}
                                                                        </Typography>
                                                                    </div>
                                                                } />
                                                                <ListItemSecondaryAction>
                                                                    <StatusBtn item={item} openInfo={openInfoPopup}></StatusBtn>
                                                                </ListItemSecondaryAction>
                                                            </ListItem>
                                                        ))}
                                                    </List>
                                                </React.Fragment> : <NoDataFound msg='No Expedited Shipping'></NoDataFound>}
                                            </React.Fragment> : <BulletShimmer></BulletShimmer>}



                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                                <Grid item xs={12} sm={3} style={{ paddingLeft: 3, paddingRight: 3 }}>
                                    <Accordion expanded={expandedDecisions} onChange={() => handleDecisions()} style={{ height: expandedDecisions ? 350 : 80, boxShadow: "0px 1px 1px 1px rgb(0 0 0 / 20%), 0px 1px 1px 1px rgb(0 0 0 / 14%), 0px 1px 1px 2px rgb(0 0 0 / 12%)" }}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                            style={{ color: Constants.Colors.red, height: 80 }}
                                        >
                                            <CallReceived></CallReceived>
                                            <Typography style={{ paddingRight: 3 }}><b>Pending Customer Decisions</b></Typography>
                                            <Avatar
                                                style={{ backgroundColor: Constants.Colors.red, width: 25, height: 25, fontSize: "0.95rem" }}
                                            >
                                                {pendingCustomerDecisions ? pendingCustomerDecisions.length : "0"}
                                            </Avatar>
                                        </AccordionSummary>
                                        <Divider />
                                        <AccordionDetails style={{ display: "block", padding: 0, minHeight: "250px", overflowY: "scroll" }}>
                                            {isPendingCustomerDecisionsloaded ? <React.Fragment>
                                                {pendingCustomerDecisions && pendingCustomerDecisions.length > 0 ? <React.Fragment>
                                                    <List style={{ height: "250px" }}>
                                                        {pendingCustomerDecisions.map((item: any, index: any) => {
                                                            //
                                                            return (
                                                                <ListItem
                                                                    onClick={() => { navigateToOrder(item._source.shipmentkey, item._source.orderno, ShipmentStatusConstant.ReadyForPick, item._source.store) }}
                                                                    key={index}>
                                                                    <ListItemText disableTypography
                                                                        primary={<Typography style={{ fontSize: "15px" }}><b>{item._source.orderno}</b></Typography>}
                                                                        secondary={
                                                                            <div style={{ display: "" }}>
                                                                                <Typography style={{ display: "", fontSize: "12px", color: "rgba(0, 0, 0, 0.54)" }} >
                                                                                    {
                                                                                        item._source.responded == "true" ?
                                                                                            "Customer Responded"
                                                                                            :
                                                                                            "Waiting Customer Response"
                                                                                    }
                                                                                </Typography>
                                                                                <Typography style={{ display: "", fontSize: "12px", color: "rgba(0, 0, 0, 0.54)" }} >
                                                                                    {
                                                                                        item._source.responded == "true" ?
                                                                                            <React.Fragment>
                                                                                                {Utils.convertElasticDateToPst(item._source.customerresponsedate)}
                                                                                            </React.Fragment>
                                                                                            :
                                                                                            <React.Fragment>
                                                                                                {Utils.convertElasticDateToPst(item._source.transactionDate)}
                                                                                            </React.Fragment>
                                                                                    }
                                                                                </Typography>
                                                                            </div>
                                                                        } />
                                                                    <ListItemSecondaryAction>
                                                                        {
                                                                            item._source.responded == "true" ?
                                                                                <MarkEmailReadIcon style={{ cursor: "pointer", color: Constants.Colors.green }} titleAccess="Customer Responded" onClick={(e: any) => clickCustomerResponseIcon(e, item._source)} />
                                                                                :
                                                                                <HistoryToggleOffIcon style={{ cursor: "pointer", color: Constants.Colors.red }} titleAccess="Waiting for Customer Response" onClick={(e: any) => clickCustomerResponseIcon(e, item._source)} />
                                                                        }

                                                                    </ListItemSecondaryAction>
                                                                </ListItem>
                                                            );
                                                        })}
                                                    </List>
                                                </React.Fragment> : <NoDataFound msg='No Pending Customer Decisions'></NoDataFound>}
                                            </React.Fragment> : <BulletShimmer></BulletShimmer>}


                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                                <Grid item xs={12} sm={3} style={{ paddingLeft: 3, paddingRight: 3 }}>
                                    <Accordion expanded={expandedPickup} onChange={() => handlePickup()} style={{ height: expandedPickup ? 350 : 80, boxShadow: "0px 1px 1px 1px rgb(0 0 0 / 20%), 0px 1px 1px 1px rgb(0 0 0 / 14%), 0px 1px 1px 2px rgb(0 0 0 / 12%)" }}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                            style={{ color: Constants.Colors.red, height: 80 }}
                                        >
                                            <CallReceived></CallReceived>
                                            <Typography style={{ paddingRight: 3 }}><b>Overdue Customer Pickup</b></Typography>
                                            <Avatar
                                                style={{ backgroundColor: Constants.Colors.red, width: 25, height: 25, fontSize: "0.95rem" }}
                                            >
                                                {pendingCustomers ? pendingCustomers.length : ""}
                                            </Avatar>
                                        </AccordionSummary>
                                        <Divider />
                                        <AccordionDetails style={{ display: "block", padding: 0, minHeight: "250px", overflowY: "scroll", overflowX: "hidden" }}>
                                            {isPendingCustomersloaded ? <React.Fragment>
                                                {pendingCustomers && pendingCustomers.length > 0 ? <React.Fragment>
                                                    <List style={{ height: "250px" }}>
                                                        {pendingCustomers.map((item: any, index: any) => {
                                                            return (
                                                                <ListItem
                                                                    onClick={() => { navigateToOrder(item._source.Shipment.ShipmentKey, item._source.Shipment.OrderNo, ShipmentStatusConstant.ReadyForCustomerPickup, item._source.Shipment.ExtnPickupStoreID ? item._source.Shipment.ExtnPickupStoreID : 0) }}
                                                                    key={index}>
                                                                    <ListItemText disableTypography
                                                                        primary={<Typography style={{ fontSize: "15px" }}><b>{item._source.Shipment.OrderNo}</b></Typography>}
                                                                        secondary={
                                                                            <div style={{ display: "" }}>
                                                                                <Typography style={{ display: "", fontSize: "12px", color: "rgba(0, 0, 0, 0.54)" }} >{"Waiting on Customer Pickup"}
                                                                                </Typography>
                                                                            </div>
                                                                        } />
                                                                    <ListItemSecondaryAction>
                                                                        <Badge badgeContent={Utils.getDateDiffFromToday(item._source?.Shipment?.StatusDate)} style={{ color: Constants.Colors.red, fontSize: "0.95rem" }} showZero>
                                                                            <DateRangeIcon />
                                                                        </Badge>
                                                                    </ListItemSecondaryAction>
                                                                </ListItem>
                                                            )
                                                        })}
                                                    </List>
                                                </React.Fragment> : <NoDataFound msg='No Overdue Customer Pickup'></NoDataFound>}
                                            </React.Fragment> : <BulletShimmer></BulletShimmer>}


                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>}
                </Grid>
            </Grid>
            {props.store.length > 1 && <Grid item sm={12}>
                <Card className='dCard' >
                    <CardContent className='dCardContent'>
                        <div className='dCardHeader' style={{ marginBottom: 7 }}>
                            <div className='icon'>
                                <AssignmentLateIcon fontSize={"large"}></AssignmentLateIcon>
                            </div>
                            <div className='header' style={{}}>
                                <h3 style={{ fontSize: 24 }}>
                                    Fulfillment Backlog
                                </h3>
                            </div>
                        </div>
                        <DashboardGrid store={props.store} district={props.district} changeDistrict={props.changeDistrict} changeRegion={props.changeRegion} changeStore={props.changeStore} />
                    </CardContent>
                </Card>
            </Grid>}
            <Grid container spacing={2} style={{ display: "none" }}>
                <Grid item xs={12} sm={6}>
                    <Card className='dCard'>
                        <CardContent>
                            <div className='dCardHeader'>
                                <div className='icon'>
                                    <DirectionsCar></DirectionsCar>
                                </div>
                                <div className='header' style={{ marginTop: "4px" }}>
                                    <Typography gutterBottom>
                                        Store Orders Status (Today vs Yesterday)
                                    </Typography>
                                </div>
                            </div>
                            <Divider />

                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Card className='dCard'>
                        <CardContent style={{ paddingBottom: "5px" }}>
                            <div className='dCardHeader'>
                                <div className='icon'>
                                    <DirectionsCar></DirectionsCar>
                                </div>
                                <div className='header' style={{ marginTop: "4px" }}>
                                    <Typography gutterBottom>
                                        Orders Booked/Cancelled Status (Last 7 days)
                                    </Typography>
                                </div>
                            </div>
                            <Divider />

                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div >
    );

}


export default Dashboard;